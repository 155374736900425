import { useEffect, useState } from "react";
import { DialogTitle, DialogContent, DialogContentText, TextField, FormControl, InputLabel, Select, MenuItem, Button } from "@mui/material";
import styles from "../../prospectlist.module.css";
import { X } from "react-feather";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { toast } from "react-smart-toaster";

function PhoneField(props) {
    return (<NumberFormat
        customInput={TextField}
        prefix=""
        displayType="input"
        type="tel"
        format={"(###) ###-####"}
        {...props}
    />);
}

const phoneRegExp = /^\(?([0-9]{3})\)?[ ]?([0-9]{3})[-]?([0-9]{4})$/;

const StepOneForm = (props) => {
    const schema = yup.object().shape({
        prospect_name: yup.string().required("This field is Required").max(50, "First name cannot be more than 50 characters"),
        phone: yup.string().required("This field is Required").matches(phoneRegExp, "Invalid phone number").test("max-4-digit", "Phone number is already registered.", (value) => {
            return phoneIsUnique(value);
        }),
        property_id: yup.string().required("This field is Required"),
        apt_id: yup.string()
        //apt_id: yup.string().required("This field is Required")
    });

    const { getAccessTokenSilently } = useAuth0();
    const [propertyList, setPropertyList] = useState([]);
    const [apartmentList, setApartmentList] = useState([]);
    const { handleSubmit, control, errors, setValue } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: {
            property_id: '',
            apt_id: ''
        }
    });

    const phoneIsUnique = async (phone) => {
        const isEmailUnique = await checkEmailIsUnique(phone);
        return isEmailUnique?.data?.status;
    };

    const checkEmailIsUnique = (phone) => {
        return axios.get(process.env.REACT_APP_APPLICANT_API_URL + 'v1/check-phone-new/' + phone);
    }

    useEffect(() => {
        const fetchPropertyList = async () => {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
            await axios.get(process.env.REACT_APP_APPLICANT_API_URL + "v1/property-list-with-apartment", {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then((response) => {
                setPropertyList(response.data.data);
            }).catch((error) => {
                if (typeof error.response !== "undefined")
                    toast.error(error.response.data.message);
            });
        };
        fetchPropertyList();
    }, [getAccessTokenSilently]);

    useEffect(() => {
        if (props.selectedProperty > 0) {
            setApartmentList([]);
            setValue('property_id', props.selectedProperty);
            setValue('apt_id', '');
            propertyList.map(i => {
                if (i?.id === props.selectedProperty) {
                    setApartmentList(i?.apartments);
                }
                return null;
            });
        }
    }, [props.selectedProperty, propertyList, setValue]);

    const onSubmit = (data) => {
        props.onSave(data);
    }

    const selectProperty = (e) => {
        setApartmentList([]);
        setValue('apt_id', '');
        propertyList.map(i => {
            if (i?.id === e?.target?.value) {
                setApartmentList(i?.apartments);
            }
            return null;
        });
    }

    return (<>
        <DialogTitle id="alert-dialog-title"><span>Add Prospect</span><button onClick={(e) => props?.setOpenAddProspectModal(false)}><X /></button></DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={`${styles.AddprospectFirstStepForm}`}>
                        <p className={`${styles.AddprospectFirstStepFormTitle}`}>Profile Information</p>
                        <div className={`${styles.FirstStepFormFullSec} FormGroup`}>
                            <Controller
                                name={`prospect_name`}
                                control={control}
                                render={(field) => (
                                    <TextField value={field.value} onChange={(e) => field.onChange(e)} label="Name*" variant="outlined" error={errors?.prospect_name} />
                                )}
                            />
                            {errors.prospect_name && (<p className={`${styles.ErrorM}`}>{errors?.prospect_name?.message}</p>)}
                        </div>
                        <div className={`${styles.FirstStepFormFullSec} FormGroup`}>
                            <Controller
                                name={`phone`}
                                control={control}
                                render={(field) => (
                                    <PhoneField value={field.value} onChange={(e) => field.onChange(e)} label="Mobile Phone*" variant="outlined" error={errors?.phone} />
                                )}
                            />
                            {errors.phone && (<p className={`${styles.ErrorM}`}>{errors?.phone?.message}</p>)}
                        </div>
                        <div className={`${styles.FirstStepFormFullSec} FormGroup`}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" error={errors?.property_id}>Property*</InputLabel>
                                <Controller
                                    name="property_id"
                                    control={control}
                                    render={(field) => (
                                        <Select label="Property*" {...field} onChange={(e) => { field.onChange(e); selectProperty(e); }} error={errors?.property_id}>
                                            {propertyList.map(i => {
                                                if (props.selectedProperty === 0) {
                                                    return <MenuItem key={i?.id} value={i?.id}>{i?.name}</MenuItem>
                                                } else {
                                                    if (props.selectedProperty === i?.id) {
                                                        return <MenuItem key={i?.id} value={i?.id}>{i?.name}</MenuItem>
                                                    } else {
                                                        return null;
                                                    }
                                                }
                                            })}
                                        </Select>
                                    )}
                                />
                            </FormControl>
                            {errors.property_id && (<p className={`${styles.ErrorM}`}>{errors?.property_id?.message}</p>)}
                        </div>
                        <div className={`${styles.FirstStepFormFullSec} FormGroup`}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" error={errors?.apt_id}>Unit</InputLabel>
                                <Controller
                                    name="apt_id"
                                    control={control}
                                    render={(field) => (
                                        <Select label="Unit*" {...field} error={errors?.apt_id}>
                                            {apartmentList.map(i => {
                                                return <MenuItem key={i?.id} value={i?.id}>{i?.apt_no}</MenuItem>
                                            })}
                                        </Select>
                                    )}
                                />
                            </FormControl>
                            {errors.apt_id && (<p className={`${styles.ErrorM}`}>{errors?.apt_id?.message}</p>)}
                        </div>
                        <div className={`${styles.ButtonAction}`}>
                            <Button className="CancelPopupBU" onClick={(e) => props?.setOpenAddProspectModal(false)}>Cancel</Button>
                            <Button className="SubmitPopupBU" type="submit">Next</Button>
                        </div>
                    </div>
                </form>
            </DialogContentText>
        </DialogContent>
    </>);
}

export default StepOneForm;