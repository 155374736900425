import { TextField } from "@mui/material";
import styles from "../../propertydetails.module.css";
import { Editor } from "@tinymce/tinymce-react";
import { Button } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-smart-toaster";
import { useAuth0 } from "@auth0/auth0-react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect } from "react";

const schema = yup.object().shape({
    title: yup.string().required("This field is Required").max(50, "Layout title can not be more than 4 characters"),
    description: yup.string().required("This field is Required")
});

const LeaseTemplateAddForm = ({ property_id, leaseEditId, setAddModalOpen, setLoading, setSnackbarMsg, setSnackbarOpen, addLeaseDocument, updateLeaseDocument }) => {
    const { getAccessTokenSilently } = useAuth0();

    const { errors, control, handleSubmit, setValue } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            title: ''
        }
    });

    useEffect(() => {
        async function fetchData() {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
            axios.get(process.env.REACT_APP_PROPERTY_API_URL + "v1/properties/" + property_id + "/leases/" + leaseEditId, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                setValue("title", response.data.data?.title);
                setValue("description", response.data.data?.description);
            }).catch((error) => {
                if (typeof error.response !== "undefined")
                    toast.error(error.response.data.message);
            });
        }

        if (leaseEditId > 0) {
            fetchData();
        }
    }, [leaseEditId, getAccessTokenSilently, property_id, setValue]);

    const onSubmit = async (data) => {
        setLoading(true);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
        axios.post(process.env.REACT_APP_PROPERTY_API_URL + "v1/properties/" + property_id + "/leases", data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            setAddModalOpen(false);
            setSnackbarMsg("Lease document added successfully.");
            setSnackbarOpen(true);

            addLeaseDocument(response.data.data);
            setLoading(false);

        }).catch((error) => {
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
            setLoading(false);
        });
    };

    const onUpdate = async (data) => {
        setLoading(true);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
        axios.post(process.env.REACT_APP_PROPERTY_API_URL + "v1/properties/" + property_id + "/leases/" + leaseEditId, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            setAddModalOpen(false);
            setSnackbarMsg("Lease document updated successfully.");
            setSnackbarOpen(true);

            updateLeaseDocument(response.data.data);
            setLoading(false);

        }).catch((error) => {
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
            setLoading(false);
        });
    }

    return (<div className={`${styles.ModalFormGroup}`}>
        <form onSubmit={handleSubmit(leaseEditId > 0 ? onUpdate : onSubmit)}>
            <div className={`${styles.AddLeaseSection}`}>
                <div className={`${styles.AddDocTitleSec}`}>
                    <div className={`${styles.FormGroupAddLease} FormGroup`}>
                        <Controller
                            name="title"
                            control={control}
                            render={(field) => (
                                <TextField
                                    {...field}
                                    id="outlined-basic"
                                    variant="outlined"
                                    label="Title*"
                                />
                            )}
                        />
                        {errors.title && (<p className={`${styles.ErrorM}`}>{errors.title.message}</p>)}
                    </div>
                </div>
                <div className={`${styles.FormGroupTextareaFull}`}>
                    <label className={`${styles.FilterLabel}`}>Description<span className={`${styles.errorSpan}`}>*</span></label>
                    <Controller
                        name="description"
                        control={control}
                        render={(field) => (
                            <Editor
                                className={`${styles.formControlTextArea}`}
                                apiKey={"z3r7z5rrb92qgt55caemf3mrz1kdxuckuq25g9u3syc75r7u"}
                                value={field?.value}
                                init={{
                                    height: 500,
                                    menubar: false,
                                    plugins: ["lists", "code", "table"],
                                    toolbar: [
                                        "undo redo | bold italic underline strikethrough | forecolor backcolor removeformat | fontselect fontsize | alignleft aligncenter alignright alignjustify | numlist bullist | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | code",
                                        "tenant1name tenant1 tenant2name tenant2 tenant3name tenant3 property_manager propertyowner apartment_location security monthlyrent term start_date end_date",
                                    ],
                                    font_size_formats:
                                        "8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt",
                                    setup: (editor) => {
                                        editor.ui.registry.addButton("tenant1", {
                                            text: "Tenant 1 Signature",
                                            onAction: () =>
                                                editor.insertContent(
                                                    `<img src="${process.env.REACT_APP_BASE_URL}/static/tenant-1-signature-name.jpg" width="250" />`
                                                ),
                                        });
                                        editor.ui.registry.addButton("tenant2", {
                                            text: "Tenant 2 Signature",
                                            onAction: () =>
                                                editor.insertContent(
                                                    `<img src="${process.env.REACT_APP_BASE_URL}/static/tenant-2-signature-name.jpg" width="250" />`
                                                ),
                                        });
                                        editor.ui.registry.addButton("tenant3", {
                                            text: "Tenant 3 Signature",
                                            onAction: () =>
                                                editor.insertContent(
                                                    `<img src="${process.env.REACT_APP_BASE_URL}/static/tenant-3-signature-name.jpg" width="250" />`
                                                ),
                                        });
                                        editor.ui.registry.addButton("property_manager", {
                                            text: "Property Manager",
                                            onAction: () =>
                                                editor.insertContent(` {{{{PropertyManager}}}} `),
                                        });
                                        editor.ui.registry.addButton("tenant1name", {
                                            text: "Tenant 1 Name",
                                            onAction: () =>
                                                editor.insertContent(` {{{{Tenant1Name}}}} `),
                                        });
                                        editor.ui.registry.addButton("tenant2name", {
                                            text: "Tenant 2 Name",
                                            onAction: () =>
                                                editor.insertContent(` {{{{Tenant2Name}}}} `),
                                        });
                                        editor.ui.registry.addButton("tenant3name", {
                                            text: "Tenant 3 Name",
                                            onAction: () =>
                                                editor.insertContent(` {{{{Tenant3Name}}}} `),
                                        });
                                        editor.ui.registry.addButton("apartment_location", {
                                            text: "Apartment Location",
                                            onAction: () =>
                                                editor.insertContent(` {{{{ApartmentLocation}}}} `),
                                        });
                                        editor.ui.registry.addButton("term", {
                                            text: "Lease Term",
                                            onAction: () =>
                                                editor.insertContent(` {{{{LeaseTerm}}}} `),
                                        });
                                        editor.ui.registry.addButton("security", {
                                            text: "Security Deposit",
                                            onAction: () =>
                                                editor.insertContent(` {{{{SecurityDeposit}}}} `),
                                        });
                                        editor.ui.registry.addButton("monthlyrent", {
                                            text: "Monthly Rent",
                                            onAction: () =>
                                                editor.insertContent(` {{{{MonthlyRent}}}} `),
                                        });
                                        editor.ui.registry.addButton("start_date", {
                                            text: "Start Date",
                                            onAction: () =>
                                                editor.insertContent(` {{{{StartDate}}}} `),
                                        });
                                        editor.ui.registry.addButton("end_date", {
                                            text: "End Date",
                                            onAction: () =>
                                                editor.insertContent(` {{{{EndDate}}}} `),
                                        });
                                        editor.ui.registry.addButton("propertyowner", {
                                            text: "Property Manager Signature",
                                            onAction: () =>
                                                editor.insertContent(
                                                    `<img src="${process.env.REACT_APP_BASE_URL}/static/property-owner-signature-name.jpg" width="250" />`
                                                ),
                                        });
                                    },
                                }}
                                onEditorChange={(e) => field.onChange(e)}
                            />
                        )}
                    />
                    {errors.description && (<p className={`${styles.ErrorM}`}>{errors.description.message}</p>)}
                </div>
                <div className={`${styles.ButtonAction}`}>
                    <Button className="CancelPopupBU" onClick={(e) => setAddModalOpen(false)}>Cancel</Button>
                    <Button className="SubmitPopupBU" type="submit">{leaseEditId > 0 ? 'Update' : 'Add'}</Button>
                </div>
            </div>
        </form>
    </div>)
}

export default LeaseTemplateAddForm;