import React, { useEffect } from "react";
import styles from './leasesignsuccess.module.css';
import Helmet from "react-helmet";
import Header from '../../components/HeaderPublicOther'
import Footer from '../../components/FooterPublicOther';

function LeaseSignSuccess({ history, match }) {

  useEffect(() => {
    setTimeout(() => {
      window.opener = null;
      window.open("", "_self");
      window.close();
    }, 1000);
  }, []);

  const closeTab = () => {
    window.opener = null;
    window.open("", "_self");
    window.close();
  };

  return (
    <div>
      <Helmet title="Lease Sign Success" />
      <Header />

      <div className="WhiteBG">
        <div className={`${styles.ErrorMain}`}>
          <div className={`${styles.ErrorMainSec}`}>
            <img src="/static/img/contract.png" alt="" className={`${styles.LeaseSignIMG}`} />
            <p className={`${styles.ErrorTitle}`}>Add Lease Document</p>
            <p className={`${styles.PaymentSuccessTitleSub}`}>Your Lease document is created successfully!</p>
            <button className={`${styles.LearnMBUBanner}`} onClick={closeTab}>Back to website</button>
          </div>
        </div>
        <Footer />
      </div>

    </div>
  );
}

export default LeaseSignSuccess;
