import React, { useEffect } from "react";
import Loader from "../../components/Loader";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';

function LogOff() {
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  const { logout, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    async function logOutEvent() {
      const token = await getAccessTokenSilently();
      axios.post(process.env.REACT_APP_USER_API_URL + 'v1/auth0-logout', {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(response => {
        logout({ returnTo: window.location.origin });
      });
    }
    logOutEvent();
  }, [audience, logout, getAccessTokenSilently]);

  return (
    <React.Fragment>
      <Loader />
    </React.Fragment>
  );
}

export default LogOff;
