import { CheckCircle, DollarSign, Eye, FileText, Send, X, XCircle } from "react-feather";
import styles from "../leasedetails.module.css";
import dayjs from "dayjs";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import relativeTime from 'dayjs/plugin/relativeTime';
import { useState } from "react";
import { Dialog, DialogContent } from "@mui/material";
dayjs.extend(relativeTime);

const LeaseHistoryCard = ({ data, setLoading, setSnackbarOpen, setSnackbarMsg }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [filePopupOpen, setFilePopupOpen] = useState(false);
    const [fileUrl, setFileUrl] = useState('');

    const resendInvoice = async (hId) => {
        setLoading(true);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        await axios.get(process.env.REACT_APP_PAYMENT_API_URL + 'v1/resend-invoice/' + hId, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(response => {
            setLoading(false);

            setSnackbarOpen(true);
            setSnackbarMsg('Invoice has been resent successfully.');
        }).catch(error => {
            setLoading(false);
        });
    }

    return (<div className={`${styles.HistoryRow}`}>
        {data?.histories2.map((item, index) => {
            return (<div className={`${styles.HistoryCard}`} key={index}>
                <div className={`${styles.HistoryCardHead}`}>
                    <div className={((item?.status === 'fail') ? `${styles.HistoryIcon}  ${styles.Failed}` : `${styles.HistoryIcon}`)}>
                        {(item?.status === 'fail') ? <XCircle /> : ((item?.type === 'rent-bill' || item?.type === 'security-bill' || item?.type === 'late-fee-bill') ? <DollarSign /> : ((item?.type === 'send-invoice' || item?.type === 'resend-invoice') ? <FileText /> : <CheckCircle />))}
                    </div>
                    <div className={`${styles.HistoryCardDE}`}>
                        <p className={`${styles.HistoryCardTitle}`}><p className={`${styles.HistoryCardTitle}`}>{item.title}<span>{dayjs(item?.created_at).fromNow()}</span></p></p>
                    </div>
                </div>
                <div className={`${styles.HistoryCardBody}`}>
                    <p className={`${styles.HistoryText}`} dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                    {(item?.verification_data && item?.verification_data?.files) && <button className={`${styles.ViewDetailsBU}`} onClick={(e) => { setFilePopupOpen(true); setFileUrl(item?.verification_data?.file_url); }}><Eye /> View</button >}
                    {(item?.type === 'send-invoice' || item?.type === 'resend-invoice') && <button className={`${styles.ViewDetailsBU}`} onClick={(e) => resendInvoice(item?.id)}><Send /> Resend</button >}
                </div>
            </div>)
        })}


        <Dialog
            onClose={(e) => setFilePopupOpen(false)}
            aria-labelledby="customized-dialog-title"
            open={filePopupOpen}
            className="NewPopUp NewPopUpForLease"
        >
            <button onClick={(e) => setFilePopupOpen(false)} className={`${styles.IDImageCloseBU}`}><X /></button>
            <DialogContent>
                <div className={`${styles.IDImageSec}`}><img src={fileUrl} className={`${styles.IDImage}`} alt="" /></div>
            </DialogContent>
        </Dialog>
    </div>)
}

export default LeaseHistoryCard;