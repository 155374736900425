import { useState } from 'react';
import styles from '../communication.module.css';
import { Send, Search, X } from "react-feather";

function ChatHeader(props) {

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (event) => {
    setSearchQuery(event.target.value)
    props.onSearchMessage(event.target.value)
  }

  const onClearSearch = () => {
    props.onSearchMessage("");
    setSearchQuery("")
  }

  return (
    <>
      <div className={`${styles.ChatSecHeader}`}>
        <div className={`${styles.ChatSecHeaderTenantSec}`}>
          {props.userDet?.id && <><div className={`${styles.PropertyImageSec} ${styles.Avatar}`}>
            <TenantAvatar tenants={props.userDet?.tenant_list}></TenantAvatar>

          </div>

            <div>
              <p className={`${styles.ChatSecHeaderTenantSecTitle}`}>{props.userDet?.tenant_list.map((tnt, index) => {
                return (<>
                  <span key={index}>{tnt.full_name}{index !== props.userDet?.tenant_list.length - 1 && ','}</span>
                </>)
              })}</p>
              <div className={`${styles.ChatSecHeaderTenantSec}`}>
                <p className={`${styles.ChatSecHeaderTenantSecMember}`}>{props.userDet?.propertyName} - {props.userDet?.apt_no}</p>
              </div>
            </div>
          </>}
        </div>

        <div className={`${styles.ChatSecHeaderActions}`}>
          {props.userDet?.id && <div className={`${styles.ChatSecSearch}`} onClick={props.toggleMenu}>
            <input type="text" placeholder="Search..." />
            <Search />
          </div>}
          <div className={`${styles.ChatSecStartMessage}`} onClick={props.handleClickOpen}><Send /> Compose</div>
        </div>
      </div>
      <div className={props.isMenuOpen ? `${styles.HeadSearchSec} ${styles.Open}` : `${styles.HeadSearchSec} `}>
        <input type='text' placeholder="Search..." value={searchQuery} onChange={handleSearch} />
        <X onClick={onClearSearch} />
      </div>
    </>
  )
}

function TenantAvatar({ tenants }) {
  return (
    <>
      {/* ******** For four tenant ********* */}
      {tenants.length === 4 && <div className={`${styles.AvatarSec}`}>
        <div className={`${styles.AvatarHalfSec}`}>
          <div className={`${styles.AvatarHalfIcon}`}>{tenants[0]?.user_image ? <img src={tenants[0]?.user_image} alt='' /> : <span>{tenants[0]?.full_name?.charAt(0).toUpperCase()}</span>}</div>
          <div className={`${styles.AvatarHalfIcon}`}>{tenants[1]?.user_image ? <img src={tenants[1]?.user_image} alt='' /> : <span>{tenants[1]?.full_name?.charAt(0).toUpperCase()}</span>}</div>
        </div>
        <div className={`${styles.AvatarHalfSec}`}>
          <div className={`${styles.AvatarHalfIcon}`}>{tenants[2]?.user_image ? <img src={tenants[2]?.user_image} alt='' /> : <span>{tenants[2]?.full_name?.charAt(0).toUpperCase()}</span>}</div>
          <div className={`${styles.AvatarHalfIcon}`}>{tenants[3]?.user_image ? <img src={tenants[3]?.user_image} alt='' /> : <span>{tenants[3]?.full_name?.charAt(0).toUpperCase()}</span>}</div>
        </div>
      </div>}
      {/* ******** For four tenant ********* */}

      {/* ******** For triple tenant ********* */}
      {tenants.length === 3 && <div className={`${styles.AvatarSec}`}>
        <div className={`${styles.AvatarHalfSec} ${styles.Full}`}>
          <div className={`${styles.AvatarHalfIcon}`}>{tenants[0]?.user_image ? <img src={tenants[0]?.user_image} alt='' /> : <span>{tenants[0]?.full_name?.charAt(0).toUpperCase()}</span>}</div>
        </div>
        <div className={`${styles.AvatarHalfSec}`}>
          <div className={`${styles.AvatarHalfIcon}`}>{tenants[1]?.user_image ? <img src={tenants[1]?.user_image} alt='' /> : <span>{tenants[1]?.full_name?.charAt(0).toUpperCase()}</span>}</div>
          <div className={`${styles.AvatarHalfIcon}`}>{tenants[2]?.user_image ? <img src={tenants[2]?.user_image} alt='' /> : <span>{tenants[2]?.full_name?.charAt(0).toUpperCase()}</span>}</div>
        </div>
      </div>}
      {/* ******** For triple tenant ********* */}

      {/* ******** For double tenant ********* */}
      {tenants.length === 2 && <div className={`${styles.AvatarSec}`}>
        <div className={`${styles.AvatarHalfSec} ${styles.Full}`}>
          <div className={`${styles.AvatarHalfIcon}`}>{tenants[0]?.user_image ? <img src={tenants[0]?.user_image} alt='' /> : <span>{tenants[0]?.full_name?.charAt(0).toUpperCase()}</span>}</div>
        </div>
        <div className={`${styles.AvatarHalfSec} ${styles.Full}`}>
          <div className={`${styles.AvatarHalfIcon}`}>{tenants[1]?.user_image ? <img src={tenants[1]?.user_image} alt='' /> : <span>{tenants[1]?.full_name?.charAt(0).toUpperCase()}</span>}</div>
        </div>
      </div>}
      {/* ******** For double tenant ********* */}

      {/* ******** For single tenant ********* */}
      {tenants.length === 1 && <div className={`${styles.AvatarSec} ${styles.Full}`}>
        <div className={`${styles.AvatarHalfSec}`}>
          <div className={`${styles.AvatarHalfIcon}`}>{tenants[0]?.user_image ? <img src={tenants[0]?.user_image} alt='' /> : <span>{tenants[0]?.full_name?.charAt(0).toUpperCase()}</span>}</div>
        </div>
      </div>}
      {/* ******** For single tenant ********* */}
    </>
  )
}

export default ChatHeader