import React from "react";
import styled from "styled-components/macro";

const Root = styled.div`
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

const Custom = ({ children }) => {
  return (
    <Root>
      {children}
    </Root>
  );
};

export default Custom;
