import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet";
import styles from './communication.module.css';
import { Home, Search, X } from "react-feather";
import { Grid, Card, Typography, Divider as MuiDivider, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { withAuthenticationRequired, withAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import Loader from "../../components/Loader";
import { CommunicationLoaderS } from "../../components/LoaderC";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import socket from "../../services/socketService";
import PropertyTenantsList from "./components/propertyTenantsList";
import PropertyTenantsSearch from "./components/propertyTenantsSearch";
import ChatHeader from "./components/chatHeader";
import ChatMessages from "./components/chatMessages";
import ChatInput from "./components/chatInput";
import { toast } from 'react-smart-toaster';

const Divider = styled(MuiDivider)(spacing);

const ChatContainer = styled(Grid)`
  width: 100%;
  height: 65vh;
`;

const ChatSidebar = styled(Grid)`
  border-right: 1px solid ${(props) => props.theme.palette.divider};
`;

const ChatMain = styled(Grid)``;

class Chat extends React.Component {
  constructor({ match, ...props }) {
    super(...arguments);
    this.from_user_id = 1;
    this.state = {
      open: false, socket: '', from_user_id: '', to_user_id: 0, temp_to_user_id: 0, propertyList: [], chatList: [], tenantTypeHeadList: [], loading1: true, loading2: false, userDet: {}, messageTextVal: '', messageTextVal2: '', isOpenProperty: 0, isMenuOpen: '', chatMessagesList: [], filteredChatMessages: [], isConversationCreating: false
    };
    this.chatAreaRef = React.createRef();
  }

  async componentDidMount() {
    this.setState({ socket: socket });

    socket.on('connect', () => {
      console.log('WebSocket connecteddddddddddddddddd');
    });

    socket.on('broadcast-message', (msg) => {
      let message = msg.data
      if (this.state.userDet.apt_id) {
        if (this.state.userDet.apt_id === message.aptId && this.state.userDet.property_id === message.propertyId) {
          const newMessage = [...this.state.chatMessagesList, message];
          this.setState({ chatMessagesList: newMessage, filteredChatMessages: newMessage })
        }
      }

      if (!this.state.userDet.apt_id || (this.state.userDet.apt_id !== message.aptId || this.state.userDet.property_id !== message.propertyId)) {
        const updatePropertyList = this.state.propertyList.map((property) => {
          if (property.id === message.propertyId) {
            property.unread_no += 1;
            property.tenants.map((unit) => {
              if (unit.apt_id === message.aptId) {
                unit.unread_no += 1;
              }
              return null;
            })
          }
          return property;
        });
        this.setState({ propertyList: updatePropertyList });
      }
    });

   socket.on('get-conversation', (messages) => {
      this.setState({ chatMessagesList: messages.data, filteredChatMessages: messages.data, loading2: false });
    })

    socket.on('add-conversation-id-for-new-conversation', (conversationInfo) => {
      let info = conversationInfo.data;
      if (this.state.userDet.property_id === info.propertyId && this.state.userDet.apt_id === info.aptId) {
        const details = this.state.userDet;
        details.conversation_id = info.conversationId
        this.setState({ userDet: details, isConversationCreating: false })
      }
      const updatePropertyList = this.state.propertyList.map((property) => {
        if (property.id === info.propertyId) {
          property.tenants.map((unit) => {
            if (unit.apt_id === info.aptId) {
              unit.conversation_id = info.conversationId;
            }
            return null;
          })
        }
        return property;
      });
      this.setState({ propertyList: updatePropertyList });
    });

    socket.on('error-handler', (error) => toast.error(error.message));

    const { getAccessTokenSilently, user } = this.props.auth0;
    this.token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
    let t = this;

    t.setState({ ...t.state, from_user_id: user?.sub });

    await axios(process.env.REACT_APP_COMMUNICATION_API_URL + 'v1/typehead-tenant-list', {
      headers: {
        Authorization: `Bearer ${this.token}`,
      }
    }).then(response => {
      t.setState({ ...t.state, tenantTypeHeadList: response.data.data });
    }).catch(error => {
      t.setState({ ...t.state, tenantTypeHeadList: [] });
    });

    await axios(process.env.REACT_APP_COMMUNICATION_API_URL + 'v1/property-tenant-list', {
      headers: {
        Authorization: `Bearer ${this.token}`,
      }
    }).then(response => {
      t.setState({ ...t.state, propertyList: response.data.data, loading1: false });
      this.handleQueryParams()
    }).catch(error => {
      t.setState({ ...t.state, propertyList: [] });
    });
  }

  handleQueryParams = () => {
    const { location } = this.props;
    const queryParams = new URLSearchParams(location.search);
    const propertyId = queryParams.get("propertyId");
    const aptId = queryParams.get("aptId");

    if (propertyId && aptId) {
      const propertyItem = this.state.propertyList.find((p) => p.id === propertyId).tenants.find((apt) => apt.apt_id === aptId);
      this.openProperty(propertyItem.property_id);
      this.openChatWindow(propertyItem)
    }
  }

  setSelected = (selected) => {
    if (selected.length) {
      let item = selected[0];
      const property = this.state.propertyList.find((p) => p.id === item.property_id).tenants.find((apt) => apt.apt_id === item.apt_id);
      this.openProperty(property.property_id);
      this.openChatWindow(property);
    }
  }

  filterByCallback(option, props) {
    if (option.is_parent) {
      let filteredData = props?.options.filter(i => {
        return (i.is_parent === 0 && option.property_id === i.property_id && (i?.apt_no.toLowerCase().indexOf(props.text.toLowerCase()) !== -1 || i.name.toLowerCase().indexOf(props.text.toLowerCase()) !== -1));
      });
      if (filteredData.length)
        return true;
      else
        return false;
    }
    return option?.apt_no.toLowerCase().indexOf(props.text.toLowerCase()) !== -1 || option.name.toLowerCase().indexOf(props.text.toLowerCase()) !== -1;
  }

  openChatWindow = (item) => {
    const propertyName = this.state.tenantTypeHeadList.find((t) => t.is_parent && t.property_id === item.property_id);
    item = { ...item, propertyName: propertyName.name }
    this.setState({ to_user_id: item.id, userDet: item, chatList: [], loading2: true, messageTextVal: '', chatMessagesList: [] });

    const payload = { conversationId: item.conversation_id };

    this.state.socket.emit('/request-conversation', payload)
   

    if (item.conversation_id) {
      const updatePropertyList = this.state.propertyList.map((property) => {
        if (property.id === item.property_id) {
          property.tenants.map((unit) => {
            if (unit.apt_id === item.apt_id) {
              property.unread_no -= unit.unread_no
              unit.unread_no = 0;
            }
            return null;
          });
        }
        return property;
      });
      this.setState({ propertyList: updatePropertyList })
      this.state.socket.emit('read-message', { conversationId: item.conversation_id })
    }
  }

  sendMessage = () => {
    const { user } = this.props.auth0;
    let currentVal = this.state.messageTextVal ? this.state.messageTextVal : this.state.messageTextVal2;
    currentVal = currentVal.trim();
    if (currentVal) {
      try {
        if (!this.state.userDet.conversation_id) {
          this.setState({ isConversationCreating: true })
        }
        const messagePayload = {
          conversationId: this.state.userDet.conversation_id,
          senderId: user.sub,
          senderName: user.name,
          message: currentVal,
          aptId: this.state.userDet.apt_id,
          propertyId: this.state.userDet.property_id
        }

        const newMessage = [...this.state.chatMessagesList, { conversation_id: this.state.userDet.conversation_id, message: currentVal, sender_name: user.name, sender_id: user.sub, createdAt: new Date().toISOString(), is_read: 1, message_type: 'text' }];
        this.setState({ chatMessagesList: newMessage, filteredChatMessages: newMessage });
        this.state.socket.emit('send-message', messagePayload)
        this.setState({ messageTextVal: '', messageTextVal2: '' })
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("plese enter text");
    }
  }

  onSearchMessage = (searchQuery) => {
    try {
      if (searchQuery) {
        const filteredChatMessages = this.state.chatMessagesList.filter(
          message =>
            message.message ? message.message.toLowerCase().includes(searchQuery.toLowerCase()) : false
        );
        this.setState({ filteredChatMessages });

      } else {
        this.setState({ filteredChatMessages: this.state.chatMessagesList });
      }
    } catch (error) {
      console.log(error)
    }
  }

  handleChange = (e) => {
    this.setState({ ...this.state, messageTextVal: e.target.value });
  }

  handleClickOpen = () => {
    this.setState({ ...this.state, open: true, temp_to_user_id: 0, messageTextVal2: '' });
  };

  handleClose = () => {
    this.setState({ ...this.state, open: false, temp_to_user_id: 0, messageTextVal2: '' });
  };

  setSelected2 = (selected) => {
    if (selected.length) {
      let item = selected[0];
      const property = this.state.propertyList.find((p) => p.id === item.property_id).tenants.find((apt) => apt.apt_id === item.apt_id);
      this.openProperty(property.property_id);
      this.openChatWindow(property);
    }
  }

  sendMessage2 = () => {
    let currentVal = this.state.messageTextVal2;
    currentVal = currentVal.trim();
    if (!currentVal) {
      return;
    }
    this.setState({ ...this.state, messageTextVal: currentVal });

    this.handleClose()
    this.sendMessage();
  }

  openProperty = (itemId) => {
    if (this.state.isOpenProperty === itemId) {
      this.setState({ isOpenProperty: 0 });
    } else {
      this.setState({ isOpenProperty: itemId });
    }
  }

  toggleMenu = () => {
    let isOpen = this.state.isMenuOpen;
    if (isOpen === '') {
      isOpen = 'Open';
    } else {
      isOpen = '';
    }
    this.setState({ isMenuOpen: isOpen })
  }

  render() {
    return (
      <React.Fragment>
        <Helmet title="Communication" />
        <div className={`${styles.pageTitleWrap}`}>
          <Typography display="inline" className={`${styles.pageTitle}`} >Communication</Typography>
          <nav aria-label="breadcrumb">
            <ol className={`${styles.breadcrumb}`}>
              <li className={`${styles.breadcrumbItem}`}><span><Home /></span>Communication </li>
            </ol>
          </nav>
        </div>

        <div className={`${styles.mainCard}`}>
          <ChatContainer container component={Card}>
            <ChatSidebar item xs={12} md={4} lg={3} className="ChatSideBar">

              <div className={`${styles.ApartmentSarchSec} ApartmentSarchSec`}>
                <PropertyTenantsSearch filterByCallback={this.filterByCallback} propertyTypeAheadObj={this.state.tenantTypeHeadList} setSelected={this.setSelected} />
                <Search />
              </div>

              <Divider />
              <div className={`${styles.PropertyGroupSec}`}>
                {this.state.loading1 && <CommunicationLoaderS />}
                {this.state.propertyList.map((item, index) => {
                  return item?.tenants?.length ? (<PropertyTenantsList item={item} key={item.id} props={this} openChatWindow={this.openChatWindow} openProperty={this.openProperty} ActiveTenant={this.state.to_user_id} isOpen={this.state.isOpenProperty === item.id} />) : null;
                })}
              </div>

            </ChatSidebar>

            <ChatMain item xs={12} md={8} lg={9} className="ChatMessageSec">
              <ChatHeader onSearchMessage={this.onSearchMessage} userDet={this.state.userDet} isMenuOpen={this.state.isMenuOpen} toggleMenu={this.toggleMenu} handleClickOpen={this.handleClickOpen}></ChatHeader>
              <ChatMessages isConversationCreated={this.state.userDet.conversation_id} userId={this.state.from_user_id} loading2={this.state.loading2} chatMessagesList={this.state.filteredChatMessages} />
              {!this.state.isConversationCreating ? <ChatInput userDet={this.state.userDet} sendMessage={this.sendMessage} handleChange={this.handleChange} messageTextVal={this.state.messageTextVal} /> : <p className={`${styles.ConStarted}`}>Conversation is being created <img src='/static/img/chat-loader.svg' alt="Property" /></p>}
              {!this.state.userDet?.apt_id && <div className={`${styles.WelcomeMessageSec}`}>
                <div className={`${styles.WelcomeMessageBody}`}>
                  <img src='/static/img/chatIcon.png' alt="Property" />
                  <p className={`${styles.WelcomeMessageTitle}`}>Ready? Set, Chat!</p>
                  <p className={`${styles.WelcomeMessageText}`}>Start a conversation with a tenant by clicking on their name in the left panel.</p>
                </div>
              </div>}
            </ChatMain>

            <Dialog
              maxWidth="md"
              open={this.state?.open}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="CommuniChatNewPop"
            >
              <DialogTitle id="alert-dialog-title" className={`${styles.ChatNewPopHead}`}>
                New text message
                <button className={`${styles.CloseBU}`} onClick={this.handleClose}><X /></button>
              </DialogTitle>
              <DialogContent className={`${styles.ChatNewPopBody}`}>
                <div className={`${styles.ChatBody} ChatBody`}>
                  <div className={`${styles.PopForm} ${styles.ApartmentSarchSec} ApartmentSarchSec`}>
                    <label className={`${styles.PopFormTitle}`}>To</label>
                    <Typeahead
                      id="search-tenants"
                      labelKey="name"
                      filterBy={this.filterByCallback}
                      options={this.state.tenantTypeHeadList}
                      placeholder="Search tenants"
                      clearButton={true}
                      flip={true}
                      onChange={this.setSelected2}
                      renderMenuItemChildren={(option) => {
                        return option?.is_parent ? (
                          <div>
                            {option?.name}
                          </div>
                        ) : (
                          <div style={{ marginLeft: '20px' }}>
                            {option?.name}
                            <div>
                              <small>{option?.apt_no}</small>
                            </div>
                          </div>
                        )
                      }}
                    />
                  </div>
                  <div className={`${styles.PopForm}`}>
                    <label className={`${styles.PopFormTitle}`}>Message</label>
                    <textarea placeholder="Type your message" className={`${styles.PopFormTextArea}`} value={this.state.messageTextVal2} onChange={(e) => this.setState({ ...this.state, messageTextVal2: e.target.value })}></textarea>
                  </div>
                  <button className={`${styles.PopFormSendBU}`} onClick={this.sendMessage2}>Send</button>
                </div>
              </DialogContent>
            </Dialog>

          </ChatContainer>
        </div>

        {/* Chat notification window  */}

        {/* <div className={`${styles.ChatNotificationSec}`}>
          <p className={`${styles.ChatNotificationSecTitle}`}>Weston Hall</p>
          <p className={`${styles.ChatNotificationSecSubTitle}`}>Unit 111</p>
          <button className={`${styles.CloseChatNotiBU}`}><X /></button>
          <div className={`${styles.ChatNotiBodySec}`}>
            <div className={`${styles.ChatNotiBodyIcon}`}>
              <img src='/static/img/logoW.png' alt="Property" />
            </div>
            <div className={`${styles.ChatNotiBodyTextSec}`}>
              <p className={`${styles.ChatNotiBodyTitle}`}>New message from Ed Fowler</p>
              <p className={`${styles.ChatNotiBodyText}`}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
          </div>
        </div> */}
      </React.Fragment >
    );
  }
}

export default withAuthenticationRequired(withAuth0(Chat), {
  onRedirecting: () => <Loader />,
});
