import React, { useState } from "react";
import { AppBar, Tabs, Tab, Box, Select, FormControl, MenuItem, InputLabel } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import AppPermissionTab from "./Tabs/AppPermissionTab";
import MobileThemeTab from "./Tabs/MobileThemeTab";
import RentalApplicationTab from "./Tabs/RentalApplicationTab";
import LayoutTab from "./Tabs/LayoutTab";
import ApartmentTab from "./Tabs/ApartmentTab";
import LeaseDocumentTab from "./Tabs/LeaseDocumentTab";
import { ChevronDown } from "react-feather";
import PaymentSetTab from "./Tabs/PaymentSetTab";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const SimpleTabs = (props) => {
    const classes = useStyles();
    const [tabValue, setTabValue] = useState(0);
    const [moreOption, setMoreOption] = useState("");

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (<div className={classes.root}>
        <AppBar position="static">
            <Tabs value={tabValue} onChange={handleChange} aria-label="simple tabs example" variant="scrollable" scrollButtons="auto">
                <Tab label="Apartment Listing" {...a11yProps(0)} />
                <Tab label="Layouts" {...a11yProps(1)} />
                <Tab label="Rental Application" {...a11yProps(2)} />
                <Tab label="Lease Templates" {...a11yProps(3)} />
                <FormControl className="MoreOptions">
                    <InputLabel>More</InputLabel>
                    <ChevronDown className="MoreIcon" />
                    <Select value={moreOption} onChange={(e) => { setMoreOption(e.target.value); setTabValue(e.target.value); }} displayEmpty>
                        <MenuItem value={4}>Settings</MenuItem>
                        <MenuItem value={5}>Mobile App Permissions</MenuItem>
                        <MenuItem value={6}>Mobile Theme</MenuItem>
                    </Select>
                </FormControl>
            </Tabs>
        </AppBar>

        <TabPanel value={tabValue} index={0}>
            <ApartmentTab no_of_floor={props.no_of_floor} layouts={props.layouts} property_units={props.property_units} property_id={props.property_id} property_name={props?.property_name} setPropertyData={props.setPropertyData.bind(this)} setSnackbarOpen={props?.setSnackbarOpen.bind(this)} setSnackbarMsg={props?.setSnackbarMsg.bind(this)} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
            <LayoutTab layouts={props.layouts} property_id={props.property_id} property_name={props?.property_name} setPropertyData={props.setPropertyData.bind(this)} setSnackbarOpen={props?.setSnackbarOpen.bind(this)} setSnackbarMsg={props?.setSnackbarMsg.bind(this)} />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
            <RentalApplicationTab property_id={props?.property_id} property_name={props?.property_name} url_token={props?.url_token} rental_application={props?.rental_application} setSnackbarOpen={props?.setSnackbarOpen.bind(this)} setSnackbarMsg={props?.setSnackbarMsg.bind(this)} setPropertyData={props.setPropertyData.bind(this)} />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
            <LeaseDocumentTab property_id={props?.property_id} property_name={props?.property_name} setSnackbarOpen={props?.setSnackbarOpen.bind(this)} setSnackbarMsg={props?.setSnackbarMsg.bind(this)} lease_documents={props.lease_documents} setPropertyData={props.setPropertyData.bind(this)} />
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
            <PaymentSetTab property_id={props?.property_id} property_name={props?.property_name} setSnackbarOpen={props?.setSnackbarOpen.bind(this)} setSnackbarMsg={props?.setSnackbarMsg.bind(this)} setPropertyData={props.setPropertyData.bind(this)} late_fee_data={props?.late_fee_data} doorlock_password={props?.doorlock_password} />
        </TabPanel>
        <TabPanel value={tabValue} index={5}>
            <AppPermissionTab property_id={props?.property_id} property_name={props?.property_name} app_permissions={props?.app_permissions} setSnackbarOpen={props?.setSnackbarOpen.bind(this)} setSnackbarMsg={props?.setSnackbarMsg.bind(this)} setPropertyData={props.setPropertyData.bind(this)} />
        </TabPanel>
        <TabPanel value={tabValue} index={6}>
            <MobileThemeTab property_id={props?.property_id} property_name={props?.property_name} setSnackbarOpen={props?.setSnackbarOpen.bind(this)} setSnackbarMsg={props?.setSnackbarMsg.bind(this)} app_theme_options={props?.app_theme_options} setPropertyData={props.setPropertyData.bind(this)} />
        </TabPanel>

    </div>);
}


export default SimpleTabs;