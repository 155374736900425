import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import Loader from "../../components/Loader";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from 'axios';
import Select from "react-select";
import Helmet from "react-helmet";
import { toast } from 'react-smart-toaster';
import styles from './maintenancerequestadd.module.css';
import { Typography, Button } from "@material-ui/core";
import Footer from "../../components/Footer";
import { Home, Upload, X, Clock } from 'react-feather';
import 'date-fns';
import { FromLoader } from "../../components/LoaderC";
import { TimePicker } from "@material-ui/pickers";
import { AddLogCallAPI } from "../../components/AddLogs";
import NumberFormat from "react-number-format";
import dayjs from "dayjs";

const brandColor = '#cccccc';
const customStyles = {
  control: (base, state) => ({
    ...base,
    boxShadow: state.isFocused ? 0 : 0,
    borderColor: state.isFocused
      ? brandColor
      : base.borderColor,
    '&:hover': {
      borderColor: state.isFocused
        ? brandColor
        : base.borderColor,
    }
  })
};

const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const schema = yup.object().shape({
  tenant_id: yup.object().required('This field is Required'),
  category: yup.object().required('This field is Required'),
  contact_way: yup.object().required('This field is Required'),
  contact_val_phone: yup.string().when('contact_way', (contact_way, schema) => {
    if (contact_way && contact_way.value === 'Phone') return yup.string().required('This field is Required').matches(phoneRegExp, 'Invalid phone number');
    else return yup.string();
  }),
  contact_val_email: yup.string().when('contact_way', (contact_way, schema) => {
    if (contact_way && contact_way.value === 'Email') return yup.string().required('This field is Required').email('Invalid E-mail').max(50, 'Organizer E-mail cannot be more than 50 characters');
    else return yup.string();
  }),
  contact_time_val: yup.object().required('This field is Required'),
  contact_time_start: yup.date().required('This field is Required'),
  contact_time_end: yup.date().required('This field is Required'),
  description: yup.string().required('This field is Required').max(200, 'Description cannot be more than 200 characters'),
  priority: yup.object().required('This field is Required')

});
const electricalOptions = [
  { label: 'Electrical Socket', value: 'Electrical Socket', category_id: 'Electrical' },
  { label: 'Light Switch/Dimmer', value: 'Light Switch/Dimmer', category_id: 'Electrical' },
]
const plumbingOptions = [
  { label: 'Leak', value: 'Leak', category_id: 'Plumbing' },
  { label: 'Drain clogged', value: 'Drain clogged', category_id: 'Plumbing' },
  { label: 'Water temperature Issue', value: 'Water temperature Issue', category_id: 'Plumbing' },
]
const appliancesOptions = [
  { label: 'Stove/Oven Issue', value: 'Stove/Oven Issue', category_id: 'Appliances' },
  { label: 'Refrigerator Issue', value: 'Refrigerator Issue', category_id: 'Appliances' },
]

const categoryGroupOptions = [
  { label: 'Electrical', options: electricalOptions, id: 'Electrical' },
  { label: 'Plumbing', options: plumbingOptions, id: 'Plumbing' },
  { label: 'Appliances', options: appliancesOptions, id: 'Appliances' },
]
const contactWayList = [
  { value: 'Email', label: 'Email' },
  { value: 'Phone', label: 'Phone' }
];
const contactTimeList = [
  { value: 'Weekdays', label: 'Weekdays' },
  { value: 'Weekends', label: 'Weekends' }
];
const priorityList = [
  { value: 1, label: 'Low' },
  { value: 2, label: 'Medium' },
  { value: 3, label: 'High' }
];
function PhoneField(props) {
  return <NumberFormat
    prefix=""
    displayType="input"
    type="tel"
    format={"(###) ###-####"}
    {...props} />;
}
function MaintenanceRequestAdd({ history, match }) {
  const { getAccessTokenSilently } = useAuth0();
  const redirect = useHistory();
  const audience = process.env.REACT_APP_CASE_API_URL;
  const { id } = match.params;
  const isAddMode = !id;
  const [loading, setLoading] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [TenantList, setTenantList] = useState({ data: [], loading: false });
  const [contentLoading, setContentLoading] = useState(false);
  const [selectedContactway, setselectedContactway] = useState({ value: '', label: 'Select...' });
  const defaultTimeStart = dayjs().set("hour", 7).set("minute", 0)
  const defaultTimeEnd = dayjs().set("hour", 19).set("minute", 0);
  const [ImgClass, setImgClass] = useState({ progressBar: 'hide', progress: 0, imageBlock: 'hide', imagePathArr: [], imageNameArr: [] });
  const [ImgPathTemp, setImgPathTemp] = useState({ Files: [], imagePathArr: [] });

  const { register, handleSubmit, control, errors, setValue, watch } = useForm({
    resolver: yupResolver(schema), mode: 'onChange'
  });
  const maxChars = 200;
  const [charsLeft, setCharsLeft] = useState(maxChars);
  const [maxSelectedContactWayChars, setMaxSelectedContactWayChars] = useState(0);
  const [contactWayCharsLeft, setcontactWayCharsLeft] = useState(maxSelectedContactWayChars);
  const [contactType, setContactType] = useState('');

  useEffect(() => {
    async function fetchTenant() {
      setContentLoading(true);
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
      await axios.get(audience + 'v1/property-tenant-list', {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).then(response => {
        setTenantList({ data: response.data.data, loading: true });
        setContentLoading(false);
      }).catch(error => {
        setTenantList({ data: [], loading: true });
      });
    }
    if (!isAddMode) {
      fetchTenant();
    }
  }, [audience, setValue, isAddMode, getAccessTokenSilently]);

  useEffect(() => {
    if (!isAddMode) {
      async function fetchByID() {
        if (isLoad) {
          setContentLoading(false);
          return false;
        }
        setIsLoad(true);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
        await axios.get(audience + 'v1/maintenance-requests/' + id, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }).then(response => {
          setContentLoading(false);
          const resData = response.data.data;

          setValue('contact_time_start', dayjs(resData.contact_time_start, "LT"));
          setValue('contact_time_end', dayjs(resData.contact_time_end, "LT"));

          let img_path_arr = resData.images.map((e) => {
            return e.file_public_url
          });
          let image_name_arr = resData.images.map((e) => {
            return { "id": e.id, "file_path": e.file_path };
          });
          setImgClass({ ...ImgClass, imageBlock: '', imagePathArr: img_path_arr, imageNameArr: image_name_arr });

          contactWayList.map((e) => {
            if (e.value === resData.contact_way) {
              setValue('contact_way', e);
              setselectedContactway(e);
            }
            return true;
          });
          contactTimeList.map((e) => {
            if (e.value === resData.contact_time_val) {
              setValue('contact_time_val', e);
            }
            return true;
          });

          setCharsLeft(maxChars - resData.description.length);


          let m = 0;

          if (resData.contact_way === 'Phone') {
            m = 10;
            setContactType('phone');
            setValue('contact_val_phone', resData.contact_val);
          }
          if (resData.contact_way === 'Email') {
            m = 50;
            setContactType('email');
            setMaxSelectedContactWayChars(m);
            setcontactWayCharsLeft(m - resData.contact_val.length);
            setValue('contact_val_email', resData.contact_val);
          }

          priorityList.map((e) => {
            if (e.value === resData.priority) {
              setValue('priority', e);
            }
            return true;
          });
          const fields = ['description'];
          fields.forEach(field => setValue(field, resData[field]));

          categoryGroupOptions.map((e) => {
            if (e.id === resData.category) {
              e.options.map((e1) => {
                if (e1.value === resData.sub_category) {
                  setValue('category', e1);
                }
                return true;
              });
            }
            return true;
          });
          TenantList?.data.map((e) => {
            if (e.id === resData.property_id) {

              e.options.map((e1) => {
                if (e1.value === resData.tenant_id) {

                  setValue('tenant_id', e1);
                }
                return true;
              });
            }
            return true;
          });
        }).catch(error => {
          if (typeof error.response !== 'undefined')
            toast.error(error.response.data.message);
          setContentLoading(false);
        });
      }
      if (TenantList.loading) {
        fetchByID();
      }
    }

  }, [id, isAddMode, TenantList, audience, setValue, isLoad, ImgClass, getAccessTokenSilently, contactType]);

  function getContactWay(event) {
    setValue('contact_way', event);
    let m = 0;
    setselectedContactway(event);
    if (event.value === 'Phone') {
      m = 10;
      setContactType('phone');
    }
    if (event.value === 'Email') {
      m = 50;
      setContactType('email');
    }
    setMaxSelectedContactWayChars(m);
    setcontactWayCharsLeft(m);
  }

  function deleteImage(index) {
    let imgClass = ImgClass;
    let img_path_arr = imgClass.imagePathArr;
    img_path_arr = img_path_arr.filter(function (item, i) {
      return i !== index;
    })
    let image_name_arr = imgClass.imageNameArr;
    image_name_arr = image_name_arr.filter(function (item, i) {
      return i !== index;
    });

    setImgClass({ ...ImgClass, imagePathArr: img_path_arr, imageNameArr: image_name_arr });

  }

  function fileUploadButtonClickTemp() {
    document.getElementById('fileUploadID').getElementsByClassName('inputBtn')[0].click();
    return false;
  }

  async function onSubmit(data) {
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
    if (data.category) {
      data.sub_category = data.category.value;
      data.category = data.category.category_id;
    }
    if (data.tenant_id) {
      data.property_id = data.tenant_id.property_id;
      data.tenant_id = data.tenant_id.value;
    }
    if (data.contact_way) {
      data.contact_way = data.contact_way.value;
    }
    if (data.contact_time_val) {
      data.contact_time_val = data.contact_time_val.value;
    }
    if (data.contact_way === 'Phone') {
      data.contact_val = data.contact_val_phone;

    }
    if (data.contact_way === 'Email') {
      data.contact_val = data.contact_val_email;

    }
    if (data.priority) {
      data.priority = data.priority.value;
    }
    data.images = JSON.stringify(ImgClass.imageNameArr);

    if (data.contact_time_start) {
      data.contact_time_start = dayjs(data.contact_time_start).format('LT');
    }
    if (data.contact_time_end) {
      data.contact_time_end = dayjs(data.contact_time_end).format('LT');
    }
    setLoading(true);

    let formData = new FormData();

    Object.keys(data).map((key) => {
      formData.append(key, data[key]);

      return true;
    });

    if (ImgPathTemp.Files.length) {
      for (let n in ImgPathTemp.Files) {
        formData.append("uploadImages[]", ImgPathTemp.Files[n]);
      }
    }

    if (isAddMode) {
      return axios.post(audience + 'v1/maintenance-requests', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }).then(response => {
        let respData = response.data.data;
        toast.success(response.data.message);
        if (isAddMode) {
          setTimeout(() => {
            redirect.push('/cases/maintenance-request');
          }, 3000);
        }
        setLoading(false);
        let logData = {
          'title': 'New maintenance request is created',
          'description': [
            'Name: ' + respData.title,
            'Request number: ' + respData.request_no,
            'Property: ' + respData.property_name,
            'Apartment: ' + respData.apt_name,
            'Tenant: ' + respData.tenant_name,
          ]
        }
        AddLogCallAPI(logData, token);

      }).catch(error => {
        if (typeof error.response !== 'undefined')
          toast.error(error.response.data.message);
        setLoading(false);
      });
    } else {
      return axios.post(audience + 'v1/maintenance-requests/edit/' + id, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }).then(response => {
        let respData = response.data.data;
        toast.success(response.data.message);
        setLoading(false);
        setTimeout(() => {
          redirect.push('/cases/maintenance-request/details/' + id);
        }, 3000);
        let logData = {
          'title': 'Maintenance request is updated',
          'description': [
            'Name: ' + respData.title,
            'Request number: ' + respData.request_no,
            'Property: ' + respData.property_name,
            'Apartment: ' + respData.apt_name,
            'Tenant: ' + respData.tenant_name,
          ]
        }
        AddLogCallAPI(logData, token);


      }).catch(error => {
        if (typeof error.response !== 'undefined')
          toast.error(error.response.data.message);
        setLoading(false);
      });
    }
  };
  function cancelBtnClick(e) {
    if (!isAddMode) {
      redirect.push('/cases/maintenance-request/details/' + id);
    } else {
      redirect.push('/cases/maintenance-request/');
    }

  }

  const fileHandler = (e) => {
    var validExtensions = ['jpg', 'jpeg', 'png', 'svg']; //array of valid extensions
    var fileName = e.target.files[0].name;
    var fileNameExt = fileName.substr(fileName.lastIndexOf('.') + 1);
    if (validExtensions.indexOf(fileNameExt.toLowerCase()) === -1) {
      toast.error('File type is not allowed');

    }
    else if (e.target.files[0].size > 5242880) {
      toast.error('Image size should not be greater than 5 mb.');

    }
    else {
      let tempFiles = ImgPathTemp.Files;
      let tempImages = ImgPathTemp.imagePathArr;
      for (let n = 0; n < e.target.files.length; n++) {
        let file = e.target.files[n];
        tempImages.push(URL.createObjectURL(file));
        tempFiles.push(file);
      }
      setImgPathTemp({ ...ImgPathTemp, imagePathArr: tempImages, Files: tempFiles });
    }

  }

  function deleteTempImage(index) {
    let tempFiles = ImgPathTemp.Files;
    let tempImages = ImgPathTemp.imagePathArr;


    tempFiles = tempFiles.filter(function (item, i) {
      return i !== index;
    });

    tempImages = tempImages.filter(function (item, i) {
      return i !== index;
    })
    setImgPathTemp({ ...ImgPathTemp, imagePathArr: tempImages, Files: tempFiles });
  }

  return (<React.Fragment>
    {loading && <Loader />}
    <Helmet title={((isAddMode) ? 'Add' : 'Edit') + ' Maintenance Request'} />
    <div className={`${styles.pageTitleWrap}`}>
      <Typography display="inline" className={`${styles.pageTitle}`}>{(isAddMode) ? 'Add' : 'Edit'} Maintenance Request</Typography>
      <nav aria-label="breadcrumb">
        <ol className={`${styles.breadcrumb}`}>
          <li className={`${styles.breadcrumbItem}`}><span><Home /></span>Cases</li>
          <li className={`${styles.breadcrumbItem}`}><Link to={'/cases/maintenance-request'} >Maintenance Request</Link></li>
          <li className={`${styles.breadcrumbItem} ${styles.active}`}>{(isAddMode) ? 'Add' : 'Edit'}</li>
        </ol>
      </nav>
    </div>

    <div className={`${styles.mainCard}`}>
      {contentLoading && <div className="Loader FromLoader"><FromLoader /></div>}
      {!contentLoading && <div className={`${styles.AddUserMain}`}>
        <form id="myForm" onSubmit={handleSubmit(onSubmit)}>

          <div className={`${styles.Row}`}>
            <div className={`${styles.SmallDiv}`}>
              <div className={`${styles.FormRow}`}>

                <div className={`${styles.FormGroup}`}>
                  <label className={`${styles.FilterLabel}`} >Tenant<span className={`${styles.errorSpan}`}>*</span></label>
                  <Controller styles={customStyles}
                    name="tenant_id"
                    control={control}
                    options={TenantList.data}
                    as={Select}
                    ref={register}
                  />
                  {errors.tenant_id && !watch().tenant_id && <p className={`${styles.ErrorM}`}>{errors.tenant_id.message}</p>}
                  {errors.tenant_id && watch().tenant_id && <p className={`${styles.ErrorM}`}>{errors.tenant_id.message}</p>}
                </div>
                <div className={`${styles.FormGroup}`}>
                  <label className={`${styles.FilterLabel}`} >Category<span className={`${styles.errorSpan}`}>*</span></label>
                  <Controller
                    name="category"
                    control={control}
                    options={categoryGroupOptions}
                    as={Select}
                    ref={register}
                    placeholder='Select category'
                  />
                  {errors.category && !watch().category && <p className={`${styles.ErrorM}`}>{errors.category.message}</p>}
                </div>
              </div>
            </div>

            <div className={`${styles.BigDiv}`}>
              <div className={`${styles.FormRow}`}>
                <div className={`${styles.FormGroupCus}`}>
                  <label className={`${styles.FilterLabel}`} >Best way to contact tenant<span className={`${styles.errorSpan}`}>*</span></label>
                  <div className={`${styles.FormRowCus}`}>
                    <div className={`${styles.FormGroupCusS}`}>
                      <Controller
                        name="contact_way"
                        control={control}
                        options={contactWayList}

                        ref={register}
                        render={({ onChange, options, value }) => (
                          <Select styles={customStyles} name="contact_way" onChange={getContactWay} options={contactWayList} value={selectedContactway} />
                        )}
                      />
                      {errors.contact_way && !watch().contact_way && <p className={`${styles.ErrorM}`}>{errors.contact_way.message}</p>}
                    </div>
                    {contactType === 'phone' && <div className={`${styles.FormGroupCusB}`}>
                      <Controller
                        className={`${styles.formControl}`}
                        name="contact_val_phone"
                        control={control}
                        as={<PhoneField />}
                        ref={register}
                      />
                      {errors.contact_val_phone && <p className={`${styles.ErrorM}`}>{errors.contact_val_phone.message}</p>}
                    </div>}
                    {contactType === 'email' && <div className={`${styles.FormGroupCusB}`}>
                      <input className={`${styles.formControl}`} type="text" name="contact_val_email" ref={register} maxLength={maxSelectedContactWayChars} onChange={e => setcontactWayCharsLeft(maxSelectedContactWayChars - e.target.value.length)} />
                      {(maxSelectedContactWayChars === 50) && <p>{contactWayCharsLeft} {(contactWayCharsLeft > 1) ? 'characters' : 'character'} remaining</p>}
                      {errors.contact_val_email && <p className={`${styles.ErrorM}`}>{errors.contact_val_email.message}</p>}
                    </div>}
                  </div>
                </div>
                <div className={`${styles.FormGroupCus}`}>
                  <label className={`${styles.FilterLabel}`} >Best time to contact<span className={`${styles.errorSpan}`}>*</span></label>
                  <div className={`${styles.FormRowCus}`}>
                    <div className={`${styles.FormGroupCusS}`}>
                      <Controller styles={customStyles}
                        name="contact_time_val"
                        control={control}
                        options={contactTimeList}
                        as={Select}
                        ref={register}
                      />
                      {errors.contact_time_val && !watch().contact_time_val && <p className={`${styles.ErrorM}`}>{errors.contact_time_val.message}</p>}
                    </div>
                    <div className={`${styles.FormGroupCusB}`}>
                      <div className={`${styles.TimePick} TimePick`}>
                        <Clock className={`${styles.ClockIcon}`} />
                        <Controller
                          defaultValue={defaultTimeStart}
                          control={control}
                          name="contact_time_start"
                          render={(props) => (
                            <TimePicker
                              value={props.value}
                              onChange={(e) => props.onChange(e)}
                            />
                          )}
                        />
                        {errors.contact_time_start && <p className={`${styles.ErrorM}`}>{errors.contact_time_start.message}</p>}
                      </div>
                      <p>to</p>
                      <div className={`${styles.TimePick} TimePick`}>
                        <Clock className={`${styles.ClockIcon}`} />
                        <Controller
                          control={control}
                          defaultValue={defaultTimeEnd}
                          name="contact_time_end"
                          render={(props) => (
                            <TimePicker
                              value={props.value}
                              onChange={(e) => props.onChange(e)}
                            />
                          )}
                        />
                        {errors.contact_time_end && <p className={`${styles.ErrorM}`}>{errors.contact_time_end.message}</p>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={`${styles.FormGroup}`}>
              <label className={`${styles.FilterLabel}`} >Description<span className={`${styles.errorSpan}`}>*</span></label>
              <textarea placeholder="Add text here..." className={`${styles.formControlTextArea}`} name="description" ref={register} maxLength={maxChars} onChange={e => setCharsLeft(maxChars - e.target.value.length)}></textarea>
              <p>{charsLeft} {(charsLeft > 1) ? 'characters' : 'character'} remaining</p>
              {errors.description && <p className={`${styles.ErrorM}`}>{errors.description.message}</p>}
            </div>
            <div className={`${styles.FormGroup}`}>
              <label className={`${styles.FilterLabel}`} >Priority<span className={`${styles.errorSpan}`}>*</span></label>
              <Controller styles={customStyles}
                name="priority"
                control={control}
                options={priorityList}
                as={Select}
                ref={register}
              />
              {errors.priority && <p className={`${styles.ErrorM}`}>{errors.priority.message}</p>}
            </div>

            <div className={`${styles.FileIMGDiv}`}>
              <div className={`${styles.FileUpDuBUDiv}`}>
                <label for="file-upload" className={`${styles.UploadLabel}`} id="fileUploadID">
                  <input type="file" className="inputBtn" onChange={fileHandler} accept=".jpg,.png,.jpeg,.svg" inputProps={{ accept: 'image/*' }} />
                  <span onClick={fileUploadButtonClickTemp.bind()}>
                    <div className={`${styles.UpIcon}`}><Upload /></div> Upload Photo
                  </span>
                  <p className={`${styles.UploadText}`}>File size upto 5 MB  JPEG, JPG, PNG</p>
                </label>
                <span className={'e-upload-progress-wrap ' + ImgClass.progressBar}>
                  <span className="e-progress-inner-wrap">
                    <progressbar className="e-upload-progress-bar e-upload-progress" value="0" max="100" style={{ width: ImgClass.progress + '%' }}></progressbar>
                  </span>
                  <span className="e-progress-bar-text">{ImgClass.progress}%</span>
                </span>
              </div>
              {ImgClass.imagePathArr.map((item, index) => {
                return <div className={`${styles.ImgFile}`}>
                  <img src={item} alt="" />
                  <Button onClick={deleteImage.bind(this, index)}><X /></Button>
                </div>
              })}

              {ImgPathTemp.imagePathArr.map((item, index) => {
                return <div className={`${styles.ImgFile}`}>
                  <img src={item} alt="" />
                  <Button onClick={deleteTempImage.bind(this, index)}><X /></Button>
                </div>
              })}
            </div>
          </div>

          <div className={`${styles.ButtonDiv}`}>
            <button onClick={cancelBtnClick.bind(this)} type="button" className={`${styles.ButtonVoiletLine}`}>Cancel</button>
            <button type="submit" className={`${styles.ButtonVoiletSolid}`}>{isAddMode ? 'Add' : 'Update'}</button>
          </div>
        </form>
      </div>}
    </div>
    <Footer />
  </React.Fragment>);
}

export default withAuthenticationRequired(MaintenanceRequestAdd, {
  onRedirecting: () => <Loader />,
});