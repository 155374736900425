import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet";
import styles from './communication-prospect.module.css';
import { Home, Search, X } from "react-feather";
import { Grid, Card, Typography, Divider as MuiDivider } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { withAuthenticationRequired, withAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import Loader from "../../components/Loader";
import { CommunicationLoaderS } from "../../components/LoaderC";

import 'react-bootstrap-typeahead/css/Typeahead.css';
import socket from "../../services/socketService";
import ProspectList from "./components/prospectList";

import ChatHeader from "./components/prospectChatHeader";
import ChatMessages from "./components/prospectChatMessages";
import ChatInput from "./components/prospectChatInput";
import { toast } from 'react-smart-toaster';
import ProspectsSearch from "./components/prospectsSearch";

const Divider = styled(MuiDivider)(spacing);

const ChatContainer = styled(Grid)`
  width: 100%;
  height: 65vh;
`;

const ChatSidebar = styled(Grid)`
  border-right: 1px solid ${(props) => props.theme.palette.divider};
`;

const ChatMain = styled(Grid)``;

class CommunicationProspect extends React.Component {
  constructor({ match, ...props }) {
    super(...arguments);
    this.from_user_id = 1;
    this.state = {
      open: false, socket: '', from_user_id: '', to_user_id: 0, temp_to_user_id: 0, propertyList: [], prospectList: [], chatList: [], prospectTypeHeadList: [], tenantTypeHeadList: [], loading1: true, loading2: false, userDet: {}, messageTextVal: '', messageTextVal2: '',  isMenuOpen: '', chatMessagesList: [], filteredChatMessages: [], isConversationCreating: false
    };
    this.chatAreaRef = React.createRef();
  }

  async componentDidMount() {
    this.setState({ socket: socket });

    socket.on('connect', () => {
      console.log('WebSocket connecteddddddddddddddddd');
      
    });

   const broadcastMessageHandler = (msg) => {
    
    let message = msg.data
    if (true) {
      if (this.state.userDet && this.state.userDet.conversation_id === message.cid ) {
        const newMessage = [...this.state.chatMessagesList, message];
        this.setState({ chatMessagesList: newMessage, filteredChatMessages: newMessage })
      }else {
        const updateProspectList = this.state.prospectList.map((pl) => {
          if(message.cid === pl.conversation_id) {
            pl.unread_no = pl.unread_no + 1;
          }
          return pl;
        })
        this.setState({ prospectList: updateProspectList });
      }
    }
   }

   const getConversationHandler = (messages) => {
    console.log(messages)
    this.setState({ chatMessagesList: messages.data, filteredChatMessages: messages.data, loading2: false });
  
   }
   const socketErrorHandler = (error) => {
    toast.error("Internal server error")
    console.log(error)
  }

    socket.on('broadcast-message-for-prospect-remove', broadcastMessageHandler);

    socket.on('get-conversation-for-prospect-remove',getConversationHandler)

    socket.on('error-handler-for-prospect-remove', socketErrorHandler);

    const { getAccessTokenSilently, user } = this.props.auth0;
    this.token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
    let t = this;

    t.setState({ ...t.state, from_user_id: user?.sub });

   

    await axios(process.env.REACT_APP_APPLICANT_API_URL + 'v1/prospect-list', {
      headers: {
        Authorization: `Bearer ${this.token}`,
      }
    }).then(response => {
      t.setState({ ...t.state, prospectList: response.data.data, prospectTypeHeadList:response.data.data, loading1: false });
      this.handleQueryParams()
    }).catch(error => {
      t.setState({ ...t.state, propertyList: [] });
    });
  }

  componentWillUnmount() {
    // this.state.socket.off('get-conversation-for-prospect', this.getConversationHandler);
   
    // this.state.socket.off('broadcast-message-for-prospect', this.broadcastMessageHandler);
   
    // this.state.socket.off('error-handler-for-prospect', this.socketErrorHandler);
  
  }

  handleQueryParams = () => {
    const { location } = this.props;
    const queryParams = new URLSearchParams(location.search);
    const propertyId = queryParams.get("propertyId");
    const cid = queryParams.get("cid");

    if (propertyId && cid) {
      const prospectItem = this.state.prospectList.find((p) => p.conversation_id === cid)
      // this.openProperty(propertyItem.property_id);
      this.openChatWindow(prospectItem)
    }
  }

  setSelected = (selected) => {
  
    if (selected.length) {
      let item = selected[0];
      // const prospect = this.state.prospectList.find((p) => p.conversation_id === item.conversation_id);
     
      this.openChatWindow(item);
    }
  }

  filterByCallback(option, props) {
    const inputValue = props.text.toLowerCase();
    const fullName = option.full_name ? option.full_name.toLowerCase() : '';
    const phone = option.phone ? option.phone.toLowerCase() : '';

    return fullName.includes(inputValue) || phone.includes(inputValue);
  }

  openChatWindow = (item) => {
   
   
    item = { ...item, propertyName: item.property_name }
    this.setState({ to_user_id: item.id, userDet: item, chatMessagesList: [],filteredChatMessages: [],chatList: [], loading2: true, messageTextVal: '' });

    const payload = { conversationId: item.conversation_id };

    this.state.socket.emit('/request-conversation-for-prospect-remove', payload)
   

    if (item.conversation_id) {
      const updateProspectList = this.state.prospectList.map((prospect) => {
        if (prospect.conversation_id === item.conversation_id) {
          prospect.unread_no = 0;
        }
        return prospect;
      });
      this.setState({ prospectList: updateProspectList })
      this.state.socket.emit('read-message-for-prospect-remove', { conversationId: item.conversation_id })
    }
  }

  sendMessage = () => {
    const { user } = this.props.auth0;
    let currentVal = this.state.messageTextVal 
    currentVal = currentVal.trim();
    if (currentVal) {
      try {
        if (!this.state.userDet.conversation_id) {
          toast.error("No conversation found")
        }
        const messagePayload = {
          conversationId: this.state.userDet.conversation_id,
          senderId: user.sub,
          senderName: user.name,
          message: currentVal,
         
          
        }
        const currentDate = new Date();
        const isoDate = currentDate.toISOString();
        const messageItem = {
          cid: messagePayload.conversationId,
          author: messagePayload.senderName,
          createdAt: isoDate,
          index: 0,
          message: currentVal,
          senderId: messagePayload.senderId
        }

        const newMessage = [...this.state.chatMessagesList, messageItem ];
        this.setState({ chatMessagesList: newMessage, filteredChatMessages: newMessage });
        this.state.socket.emit('send-message-for-prospect-remove', messagePayload)
        this.setState({ messageTextVal: '' })
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("plese enter text");
    }
  }

  onSearchMessage = (searchQuery) => {
    try {
      if (searchQuery) {
        const filteredChatMessages = this.state.chatMessagesList.filter(
          message =>
            message.message ? message.message.toLowerCase().includes(searchQuery.toLowerCase()) : false
        );
        this.setState({ filteredChatMessages });

      } else {
        this.setState({ filteredChatMessages: this.state.chatMessagesList });
      }
    } catch (error) {
      console.log(error)
    }
  }

  handleChange = (e) => {
    this.setState({ ...this.state, messageTextVal: e.target.value });
  }

  handleClickOpen = () => {
    this.setState({ ...this.state, open: true, temp_to_user_id: 0, messageTextVal2: '' });
  };

  handleClose = () => {
    this.setState({ ...this.state, open: false, temp_to_user_id: 0, messageTextVal2: '' });
  };







  toggleMenu = () => {
    let isOpen = this.state.isMenuOpen;
    if (isOpen === '') {
      isOpen = 'Open';
    } else {
      isOpen = '';
    }
    this.setState({ isMenuOpen: isOpen })
  }

  render() {
    return (
      <React.Fragment>
        <Helmet title="Communication" />
        <div className={`${styles.pageTitleWrap}`}>
          <Typography display="inline" className={`${styles.pageTitle}`} >Communication</Typography>
          <nav aria-label="breadcrumb">
            <ol className={`${styles.breadcrumb}`}>
              <li className={`${styles.breadcrumbItem}`}><span><Home /></span>Communication </li>
            </ol>
          </nav>
        </div>

        <div className={`${styles.mainCard}`}>
          <ChatContainer container component={Card}>
            <ChatSidebar item xs={12} md={4} lg={3} className="ChatSideBar">

              <div className={`${styles.ApartmentSarchSec} ApartmentSarchSec`}>
                <ProspectsSearch filterByCallback={this.filterByCallback} prospectTypeHeadList={this.state.prospectTypeHeadList} setSelected={this.setSelected} />
                <Search />
              </div>

              <Divider />
              <div className={`${styles.PropertyGroupSec}`}>
                {this.state.loading1 && <CommunicationLoaderS />}
                {this.state.prospectList.map((item, index) => {
                  return <ProspectList item={item} key={item.id} props={this} openChatWindow={this.openChatWindow}  ActiveTenant={this.state.to_user_id} />;
                })}
              </div>

            </ChatSidebar>

            <ChatMain item xs={12} md={8} lg={9} className="ChatMessageSec">
              <ChatHeader onSearchMessage={this.onSearchMessage} userDet={this.state.userDet} isMenuOpen={this.state.isMenuOpen} toggleMenu={this.toggleMenu} handleClickOpen={this.handleClickOpen}></ChatHeader>
              <ChatMessages isConversationCreated={this.state.userDet.conversation_id} userId={this.state.from_user_id} loading2={this.state.loading2} chatMessagesList={this.state.filteredChatMessages} />
              <ChatInput userDet={this.state.userDet} sendMessage={this.sendMessage} handleChange={this.handleChange} messageTextVal={this.state.messageTextVal} /> 
              {!this.state.userDet?.conversation_id&& <div className={`${styles.WelcomeMessageSec}`}>
                <div className={`${styles.WelcomeMessageBody}`}>
                  <img src='/static/img/chatIcon.png' alt="Property" />
                  <p className={`${styles.WelcomeMessageTitle}`}>Ready? Set, Chat!</p>
                  <p className={`${styles.WelcomeMessageText}`}>Start a conversation with a prospect by clicking on their name in the left panel.</p>
                </div>
              </div>}
            </ChatMain>

            

          </ChatContainer>
        </div>

        {/* Chat notification window  */}

        {/* <div className={`${styles.ChatNotificationSec}`}>
          <p className={`${styles.ChatNotificationSecTitle}`}>Weston Hall</p>
          <p className={`${styles.ChatNotificationSecSubTitle}`}>Unit 111</p>
          <button className={`${styles.CloseChatNotiBU}`}><X /></button>
          <div className={`${styles.ChatNotiBodySec}`}>
            <div className={`${styles.ChatNotiBodyIcon}`}>
              <img src='/static/img/logoW.png' alt="Property" />
            </div>
            <div className={`${styles.ChatNotiBodyTextSec}`}>
              <p className={`${styles.ChatNotiBodyTitle}`}>New message from Ed Fowler</p>
              <p className={`${styles.ChatNotiBodyText}`}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
          </div>
        </div> */}
      </React.Fragment >
    );
  }
}

export default withAuthenticationRequired(withAuth0(CommunicationProspect), {
  onRedirecting: () => <Loader />,
});
