export default function prospectReducer (counter = 0, actions) {

    switch (actions.type) {

        case 'increment':
            return counter = counter + actions.payload;
            case 'reset':
                return counter = 0;

        default:
            return counter
    }

}