import { Check, ChevronDown } from 'react-feather';
import styles from '../rentalappplication.module.css';
import { Accordion, AccordionDetails, AccordionSummary, FormControl, TextField } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import axios from 'axios';

const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

const PhoneField = React.forwardRef((props, ref) => {
    return <NumberFormat
        customInput={TextField}
        prefix=""
        displayType="input"
        type="tel"
        format={"(###) ###-####"}
        {...props}
    />;
});

const TenDigitField = React.forwardRef((props, ref) => {
    return <NumberFormat
        customInput={TextField}
        prefix=""
        displayType="input"
        type="text"
        thousandSeparator={false}
        decimalScale={0}
        {...props}
    />;
});

const MoneyFieldCurrentRent = React.forwardRef((props, ref) => {
    return <NumberFormat
        customInput={TextField}
        prefix=""
        displayType="input"
        type="text"
        thousandSeparator={true}
        allowNegative={false}
        decimalScale={2}
        fixedDecimalScale
        {...props}
    />;
});

const RentalInfoAccordion = ({ expanded, stepData, setStepData, accordinChange, lastStep, setLastStep, setCurrentStep, getNextStep, setPanelExpanded, panelRef, applicantId }) => {
    const [charsLeft, setCharsLeft] = useState({ reason_for_moving: 200, who_live_with_you: 200 });

    const { handleSubmit, control, setValue, formState: { errors } } = useForm({
        mode: "onChange",
        defaultValues: {
            landlord_name: '',
            landlord_phone_no: '',
            reason_for_moving: '',
            who_live_with_you: '',
            long_lived_current_addrs: '',
            current_rent: ''
        }
    });

    useEffect(() => {
        if (stepData) {
            if (stepData?.landlord_name) {
                setValue('landlord_name', stepData?.landlord_name);
            }
            if (stepData?.landlord_phone_no) {
                setValue('landlord_phone_no', stepData?.landlord_phone_no);
            }
            if (stepData?.reason_for_moving) {
                setValue('reason_for_moving', stepData?.reason_for_moving);
            }
            if (stepData?.who_live_with_you) {
                setValue('who_live_with_you', stepData?.who_live_with_you);
            }
            if (stepData?.long_lived_current_addrs) {
                setValue('long_lived_current_addrs', stepData?.long_lived_current_addrs);
            }
            if (stepData?.current_rent) {
                setValue('current_rent', stepData?.current_rent);
            }
        }
    }, [stepData, setValue]);

    const onSubmit = (data) => {
        let postData = { ...data, is_rental_info_submitted: 1 };
        setStepData(prev => {
            return { ...prev, ...postData };
        });

        if (applicantId) {
            axios.post(process.env.REACT_APP_APPLICANT_API_URL + 'v1/save-applicants/' + applicantId, postData);
        }

        let currentStepTemp = getNextStep(4);
        setCurrentStep(currentStepTemp);
        setPanelExpanded('panel' + currentStepTemp);
        if (lastStep < currentStepTemp) {
            setLastStep(currentStepTemp);
        }
    }

    return (<Accordion expanded={expanded === 'panel4'} onChange={accordinChange('panel4', 4)} disabled={lastStep < 4}>
        <AccordionSummary expandIcon={<ChevronDown />} aria-controls="panel1bh-content" id="panel1bh-header" ref={panelRef}>
            <div className={`${styles.FormAccoHead}`}>
                <p className={`${styles.FormAccoHeadTitle}`}>Rental Information</p>
                <div className={((lastStep > 4) ? `${styles.AccoCheck} ${styles.Active}` : `${styles.AccoCheck}`)}><Check /></div>
            </div>
        </AccordionSummary>
        <AccordionDetails className={`${styles.CussAccoRow}`}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={`${styles.AccoFormSec}`}>

                    <div className={`${styles.FormGroupHalf} FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>Name of present landlord<span>*</span></label>
                        <FormControl fullWidth>
                            <Controller
                                name="landlord_name"
                                control={control}
                                rules={{
                                    required: "This field is required.",
                                    maxLength: {
                                        value: 50,
                                        message: "Landlord name cannot be more than 50 characters."
                                    }
                                }}
                                render={(field) => (
                                    <TextField
                                        {...field}
                                        label="Name"
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }} />
                                )}
                            />
                            {errors.landlord_name && <p className={`${styles.ErrorM}`}>{errors.landlord_name.message}</p>}
                        </FormControl>
                    </div>
                    <div className={`${styles.FormGroupHalf} FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>Phone number of present landlord<span>*</span></label>
                        <FormControl fullWidth>
                            <Controller
                                name="landlord_phone_no"
                                control={control}
                                rules={{
                                    required: 'This field is required.',
                                    pattern: {
                                        value: phoneRegExp,
                                        message: "Please enter a valid phone number.",
                                        excludeEmptyString: true
                                    }
                                }}
                                render={(field) => (
                                    <PhoneField
                                        {...field}
                                        label="Phone number"
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }}
                                    />
                                )}
                            />
                            {errors.landlord_phone_no && <p className={`${styles.ErrorM}`}>{errors.landlord_phone_no.message}</p>}
                        </FormControl>
                    </div>

                    <div className={`${styles.FormGroupFull} FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>Reason for moving<span>*</span></label>
                        <FormControl fullWidth>
                            <Controller
                                name="reason_for_moving"
                                control={control}
                                rules={{
                                    required: "This field is required.",
                                    maxLength: {
                                        value: 200,
                                        message: "Reason for moving can not be more than 200 characters."
                                    }
                                }}
                                render={(field) => (
                                    <TextField
                                        {...field}
                                        label="Reason for moving"
                                        multiline
                                        rows={4}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            setCharsLeft((prev) => {
                                                return { ...prev, reason_for_moving: 200 - e.target.value.length };
                                            });
                                        }}
                                        inputProps={{ maxLength: 200 }}
                                    />
                                )}
                            />
                            <p className={`${styles.FormFieldMessage}`}>{charsLeft?.reason_for_moving > 1 ? charsLeft?.reason_for_moving + " characters remaining" : charsLeft?.reason_for_moving + " character remaining"}</p>
                            {errors.reason_for_moving && <p className={`${styles.ErrorM}`}>{errors.reason_for_moving.message}</p>}
                        </FormControl>
                    </div>

                    <div className={`${styles.FormGroupFull} FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>Occupancy<span>*</span></label>
                        <p className={`${styles.FormSubLabel}`}>Who will be living with you? Please provide their first name, last name, date of birth, and relationship to you.</p>
                        <FormControl fullWidth>
                            <Controller
                                name="who_live_with_you"
                                control={control}
                                rules={{
                                    required: "This field is required.",
                                    maxLength: {
                                        value: 200,
                                        message: "Who else will be living with you ? can not be more than 200 characters."
                                    }
                                }}
                                render={(field) => (
                                    <TextField
                                        {...field}
                                        label="Describe"
                                        multiline
                                        rows={4}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            setCharsLeft((prev) => {
                                                return { ...prev, who_live_with_you: 200 - e.target.value.length };
                                            });
                                        }}
                                        inputProps={{ maxLength: 200 }}
                                    />
                                )}
                            />
                            <p className={`${styles.FormFieldMessage}`}>{charsLeft?.who_live_with_you > 1 ? charsLeft?.who_live_with_you + " characters remaining" : charsLeft?.who_live_with_you + " character remaining"}</p>
                            {errors.who_live_with_you && <p className={`${styles.ErrorM}`}>{errors.who_live_with_you.message}</p>}
                        </FormControl>
                    </div>

                    <div className={`${styles.FormGroupHalf} FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>Current Address Term<span>*</span></label>
                        <p className={`${styles.FormSubLabel}`}>How many months have you lived at present address?</p>
                        <FormControl fullWidth>
                            <Controller
                                name="long_lived_current_addrs"
                                control={control}
                                rules={{
                                    required: 'This field is required.',
                                    maxLength: {
                                        value: 3,
                                        message: "Lived present address can not be more than 3 digits."
                                    },
                                }}
                                render={(field) => (
                                    <TenDigitField
                                        {...field}
                                        label="No. of months"
                                        format="###"
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }} />
                                )}
                            />
                            {errors.long_lived_current_addrs && <p className={`${styles.ErrorM}`}>{errors.long_lived_current_addrs.message}</p>}
                        </FormControl>
                    </div>
                    <div className={`${styles.FormGroupHalf} FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>Current rent<span>*</span></label>
                        <p className={`${styles.FormSubLabel}`}>Current rent per month in USD</p>
                        <FormControl fullWidth>
                            <Controller
                                name="current_rent"
                                control={control}
                                rules={{
                                    required: 'This field is required.',
                                    validate: (value, formValues) => parseInt(value.replace(/,/g, '')) < 10000 || 'Current rent can not be more than 4 digits.'
                                }}
                                render={(field) => (
                                    <MoneyFieldCurrentRent
                                        {...field}
                                        label="Amount"
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }} />
                                )}
                            />
                            {errors.current_rent && <p className={`${styles.ErrorM}`}>{errors.current_rent.message}</p>}
                        </FormControl>
                    </div>

                    <div className={`${styles.AccoFormBUsec}`}>
                        <button type="submit" className={`${styles.applicantNextBU}`}>NEXT</button>
                    </div>

                </div>
            </form>
        </AccordionDetails>
    </Accordion >);
}

export default RentalInfoAccordion;