
const communicationChatBoxOpen = (flag)=> {

    return {
        type: 'open',
        payload: flag
        
    }
}


export  {communicationChatBoxOpen}