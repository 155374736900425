import { combineReducers } from "redux";

import themeReducer from "./themeReducer";
import userInfoReducer from './userInfoReducer'
import prospectReducer from "./prospectCounterReducer";
import communicationChatBoxReducer from "./communicationChatBoxReducer";
import communicationChatReloadReducer from "./communicationChatReloadReducer";
export const rootReducer = combineReducers({
  themeReducer,
  userInfoReducer,
  prospectReducer,
  communicationChatBoxReducer,
  communicationChatReloadReducer
});
