import styles from '../../maintenancerequestdetails.module.css';
import { Link } from "react-router-dom";
import { Star } from "react-feather";
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

const FeedbackTab = ({ feedbacks }) => {
    const [feedbackList, setFeedbackList] = useState([]);

    useEffect(() => {
        if (feedbacks) {
            setFeedbackList(feedbacks);
        }
    }, [feedbacks]);


    return (<div>
        {feedbackList?.map(item => {
            return <div className={`${styles.ReferBodySummCommNotes}`} key={item?.id}>
                <img src={(item?.tenant_image === '') ? "/static/img/avatars/default_avataar.svg" : item?.tenant_image} alt="" />
                <div className={`${styles.ReferBodySummCommBody}`}>
                    <div className={`${styles.NoteListTitle}`}>
                        <p className={`${styles.SummCommBodyName}`}>{item.tenant_name} <span>Tenant</span> <span>Apt - {item.tenant_apt}</span></p>
                    </div>
                    <p className={`${styles.SummCommBodyDate}`}>{dayjs(item?.created_at).fromNow()}</p>
                    <div className={`${styles.RateStarDiv}`}>
                        <Link className={(item.rating >= 1) ? `${styles.StarNote} ${styles.StarNoteActive}` : `${styles.StarNote}`}><Star /></Link>
                        <Link className={(item.rating >= 2) ? `${styles.StarNote} ${styles.StarNoteActive}` : `${styles.StarNote}`}><Star /></Link>
                        <Link className={(item.rating >= 3) ? `${styles.StarNote} ${styles.StarNoteActive}` : `${styles.StarNote}`}><Star /></Link>
                        <Link className={(item.rating >= 4) ? `${styles.StarNote} ${styles.StarNoteActive}` : `${styles.StarNote}`}><Star /></Link>
                        <Link className={(item.rating === 5) ? `${styles.StarNote} ${styles.StarNoteActive}` : `${styles.StarNote}`}><Star /></Link>
                    </div>
                    <div className={`${styles.BorderDivSummComm}`}>
                        <p className={`${styles.SummCommBodyText}`}>{item.description}</p>
                    </div>
                </div>
            </div>
        })}

        {feedbackList.length === 0 && <div className={`${styles.NoDataMain}`}>
            <div className={`${styles.NoDataIMG}`}>
                <img src="/static/img/nodata.svg" alt="No Data" />
            </div>
            <p className={`${styles.NoDataText}`}>No Data Found</p>
        </div>}
    </div>);
}

export default FeedbackTab;