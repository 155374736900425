import { useEffect, useState } from "react";
import TimeSlot from "./TimeSlot";
import dayjs from "dayjs";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

const AddTourTileSlot = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [timeSlot, setTimeSlot] = useState([
    {
      value: "07:00:00",
      time_slot: "7:00 am",
      is_open_house: 0,
      is_scheduled: 0,
      no_of_schedule: 0
    },
    {
      value: "07:30:00",
      time_slot: "7:30 am",
      is_open_house: 0,
      is_scheduled: 0,
      no_of_schedule: 0
    },
    {
      value: "08:00:00",
      time_slot: "8:00 am",
      is_open_house: 0,
      is_scheduled: 0,
      no_of_schedule: 0
    },
    {
      value: "08:30:00",
      time_slot: "8:30 am",
      is_open_house: 0,
      is_scheduled: 0,
      no_of_schedule: 0
    },
    {
      value: "09:00:00",
      time_slot: "9:00 am",
      is_open_house: 0,
      is_scheduled: 0,
      no_of_schedule: 0
    },
    {
      value: "09:30:00",
      time_slot: "9:30 am",
      is_open_house: 0,
      is_scheduled: 0,
      no_of_schedule: 0
    },
    {
      value: "10:00:00",
      time_slot: "10:00 am",
      is_open_house: 0,
      is_scheduled: 0,
      no_of_schedule: 0
    },
    {
      value: "10:30:00",
      time_slot: "10:30 am",
      is_open_house: 0,
      is_scheduled: 0,
      no_of_schedule: 0
    },
    {
      value: "11:00:00",
      time_slot: "11:00 am",
      is_open_house: 0,
      is_scheduled: 0,
      no_of_schedule: 0
    },
    {
      value: "11:30:00",
      time_slot: "11:30 am",
      is_open_house: 0,
      is_scheduled: 0,
      no_of_schedule: 0
    },
    {
      value: "12:00:00",
      time_slot: "12:00 pm",
      is_open_house: 0,
      is_scheduled: 0,
      no_of_schedule: 0
    },
    {
      value: "12:30:00",
      time_slot: "12:30 pm",
      is_open_house: 0,
      is_scheduled: 0,
      no_of_schedule: 0
    },
    {
      value: "13:00:00",
      time_slot: "1:00 pm",
      is_open_house: 0,
      is_scheduled: 0,
      no_of_schedule: 0
    },
    {
      value: "13:30:00",
      time_slot: "1:30 pm",
      is_open_house: 0,
      is_scheduled: 0,
      no_of_schedule: 0
    },
    {
      value: "14:00:00",
      time_slot: "2:00 pm",
      is_open_house: 0,
      is_scheduled: 0,
      no_of_schedule: 0
    },
    {
      value: "14:30:00",
      time_slot: "2:30 pm",
      is_open_house: 0,
      is_scheduled: 0,
      no_of_schedule: 0
    },
    {
      value: "15:00:00",
      time_slot: "3:00 pm",
      is_open_house: 0,
      is_scheduled: 0,
      no_of_schedule: 0
    },
    {
      value: "15:30:00",
      time_slot: "3:30 pm",
      is_open_house: 0,
      is_scheduled: 0,
      no_of_schedule: 0
    },
    {
      value: "16:00:00",
      time_slot: "4:00 pm",
      is_open_house: 0,
      is_scheduled: 0,
      no_of_schedule: 0
    },
    {
      value: "16:30:00",
      time_slot: "4:30 pm",
      is_open_house: 0,
      is_scheduled: 0,
      no_of_schedule: 0
    },
    {
      value: "17:00:00",
      time_slot: "5:00 pm",
      is_open_house: 0,
      is_scheduled: 0,
      no_of_schedule: 0
    },
    {
      value: "17:30:00",
      time_slot: "5:30 pm",
      is_open_house: 0,
      is_scheduled: 0,
      no_of_schedule: 0
    },
    {
      value: "18:00:00",
      time_slot: "6:00 pm",
      is_open_house: 0,
      is_scheduled: 0,
      no_of_schedule: 0
    },
    {
      value: "18:30:00",
      time_slot: "6:30 pm",
      is_open_house: 0,
      is_scheduled: 0,
      no_of_schedule: 0
    },
    {
      value: "19:00:00",
      time_slot: "7:00 pm",
      is_open_house: 0,
      is_scheduled: 0,
      no_of_schedule: 0
    },
    {
      value: "19:30:00",
      time_slot: "7:30 pm",
      is_open_house: 0,
      is_scheduled: 0,
      no_of_schedule: 0
    },
    {
      value: "20:00:00",
      time_slot: "8:00 pm",
      is_open_house: 0,
      is_scheduled: 0,
      no_of_schedule: 0
    },
    {
      value: "20:30:00",
      time_slot: "8:30 pm",
      is_open_house: 0,
      is_scheduled: 0,
      no_of_schedule: 0
    },
    {
      value: "21:00:00",
      time_slot: "9:00 pm",
      is_open_house: 0,
      is_scheduled: 0,
      no_of_schedule: 0
    },
    {
      value: "21:30:00",
      time_slot: "9:30 pm",
      is_open_house: 0,
      is_scheduled: 0,
      no_of_schedule: 0
    },
    {
      value: "22:00:00",
      time_slot: "10:00 pm",
      is_open_house: 0,
      is_scheduled: 0,
      no_of_schedule: 0
    },
    {
      value: "22:30:00",
      time_slot: "10:30 pm",
      is_open_house: 0,
      is_scheduled: 0,
      no_of_schedule: 0
    },
    {
      value: "23:00:00",
      time_slot: "11:00 pm",
      is_open_house: 0,
      is_scheduled: 0,
      no_of_schedule: 0
    },
  ]);

  const [allOpenHouses, setAllOpenHouses] = useState([]);
  const [allSchedules, setAllSchedules] = useState([]);

  useEffect(() => {
    const fetchOpenHouses = async () => {
      let property = 0;
      if (props?.selectProperty !== "") {
        property = props?.selectProperty;
      }
      let selectedDate = dayjs(props?.selectedTourDate).format("YYYY-MM-DD");
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      await axios
        .get(
          process.env.REACT_APP_APPLICANT_API_URL +
          "v1/open-house-by-date/" +
          selectedDate +
          "/" +
          property,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setAllOpenHouses(response.data.data);
        });
    };

    const fetchSchedules = async () => {
      let property = 0;
      if (props?.selectProperty !== "") {
        property = props?.selectProperty;
      }
      let selectedDate = dayjs(props?.selectedTourDate).format("YYYY-MM-DD");
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      await axios
        .get(
          process.env.REACT_APP_APPLICANT_API_URL +
          "v1/schedule-by-date/" +
          selectedDate +
          "/" +
          property,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setAllSchedules(response.data.data);
        });
    };

    fetchOpenHouses();
    fetchSchedules();
  }, [props?.selectedTourDate, props?.selectProperty, getAccessTokenSilently]);

  useEffect(() => {
    let allOpenHouseTimeArr = allOpenHouses?.map((i) => {
      return dayjs(i?.date_time).format("HH:mm:ss");
    });
    setTimeSlot((prev) => {
      return prev.map((i) => {
        if (allOpenHouseTimeArr.length > 0) {
          if (allOpenHouseTimeArr.indexOf(i?.value) > -1) {
            return { ...i, is_open_house: 1 };
          }
        }

        return { ...i, is_open_house: 0 };
      });
    });
  }, [allOpenHouses]);

  useEffect(() => {
    let allSchedulesArr = allSchedules?.map((i) => {
      return dayjs(i?.date_time).format("HH:mm:ss");
    });

    setTimeSlot((prev) => {
      return prev.map((i) => {
        if (allSchedulesArr.length > 0) {
          if (allSchedulesArr.indexOf(i?.value) > -1) {
            let selSchedule = allSchedules.filter(s => i?.value === dayjs(s?.date_time).format("HH:mm:ss"));
            return { ...i, is_scheduled: 1, no_of_schedule: selSchedule[0]?.no_of_schedule };
          }
        }

        return { ...i, is_scheduled: 0, no_of_schedule: 0 };
      });
    });
  }, [allSchedules]);

  return (
    <ul>
      {timeSlot.map(({ value, time_slot, is_open_house, is_scheduled, no_of_schedule }) => {
        return (
          <TimeSlot
            key={value}
            TimeId={value}
            TimeSlot={time_slot}
            GrapTimeId={props?.selectedTourTime}
            SetGrapTimeId={props?.setSelectedTourTime}
            isOpenHouse={is_open_house}
            isScheduled={is_scheduled}
            no_of_schedule={no_of_schedule}
          />
        );
      })}
    </ul>
  );
};

export default AddTourTileSlot;
