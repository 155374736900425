import React, { useEffect, useState } from "react";
import styles from './rental-application-payment.module.css';
import Helmet from "react-helmet";
import axios from 'axios';
import { Link, useHistory } from "react-router-dom";
import { Parallax, Background } from 'react-parallax';
import moment from 'moment';
import { Controller, useForm } from "react-hook-form";
import { FormControl, TextField } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { usePlacesWidget } from "react-google-autocomplete";

const zipRegExp = /\b\d{5}\b/;

const schema = yup.object().shape({
  full_name: yup.string().required('This field is Required').max(50, 'Full name cannot be more than 50 characters'),
  email: yup.string().required('This field is reuired').email('Invalid E-mail'),
  address: yup.string().required('This field is Required').max(50, 'Address cannot be more than 50 characters'),
  address2: yup.string().max(50, 'Address 2 cannot be more than 50 characters'),
  city: yup.string().required('This field is Required').max(50, 'City cannot be more than 50 characters'),
  state: yup.string().required('This field is Required'),
  zip: yup.string().required('This field is Required').matches(zipRegExp, { message: "Please enter a valid zip code", excludeEmptyString: true }),
});

export default function RentalApplicationPayment({ history, match }) {
  const { str } = match.params;
  const redirect = useHistory();
  const [propertyDet, setPropertyDet] = useState(null);
  const [applicantDet, setApplicantDet] = useState(null);
  const [applicationFee, setApplicationFee] = useState(40);

  const { register, handleSubmit, control, errors, setValue, clearErrors } = useForm({
    resolver: yupResolver(schema), mode: 'onChange'
  });

  const { ref: autocompleteRef } = usePlacesWidget({
    apiKey: "AIzaSyDrxtVszbIQ4U8rL90V1lDfy5HvN673fcg",
    options: {
      types: ["address"],
      componentRestrictions: { country: "us" },
      fields: ["address_components", "geometry"],
    },
    onPlaceSelected: (place) => {
      let address = "";
      let city = "";
      let state = "";
      let postcode = "";

      for (let component of place.address_components) {
        let componentType = component.types[0];
        switch (componentType) {
          case "street_number": {
            address = component.long_name;
            break;
          }
          case "route": {
            address += ' ' + component.short_name;
            break;
          }
          case "postal_code": {
            postcode = component.long_name;
            break;
          }
          case "locality":
            city = component.long_name;
            break;
          case "sublocality_level_1":
            city = component.long_name;
            break;
          case "administrative_area_level_3":
            city = component.long_name;
            break;
          case "administrative_area_level_1": {
            state = component.short_name;
            break;
          }
          default:
            break;
        }
      }

      setValue('address', address);
      setValue('city', city);
      setValue('state', state);
      setValue('zip', postcode);
      clearErrors();

    },
  });

  useEffect(() => {
    async function fetchByID() {
      await axios.get(process.env.REACT_APP_APPLICANT_API_URL + 'v1/application-det-payment/' + str
      ).then(response => {
        if (response?.data?.isPaid === 1) {
          redirect.push('/rental-application-success/' + response?.data?.propertyDetails?.id);
        }

        if (typeof (response?.data?.propertyDetails?.rental_application?.application_amount) !== 'undefined') {
          setApplicationFee(parseFloat(response?.data?.propertyDetails?.rental_application?.application_amount));
        }

        setPropertyDet(response?.data?.propertyDetails);
        setApplicantDet(response?.data?.applicantDetails);

        setValue('full_name', response?.data?.applicantDetails?.full_name);
        setValue('email', response?.data?.applicantDetails?.email);
        setValue('address', response?.data?.applicantDetails?.address);
        setValue('address2', response?.data?.applicantDetails?.address2);
        setValue('city', response?.data?.applicantDetails?.city);
        setValue('state', response?.data?.applicantDetails?.state);
        setValue('zip', response?.data?.applicantDetails?.zip);
      }).catch(error => {
        redirect.push('/rental-application-invalid');
      });
    }
    fetchByID();
  }, [str, setValue, redirect]);

  const onSubmit = (data) => {
    data = { ...data, total_amount: applicationFee, prospect_id: applicantDet?.id, property_id: propertyDet?.id, payment_url_token: applicantDet?.payment_url_token };
    axios.post(process.env.REACT_APP_APPLICANT_API_URL + 'v1/card-payment-by-api', data).then(response => {
      window.location.replace(response?.data?.session?.url);
    });
  }

  return (<React.Fragment>
    <Helmet title="Rental Applicant Payment" />

    <Parallax strength={300} className="RentalParallax">
      <Background className="custom-bg">
        <img src="/static/img/rental-app-bg.jpg" alt="fill murray" />
      </Background>
    </Parallax>

    <div className="WhiteBG">

      <div className={`${styles.RentalAppliArea}`}>

        <div className={`${styles.RentalAppliHead}`}>
          <img src={(propertyDet?.app_theme_options && propertyDet?.app_theme_options?.image_url !== '') ? propertyDet?.app_theme_options?.image_url : '/static/img/logo.svg'} alt="logo" />

          <p className={`${styles.RentalAppliHeadTitle}`}>{propertyDet?.name}</p>
          <p className={`${styles.RentalAppliHeadTitle}`}>Application Checkout</p>
        </div>

        <div className={`${styles.ApplicantSec}`}>
          <p className={`${styles.ApplicantSecTitle}`}>Application Fee ${applicationFee.toFixed(2)}</p>

          <form onSubmit={handleSubmit(onSubmit)}>

            <div className={`${styles.PayNowSec}`}>
              <div className={`${styles.BillingInfoTSection}`}>
                <p className={`${styles.PayNowSecTitle}`}>Billing Contact & address</p>
              </div>

              {/*<div className={`${styles.BillingInfoTextSec}`}>
                <p className={`${styles.BillingTextList}`}>{applicantDet?.full_name}</p>
                <p className={`${styles.BillingTextList}`}>{applicantDet?.email}</p>
                <p className={`${styles.BillingTextList}`}>{applicantDet?.address}, {applicantDet?.address2}<br />{applicantDet?.city}, {applicantDet?.state} {applicantDet?.zip}</p>
              </div>*/}

              <div className={`${styles.FormGroupHalf} FormGroup CusRental`}>
                <label className={`${styles.FormLabel}`}>Full Name<span>*</span></label>
                <FormControl fullWidth>
                  <Controller
                    name="full_name"
                    render={(props) => (<TextField {...props} className={`${styles.formControl}`} label="Full name" defaultValue={' '} error={errors?.full_name} />)}
                    control={control}
                    ref={register}
                  />
                  {errors.full_name && <p className={`${styles.ErrorM}`}>{errors.full_name.message}</p>}
                </FormControl>
              </div>

              <div className={`${styles.FormGroupHalf} FormGroup CusRental`}>
                <label className={`${styles.FormLabel}`}>Email<span>*</span></label>
                <FormControl fullWidth>
                  <Controller
                    name="email"
                    render={(props) => (<TextField {...props} className={`${styles.formControl}`} label="Email" defaultValue={' '} error={errors?.email} />)}
                    control={control}
                    ref={register}
                  />
                  {errors.email && <p className={`${styles.ErrorM}`}>{errors.email.message}</p>}
                </FormControl>
              </div>

              <div className={`${styles.FormGroupHalf} FormGroup CusRental`}>
                <label className={`${styles.FormLabel}`}>Address<span>*</span></label>
                <FormControl fullWidth>
                  <Controller
                    name="address"
                    render={(props) => (<TextField onChange={(e) => props.onChange(e)} value={props?.value ? props?.value : ' '} error={errors?.address} inputRef={autocompleteRef} className={`${styles.formControl}`} label="Address" defaultValue={' '} />)}
                    control={control}
                    ref={register}
                  />
                  {errors.address && <p className={`${styles.ErrorM}`}>{errors.address.message}</p>}
                </FormControl>
              </div>

              <div className={`${styles.FormGroupHalf} FormGroup CusRental`}>
                <label className={`${styles.FormLabel}`}>Address 2</label>
                <FormControl fullWidth>
                  <Controller
                    name="address2"
                    render={(props) => (<TextField {...props} className={`${styles.formControl}`} label="Address 2" defaultValue={' '} error={errors?.address2} />)}
                    control={control}
                    ref={register}
                  />
                  {errors.address2 && <p className={`${styles.ErrorM}`}>{errors.address2.message}</p>}
                </FormControl>
              </div>

              <div className={`${styles.FormGroupHalf} FormGroup CusRental`}>
                <label className={`${styles.FormLabel}`}>City<span>*</span></label>
                <FormControl fullWidth>
                  <Controller
                    name="city"
                    render={(props) => (<TextField {...props} value={props?.value ? props?.value : ' '} className={`${styles.formControl}`} label="City" defaultValue={props?.value ? props?.value : ' '} error={errors?.city} />)}
                    control={control}
                    ref={register}
                  />
                  {errors.city && <p className={`${styles.ErrorM}`}>{errors.city.message}</p>}
                </FormControl>
              </div>

              <div className={`${styles.FormGroupHalf} FormGroup CusRental`}>
                <label className={`${styles.FormLabel}`}>State<span>*</span></label>
                <FormControl fullWidth>
                  <Controller
                    name="state"
                    render={(props) => (<TextField {...props} value={props?.value ? props?.value : ' '} className={`${styles.formControl}`} label="State" defaultValue={' '} error={errors?.state} />)}
                    control={control}
                    ref={register}
                  />
                  {errors.state && <p className={`${styles.ErrorM}`}>{errors.state.message}</p>}
                </FormControl>
              </div>

              <div className={`${styles.FormGroupHalf} FormGroup CusRental`}>
                <label className={`${styles.FormLabel}`}>Postal Code<span>*</span></label>
                <FormControl fullWidth>
                  <Controller
                    name="zip"
                    render={(props) => (<TextField {...props} value={props?.value ? props?.value : ' '} className={`${styles.formControl}`} label="Postal Code" defaultValue={' '} error={errors?.zip} />)}
                    control={control}
                    ref={register}
                  />
                  {errors.zip && <p className={`${styles.ErrorM}`}>{errors.zip.message}</p>}
                </FormControl>
              </div>

            </div>

            <button type="submit" className={`${styles.RentallAppliSubmitBU}`}>Pay ${applicationFee.toFixed(2)}</button>
          </form>
        </div>

      </div>

      <div className={`${styles.FooterWrap}`}>
        <div className={`${styles.footerMain}`}>

          <div className={`${styles.footerMainLeft}`}>
            <img src={(propertyDet?.app_theme_options && propertyDet?.app_theme_options?.image_url !== '') ? propertyDet?.app_theme_options?.image_url : '/static/img/logo.svg'} alt="logo" />
          </div>

          <div className={`${styles.footerMainRight}`}>
            <div>
              <h3>CONTACT US</h3>
              <ul>
                <li><a href={"mailto:" + propertyDet?.email}>{propertyDet?.email}</a></li>
                <li><a href={"tel:" + propertyDet?.phone}>{propertyDet?.phone}</a></li>
              </ul>
            </div>
            <div>
              <h3>FIND US</h3>
              <ul>
                <li>{propertyDet?.address} <br />{propertyDet?.city}, {propertyDet?.state} {propertyDet?.zip}</li>
              </ul>
            </div>
            <div>
              <h3>LEGAL</h3>
              <ul>
                <li><Link to={'/privacy-policy'}>Privacy Policy</Link></li>
                <li><Link to={'/eula'}>End User License Agreement</Link></li>
              </ul>
            </div>
            <div>
              <ul>
                <li>PropertyDek © {moment().format("YYYY")}.<br />All rights reserved.</li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    </div>

  </React.Fragment >);
}
