import styles from "../dashboard.module.css";

const LeaseData = ({ data }) => {

    return (
        <div className={`${styles.InsideCard} ${styles.Size45}`}>
            <div className={`${styles.InsideCardFlex}`}>
                <div className={`${styles.PrograssIcon}`}>
                    <img src="/static/img/LeaseIcon.svg" width="35px" height="35px" alt="" />
                </div>
                <div className={`${styles.InsideCardFlexCon}`}>
                    <h3 className={`${styles.InsideCardTitleAmount}`}>{data}</h3>
                    <h6 className={`${styles.InsideCardTitle}`}>Expiring Leases in 90 Days</h6>
                </div>
            </div>
        </div>
    );
}

export default LeaseData;