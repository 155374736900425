import React, { useEffect } from "react";
import moment from 'moment'
import styles from './violationsdetails.module.css';
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Helmet from "react-helmet";
import { Home, MoreVertical, Trash, Edit, X, Search, Plus, ArrowLeft, ChevronDown } from "react-feather";
import { Typography, Box, AppBar, Tabs, Tab, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { Dropdown } from 'react-bootstrap';
import Footer from "../../components/Footer";
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import Loader from "../../components/Loader";
import { TableLoader } from "../../components/LoaderC";
import Select from 'react-select';
import { withAuthenticationRequired, withAuth0 } from "@auth0/auth0-react";
import fileDownload from 'js-file-download';
import { AddLogCallAPI } from "../../components/AddLogs";
import { useAuth0 } from "@auth0/auth0-react";
import UploaderComponent from '../../components/UploaderComponent';

const brandColor = '#cccccc';
const customStyles = {
  control: (base, state) => ({
    ...base,
    boxShadow: state.isFocused ? 0 : 0,
    borderColor: state.isFocused
      ? brandColor
      : base.borderColor,
    '&:hover': {
      borderColor: state.isFocused
        ? brandColor
        : base.borderColor,
    }
  })
};

const schemaNote = yup.object().shape({
  note: yup.string().required('This field is Required').max(200, 'Notes cannot be more than 200 characters'),
});
const schemaFile = yup.object().shape({
  file_name: yup.string().required('This field is Required').max(200, 'File name cannot be more than 200 characters'),
});

const FileMenuToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button ref={ref}
    className={`${styles.FilesUpCardDD} ${styles.NoPadd}`}
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      onClick(e);
    }}>
    {children}
  </button>
));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

function SimpleTabs(props) {
  const { user, isAuthenticated } = useAuth0();
  const { register, handleSubmit, errors, setValue: setNoteValue } = useForm({
    resolver: yupResolver(schemaNote)
  });
  const { register: registerFile, handleSubmit: fileHandeSubmit, errors: errorsFile, setValue: setFileValue } = useForm({
    resolver: yupResolver(schemaFile)
  });

  let alldata = props.itemData;
  let histories = [];
  if (props.itemData.histories) {
    histories = props.itemData.histories;
  }
  let images = [];
  if (props.itemData.images) {
    images = props.itemData.images;
  }
  let files = [];
  if (props.itemData.files) {
    files = props.itemData.files;
  }
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [Loading, setLoading] = React.useState(false);
  useEffect(() => {
    if (props.noteID) {
      // setContentLoading(true);
      async function fetchByID() {
        await axios.get(props.audience + 'v1/violations/' + alldata.id + '/notes/' + props.noteID, {
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        }).then(response => {
          // setContentLoading(false);
          const resData = response.data.data;
          const fields = ['note'];
          fields.forEach(field => setNoteValue(field, resData[field]));

        }).catch(error => {
          if (typeof error.response !== 'undefined')
            toast.error(error.response.data.message);
          //setContentLoading(false);
        });
      }
      fetchByID();
    }


  }, [alldata.id, props.token, props.noteID, props.audience, setNoteValue]);
  useEffect(() => {
    setFileValue('file_name', props.fileName);
    if (props.fileID) {
      async function fetchFileDetByID() {
        await axios.get(props.audience + 'v1/violations/' + alldata.id + '/files/' + props.fileID, {
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        }).then(response => {

          const resFileData = response.data.data;
          const fieldsF = ['file_name'];
          fieldsF.forEach(field => setFileValue(field, resFileData[field]));

        }).catch(error => {
          if (typeof error.response !== 'undefined')
            toast.error(error.response.data.message);
          //setContentLoading(false);
        });
      }

      fetchFileDetByID();

    }

  }, [alldata.id, props.token, props.fileID, props.audience, setFileValue, props.fileName]);

  const filePreviewFunc = async (itemFile) => {
    setLoading(true);
    await axios.get(props.audience + 'v1/violations/' + itemFile.violation_id + '/files/preview/' + itemFile.id, {
      headers: {
        Authorization: `Bearer ${props.token}`,
      }
    }).then(response => {
      setLoading(false);
      if (itemFile.file_type === 'jpg' || itemFile.file_type === 'jpeg' || itemFile.file_type === 'png' || itemFile.file_type === 'pdf') {
        window.open(response.data.file_url, "_blank");
      } else {
        window.open('https://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(response.data.file_url), "_blank");
      }
    }).catch(error => {
      setLoading(false);
      if (typeof error.response !== 'undefined')
        toast.error(error.response.data.message);
    });
  }

  const fileDownloadFunc = async (itemFile, event) => {
    event.stopPropagation();
    setLoading(true);
    await axios.get(props.audience + 'v1/violations/' + itemFile.violation_id + '/files/download/' + itemFile.id, {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${props.token}`,
      }
    }).then(response => {
      setLoading(false);
      fileDownload(response.data, itemFile.file_name);
      toast.success('File downloaded successfully');

      let logData = {
        'title': 'Violation file downloaded',
        'description': [
          'Name: ' + alldata.title,
          'Violation number: ' + alldata.violation_no,
          'Property: ' + alldata.property_name,
          'Apartment: ' + alldata.apt_name,
          'Tenant: ' + alldata.tenant_name,
          'File: ' + itemFile.file_name,

        ]
      }
      AddLogCallAPI(logData, props.token);
    }).catch(error => {
      setLoading(false);
      if (typeof error.response !== 'undefined')
        toast.error(error.response.data.message);
    });
  }
  let tabIndex = -1;
  let tabPanelIndex = -1;

  return (<>
    {Loading && <Loader />}
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          variant="scrollable"
          scrollButtons="auto"
        >

          <Tab label="Summary" {...a11yProps(++tabIndex)} />
          {(isAuthenticated && (user['https://propertydek.com/permissions'].indexOf('violation-note:show') > -1)) && <Tab label="Notes" {...a11yProps(++tabIndex)} />}
          {(isAuthenticated && (user['https://propertydek.com/permissions'].indexOf('violation-file:crud') > -1 || user['https://propertydek.com/permissions'].indexOf('violation-file:show') > -1)) && <Tab label="Files" {...a11yProps(++tabIndex)} />}
          {(isAuthenticated && (user['https://propertydek.com/permissions'].indexOf('violation-history:show') > -1)) && <Tab label="History" {...a11yProps(++tabIndex)} />}
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={++tabPanelIndex}>
        <div className={`${styles.BorderDivSumm}`}>
          <div className={`${styles.Row}`}>
            <div className={`${styles.SummFormFull}`}>

              <p className={`${styles.SummFormLabel}`}>Description</p>
              <p className={`${styles.DesText}`}>{alldata.description}</p>
            </div>
          </div>
        </div>
        <div className={`${styles.FileUpDuContainer}`}>


        </div>
        <div className={`${styles.BorderDivSumm}`}>
          <p className={`${styles.SummFormLabel}`}>Photos</p>

          <div className={`${styles.Row}`}>
            {(isAuthenticated && user['https://propertydek.com/permissions'].indexOf('violation:update') > -1) && <UploaderComponent allowedExtensions={".jpg,.png,.jpeg,.svg"} isMuliple={true} maxFileSize={5242880} uploadUrl={process.env.REACT_APP_CASE_API_URL + 'v1/violations/' + props?.itemData?.id + '/images/upload'} setLoading={setLoading.bind(this)} onUploadLocal={props?.onImageUploadSuccess.bind(this)} buttonText={"Upload Photo"} helpText={"File size upto 5 MB  JPEG, JPG, PNG"} />}
            {images && images.map((itemFile, indexF) => {
              return <div key={indexF} className={`${styles.SummFormSemiHalf}`}>
                <img style={{ cursor: "pointer" }} onClick={props.imageShow.bind(this, itemFile)} src={itemFile.file_public_url} alt="" />
                {(isAuthenticated && user['https://propertydek.com/permissions'].indexOf('violation:update') > -1) && <Button onClick={props.deleteImageModalShow.bind(this, itemFile, indexF)}><X /></Button>}
              </div>
            })}

          </div>

          <Dialog
            open={props.imageDeleteModal}
            fullWidth
            maxWidth="sm"
            onClose={props.delImageModalClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp"
          >
            <DialogTitle id="alert-dialog-title">Delete Image<button onClick={props.delImageModalClose}><X /></button></DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div className="ModalFormGroup">
                  <label className="PopupBodyText">Are you sure you want to delete this file?</label>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.deleteImage} className="SubmitPopupBU">Delete</Button>
              <Button onClick={props.delImageModalClose} className="CancelPopupBU">Cancel</Button>

            </DialogActions>
          </Dialog>
        </div>
      </TabPanel>
      {(isAuthenticated && (user['https://propertydek.com/permissions'].indexOf('violation-note:show') > -1)) && <TabPanel value={value} index={++tabPanelIndex}>
        <div>
          <div className={`${styles.NotesSearchArea}`}>
            <div className={`${styles.NotesSearchMain}`}>
              <input type="text" placeholder="Search" className={`${styles.searchInput}`} onChange={props.searchData.bind(this)} />
              <Search />
            </div>
            {(isAuthenticated && user['https://propertydek.com/permissions'].indexOf('violation-note:create') > -1) && <button onClick={props.handleClickOpen.bind(this, '')} className={`${styles.AddNoteBu}`}><Plus /></button>}
          </div>
          {props.noteModalOpen && !props.noteID && <div className={`${styles.AddCommDiv}`}>
            <form id="myForm" onSubmit={handleSubmit(props.notesubmit.bind(this))}>
              <div className={`${styles.formGroup}`}>

                <textarea placeholder="Add text here..." type="text" className={`${styles.ReferCommentArea}`} name="note" ref={register}></textarea>

                {errors.note && <p className={`${styles.ErrorM}`}>{errors.note.message}</p>}
              </div>
              <Button onClick={props.handleClose2} className={`${styles.ButtonVoiletLine}`}>
                Cancel
              </Button>
              <Button type="submit" className={`${styles.ButtonVoiletSolid}`}>
                {((props.noteID) ? 'Update' : 'Add')}
              </Button>
            </form>
          </div>}

          {props.noteSearchItems().map((itemNote, indexN) => {
            return <div className={`${styles.ReferBodySummCommNotes}`} key={indexN}>
              <img src={(itemNote.user_image === '') ? "/static/img/default_avataar.svg" : itemNote.user_image} alt="" />
              <div className={`${styles.ReferBodySummCommBody}`}>
                <div className={`${styles.NoteListTitle}`}>
                  <p className={`${styles.SummCommBodyName}`}>{itemNote.user_name} <span>{itemNote.role} @{itemNote.property}</span></p>
                  <div className={`${styles.NoteListIcons}`}>
                    {(isAuthenticated && user['https://propertydek.com/permissions'].indexOf('violation-note:update') > -1) && <Dropdown className={`${styles.DropDownMain}`}>
                      <Dropdown.Toggle id="dropdown-basic" className={`${styles.FilesUpCardDD}`}>
                        <MoreVertical />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="DropDownMenuBoxCus">
                        <Dropdown.Item className="DropDListWIcon" onClick={props.handleClickOpen.bind(this, itemNote.id)}><Edit /> Edit</Dropdown.Item>
                        <Dropdown.Item className="DropDListWIcon" onClick={props.noteDeleteModalOpen.bind(this, itemNote.id)}><Trash /> Delete</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>}
                  </div>
                </div>
                <p className={`${styles.SummCommBodyDate}`}>{itemNote.time_str}</p>
                <p className={`${styles.SummCommBodyText}`}>



                  {(props.noteModalOpen && props.noteID && (props.noteID === itemNote.id)) && <div className={`${styles.AddCommDiv}`}>
                    <form id="myForm" onSubmit={handleSubmit(props.notesubmit.bind(this))}>
                      <div className={`${styles.formGroup}`}>

                        <textarea placeholder="Add text here..." type="text" className={`${styles.ReferCommentArea}`} name="note" ref={register}></textarea>

                        {errors.note && <p className={`${styles.ErrorM}`}>{errors.note.message}</p>}
                      </div>
                      <Button onClick={props.handleClose2} className={`${styles.ButtonVoiletLine}`}>
                        Cancel
                      </Button>
                      <Button type="submit" className={`${styles.ButtonVoiletSolid}`}>
                        {((props.noteID) ? 'Update' : 'Add')}
                      </Button>
                    </form>
                  </div>}

                  {(!props.noteID) && itemNote.note}


                </p>
              </div>
            </div>
          })}
          {props.noteSearchItems().length === 0 && <div className={`${styles.NoDataMain}`}>
            <div className={`${styles.NoDataIMG}`}>
              <img src="/static/img/nodata.svg" alt="No Data" />
            </div>
            <p className={`${styles.NoDataText}`}>No Data Found</p></div>}

          <Dialog
            open={props.noteDeleteModal}
            fullWidth
            maxWidth="sm"
            onClose={props.delNoteClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp"
          >

            <DialogTitle id="alert-dialog-title">Delete Note <button onClick={props.delNoteClose}><X /></button></DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div className="ModalFormGroup">
                  <label className="PopupBodyText" >Are you sure you want to delete this item?</label>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.delNote} className="SubmitPopupBU">
                Delete
              </Button>
              <Button onClick={props.delNoteClose} className="CancelPopupBU">
                Cancel
              </Button>
            </DialogActions>

          </Dialog>
        </div>

      </TabPanel>}
      {(isAuthenticated && (user['https://propertydek.com/permissions'].indexOf('violation-file:crud') > -1 || user['https://propertydek.com/permissions'].indexOf('violation-file:show') > -1)) && <TabPanel value={value} index={++tabPanelIndex}>
        <div>


          <div className={`${styles.FileUpDuContainer}`}>
            <h5>Upload Files</h5>

          </div>
          <div className={`${styles.FilesUploadRow}`}>

            {(isAuthenticated && (user['https://propertydek.com/permissions'].indexOf('violation-file:crud') > -1)) && <UploaderComponent allowedExtensions={".pdf,.doc,.docx,.png,.jpg,.jpeg"} isMuliple={true} maxFileSize={5242880} uploadUrl={process.env.REACT_APP_CASE_API_URL + 'v1/violations/' + props?.itemData?.id + '/files/upload'} setLoading={setLoading.bind(this)} onUploadLocal={props?.onFileUploadSuccess.bind(this)} buttonText={"Upload Files"} helpText={"SVG, JPEG, JPG, PNG, PDF, WORD, EXCEL file only"} />}

            {files.map((itemFile, indexF) => {
              let fileSrc = '/static/img/picture.svg';
              if (itemFile.file_type === 'pdf') {
                fileSrc = '/static/img/pdf.svg';
              }
              else if (itemFile.file_type === 'doc' || itemFile.file_type === 'docx') {
                fileSrc = '/static/img/word.svg';
              } else {
                fileSrc = '/static/img/picture.svg';
              }
              return <div key={indexF} className={`${styles.FilesUploadGrid}`}>
                <div className={`${styles.FilesUploadCard}`} style={{ cursor: "pointer" }} onClick={filePreviewFunc.bind(this, itemFile)}>
                  <div className={`${styles.CardIconSec}`}>
                    <img src={fileSrc} alt="" />

                    {(isAuthenticated && (user['https://propertydek.com/permissions'].indexOf('violation-file:crud') > -1)) && <Dropdown className={`${styles.DropDownMainFiles}`}>
                      <Dropdown.Toggle id="dropdown-basic11" className={`${styles.FilesUpCardDD}`} as={FileMenuToggle}>
                        <MoreVertical />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="DropDownMenuBoxCus">
                        <Dropdown.Item className="DropDListWIcon" onClick={props.fileRenameModalOpen.bind(this, itemFile.id)} >Rename</Dropdown.Item>
                        <Dropdown.Item className="DropDListWIcon" onClick={props.fileDeleteModalOpen.bind(this, itemFile.id)}>Delete</Dropdown.Item>
                        <Dropdown.Item className="DropDListWIcon" onClick={fileDownloadFunc.bind(this, itemFile)}>Download</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>}
                  </div>
                  <div className={`${styles.CardConSec}`}>
                    <p>{itemFile.file_name}</p>
                    <p>{itemFile.file_size}</p>
                  </div>
                </div>
              </div>

            })}

            <Dialog
              open={props.fileRenameModal}
              fullWidth
              maxWidth="sm"
              onClose={props.fileRenameModalClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="NewPopUp"
            >
              <form id="myForm" onSubmit={fileHandeSubmit(props.fileRename.bind(this))}>
                <DialogTitle id="alert-dialog-title">File Rename <Button onClick={props.fileRenameModalClose}><X /></Button></DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <div className="ModalFormGroup">
                      <label className={`${styles.FilterLabel}`} >File</label>
                      <input className={`${styles.formControl}`} type="text" name="file_name" ref={registerFile} />

                      {errors.file_name && <p className={`${styles.ErrorM}`}>{errorsFile.file_name.message}</p>}

                    </div>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button type="submit" className="SubmitPopupBU">
                    Rename
                  </Button>
                  <Button onClick={props.fileRenameModalClose} className="CancelPopupBU">
                    Cancel
                  </Button>
                </DialogActions>
              </form>
            </Dialog>

            <Dialog
              open={props.fileDeleteModal}
              fullWidth
              maxWidth="sm"
              onClose={props.fileDelModalClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="NewPopUp"
            >

              <DialogTitle id="alert-dialog-title">Delete File <Button onClick={props.fileDelModalClose}><X /></Button></DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <div className="ModalFormGroup">
                    <label className="PopupBodyText">Are you sure you want to delete this item?</label>
                  </div>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={props.delFile} className="SubmitPopupBU">
                  Delete
                </Button>
                <Button onClick={props.fileDelModalClose} className="CancelPopupBU">
                  Cancel
                </Button>
              </DialogActions>

            </Dialog>

          </div>

        </div>
      </TabPanel>}
      {(isAuthenticated && (user['https://propertydek.com/permissions'].indexOf('violation-history:show') > -1)) && <TabPanel value={value} index={++tabPanelIndex}>
        {histories && histories.map((itemHistory, indexH) => {
          return <div className={`${styles.BorderDivSumm}`} key={indexH}>
            <div className={`${styles.HistoryTitle}`}>
              <img src="/static/img/RollCheck.svg" alt="" />
              <p className={`${styles.HistoryTitleCon}`}>{itemHistory.title} <span>{moment(itemHistory.created_at).format("L")}</span></p>
            </div>
            <div className={`${styles.BorderDivSummHis}`}>
              <p className={`${styles.SummCommBodyText}`} dangerouslySetInnerHTML={{ __html: itemHistory.description }}></p>
            </div>
          </div>
        })}
        {histories.length === 0 && <div className={`${styles.NoDataMain}`}>
          <div className={`${styles.NoDataIMG}`}>
            <img src="/static/img/nodata.svg" alt="No Data" />
          </div>
          <p className={`${styles.NoDataText}`}>No Data Found</p></div>}

      </TabPanel>}
    </div>
  </>
  );
}


class ViolationsDetails extends React.Component {
  constructor({ match, ...props }) {
    super(...arguments);
    this.id = this.props.match.params.id;
    this.state = {
      allData: [], data: [], filterStatusList: [{ value: 0, label: 'Open' }, { value: 1, label: 'In-Progress' }, { value: 2, label: 'Resolved' }, { value: 3, label: 'Cancelled' }], filterPriorityList: [{ value: 1, label: 'Low' }, { value: 2, label: 'Medium' }, { value: 3, label: 'High' }], statSelectHtml: '', prioritySelectHtml: '', noteModalOpen: false, noteID: '', noteDeleteModal: false, deNoteID: '', noteSearchKey: '', fileDeleteModal: false, fileID: '', fileName: '', fileRenameModal: false, imageDeleteModal: false, rowImageID: '', rowArchiveModal: false, rowArchiveID: ''
    };
    this.audience = process.env.REACT_APP_CASE_API_URL;
    this.imageAllowedExtensions = '.jpg,.png,.jpeg,.svg'
    this.imageAsyncSettings = {
      saveUrl: process.env.REACT_APP_CASE_API_URL + 'v1/violations/' + this.props.match.params.id + '/images/upload',
      removeUrl: ''
    };
    this.fileAllowedExtensions = '.pdf,.doc,.docx,.png,.jpg,.jpeg';
    this.fileAsyncSettings = {
      saveUrl: process.env.REACT_APP_CASE_API_URL + 'v1/violations/' + this.props.match.params.id + '/files/upload',
      removeUrl: ''
    };
  }
  async componentDidMount() {
    const { getAccessTokenSilently } = this.props.auth0;
    this.token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
    if (this.id) {
      this.getDetails(this.id);
    }
  }
  async getDetails(fId) {
    try {
      const { user, isAuthenticated } = this.props.auth0;
      this.setState({ ...this.state, contentLoading: true });
      await axios.get(this.audience + 'v1/violations/' + fId, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        }
      }).then(response => {
        let seSelect = this.state.filterStatusList.filter(i => i.value === response.data.data.current_status);
        let statusDrop = <Select styles={customStyles} onChange={this.changeCStatus.bind(this, response.data.data, response.data.data.current_status)} defaultValue={seSelect} options={this.state.filterStatusList} isDisabled={!(isAuthenticated && (user['https://propertydek.com/permissions'].indexOf('violation:update') > -1))} />;
        let sePriority = this.state.filterPriorityList.filter(i => i.value === response.data.data.priority);
        let priorityDrop = <Select styles={customStyles} onChange={this.changePriority.bind(this, response.data.data, response.data.data.priority)} defaultValue={sePriority} options={this.state.filterPriorityList} isDisabled={!(isAuthenticated && (user['https://propertydek.com/permissions'].indexOf('violation:update') > -1))} />;
        this.setState({
          ...this.state, data: response.data.data, statSelectHtml: statusDrop, prioritySelectHtml: priorityDrop, contentLoading: false
        });

      });
    } catch (error) {
      this.setState({ ...this.state, contentLoading: false, contentLoading2: false });
    }
  }

  changeCStatus(data, cVal, e) {
    if (e.value !== cVal) {
      this.setState({ ...this.state, loading: true });
      axios.put(this.audience + 'v1/violations/' + data.id,
        { 'current_status': e.value }, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }).then(response => {
        let newData = this.state.data;
        let respData = response.data.data;

        newData = { ...newData, current_status: e.value };
        toast.success(response.data.message);
        this.setState({ ...this.state, data: newData, loading: false });
        this.getDetails(this.id);
        let statusText = 'Open';
        if (respData.current_status === 1) {
          statusText = 'In-Progress';
        }
        if (respData.current_status === 2) {
          statusText = 'Resolved';
        }
        if (respData.current_status === 3) {
          statusText = 'Cancelled';
        }
        let logData = {
          'title': 'Violation status changed',
          'description': [
            'Name: ' + respData.title,
            'Violation number: ' + respData.violation_no,
            'Property: ' + respData.property_name,
            'Apartment: ' + respData.apt_name,
            'Tenant: ' + respData.tenant_name,
            'Status: ' + statusText,
          ]
        }
        AddLogCallAPI(logData, this.token);

        //  toast.success(response.data.message);
        //  this.setState({ ...this.state, data: response.data.data, loading: false });
      }).catch(error => {
        if (typeof error.response !== 'undefined')
          toast.error(error.response.data.message);
        this.setState({ ...this.state, loading: false });
      });
    }
  }

  changePriority(data, cVal, e) {
    if (e.value !== cVal) {
      this.setState({ ...this.state, loading: true });
      axios.put(this.audience + 'v1/violations/' + data.id,
        { 'priority': e.value }, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }).then(response => {
        let newData = this.state.data;
        let respData = response.data.data;
        newData = { ...newData, priority: e.value };
        toast.success(response.data.message);
        this.setState({ ...this.state, data: newData, loading: false });

        let logData = {
          'title': 'Violation priority changed',
          'description': [
            'Name: ' + respData.title,
            'Violation number: ' + respData.violation_no,
            'Property: ' + respData.property_name,
            'Apartment: ' + respData.apt_name,
            'Tenant: ' + respData.tenant_name,
            'Priority: ' + respData.priority_text,
          ]
        }
        AddLogCallAPI(logData, this.token);
        this.getDetails(this.id);

      }).catch(error => {
        if (typeof error.response !== 'undefined')
          toast.error(error.response.data.message);
        this.setState({ ...this.state, loading: false });
      });
    }
  }
  async changeStatus(data, e) {
    let is_archived = (1 - data.is_archived);
    this.setState({ ...this.state, loading: true });
    axios.put(this.audience + 'v1/violations/' + data.id,
      { 'is_archived': is_archived }, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      }
    }).then(response => {
      let newData = this.state.data;
      let respData = response.data.data;
      newData = { ...newData, is_archived: is_archived };
      toast.success(response.data.message);
      this.setState({ ...this.state, data: newData, loading: false });
      let logData = {
        'title': (is_archived ? 'Violation is archived' : 'Violation is not archived'),
        'description': [
          'Name: ' + respData.title,
          'Violation number: ' + respData.violation_no,
          'Property: ' + respData.property_name,
          'Apartment: ' + respData.apt_name,
          'Tenant: ' + respData.tenant_name,
        ]
      }
      AddLogCallAPI(logData, this.token);
    }).catch(error => {
      if (typeof error.response !== 'undefined')
        toast.error(error.response.data.message);
      this.setState({ ...this.state, loading: false });
    });
  }

  // rowAchive(e) {
  //   e.stopPropagation();
  //   let data1 = [this.state.data].filter(item => item.id === this.state.rowDelID);
  //   let data = data1[0];
  //   let is_archived = (1 - data.is_archived);
  //   this.setState({ ...this.state, loading: true });
  //   axios.put(this.audience + 'v1/violations/' + data.id,
  //     { 'is_archived': is_archived }, {
  //     headers: {
  //       Authorization: `Bearer ${this.token}`,
  //     }
  //   }).then(response => {
  //     let newData = this.state.data;
  //     let respData = this.state.data;
  //     newData = { ...newData, is_archived: is_archived };
  //     toast.success(response.data.message);
  //     this.setState({ ...this.state, data: newData, loading: false, rowDeleteModal: false, rowDelID: '' });
  //     let logData = {
  //       'title': (is_archived ? 'Violation' : 'Violation is not archived'),
  //       'description': [
  //         'Name: ' + respData.title,
  //         'Violation number: ' + respData.violation_no,
  //         'Property: ' + respData.property_name,
  //         'Apartment: ' + respData.apt_name,
  //         'Tenant: ' + respData.tenant_name,
  //       ]
  //     }
  //     AddLogCallAPI(logData, this.token);
  //   }).catch(error => {
  //     if (typeof error.response !== 'undefined')
  //       toast.error(error.response.data.message);
  //     this.setState({ ...this.state, loading: false });
  //   });
  // }


  archiveRowOpen(rowarchiveID, event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ ...this.state, rowArchiveModal: true, rowarchiveID: rowarchiveID });
  }
  archiveModalClose() {
    this.setState({ ...this.state, rowArchiveModal: false, rowarchiveID: '' });
  }

  rowAchive(e) {
    this.setState({ ...this.state, rowArchiveModal: false, loading: true });
    let data1 = [this.state.data].filter(item => item.id === this.state.rowarchiveID);
    let data = data1[0];
    let is_archived = (1 - data.is_archived);
    // this.setState({ ...this.state, loading: true });
    axios.put(this.audience + 'v1/violations/' + data.id,
      { 'is_archived': is_archived }, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      }
    }).then(response => {
      let newData = this.state.data;
      let respData = response.data.data;
      newData = { ...newData, is_archived: is_archived };
      toast.success(response.data.message);
      this.setState({ ...this.state, data: newData, loading: false, rowArchiveModal: false, rowarchiveID: '' });
      let logData = {
        'title': (is_archived ? 'Violation is archived' : 'Violation is unarchived'),
        'description': [
          'Name: ' + respData.title,
          'Violation number: ' + respData.violation_no,
          'Property: ' + respData.property_name,
          'Apartment: ' + respData.apt_name,
          'Tenant: ' + respData.tenant_name,
        ]
      }
      AddLogCallAPI(logData, this.token);
    }).catch(error => {
      if (typeof error.response !== 'undefined')
        toast.error(error.response.data.message);
      this.setState({ ...this.state, loading: false });
    });
  }
  deleteRowOpen(rowDelID, event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ ...this.state, rowDeleteModal: true, rowDelID: rowDelID });
  }
  delModalClose() {
    this.setState({ ...this.state, rowDeleteModal: false, rowDelID: '' });
  }
  rowDelete() {
    this.setState({ ...this.state, rowDeleteModal: false, loading: true });
    return axios.delete(this.audience + 'v1/violations/' + this.state.rowDelID, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      }
    }).then(response => {
      toast.success(response.data.message);
      let respData = response.data.data;
      this.setState({ ...this.state, rowDelID: '', loading: false, rowDeleteModal: false });

      let logData = {
        'title': 'Violation is deleted',
        'description': [
          'Name: ' + respData.title,
          'Violation number: ' + respData.violation_no,
          'Property: ' + respData.property_name,
          'Apartment: ' + respData.apt_name,
          'Tenant: ' + respData.tenant_name,
        ]
      }
      AddLogCallAPI(logData, this.token);
      setTimeout(() => {
        this.props.history.push('/cases/violations');
      }, 2000);
    }).catch(error => {
      if (typeof error.response !== 'undefined')
        toast.error(error.response.data.message);
      this.setState({ ...this.state, loading: false });

    });
  }
  searchData(e) {
    let searchKey = e.target.value;
    searchKey = searchKey.toLowerCase();
    this.setState({ ...this.state, noteSearchKey: searchKey }, this.noteSearchItems);
  }

  noteSearchItems = () => {
    let searchKey = this.state.noteSearchKey;
    let filteredData = [];
    if (this.state.data && this.state.data.notes)
      filteredData = this.state.data.notes;
    if (searchKey !== '') {
      filteredData = filteredData.filter((item) => item.note.toLowerCase().includes(searchKey));
    }
    return filteredData;
  }
  noteDeleteModalOpen(noteID, event) {
    this.setState({ ...this.state, noteDeleteModal: true, deNoteID: noteID });
  }

  delNoteClose() {
    this.setState({ ...this.state, noteDeleteModal: false });
  };

  delNote() {
    this.setState({ ...this.state, noteDeleteModal: false, loading: true });
    let fId = this.state.data.id;
    return axios.delete(this.audience + 'v1/violations/' + fId + '/notes/' + this.state.deNoteID, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      }
    }).then(response => {
      toast.success(response.data.message);
      let allNotes = this.state.data.notes.filter(item => item.id !== this.state.deNoteID);
      let notes = this.state.data.notes.filter(item => item.id === this.state.deNoteID);
      let data = { ...this.state.data, notes: allNotes };
      this.setState({ ...this.state, data: data, noteID: '', loading: false });
      let logData = {
        'title': 'Violation comment is deleted',
        'description': [
          'Name: ' + this.state.data.title,
          'Violation number: ' + this.state.data.violation_no,
          'Property: ' + this.state.data.property_name,
          'Apartment: ' + this.state.data.apt_name,
          'Tenant: ' + this.state.data.tenant_name,
          'Comment: ' + notes[0].note,
        ]
      }
      AddLogCallAPI(logData, this.token);
    }).catch(error => {
      if (typeof error.response !== 'undefined')
        toast.error(error.response.data.message);
      this.setState({ ...this.state, loading: false });

    });
  }
  handleClickOpen(noteID, event) {
    this.setState({ ...this.state, noteModalOpen: true, noteID: noteID });
  };
  handleClose2() {
    this.setState({ ...this.state, noteModalOpen: false, noteID: false });
  };


  notesubmit(data) {
    this.setState({ ...this.state, noteModalOpen: false, loading: true });
    let mrid = this.state.data.id;
    const postData = data;

    //setOpen(false);
    if (!this.state.noteID) {
      return axios.post(this.audience + 'v1/violations/' + mrid + '/notes', postData, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        }
      }).then(response => {
        toast.success(response.data.message);
        let notes = response.data.data;
        let respData = response.data.data;
        let allNotes = this.state.data.notes;
        allNotes = [notes].concat(allNotes);
        let data = { ...this.state.data, notes: allNotes };
        this.setState({ ...this.state, data: data, noteID: '', loading: false });
        let logData = {
          'title': 'Violation new note is created',
          'description': [
            'Name: ' + this.state.data.title,
            'Violation number: ' + this.state.data.violation_no,
            'Property: ' + this.state.data.property_name,
            'Apartment: ' + this.state.data.apt_name,
            'Tenant: ' + this.state.data.tenant_name,
            'Comment: ' + respData.note,
          ]
        }

        AddLogCallAPI(logData, this.token);

      }).catch(error => {
        if (typeof error.response !== 'undefined')
          toast.error(error.response.data.message);
        this.setState({ ...this.state, loading: false });
        // setLoading(false);
      });
    } else {
      return axios.put(this.audience + 'v1/violations/' + mrid + '/notes/' + this.state.noteID, postData, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        }
      }).then(response => {
        toast.success(response.data.message);
        let notes = response.data.data;
        let respData = response.data.data;
        let allNotes = this.state.data.notes.map(el => (
          el.id === this.state.noteID ? { ...el, note: notes.note } : el
        ))
        let data = { ...this.state.data, notes: allNotes };
        this.setState({ ...this.state, data: data, noteID: '', loading: false });

        let logData = {
          'title': 'Violation note is updated',
          'description': [
            'Name: ' + this.state.data.title,
            'Violation number: ' + this.state.data.violation_no,
            'Property: ' + this.state.data.property_name,
            'Apartment: ' + this.state.data.apt_name,
            'Tenant: ' + this.state.data.tenant_name,
            'Comment: ' + respData.note,
          ]
        }
        AddLogCallAPI(logData, this.token);
      }).catch(error => {
        if (typeof error.response !== 'undefined')
          toast.error(error.response.data.message);
        this.setState({ ...this.state, loading: false });
        // setLoading(false);
      });
    }

  };
  imageUploadButtonClick() {
    document.getElementById('imageUploadID').getElementsByClassName('e-file-select-wrap')[0].querySelector('button').click();
    return false;
  }
  onImageSelected(args) {
    if (args.filesData[0].statusCode === '0') {
      if (args.filesData[0].validationMessages.maxSize === '') {
        toast.error(args.filesData[0].status);
      } else {
        toast.error('The image size should not be greater than 5MB.');
      }
    }
  }
  onImageUpload(args) {
    if (args.file.statusCode === "0") {
      toast.error(args.statusText);
    }
    this.setState({ ...this.state, loading: false });
  }
  onImageProgress(args) {
    this.setState({ ...this.state, loading: true });
  }
  async onImageUploadSuccess(files) {
    let all_files = this.state.data.images;
    all_files = files.concat(all_files);
    let data = { ...this.state.data, images: [] };
    this.setState({ ...this.state, data: data });
    let data2 = { ...this.state.data, images: all_files };
    this.setState({ ...this.state, data: data2 });


    toast.success('Files uploaded successfully.');
    let logData = {
      'title': 'Violation image uploaded',
      'description': [
        'Name: ' + this.state.data.title,
        'Violation number: ' + this.state.data.violation_no,
        'Property: ' + this.state.data.property_name,
        'Apartment: ' + this.state.data.apt_name,
        'Tenant: ' + this.state.data.tenant_name,

      ]
    }
    AddLogCallAPI(logData, this.token);
  }
  deleteImageModalShow(rowImageID, event) {
    //event.preventDefault();
    //event.stopPropagation();
    this.setState({ ...this.state, imageDeleteModal: true, rowImageID: rowImageID.id });
  }
  delImageModalClose() {
    this.setState({ ...this.state, imageDeleteModal: false, rowImageID: '' });
  }
  deleteImage() {
    this.setState({ ...this.state, loading: true });

    return axios.delete(this.audience + 'v1/violations/' + this.state.data.id + '/images/' + this.state.rowImageID, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      }
    }).then(response => {
      toast.success(response.data.message);
      let all_files = this.state.data.images.filter(item => item.id !== this.state.rowImageID);
      let data = { ...this.state.data, images: all_files };
      this.setState({ ...this.state, data: data, loading: false, imageDeleteModal: false, rowImageID: '' });
      let logData = {

        'title': 'Violation image deleted',
        'description': [
          'Name: ' + this.state.data.title,
          'Violation number: ' + this.state.data.violation_no,
          'Property: ' + this.state.data.property_name,
          'Apartment: ' + this.state.data.apt_name,
          'Tenant: ' + this.state.data.tenant_name,

        ]
      }
      AddLogCallAPI(logData, this.token);
    }).catch(error => {
      if (typeof error.response !== 'undefined')
        toast.error(error.response.data.message);
      this.setState({ ...this.state, loading: false });

    });
  }
  /* deleteImage(itemData, index) {
     this.setState({ ...this.state, loading: true });
 
     return axios.delete(this.audience + 'v1/violations/' + itemData.violation_id + '/images/' + itemData.id, {
       headers: {
         Authorization: `Bearer ${this.token}`,
       }
     }).then(response => {
       toast.success(response.data.message);
       let all_files = this.state.data.images.filter(item => item.id !== itemData.id);
       let data = { ...this.state.data, images: all_files };
       this.setState({ ...this.state, data: data, loading: false });
       this.setState({ ...this.state, data: data, loading: false });
       let logData = {
 
         'title': 'Violation image deleted',
         'description': [
           'Name: ' + this.state.data.title,
           'Violation number: ' + this.state.data.violation_no,
           'Property: ' + this.state.data.property_name,
           'Apartment: ' + this.state.data.apt_name,
           'Tenant: ' + this.state.data.tenant_name,
 
         ]
       }
       AddLogCallAPI(logData, this.token);
     }).catch(error => {
       if (typeof error.response !== 'undefined')
         toast.error(error.response.data.message);
 
     });
   }*/
  imageShow(data, e) {
    window.open(data.file_public_url, "_blank");
  }

  fileUploadButtonClick() {

    document.getElementById('fileUploadID').getElementsByClassName('e-file-select-wrap')[0].querySelector('button').click();

    return false;
  }
  onFileSelected(args) {

    if (args.filesData[0].statusCode === '0') {
      if (args.filesData[0].validationMessages.maxSize === '') {
        toast.error(args.filesData[0].status);
      } else {
        toast.error('The image size should not be greater than 5MB.');
      }
    }
  }
  onFileFileUpload(args) {
    if (args.file.statusCode === "0") {
      toast.error(args.statusText);
    }
    this.setState({ ...this.state, loading: false });
  }
  onFileFileProgress(args) {

    this.setState({ ...this.state, loading: true });
  }
  async onFileUploadSuccess(files) {
    let all_files = this.state.data.files;
    all_files = [files].concat(all_files);
    let data = { ...this.state.data, files: [] };
    this.setState({ ...this.state, data: data });
    let data2 = { ...this.state.data, files: all_files };
    this.setState({ ...this.state, data: data2 });
    let logData = {
      'title': 'Violation file uploaded',
      'description': [
        'Name: ' + this.state.data.title,
        'Violation number: ' + this.state.data.violation_no,
        'Property: ' + this.state.data.property_name,
        'Apartment: ' + this.state.data.apt_name,
        'Tenant: ' + this.state.data.tenant_name,
        'File: ' + files.file_name,

      ]
    }
    AddLogCallAPI(logData, this.token);


    toast.success('Files uploaded successfully.');

  }
  fileRenameModalOpen(fileID, event) {
    event.stopPropagation();
    this.setState({ ...this.state, fileRenameModal: false, loading: true });
    let violationId = this.state.data.id;


    axios.get(this.audience + 'v1/violations/' + violationId + '/files/' + fileID, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      }
    }).then(response => {

      const resFileData = response.data.data;
      if (resFileData.file_name) {
        this.setState({ ...this.state, fileRenameModal: true, fileID: fileID, fileName: resFileData.file_name, loading: false });
      }

      // fieldsF.forEach(field => setFileValue(field, resFileData[field]));

    }).catch(error => {
      if (typeof error.response !== 'undefined')
        toast.error(error.response.data.message);
      this.setState({ ...this.state, loading: false });
      //setContentLoading(false);
    });

  }
  fileRenameModalClose() {
    this.setState({ ...this.state, fileRenameModal: false, fileID: '', fileName: '' });
    //setOpen(false);
  };
  fileRename(data) {
    this.setState({ ...this.state, fileRenameModal: false, loading: true });
    const postData = data;
    let violationId = this.state.data.id;
    return axios.put(this.audience + 'v1/violations/' + violationId + '/files/' + this.state.fileID, postData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      }
    }).then(response => {
      toast.success(response.data.message);
      let files = response.data.data;


      let allFiles = this.state.data.files.map(el => (
        el.id === this.state.fileID ? { ...el, file_name: files.file_name } : el
      ))
      let data = { ...this.state.data, files: allFiles };
      this.setState({ ...this.state, data: data, fileID: '', fileName: '', loading: false });
      let logData = {
        'title': 'Violation file renamed',
        'description': [
          'Name: ' + this.state.data.title,
          'Violation number: ' + this.state.data.violation_no,
          'Property: ' + this.state.data.property_name,
          'Apartment: ' + this.state.data.apt_name,
          'Tenant: ' + this.state.data.tenant_name,
          'File: ' + files.file_name,

        ]
      }
      AddLogCallAPI(logData, this.token);
    }).catch(error => {
      if (typeof error.response !== 'undefined')
        toast.error(error.response.data.message);
      this.setState({ ...this.state, loading: false });
      // setLoading(false);
    });
  }
  fileDeleteModalOpen(fileID, event) {
    event.stopPropagation();
    this.setState({ ...this.state, fileDeleteModal: true, fileID: fileID });
  }
  fileDelModalClose() {
    this.setState({ ...this.state, fileDeleteModal: false });
    //setOpen(false);
  };
  delFile() {
    this.setState({ ...this.state, fileDeleteModal: false, loading: true });
    let violationId = this.state.data.id;
    return axios.delete(this.audience + 'v1/violations/' + violationId + '/files/' + this.state.fileID, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      }
    }).then(response => {

      toast.success(response.data.message);

      let allFiles = this.state.data.files.filter(item => item.id !== this.state.fileID);
      let files = this.state.data.files.filter(item => item.id === this.state.fileID);
      let data = { ...this.state.data, files: allFiles };
      this.setState({ ...this.state, data: data, fileID: '', loading: false });
      let logData = {

        'title': 'Violation file deleted',
        'description': [
          'Name: ' + this.state.data.title,
          'Violation number: ' + this.state.data.violation_no,
          'Property: ' + this.state.data.property_name,
          'Apartment: ' + this.state.data.apt_name,
          'Tenant: ' + this.state.data.tenant_name,
          'File: ' + files[0].file_name,


        ]
      }
      AddLogCallAPI(logData, this.token);
    }).catch(error => {
      if (typeof error.response !== 'undefined')
        toast.error(error.response.data.message);
      this.setState({ ...this.state, loading: false });

    });
  }
  render() {
    const { user, isAuthenticated } = this.props.auth0;
    return (<React.Fragment>
      {this.state.loading && <Loader />}
      {this.state.contentLoading && <div className={`${styles.LoaderCard}`}><div className="Loader TableLoader"><TableLoader /></div></div>}
      {!this.state.contentLoading && <Helmet title={this.state.data.title} />}
      <div className={`${styles.pageTitleWrap}`}>
        <div>
          <button onClick={() => this.props.history.goBack()} className={`${styles.BackArrow}`}><ArrowLeft /> Back</button>
          <Typography display="inline" className={`${styles.pageTitle}`}>{this.state.data.title}</Typography>
        </div>

        <nav aria-label="breadcrumb">
          <ol className={`${styles.breadcrumb}`}>
            <li className={`${styles.breadcrumbItem}`}><span><Home /></span>Cases</li>
            <li className={`${styles.breadcrumbItem}`}><Link to={'/cases/violations'} >Violation</Link></li>
            <li className={`${styles.breadcrumbItem} ${styles.active}`}>{this.state.data.title}</li>
          </ol>
        </nav>
      </div>

      <div className={`${styles.mainCard}`}>

        <div className={`${styles.SMReferDeBigDiv}`}>
          <div className={`${styles.Card}`}>

            <div className={`${styles.ActionRefMain}`}>
              <div className={`${styles.TitleSummary}`}><p className={`${styles.ReqDeTitle}`}>{this.state.data.title}</p>

                {this.state.data.overdue && <p className={`${styles.TagDiv} ${styles.Red}`}>{this.state.data.overdue}</p>}

              </div>

              <div className={`${styles.InsideConCheck}`}>
                {(isAuthenticated && (user['https://propertydek.com/permissions'].indexOf('violation:update') > -1 || user['https://propertydek.com/permissions'].indexOf('violation:delete') > -1)) && <Dropdown>
                  <Dropdown.Toggle className={`${styles.ActionsBU} ActionsBU`} id="dropdown-basic">
                    Actions <ChevronDown />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className={`${styles.ActionsBUList} ActionsBUList`}>
                    {(isAuthenticated && (user['https://propertydek.com/permissions'].indexOf('violation:update') > -1)) && <><Dropdown.Item className={`${styles.ActionsBUListItem}`} as={Link} to={`/cases/violations/edit/${this.id}`}>Edit</Dropdown.Item>
                      <Dropdown.Item className={`${styles.ActionsBUListItem}`} onClick={this.archiveRowOpen.bind(this, this.state.data.id)}>{this.state.data.is_archived ? 'Unarchive' : 'Archive'}</Dropdown.Item></>}
                    {(isAuthenticated && (user['https://propertydek.com/permissions'].indexOf('violation:delete') > -1)) && <Dropdown.Item className={`${styles.ActionsBUListItem} ${styles.Delete}`} onClick={this.deleteRowOpen.bind(this, this.state.data.id)}>Delete</Dropdown.Item>}
                  </Dropdown.Menu>
                </Dropdown>}
              </div>
            </div>
            <div className={`${styles.SMBigSideBody} TabMainClientProfile ClientDetails`}>
              <SimpleTabs audience={this.audience} token={this.token} itemState={this.state} itemData={this.state.data} searchData={this.searchData.bind(this)} noteSearchItems={this.noteSearchItems.bind(this)} notesubmit={this.notesubmit.bind(this)} delNote={this.delNote.bind(this)} noteDeleteModal={this.state.noteDeleteModal} noteDeleteModalOpen={this.noteDeleteModalOpen.bind(this)} delNoteClose={this.delNoteClose.bind(this)} deNoteID={this.state.deNoteID} noteID={this.state.noteID} noteModalOpen={this.state.noteModalOpen} handleClickOpen={this.handleClickOpen.bind(this)} handleClose2={this.handleClose2.bind(this)} imageUploadButtonClick={this.imageUploadButtonClick.bind(this)} imageAsyncSettings={this.imageAsyncSettings} imageAllowedExtensions={this.imageAllowedExtensions} onImageUploadSuccess={this.onImageUploadSuccess.bind(this)} onImageUpload={this.onImageUpload.bind(this)} onImageProgress={this.onImageProgress.bind(this)} imageShow={this.imageShow.bind(this)} imageDeleteModal={this.state.imageDeleteModal} rowImageID={this.state.rowImageID} deleteImageModalShow={this.deleteImageModalShow.bind(this)} delImageModalClose={this.delImageModalClose.bind(this)} deleteImage={this.deleteImage.bind(this)} onImageSelected={this.onImageSelected.bind(this)} fileUploadButtonClick={this.fileUploadButtonClick.bind(this)} fileRenameModal={this.state.fileRenameModal} fileRenameModalOpen={this.fileRenameModalOpen.bind(this)} fileRenameModalClose={this.fileRenameModalClose.bind(this)} fileRename={this.fileRename.bind(this)}
                fileID={this.state.fileID} fileName={this.state.fileName} fileDeleteModal={this.state.fileDeleteModal} fileDeleteModalOpen={this.fileDeleteModalOpen.bind(this)} fileDelModalClose={this.fileDelModalClose.bind(this)} delFile={this.delFile.bind(this)} fileAsyncSettings={this.fileAsyncSettings} fileAllowedExtensions={this.fileAllowedExtensions} onFileSelected={this.onFileSelected.bind(this)} onFileUploadSuccess={this.onFileUploadSuccess.bind(this)} onFileFileUpload={this.onFileFileUpload.bind(this)} onFileFileProgress={this.onFileFileProgress.bind(this)
                } />
            </div>
          </div>
        </div>

        <div className={`${styles.SMProCusSmallDiv}`}>
          <div className={`${styles.Card}`}>
            <ul className={`${styles.CaseSummSideUL}`}>
              <li>
                <p className={`${styles.ULtitle}`}>Tenant</p>
                <p className={`${styles.ULData}`}><Link to={`/people/tenants/details/${this.state.data.tenant_id}`}>{this.state.data.tenant_name}</Link></p>
              </li>
              <li className={`${styles.Dropdown}`}>
                <p className={`${styles.ULtitle}`}>Priority</p>
                {this.state.prioritySelectHtml}
              </li>
              <li className={`${styles.Dropdown}`}>
                <p className={`${styles.ULtitle}`}>Status</p>
                {this.state.statSelectHtml}
              </li>
              <li>
                <p className={`${styles.ULtitle}`}>Priority</p>
                <p className={`${styles.ULData}`}>{this.state.data.priority_text}</p>
              </li>
              <li>
                <p className={`${styles.ULtitle}`}>Issued Date</p>
                <p className={`${styles.ULData}`}>{moment(this.state.data.created_at).format("L")}</p>
              </li>
              <li>
                <p className={`${styles.ULtitle}`}>Property</p>
                <p className={`${styles.ULData}`}>{this.state.data.property_name}</p>
              </li>
              <li>
                <p className={`${styles.ULtitle}`}>Violation Number</p>
                <p className={`${styles.ULData}`}>{this.state.data.violation_no}</p>
              </li>
            </ul>
          </div>
        </div>

      </div>
      <Dialog
        open={this.state.rowArchiveModal}
        fullWidth
        maxWidth="sm"
        onClose={this.archiveModalClose.bind(this)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="NewPopUp"
      >
        <DialogTitle id="alert-dialog-title">Are you sure you want to {this.state.data.is_archived ? 'unarchive' : 'archive'} the Violation Record?<button onClick={this.archiveModalClose.bind(this)}><X /></button></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="ModalFormGroup">
              <label className="PopupBodyText">{this.state.data.is_archived ? 'The Violation Record will be unarchived immediately.You can archive it at any time.' : 'The Violation Record will be archived immediately.You can unarchive it at any time.'}</label>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.rowAchive.bind(this)} className="SubmitPopupBU">{this.state.data.is_archived ? 'Unarchive' : 'Archive'}</Button>
          <Button onClick={this.archiveModalClose.bind(this)} className="CancelPopupBU">Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={this.state.rowDeleteModal}
        fullWidth
        maxWidth="sm"
        onClose={this.delModalClose.bind(this)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="NewPopUp"
      >
        <DialogTitle id="alert-dialog-title">Are you sure you want to delete the Violation Record?<button onClick={this.delModalClose.bind(this)}><X /></button></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="ModalFormGroup">
              <label className="PopupBodyText">The Violation Record will be removed immediately.You can't undo this action.</label>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.rowDelete.bind(this)} className="SubmitPopupBU">Delete</Button>
          <Button onClick={this.delModalClose.bind(this)} className="CancelPopupBU">Cancel</Button>
        </DialogActions>
      </Dialog>

      <Footer />
    </React.Fragment>);
  }

}

//export default ViolationsDetails;
export default withAuthenticationRequired(withAuth0(ViolationsDetails), {
  onRedirecting: () => <Loader />,
});