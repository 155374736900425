import { ArrowRight, Check, ChevronDown, Eye, EyeOff } from 'react-feather';
import styles from '../rentalappplication.module.css';
import { Accordion, AccordionDetails, AccordionSummary, FormControl, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import NumberFormat from 'react-number-format';
import axios from 'axios';

const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const socialsecurityRegExpNew = /\b\d{3}-\d{2}-\d{4}\b/;

const format = (val) => {
    if (val === "") return "";
    let s1 = val.substring(0, 3);
    let s2 = val.substring(3, 5);
    let s3 = val.substring(5, 9);

    if (s3.length) {
        return `${s1}-${s2}-${s3}`;
    } else if (s2.length) {
        return `${s1}-${s2}`;
    } else {
        return `${s1}`;
    }
};

const NineDigitField = React.forwardRef((props, ref) => {
    return <NumberFormat
        customInput={TextField}
        prefix=""
        displayType="input"
        format={format}
        thousandSeparator={false}
        decimalScale={0} {...props} />;
});

const PhoneField = React.forwardRef((props, ref) => {
    return <NumberFormat
        customInput={TextField}
        prefix=""
        displayType="input"
        type="tel"
        format={"(###) ###-####"}
        {...props} />;
});

const PersonalInfoAccordion = ({ expanded, setStepData, stepData, accordinChange, lastStep, setLastStep, setCurrentStep, getNextStep, setPanelExpanded, panelRef, applicantId, setApplicantId, propertyId, applicantN }) => {
    const [ssntype1, setSsntype1] = React.useState('password');
    const [tokeninputshow, setTokeninputshow] = useState(false);
    const [verifyIstrue, setVerifyIstrue] = useState(false);
    const [phoneVerified, setPhoneVerified] = useState(false);
    const [tokenError, setTokenError] = useState('');
    const [otpError, setOtpError] = useState('');
    const [isPhoneReadOnly, setIsPhoneReadOnly] = useState(false);
    const [otpSuccess, setOtpSuccess] = useState('');
    const [tokenSuccess, setTokenSuccess] = useState('');
    const [tokenVerifing, setTokenVerifing] = useState(false);

    const { handleSubmit, control, setValue, formState: { errors, isSubmitted }, getValues, clearErrors } = useForm({
        mode: "onChange",
        defaultValues: {
            first_name: "",
            middle_name: "",
            last_name: "",
            birth_date: null,
            driver_license: "",
            social_security_number: "",
            email: "",
            phone: ""
        }
    });

    useEffect(() => {
        if (stepData) {
            if (stepData?.first_name) {
                setValue('first_name', stepData?.first_name);
            }
            if (stepData?.middle_name) {
                setValue('middle_name', stepData?.middle_name);
            }
            if (stepData?.last_name) {
                setValue('last_name', stepData?.last_name);
            }
            if (stepData?.birth_date) {
                setValue('birth_date', dayjs(stepData?.birth_date));
            }
            if (stepData?.driver_license) {
                setValue('driver_license', stepData?.driver_license);
            }
            if (stepData?.social_security_number) {
                setValue('social_security_number', stepData?.social_security_number);
            }
            if (stepData?.email) {
                setValue('email', stepData?.email);
            }
            if (stepData?.phone) {
                setValue('phone', stepData?.phone);
                setIsPhoneReadOnly(true);
                setPhoneVerified(true);
            }
        }
    }, [stepData, setValue]);

    const checkEmailIsUnique = (phone) => {
        if (applicantId) {
            return axios.get(process.env.REACT_APP_APPLICANT_API_URL + 'v1/check-phone-new-2/' + phone + '/' + applicantId);
        } else {
            return axios.get(process.env.REACT_APP_APPLICANT_API_URL + 'v1/check-phone-new-2/' + phone);
        }
    }

    const phoneIsUnique = async (phone) => {
        const isEmailUnique = await checkEmailIsUnique(phone);

        if (isEmailUnique.data.status === true) {
            if (!tokeninputshow) {
                setVerifyIstrue(true);
            } else {
                setVerifyIstrue(false);
            }
        } else {
            setVerifyIstrue(false);
        }

        return isEmailUnique?.data?.status;
    };

    const checkPhoneVerified = async () => {
        return phoneVerified === true;
    }

    const sendToken = () => {
        clearErrors('phone');
        setTokenError('');
        setTokenSuccess('');
        const postData = { phone: getValues('phone') };
        axios.post(process.env.REACT_APP_APPLICANT_API_URL + 'v1/send-phone-token', postData).then(response => {
            setTokeninputshow(true);
            setVerifyIstrue(false);
            setTokenSuccess(response?.data?.message);
            setTimeout(function () {
                setTokenSuccess('');
            }, 3000)
        }).catch(error => {
            setTokenError(error?.response?.data?.message);
            clearErrors('phone');
        });
    }

    const resendToken = (event) => {
        setTokenError('');
        setTokenSuccess('');
        const postData = { phone: getValues('phone') };
        axios.post(process.env.REACT_APP_APPLICANT_API_URL + 'v1/send-phone-token', postData).then(response => {
            setTokenSuccess(response?.data?.message);
            setTimeout(function () {
                setTokenSuccess('');
            }, 3000)
        }).catch(error => {
            setTokenError(error?.response?.data?.message);
        });
    }

    const enterOTP = (event) => {
        clearErrors('phone');
        setOtpError('');
        setOtpSuccess('');

        setTokenError('');
        setTokenSuccess('');
        if (!tokenVerifing) {
            let otpVal = event.target.value;
            otpVal = otpVal.trim();
            if (otpVal.length < 6) {
                setOtpError('Token is invalid');
            }
            if (otpVal.length === 6) {
                setTokenVerifing(true);
                const postData = { phone: getValues('phone'), token: otpVal };
                axios.post(process.env.REACT_APP_APPLICANT_API_URL + 'v1/verify-phone-token', postData).then(response => {
                    let status = response.data.status;
                    setTokenVerifing(false);
                    if (status) {
                        setPhoneVerified(true);
                        setOtpSuccess(response.data.message);
                        setTimeout(function () {
                            setOtpSuccess('');
                        }, 3000)
                        setIsPhoneReadOnly(true);
                    } else {
                        setOtpError(response.data.message);
                        setIsPhoneReadOnly(false);
                    }
                }).catch(error => {
                    setTokenVerifing(false);
                    setOtpError('');
                });
            }
        }
    }

    const phoneBlankCheck = (event) => {
        setIsPhoneReadOnly(false);
        setTokeninputshow(false);
        setVerifyIstrue(false);
        setTokenError('');
        setTokenSuccess('');
        setOtpSuccess('');
        setOtpError('');
        setPhoneVerified(false);
        if (event.target.value === '') {
            setValue('phone', '');
        } else {
            setValue('phone', event.target.value);
        }
    }

    const onSubmit = (data) => {
        data = { ...data, birth_date: dayjs(data?.birth_date).format('YYYY-MM-DD') };

        let postData = { ...stepData, ...data };
        postData = { ...postData, property_id: propertyId, is_submitted: 1, is_personal_info_submitted: 1, CurrentStep: 2 };
        setStepData(prev => {
            return { ...prev, ...postData };
        });

        if (applicantId) {
            axios.post(process.env.REACT_APP_APPLICANT_API_URL + 'v1/save-applicants/' + applicantId, postData);
        } else {
            postData = { ...postData, is_primary: 1 };
            axios.post(process.env.REACT_APP_APPLICANT_API_URL + 'v1/save-applicants', postData).then(response => {
                setApplicantId(response?.data?.data?.id);
                axios.post(process.env.REACT_APP_APPLICANT_API_URL + 'v1/save-applicants/' + response?.data?.data?.id, { phone: response?.data?.data?.phone });
            });
        }

        let currentStepTemp = getNextStep(2);
        setCurrentStep(currentStepTemp);
        setPanelExpanded('panel' + currentStepTemp);
        if (lastStep < currentStepTemp) {
            setLastStep(currentStepTemp);
        }
    }

    return (<Accordion expanded={expanded === 'panel2'} onChange={accordinChange('panel2', 2)} disabled={lastStep < 2}>
        <AccordionSummary expandIcon={<ChevronDown />} aria-controls="panel1bh-content" id="panel1bh-header" ref={panelRef}>
            <div className={`${styles.FormAccoHead}`}>
                <p className={`${styles.FormAccoHeadTitle}`}>Personal information</p>
                <div className={((lastStep > 2) ? `${styles.AccoCheck} ${styles.Active}` : `${styles.AccoCheck}`)}><Check /></div>
            </div>
        </AccordionSummary>
        <AccordionDetails className={`${styles.CussAccoRow}`}>
            <p className={`${styles.DisclamerText}`}>By entering your phone number and clicking “Verify”, you consent to receiving calls and texts on behalf of Weston Hall via automatic dialing or other technology about apartment related notifications. Your consent is not required to enter into a rental transaction or make any purchase. Reply STOP to cancel any time.</p>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={`${styles.AccoFormSec}`}>

                    <div className={`${styles.FormGroupHalf} FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>First Name<span>*</span></label>
                        <FormControl fullWidth>
                            <Controller
                                name="first_name"
                                control={control}
                                rules={{
                                    required: "This field is required.",
                                    maxLength: {
                                        value: 50,
                                        message: "First name cannot be more than 50 characters."
                                    }
                                }}
                                render={(field) => (
                                    <TextField
                                        {...field}
                                        label="First Name"
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }} />
                                )}
                            />
                            {errors.first_name && <p className={`${styles.ErrorM}`}>{errors.first_name.message}</p>}
                        </FormControl>
                    </div>
                    <div className={`${styles.FormGroupHalf} FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>Middle initial</label>
                        <FormControl fullWidth>
                            <Controller
                                name="middle_name"
                                control={control}
                                rules={{
                                    maxLength: {
                                        value: 50,
                                        message: "Middle initial cannot be more than 50 characters."
                                    }
                                }}
                                render={(field) => (
                                    <TextField
                                        {...field}
                                        label="Middle initial"
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }} />
                                )}
                            />
                            {errors.middle_name && <p className={`${styles.ErrorM}`}>{errors.middle_name.message}</p>}
                        </FormControl>
                    </div>

                    <div className={`${styles.FormGroupHalf} FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>Last Name<span>*</span></label>
                        <FormControl fullWidth>
                            <Controller
                                name="last_name"
                                control={control}
                                rules={{
                                    required: "This field is required.",
                                    maxLength: {
                                        value: 50,
                                        message: "Last name cannot be more than 50 characters."
                                    }
                                }}
                                render={(field) => (
                                    <TextField
                                        {...field}
                                        label="Last name"
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }} />
                                )}
                            />
                            {errors.last_name && <p className={`${styles.ErrorM}`}>{errors.last_name.message}</p>}
                        </FormControl>
                    </div>
                    <div className={`${styles.FormGroupHalf} FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>Birth date<span>*</span></label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Controller
                                name="birth_date"
                                control={control}
                                rules={{ required: 'This field is required.' }}
                                render={(field) => (
                                    <DatePicker
                                        {...field}
                                        renderInput={(params) => (
                                            <TextField {...params} />
                                        )}
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                        {errors.birth_date && <p className={`${styles.ErrorM}`}>{errors.birth_date.message}</p>}
                    </div>

                    <div className={`${styles.FormGroupHalf} FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>Driver's license or ID #<span>*</span></label>
                        <FormControl fullWidth>
                            <Controller
                                name="driver_license"
                                control={control}
                                rules={{
                                    required: "This field is required.",
                                    maxLength: {
                                        value: 15,
                                        message: "This field cannot be more than 15 characters."
                                    },
                                    pattern: {
                                        value: /^[0-9a-zA-Z]+$/,
                                        message: "Please enter a valid driver's license or id."
                                    }
                                }}
                                render={(field) => (
                                    <TextField
                                        {...field}
                                        label="Driver's license or ID #"
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }} />
                                )}
                            />
                            {errors.driver_license && <p className={`${styles.ErrorM}`}>{errors.driver_license.message}</p>}
                        </FormControl>
                    </div>
                    <div className={`${styles.FormGroupHalf} FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>Social security number<span>*</span></label>
                        <FormControl fullWidth>
                            <Controller
                                name="social_security_number"
                                control={control}
                                rules={{
                                    required: 'This field is required.',
                                    pattern: {
                                        value: socialsecurityRegExpNew,
                                        message: "Please enter a valid social security number.",
                                        excludeEmptyString: true
                                    }
                                }}
                                render={(field) => (
                                    <NineDigitField
                                        {...field}
                                        label="XXX-XX-XXXX"
                                        type={ssntype1}
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }} />
                                )}
                            />
                            {(ssntype1 === 'password') && <button type="button" className={`${styles.ShowHideIcon}`} onClick={() => setSsntype1('text')} ><EyeOff /></button>}
                            {(ssntype1 === 'text') && <button type="button" className={`${styles.ShowHideIcon}`} onClick={() => setSsntype1('password')}><Eye /></button>}
                            {errors.social_security_number && <p className={`${styles.ErrorM}`}>{errors.social_security_number.message}</p>}
                        </FormControl>
                    </div>

                    <div className={`${styles.FormGroupHalf} FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>Email address<span>*</span></label>
                        <FormControl fullWidth>
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: "This field is required.",
                                    email: "Invalid email",
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Invalid email."
                                    }
                                }}
                                render={(field) => (
                                    <TextField
                                        {...field}
                                        label="example@gmail.com"
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }}
                                        disabled={applicantN === 2}
                                    />
                                )}
                            />
                            {errors.email && <p className={`${styles.ErrorM}`}>{errors.email.message}</p>}
                        </FormControl>
                    </div>
                    <div className={`${styles.FormGroupHalf} FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>Phone<span>*</span></label>
                        <FormControl fullWidth>
                            <Controller
                                name="phone"
                                control={control}
                                onKeyUp={(e) => phoneBlankCheck(e)}
                                rules={{
                                    required: 'This field is required.',
                                    pattern: {
                                        value: phoneRegExp,
                                        message: "Please enter a valid phone number.",
                                        excludeEmptyString: true
                                    },
                                    validate: {
                                        phoneIsUnique: phoneIsUnique,
                                        checkPhoneVerified: checkPhoneVerified
                                    }
                                }}
                                render={(field) => (
                                    <PhoneField
                                        {...field}
                                        label="(###) ###-####"
                                        disabled={isPhoneReadOnly}
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }} />
                                )}
                            />

                            {(verifyIstrue === true && !tokeninputshow && !isPhoneReadOnly) && <button className={`${styles.ShowHideIcon} ${styles.Verify}`} onClick={(e) => sendToken()} type="button">Verify <ArrowRight /></button>}
                            {(tokeninputshow && !verifyIstrue && !isPhoneReadOnly) && <button className={`${styles.ShowHideIcon} ${styles.Verify}`} onClick={(e) => resendToken()} type="button">Resend <ArrowRight /></button>}
                            {(isPhoneReadOnly) && <p className={`${styles.ShowHideIcon} ${styles.Verified}`}>Verified <Check /></p>}
                            {(tokenError !== '') && <p className={`${styles.ErrorM}`}>{tokenError}</p>}
                            {(tokenSuccess !== '') && <p className={`${styles.successM}`}>{tokenSuccess}</p>}
                            {(tokeninputshow && !isPhoneReadOnly) && <NumberFormat customInput={TextField} displayType="input" type="text" format={"######"} placeholder="Enter your token" className={`${styles.formControl} ${styles.OTP}`} onKeyUp={(e) => enterOTP(e)} />}

                            {(errors.phone) && <p className={`${styles.ErrorM}`}>{errors.phone.message}</p>}
                            {(errors.phone && errors.phone.type === "phoneIsUnique") && <p className={`${styles.ErrorM}`}>Phone number is already registered.</p>}
                            {(errors.phone && errors.phone.type === "checkPhoneVerified" && isSubmitted && tokenError === '' && otpError === '') && <p className={`${styles.ErrorM}`}>{(tokeninputshow ? 'Please enter the valid token.' : 'Phone number is not verified. Please click verify button.')}</p>}

                            {(otpSuccess !== '') && <p className={`${styles.successM}`}>{otpSuccess}</p>}
                            {(otpError !== '') && <p className={`${styles.ErrorM}`}>{otpError}</p>}
                            {(isPhoneReadOnly) && <button className={`${styles.ChangePhoneText}`} onClick={(e) => {
                                setIsPhoneReadOnly(false);
                                setTokeninputshow(false);
                                setVerifyIstrue(false);
                                setTokenError('');
                                setTokenSuccess('');
                                setOtpSuccess('');
                                setOtpError('');
                                setPhoneVerified(false);
                                setValue('phone', '');
                            }} >Click to update number</button>}

                        </FormControl>
                    </div>

                    <div className={`${styles.AccoFormBUsec}`}>
                        <button type="submit" className={`${styles.applicantNextBU}`}>NEXT</button>
                    </div>

                </div>
            </form>
        </AccordionDetails>
    </Accordion >);
}

export default PersonalInfoAccordion;