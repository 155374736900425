import { useEffect, useRef, useState } from 'react';
import styles from './rentalappplication.module.css';
import { Link as Linkto, useHistory } from "react-router-dom";
import { Background, Parallax } from 'react-parallax';
import Loader from '../../components/Loader';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import BasicInfoAccordion from './components/BasicInfoAccordion';
import PersonalInfoAccordion from './components/PersonalInfoAccordion';
import AddressAccordion from './components/AddressAccordion';
import RentalInfoAccordion from './components/RentalInfoAccordion';
import EmploymentAccordion from './components/EmploymentAccordion';
import OtherInfoAccordion from './components/OtherInfoAccordion';
import FilesAccordion from './components/FilesAccordion';
import TermsCondAccordion from './components/TermsCondAccordion';
import moment from 'moment';
import { IconButton, Slide, Snackbar } from '@mui/material';
import { X } from 'react-feather';
import { SmartToaster, toast } from 'react-smart-toaster';

const RentalApplication = ({ match }) => {
  const { str } = match?.params;
  const redirect = useHistory();
  const [loading, setLoading] = useState(false);
  const [propertDetails, setPropertDetails] = useState(null);
  const [apartmentList, setApartmentList] = useState([]);
  const [panelExpanded, setPanelExpanded] = useState('panel1');
  const [currentStep, setCurrentStep] = useState(1);
  const [lastStep, setLastStep] = useState(1);
  const [stepData, setStepData] = useState(null);
  const [skipSteps, setSkipSteps] = useState([]);
  const [applicantId, setApplicantId] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const panel2Ref = useRef();
  const panel3Ref = useRef();
  const panel4Ref = useRef();
  const panel5Ref = useRef();
  const panel6Ref = useRef();
  const panel7Ref = useRef();
  const panel8Ref = useRef();

  useEffect(() => {
    const fetchByID = async () => {
      await axios.get(process.env.REACT_APP_APPLICANT_API_URL + 'v1/application/property/' + str).then(response => {
        const resData = response.data;
        if (resData.apartmentList.length === 0) {
          redirect.push('/rental-application-error/' + resData?.propertyDet?.id);
        } else {
          setPropertDetails(resData?.propertyDet);
          setApartmentList(resData?.apartmentList);

          let propertyFormTemp = resData?.propertyDet?.rental_application;
          if (propertyFormTemp?.landlord_name === false && propertyFormTemp?.landlord_phone_no === false && propertyFormTemp?.reason_for_moving === false && propertyFormTemp?.who_live_with_you === false && propertyFormTemp?.lived_current_addrs === false && propertyFormTemp?.current_rent === false) {
            setSkipSteps(oldArray => [...oldArray, 4]);
          }
          if (propertyFormTemp?.employer_information === false) {
            setSkipSteps(oldArray => [...oldArray, 5]);
          }
        }
      }).catch(error => {
        redirect.push('/rental-application-invalid');
      });
    }
    fetchByID();
  }, [str, redirect]);

  const accordinChange = (panel, step) => (event, isExpanded) => {
    setPanelExpanded(isExpanded ? panel : false);
    if (isExpanded) {
      setCurrentStep(step);
      if (lastStep < step) {
        setLastStep(step);
      }
    }
  };

  const getNextStep = (cStep) => {
    cStep++;
    if (skipSteps.includes(cStep)) {
      let cTemp = getNextStep(cStep);
      return cTemp;
    } else {
      return cStep;
    }
  }

  useEffect(() => {

    setTimeout(() => {
      if (currentStep === 2) {
        panel2Ref.current.scrollIntoView({ behavior: "smooth" });
      }
      if (currentStep === 3) {
        panel3Ref.current.scrollIntoView({ behavior: "smooth" });
      }
      if (currentStep === 4) {
        panel4Ref.current.scrollIntoView({ behavior: "smooth" });
      }
      if (currentStep === 5) {
        panel5Ref.current.scrollIntoView({ behavior: "smooth" });
      }
      if (currentStep === 6) {
        panel6Ref.current.scrollIntoView({ behavior: "smooth" });
      }
      if (currentStep === 7) {
        panel7Ref.current.scrollIntoView({ behavior: "smooth" });
      }
      if (currentStep === 8) {
        panel8Ref.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 500);

  }, [currentStep]);

  const checkIsSubmit = () => {
    let isPersonalInfoSubmitted = false;
    let isAddressSubmitted = false;
    let isRentalInfoSubmitted = false;
    let isEmployementSubmitted = false;
    let isOtherInfoSubmitted = false;
    let isUploadFiles = false;
    let isTermSubmitted = false;
    if (stepData?.is_personal_info_submitted && stepData?.is_personal_info_submitted === 1) {
      isPersonalInfoSubmitted = true;
    }
    if (stepData?.is_address_submitted && stepData?.is_address_submitted === 1) {
      isAddressSubmitted = true;
    }
    if (stepData?.is_rental_info_submitted && stepData?.is_rental_info_submitted === 1) {
      isRentalInfoSubmitted = true;
    }
    if (stepData?.is_employement_submitted && stepData?.is_employement_submitted === 1) {
      isEmployementSubmitted = true;
    }
    if (stepData?.is_other_info_submitted && stepData?.is_other_info_submitted === 1) {
      isOtherInfoSubmitted = true;
    }
    if (stepData?.is_upload_files && stepData?.is_upload_files === 1) {
      isUploadFiles = true;
    }
    if (stepData?.is_term_submitted && stepData?.is_term_submitted === 1) {
      isTermSubmitted = true;
    }

    if (isPersonalInfoSubmitted && isAddressSubmitted && isRentalInfoSubmitted && isEmployementSubmitted && isOtherInfoSubmitted && isUploadFiles && isTermSubmitted)
      return true;
    else
      return false;
  }

  const submitApplicant = () => {
    setLoading(true);
    let postData = stepData;
    postData = { ...postData, applcation_fee: propertDetails?.rental_application?.applcation_fee };

    if (postData?.CurrentStep) {
      delete postData?.CurrentStep;
    }

    axios.post(process.env.REACT_APP_APPLICANT_API_URL + 'v1/add-applicants', postData).then(response => {
      setSnackbarOpen(true);
      setSnackbarMsg('Rental Application submitted successfully!');
      setLoading(false);
      setTimeout(() => {
        redirect.push('/rental-application-payment/' + response?.data?.urlToken);
      }, 2000);
    }).catch(error => {
      setLoading(false);
    });
  }

  return (<>
    {loading && <Loader />}
    <Helmet title="Rental Applicant" />
    <SmartToaster store={toast} lightBackground={true} position={"top_center"} />

    <Parallax strength={300} className="RentalParallax">
      <Background className="custom-bg">
        <img src="/static/img/rental-app-bg.jpg" alt="fill murray" />
      </Background>
    </Parallax>

    <div className="WhiteBG">

      <div className={`${styles.RentalAppliArea}`}>

        <div className={`${styles.RentalAppliHead}`}>
          <img src={(propertDetails?.app_theme_options && propertDetails?.app_theme_options?.image_url !== '') ? propertDetails?.app_theme_options?.image_url : '/static/img/logo.svg'} alt="logo" style={{ width: '200px' }} />
          <p className={`${styles.RentalAppliHeadTitle}`}>{propertDetails?.name}</p>
          <p className={`${styles.RentalAppliHeadTitle}`}>Rental Application</p>
        </div>

        <div className={`${styles.ApplicantSec}`}>
          <p className={`${styles.ApplicantSecTitle}`}>Applicant #1</p>
          <div className={`${styles.FormNoteSec}`}>
            <img src="/static/img/NoteIcon.svg" alt="logo" />
            <p className={`${styles.FormNoteText}`}>Please note that <span>all fields are required</span>. Only fields marked optional are optional</p>
          </div>
          <div className="FormAccording">

            <BasicInfoAccordion stepData={stepData} setStepData={setStepData.bind(this)} apartmentList={apartmentList} expanded={panelExpanded} accordinChange={accordinChange.bind(this)} lastStep={lastStep} setLastStep={setLastStep.bind(this)} setCurrentStep={setCurrentStep.bind(this)} getNextStep={getNextStep.bind(this)} setPanelExpanded={setPanelExpanded?.bind(this)} />

            <PersonalInfoAccordion panelRef={panel2Ref} stepData={stepData} setStepData={setStepData.bind(this)} expanded={panelExpanded} accordinChange={accordinChange.bind(this)} lastStep={lastStep} setLastStep={setLastStep.bind(this)} setCurrentStep={setCurrentStep.bind(this)} getNextStep={getNextStep.bind(this)} setPanelExpanded={setPanelExpanded?.bind(this)} applicantId={applicantId} setApplicantId={setApplicantId?.bind(this)} propertyId={propertDetails?.id} applicantN={1} />

            <AddressAccordion panelRef={panel3Ref} stepData={stepData} setStepData={setStepData.bind(this)} expanded={panelExpanded} accordinChange={accordinChange.bind(this)} lastStep={lastStep} setLastStep={setLastStep.bind(this)} setCurrentStep={setCurrentStep.bind(this)} getNextStep={getNextStep.bind(this)} setPanelExpanded={setPanelExpanded?.bind(this)} applicantId={applicantId} />

            <RentalInfoAccordion panelRef={panel4Ref} stepData={stepData} setStepData={setStepData.bind(this)} expanded={panelExpanded} accordinChange={accordinChange.bind(this)} lastStep={lastStep} setLastStep={setLastStep.bind(this)} setCurrentStep={setCurrentStep.bind(this)} getNextStep={getNextStep.bind(this)} setPanelExpanded={setPanelExpanded?.bind(this)} applicantId={applicantId} />

            <EmploymentAccordion panelRef={panel5Ref} stepData={stepData} setStepData={setStepData.bind(this)} expanded={panelExpanded} accordinChange={accordinChange.bind(this)} lastStep={lastStep} setLastStep={setLastStep.bind(this)} setCurrentStep={setCurrentStep.bind(this)} getNextStep={getNextStep.bind(this)} setPanelExpanded={setPanelExpanded?.bind(this)} applicantId={applicantId} />

            <OtherInfoAccordion panelRef={panel6Ref} stepData={stepData} setStepData={setStepData.bind(this)} expanded={panelExpanded} accordinChange={accordinChange.bind(this)} lastStep={lastStep} setLastStep={setLastStep.bind(this)} setCurrentStep={setCurrentStep.bind(this)} getNextStep={getNextStep.bind(this)} setPanelExpanded={setPanelExpanded?.bind(this)} applicantId={applicantId} />

            <FilesAccordion panelRef={panel7Ref} stepData={stepData} setStepData={setStepData.bind(this)} expanded={panelExpanded} accordinChange={accordinChange.bind(this)} lastStep={lastStep} setLastStep={setLastStep.bind(this)} setCurrentStep={setCurrentStep.bind(this)} getNextStep={getNextStep.bind(this)} setPanelExpanded={setPanelExpanded?.bind(this)} applicantId={applicantId} setLoading={setLoading.bind(this)} />

            <TermsCondAccordion panelRef={panel8Ref} stepData={stepData} setStepData={setStepData.bind(this)} expanded={panelExpanded} accordinChange={accordinChange.bind(this)} lastStep={lastStep} setPanelExpanded={setPanelExpanded?.bind(this)} applicantId={applicantId} />

            <button type="button" onClick={(e) => submitApplicant()} className={checkIsSubmit() ? `${styles.RentallAppliSubmitBU} ${styles.Active}` : `${styles.RentallAppliSubmitBU}`} disabled={!checkIsSubmit()}>Submit <img src="/static/img/Icon-send.png" alt="" /></button>

          </div>
        </div>

      </div>

      <div className={`${styles.FooterWrap}`}>
        <div className={`${styles.footerMain}`}>

          <div className={`${styles.footerMainLeft}`}>
            <Linkto to={'/'}><img src={(propertDetails?.app_theme_options && propertDetails?.app_theme_options?.image_url !== '') ? propertDetails?.app_theme_options?.image_url : '/static/img/logo.svg'} alt="logo" /></Linkto>
          </div>

          <div className={`${styles.footerMainRight}`}>
            <div>
              <h3>CONTACT US</h3>
              <ul>
                <li><a href={`mailto: ${propertDetails?.email}`}>{propertDetails?.email}</a></li>
                <li><a href={`tel: ${propertDetails?.phone}`} >{propertDetails?.phone}</a></li>
              </ul>
            </div>
            <div>
              <h3>FIND US</h3>
              <ul>
                <li>{propertDetails?.address} <br />{propertDetails?.city}, {propertDetails?.state} {propertDetails?.zip}</li>
              </ul>
            </div>
            <div>
              <h3>LEGAL</h3>
              <ul>
                <li><Linkto to={'/privacy-policy'}>Privacy Policy</Linkto></li>
                <li><Linkto to={'/eula'}>End User License Agreement</Linkto></li>
              </ul>
            </div>
            <div>
              <ul>
                <li>Weston Hall © {moment().format("YYYY")}.<br />All rights reserved.</li>
              </ul>
            </div>
          </div>

        </div>
      </div>

    </div>

    <Snackbar
      open={snackbarOpen}
      onClose={(e) => setSnackbarOpen(false)}
      TransitionComponent={Slide}
      message={snackbarMsg}
      autoHideDuration={10000}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={(e) => setSnackbarOpen(false)}
        >
          <X />
        </IconButton>
      }
    />
  </>);

}

export default RentalApplication;
