import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { setUserInfo } from "../redux/actions/userInfoAction";
import styles from './MessagesDropdown.module.css';
import { IconButton, Tooltip, Badge } from "@material-ui/core";
import { MessageSquare } from "react-feather";
import { incrementProspectCounter, resetProspectCounter } from "../redux/actions/prospectCounterAction";

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

function MessagesDropdown() {
  const history = useHistory();
  const userInfo = useSelector((store) => store.userInfoReducer)
  const prospectCounter = useSelector((store) => store.prospectReducer)
  console.log(prospectCounter)
  const dispacth = useDispatch();
  const [messageCounter, setMessageCounter] = useState(0);
  const [messageCounterForProspect, setMessageCounterForProspect] = useState(0);

  useEffect(() => {
    if (userInfo?.properties?.length > 0) {
      const result = userInfo.properties.reduce((counter, value) => counter += value.unread_no, 0);
      setMessageCounter(result);
    }
  }, [userInfo])

  useEffect(() => {
    console.log(prospectCounter,"prospectCounter")
    setMessageCounterForProspect(prospectCounter)
  }, [prospectCounter])

  const ref = useRef(null);

  const handleMessageCounter = () => {
    const result = userInfo.properties.map((p) => {
      p.unread_no = 0;
      return p;
    });
    userInfo.properties = result;
    dispacth(setUserInfo({ ...userInfo }))
    history.push('/communication');
  }

  const handleMessageCounterForProspect = () => {
    
    dispacth(resetProspectCounter())
    history.push('/communication/prospect');
  }
console.log(messageCounterForProspect)
  return (<React.Fragment>
    <Tooltip title="Prospect Messages">
    <IconButton color="inherit"  onClick={handleMessageCounterForProspect} className="customHeaderIcon">
        { messageCounterForProspect > 0 && <Indicator badgeContent={messageCounterForProspect} className={`${styles.SpanClassBadge}`} />}
        <MessageSquare />
      </IconButton>
     
    </Tooltip>
    <Tooltip title="Messages">
      <IconButton color="inherit" ref={ref} onClick={handleMessageCounter} className="customHeaderIcon">
        {messageCounter > 0 && <Indicator badgeContent={messageCounter} className={`${styles.SpanClassBadge}`} />}
        <MessageSquare />
      </IconButton>
    </Tooltip>
  </React.Fragment>
  );
}

export default MessagesDropdown;