import styles from '../../../maintenancerequestdetails.module.css';
import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import { useAuth0 } from "@auth0/auth0-react";
import { AddLogCallAPI } from '../../../../../components/AddLogs';

const schemaNote = yup.object().shape({
    note: yup.string().required('This field is Required').max(400, 'Notes cannot be more than 400 characters'),
});

const AddNoteForm = ({ requestData, setRequestData, setLoading, setSnackbarOpen, setSnackbarMsg, setAddFormOpen, setNoteData, noteData }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [noteId, setNoteId] = useState(0);
    const [charsLeft, setCharsLeft] = useState(400);

    const { register, handleSubmit, errors, setValue } = useForm({
        resolver: yupResolver(schemaNote)
    });

    useEffect(() => {
        if (noteData) {
            setNoteId(noteData?.id);
            setValue('note', noteData?.note);
            setCharsLeft(400 - noteData?.note?.length);
        }
    }, [noteData, setValue]);

    const onSubmit = async (data) => {
        setLoading(true);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
        if (noteId) {
            return axios.put(process.env.REACT_APP_CASE_API_URL + 'v1/maintenance-requests/' + requestData?.id + '/notes/' + noteId, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then(response => {
                setLoading(false);

                setSnackbarOpen(true);
                setSnackbarMsg(response.data.message);

                setAddFormOpen(false);
                setNoteData(null);

                setRequestData(prev => {
                    let notes = prev?.notes?.map(i => {
                        if (i?.id === noteId)
                            return { ...i, note: response.data.data.note };
                        return i;
                    });
                    return { ...prev, notes: notes };
                });

                let logData = {
                    'title': 'Maintenance request  note is updated',
                    'description': [
                        'Name: ' + requestData?.title,
                        'Request number: ' + requestData?.request_no,
                        'Property: ' + requestData?.property_name,
                        'Apartment: ' + requestData?.apt_name,
                        'Tenant: ' + requestData?.tenant_name,
                        'Comment: ' + response.data.data?.note,
                    ]
                }
                AddLogCallAPI(logData, token);

            }).catch(error => {
                if (typeof error.response !== 'undefined')
                    toast.error(error.response.data.message);
                setLoading(false);
            });
        } else {
            return axios.post(process.env.REACT_APP_CASE_API_URL + 'v1/maintenance-requests/' + requestData?.id + '/notes', data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                setLoading(false);

                setSnackbarOpen(true);
                setSnackbarMsg(response.data.message);

                setAddFormOpen(false);
                setNoteData(null);

                setRequestData(prev => {
                    let notes = [response.data.data].concat(prev?.notes)
                    return { ...prev, notes: notes };
                });

                let logData = {
                    'title': 'Maintenance request new note is created',
                    'description': [
                        'Name: ' + requestData?.title,
                        'Request number: ' + requestData?.request_no,
                        'Property: ' + requestData?.property_name,
                        'Apartment: ' + requestData?.apt_name,
                        'Tenant: ' + requestData?.tenant_name,
                        'Comment: ' + response.data.data?.note,
                    ]
                }
                AddLogCallAPI(logData, token);
            }).catch(error => {
                if (typeof error.response !== 'undefined')
                    toast.error(error.response.data.message);
                setLoading(false);
            });
        }
    }

    return (<>
        <div className={`${styles.AddCommDiv}`}>
            <form id="myForm" onSubmit={handleSubmit(onSubmit)}>
                <div className={`${styles.formGroup}`}>
                    <textarea className={`${styles.ReferCommentArea}`} name="note" ref={register} placeholder="Add text here..." maxLength={400} onChange={(e) => setCharsLeft(400 - e.target.value.length)}></textarea>
                    <p className={`${styles.RemainingText}`}>{charsLeft} {charsLeft > 1 ? "characters" : "character"} remaining</p>
                    {errors.note && <p className={`${styles.ErrorM}`}>{errors.note.message}</p>}
                </div>
                <div className={`${styles.ButtonSec}`}>
                    <input type="button" value="Cancel" className={`${styles.CancelBU}`} onClick={(e) => { setAddFormOpen(false); setNoteData(null); }} />
                    <input type="submit" value="Add" className={`${styles.AddBU}`} />
                </div>
            </form>
        </div>
    </>);
}

export default AddNoteForm;