import { useState, useEffect } from "react";
import styles from "../maintenancerequest.module.css";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { toast } from "react-smart-toaster";
import { Clock, X } from "react-feather";
import { Button, FormControl, InputLabel, MenuItem, ListSubheader, Select, TextField } from "@mui/material";
import { AddLogCallAPI } from "../../../components/AddLogs";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const schema = yup.object().shape({
  tenant_id: yup.string().required("This field is Required"),
  category: yup.string().required("This field is Required"),
  description: yup.string().required("This field is Required").max(200, "Description cannot be more than 200 characters"),
  contact_time_val: yup.string().required("This field is Required"),
  contact_time_start: yup.date().required("This field is Required"),
  contact_time_end: yup.date().required("This field is Required"),
  priority: yup.string().required("This field is Required"),
});

const categoryGroupOptions = [
  { label: "Electrical", parent: 1 },
  { value: "Electrical Socket", label: "Electrical Socket", parent: 0, category: "Electrical", img_path: "/static/img/ElectricalSocket.svg" },
  { value: "Light Switch/Dimmer", label: "Light Switch/Dimmer", parent: 0, category: "Electrical", img_path: "/static/img/Dimmer.svg" },
  { label: "Plumbing", parent: 1 },
  { value: "Leak", label: "Leak", parent: 0, category: "Plumbing", img_path: "/static/img/Leak.svg" },
  { value: "Drain clogged", label: "Drain clogged", parent: 0, category: "Plumbing", img_path: "/static/img/DrainClogged.svg" },
  { value: "Water temperature Issue", label: "Water temperature Issue", parent: 0, category: "Plumbing", img_path: "/static/img/WaterTemperature.svg" },
  { label: "Appliances", parent: 1 },
  { value: "Stove/Oven Issue", label: "Stove/Oven Issue", parent: 0, category: "Appliances", img_path: "/static/img/StoveOven.svg" },
  { value: "Refrigerator Issue", label: "Refrigerator Issue", parent: 0, category: "Appliances", img_path: "/static/img/Refrigerator.svg" },
  { value: "Washer/Dryer Issue", label: "Washer/Dryer Issue", parent: 0, category: "Appliances", img_path: "/static/img/Washer.svg" },
  { label: "Other", parent: 1 },
  { value: "Other Issue", label: "Other Issue", parent: 0, category: "Other", img_path: "/static/img/other.svg" },
];

const AddMaintenanceForm = ({ setAddModalOpen, setLoading, setSnackbarOpen, setSnackbarMsg, onSubmitLocal, tenant_id }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [tenantList, setTenantList] = useState([]);
  const [charsLeft, setCharsLeft] = useState(200);
  const [ImgPathTemp, setImgPathTemp] = useState({ Files: [], imagePathArr: [] });

  useEffect(() => {
    async function fetchTenants() {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
      await axios.get(process.env.REACT_APP_CASE_API_URL + "v1/property-tenant-list", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        let tFinalList = [];

        let templistNew = response.data.data;
        if (tenant_id) {
          templistNew = response.data.data?.map(item => {
            let opts = item?.options?.filter(i => i?.value === parseInt(tenant_id));
            return { ...item, options: opts };
          });
          templistNew = templistNew.filter(i => i.options?.length > 0);
        }

        templistNew?.map((item) => {
          tFinalList.push({ label: item.label, parent: 1 });
          item?.options?.map((subItem) => {
            tFinalList.push({
              value: subItem.value,
              label: subItem.label,
              parent: 0,
              propertyId: item?.id,
            });
            return false;
          });

          return false;
        });

        setTenantList(tFinalList);
      }).catch((error) => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
      });
    }

    fetchTenants();
  }, [getAccessTokenSilently, tenant_id]);

  const { handleSubmit, control, errors, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      contact_time_start: dayjs().set("hour", 7).set("minute", 0),
      contact_time_end: dayjs().set("hour", 19).set("minute", 0),
    }
  });

  const onSubmit = async (data) => {
    let postData = {};

    postData = { ...postData, tenant_id: data?.tenant_id, priority: data?.priority, description: data?.description, contact_time_val: data?.contact_time_val };
    if (data?.tenant_id) {
      let selectTenant = tenantList?.filter((i) => i?.value === parseInt(data?.tenant_id));
      postData = { ...postData, property_id: selectTenant[0]?.propertyId };
    }
    if (data?.contact_time_start) {
      postData = { ...postData, contact_time_start: dayjs(data.contact_time_start).format("LT") };
    }
    if (data?.contact_time_end) {
      postData = { ...postData, contact_time_end: dayjs(data.contact_time_end).format("LT") };
    }
    if (data?.category) {
      let selectCat = categoryGroupOptions?.filter((i) => i?.value === data.category);
      postData = { ...postData, category: selectCat[0]?.category, sub_category: data?.category };
    }

    let formData = new FormData();
    Object.keys(postData).map((key) => {
      formData.append(key, postData[key]);
      return true;
    });
    if (ImgPathTemp.Files.length) {
      for (let n in ImgPathTemp.Files) {
        formData.append("uploadImages[]", ImgPathTemp.Files[n]);
      }
    }

    setLoading(true);

    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

    return axios.post(process.env.REACT_APP_CASE_API_URL + "v1/maintenance-requests", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      setLoading(false);

      setSnackbarOpen(true);
      setSnackbarMsg(response.data.message);

      setAddModalOpen(false);

      onSubmitLocal(response.data.data);

      let logData = {
        title: "New maintenance request is created",
        description: [
          "Name: " + response.data.data.title,
          "Request number: " + response.data.data.request_no,
          "Property: " + response.data.data.property_name,
          "Apartment: " + response.data.data.apt_name,
          "Tenant: " + response.data.data.tenant_name,
        ],
      };
      AddLogCallAPI(logData, token);
    }).catch((error) => {
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
      setLoading(false);
    });
  }

  const fileHandler = (e) => {
    var validExtensions = ["jpg", "jpeg", "png", "svg"]; //array of valid extensions
    var fileName = e.target.files[0].name;
    var fileNameExt = fileName.substr(fileName.lastIndexOf(".") + 1);
    if (validExtensions.indexOf(fileNameExt.toLowerCase()) === -1) {
      toast.error("File type is not allowed");
    } else if (e.target.files[0].size > 5242880) {
      toast.error("Image size should not be greater than 5 mb.");
    } else {
      let tempFiles = ImgPathTemp.Files;
      let tempImages = ImgPathTemp.imagePathArr;
      let newData = ImgPathTemp;
      for (let n = 0; n < e.target.files.length; n++) {
        let file = e.target.files[n];
        tempImages.push(URL.createObjectURL(file));
        tempFiles.push(file);
      }
      newData = { ...newData, imagePathArr: tempImages, Files: tempFiles };
      setImgPathTemp(newData);
    }
  }

  const deleteTempImage = (index) => {
    let tempFiles = ImgPathTemp.Files;
    let tempImages = ImgPathTemp.imagePathArr;
    let newData = ImgPathTemp;

    tempFiles = tempFiles.filter(function (item, i) {
      return i !== index;
    });

    tempImages = tempImages.filter(function (item, i) {
      return i !== index;
    });

    newData = { ...newData, imagePathArr: tempImages, Files: tempFiles };
    setImgPathTemp(newData);
  }

  return (<div className={`${styles.ModalFormGroup}`}>
    <form onSubmit={handleSubmit(onSubmit)}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className={`${styles.ReqAddRow}`}>
          <div className={`${styles.ReqAddFormSec}`}>
            <div className={`${styles.FormSec}`}>
              <div className={`${styles.FormGroupFull} FormGroup`}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Select Tenant*</InputLabel>
                  <Controller
                    name="tenant_id"
                    control={control}
                    render={(field) => (
                      <Select
                        label="Select Tenant*"
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                      >
                        {tenantList.map((item) => {
                          if (item?.parent === 1)
                            return (<ListSubheader className={`${styles.GropSelectTitle}`}>{item?.label}</ListSubheader>);
                          else
                            return (<MenuItem value={item?.value} className={`${styles.GropSelectList}`}>{item?.label}</MenuItem>);
                        })}
                      </Select>
                    )}
                  />
                </FormControl>
                {errors.tenant_id && (<p className={`${styles.ErrorM}`}>{errors.tenant_id.message}</p>)}
              </div>
            </div>
            <div className={`${styles.FormSec}`}>
              <div className={`${styles.FormGroupFull} FormGroup`}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Select Category*</InputLabel>
                  <Controller
                    name="category"
                    control={control}
                    render={(field) => (
                      <Select
                        label="Select Category*"
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                      >
                        {categoryGroupOptions.map((item) => {
                          if (item?.parent === 1)
                            return (<ListSubheader className={`${styles.GropSelectTitle}`}>{item?.label}</ListSubheader>);
                          else
                            return (<MenuItem value={item?.value} className={`${styles.GropSelectList}`}>
                              <img src={item?.img_path} alt="" />
                              {item?.label}
                            </MenuItem>);
                        })}
                      </Select>
                    )}
                  />
                </FormControl>
                {errors.category && (<p className={`${styles.ErrorM}`}>{errors.category.message}</p>)}
              </div>
            </div>
            <div className={`${styles.FormSec}`}>
              <div className={`${styles.FormGroupFull} FormGroup`}>
                <Controller
                  name={`description`}
                  control={control}
                  render={(field) => (
                    <TextField
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        setCharsLeft(200 - e.target.value.length);
                      }}
                      id="outlined-multiline-static"
                      label="Add Description*"
                      variant="outlined"
                      inputProps={{ maxLength: 200 }}
                      multiline
                      rows={6}
                    />
                  )}
                />
                <p className={`${styles.RemainingText}`}>{charsLeft} {charsLeft > 1 ? "characters" : "character"} remaining</p>
                {errors.description && (<p className={`${styles.ErrorM}`}>{errors?.description?.message}</p>)}
              </div>
            </div>
            <div className={`${styles.FormSecRow}`}>
              <div className={`${styles.FormHalfSec}`}>
                <div className={`${styles.FormSec}`}>
                  <div className={`${styles.FormGroupFull} FormGroup`}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Select the best time to contact*</InputLabel>
                      <Controller
                        name="contact_time_val"
                        control={control}
                        render={(field) => (
                          <Select
                            label="Select best time to contact*"
                            {...field}
                            onChange={(e) => {
                              field.onChange(e);
                            }}
                          >
                            <MenuItem value={"Weekdays"}>Weekdays</MenuItem>
                            <MenuItem value={"Weekends"}>Weekends</MenuItem>
                          </Select>
                        )}
                      />
                    </FormControl>
                    {errors.contact_time_val && !watch().contact_time_val && (<p className={`${styles.ErrorM}`}>{errors.contact_time_val.message}</p>)}
                  </div>
                </div>
              </div>
              <div className={`${styles.FormHalfSec}`}>
                <div className={`${styles.FormTimeZone}`}>
                  <div className={`${styles.TimePick} TimePick`}>
                    <Clock className={`${styles.ClockIcon}`} />
                    <Controller
                      control={control}
                      name="contact_time_start"
                      render={(props) => (
                        <MobileTimePicker
                          {...props}
                          label="Select Time"
                        />
                      )}
                    />
                    {errors.contact_time_start && (<p className={`${styles.ErrorM}`}>{errors.contact_time_start.message}</p>)}
                  </div>
                  <p className={`${styles.TimeP}`}>to</p>
                  <div className={`${styles.TimePick} TimePick`}>
                    <Clock className={`${styles.ClockIcon}`} />
                    <Controller
                      control={control}
                      name="contact_time_end"
                      render={(props) => (
                        <MobileTimePicker
                          {...props}
                          label="Select Time"
                        />
                      )}
                    />
                    {errors.contact_time_end && (<p className={`${styles.ErrorM}`}>{errors.contact_time_end.message}</p>)}
                  </div>
                </div>
              </div>
            </div>
            <div className={`${styles.FormSec}`}>
              <div className={`${styles.FormGroupFull} FormGroup`}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Select Priority*</InputLabel>
                  <Controller
                    name="priority"
                    control={control}
                    render={(field) => (
                      <Select
                        label="Select Priority*"
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                      >
                        <MenuItem value={1}>Low</MenuItem>
                        <MenuItem value={2}>Medium</MenuItem>
                        <MenuItem value={3}>High</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
                {errors.priority && (<p className={`${styles.ErrorM}`}>{errors.priority.message}</p>)}
              </div>
            </div>
            <div className={`${styles.FormIMGSec} ${styles.FileUploadSmall}`}>
              <div className={`${styles.FileIMGDiv}`}>
                <div className={`${styles.FileUpDuBUDiv}`}>
                  <label for="file-upload" className={`${styles.UploadLabel}`}>
                    <span>
                      <div className={`${styles.UpIcon}`}>
                        <img src="/static/img/upload-floor.svg" alt="" />
                      </div>
                      <div>
                        <p>Upload Photo</p>
                        <p className={`${styles.UploadText}`}>
                          File size upto 5 MB <br /> JPEG, JPG, PNG only
                        </p>
                      </div>
                    </span>
                  </label>
                  <input id="file-upload" type="file" onChange={fileHandler} accept=".jpg,.png,.jpeg,.svg" inputProps={{ accept: "image/*" }} />
                </div>
                <div className={`${styles.UpIMGSec}`}>
                  {ImgPathTemp.imagePathArr.map((item, index) => {
                    return (<div className={`${styles.ImgFile}`}>
                      <img src={item} alt="" />
                      <Button onClick={deleteTempImage.bind(this, index)}>
                        <X />
                      </Button>
                    </div>);
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </LocalizationProvider>
      <div className={`${styles.ButtonAction}`}>
        <Button className="CancelPopupBU" type="button" onClick={(e) => setAddModalOpen(false)}>Cancel</Button>
        <Button className="SubmitPopupBU" type="submit">Add</Button>
      </div>
    </form>
  </div>);
};

export default AddMaintenanceForm;