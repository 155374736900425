import { useEffect, useState } from "react";
import { Plus, X } from "react-feather";
import styles from "../tour.module.css";
import { Box, Button, Chip, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AddTourTileSlot from "../../prospect/Components/AddTourTileSlot";
import dayjs from "dayjs";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { toast } from "react-smart-toaster";
import Loader from "../../../components/Loader";
import StepOneForm from "../../prospect/Components/AddProspect/StepOneForm";
import StepTwoForm from "../../prospect/Components/AddProspect/StepTwoForm";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const OpenTourFrom = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [selectedTourTime, setSelectedTourTime] = useState("");
  const [selectedTourDate, setSelectedTourDate] = useState(dayjs());
  const [tourTimeSlotError, setTourTimeSlotError] = useState("");
  const [selectProperty, setSelectProperty] = useState("");
  const [propertyError, setPropertyError] = useState("");
  const [allProspectsList, setAllProspectsList] = useState([]);
  const [prospectsList, setProspectsList] = useState([]);
  const [selectedProspect, setSelectedProspect] = useState([]);
  const [prospectError, setProspectError] = useState("");
  const [addPropspectForm, setAddPropspectForm] = useState(false);
  const [addFormStep, setAddFormStep] = useState(1);
  const [stepOneData, setStepOneData] = useState(null);
  const [editId, setEditId] = useState(0);
  const [allApartmentList, setAllApartmentList] = useState([]);
  const [apartmentList, setApartmentList] = useState([]);
  const [selectApartment, setSelectApartment] = useState("");
  const [aptError, setAptError] = useState("");


  useEffect(() => {
    const fetchAllPropects = async () => {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
      await axios.get(process.env.REACT_APP_APPLICANT_API_URL + "v1/prospect-list-tour", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((response) => {
        setAllProspectsList(response.data.data);
      }).catch((error) => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
      });
    };
    fetchAllPropects();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const fetchAllApartments = async () => {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
      await axios.get(process.env.REACT_APP_APPLICANT_API_URL + "v1/all-apartments", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((response) => {
        setAllApartmentList(response.data.data);
      }).catch((error) => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
      });
    };
    fetchAllApartments();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (props.is_edit) {
      console.log(props.selectedEvent?.extendedProps);
      let date_time = props.selectedEvent?.extendedProps?.date_time;
      let dateArr = date_time.split(" ");
      setSelectedTourDate(dayjs(dateArr[0]));
      setSelectedTourTime(dateArr[1]);
      setSelectProperty(props.selectedEvent?.extendedProps?.property_id);
      setSelectApartment(props.selectedEvent?.extendedProps?.apt_id);
      setEditId(props.selectedEvent?.extendedProps?.tour_id);

      let selPros = allProspectsList.filter(
        (i) => i.id === props.selectedEvent?.extendedProps?.prospect_id
      );
      setSelectedProspect(selPros);
    }
  }, [props.is_edit, props.selectedEvent, allProspectsList]);

  useEffect(() => {
    if (props.selectedProperty > 0) {
      setSelectProperty(props.selectedProperty);
    }
  }, [props.selectedProperty]);

  useEffect(() => {
    let apartmentListTemp = [];
    if (selectProperty) {
      apartmentListTemp = allApartmentList?.filter(i => i?.property_id === selectProperty);
    }

    setApartmentList(apartmentListTemp);
  }, [allApartmentList, selectProperty]);

  useEffect(() => {
    if (selectProperty !== "") {
      let pList = allProspectsList?.map((i) => {
        if (i?.property_id === selectProperty) {
          return i;
        }
        return null;
      });

      pList = pList.filter((i) => i !== null);

      setProspectsList(pList);
    }
  }, [allProspectsList, selectProperty]);

  useEffect(() => {
    if (selectedTourTime !== "") {
      setTourTimeSlotError("");
    }
  }, [selectedTourTime]);

  useEffect(() => {
    if (selectProperty !== "") {
      setPropertyError("");
    }
  }, [selectProperty]);

  useEffect(() => {
    if (selectApartment !== "") {
      setAptError("");
    }
  }, [selectApartment]);

  useEffect(() => {
    if (selectedProspect.length > 0) {
      setProspectError("");
    }
  }, [selectedProspect]);

  const onSubmit = async () => {
    if (selectProperty === "") {
      setPropertyError("Please select property.");
      return false;
    }

    if (selectApartment === "") {
      setAptError("Please select apartment.");
      return false;
    }
    if (selectedProspect.length === 0) {
      setProspectError("Please select prospect.");
      return false;
    }
    if (selectedTourTime === "") {
      setTourTimeSlotError("Please select time slot.");
      return false;
    }

    let prospectIds = selectedProspect?.map((i) => {
      return i?.id;
    });

    let postData = { property_id: selectProperty, apt_id: selectApartment, prospect_ids: prospectIds, date_time: dayjs(selectedTourDate).format("YYYY-MM-DD") + " " + selectedTourTime };
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
    setLoading(true);

    axios.post(process.env.REACT_APP_APPLICANT_API_URL + "v1/add-tour", postData, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((response) => {
      setLoading(false);
      props.setAddTourFormOpen(false);
      props.setSnackbarMsg(response.data.message);
      props.setSnackbarOpen(true);

      props.onSubmit(response.data.data);
    }).catch((error) => {
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
      setLoading(false);
    });
  };

  const onSubmitOne = (data) => {
    setAddFormStep(2);
    setStepOneData(data);
  };

  const onSubmitTwo = async (data) => {
    let postData = {};
    postData = { ...postData, ...stepOneData };
    if (typeof data.credit_score !== "undefined") {
      postData = { ...postData, credit_score: data.credit_score };
    }
    if (typeof data.have_pets !== "undefined") {
      postData = { ...postData, have_pets: data.have_pets };
    }
    if (typeof data.income_per_year !== "undefined") {
      postData = { ...postData, income_per_year: data.income_per_year };
    }
    if (typeof data.bedrooms !== "undefined") {
      postData = { ...postData, bedrooms: data.bedrooms };
    }
    if (typeof data.move_in_date !== "undefined") {
      postData = { ...postData, move_in_date: dayjs(data?.move_in_date).format("YYYY-MM-DD HH:mm:ss") };
    }
    if (typeof data.bathrooms !== "undefined") {
      postData = { ...postData, bathrooms: data.bathrooms };
    }
    if (typeof data.do_you_smoke !== "undefined") {
      postData = { ...postData, do_you_smoke: data.do_you_smoke };
    }

    setAddPropspectForm(false);
    setLoading(true);
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

    await axios.post(process.env.REACT_APP_APPLICANT_API_URL + "v1/add-prospect", postData, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((response) => {
      setLoading(false);

      let newData = response.data.data;

      setAllProspectsList((prev) => {
        return prev.concat([newData]);
      });

      setSelectedProspect((prev) => {
        return prev.concat([newData]);
      });
    }).catch((error) => {
      setLoading(false);
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
    });
  };

  const onUpdate = async () => {
    if (selectApartment === "") {
      setAptError("Please select apartment.");
      return false;
    }

    let postData = { apt_id: selectApartment, date_time: dayjs(selectedTourDate).format("YYYY-MM-DD") + " " + selectedTourTime };
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
    setLoading(true);

    axios.put(process.env.REACT_APP_APPLICANT_API_URL + "v1/tour/" + editId, postData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      setLoading(false);
      props.setAddTourFormOpen(false);
      props.setSnackbarMsg(response.data.message);
      props.setSnackbarOpen(true);

      props.onSubmit(response.data.data);
    }).catch((error) => {
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
      setLoading(false);
    });
  };

  return (<>
    {loading && <Loader />}
    {!addPropspectForm && (<>
      <DialogTitle id="alert-dialog-title">
        {props?.is_edit ? "Edit" : "Add"} Tour
        <button onClick={(e) => props.setAddTourFormOpen(false)}>
          <X />
        </button>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          <div className={`${styles.Width100}`}>
            <div className={`${styles.PopupFilterRow}`}>
              <div className={`${styles.FilterCard} ${styles.Select} CusSelectFilter AddTour`}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Property Name*</InputLabel>
                  <Select
                    input={<OutlinedInput label="Property Name" />}
                    MenuProps={MenuProps}
                    onChange={(e) => setSelectProperty(e.target.value)}
                    value={selectProperty}
                    disabled={props?.is_edit}
                  >
                    {props?.allProperties?.map((item) => {
                      return (<MenuItem key={item?.value} value={item?.value}>{item?.label}</MenuItem>);
                    })}
                  </Select>
                </FormControl>
                {propertyError !== "" && (<p className={`${styles.AddTourErrorMessage}`}>{propertyError}</p>)}
              </div>

              <div className={`${styles.FilterCard} ${styles.Select} CusSelectFilter AddTour`}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Apartment*</InputLabel>
                  <Select
                    input={<OutlinedInput label="Apartment" />}
                    MenuProps={MenuProps}
                    onChange={(e) => setSelectApartment(e.target.value)}
                    value={selectApartment}
                  >
                    {apartmentList?.map((item) => {
                      return (<MenuItem key={item?.id} value={item?.id}>{item?.apt_no}</MenuItem>);
                    })}
                  </Select>
                </FormControl>
                {aptError !== "" && (<p className={`${styles.AddTourErrorMessage}`}>{aptError}</p>)}
              </div>

              <div className={`${styles.FilterCard} ${styles.Full} ${styles.Select} CusSelectFilter AddTour`}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Prospect's Name*</InputLabel>
                  <Select
                    multiple
                    value={selectedProspect}
                    onChange={(e) => {
                      if (e.target.value.indexOf(0) > -1) {
                        setAddPropspectForm(true);
                        setAddFormStep(1);
                        e.target.value.splice(e.target.value.indexOf(0), 1);
                      }
                      setSelectedProspect(e.target.value);
                    }}
                    input={<OutlinedInput label="Prospect's Name*" />}
                    renderValue={(selected) => (<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (<Chip key={value?.id} label={value?.full_name + " - " + value?.phone} />))}
                    </Box>)}
                    MenuProps={MenuProps}
                    disabled={props?.is_edit}
                  >
                    {selectProperty !== "" ? (<MenuItem key={0} value={0}>
                      <div className={`${styles.AddProspectBU}`}><Plus />New Prospect</div>
                    </MenuItem>) : null}

                    {prospectsList.map((prospect) => {
                      return (<MenuItem key={prospect.id} value={prospect}>
                        {prospect.full_name} - {prospect.phone}
                      </MenuItem>);
                    })}
                  </Select>
                </FormControl>
                {prospectError !== "" && (<p className={`${styles.AddTourErrorMessage}`}>
                  {prospectError}
                </p>
                )}
              </div>
            </div>

            <p className={`${styles.AddTourPopTT}`}>
              Select Tour Date and Time
            </p>
            <div className={`${styles.ModalFormGroup} ModalFormGroup`}>
              <div className={`${styles.AddTourModal} AddTourModal`}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <StaticDatePicker
                    orientation="landscape"
                    value={selectedTourDate}
                    onChange={(e) => setSelectedTourDate(e)}
                  />
                </LocalizationProvider>
              </div>
              <div className={`${styles.AddTourTimeArea}`}>
                <p className={`${styles.TimeSlotTitle}`}>
                  {dayjs(selectedTourDate).format("dddd, MMMM D")}
                </p>
                <p className={`${styles.AddTourErrorMessage}`}>
                  {tourTimeSlotError !== "" && tourTimeSlotError}
                </p>
                <AddTourTileSlot
                  setSelectedTourTime={setSelectedTourTime.bind(this)}
                  selectedTourTime={selectedTourTime}
                  selectedTourDate={selectedTourDate}
                  selectProperty={selectProperty}
                />
              </div>
            </div>
            <div className={`${styles.ButtonAction}`}>
              <Button
                onClick={(e) => props.setAddTourFormOpen(false)}
                className="CancelPopupBU"
              >
                Cancel
              </Button>
              {!props?.is_edit && (
                <Button className="SubmitPopupBU" onClick={onSubmit}>
                  Add
                </Button>
              )}
              {props?.is_edit && (
                <Button className="SubmitPopupBU" onClick={onUpdate}>
                  Update
                </Button>
              )}
            </div>
          </div>
        </DialogContentText>
      </DialogContent>
    </>
    )}
    {addPropspectForm && addFormStep === 1 && (
      <StepOneForm
        setOpenAddProspectModal={setAddPropspectForm.bind(this)}
        onSave={onSubmitOne.bind(this)}
        selectedProperty={selectProperty}
      />
    )}

    {addPropspectForm && addFormStep === 2 && (
      <StepTwoForm
        setOpenAddProspectModal={setAddPropspectForm.bind(this)}
        onSave={onSubmitTwo.bind(this)}
        onSubmit={onSubmitTwo.bind(this)}
        isTourOption={false}
      />
    )}
  </>
  );
};

export default OpenTourFrom;
