import React, { useEffect, useState } from "react";
import styles from './logs.module.css';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loader from "../../components/Loader";
import { Helmet } from "react-helmet";
import { Paper, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import { ArrowDown, ArrowUp, Home, Search } from "react-feather";
import Footer from "../../components/Footer";
import { TableLoader } from "../../components/LoaderC";
import axios from 'axios';
import { styled } from "@mui/system";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#fbf7fc',
  },
}));

const NewlineText = (props) => {
  let text = props.text;
  let newText = text.replace(/(?:\r\n|\r|\n)/g, '<br>');
  return <div style={{ whiteSpace: "pre-wrap" }} dangerouslySetInnerHTML={{ __html: newText }}></div>;
}

const Logs = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [contentLoading, setContentLoading] = useState(true);
  const [allData, setAllData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [filterKey, setFilterKey] = useState("");

  useEffect(() => {
    const getLogs = async () => {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
      const timezone_offset_minutesdata = new Date().getTimezoneOffset();
      const timezone_offset_minutes = timezone_offset_minutesdata === 0 ? 0 : -timezone_offset_minutesdata;

      setContentLoading(true);

      axios(process.env.REACT_APP_LOGS_API_URL + 'v1/logs?timezone_offset=' + timezone_offset_minutes, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).then(response => {
        setContentLoading(false);
        setAllData(response.data.data);
      }).catch((err) => {
        setContentLoading(false);
      });
    }

    getLogs();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    let filterDataTemp = allData;

    if (filterKey !== "") {
      let inputVal = filterKey.toLowerCase();
      filterDataTemp = filterDataTemp.filter((item) => {
        return (item.title.toLowerCase().includes(inputVal) || item.description.toLowerCase().includes(inputVal) || item.performed_by.toLowerCase().includes(inputVal) || item.date_str.toLowerCase().includes(inputVal));
      });
    }

    if (orderBy !== '') {
      if (order === 'asc') {
        if (orderBy === 'date')
          filterDataTemp = filterDataTemp.sort((a, b) => a.id > b.id ? 1 : -1);
        if (orderBy === 'title')
          filterDataTemp = filterDataTemp.sort((a, b) => a.title < b.title ? 1 : -1);
        if (orderBy === 'description')
          filterDataTemp = filterDataTemp.sort((a, b) => a.description < b.description ? 1 : -1);
        if (orderBy === 'performed_by')
          filterDataTemp = filterDataTemp.sort((a, b) => a.performed_by < b.performed_by ? 1 : -1);
      }
      if (order === 'desc') {
        if (orderBy === 'date')
          filterDataTemp = filterDataTemp.sort((a, b) => a.id < b.id ? 1 : -1);
        if (orderBy === 'title')
          filterDataTemp = filterDataTemp.sort((a, b) => a.title > b.title ? 1 : -1);
        if (orderBy === 'description')
          filterDataTemp = filterDataTemp.sort((a, b) => a.description > b.description ? 1 : -1);
        if (orderBy === 'performed_by')
          filterDataTemp = filterDataTemp.sort((a, b) => a.performed_by > b.performed_by ? 1 : -1);
      }
    }

    setFilterData(filterDataTemp);
  }, [allData, order, orderBy, filterKey]);

  return <React.Fragment>

    <Helmet title="Logs" />

    <div className={`${styles.pageTitleWrap}`}>
      <Typography display="inline" className={`${styles.pageTitle}`} >Logs</Typography>
      <nav aria-label="breadcrumb">
        <ol className={`${styles.breadcrumb}`}>
          <li className={`${styles.breadcrumbItem}`}><span><Home /></span>Logs</li>
        </ol>
      </nav>
    </div>

    <div className={`${styles.mainCard}`}>
      {contentLoading && <div className={`${styles.LoaderCard}`}><div className="Loader TableLoader"><TableLoader /></div></div>}

      {!contentLoading && <>
        <TableContainer component={Paper} className={`${styles.CustomUITable} CustomUITable`}>
          <div className={`${styles.TableSearchSec}`}>
            <Search />
            <input type="text" placeholder="Search" onChange={(e) => setFilterKey(e.target.value)} />
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell onClick={(e) => { setOrder(prev => prev === 'asc' ? 'desc' : 'asc'); setOrderBy('date'); }} className={`${styles.TableHeadTitle}`}>
                  Date
                  {orderBy === 'date' && (order === 'asc' ? <span><ArrowDown /></span> : <span><ArrowUp /></span>)}
                </TableCell>
                <TableCell onClick={(e) => { setOrder(prev => prev === 'asc' ? 'desc' : 'asc'); setOrderBy('title'); }} className={`${styles.TableHeadTitle}`}>
                  Action Name
                  {orderBy === 'title' && (order === 'asc' ? <span><ArrowDown /></span> : <span><ArrowUp /></span>)}
                </TableCell>
                <TableCell onClick={(e) => { setOrder(prev => prev === 'asc' ? 'desc' : 'asc'); setOrderBy('description'); }} className={`${styles.TableHeadTitle}`}>
                  Action Description
                  {orderBy === 'description' && (order === 'asc' ? <span><ArrowDown /></span> : <span><ArrowUp /></span>)}
                </TableCell>
                <TableCell onClick={(e) => { setOrder(prev => prev === 'asc' ? 'desc' : 'asc'); setOrderBy('performed_by'); }} className={`${styles.TableHeadTitle}`}>
                  Performed By
                  {orderBy === 'performed_by' && (order === 'asc' ? <span><ArrowDown /></span> : <span><ArrowUp /></span>)}
                </TableCell>
                <TableCell className={`${styles.TableHeadTitle}`}>Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filterData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                <StyledTableRow key={row?.id}>
                  <StyledTableCell>
                    <div className={`${styles.DateDiv}`}>
                      <p className={`${styles.DateTB}`}>{row?.date_str}</p>
                      <p className={`${styles.DateTS}`}>{row?.time_str}, {row?.time_str2}</p>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div className={`${styles.DateDiv}`}>
                      <p className={`${styles.ActionName}`}>{row?.title}</p>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div className={`${styles.DateDiv}`}>
                      <NewlineText text={row?.description} />
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div className={`${styles.DateDiv} ${styles.ActionName}`}>
                      <NewlineText text={row?.performed_by} />
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div className={`${styles.DateDiv}`}>
                      <p className={`${styles.DateTB}`}>{((row?.is_tenant === 1) ? 'Tenant' : ((row?.is_prospect === 1) ? 'Prospect' : 'User'))}</p>
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={filterData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, p) => setPage(p)}
          showFirstButton={true}
          showLastButton={true}
          onRowsPerPageChange={(e) => { setRowsPerPage(e.target.value); setPage(0); }}
        />
      </>}
    </div>

    <Footer />
  </React.Fragment>;
}

export default withAuthenticationRequired(Logs, {
  onRedirecting: () => <Loader />,
});