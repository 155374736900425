import React from "react";
import styled from "styled-components/macro";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import Helmet from "react-helmet";

import { Button as MuiButton, Typography } from "@material-ui/core";
import { spacing } from "@material-ui/system";

const Button = styled(MuiButton)(spacing);

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)}px;
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

function AuthLogin() {
  let query = useQuery();
  const { logout } = useAuth0();


  return (
    <Wrapper>
      <Helmet title={'Unauthorized'} />
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        {'Unauthorized'}
      </Typography>
      <Typography component="h2" variant="h5" align="center" gutterBottom>
        {query.get('error_description')}
      </Typography>

      <Button
        onClick={() => logout({ returnTo: window.location.origin })}
        variant="contained"
        color="secondary"
        mt={2}
      >
        Return to website
      </Button>
    </Wrapper>
  );
}

export default AuthLogin;
