import React, { useEffect, useState } from "react";
import styles from './paynow.module.css';
import Helmet from "react-helmet";
import { Parallax, Background } from 'react-parallax';
import { DollarSign, Edit } from "react-feather";
import { Link as Linkto } from "react-router-dom";
import axios from 'axios';
import Loader from "../../components/Loader";
import Page404 from "../auth/Page404";
import moment from 'moment';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import { FormControlLabel, Checkbox, Dialog, DialogTitle, DialogContent, DialogActions, Button, Link } from '@material-ui/core';
import "../../global2.css";
import OtpInput from 'react-otp-input';
import Autocomplete from "react-google-autocomplete";

const brandColor = '#cccccc';
const boxShadow = '0px 0px 10px 0px #649bdd38';
const customStyles = {
  control: (base, state) => ({
    ...base,
    boxShadow: state.isFocused
      ? boxShadow
      : base.boxShadow,
    borderColor: state.isFocused
      ? brandColor
      : base.borderColor,
    '&:hover': {
      borderColor: state.isFocused
        ? brandColor
        : base.borderColor,
      boxShadow: state.isFocused
        ? boxShadow
        : base.boxShadow,
    }
  })
};

const FiveDigitFieldformat = React.forwardRef((props, ref) => {
  return <NumberFormat
    prefix=""
    displayType="input"
    type="text"
    thousandSeparator={false}
    decimalScale={0} {...props} />;
});

function MoneyField(props) {
  return <NumberFormat
    prefix=""
    displayType="input"
    type="text"
    thousandSeparator={true}
    allowNegative={false}
    decimalScale={2}
    fixedDecimalScale
    {...props} />;
}

function PhoneField(props) {
  return <NumberFormat
    prefix=""
    displayType="input"
    type="text"
    format={"(###) ###-####"}
    {...props} />;
}

const schema = yup.object().shape({
  amount_type: yup.mixed().required('This field is Required'),
  amount: yup.string().required('This field is Required').test("non-zero-validate", "This field should be $1 or greater.", (value) => parseInt(value) > 0),
  description: yup.string().required('This field is Required').max(200, 'Description cannot be more than 200 characters'),
  full_name: yup.string().required('This field is Required').max(50, 'Full name cannot be more than 50 characters'),
  email: yup.string().required('This field is reuired').email('Invalid E-mail'),
  address: yup.string(),
  address2: yup.string().max(50, 'Address 2 cannot be more than 50 characters'),
  city: yup.string(),
  state: yup.string(),
  zip: yup.string(),
  term_cond: yup.boolean().required('This field is Required').test('is-agree', 'This field is Required', (value, context) => value === true)
});

const phoneRegExp = /^\(?([0-9]{3})\)?[ ]?([0-9]{3})[-]?([0-9]{4})$/;
const schema2 = yup.object().shape({
  phone: yup.string().required('This field is Required').matches(phoneRegExp, 'Invalid phone number')
});

const schema3 = yup.object().shape({
  otp: yup.string().required('Please enter 6 digit code.').min(6, 'Please enter 6 digit code.').max(6, 'Please enter 6 digit code.')
});

export default function PayNow({ history, match }) {
  const { token } = match.params;
  const [loading, setLoading] = useState(true);
  const [isNotExist, setIsNotExist] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [propertyDet, setPropertyDet] = useState({});
  const [tenantDet, setTenantDet] = useState({});
  const [tenantDet2, setTenantDet2] = useState(null);
  const [totalCharge, setTotalCharge] = useState(0.00);
  const [amountType, setAmountType] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [loginError2, setLoginError2] = useState('');
  const [loginPhone, setLoginPhone] = useState('');

  const { register, handleSubmit, control, errors, setValue, getValues, clearErrors } = useForm({
    resolver: yupResolver(schema), mode: 'onChange'
  });

  const { register: register2, handleSubmit: handleSubmit2, control: control2, errors: errors2 } = useForm({
    resolver: yupResolver(schema2)
  });

  const { register: register3, handleSubmit: handleSubmit3, control: control3, errors: errors3 } = useForm({
    resolver: yupResolver(schema3)
  });

  useEffect(() => {
    let tenantLoginData = localStorage.getItem("tenantLoginData");
    if (tenantLoginData) {
      tenantLoginData = JSON.parse(tenantLoginData);
      let timeDef = Date.now() - tenantLoginData?.time;
      timeDef = Math.floor(timeDef / 1000 / 60 / 60);

      if (timeDef < 24) {
        setModalOpen(false);
      } else {
        setModalOpen(true);
      }
    } else {
      setModalOpen(true);
    }
  }, [setModalOpen]);

  useEffect(() => {
    axios.get(process.env.REACT_APP_PAYMENT_API_URL + 'v1/check-payment-token/' + token).then(response => {
      let resData = response.data.data;
      setLoading(false);
      if (resData.property_id) {
        getPropertyInfo(resData.property_id);
      }

      if (resData.tenant_id) {
        getTenantInfo(resData.tenant_id);
      }

      if (resData.tenant_info_id) {
        getTenantInfoDet(resData.tenant_info_id);
      }

      if (resData.is_exist === 0) {
        setIsNotExist(true);
      }
      if (resData.is_expired === 1) {
        setIsExpired(true);
      }
    }).catch(error => {
      setLoading(false);
    });

    const getTenantInfo = async (id) => {
      await axios.get(process.env.REACT_APP_PAYMENT_API_URL + 'v1/tenant-payment-info/' + id).then(response => {
        let resData = response.data.data;
        setTenantDet(resData);

        setValue('address', resData?.address);
        setValue('address2', resData?.address2);
        setValue('city', resData?.city);
        setValue('zip', resData?.zip);
        setValue('state', resData?.state);


        setValue('addressnew', resData.address + ', ' + resData.city + ', ' + resData.state + ' ' + resData.zip);

        if (resData.balance2 <= 0) {
          setTimeout(() => {
            setValue('amount_type', 'other_amount');
          }, 100);
        }
      });
    }

    const getTenantInfoDet = async (id) => {
      await axios.get(process.env.REACT_APP_PAYMENT_API_URL + 'v1/tenant-payment-info-det/' + id).then(response => {
        let resData = response.data.data;
        setTenantDet2(resData);

        setValue('full_name', resData?.full_name);
        setValue('email', resData?.email);
      });
    }
  }, [token, setValue]);

  const getPropertyInfo = async (id) => {
    await axios.get(process.env.REACT_APP_APPLICANT_API_URL + 'v1/application/property-det/' + id).then(response => {
      const resData = response.data.propertyDet;
      setPropertyDet(resData);
    })
  }

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      let amount = data.amount.replace(/,/g, '');
      amount = parseFloat(amount);

      let postData = { total_amount: amount, description: data?.description, amount: amount, rentRollId: tenantDet?.last_rent_roll_id, tenantId: tenantDet?.id, tenantInfoId: tenantDet2?.id, cus_name: data?.full_name, cus_email: data.email, cus_phone: tenantDet2?.phone, cus_addr1: data?.address, cus_addr2: data?.address2, cus_city: data?.city, cus_state: data.state, cus_zip: data.zip, url_token: token };

      axios.post(process.env.REACT_APP_PAYMENT_API_URL + 'v1/bank-payment-by-api', postData).then(response => {
        setLoading(false);
        let paymentAuthData = { tenantId: tenantDet?.id, amount: amount.toFixed(2) };
        localStorage.setItem("paymentAuthData", JSON.stringify(paymentAuthData));

        window.location.replace(response?.data?.session?.url);

      }).catch((error) => {
        setLoading(false);
      });

    } catch (error) {
      setLoading(false);
    }
  }

  const calulateStripeChrage = () => {
    let totalAmount = 0;
    let sCharge = 0;

    let amount = getValues('amount');
    amount = amount.toString();
    amount = amount.replace(/,/g, '');
    if (amount !== '')
      totalAmount = parseFloat(amount);

    //setStripeCharge(sCharge);
    setTotalCharge(totalAmount + sCharge);
  }

  const getToken = async (data) => {
    setLoginError('');
    setLoginError2('');
    setLoginPhone('');
    setLoading(true);
    await axios.post(process.env.REACT_APP_PAYMENT_API_URL + 'v1/send-login-code', data).then(response => {
      setLoading(false);
      if (response.data.status === 'validation-error') {
        setLoginError(response.data.message);
        return false;
      }
      if (response.data?.resp?.error && response.data?.resp?.error === 'bad.connection') {
        setLoginError('The phone number is invalid.');
        return false;
      }
      if (response.data?.resp?.error) {
        setLoginError(response.data?.resp?.error_description);
        return false;
      }
      if (!response.data?.resp?.error) {
        setModalOpen(false);
        setModalOpen2(true);
        setLoginPhone(response.data?.resp?.phone_number);
      }
    }).catch((error) => {
      setLoading(false);
    });
  }

  const confirmToken = async (data) => {
    setLoading(true);
    setLoginError2('');

    data = { ...data, phone: loginPhone };

    await axios.post(process.env.REACT_APP_PAYMENT_API_URL + 'v1/authenticate-code', data).then(response => {
      setLoading(false);
      if (response.data?.resp?.error) {
        setLoginError2(response.data?.resp?.error_description);
        return false;
      } else {
        let rsData = response.data?.resp;
        localStorage.setItem("tenantLoginData", JSON.stringify({ tenant_info_id: tenantDet2?.id, tenant_phhone: tenantDet2?.phone, access_token: rsData?.access_token, time: Date.now() }));
        setModalOpen2(false);
      }
    }).catch((error) => {
      setLoading(false);
    });
  }

  const resendCode = async () => {
    setLoginError('');
    setLoginError2('');
    setModalOpen2(false);
    setModalOpen(true);
  }

  const [showBillingInfo, setShowBillingInfo] = useState(false);

  const onPlaceSelected = (place) => {

    let address = "";
    let city = "";
    let state = "";
    let postcode = "";

    for (let component of place.address_components) {
      let componentType = component.types[0];
      switch (componentType) {
        case "street_number": {
          address = component.long_name;
          break;
        }
        case "route": {
          address += ' ' + component.short_name;
          break;
        }
        case "postal_code": {
          postcode = component.long_name;
          break;
        }
        case "locality":
          city = component.long_name;
          break;
        case "sublocality_level_1":
          city = component.long_name;
          break;
        case "administrative_area_level_3":
          city = component.long_name;
          break;
        case "administrative_area_level_1": {
          state = component.short_name;
          break;
        }
        default:
          break;
      }

    }

    setValue('address', address);
    setValue('city', city);
    setValue('state', state);
    setValue('zip', postcode);

  }

  return (<React.Fragment>
    {loading && <Loader />}
    {isNotExist && <Page404 />}
    {(!isNotExist && isExpired) && <Page404 />}

    {(!isNotExist && !isExpired) && <>
      <Helmet title="Payment" />

      <Parallax strength={300} className="RentalParallax">
        <Background className="custom-bg">
          <img src="/static/img/rental-app-bg.jpg" alt="fill murray" />
        </Background>
      </Parallax>

      <div className="WhiteBG">

        <div className={`${styles.RentalAppliArea}`}>

          <div className={`${styles.RentalAppliHead}`}>
            {(tenantDet?.theme_options?.image_url !== '') && <img src={tenantDet?.theme_options?.image_url} alt="logo" />}
            {(tenantDet?.theme_options?.image_url === '') && <img src={'/static/img/logo.svg'} alt="logo" />}

            <p className={`${styles.RentalAppliHeadTitle}`}>{propertyDet?.name}</p>
            <p className={`${styles.RentalAppliHeadTitle}`}>Rent Payment</p>
          </div>

          <div className={`${styles.ApplicantSec}`}>
            <p className={`${styles.ApplicantSecTitle}`}>{tenantDet?.balancestr}</p>
            <p className={`${styles.ApplicantSecSubTitle}`}>{(tenantDet?.balance2 < 0) ? 'Over Paid' : 'Balance Due'}</p>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={`${styles.PayNowSec}`}>
                <p className={`${styles.PayNowSecTitle}`}>Payment information</p>
                {(tenantDet?.balance2 <= 0) && <input type="hidden" name="amount_type" ref={register} />}

                {(tenantDet?.balance2 > 0) && <div className={`${styles.ToggleBUFull}`}>
                  <label className={`${styles.FormLabel}`} >Payment amount*</label>
                  <div className={`${styles.CusRadioRow}`}>
                    <label className={`${styles.CusRadio}`}>
                      <input type="radio" name="amount_type" ref={register} control={control} value="total_due" onChange={e => { setAmountType('total_due'); setValue("amount", tenantDet.balance2); calulateStripeChrage(); clearErrors(['amount_type', 'amount']); }} />
                      <div className={`${styles.checkmark}`}>Full balance</div>
                    </label>
                    <label className={`${styles.CusRadio}`}>
                      <input type="radio" name="amount_type" ref={register} control={control} value="other_amount" onChange={e => { setAmountType('other_amount'); setValue("amount", ''); calulateStripeChrage(); clearErrors(['amount_type', 'amount']); }} />
                      <div className={`${styles.checkmark}`}>Other amount</div>
                    </label>
                    {errors.amount_type && <p className={`${styles.ErrorM}`}>{errors.amount_type.message}</p>}
                  </div>
                </div>}

                <div className={`${styles.FormGroupHalf} ${styles.Dollar}`}>
                  <label className={`${styles.FormLabel}`} >Amount*</label>
                  <div className={`${styles.Width100}`}>
                    <p className={`${styles.AccoDollarSign}`}><DollarSign /></p>
                    <Controller
                      name="amount"
                      render={({ onChange, value }) => (<MoneyField styles={customStyles} className={`${styles.formControl}`} value={value} onChange={(value) => { onChange(value); calulateStripeChrage(); }} placeholder="0.00" readOnly={amountType === "total_due"} />)}
                      control={control}
                      ref={register}
                      className={`${styles.formControl}`}
                    />
                    {errors.amount && <p className={`${styles.ErrorM}`}>{errors.amount.message}</p>}
                  </div>
                </div>

                <div className={`${styles.FormGroupHalf}`}>
                  <label className={`${styles.FormLabel}`} >Memo*</label>
                  <div className={`${styles.Width100}`}>
                    <input className={`${styles.formControl}`} type="text" styles={customStyles} name="description" ref={register} />
                    {errors.description && <p className={`${styles.ErrorM}`}>{errors.description.message}</p>}
                  </div>
                </div>

                {/*<div className={`${styles.ChargesSec}`}>
                  <p className={`${styles.ChargesText}`}><span>Transaction Fee:</span> ${stripeCharge.toLocaleString('en', { minimumFractionDigits: 2 })}</p>
                  <p className={`${styles.ChargesText}`}><span>TOTAL Charge:</span> ${totalCharge.toLocaleString('en', { minimumFractionDigits: 2 })}</p>
                </div>*/}

              </div>

              <div className={`${styles.PayNowSec}`}>
                <div className={`${styles.BillingInfoTSection}`}>
                  <p className={`${styles.PayNowSecTitle}`}>Billing Contact & address</p>
                  {!showBillingInfo && <button className={`${styles.EditBillingBU}`} onClick={() => {
                    setShowBillingInfo(!showBillingInfo);
                  }}><Edit /></button>}
                </div>

                {!showBillingInfo && <div className={`${styles.BillingInfoTextSec}`}>
                  <p className={`${styles.BillingTextList}`}>{tenantDet2?.full_name}</p>
                  <p className={`${styles.BillingTextList}`}>{tenantDet2?.email}</p>
                  <p className={`${styles.BillingTextList}`}>{tenantDet?.address}, {tenantDet?.address2}<br />{tenantDet?.city}, {tenantDet?.state} {tenantDet?.zip}</p>
                </div>}


                <div className={!showBillingInfo ? `${styles.BillingInfoFormSec} ${styles.BillingInfoFormSecHide}` : `${styles.BillingInfoFormSec}`}>
                  <div className={`${styles.FormGroupHalf}`}>
                    <label className={`${styles.FormLabel}`} >Full name*</label>
                    <div className={`${styles.Width100}`}>
                      <input className={`${styles.formControl}`} placeholder="Full Name" type="text" styles={customStyles} name="full_name" ref={register} />
                      {errors.full_name && <p className={`${styles.ErrorM}`}>{errors.full_name.message}</p>}
                    </div>
                  </div>
                  <div className={`${styles.FormGroupHalf}`}>
                    <label className={`${styles.FormLabel}`} >Email*</label>
                    <div className={`${styles.Width100}`}>
                      <input className={`${styles.formControl}`} placeholder="example@gmail.com" type="email" styles={customStyles} name="email" ref={register} />
                      {errors.email && <p className={`${styles.ErrorM}`}>{errors.email.message}</p>}
                    </div>
                  </div>

                  <div className={`${styles.FormGroupFull}`}>
                    <label className={`${styles.FormLabel}`} >Address*</label>
                    <div className={`${styles.Width100}`}>
                      <Controller
                        className={`${styles.formControl}`}
                        render={(field) => (<Autocomplete
                          name="addressnew"
                          ref={register}
                          className={`${styles.formControl}`}
                          apiKey={'AIzaSyDrxtVszbIQ4U8rL90V1lDfy5HvN673fcg'}
                          options={{
                            types: ["address"],
                            componentRestrictions: { country: "us" },
                            fields: ["address_components", "geometry"],
                          }}
                          onPlaceSelected={onPlaceSelected}
                          defaultValue={field?.value ? field?.value : ''}
                          onChange={(e) => field.onChange(e)} />)}
                        name="addressnew"
                        control={control}
                        autoComplete="off"
                        type="text"
                      />
                      <input className={`${styles.formControl}`} placeholder="Address" type="hidden" styles={customStyles} name="address" ref={register} />
                      {errors.address && <p className={`${styles.ErrorM}`}>{errors.address.message}</p>}
                    </div>
                  </div>

                  <div className={`${styles.FormGroupFull}`}>
                    <label className={`${styles.FormLabel}`} >Address line 2</label>
                    <div className={`${styles.Width100}`}>
                      <input className={`${styles.formControl}`} placeholder="Address line 2" type="text" styles={customStyles} name="address2" ref={register} />
                    </div>
                  </div>

                  <div className={`${styles.FormGroupHalf} ${styles.FormGroupHalfHide}`}>
                    <label className={`${styles.FormLabel}`} >City*</label>
                    <div className={`${styles.Width100}`}>
                      <input className={`${styles.formControl}`} placeholder="City" type="text" styles={customStyles} name="city" ref={register} />
                      {errors.city && <p className={`${styles.ErrorM}`}>{errors.city.message}</p>}
                    </div>
                  </div>
                  <div className={`${styles.FormGroupHalf} ${styles.FormGroupHalfHide} CusSelect`}>
                    <label className={`${styles.FormLabel}`} >State</label>
                    <div className={`${styles.Width100}`}>
                      <input className={`${styles.formControl}`} placeholder="State" type="text" styles={customStyles} name="state" ref={register} />
                      {errors.state && <p className={`${styles.ErrorM}`}>{errors.state.message}</p>}
                    </div>
                  </div>

                  <div className={`${styles.FormGroupHalf} ${styles.FormGroupHalfHide}`}>
                    <label className={`${styles.FormLabel}`} >Zip code*</label>
                    <div className={`${styles.Width100}`}>
                      <Controller className={`${styles.formControl}`} as={<FiveDigitFieldformat format="#####" />} placeholder="Zip code" control={control} name="zip" ref={register} />
                      {errors.zip && <p className={`${styles.ErrorM}`}>{errors.zip.message}</p>}
                    </div>
                  </div>
                </div>


                <div className={`${styles.FormGroupFull}`}>
                  <label className={`${styles.FormLabel}`} >Terms and Conditions</label>
                  <div className={`${styles.TermsTextSec}`}>
                    <p>I authorize the debit of my checking/ savings account for the amount indicated above. I understand that because this is an electronic transaction, these funds may be withdrawn from my account immediately. If payment is rejected for Non-Sufficient Funds (NSF), I understand that another attempt to process the charge will be made in 30 days, and I agree to an additional $25 charge for each attempt returned NSF, which will be initiated as a separate transaction from the authorized payment.</p>
                    <FormControlLabel
                      label={"I agree to the terms and conditions"}
                      control={
                        <Controller
                          ref={register}
                          name="term_cond"
                          control={control}
                          render={(field) => <Checkbox {...field} onChange={(e) => field.onChange(e.target.checked)} />}
                        />
                      }
                    />
                  </div>
                  {errors.term_cond && <span className={`${styles.TermErrorMM}`}>{errors?.term_cond?.message}</span>}
                </div>

              </div>

              <button type="submit" className={`${styles.RentallAppliSubmitBU}`}>Pay ${totalCharge.toLocaleString('en', { minimumFractionDigits: 2 })}</button>
            </form>
          </div>

        </div>

        <div className={`${styles.FooterWrap}`}>
          <div className={`${styles.footerMain}`}>

            <div className={`${styles.footerMainLeft}`}>
              {(tenantDet?.theme_options?.image_url !== '') && <Linkto to={'/'}><img src={tenantDet?.theme_options?.image_url} alt="logo" /></Linkto>}
              {(tenantDet?.theme_options?.image_url === '') && <Linkto to={'/'}><img src={'/static/img/logo.svg'} alt="logo" /></Linkto>}
            </div>

            <div className={`${styles.footerMainRight}`}>
              <div>
                <h3>CONTACT US</h3>
                <ul>
                  <li><a href={"mailto:" + propertyDet?.email}>{propertyDet?.email}</a></li>
                  <li><a href={"mailto:" + propertyDet?.phone}>{propertyDet?.phone}</a></li>
                </ul>
              </div>
              <div>
                <h3>FIND US</h3>
                <ul>
                  <li>{propertyDet?.address} <br />{propertyDet?.city}, {propertyDet?.state} {propertyDet?.zip}</li>
                </ul>
              </div>
              <div>
                <h3>LEGAL</h3>
                <ul>
                  <li><Linkto to={'/privacy-policy'}>Privacy Policy</Linkto></li>
                  <li><Linkto to={'/eula'}>End User License Agreement</Linkto></li>
                </ul>
              </div>
              <div>
                <ul>
                  <li>PropertyDek © {moment().format("YYYY")}.<br />All rights reserved.</li>
                </ul>
              </div>
            </div>

          </div>
        </div>

      </div>


      <Dialog
        open={modalOpen}
        fullWidth
        maxWidth="xs"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="NewPopUp"
      >
        <form onSubmit={handleSubmit2(getToken)}>
          <DialogTitle id="alert-dialog-title">Welcome to PropertyDek</DialogTitle>
          <DialogContent>
            <p className="PopTokenText">Enter your phone number and we’ll send a text to verify your phone.</p>
            {(loginError !== '') && <span className={`${styles.ErrorM2}`}>{loginError}</span>}
            <div className="PopTokenFormSec">
              <img src={'/static/img/USA-icon.svg'} alt="logo" />
              <p className="FormCodeSec">+1</p>
              <Controller
                name="phone"
                render={({ onChange, value }) => (<PhoneField className="PopTokenInput" value={value} onChange={(e) => { onChange(e); }} />)}
                control={control2}
                ref={register2}
                className="PopTokenInput"
              />
            </div>
            {errors2.phone && <span className={`${styles.ErrorM2}`}>{errors2.phone.message}</span>}
          </DialogContent>
          <DialogActions className={`${styles.ButtonSec}`}>
            <Button type="submit" className="SubmitPopupBUPopPin">Next</Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={modalOpen2}
        fullWidth
        maxWidth="xs"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="NewPopUp"
      >
        <form onSubmit={handleSubmit3(confirmToken)}>
          <DialogTitle id="alert-dialog-title">Confirm token</DialogTitle>
          <DialogContent>
            <p className="PopTokenText">Confirm token by typing in the 6 digit code we sent via text.</p>
            {(loginError2 !== '') && <span className={`${styles.ErrorM2}`}>{loginError2}</span>}
            <div className="PinArea">
              <Controller
                name="otp"
                render={({ onChange, value }) => (<OtpInput
                  value={value}
                  onChange={onChange}
                  numInputs={6}
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => <input {...props} />}
                />)}
                control={control3}
                ref={register3}
              />
              {errors3.otp && <span className={`${styles.ErrorM2}`}>{errors3.otp.message}</span>}
              <p className="PopTokenTText">We sent a code to the following phone number:</p>
              <div className="Poplink">
                <Link className="PopTokenLink">{loginPhone}</Link>
                <Link className="PopTokenLink" onClick={resendCode}>Resend code</Link>
              </div>
            </div>
          </DialogContent>
          <DialogActions className={`${styles.ButtonSec}`}>
            <Button type="submit" className="SubmitPopupBUPopPin">Confirm</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>}
  </React.Fragment >);
}
