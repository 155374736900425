import styles from "../../../../maintenancerequestdetails.module.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-smart-toaster";
import { useAuth0 } from "@auth0/auth0-react";
import { AddLogCallAPI } from "../../../../../../components/AddLogs";
import { Select, FormControl, InputLabel, MenuItem, TextField, IconButton, Button } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { FileText, X } from "react-feather";
import { makeStyles } from "@material-ui/core/styles";

const schema = yup.object().shape({
  assignee_id: yup.string().required("This field is Required"),
  vehicle: yup.string().required("This field is Required"),
  miles: yup.string().required("This field is Required"),
  date: yup.date().required("This field is Required"),
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  input: {
    display: "none",
  },
}));

const VehicleAdd = ({ appEditId, appEditData, requestData, setRequestData, setAddModalOpen, setSnackbarOpen, setSnackbarMsg, setLoading }) => {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const [technicianList, setTechnicianList] = useState([]);
  const [uploadFile, setUploadFile] = useState(null);
  const [isFile, setIsFile] = useState(false);

  const { control, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      assignee_id: "",
      vehicle: "",
      date: dayjs(),
    },
  });

  useEffect(() => {
    if (appEditData) {
      setValue("assignee_id", appEditData?.assignee_id);
      setValue("vehicle", appEditData?.vehicle);
      setValue("miles", appEditData?.miles);
      setValue("date", dayjs(appEditData?.datetime));

      if (appEditData?.file_path && appEditData?.file_path !== "") {
        setIsFile(true);
      }
    }
  }, [appEditData, setValue]);

  useEffect(() => {
    async function getAssigneeList() {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
      axios.get(process.env.REACT_APP_CASE_API_URL + "v1/technicians", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        setTechnicianList(response.data.data);
      }).catch((error) => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
      });
    }
    getAssigneeList();
  }, [getAccessTokenSilently]);

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("assignee_id", data?.assignee_id);
    formData.append("vehicle", data?.vehicle);
    formData.append("miles", data?.miles);
    formData.append("date", dayjs(data?.date).format("YYYY-MM-DD"));

    if (uploadFile) {
      formData.append("receipt", uploadFile);
    }

    setAddModalOpen(false);
    setLoading(true);

    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

    if (appEditId === 0) {
      return axios.post(process.env.REACT_APP_CASE_API_URL + "v1/maintenance-requests/" + requestData?.id + "/appointment-vehicles", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        setLoading(false);

        setSnackbarOpen(true);
        setSnackbarMsg(response.data.message);

        setRequestData((prev) => {
          let vehicles = [response.data.data].concat(prev?.vehicles);
          return { ...prev, vehicles: vehicles };
        });

        let logData = {
          title: "Maintenance request new vehicle is created",
          description: [
            "Name: " + requestData?.title,
            "Request number: " + requestData?.request_no,
            "Property: " + requestData?.property_name,
            "Apartment: " + requestData?.apt_name,
            "Tenant: " + requestData?.tenant_name,
          ],
        };
        AddLogCallAPI(logData, token);
      }).catch((error) => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
        setLoading(false);
      });
    } else {
      formData.append("old_file", appEditData?.file_path);
      axios.post(process.env.REACT_APP_CASE_API_URL + "v1/maintenance-requests/" + requestData?.id + "/appointment-vehicles/" + appEditId, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        setLoading(false);

        setSnackbarOpen(true);
        setSnackbarMsg(response.data.message);

        setRequestData((prev) => {
          let vehicles = prev?.vehicles?.map((i) => {
            if (i?.id === appEditId) {
              return response.data.data;
            }
            return i;
          });
          return { ...prev, vehicles: vehicles };
        });

        let logData = {
          title: "Maintenance request vehicle is updated",
          description: [
            "Name: " + requestData?.title,
            "Request number: " + requestData?.request_no,
            "Property: " + requestData?.property_name,
            "Apartment: " + requestData?.apt_name,
            "Tenant: " + requestData?.tenant_name,
          ],
        };
        AddLogCallAPI(logData, token);
      }).catch((error) => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
        setLoading(false);
      });
    }
  };

  const fileHandler = (e) => {
    setUploadFile(e.target.files[0]);
  };

  return (<div className={`${styles.ModalFormGroup}`}>
    <form onSubmit={handleSubmit(onSubmit)}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className={`${styles.PopFormGroup}`}>
          <div className={`${styles.FormGroupFull} FormGroup`}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select Technician*</InputLabel>
              <Controller
                name="assignee_id"
                control={control}
                render={(field) => (
                  <Select
                    label="Select Technician*"
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                  >
                    {technicianList.map((item) => {
                      return (<MenuItem value={item?.value}>{item?.label}</MenuItem>);
                    })}
                  </Select>
                )}
              />
            </FormControl>
            {errors.assignee_id && (<p className={`${styles.ErrorM}`}>{errors.assignee_id.message}</p>)}
          </div>
        </div>
        <div className={`${styles.PopFormGroup}`}>
          <div className={`${styles.FormGroupFull} FormGroup`}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select Vehicle*</InputLabel>
              <Controller
                name="vehicle"
                control={control}
                render={(field) => (
                  <Select
                    label="Select Vehicle*"
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                  >
                    <MenuItem value={"Company Vehicle"}>Company Vehicle</MenuItem>
                    <MenuItem value={"Personal Vehicle"}>Personal Vehicle</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
            {errors.vehicle && (<p className={`${styles.ErrorM}`}>{errors.vehicle.message}</p>)}
          </div>
        </div>
        <div className={`${styles.PopFormGroupTwo}`}>
          <div className={`${styles.Width48} FormGroup`}>
            <Controller
              className={`${styles.formControl}`}
              name="miles"
              control={control}
              render={(field) => (
                <TextField
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  id="outlined-basic"
                  label="Miles*"
                  variant="outlined"
                />
              )}
            />
            {errors.miles && (<p className={`${styles.ErrorM}`}>{errors.miles.message}</p>)}
          </div>
          <div className={`${styles.Width48}`}>
            <div className={`${styles.DatePick} TimePick`}>
              <Controller
                className={`${styles.formControl}`}
                name="date"
                control={control}
                render={(fields) => (
                  <MobileDatePicker {...fields} label="Select Date" />
                )}
              />
              {errors.date && (<p className={`${styles.ErrorM}`}>{errors.date.message}</p>)}
            </div>
          </div>
        </div>
        <div>
          <input accept="image/*,.pdf" className={classes.input} id="icon-button-file" type="file" onChange={fileHandler} />
          <label htmlFor="icon-button-file">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              className={`${styles.UploadReceiptBU}`}
            >
              <FileText /> Upload Receipt
            </IconButton>
          </label>

          {uploadFile && <div className={`${styles.UploadFileList}`}>
            <FileText />
            <p>{uploadFile?.name}</p>
            <button onClick={(e) => setUploadFile(false)}>
              <X />
            </button>
          </div>}

          {(!uploadFile && isFile) && <div className={`${styles.UploadFileList}`}>
            <FileText />
            <p>Receipt</p>
            <button onClick={(e) => setIsFile(false)}>
              <X />
            </button>
          </div>}
        </div>
      </LocalizationProvider>
      <div className={`${styles.ButtonAction}`}>
        <Button className="CancelPopupBU" onClick={(e) => setAddModalOpen(false)}>Cancel</Button>
        <Button className="SubmitPopupBU" type="submit">{appEditId ? "Update" : "Add"}</Button>
      </div>
    </form>
  </div>);
};

export default VehicleAdd;