import { Check, ChevronDown } from 'react-feather';
import styles from '../rentalappplication.module.css';
import { Accordion, AccordionDetails, AccordionSummary, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import NumberFormat from 'react-number-format';
import axios from 'axios';
import { usePlacesWidget } from 'react-google-autocomplete';

const TenDigitField = React.forwardRef((props, ref) => {
    return <NumberFormat
        customInput={TextField}
        prefix=""
        displayType="input"
        type="text"
        thousandSeparator={false}
        decimalScale={0}
        {...props}
    />;
});

const MoneyField = React.forwardRef((props, ref) => {
    return <NumberFormat
        customInput={TextField}
        prefix=""
        displayType="input"
        type="text"
        thousandSeparator={true}
        allowNegative={false}
        decimalScale={0}
        {...props}
    />;
});

const EmploymentAccordion = ({ expanded, stepData, setStepData, accordinChange, lastStep, setLastStep, setCurrentStep, getNextStep, setPanelExpanded, panelRef, applicantId }) => {
    const { handleSubmit, control, setValue, formState: { errors }, clearErrors } = useForm({
        mode: "onChange",
        defaultValues: {
            employement: '',
            employer_name: '',
            employer_address: '',
            years_employed: '',
            job_title: '',
            income_per_year: '',
            hire_date: null,
        }
    });

    useEffect(() => {
        if (stepData) {
            if (stepData?.employement) {
                setValue('employement', stepData?.employement);
            }
            if (stepData?.employer_name) {
                setValue('employer_name', stepData?.employer_name);
            }
            if (stepData?.employer_address) {
                setValue('employer_address', stepData?.employer_address);
            }
            if (stepData?.years_employed) {
                setValue('years_employed', stepData?.years_employed);
            }
            if (stepData?.job_title) {
                setValue('job_title', stepData?.job_title);
            }
            if (stepData?.income_per_year) {
                setValue('income_per_year', stepData?.income_per_year);
            }
            if (stepData?.hire_date) {
                setValue('hire_date', dayjs(stepData?.hire_date));
            }
        }
    }, [stepData, setValue]);

    const { ref: autocompleteRef } = usePlacesWidget({
        apiKey: "AIzaSyDrxtVszbIQ4U8rL90V1lDfy5HvN673fcg",
        options: {
            types: ["address"],
            componentRestrictions: { country: "us" },
            fields: ["address_components", "geometry"],
        },
        onPlaceSelected: (place) => {
            let address = "";
            let city = "";
            let state = "";
            let postcode = "";
            for (let component of place.address_components) {
                let componentType = component.types[0];
                switch (componentType) {
                    case "street_number": {
                        address = component.long_name;
                        break;
                    }
                    case "route": {
                        address += ' ' + component.short_name;
                        break;
                    }
                    case "postal_code": {
                        postcode = component.long_name;
                        break;
                    }
                    case "locality": {
                        if (city === '')
                            city = component.long_name;
                        break;
                    }
                    case "sublocality_level_1": {
                        city = component.long_name;
                        break;
                    }
                    case "administrative_area_level_1": {
                        state = component.short_name;
                        break;
                    }
                    default:
                        break;
                }
            }
            let full_address = address + ', ' + city + ', ' + state + ' ' + postcode;
            setValue('employer_address', full_address);
            clearErrors();
        }
    });

    const onSubmit = (data) => {
        data = { ...data, hire_date: dayjs(data?.hire_date).format('YYYY-MM-DD') };

        let postData = { ...data, is_employement_submitted: 1 };
        setStepData(prev => {
            return { ...prev, ...postData };
        });

        if (applicantId) {
            axios.post(process.env.REACT_APP_APPLICANT_API_URL + 'v1/save-applicants/' + applicantId, postData);
        }

        let currentStepTemp = getNextStep(5);
        setCurrentStep(currentStepTemp);
        setPanelExpanded('panel' + currentStepTemp);
        if (lastStep < currentStepTemp) {
            setLastStep(currentStepTemp);
        }
    }

    return (<Accordion expanded={expanded === 'panel5'} onChange={accordinChange('panel5', 5)} disabled={lastStep < 5}>
        <AccordionSummary expandIcon={<ChevronDown />} aria-controls="panel1bh-content" id="panel1bh-header" ref={panelRef}>
            <div className={`${styles.FormAccoHead}`}>
                <p className={`${styles.FormAccoHeadTitle}`}>Employment</p>
                <div className={((lastStep > 5) ? `${styles.AccoCheck} ${styles.Active}` : `${styles.AccoCheck}`)}><Check /></div>
            </div>
        </AccordionSummary>
        <AccordionDetails className={`${styles.CussAccoRow}`}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={`${styles.AccoFormSec}`}>

                    <div className={`${styles.FormGroupHalf} FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>Employment<span>*</span></label>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select</InputLabel>
                            <Controller
                                name="employement"
                                control={control}
                                rules={{
                                    required: 'This field is required.',
                                }}
                                render={(field) => (
                                    <Select
                                        {...field}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }}
                                    >
                                        < MenuItem value={'Full-Time'}>{'Full-Time'}</MenuItem>
                                        < MenuItem value={'Part-Time'}>{'Part-Time'}</MenuItem>
                                    </Select>
                                )}
                            />
                            {errors.employement && <p className={`${styles.ErrorM}`}>{errors.employement.message}</p>}
                        </FormControl>
                    </div>
                    <div className={`${styles.FormGroupHalf} FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>Employer name<span>*</span></label>
                        <FormControl fullWidth>
                            <Controller
                                name="employer_name"
                                control={control}
                                rules={{
                                    required: "This field is required.",
                                    maxLength: {
                                        value: 50,
                                        message: "Employer name can not be more than 50 characters."
                                    }
                                }}
                                render={(field) => (
                                    <TextField
                                        {...field}
                                        label="Name"
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }}
                                    />
                                )}
                            />
                            {errors.employer_name && <p className={`${styles.ErrorM}`}>{errors.employer_name.message}</p>}
                        </FormControl>
                    </div>

                    <div className={`${styles.FormGroupHalf} FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>Employer address<span>*</span></label>
                        <FormControl fullWidth>
                            <Controller
                                name="employer_address"
                                control={control}
                                rules={{
                                    required: 'This field is required.',
                                    maxLength: {
                                        value: 50,
                                        message: "Employer address can not be more than 50 characters."
                                    }
                                }}
                                render={(field) => (
                                    <TextField
                                        {...field}
                                        inputRef={autocompleteRef}
                                        autoComplete='off'
                                        placeholder=""
                                        label="Address"
                                    />
                                )}
                            />
                            {errors.employer_address && <p className={`${styles.ErrorM}`}>{errors.employer_address.message}</p>}
                        </FormControl>
                    </div>
                    <div className={`${styles.FormGroupHalf} FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>Years employed<span>*</span></label>
                        <FormControl fullWidth>
                            <Controller
                                name="years_employed"
                                control={control}
                                rules={{
                                    required: 'This field is required.',
                                    masLength: {
                                        value: 2,
                                        message: "Years employeed can not be more than 2 digits."
                                    },
                                }}
                                render={(field) => (
                                    <TenDigitField
                                        {...field}
                                        format="##"
                                        label="No. of years"
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }}
                                    />
                                )}
                            />
                            {errors.years_employed && <p className={`${styles.ErrorM}`}>{errors.years_employed.message}</p>}
                        </FormControl>
                    </div>

                    <div className={`${styles.FormGroupHalf} FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>Job title<span>*</span></label>
                        <FormControl fullWidth>
                            <Controller
                                name="job_title"
                                control={control}
                                rules={{
                                    required: "This field is required.",
                                    maxLength: {
                                        value: 50,
                                        message: "Employer name can not be more than 50 characters."
                                    }
                                }}
                                render={(field) => (
                                    <TextField
                                        {...field}
                                        label="Job title"
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }}
                                    />
                                )}
                            />
                            {errors.job_title && <p className={`${styles.ErrorM}`}>{errors.job_title.message}</p>}
                        </FormControl>
                    </div>
                    <div className={`${styles.FormGroupHalf} FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>Income per year in USD<span>*</span></label>
                        <FormControl fullWidth>
                            <Controller
                                name="income_per_year"
                                control={control}
                                rules={{
                                    required: 'This field is required.',
                                    maxLength: {
                                        value: 7,
                                        message: "Income per year can not be more than 6 digits."
                                    },
                                }}
                                render={(field) => (
                                    <MoneyField
                                        {...field}
                                        label="Amount"
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }}
                                    />
                                )}
                            />
                            {errors.income_per_year && <p className={`${styles.ErrorM}`}>{errors.income_per_year.message}</p>}
                        </FormControl>
                    </div>

                    <div className={`${styles.FormGroupHalf} FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>Hire date<span>*</span></label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Controller
                                name="hire_date"
                                control={control}
                                rules={{ required: 'This field is required.' }}
                                render={(field) => (
                                    <DatePicker
                                        {...field}
                                        renderInput={(params) => (
                                            <TextField {...params} />
                                        )}
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                        {errors.hire_date && <p className={`${styles.ErrorM}`}>{errors.hire_date.message}</p>}
                    </div>

                    <div className={`${styles.AccoFormBUsec}`}>
                        <button type="submit" className={`${styles.applicantNextBU}`}>NEXT</button>
                    </div>

                </div>
            </form>
        </AccordionDetails>
    </Accordion >);
}

export default EmploymentAccordion;