import React, { useState } from "react";
import styles from "./moreOption.module.css";
import { Typography } from "@mui/material";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import { Bell, Calendar, CreditCard, FileText, LogOut, MessageSquare, Search, User, UserCheck, Users } from "react-feather";
import { communicationChatBoxOpen } from "../../redux/actions/communicationChatBoxAction";
import { useDispatch, useSelector } from "react-redux";
const Prospect = () => {

  const dispatch = useDispatch()
  const isCommunicationChatBoxOpen = useSelector((store) => store.communicationChatBoxReducer)

const communicationChatBoxHandler = () => {
  dispatch(communicationChatBoxOpen(!isCommunicationChatBoxOpen))
}

  return (
    <React.Fragment>
      <Helmet title="More Option" />

      <div className={`${styles.pageTitleWrap}`}>
        <Typography display="inline" className={`${styles.pageTitle}`}>
          More
        </Typography>
      </div>
      <div className={`${styles.MainCard}`}>
        <div className={`${styles.MoreList}`}>
          <Typography variant="button" display="block"  className={`${styles.MoreCateTitle}`}>People</Typography>
          <div className={`${styles.OptionCard}`}>
            <Link to={"/people/prospects"} className={`${styles.OptionCardUnder}`}>
              <UserCheck />
              <p>Prospect</p>
            </Link>
          </div>
          <div className={`${styles.OptionCard}`}>
            <Link to={"/people/tenants"} className={`${styles.OptionCardUnder}`}>
              <User />
              <p>Tenant</p>
            </Link>
          </div>
          <div className={`${styles.OptionCard}`}>
            <Link to={"/people/users"} className={`${styles.OptionCardUnder}`}>
              <Users />
              <p>User</p>
            </Link>
          </div>
        </div>
        <div className={`${styles.MoreList}`}>
          <Typography variant="button" display="block"  className={`${styles.MoreCateTitle}`}>Cases</Typography>
          <div className={`${styles.OptionCard}`}>
            <Link to={"/cases/maintenance-request"} className={`${styles.OptionCardUnder}`}>
              <Calendar />
              <p>Maintenance Request</p>
            </Link>
          </div>
        </div>
        <div className={`${styles.MoreList}`}>
          <Typography variant="button" display="block"  className={`${styles.MoreCateTitle}`}>Communication</Typography>
          <div className={`${styles.OptionCard}`}>
            <Link to={"/schedule"} className={`${styles.OptionCardUnder}`}>
              <Calendar />
              <p>Schedule</p>
            </Link>
          </div>
          <div className={`${styles.OptionCard}`} onClick={communicationChatBoxHandler}>
            <span  className={`${styles.OptionCardUnder}`}>
              <MessageSquare />
              <p>Chat</p>
            </span>
          </div>
        </div>
        <div className={`${styles.MoreList}`}>
          <Typography variant="button" display="block"  className={`${styles.MoreCateTitle}`}>Operations</Typography>
          <div className={`${styles.OptionCard}`}>
            <Link to={"/leases"} className={`${styles.OptionCardUnder}`}>
              <FileText />
              <p>Leases</p>
            </Link>
          </div>
          <div className={`${styles.OptionCard}`}>
            <Link to={"/payments"} className={`${styles.OptionCardUnder}`}>
              <CreditCard />
              <p>Payments</p>
            </Link>
          </div>
        </div>
        <div className={`${styles.MoreList}`}>
          <Typography variant="button" display="block"  className={`${styles.MoreCateTitle}`}>Smart Devices</Typography>
          <div className={`${styles.OptionCard}`}>
            <Link to={"/smart-devices/list"} className={`${styles.OptionCardUnder}`}>
              <FileText />
              <p>Manage Devices</p>
            </Link>
          </div>
          <div className={`${styles.OptionCard}`}>
            <Link to={"/smart-devices/alerts"} className={`${styles.OptionCardUnder}`}>
              <Bell />
              <p>Device Alerts</p>
            </Link>
          </div>
        </div>
        <div className={`${styles.MoreList}`}>
          <Typography variant="button" display="block"  className={`${styles.MoreCateTitle}`}>Logs</Typography>
          <div className={`${styles.OptionCard}`}>
            <Link to={"/logs"} className={`${styles.OptionCardUnder}`}>
              <Search />
              <p>Logs</p>
            </Link>
          </div>          
        </div>
        <div className={`${styles.OptionCardFull}`}>
          <Link to={"/logout"} className={`${styles.OptionCardUnderFull}`}>
            <LogOut />
            <p>Logout</p>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Prospect;
