import { DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import styles from "../tenantdetails.module.css";
import { useEffect } from "react";
import { ArrowLeft, X } from "react-feather";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import NumberFormat from "react-number-format";
import axios from "axios";
import { toast } from "react-smart-toaster";
import { useAuth0 } from "@auth0/auth0-react";
import { AddLogCallAPI } from "../../../components/AddLogs";

function PhoneField(props) {
    return (
        <NumberFormat
            customInput={TextField}
            prefix=""
            displayType="input"
            type="tel"
            format={"(###) ###-####"}
            {...props}
        />
    );
}

const phoneRegExp = /^\(?([0-9]{3})\)?[ ]?([0-9]{3})[-]?([0-9]{4})$/;
const schema = yup.object().shape({
    first_name: yup.string().required("This field is Required").max(50, "First name cannot be more than 50 characters"),
    last_name: yup.string().required("This field is Required").max(50, "Last name cannot be more than 50 characters"),
    email: yup.string().required("This field is Required").email("Invalid E-mail").max(50, "Email cannot be more than 50 characters"),
    phone: yup.string().required("This field is Required").matches(phoneRegExp, "Invalid phone number"),
});

const TenantEditForm = ({ tenantId, tenantData, setTenantData, setLoading, setSnackbarOpen, setSnackbarMsg, setShowAddForm, property, apartment, isAddForm }) => {
    const { getAccessTokenSilently } = useAuth0();

    const { handleSubmit, control, errors, setValue } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            property_id: "",
            apt_id: "",
        },
    });

    useEffect(() => {
        if (!isAddForm) {
            setValue('first_name', tenantData?.first_name);
            setValue('last_name', tenantData?.last_name);
            setValue('email', tenantData?.email);
            setValue('phone', tenantData?.phone);
        }
        setValue('property_id', property?.id);
        setValue('apt_id', apartment?.id);
    }, [tenantData, isAddForm, property?.id, apartment?.id, setValue]);

    const onUpdate = async (data) => {
        setLoading(true);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        const postData = { first_name: data.first_name, last_name: data.last_name, email: data.email, phone: data.phone, };

        return axios.put(process.env.REACT_APP_TENANT_API_URL + "v1/tenants/" + tenantData?.id + "/update-new", postData, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            setLoading(false);
            setShowAddForm(false);
            setSnackbarOpen(true);
            setSnackbarMsg(response.data.message);

            setTenantData(prev => {
                return { ...prev, all_tenants: response.data.data };
            });

            let updateTenant = response.data.data.filter((i) => parseInt(i.id) === parseInt(tenantData?.id));

            let logData = {
                title: "Tenant is updated",
                description: [
                    "Name: " + updateTenant[0]?.full_name,
                    "Phone: " + updateTenant[0]?.phone,
                    "Property: " + property?.name,
                    "Apartment: " + apartment?.apt_no,
                ],
            };
            AddLogCallAPI(logData, token);
        }).catch((error) => {
            setLoading(false);
            if (typeof error.response !== "undefined") {
                if (error.response.data.error_type === "validation_error") {
                    if (typeof error.response.data.messages.phone !== "undefined") {
                        toast.error(error.response.data.messages.phone[0]);
                    }
                } else {
                    toast.error(error.response.data?.message);
                }
            }
        });
    }

    const onSubmit = async (data) => {
        setLoading(true);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        const postData = { first_name: data.first_name, last_name: data.last_name, email: data.email, phone: data.phone, };

        return axios.post(process.env.REACT_APP_TENANT_API_URL + "v1/tenants/" + tenantId + "/add-new", postData, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            setLoading(false);
            setShowAddForm(false);
            setSnackbarOpen(true);
            setSnackbarMsg(response.data.message);

            setTenantData(prev => {
                return { ...prev, all_tenants: response.data.data };
            });

            let addTenantTemp = response.data.data;

            let logData = {
                title: "Tenant is updated",
                description: [
                    "Name: " + addTenantTemp[addTenantTemp?.length - 1]?.full_name,
                    "Phone: " + addTenantTemp[addTenantTemp?.length - 1]?.phone,
                    "Property: " + this.state.data?.property?.name,
                    "Apartment: " + this.state.data?.apartment?.apt_no,
                ],
            };
            AddLogCallAPI(logData, token);
        }).catch((error) => {
            setLoading(false);
            if (typeof error.response !== "undefined") {
                if (error.response.data.error_type === "validation_error") {
                    if (typeof error.response.data.messages.phone !== "undefined") {
                        toast.error(error.response.data.messages.phone[0]);
                    }
                } else {
                    toast.error(error.response.data?.message);
                }
            }
        });
    }

    return (<>
        <DialogTitle id="alert-dialog-title">
            <button className={`${styles.BackAddBU}`} onClick={(e) => setShowAddForm(false)}><ArrowLeft /></button>
            <span>{isAddForm ? 'Add Tenant' : 'Edit Tenant'}</span>
            <button onClick={(e) => setShowAddForm(false)}>
                <X />
            </button>
        </DialogTitle>
        <DialogContent>
            <div className={`${styles.ProfileCardEditSec}`}>
                <form onSubmit={handleSubmit(isAddForm ? onSubmit : onUpdate)}>
                    <div className={`${styles.ProfileIMGSec}`}>
                        <div className={`${styles.ProfileIMGCard}`}>
                            <div className={`${styles.ProfileIMG}`}>
                                <img src={tenantData?.user_image !== "" ? tenantData?.user_image : "/static/img/avatars/profile-avatar-small.png"} className={`${styles.roundedcircle}`} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.ProfileEditFormSec} ${styles.ProfileEditFirstSec}`}>
                        <div className={`${styles.EditFormHalfSec} FormGroup`}>
                            <Controller
                                name={`first_name`}
                                control={control}
                                render={(field) => (
                                    <TextField {...field} label="First Name*" variant="outlined" error={errors?.first_name} />
                                )}
                            />
                            {errors.first_name && (<p className={`${styles.ErrorM}`}>{errors.first_name.message}</p>)}
                        </div>
                        <div className={`${styles.EditFormHalfSec} FormGroup`}>
                            <Controller
                                name={`last_name`}
                                control={control}
                                render={(field) => (
                                    <TextField {...field} label="Last Name*" variant="outlined" error={errors?.last_name} />
                                )}
                            />
                            {errors.last_name && (<p className={`${styles.ErrorM}`}>{errors.last_name.message}</p>)}
                        </div>
                        <div className={`${styles.EditFormHalfSec} FormGroup`}>
                            <Controller
                                name={`email`}
                                control={control}
                                render={(field) => (
                                    <TextField {...field} label="Email*" variant="outlined" error={errors?.email} />
                                )}
                            />
                            {errors.email && (<p className={`${styles.ErrorM}`}>{errors.email.message}</p>)}
                        </div>
                        <div className={`${styles.EditFormHalfSec} FormGroup`}>
                            <Controller
                                name={`phone`}
                                control={control}
                                render={(field) => (
                                    <PhoneField {...field} label="Phone*" variant="outlined" error={errors?.phone} />
                                )}
                            />
                            {errors.phone && (<p className={`${styles.ErrorM}`}>{errors.phone.message}</p>)}
                        </div>
                        <div className={`${styles.EditFormHalfSec} FormGroup`}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Property*</InputLabel>
                                <Controller
                                    name="property_id"
                                    control={control}
                                    render={(field) => (
                                        <Select
                                            {...field}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Property*"
                                        >
                                            <MenuItem value={property?.id}>{property?.name}</MenuItem>
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        </div>
                        <div className={`${styles.EditFormHalfSec} FormGroup`}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Apartment*</InputLabel>
                                <Controller
                                    name="apt_id"
                                    control={control}
                                    render={(field) => (
                                        <Select
                                            {...field}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label={"Apartment*"}
                                        >
                                            <MenuItem value={apartment?.id}>{apartment?.apt_no}</MenuItem>
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        </div>
                    </div>
                    <div className={`${styles.ButtonArea}`}>
                        <button className={`${styles.ButtonVoiletLine}`} type="button" onClick={(e) => setShowAddForm(false)}>Cancel</button>
                        <button className={`${styles.ButtonVoiletSolid}`} type="submit">{isAddForm ? 'Add' : 'Save'}</button>
                    </div>
                </form>
            </div>
        </DialogContent>

        {/* <div className={`${styles.EditProfileSec}`}>
            <div className={`${styles.AddFormTitleSec}`}>
                <button className={`${styles.BackAddBU}`} onClick={(e) => setShowAddForm(false)}><ArrowLeft /></button>
                <p className={`${styles.AddPropertyTitle}`}>{isAddForm ? 'Add Tenant' : 'Edit Tenant'}</p>
            </div>
            <div className={`${styles.ProfileIMGSec}`}>
                <div className={`${styles.ProfileIMGCard}`}>
                    <div className={`${styles.ProfileIMG}`}>
                        <img src={tenantData?.user_image !== "" ? tenantData?.user_image : "/static/img/avatars/profile-avatar-small.png"} className={`${styles.roundedcircle}`} alt="" />
                    </div>
                </div>
            </div>
            <form onSubmit={handleSubmit(isAddForm ? onSubmit : onUpdate)}>

                <div className={`${styles.ProfileEditFormSec}`}>
                    <div className={`${styles.EditFormHalfSec} FormGroup`}>
                        <Controller
                            name={`first_name`}
                            control={control}
                            render={(field) => (
                                <TextField {...field} label="First Name*" variant="outlined" error={errors?.first_name} />
                            )}
                        />
                        {errors.first_name && (<p className={`${styles.ErrorM}`}>{errors.first_name.message}</p>)}
                    </div>
                    <div className={`${styles.EditFormHalfSec} FormGroup`}>
                        <Controller
                            name={`last_name`}
                            control={control}
                            render={(field) => (
                                <TextField {...field} label="Last Name*" variant="outlined" error={errors?.last_name} />
                            )}
                        />
                        {errors.last_name && (<p className={`${styles.ErrorM}`}>{errors.last_name.message}</p>)}
                    </div>
                    <div className={`${styles.EditFormHalfSec} FormGroup`}>
                        <Controller
                            name={`email`}
                            control={control}
                            render={(field) => (
                                <TextField {...field} label="Email*" variant="outlined" error={errors?.email} />
                            )}
                        />
                        {errors.email && (<p className={`${styles.ErrorM}`}>{errors.email.message}</p>)}
                    </div>
                    <div className={`${styles.EditFormHalfSec} FormGroup`}>
                        <Controller
                            name={`phone`}
                            control={control}
                            render={(field) => (
                                <PhoneField {...field} label="Phone*" variant="outlined" error={errors?.phone} />
                            )}
                        />
                        {errors.phone && (<p className={`${styles.ErrorM}`}>{errors.phone.message}</p>)}
                    </div>
                    <div className={`${styles.EditFormHalfSec} FormGroup`}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Property*</InputLabel>
                            <Controller
                                name="property_id"
                                control={control}
                                render={(field) => (
                                    <Select
                                        {...field}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Property*"
                                    >
                                        <MenuItem value={property?.id}>{property?.name}</MenuItem>
                                    </Select>
                                )}
                            />
                        </FormControl>
                    </div>
                    <div className={`${styles.EditFormHalfSec} FormGroup`}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Apartment*</InputLabel>
                            <Controller
                                name="apt_id"
                                control={control}
                                render={(field) => (
                                    <Select
                                        {...field}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label={"Apartment*"}
                                    >
                                        <MenuItem value={apartment?.id}>{apartment?.apt_no}</MenuItem>
                                    </Select>
                                )}
                            />
                        </FormControl>
                    </div>
                </div>
                <div className={`${styles.ButtonSec}`}>
                    <button className={`${styles.CancelBU}`} onClick={(e) => setShowAddForm(false)}>Cancel</button>
                    <button className={`${styles.AddBU}`} >{isAddForm ? 'Add' : 'Update'}</button>
                </div>
            </form>
        </div> */}
    </>)
}


export default TenantEditForm;