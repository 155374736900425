import styles from '../../maintenancerequestdetails.module.css';
import { useEffect, useState } from 'react';
import dayjs from "dayjs";


const HistoryTab = ({ histories }) => {
    const [historyList, setHistoryList] = useState([]);

    useEffect(() => {
        if (histories) {
            setHistoryList(histories);
        }
    }, [histories]);

    return (<>
        {historyList?.map(item => {
            return <div className={`${styles.BorderDivSumm}`} key={item?.id}>
                <div className={`${styles.HistoryTitle}`}>
                    <img src="/static/img/RollCheck.svg" alt="" />
                    <p className={`${styles.HistoryTitleCon}`}>{item.title} <span>{dayjs(item.created_at).format("L")}</span></p>
                </div>
                <div className={`${styles.BorderDivSummHis}`}>
                    <p className={`${styles.SummCommBodyText}`} dangerouslySetInnerHTML={{ __html: item.description }}></p>
                </div>
            </div>
        })}
        {historyList.length === 0 && <div className={`${styles.NoDataMain}`}>
            <div className={`${styles.NoDataIMG}`}>
                <img src="/static/img/nodata.svg" alt="No Data" />
            </div>
            <p className={`${styles.NoDataText}`}>No Data Found</p>
        </div>}
    </>);
}

export default HistoryTab;