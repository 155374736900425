import { ChevronLeft } from "react-feather";
import styles from "../dashboard.module.css";

const MonthlyCollectionList = ({ openedRentCard, setOpenedRentCard, title, total, data }) => {

    return (<div className={!openedRentCard ? `${styles.RentCollectDE}` : `${styles.RentCollectDE} ${styles.Active}`}>
        <div className={`${styles.RentCollectDEHead}`}>
            <button className={`${styles.Back}`} onClick={(e) => setOpenedRentCard(false)}><ChevronLeft /></button>
            <div>
                <p className={`${styles.DEHeadTitle}`}>{title}</p>
                <p className={`${styles.DEHeadSubTitle}`}>${total}</p>
            </div>
        </div>
        <div className={`${styles.InsideCardContentSec}`}>
            {data?.length === 0 && (<div className={`${styles.NoDataSec}`}>
                <div className={`${styles.NoDataIMG}`}><img src="/static/img/no-payment.png" alt="" /></div>
                <p className={`${styles.NoDataText}`}>Hey there!</p>
                <p className={`${styles.NoDataTextSub}`}>Payment List is Empty.</p>
            </div>)}

            {data?.map(item => {
                return <div className={`${styles.InsideCardSec}`}>
                    <p className={`${styles.Name}`}>{item?.tenant_name}</p>
                    <p className={`${styles.Unit}`}>{item?.apt_name}</p>
                    <p className={`${styles.Payment}`}>${item?.amount}</p>
                </div>
            })}
        </div>


    </div>);
}

export default MonthlyCollectionList;