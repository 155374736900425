import React, { useState } from "react";
import ReactPlayer from "react-player";
import { Img } from "react-image";
import styles from "../communication-chat.module.css";
import { Download, X } from "react-feather";

const MessageFileViewer = (props) => {
  const [fullImage, setFullImage] = useState(false);

  const handleDownload = (fileUrl) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    link.download = "file"; // Set the desired filename for the downloaded file
    link.click();
  };

  const renderFileContent = (fileUrl, contentType) => {
    if (contentType.startsWith("image/")) {
      return (
        <div onClick={() => setFullImage(true)} className={`${styles.ImgFile}`}>
          <Img src={fileUrl} alt="Image" />
        </div>
      );
    } else if (contentType.startsWith("video/")) {
      return (
        <div className={`${styles.ImgFile}`}>
          <ReactPlayer url={fileUrl} controls />
        </div>
      );
    } else if (contentType.startsWith("audio/")) {
      return <audio src={fileUrl} controls />;
    } else {
      return (
        <div>
          <p className={`${styles.NoFileTT}`}>
            This file type is not supported for preview.
          </p>
          <button
            className={`${styles.NoFileDrBU}`}
            onClick={() => handleDownload(fileUrl)}
          >
            Download File
          </button>
        </div>
      );
    }
  };

  return (
    <>
      {props.media.map((file, index) => (
        <div key={index}>
          {/* <p>abc</p> */}
          {renderFileContent(file.url, file.content_type)}
          {fullImage && (
            <div
              className={
                fullImage === true
                  ? `${styles.ImgFilePops} ${styles.Active}`
                  : `${styles.ImgFilePops}`
              }
            >
              <Img src={file.url} alt="Image" />
              <button
                onClick={() => setFullImage(false)}
                className={`${styles.CancelImgBU}`}
              >
                <X />
              </button>
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default MessageFileViewer;
