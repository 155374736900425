import { useState, useEffect } from "react";
import styles from "../maintenancerequestdetails.module.css";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { toast } from "react-smart-toaster";
import { Clock } from "react-feather";
import { FormControl, InputLabel, MenuItem, ListSubheader, Select } from "@mui/material";
import { AddLogCallAPI } from "../../../components/AddLogs";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const schema = yup.object().shape({
  tenant_id: yup.string().required("This field is Required"),
  category: yup.string().required("This field is Required"),
  contact_time_val: yup.string().required("This field is Required"),
  contact_time_start: yup.date().required("This field is Required"),
  contact_time_end: yup.date().required("This field is Required"),
  priority: yup.string().required("This field is Required"),
});

const categoryGroupOptions = [
  { label: "Electrical", parent: 1 },
  { value: "Electrical Socket", label: "Electrical Socket", parent: 0, category: "Electrical", img_path: "/static/img/ElectricalSocket.svg" },
  { value: "Light Switch/Dimmer", label: "Light Switch/Dimmer", parent: 0, category: "Electrical", img_path: "/static/img/Dimmer.svg" },
  { label: "Plumbing", parent: 1 },
  { value: "Leak", label: "Leak", parent: 0, category: "Plumbing", img_path: "/static/img/Leak.svg" },
  { value: "Drain clogged", label: "Drain clogged", parent: 0, category: "Plumbing", img_path: "/static/img/DrainClogged.svg" },
  { value: "Water temperature Issue", label: "Water temperature Issue", parent: 0, category: "Plumbing", img_path: "/static/img/WaterTemperature.svg" },
  { label: "Appliances", parent: 1 },
  { value: "Stove/Oven Issue", label: "Stove/Oven Issue", parent: 0, category: "Appliances", img_path: "/static/img/StoveOven.svg" },
  { value: "Refrigerator Issue", label: "Refrigerator Issue", parent: 0, category: "Appliances", img_path: "/static/img/Refrigerator.svg" },
  { value: "Washer/Dryer Issue", label: "Washer/Dryer Issue", parent: 0, category: "Appliances", img_path: "/static/img/Washer.svg" },
  { label: "Other", parent: 1 },
  { value: "Other Issue", label: "Other Issue", parent: 0, category: "Other", img_path: "/static/img/other.svg" },
];

const EditMaintenanceForm = ({ setAddModalOpen, setLoading, setSnackbarOpen, setSnackbarMsg, setRequestData, requestData }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [tenantList, setTenantList] = useState([]);

  const { handleSubmit, control, setValue } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      contact_time_start: dayjs().set("hour", 7).set("minute", 0),
      contact_time_end: dayjs().set("hour", 19).set("minute", 0),
      tenant_id: "",
      category: "",
      contact_time_val: "",
      priority: "",
    }
  });

  useEffect(() => {
    if (requestData) {
      setValue('tenant_id', requestData?.tenant_id);
      setValue('category', requestData?.sub_category);
      setValue('contact_time_val', requestData?.contact_time_val);
      setValue('contact_time_start', dayjs(dayjs().format('YYYY-MM-DD') + " " + requestData?.contact_time_start));
      setValue('contact_time_end', dayjs(dayjs().format('YYYY-MM-DD') + " " + requestData?.contact_time_end));
      setValue('priority', requestData?.priority);
    }
  }, [requestData, setValue]);

  useEffect(() => {
    async function fetchTenants() {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
      await axios.get(process.env.REACT_APP_CASE_API_URL + "v1/property-tenant-list", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        let tFinalList = [];

        let templistNew = response.data.data;

        templistNew?.map((item) => {
          tFinalList.push({ label: item.label, parent: 1 });
          item?.options?.map((subItem) => {
            tFinalList.push({
              value: subItem.value,
              label: subItem.label,
              parent: 0,
              propertyId: item?.id,
            });
            return false;
          });

          return false;
        });

        setTenantList(tFinalList);
      }).catch((error) => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
      });
    }

    fetchTenants();
  }, [getAccessTokenSilently]);

  const onSubmit = async (data) => {
    let postData = {};

    postData = { ...postData, tenant_id: data?.tenant_id, priority: data?.priority, description: data?.description, contact_time_val: data?.contact_time_val };
    if (data?.tenant_id) {
      let selectTenant = tenantList?.filter((i) => i?.value === parseInt(data?.tenant_id));
      postData = { ...postData, property_id: selectTenant[0]?.propertyId };
    }
    if (data?.contact_time_start) {
      postData = { ...postData, contact_time_start: dayjs(data.contact_time_start).format("LT") };
    }
    if (data?.contact_time_end) {
      postData = { ...postData, contact_time_end: dayjs(data.contact_time_end).format("LT") };
    }
    if (data?.category) {
      let selectCat = categoryGroupOptions?.filter((i) => i?.value === data.category);
      postData = { ...postData, category: selectCat[0]?.category, sub_category: data?.category };
    }

    setLoading(true);

    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

    return axios.post(process.env.REACT_APP_CASE_API_URL + "v1/maintenance-requests/" + requestData?.id, postData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      setLoading(false);

      setSnackbarOpen(true);
      setSnackbarMsg(response.data.message);

      setAddModalOpen(false);

      setRequestData(prev => {
        return { ...prev, ...response.data.data };
      });

      let logData = {
        title: "New maintenance request is created",
        description: [
          "Name: " + response.data.data.title,
          "Request number: " + response.data.data.request_no,
          "Property: " + response.data.data.property_name,
          "Apartment: " + response.data.data.apt_name,
          "Tenant: " + response.data.data.tenant_name,
        ],
      };
      AddLogCallAPI(logData, token);
    }).catch((error) => {
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
      setLoading(false);
    });
  }

  return (<div className={`${styles.ProfileCardEditSec}`}>
    <form onSubmit={handleSubmit(onSubmit)}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className={`${styles.FormGroupFull} FormGroup`}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select Tenant*</InputLabel>
            <Controller
              name="tenant_id"
              control={control}
              render={(field) => (
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select Tenant*"
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                >
                  {tenantList.map((item) => {
                    if (item?.parent === 1)
                      return (<ListSubheader className={`${styles.GropSelectTitle}`}>{item?.label}</ListSubheader>);
                    else
                      return (<MenuItem value={item?.value} className={`${styles.GropSelectList}`}>{item?.label}</MenuItem>);
                  })}
                </Select>
              )}
            />
          </FormControl>
        </div>
        <div className={`${styles.FormGroupFull} FormGroup`}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select Category*</InputLabel>
            <Controller
              name="category"
              control={control}
              render={(field) => (
                <Select
                  label="Select Category*"
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                >
                  {categoryGroupOptions.map((item) => {
                    if (item?.parent === 1)
                      return (<ListSubheader className={`${styles.GropSelectTitle}`}>{item?.label}</ListSubheader>);
                    else
                      return (<MenuItem value={item?.value} className={`${styles.GropSelectList}`}>
                        <img src={item?.img_path} alt="" />
                        {item?.label}
                      </MenuItem>);
                  })}
                </Select>
              )}
            />
          </FormControl>
        </div>
        <div className={`${styles.FormGroupFull} FormGroup`}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select the best time to contact*</InputLabel>
            <Controller
              name="contact_time_val"
              control={control}
              render={(field) => (
                <Select
                  label="Select best time to contact*"
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                >
                  <MenuItem value={"Weekdays"}>Weekdays</MenuItem>
                  <MenuItem value={"Weekends"}>Weekends</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </div>
        <div
          className={`${styles.FormGroupFull} ${styles.FormTimeZone}`}
        >
          <div className={`${styles.TimePick} TimePick`}>
            <Clock className={`${styles.ClockIcon}`} />
            <Controller
              control={control}
              name="contact_time_start"
              render={(props) => (
                <MobileTimePicker
                  {...props}
                  label="Select Time"
                />
              )}
            />
          </div>
          <div className={`${styles.TimePick} TimePick`}>
            <Clock className={`${styles.ClockIcon}`} />
            <Controller
              control={control}
              name="contact_time_end"
              render={(props) => (
                <MobileTimePicker
                  {...props}
                  label="Select Time"
                />
              )}
            />
          </div>
        </div>
        <div className={`${styles.PopFormGroupTwo}`}>
          <div className={`${styles.FormGroupHalf} FormGroup`}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Importance*</InputLabel>
              <Controller
                name="priority"
                control={control}
                render={(field) => (
                  <Select
                    label="Importance*"
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                  >
                    <MenuItem value={1}>Low</MenuItem>
                    <MenuItem value={2}>Medium</MenuItem>
                    <MenuItem value={3}>High</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </div>
        </div>
      </LocalizationProvider>
      <div className={`${styles.ButtonArea}`}>
        <button className={`${styles.ButtonVoiletLine}`} type="button" onClick={(e) => setAddModalOpen(false)}>Cancel</button>
        <button type="submit" className={`${styles.ButtonVoiletSolid}`}>Update</button>
      </div>
    </form>
  </div>);
};

export default EditMaintenanceForm;