import React from "react";
import styles from './error404.module.css'; import Header from '../../components/HeaderPublic'
import Footer from '../../components/FooterPublic';

export default function RentalApplicationInvalid() {

  return (<>
    <Header />
    <div className="WhiteBG">
      <div className={`${styles.ErrorMain}`}>
        <div className={`${styles.ErrorMainSec}`}>
          <img src="/static/img/404.jpg" alt="" className={`${styles.SuccessImg}`} />
          <p className={`${styles.ErrorTitle}`}>Page not found</p>
          <p className={`${styles.PaymentSuccessTitleSub}`}>The page you are looking for might have been removed.</p>
          <a href="/" className={`${styles.LearnMBUBanner}`}>Return to website</a>
        </div>
      </div>
      <Footer />
    </div>
  </>);
}
