
const incrementProspectCounter = (counts)=> {

    return {
        type: 'increment',
        payload: counts
        
    }
}
const resetProspectCounter = ()=> {

    return {
        type: 'reset',
        
        
    }
}

export  {incrementProspectCounter,resetProspectCounter}