import { ChevronDown } from "react-feather";
import { AppBar, Box, FormControl, InputLabel, MenuItem, Select, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import TenantTab from "./Tabs/TenantTab";
import NotesTab from "./Tabs/NotesTab";
import RequestTab from "./Tabs/RequestTab";
import PaymentsTab from "./Tabs/PaymentsTab";
import PermissionsTab from "./Tabs/PermissionsTab";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (<div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>{value === index && <Box p={3}>{children}</Box>}</div>);
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const SimpleTabs = ({ tenantData, setTenantData, setLoading, setSnackbarMsg, setSnackbarOpen }) => {
    const classes = useStyles();
    const [tabValue, setTabValue] = useState(0);
    const [moreOption, setMoreOption] = useState("");

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs
                    value={tabValue}
                    onChange={(e, t) => { setMoreOption(""); setTabValue(t); }}
                    aria-label="simple tabs example"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    <Tab label="Requests" {...a11yProps(0)} />
                    <Tab label="Payments" {...a11yProps(1)} />
                    {/* <Tab label="Leases" {...a11yProps(2)} /> */}
                    <Tab label="Permission" {...a11yProps(2)} />
                    <FormControl className="MoreOptions">
                        <InputLabel>More</InputLabel>
                        <ChevronDown className="MoreIcon" />
                        <Select value={moreOption} onChange={(e) => { setMoreOption(e.target.value); setTabValue(e.target.value); }} displayEmpty>
                            {tenantData?.all_tenants?.map((item, index) => {
                                return <MenuItem value={(index + 3)}>{item?.first_name + " " + item?.last_name}</MenuItem>
                            })}
                            <MenuItem value={(tenantData?.all_tenants?.length + 3)}>Notes</MenuItem>
                        </Select>
                    </FormControl>
                </Tabs>
            </AppBar>

            <TabPanel value={tabValue} index={0}>
                <RequestTab tenantData={tenantData} setTenantData={setTenantData.bind(this)} setLoading={setLoading.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <PaymentsTab tenantData={tenantData} setTenantData={setTenantData.bind(this)} setLoading={setLoading.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} />
            </TabPanel>
            {/* <TabPanel value={tabValue} index={2}>
                Leases
            </TabPanel> */}
            <TabPanel value={tabValue} index={2}>
                <PermissionsTab tenantData={tenantData} setTenantData={setTenantData.bind(this)} setLoading={setLoading.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} />
            </TabPanel>

            {tenantData?.all_tenants?.map((item, index) => {
                return (<TabPanel value={tabValue} index={(index + 3)}>
                    <TenantTab tenantData={item} tenantAllData={tenantData} setTenantData={setTenantData.bind(this)} property={tenantData?.property} apartment={tenantData?.apartment} setLoading={setLoading.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} />
                </TabPanel>)
            })}

            <TabPanel value={tabValue} index={(tenantData?.all_tenants?.length + 3)}>
                <NotesTab tenantData={tenantData} setTenantData={setTenantData.bind(this)} setLoading={setLoading.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} />
            </TabPanel>
        </div>
    );
}

export default SimpleTabs;