import { ChevronDown } from "react-feather";
import styles from "../communication-chat.module.css";
import { useState } from "react";

const TenantList = (props) => {

  // const formatPhoneNumber = (phoneNumber) => {
  //   const cleaned = ("" + phoneNumber).replace(/\D/g, "");
  //   const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  //   if (match) {
  //     return "(" + match[1] + ") " + match[2] + "-" + match[3];
  //   }
  //   return cleaned;
  // };

  const getTenantsOfApt = (tnts) => {
    
    
    const tenants =  props.typeaheadProspectTenantList.filter((t) => t.conversation_id === tnts.conversation_id);
    return tenants.length

  }

  return (
    <>
      {props.tenantList.length > 0 &&
        props.tenantList.map((tenant, i) => {
          return (
            <div
              key={tenant.property_id}
              onClick={() => props.toggleAccordionForTenant(tenant.property_id)}
              className={
                props.selectedProperty === tenant.property_id &&
                props.accordionOnForTenant
                  ? `${styles.accordionOn} ${styles.Open}`
                  : `${styles.accordionOn}`
              }
            >
              <p className={`${styles.ListAccoudionTitle}`}>
                <ChevronDown /> {tenant?.propertyName} (
                {tenant?.tenants?.length} members)
              </p>
              {tenant.tenants.length > 0 > 0 &&
                tenant.tenants.map((t) => {
                  return (
                    <div key={t.id}>
                      <div
                        className={
                          t.id === props?.prospectTenantDetails?.id
                            ? `${styles.activeTenant} ${styles.CommuListSec}`
                            : `${styles.CommuListSec}`
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          props.setDetails(t);
                        }}
                      >
                        <div className={`${styles.CommuListDeSec}`}>
                          <p className={`${styles.ProsTenantList}`}>
                            <span>
                              {t?.first_name}&nbsp;{t?.last_name}
                            </span>
                          </p>
                          <p className={`${styles.ProsTenantListDe}`}>
                            <span>{t.apt_no}</span>
                            <span>{getTenantsOfApt(t)} Tenants</span>
                          </p>
                        </div>
                      </div>

                      {/* <p
                        onClick={(e) => {
                          e.stopPropagation();
                          props.setDetails(t);
                        }}
                        className={
                          t.id === props?.prospectTenantDetails?.id
                            ? `${styles.activeTenant} ${styles.ProsTenantList}`
                            : `${styles.ProsTenantList}`
                        }
                      >
                        <span className={`${styles.Unit}`}>{t.apt_no}:</span>
                        <span className={`${styles.Name}`}>
                          {t?.first_name}&nbsp;{t?.last_name}
                        </span>{" "}
                        <span className={`${styles.Phone}`}>
                          {formatPhoneNumber(t.phone)}
                        </span>
                      </p> */}
                    </div>
                  );
                })}
              {tenant.tenants.length === 0 && (
                <div className={`${styles.NoDataSecOnlyText}`}>
                  <img src="/static/img/chatIcon.png" alt=""/>
                  <p>No tenant here!</p>
                </div>
              )}
            </div>
          );
        })}
    </>
  );
};

export default TenantList;
