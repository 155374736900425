import React, { useEffect, useState } from "react";
import { Link as Linkto, useHistory } from "react-router-dom";
import styles from "./properties.module.css";
import axios from "axios";
import Loader from "../../components/Loader";
import { Typography, FormControl, InputLabel, Select, MenuItem, Snackbar, Slide, IconButton, Button, CardMedia, CardContent, Card, Dialog, DialogTitle, DialogContent, TablePagination } from "@mui/material";
import { ArrowLeft, ArrowRight, Filter, Home, MapPin, X } from "react-feather";
import Helmet from "react-helmet";
import Footer from "../../components/Footer";
import { Search, Plus } from "react-feather";
import { TableLoader } from "../../components/LoaderC";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import AddPropertyForm from "./components/AddPropertyForm";

const filterViewList = [{ value: "all", label: "All" },
{ value: "Active", label: "Active" },
{ value: "Archived", label: "Archived" }];

const Properties = () => {
  const redirect = useHistory();
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [contentLoading, setContentLoading] = useState(true);
  const [filterData, setFilterData] = useState({ searchKey: "", view: "", state: "", city: "" });
  const [filterCityList, setFilterCityList] = useState([]);
  const [filterStateList, setFilterStateList] = useState([]);
  const [allPropertyList, setAllPropertyList] = useState([]);
  const [propertyList, setPropertyList] = useState([]);
  const [page, setPage] = useState(0);
  const [showAddForm, setShowAddForm] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [filterOpen, setFilterOpen] = useState(false);
  const [perPage, setPerPage] = useState(6);

  useEffect(() => {
    async function getAllCities() {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

      axios(process.env.REACT_APP_PROPERTY_API_URL + "v1/property-cities", {
        headers: {
          Authorization: `Bearer ${token}`
        },
      }).then((response) => {
        setFilterCityList([{ value: "", label: "Any" }].concat(response.data.data));
      });
    }
    getAllCities();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    async function getAllState() {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

      axios(process.env.REACT_APP_PROPERTY_API_URL + "v1/property-states", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        setFilterStateList([{ value: "all", label: "Any" }].concat(response.data.data));
      });
    }
    getAllState();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    async function getProperties() {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

      axios(process.env.REACT_APP_PROPERTY_API_URL + "v1/properties", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        setContentLoading(false);
        setAllPropertyList(response.data.data);
        setPropertyList(response.data.data);
      }).catch((error) => {
        setContentLoading(false);
      });
    }
    getProperties();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (allPropertyList.length > 0) {
      let filteredData = allPropertyList;

      if (filterData.searchKey !== "") {
        let inputVal = filterData.searchKey;
        inputVal = inputVal.toLowerCase();
        filteredData = filteredData.filter((item) => item.name.toLowerCase().includes(inputVal) || item.fulladdress.toLowerCase().includes(inputVal));
      }

      if (filterData.view !== "" && filterData.view !== "all") {
        if (filterData.view === "Active") {
          filteredData = filteredData.filter((i) => i.is_archived === 0);
        }
        if (filterData.view === "Archived") {
          filteredData = filteredData.filter((i) => i.is_archived === 1);
        }
      }

      if (filterData.state !== "" && filterData.state !== "all") {
        filteredData = filteredData.filter((i) => i.state === filterData.state);
      }

      if (filterData.city !== "" && filterData.city !== "all") {
        filteredData = filteredData.filter((i) => i.city === filterData.city);
      }

      setPropertyList(filteredData);
    }
  }, [filterData, allPropertyList]);

  const onAdd = (data) => {
    setSnackbarMsg(data.message);
    setSnackbarOpen(true);

    setAllPropertyList((prev) => {
      return [data.data].concat(prev);
    });
  }

  return (<React.Fragment>
    <Helmet title="Properties" />
    <div className={`${styles.pageTitleWrap}`}>
      <Typography display="inline" className={`${styles.pageTitle}`}>Properties</Typography>
      <nav aria-label="breadcrumb">
        <ol className={`${styles.breadcrumb}`}>
          <li className={`${styles.breadcrumbItem}`}><span><Home /></span>Properties</li>
          <li className={`${styles.breadcrumbItem} ${styles.active}`}>All Properties</li>
        </ol>
      </nav>
    </div>

    <div className={`${styles.BodyCon}`}>
      <div className={`${styles.mainCardDiv}`}>
        <div className={`${styles.mainCard}`}>
          {contentLoading && (<div className="Loader TableLoader"><TableLoader /></div>)}

          <div className={`${styles.Row}`}>

            {!contentLoading && <>
              {!showAddForm && <div className={`${styles.SearchFilterSec}`}>
                <div className={`${styles.SearchCon}`}>
                  <div className={`${styles.SearchConSec}`}>
                    <Search />
                    <input type="text" placeholder="Search" onChange={(e) => setFilterData(prev => {
                      return { ...prev, searchKey: e.target.value }
                    })} />
                  </div>
                </div>

                <div className={`${styles.FilterSec}`}>
                  <div className={filterOpen === true ? `${styles.FilterFieldSec} ${styles.Open}` : `${styles.FilterFieldSec} ${styles.Open2}`}>
                    <p className={`${styles.FilterTitle}`}>Filters<span onClick={(e) => setFilterOpen(false)}><ArrowRight /></span></p>
                    <div className={`${styles.FilterCard} ${styles.Select} ${styles.Width220} CusSelectFilter Filter`}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Status</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Status"
                          onChange={(e) => setFilterData(prev => {
                            return { ...prev, view: e.target.value }
                          })}
                        >
                          {filterViewList.map((i) => {
                            return <MenuItem key={i.value} value={i.value}>{i.label}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                    </div>

                    <div className={`${styles.FilterCard} ${styles.Select} ${styles.Width150} CusSelectFilter Filter`}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">City</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="City"
                          onChange={(e) => setFilterData(prev => {
                            return { ...prev, city: e.target.value }
                          })}
                        >
                          {filterCityList.map((i) => {
                            return <MenuItem key={i.value} value={i.value}>{i.label}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <div className={`${styles.FilterCard} ${styles.Select} ${styles.Width150} CusSelectFilter Filter`}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">State</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="State"
                          onChange={(e) => setFilterData(prev => {
                            return { ...prev, state: e.target.value }
                          })}
                        >
                          {filterStateList.map((i) => {
                            return <MenuItem value={i.value}>{i.label}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <button className={`${styles.FilterBu}`} onClick={(e) => setFilterOpen(true)}><Filter /></button>
                  {isAuthenticated && user["https://propertydek.com/permissions"].indexOf("property:create") > -1 && <button className={`${styles.AddNoteBu}`} onClick={(e) => setShowAddForm(true)}><Plus /><span>Property</span></button>}
                </div>
              </div>}

              <div className={`${styles.NotesRow}`}>
                {propertyList.map((item, index) => {
                  return (perPage === -1 || (index >= page * perPage && index < page * perPage + perPage)) ? (<Linkto key={index} to={`/properties/details/${item.id}`} className={`${styles.cardSec}`}>
                    <Card>
                      <CardContent>
                        <div className={`${styles.ImgConSec}`}>
                          <CardMedia sx={{ height: 130 }} image={item?.new_image && item?.new_image !== "" ? item?.new_image : "/static/img/whimage.jpg"} title="" className={`${styles.PropertyImg}`} />
                          <div className={`${styles.PropertyDeSec}`}>
                            <div className={`${styles.PropertyNameSec}`}>
                              <Typography gutterBottom variant="h5" component="div" className={`${styles.PropertyTitle}`}>{item?.name}</Typography>
                              {item?.is_archived === 1 && <span className={`${styles.ArchiveBadge}`}>Archived</span>}
                            </div>
                            <Typography variant="body2" color="text.secondary" className={`${styles.TextWithIcon}`}><MapPin />{item?.fulladdress}</Typography>
                            <div className={`${styles.VacantSec}`}>
                              <Typography variant="button">{item?.no_of_units?.total} Units</Typography>
                              <div className={`${styles.VacantStatus}`}>
                                <div className={`${styles.StatusBar}`} style={{ width: `${item?.no_of_units?.occupiedPercentage}%` }}></div>
                              </div>
                              <div className={`${styles.VacantTextSec}`}>
                                <Typography variant="overline">{item?.no_of_units?.rented} OCCUPIED</Typography>
                                <Typography variant="overline">{item?.no_of_units?.vacant} AVAILABLE</Typography>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={`${styles.CardBuSection}`}>
                          <Button className={`${styles.CardActionBu}`} onClick={(e) => { e.preventDefault(); e.stopPropagation(); localStorage.setItem("propertyFilterData", item?.id); redirect.push('/people/tenants'); }}>
                            <img src="/static/img/tenant.svg" alt="" />
                            <Typography variant="overline" className={`${styles.Width100}`}>Tenants</Typography>
                          </Button>
                          <Button className={`${styles.CardActionBu}`} onClick={(e) => { e.preventDefault(); e.stopPropagation(); localStorage.setItem("propertyFilterData", item?.id); redirect.push('/payments'); }}>
                            <img src="/static/img/payments.svg" alt="" />
                            <Typography variant="overline" className={`${styles.Width100}`}>Payments</Typography>
                          </Button>
                          <Button className={`${styles.CardActionBu}`} onClick={(e) => { e.preventDefault(); e.stopPropagation(); localStorage.setItem("propertyFilterData", item?.id); redirect.push('/cases/maintenance-request'); }}>
                            <img src="/static/img/RequestM-Black.svg" alt="" />
                            <Typography variant="overline" className={`${styles.Width100}`}>Maintenance</Typography>
                          </Button>
                          <Button className={`${styles.CardActionBu}`} onClick={(e) => { e.preventDefault(); e.stopPropagation(); localStorage.setItem("propertyFilterData", item?.id); redirect.push('/smart-devices/list'); }}>
                            <img src="/static/img/smart-device.svg" alt="" />
                            <Typography variant="overline" className={`${styles.Width100}`}>Smart Devices</Typography>
                          </Button>
                        </div>
                      </CardContent>
                    </Card>
                  </Linkto>) : null;
                })}

                {propertyList.length > 0 && (<div className="Pagination TablePaginationNew List">
                  <TablePagination
                    count={propertyList.length}
                    color="primary"
                    page={page}
                    rowsPerPage={perPage}
                    onPageChange={(e, v) => setPage(v)}
                    rowsPerPageOptions={[
                      { label: '6', value: 6 },
                      { label: '12', value: 12 },
                      { label: '24', value: 24 },
                      { label: 'All', value: -1 },
                    ]}
                    onRowsPerPageChange={(e) => {
                      setPage(0);
                      setPerPage(e.target.value);
                    }}
                    labelRowsPerPage={'Properties per Page:'}
                  />
                </div>)}

                {!contentLoading && propertyList.length === 0 && (<div className={`${styles.NoDataMain}`}>
                  <div className={`${styles.NoDataIMG}`}>
                    <img src="/static/img/no-property.png" alt="No Data" />
                  </div>
                  <p className={`${styles.NoDataText}`}>Sorry!</p>
                  <p className={`${styles.NoDataTextSub}`}>Properties are not listed at this time.</p>
                </div>)}

              </div>
            </>}
          </div>
        </div>
      </div>
    </div>

    <Dialog
      open={showAddForm}
      fullWidth
      maxWidth="sm"
      onClose={(e) => {
        setShowAddForm(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp MobileForm"
    >
      <DialogTitle id="alert-dialog-title">
        <button className={`${styles.BackAddBU}`} onClick={(e) => setShowAddForm(false)}><ArrowLeft /></button>
        Add Property
        <button onClick={(e) => setShowAddForm(false)}>
          <X />
        </button>
      </DialogTitle>
      <DialogContent>
        <AddPropertyForm closeForm={setShowAddForm.bind(this)} onSubmitLocal={onAdd.bind(this)} />
      </DialogContent>
    </Dialog>

    <Snackbar
      open={snackbarOpen}
      onClose={(e) => setSnackbarOpen(false)}
      TransitionComponent={Slide}
      message={snackbarMsg}
      autoHideDuration={10000}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={(e) => setSnackbarOpen(false)}
        >
          <X />
        </IconButton>
      }
    />

    <Footer />
  </React.Fragment>);
}

export default withAuthenticationRequired(Properties, {
  onRedirecting: () => <Loader />,
});