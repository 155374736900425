import React, { useEffect, useState } from "react";
import styles from "./devicealert.module.css";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import { Checkbox, FormControlLabel, Typography, Snackbar, Slide, IconButton, TablePagination } from "@mui/material";
import { Home, Trash2, X } from "react-feather";
import axios from "axios";
import { toast } from "react-smart-toaster";
import moment from "moment";
import Helmet from "react-helmet";
import Loader from "../../../components/Loader";
import Footer from "../../../components/Footer";
import { TableLoader } from "../../../components/LoaderC";
import FilterComponent from "./FilterComponent";
import PubNubChannel from "../components/pubnub/pubnub-channel";

const SmartDevicesAlerts = () => {
  const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  const [allAlerts, setAllAlerts] = useState([]);
  const [alertList, setAlertList] = useState([]);
  const [selectedAlertLength, setSelectedAlertLength] = useState(0);
  const [selectedAlerts, setSelectedAlerts] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [filterData, setFilterData] = useState({ searchKey: "", is_archived: "", property: "", apartment: "", });
  const [page, setPage] = useState(0);
  const [ownerId, setOwnerId] = useState("");
  const [perPage, setPerPage] = useState(8);


  useEffect(() => {
    async function getAllEvents() {
      let userMetadata = user["https://propertydek.com/user_metadata"];
      setOwnerId(userMetadata?.ownerID);
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

      await axios.get(process.env.REACT_APP_COMMUNICATION_MAIN_API_URL + "v1/smart-devices/smart-devices-alerts/" + userMetadata?.ownerID, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((response) => {
        setContentLoading(false);
        setAllAlerts(response.data.data);
      }).catch((error) => {
        setContentLoading(false);
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
      });
    }

    getAllEvents();
  }, [getAccessTokenSilently, user]);

  useEffect(() => {
    let filteredList = allAlerts;

    filteredList = filteredList?.filter(i => i?.device_id);

    if (filterData.searchKey !== "") {
      let inputVal = filterData.searchKey;
      inputVal = inputVal.toLowerCase();
      filteredList = filteredList.filter((item) => {
        return (item?.apt_no?.toLowerCase().includes(inputVal) || item?.location?.includes(inputVal) || item?.name?.includes(inputVal) || item?.product_type?.includes(inputVal) || item?.property_name?.includes(inputVal) || item?.title?.toLowerCase().includes(inputVal));
      });
    }

    if (filterData.is_archived !== "" && filterData.is_archived !== "all") {
      filteredList = filteredList.filter((i) => i.is_archived === filterData.is_archived);
    }

    if (filterData.is_archived !== "") {
      if (filterData.is_archived !== "all") {
        filteredList = filteredList.filter((i) => i.is_archived === filterData.is_archived);
      }
    } else {
      filteredList = filteredList.filter((i) => i.is_archived === 0);
    }

    if (filterData.property !== "" && filterData.property !== "all") {
      filteredList = filteredList.filter((i) => i.property_id === filterData.property);
    }

    if (filterData.apartment !== "" && filterData.apartment !== "all") {
      filteredList = filteredList.filter((i) => i.apt_id === filterData.apartment);
    }

    setAlertList(filteredList);
  }, [allAlerts, filterData]);

  const checkboxCng = (e) => {
    let selectedAlertsTemp = selectedAlerts;
    let chkValue = parseInt(e.target.value);
    if (e.target.checked) {
      if (selectedAlertsTemp.indexOf(chkValue) === -1)
        selectedAlertsTemp = selectedAlertsTemp.concat([chkValue]);
    } else {
      let indexOf = selectedAlertsTemp.indexOf(chkValue);
      if (indexOf > -1) selectedAlertsTemp.splice(indexOf, 1);
    }
    setSelectedAlertLength(selectedAlertsTemp.length);
    setSelectedAlerts(selectedAlertsTemp);
  };

  const checkboxAllCng = (e) => {
    let selectedAlertsTemp = [];
    if (e.target.checked) {
      selectedAlertsTemp = alertList?.filter((i) => i.is_archived === 0);
      selectedAlertsTemp = selectedAlertsTemp?.map((item) => {
        return parseInt(item?.id);
      });
    }

    setSelectedAlertLength(selectedAlertsTemp.length);
    setSelectedAlerts(selectedAlertsTemp);
  };

  const deleteAlerts = async () => {
    setLoading(true);
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

    await axios.post(process.env.REACT_APP_COMMUNICATION_MAIN_API_URL + "v1/smart-devices/smart-devices-alerts-archive", { alertIds: selectedAlerts }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((response) => {
      setLoading(false);

      setSnackbarMsg(selectedAlertLength > 1 ? selectedAlertLength + " alerts archived successfully!" : selectedAlertLength + " alert archived successfully!"
      );
      setSnackbarOpen(true);

      let alertListTemp = alertList.filter((i) => selectedAlerts.indexOf(parseInt(i.id)) === -1);
      setAlertList(alertListTemp);

      setSelectedAlerts([]);
      setSelectedAlertLength(0);
    }).catch((error) => {
      setLoading(false);
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
    });
  };

  const updateDeviceStatus = (deviceId, deviceStatus, bizCode, data) => {
    try {
      if (!data) {
        return;
      }

      if (deviceId) {
        const newAlerts = [data, ...allAlerts];
        setAllAlerts([...newAlerts]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (<React.Fragment>
    {loading && <Loader />}
    <Helmet title="Device Alert" />
    <div className={`${styles.pageTitleWrap}`}>
      <Typography display="inline" className={`${styles.pageTitle}`}>Device Alerts</Typography>
      <nav aria-label="breadcrumb">
        <ol className={`${styles.breadcrumb}`}>
          <li className={`${styles.breadcrumbItem}`}><span><Home /></span>Smart Devices</li>
          <li className={`${styles.breadcrumbItem} ${styles.active}`}>Device Alerts</li>
        </ol>
      </nav>
    </div>

    <div className={`${styles.BodyCon}`}>
      <div className={`${styles.mainCardDiv}`}>
        <div className={`${styles.mainCard}`}>
          <div className="control-pane">
            <div className={`${styles.Row}`}>
              <FilterComponent filterData={filterData} setFilterData={setFilterData.bind(this)} />

              {contentLoading && (<div className="Loader TableLoader">
                <TableLoader />
              </div>)}

              {!contentLoading && (<div className={`${styles.AlertsRow}`}>
                {isAuthenticated && (user["https://propertydek.com/permissions"].indexOf("device:update") > -1) && <div className={`${styles.AlertMainCheckSec}`}>
                  {alertList?.length > 0 && (<FormControlLabel
                    className="CustomCheck"
                    control={<Checkbox
                      onChange={checkboxAllCng}
                      indeterminate={
                        selectedAlertLength > 0 &&
                        alertList?.length !== selectedAlertLength
                      }
                      checked={
                        alertList?.length > 0 &&
                        alertList?.length === selectedAlertLength
                      }
                    />}
                    label="Select All Alerts"
                  />)}
                  {selectedAlertLength > 0 && (<button className={`${styles.AlertDeleteBU}`} onClick={deleteAlerts}>
                    <Trash2 />Delete {selectedAlertLength > 1 ? selectedAlertLength + " Alerts" : selectedAlertLength + " Alert"}
                  </button>)}
                </div>}

                {alertList?.map((item, index) => {
                  return (perPage === -1 || (index >= page * perPage && index < page * perPage + perPage)) ? (<div className={`${styles.AlertsCard}`}>
                    <div className={`${styles.AlertsCardUnder}`}>
                      <div className={`${styles.AlertsCardHead}`}>
                        {isAuthenticated && (user["https://propertydek.com/permissions"].indexOf("device:update") > -1) && item?.is_archived === 0 && (<FormControlLabel
                          className={`${styles.CustomCheck} CustomCheck`}
                          control={<Checkbox
                            checked={selectedAlerts?.indexOf(parseInt(item.id)) > -1}
                            onChange={checkboxCng}
                            value={item?.id}
                          />}
                          onClick={(e) => e.stopPropagation()}
                        />)}

                        {item?.title === "Water Leak" && (<div className={`${styles.AlertsCardImg} ${styles.Waterleak}`}>
                          <img src="/static/img/water-dash.svg" width="24px" height="24px" alt="" />
                        </div>)}

                        {item?.title === "Low Battery" && (<div className={`${styles.AlertsCardImg} ${styles.Lowbatter}`}>
                          <img src="/static/img/battery-dash.svg" width="24px" height="24px" alt="" />
                        </div>)}

                        {item?.title === "Device Offline" && (<div className={`${styles.AlertsCardImg} ${styles.Offline}`}>
                          <img src="/static/img/wifi-dash.svg" width="24px" height="24px" alt="" />
                        </div>)}

                        {(item?.title?.toLowerCase().includes('door unlocked') || item?.title?.toLowerCase().includes('door is unlocked') || item?.title?.toLowerCase().includes('door was unlocked')) && (<div className={`${styles.AlertsCardImg} ${styles.Online}`}>
                          <img src="/static/img/lock-dash.svg" width="24px" height="24px" alt="" />
                        </div>)}

                        <div className={`${styles.AlertsCardConSec}`}>
                          <p className={`${styles.AlertsCardTitle}`}>{item?.title}</p>
                          <p className={`${styles.AlertsCardTitleSm}`}>{item?.product_type}/{item?.location}</p>
                        </div>
                      </div>
                      <div className={`${styles.AlertsCardBody}`}>
                        <ul>
                          <li>
                            <p className={`${styles.Title}`}>Device Name:</p>
                            <p className={`${styles.Content}`}>{item?.name}</p>
                          </li>
                          <li>
                            <p className={`${styles.Title}`}>Property:</p>
                            <p className={`${styles.Content}`}>{item?.property_name}</p>
                          </li>
                          <li>
                            <p className={`${styles.Title}`}>Unit:</p>
                            <p className={`${styles.Content}`}>{item?.apt_no ? item?.apt_no : item?.apt_text}</p>
                          </li>
                        </ul>
                      </div>
                      <div className={`${styles.AlertsCardFoot}`}>
                        <p className={`${styles.CardDate}`}>{moment(item?.created_at).fromNow()}</p>
                        {item?.is_archived === 1 && <span className={`${styles.ArchiveBadge}`}>Archived</span>}
                      </div>
                    </div>
                  </div>) : null;
                })}

                {alertList.length > 0 && (<div className="Pagination TablePaginationNew List">
                  <TablePagination
                    count={alertList.length}
                    color="primary"
                    page={page}
                    rowsPerPage={perPage}
                    onPageChange={(e, v) => setPage(v)}
                    rowsPerPageOptions={[
                      { label: '4', value: 4 },
                      { label: '8', value: 8 },
                      { label: '16', value: 16 },
                      { label: 'All', value: -1 },
                    ]}
                    onRowsPerPageChange={(e) => {
                      setPage(0);
                      setPerPage(e.target.value);
                    }}
                    labelRowsPerPage={'Alerts per Page:'}
                  />
                </div>)}

                {alertList?.length === 0 && (<div className={`${styles.NoDataMain}`}>
                  <div className={`${styles.NoDataIMG}`}>
                    <img src="/static/img/no-device.png" alt="No Data" />
                  </div>
                  <p className={`${styles.NoDataText}`}>Don't Worry!</p>
                  <p className={`${styles.NoDataTextSub}`}>Device Alerts is empty.</p>
                </div>)}

              </div>)}
            </div>
          </div>
        </div>
      </div>
    </div>
    {ownerId && (<PubNubChannel ownerId={ownerId} updateDeviceStatus={updateDeviceStatus}></PubNubChannel>)}

    <Snackbar
      open={snackbarOpen}
      onClose={(e) => setSnackbarOpen(false)}
      TransitionComponent={Slide}
      message={snackbarMsg}
      autoHideDuration={10000}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={(e) => setSnackbarOpen(false)}
        >
          <X />
        </IconButton>
      }
    />
    <Footer />
  </React.Fragment>);
};

export default withAuthenticationRequired(SmartDevicesAlerts, {
  onRedirecting: () => <Loader />,
});