import React, { useState } from "react";
import { DialogTitle, DialogContent, DialogContentText, TextField, FormControl, InputLabel, Select, MenuItem, Button, FormControlLabel, Switch } from "@mui/material";
import styles from "../../prospectlist.module.css";
import { X } from "react-feather";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

function TenDigitField(props) {
    return (<NumberFormat
        customInput={TextField}
        prefix=""
        displayType="input"
        type="text"
        thousandSeparator={false}
        decimalScale={0}
        {...props}
    />);
}

function MoneyField(props) {
    return (<NumberFormat
        customInput={TextField}
        prefix=""
        displayType="input"
        type="text"
        thousandSeparator={true}
        allowNegative={false}
        decimalScale={0}
        {...props}
    />);
}

const creditScoreRegExp = /\b\d{3}\b/;
const schema = yup.object().shape({
    credit_score: yup.string().matches(creditScoreRegExp, "Please enter valid credit score."),
    income_per_year: yup.string().max(7, "Income per year can not be more than 6 digits."),
    have_pets: yup.string().max(2, "Case filed can not be more than 2 digits."),
    bedrooms: yup.string(),
    bathrooms: yup.string(),
    do_you_smoke: yup.string()
});

const StepTwoForm = (props) => {
    const [addTourChecked, setAddTourChecked] = useState(false);

    const { register, handleSubmit, control, errors } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange"
    });

    const onSubmit = (data) => {
        if (addTourChecked) {
            data = { ...data, is_tour: true };
            props.onSave(data);
        } else {
            data = { ...data, is_tour: false };
            props.onSubmit(data);
        }
    }

    return (<>
        <DialogTitle id="alert-dialog-title"><span>Add Prospect</span><button onClick={(e) => props?.setOpenAddProspectModal(false)}><X /></button></DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={`${styles.AddprospectFirstStepForm}`}>
                        <p className={`${styles.AddprospectFirstStepFormTitle}`}>Personal Information</p>
                        <div className={`${styles.HalfStepFormRow}`}>
                            <div className={`${styles.HalfStepFormFullSec} FormGroup`}>
                                <Controller
                                    name={`credit_score`}
                                    control={control}
                                    render={(field) => (
                                        <TenDigitField format="###" value={field.value} onChange={(e) => field.onChange(e)} label="Credit score" variant="outlined" error={errors?.credit_score} />
                                    )}
                                />
                                {errors.credit_score && (<p className={`${styles.ErrorM}`}>{errors?.credit_score?.message}</p>)}
                            </div>
                            <div className={`${styles.HalfStepFormFullSec} FormGroup`}>
                                <Controller
                                    name="have_pets"
                                    control={control}
                                    render={(field) => (<TenDigitField format="##" onChange={(e) => field.onChange(e)} id="outlined-basic" label="How many pets do you have?" value={field.value} error={errors?.have_pets} />)}
                                />
                                {errors.have_pets && (<p className={`${styles.ErrorM}`}>{errors?.have_pets?.message}</p>)}
                            </div>
                            <div className={`${styles.HalfStepFormFullSec} FormGroup`}>
                                <Controller
                                    name="income_per_year"
                                    control={control}
                                    render={(field) => (<MoneyField onChange={(e) => field.onChange(e)} id="outlined-basic" label="Salary*" value={field.value} error={errors?.income_per_year} />)}
                                />
                                {errors.income_per_year && (<p className={`${styles.ErrorM}`}>{errors?.income_per_year?.message}</p>)}
                            </div>
                            <div className={`${styles.HalfStepFormFullSec} FormGroup`}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Number of bedrooms?</InputLabel>
                                    <Controller
                                        name="bedrooms"
                                        control={control}
                                        render={(field) => (
                                            <Select
                                                {...field}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label={"Number of bedrooms?"}
                                            >
                                                <MenuItem value={1}>1</MenuItem>
                                                <MenuItem value={2}>2</MenuItem>
                                                <MenuItem value={3}>3</MenuItem>
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                                {errors.bedrooms && (<p className={`${styles.ErrorM}`}>{errors?.bedrooms?.message}</p>)}
                            </div>
                            <div className={`${styles.HalfStepFormFullSec} FormGroup`}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Controller
                                        name="move_in_date"
                                        control={control}
                                        inputref={register}
                                        render={(fields) => (
                                            <DatePicker
                                                {...fields}
                                                label="Move-In Date"
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                                {errors.move_in_date && (<p className={`${styles.ErrorM}`}>{errors?.move_in_date?.message}</p>)}
                            </div>
                            <div className={`${styles.HalfStepFormFullSec} FormGroup`}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Number of bathrooms?</InputLabel>
                                    <Controller
                                        name="bathrooms"
                                        control={control}
                                        render={(field) => (
                                            <Select
                                                {...field}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label={"Number of bathrooms?"}
                                            >
                                                <MenuItem value={1}>1</MenuItem>
                                                <MenuItem value={2}>2</MenuItem>
                                                <MenuItem value={3}>3</MenuItem>
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                                {errors.bathrooms && (<p className={`${styles.ErrorM}`}>{errors?.bathrooms?.message}</p>)}
                            </div>
                            <div className={`${styles.HalfStepFormFullSec} FormGroup`}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Do you smoke?</InputLabel>
                                    <Controller
                                        name="do_you_smoke"
                                        control={control}
                                        render={(field) => (
                                            <Select
                                                {...field}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label={"Do you smoke?"}
                                            >
                                                <MenuItem value="Yes">Yes</MenuItem>
                                                <MenuItem value="No">No</MenuItem>
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                                {errors.do_you_smoke && (<p className={`${styles.ErrorM}`}>{errors?.do_you_smoke?.message}</p>)}
                            </div>
                            {props?.isTourOption && <div className={`${styles.FirstStepFormFullSec} FormGroup CheckBox`}>
                                <FormControlLabel
                                    control={
                                        <Switch checked={addTourChecked} onChange={(e) => setAddTourChecked(e.target.checked)} />
                                    }
                                    label="Add a tour"
                                />
                            </div>}
                        </div>
                        <div className={`${styles.ButtonAction}`}>
                            <Button className="CancelPopupBU" onClick={(e) => props?.setOpenAddProspectModal(false)}>Cancel</Button>
                            {addTourChecked === false && <Button className="SubmitPopupBU" type="submit">Add</Button>}
                            {addTourChecked === true && <Button className="SubmitPopupBU" type="submit">Next</Button>}
                        </div>
                    </div>
                </form>
            </DialogContentText>
        </DialogContent>
    </>);
}

export default StepTwoForm;