import styles from "../communication-prospect.module.css";
import { ChevronDown } from "react-feather";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";

function ProspectList(props) {
  const handleClick = (item) => {
    props.openChatWindow(item);
  };

  return (
    <>
      <List
        style={{ maxHeight: "55vh", overflow: "auto" }}
        className="ApartmentListSec Private"
      >
        <ListItem
          button
          onClick={() => handleClick(props.item)}
          className={
            props?.ActiveTenant === props.item.id ? `${styles.activeList}` : ``
          }
        >
          <div className={`${styles.GroupName} GroupName`}>
            <div className="TenantName">
              <span>
                {props.item?.first_name
                  ? props.item?.first_name + props.item.last_name
                  : props.item.phone}
              </span>
            </div>
            {props.item?.is_prospect_applied ===0 && <span className={`${styles.TagDiv} ${styles.Purple}`}>
              Not Applied
            </span>}
            {props.item?.is_prospect_applied ===1 &&  <span className={`${styles.TagDiv} ${styles.Success}`}>
              Applied
            </span> }
          </div>
          {props.item.unread_no > 0 && (
            <span className={`${styles.PropertyTenantNotiCount}`}>
              {props.item.unread_no}
            </span>
          )}
        </ListItem>
      </List>
    </>
  );
}

export default ProspectList;
