import { useEffect, useRef } from 'react'
import styles from '../communication.module.css';
import styled from "styled-components/macro";
import { Avatar, Typography, Box } from "@material-ui/core";
import { CommunicationLoaderB } from '../../../components/LoaderC';
import moment from 'moment';
import FileViewer from './fileViewer';
const ChatMessage = styled.div`
  overflow: auto;
  height: calc(65vh - 94px);
`;
const ChatMessageInner = styled.div`
  display: inline-flex;
  max-width: 550px;
`;

const ChatMessageTime = styled(Typography)`
  text-align: left;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #666666;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
`;

const ChatMessageAvatar = styled(Avatar)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  margin-right: ${(props) => props.theme.spacing(2)}px;
`;

const ChatMessageBubble = styled.div`
  display: inline-block;
  margin-right: auto;
  background: none;
  color: #343434;
  border-radius: 3px;
  padding: 0;
  margin-bottom: 0;
  text-align: left;
`;

const ChatMessageBubbleName = styled(Typography)`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #343434;
  margin-bottom: 8px;
`;

const ChatMessageCon = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #343434;
  padding: 16px;
  background: #FBF7FC;
  border-radius: 6px;
  min-width: 300px;
`;


function ChatMessages(props) {
  const chatAreaRef = useRef(null);

  useEffect(() => {
    chatAreaRef.current.scrollIntoView({ behavior: "auto", block: "end" });
  }, [props]);

  return (
    <>
      <ChatMessage className={`${styles.PositionRelative}`}>
        {props.loading2 && <CommunicationLoaderB />}
        {props.chatMessagesList.map((item, index) => {
          return (<ChatMessageComponent key={index} item={item} userId={props.userId} />)
        })}
        <div ref={chatAreaRef} />
        {!props.isConversationCreated && !props.chatMessagesList.length && <div className={`${styles.WelcomeMessageSec} ${styles.NoTop}`}>
          <div className={`${styles.WelcomeMessageBody}`}>
            <img src='/static/img/chatIcon.png' alt="" />
            <p className={`${styles.WelcomeMessageTitle}`}>Start a New Conversation</p>
          </div>
        </div>}
        {!props.chatMessagesList.length && props.isConversationCreated && <div className={`${styles.WelcomeMessageSec} ${styles.NoTop}`}>
          <div className={`${styles.WelcomeMessageBody}`}>
            <img src='/static/img/noMessage.png' alt="" />
            <p className={`${styles.WelcomeMessageTitle}`}>No Message found!</p>
          </div>
        </div>}
      </ChatMessage>
    </>
  )
}

function ChatMessageComponent(props) {
  const messagePosition = props.userId === props.item.sender_id ? 'right' : 'left'
  function formatTimeAgo(isoDate) {
    const date = new Date(isoDate);
    return moment(date).fromNow();
  }

  return (
    <ChatMessage position={messagePosition} className={messagePosition === 'right' ? `RightSideMessage MessageSecCommon` : `MessageSecCommon`}>
      <ChatMessageInner>
        <ChatMessageAvatar alt="Lucy Lavender" src={props.item.avatar} />
        <ChatMessageBubble highlighted={messagePosition === "right"}>
          <Box>
            <ChatMessageBubbleName variant="body1">
              {props.item.sender_name}
            </ChatMessageBubbleName>
          </Box>
          {props.item.message_type === 'text' && <Typography variant="body2"><ChatMessageCon>{props.item.message}</ChatMessageCon></Typography>}
          {props.item.message_type === 'file' && <div className={`${styles.MessageFileSec}`}><FileViewer fileUrl={props.item.file_url} fileName={props.item.file_name}></FileViewer></div>}
          <ChatMessageTime variant="body2">{formatTimeAgo(props.item.createdAt)}<img src={'/static/img/message-send.png'} className={`${styles.SendIcon}`} alt="Property" /></ChatMessageTime>
        </ChatMessageBubble>
      </ChatMessageInner>
    </ChatMessage>
  );
}

export default ChatMessages