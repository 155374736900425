import React, { useEffect, useState } from "react";
import styles from './pay-success.module.css';
import axios from 'axios';
import { Parallax } from "react-parallax";

function PaySuccess({ history, match }) {
  const [amount, setAmount] = useState(0);
  const [tenantDet, setTenantDet] = useState({});

  useEffect(() => {
    async function getTenantInfo(tenantId) {
      await axios.get(process.env.REACT_APP_PAYMENT_API_URL + 'v1/tenant-payment-info/' + tenantId).then(response => {
        let resData = response.data.data;
        setTenantDet(resData);
      });
    }

    let paymentAuthDataTemp = localStorage.getItem("paymentAuthData");
    if (paymentAuthDataTemp) {
      paymentAuthDataTemp = JSON.parse(paymentAuthDataTemp);
      getTenantInfo(paymentAuthDataTemp?.tenantId);
      setAmount(paymentAuthDataTemp?.amount);
    }
  }, [setAmount]);

  return (<React.Fragment>

    <Parallax className="Parallax" bgImage="/static/img/renterbanner.jpg" strength={420}>
      <div className={`${styles.HeaderWrap}`}>
        {(tenantDet?.theme_options?.image_url !== '') && < img className={`${styles.Logo}`} src={tenantDet?.theme_options?.image_url} alt="logo" />}
        {(tenantDet?.theme_options?.image_url === '') && < img className={`${styles.Logo}`} src="/static/img/logo.svg" alt="logo" />}
      </div>
    </Parallax>

    <div className={`${styles.MainBody}`}>
      <div className={`${styles.Title}`}>
        <div className={`${styles.PaymentThanksUnder} ${styles.PrimaryIcon}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65" fill="#f48272"><path d="M64.5 32.2305C64.5 38.5469 62.625 44.6895 59.0781 49.9946C58.5961 50.7158 57.8042 51.1055 56.9975 51.1055C56.52 51.1055 56.0371 50.9692 55.6103 50.6836C54.4624 49.9165 54.1543 48.3638 54.9213 47.2158C57.917 42.7358 59.5 37.5542 59.5 32.2305C59.5 29.9429 59.2138 27.6709 58.6494 25.4785C58.3051 24.1416 59.1103 22.7783 60.4472 22.4341C61.7851 22.0903 63.1474 22.895 63.4917 24.2319C64.1606 26.8315 64.5 29.5225 64.5 32.2305ZM46.4668 55.3423C42.2675 57.8857 37.4379 59.2305 32.5 59.2305C17.6122 59.2305 5.49994 47.1182 5.49994 32.2305C5.49994 17.3428 17.6122 5.23047 32.5 5.23047C38.0122 5.23047 43.3125 6.87988 47.8286 10.0005C48.9643 10.7852 50.5214 10.5005 51.3066 9.36475C52.0913 8.229 51.8071 6.67188 50.6709 5.88672C45.3154 2.18652 39.0322 0.230469 32.5 0.230469C23.9526 0.230469 15.9164 3.55908 9.8725 9.60303C3.82855 15.647 0.499939 23.6831 0.499939 32.2305C0.499939 40.7778 3.82855 48.814 9.8725 54.8579C15.9164 60.9019 23.9526 64.2305 32.5 64.2305C38.3515 64.2305 44.0771 62.6357 49.0576 59.6187C50.2387 58.9033 50.6162 57.3662 49.9008 56.1851C49.185 55.0039 47.6479 54.627 46.4668 55.3423ZM32.7412 39.4795C32.3452 39.4951 31.9755 39.3545 31.6884 39.0811L18.5952 26.6665C17.5932 25.7163 16.0107 25.7583 15.061 26.7603C14.1108 27.7622 14.1528 29.3447 15.1547 30.2944L28.2456 42.707C29.4545 43.8564 31.0185 44.4805 32.6757 44.4805C32.7646 44.4805 32.8535 44.4785 32.9423 44.4751C34.6977 44.4043 36.31 43.6445 37.4819 42.3354C37.5117 42.3027 37.54 42.269 37.5678 42.2344L63.9482 9.42188C64.8134 8.3457 64.6425 6.77197 63.5664 5.90723C62.4907 5.04199 60.9165 5.21289 60.0512 6.28906L33.7226 39.0376C33.4638 39.3076 33.1171 39.4644 32.7412 39.4795Z" /></svg>
          <p className={`${styles.PaymentStatus}`}>Paid</p>
          <p className={`${styles.PaymentAmmount}`}>${amount}</p>
          <p className={`${styles.PaymentThanksCon}`}>Thank You!</p>
          <p className={`${styles.PaymentThanksCon}`}>Please allow 5-7 business days for your account to be updated.</p>
        </div>
      </div>
    </div>

  </React.Fragment >);
}

export default PaySuccess;