import React, { useState } from "react";
import styles from './contactus.module.css';
import Helmet from "react-helmet";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from 'axios';
//import Select from "react-select";
//import Select as sel from "react-select";
//import { Link as Linkto } from "react-router-dom";
//import { ArrowRight } from 'react-feather';
//import Zoom from 'react-reveal/Zoom';
import { toast } from 'react-smart-toaster';
import Fade from 'react-reveal/Fade';
import ReCAPTCHA from "react-google-recaptcha";
import NumberFormat from "react-number-format";
import Header from '../../components/HeaderPublicOther';
import Footer from '../../components/FooterPublicOther';
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
//import { changeEnd } from "codemirror";
const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const schema = yup.object().shape({
  full_name: yup.string().required('This field is required').max(50, 'Full name cannot be more than 50 characters'),
  email: yup.string().required('This field is required').email(('Invalid E-mail')),
  phone: yup.string().required('This field is required').matches(phoneRegExp, { message: "Please enter a valid phone number", excludeEmptyString: true }),
  // query_type: yup.object().required('This field is Required').nullable(),
  message: yup.string().required('This field is required').max(200, "Message can not be more than 200 characters ")

});

// const queryTypeArr = [
//   { value: 'General Enquiry', label: 'General Enquiry' },
//   { value: 'Free Demo', label: 'Free Demo' }
// ];
function HomePage({ history, match }) {
  const audience = process.env.REACT_APP_USER_API_URL;
  const CAPTCHAKEY = process.env.REACT_APP_CAPTCHA_SITE_KEY;
  const [captchaValue, setCaptchaValue] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isMessage, setIsMessage] = useState(false);
  const { register, handleSubmit, control, errors, reset } = useForm({
    resolver: yupResolver(schema), mode: 'onChange', defaultValues: {
      query_type: 'General Inquiry',
    }
  });

  const PhoneField = React.forwardRef((props, ref) => {
    return <NumberFormat
      prefix=""
      format={"(###) ###-####"}
      customInput={TextField}
      {...props} />;
  });

  const recaptchaRef = React.useRef();
  function onChange2(value) {
    setCaptchaValue(value);
  }

  // const handleTypeChange = (e) => {
  //   setValue('query_type', e.target.value);
  //   setValue('value', e.target.value);
  // }
  async function onSubmit(data) {
    if (captchaValue === '') {
      setIsValid(true);
      return;
    }
    const token = '';

    let postData = data;
    //setLoading(true);

    return axios.post(audience + 'v1/contact-us', postData, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then(response => {
      reset({
        full_name: "",
        email: "",
        phone: "",
        query_type: "General Enquiry",
        message: "",
      });
      recaptchaRef.current.reset();
      setIsMessage(true);


      // toast.success('Apartment added  successfully');
      // window.scrollTo(0, 0);
      var element = document.getElementById('full_name');
      var yourHeight = 220;
      element.scrollIntoView(true);
      var scrolledY = window.scrollY;
      if (scrolledY) {
        window.scroll(0, scrolledY - yourHeight);
      }
      //setResponseMessage("Thank you for your message!");
      // recaptchaRef.current.reset();
      setTimeout(() => {
        setIsMessage(false);
        setIsValid(false);
        //   redirect.push('/properties/details/' + property_id);
      }, 8000);

      //setLoading(false);

      // let logData = {
      //   'title': 'New apartment is added',
      //   'description': [
      //     'Unit No: ' + postData.apt_no,
      //     'Unit Type: ' + postData.apt_type,
      //     'Property:' + PropertyDetails.name
      //   ]
      // }
      // AddLogCallAPI(logData, token);
    }).catch(error => {
      if (typeof error.response !== 'undefined')
        toast.error(error.response.data.message);
      // setLoading(false);
    });
  }
  const onError = (errors) => {
    let focusId = '';
    let element = '';

    focusId = Object.keys(errors)[0];
    element = document.getElementById(focusId);

    var yourHeight = 220;
    element.scrollIntoView(true);
    var scrolledY = window.scrollY;
    if (scrolledY) {
      window.scroll(0, scrolledY - yourHeight);
    }
    element.focus();


  }


  return (<React.Fragment>
    <Helmet title="Contact us" />

    <Header />

    <div className="WhiteBG">
      <div className={`${styles.BannerArea}`}>
        <div className={`${styles.Container}`}>
          <div className={`${styles.Row}`}>

            <div className={`${styles.BannerTextArea}`}>
              <Fade bottom>
                <h2 className={`${styles.BannerTitleText}`}>We’d love to hear from you</h2>
                <p className={`${styles.BannerConText}`}>Whether you have a question about features, trials, pricing, need a demo, or anything else, our team is ready to answer all your questions.</p>
              </Fade>

            </div>

            <div className={`${styles.BannerIMGArea}`}>
              <Fade right><img src="/static/img/bannerimage-contact.png" alt="" className={`${styles.BannerMainIMG}`} /></Fade>
            </div>

          </div>
        </div>
      </div>

      <div className={`${styles.ConTactFormSec}`}>
        <div className={`${styles.Container}`}>
          <Fade bottom>
            <p className={`${styles.ContactTitle}`}>Get in touch with us</p>
            <div className={`${styles.GetInTouchRow}`}>

              <div className={`${styles.FormSec}`}>
                {/* <form>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FilterLabel}`}>First Name<span>*</span></label>
                    <input className={`${styles.formControl}`} type="text" name=""></input>
                  </div>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FilterLabel}`}>Email<span>*</span></label>
                    <input className={`${styles.formControl}`} type="email" name=""></input>
                  </div>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FilterLabel}`}>Phone</label>
                    <input className={`${styles.formControl}`} type="number" name=""></input>
                  </div>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FilterLabel}`}>Type of query</label>
                    <select className={`${styles.formControl}`}>
                      <option value="pm">Free Demo</option>
                      <option value="ms">General Enquiry</option>
                    </select>
                  </div>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FilterLabel}`}>Message</label>
                    <textarea className={`${styles.formControlTextArea}`}></textarea>
                  </div>
                  <button className={`${styles.ConFormSubBU}`}>Submit</button>
                </form> */}
                {isMessage && <div className={`${styles.SubmittedMessage}`}>Thanks for contacting us! We will get in touch with you shortly.</div>}
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                  <div className="FormGroup Contact">
                    {/* <label class={`${styles.FilterLabel}`}>First Name<span>*</span></label>*/}
                    {/* <input class={`${styles.formControl}`} type="text" name="first_name" ref={register} /> */}
                    {/* <TextField id="outlined-basic" label="First Name*" variant="outlined" name="first_name" ref={register} /> */}
                    <Controller as={TextField} id="full_name" label="Full Name*" variant="outlined" name="full_name" control={control} ref={register} autoComplete="off" />

                    {errors.full_name && <p className={`${styles.ErrorM}`}>{errors.full_name.message}</p>}
                  </div>
                  <div className="FormGroup Contact">
                    {/* <label class={`${styles.FilterLabel}`}>Email<span>*</span></label>
                    <input class={`${styles.formControl}`} type="email" name=""></input> */}
                    {/* <TextField id="outlined-email" label="Email*" variant="outlined" /> */}
                    <Controller as={TextField} id="email" label="Email*" variant="outlined" name="email" control={control} ref={register} autoComplete="off" />
                    {errors.email && <p className={`${styles.ErrorM}`}>{errors.email.message}</p>}
                  </div>
                  <div className="FormGroup Contact">
                    {/* <label class={`${styles.FilterLabel}`}>Phone</label> */}
                    {/* <input class={`${styles.formControl}`} type="number" name=""></input>  */}
                    {/* <TextField id="outlined-basic" label="Phone" variant="outlined" /> */}
                    <Controller as={PhoneField} id="phone" label="Phone*" variant="outlined" name="phone" control={control} ref={register} autoComplete="off" />
                    {errors.phone && <p className={`${styles.ErrorM}`}>{errors.phone.message}</p>}
                  </div>
                  <div className="FormGroup Contact">
                    {/* <label class={`${styles.FilterLabel}`}>Type of query</label>
                    <select class={`${styles.formControl}`}>
                      <option value="pm">Free Demo</option>
                      <option value="ms">General Enquiry</option>
                    </select> */}

                    {/* <Controller name="query_type" control={control} defaultValue="General Enquiry" labelid="demo-simple-select-label" render={({ onChange, value, name }) => (<Select labelId="demo-simple-select-label" onChange={(e) => { onChange(e); handleTypeChange(e); }} value={value ? value : ""} name={name}>
                      {queryTypeArr.map((item, i) => {
                        return <MenuItem key={i} value={item.value}>{item.label}</MenuItem>
                      })}
                    </Select>)} /> */}
                    <FormControl variant="outlined">
                      <InputLabel id="demo-simple-select-outlined-label">Type of query</InputLabel>
                      <Controller
                        name="query_type"
                        control={control}
                        render={(field) =>
                          <Select
                            {...field}
                            labelId="demo-simple-select-outlined-label"
                            label="Type of query"
                            onChange={(e) => {
                              field.onChange(e);
                            }}
                          >
                            <MenuItem value={'Free Demo'}>Free Demo</MenuItem>
                            <MenuItem value={'General Inquiry'}>General Inquiry</MenuItem>
                          </Select>
                        }
                      />
                    </FormControl>

                    {/* <Controller as={Select} name="query_type" control={control} ref={register} >
                      <MenuItem value={'General Enquiry'}>General Enquiry</MenuItem>
                      <MenuItem value={'Free Demo'}>Free Demo</MenuItem>
                    </Controller>

                  </FormControl> */}
                    {/* {errors.query_type && <p className={`${styles.ErrorM}`}>{errors.query_type.message}</p>} */}
                    {/* <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label="Type of query"
                    >
                      <MenuItem value={'General Enquiry'}>General Enquiry</MenuItem>
                      <MenuItem value={'Free Demo'}>Free Demo</MenuItem>
                    </Select> */}

                    {/* <FormControl>
                    <Controller as={Select} name="query_type" control={control} >
                      <MenuItem value={'Full-Time'}>Full-Time</MenuItem>
                      <MenuItem value={'Part-Time'}>Part-Time</MenuItem>
                    </Controller>
                  </FormControl> */}
                  </div>
                  <div className="FormGroup Contact">
                    {/* <label class={`${styles.FilterLabel}`}>Message</label>
                    <textarea class={`${styles.formControlTextArea}`}></textarea> */}
                    <Controller className="FormInput TextArea" render={(props) => (<TextField id="message"
                      label="Message*"
                      multiline
                      rows={10}
                      variant="outlined" value={props.value ? props.value : ''} onChange={(e) => { props.onChange(e) }} />)} name="message" control={control} />
                    {errors.message && <p className={`${styles.ErrorM}`}>{errors.message.message}</p>}
                    {/* <TextField
                    id="outlined-multiline-static"
                    label="Message"
                    multiline
                    rows={10}
                    defaultValue=""
                    variant="outlined"
                  /> */}
                  </div>
                  <div className="FormGroup Contact">
                    <ReCAPTCHA
                      sitekey={CAPTCHAKEY}
                      onChange={onChange2}
                      ref={recaptchaRef}
                    />
                    {isValid && <p className={`${styles.ErrorM}`}>Please fill the captcha</p>}
                  </div>
                  <button type="submit" className={`${styles.ConFormSubBU}`}>Submit</button>
                </form>


              </div>

              <div className={`${styles.ConDeSec}`}>
                <div className={`${styles.ContactUsConDiv}`}>
                  <img src="/static/img/contact-us-icon1.svg" alt="" />
                  <h2>Inquiries</h2>
                  <p>Talk to our experts today</p>
                  <p>US: <a className={`${styles.ContactUsNo}`} href="tel:17188254746">1-718-825-4746</a></p>
                  <p><a className={`${styles.ContactUsNo}`} href="mailto:hello@propertydek.com">hello@propertydek.com</a></p>
                  <p>Availability: Monday-Friday 9am to 5pm</p>
                </div>
                <div className={`${styles.ContactUsConDiv}`}>
                  <img src="/static/img/contact-us-icon2.svg" alt="" />
                  <h2>Support</h2>
                  <p>Using any of our products and need help? Get in touch with customer support</p>
                  {/* <a className={`${styles.ContactUsLink}`} href="https://support.fbc.nyc">Get Support</a> */}
                </div>
              </div>

            </div>
          </Fade>
        </div >
      </div >
      <Footer />
    </div>


    {/* <div className={`${styles.footer}`}>
        <div className={`${styles.Container}`}>
          <div className={`${styles.FooterRow}`}>

            <div className={`${styles.FooterLogoSec}`}>
              <img src="/static/img/logo.svg" alt="logo" />
            </div>

            <div className={`${styles.FooterConSec}`}>
              <div className={`${styles.FooterConSecSmall}`}>
                <p className={`${styles.FootListTitle}`}>Company</p>
                <Linkto className={`${styles.FootListLink}`}>Blog</Linkto>
                <Linkto className={`${styles.FootListLink}`}>Contact</Linkto>
              </div>
              <div className={`${styles.FooterConSecSmall}`}>
                <p className={`${styles.FootListTitle}`}>Services</p>
                <Linkto className={`${styles.FootListLink}`}>e-learn App</Linkto>
                <Linkto className={`${styles.FootListLink}`}>My Portal App</Linkto>
                <Linkto className={`${styles.FootListLink}`}>Pedramp Tracker App</Linkto>
              </div>
              <div className={`${styles.FooterConSecSmall}`}>
                <p className={`${styles.FootListTitle}`}>Legal</p>
                <Linkto className={`${styles.FootListLink}`}>Privacy Policy</Linkto>
                <Linkto className={`${styles.FootListLink}`}>Terms & conditions</Linkto>
                <Linkto className={`${styles.FootListLink}`}>Cookies</Linkto>
              </div>
              <div className={`${styles.FooterConSecBig}`}>
                <div>
                  <a className={`${styles.FootListLinkBig}`} href="tel:17188254746">1-718-825-4746</a>
                  <a className={`${styles.FootListLinkBig}`} href="mailto:hello@propertydek.com">hello@propertydek.com</a>
                </div>
              </div>
            </div>

          </div>

          <div className={`${styles.LastFootRow}`}>
            <p className={`${styles.CopyRightText}`}>© 2022. Propertydek.com. All rights reserved.</p>
            <div className={`${styles.SocialIcon}`}>
              <a href="https://www.linkedin.com/company/fbrothers"><img src="/static/img/linkedin.png" alt="logo" /></a>
              <a href="https://twitter.com/tech4SMBs"><img src="/static/img/twitter.png" alt="logo" /></a>
              <a href="https://www.facebook.com/tech4smbs/"><img src="/static/img/facebook.png" alt="logo" /></a>
            </div>
          </div>
        </div>
      </div> */}

  </React.Fragment >);
}




export default HomePage;