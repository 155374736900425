export default function communicationChatReloadReducer(
  isReload = false,
  actions
) {
  switch (actions.type) {
    case "reload":
      return (isReload = actions.payload);

    default:
      return isReload;
  }
}
