import React, { useEffect, useState } from "react";
import { CardContent } from "@mui/material";
import { Bar } from "react-chartjs-2";
import styles from "../dashboard.module.css";

const MonthlyCasesData = (props) => {
    const [noData, setNoData] = useState(true);
    const data = {
        labels: props.monthlyCaseLabel ? props.monthlyCaseLabel : [],
        datasets: [
            {
                label: "Maintenance",
                backgroundColor: "#A169C6",
                borderColor: "#4782da",
                hoverBackgroundColor: "#A169C6",
                hoverBorderColor: "#4782da",
                data: props.monthlyCaseData
                    ? props.monthlyCaseData.maintenance_requests
                    : [],
                barPercentage: 0.5,
                categoryPercentage: 0.5,
            },
            {
                label: "Complaints",
                backgroundColor: "#E99A37",
                borderColor: "#4782da",
                hoverBackgroundColor: "#E99A37",
                hoverBorderColor: "#4782da",
                data: props.monthlyCaseData ? props.monthlyCaseData.complaints : [],
                barPercentage: 0.5,
                categoryPercentage: 0.5,
                marginBottom: -50,
            },
            {
                label: "Violations",
                backgroundColor: "#E23725",
                borderColor: "rgba(0, 0, 0, 0.15)",
                hoverBackgroundColor: "#E23725",
                hoverBorderColor: "rgba(0, 0, 0, 0.15)",
                data: props.monthlyCaseData ? props.monthlyCaseData.violations : [],
                barPercentage: 0.5,
                categoryPercentage: 0.5,
            },
        ],
    };

    const options = {
        maintainAspectRatio: false,
        cornerRadius: 0,
        legend: {
            display: true,
            position: "bottom",
            labels: {
                padding: 30,
            },
        },
        scales: {
            yAxes: [
                {
                    gridLines: {
                        color: "transparent",
                    },
                    stacked: true,
                    ticks: {
                        stepSize: 50,
                        fontColor: "rgba(0, 0, 0, 0.54)",
                    },
                },
            ],
            xAxes: [
                {
                    stacked: true,
                    gridLines: {
                        color: "transparent",
                    },
                    ticks: {
                        fontColor: "rgba(0, 0, 0, 0.54)",
                    },
                },
            ],
        },
    };

    useEffect(() => {
        let isNoRequests = props?.monthlyCaseData?.maintenance_requests[0] === 0 && props?.monthlyCaseData?.maintenance_requests[1] === 0 && props?.monthlyCaseData?.maintenance_requests[2] === 0 && props?.monthlyCaseData?.maintenance_requests[3] === 0 && props?.monthlyCaseData?.maintenance_requests[4] === 0 && props?.monthlyCaseData?.maintenance_requests[5] === 0 && props?.monthlyCaseData?.maintenance_requests[6] === 0 && props?.monthlyCaseData?.maintenance_requests[7] === 0 && props?.monthlyCaseData?.maintenance_requests[8] === 0 && props?.monthlyCaseData?.maintenance_requests[9] === 0 && props?.monthlyCaseData?.maintenance_requests[10] === 0 && props?.monthlyCaseData?.maintenance_requests[11] === 0;

        let isNoComplaints = props?.monthlyCaseData?.complaints[0] === 0 && props?.monthlyCaseData?.complaints[1] === 0 && props?.monthlyCaseData?.complaints[2] === 0 && props?.monthlyCaseData?.complaints[3] === 0 && props?.monthlyCaseData?.complaints[4] === 0 && props?.monthlyCaseData?.complaints[5] === 0 && props?.monthlyCaseData?.complaints[6] === 0 && props?.monthlyCaseData?.complaints[7] === 0 && props?.monthlyCaseData?.complaints[8] === 0 && props?.monthlyCaseData?.complaints[9] === 0 && props?.monthlyCaseData?.complaints[10] === 0 && props?.monthlyCaseData?.complaints[11] === 0;

        let isNoViolations = props?.monthlyCaseData?.violations[0] === 0 && props?.monthlyCaseData?.violations[1] === 0 && props?.monthlyCaseData?.violations[2] === 0 && props?.monthlyCaseData?.violations[3] === 0 && props?.monthlyCaseData?.violations[4] === 0 && props?.monthlyCaseData?.violations[5] === 0 && props?.monthlyCaseData?.violations[6] === 0 && props?.monthlyCaseData?.violations[7] === 0 && props?.monthlyCaseData?.violations[8] === 0 && props?.monthlyCaseData?.violations[9] === 0 && props?.monthlyCaseData?.violations[10] === 0 && props?.monthlyCaseData?.violations[11] === 0;

        if (isNoRequests && isNoComplaints && isNoViolations) {
            setNoData(true);
        } else {
            setNoData(false);
        }
    }, [props?.monthlyCaseData]);

    return (<div className={`${styles.ChartFullDiv} Barchart`}>
        <div className={`${styles.IfnoCard}`}>
            <div className={`${styles.InsideCardTwo}`}>
                <div className={`${styles.BatteryHead}`}>
                    <h6 className={`${styles.BatteryCardTitle}`}>Monthly Cases</h6>
                </div>
            </div>

            <CardContent style={{ display: noData ? 'none' : 'block' }}>
                <div style={{ height: '340px', width: '100%' }}>
                    <Bar data={data} options={options} />
                </div>
            </CardContent>

            {noData && (<div className={`${styles.NoDataSec}`} style={{ height: '378px' }}>
                <div className={`${styles.NoDataIMG}`}><img src="/static/img/no-case.png" alt="" /></div>
                <p className={`${styles.NoDataText}`}>Hooray!</p>
                <p className={`${styles.NoDataTextSub}`}>Zero Cases Reported.</p>
            </div>)}
        </div>
    </div>);
};

export default MonthlyCasesData;
