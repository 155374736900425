import { useEffect, useState } from "react";
import styles from "../dashboard.module.css";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";

const Ruquests = ({ newData, data }) => {
    const [requestList, setRequestList] = useState([]);

    useEffect(() => {
        setRequestList(data);
    }, [data]);

    return (<div className={`${styles.CardDivTwo} ${styles.CardReq}`}>
        <div className={`${styles.IfnoCard}`}>
            <div className={`${styles.InsideCard}`}>
                <div className={`${styles.HeadFlex}`}>
                    <h6 className={`${styles.InsideCardTitle2}`}>Maintenance {newData > 0 && <span>{newData} Open</span>}</h6>
                    <Link to="/cases/maintenance-request" className={`${styles.ViewAllBU}`}>View All</Link>
                </div>
                <div className={`${styles.ScrollCard}`}>
                    {requestList?.map((item) => {
                        return (<Link to={"/cases/maintenance-request/details/" + item?.id} className={`${styles.CaseCard}`} key={item?.id}>
                            <div className={`${styles.CaseCardTitle}`}>
                                <div className={`${styles.CaseCardLeftSec}`}>
                                    <p className={`${styles.TitleText}`}>
                                        <Tooltip title={item?.title} placement="bottom-start">
                                            <span className={`${styles.NoWrap}`}>{item?.title}</span>
                                        </Tooltip>
                                        <span>#{item?.request_no}</span>
                                    </p>
                                    <p className={`${styles.TitleName}`}>{item?.property_name}:&nbsp;{item?.apt_name}, {item?.tenant_name}</p>
                                </div>
                                <div className={`${styles.CardPillSec}`}>
                                    <span className={item?.current_status === 0 ? `${styles.StatusPill} ${styles.Open}` : item?.current_status === 1 ? `${styles.StatusPill} ${styles.Yellow}` : item?.current_status === 2 ? `${styles.StatusPill} ${styles.Green}` : `${styles.StatusPill} ${styles.Red}`}>{item?.status_text}</span>
                                    <p className={`${styles.DateText}`}>{item?.submitted_date}</p>
                                </div>
                            </div>
                        </Link>);
                    })}

                    {requestList?.length === 0 && (<div className={`${styles.NoDataSec}`}>
                        <div className={`${styles.NoDataIMG}`}><img src="/static/img/no-request.png" alt="" /></div>
                        <p className={`${styles.NoDataText}`}>No worries here!</p>
                        <p className={`${styles.NoDataTextSub}`}>Maintenance List is Empty.</p>
                    </div>)}
                </div>
            </div>
        </div>
    </div>);
}

export default Ruquests;