import React from "react";
//import styled from "styled-components/macro";
//import { Link } from "react-router-dom";
import styles from "./error404.module.css";

import Helmet from "react-helmet";

// import { Button as MuiButton } from "@material-ui/core";
// import { spacing } from "@material-ui/system";

// const Button = styled(MuiButton)(spacing);

// const Wrapper = styled.div`
//   padding: ${(props) => props.theme.spacing(6)}px;
//   text-align: center;
//   background: transparent;

//   ${(props) => props.theme.breakpoints.up("md")} {
//     padding: ${(props) => props.theme.spacing(10)}px;
//   }
// `;
import Header from "../../components/HeaderPublicOther";
import Footer from "../../components/FooterPublicOther";

function Page404() {
  return (
    <div>
      <Helmet title="404 Error" />

      <Header />
      <div className="WhiteBG">
        <div className={`${styles.ErrorMain}`}>
          <div className={`${styles.ErrorMainSec}`}>
            <img
              src="/static/img/404.jpg"
              alt=""
              className={`${styles.SuccessImg}`}
            />
            <p className={`${styles.ErrorTitle}`}>Page not found</p>
            <p className={`${styles.PaymentSuccessTitleSub}`}>
              The page you are looking for might have been removed.
            </p>
            <a href="/" className={`${styles.LearnMBUBanner}`}>
              Return to website
            </a>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Page404;
