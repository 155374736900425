export default function reducer(state = {}, actions) {

    switch (actions.type) {

        case 'userInfo':

            return state = actions.payload

        default:
            return state
    }

}