import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { dashboardLayoutRoutes, authLayoutRoutes, protectedRoutes, customLayoutRoutes, dashboardDetailsLayoutRoutes } from "./index";
import DashboardLayout from "../layouts/Dashboard";
import { useAuth0 } from "@auth0/auth0-react";
import AuthLayout from "../layouts/Auth";
import CustomLayout from "../layouts/Custom";
import DashboardDetailLayout from "../layouts/DashboardforDetails";
import Page403 from "../pages/auth/Page403";
import Page404 from "../pages/auth/Page404";
import { useLocation } from "react-router-dom";
import AuthLogin from "../pages/auth/AuthLogin";
import CommunicationChat from "../components/communication-chat/communication-chat";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const ChildRoutes = (Layout, routes) => {
    const { user, isAuthenticated } = useAuth0();
    let query = useQuery();

    if (query.has('error')) {
        return (<AuthLayout>
            <AuthLogin />
        </AuthLayout>);
    }

    return routes.map(({ component: Component, guard, children, path, authProtected, permission }, index) => {
        const Guard = guard || React.Fragment;

        return children ? (
            children.map((element, index) => {
                const Guard = element.guard || React.Fragment;

                return (
                    <Route key={index} path={element.path} exact render={(props) => (
                        <Guard>
                            <Layout>
                                {isAuthenticated ? (isAuthenticated && (!element.permission.length || ((element.permission.filter(i => user['https://propertydek.com/permissions'].indexOf(i) > -1)).length > 0)) ? <element.component {...props} /> : <Page403 />) : <element.component {...props} />}
                            </Layout>
                        </Guard>
                    )}
                    />
                );
            })
        ) : Component ? (
            <Route key={index} path={path} exact render={(props) => (
                <Guard>
                    <Layout>
                        {isAuthenticated ? ((!permission.length || ((permission.filter(i => user['https://propertydek.com/permissions'].indexOf(i) > -1)).length > 0)) ? <Component {...props} /> : <Page403 />) : <Component {...props} />}

                    </Layout>
                </Guard>
            )}
            />
        ) : null;
    });
}

const Routes = () => (
    <Router>
        <Switch>
            {ChildRoutes(DashboardLayout, dashboardLayoutRoutes)} {ChildRoutes(DashboardLayout, protectedRoutes)} {ChildRoutes(DashboardDetailLayout, dashboardDetailsLayoutRoutes)}{" "} {ChildRoutes(AuthLayout, authLayoutRoutes)}

            {customLayoutRoutes.map(({ component: Component, path }, index) => {
                return (<Route key={index} path={path} exact render={(props) => (
                    <CustomLayout>
                        <Component {...props} />
                    </CustomLayout>
                )} />)
            })}

            <Route render={() => (
                <AuthLayout>
                    <Page404 />
                </AuthLayout>
            )
            } />
        </Switch>
        <CommunicationChat />
    </Router>
);

export default Routes;