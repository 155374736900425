import React, { useState, useEffect } from "react";
import moment from 'moment'
import styles from './complaints.module.css';
import { Link as RouterLink } from "react-router-dom";
import { Typography, Tooltip, Button, Link } from "@material-ui/core";
import { Home, ChevronDown, Clock, Search, Plus, CheckCircle, AlertCircle, X, ArrowUp, XCircle, Calendar } from "react-feather";
import { Dropdown } from 'react-bootstrap';
import Helmet from "react-helmet";
import Footer from "../../components/Footer";
import { TableLoader } from "../../components/LoaderC";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Loader from "../../components/Loader";
import axios from 'axios';
import Select from "react-select";
import { toast } from 'react-smart-toaster';
import styled from "styled-components/macro";
import { Pagination as MuiPagination } from "@material-ui/lab";
import { withAuthenticationRequired, withAuth0, useAuth0 } from "@auth0/auth0-react";
import { spacing } from "@material-ui/system";
import { AddLogCallAPI } from "../../components/AddLogs";
import { TimePicker } from "@material-ui/pickers";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import NumberFormat from "react-number-format";

const brandColor = '#cccccc';
const customStyles = {
  control: (base, state) => ({
    ...base,
    boxShadow: state.isFocused ? 0 : 0,
    borderColor: state.isFocused
      ? brandColor
      : base.borderColor,
    '&:hover': {
      borderColor: state.isFocused
        ? brandColor
        : base.borderColor,
    }
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: '#bfbfbf',
      marginLeft: '0px',
      fontSize: '14px',
      fontWeight: '400',
    }
  }
};


const Pagination = styled(MuiPagination)(spacing);

const OpenedToggleReadOnly = React.forwardRef(({ children, onClick }, ref) => (
  <button ref={ref}
    className={`${styles.ReferStatusBU} ${styles.ReferStatusBUNew} ${styles.Open}`}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}>
    Open
  </button>
));
const ResolvedToggleReadOnly = React.forwardRef(({ children, onClick }, ref) => (
  <button ref={ref}
    className={`${styles.ReferStatusBU} ${styles.ReferStatusBUNew} ${styles.Resolved}`}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}>
    Resolved
  </button>
));
const InprogressToggleReadOnly = React.forwardRef(({ children, onClick }, ref) => (
  <button ref={ref}
    className={`${styles.ReferStatusBU} ${styles.ReferStatusBUNew} ${styles.InProgress}`}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}>
    In-Progress

  </button>
));
const CancelledToggleReadOnly = React.forwardRef(({ children, onClick }, ref) => (
  <button ref={ref}
    className={`${styles.ReferStatusBU} ${styles.ReferStatusBUNew} ${styles.Cancelled}`}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}>
    Cancelled

  </button>
));
const OpenedToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button ref={ref}
    className={`${styles.ReferStatusBU} ${styles.Open}`}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}>
    Open <ChevronDown className={`${styles.DownArrowStatus}`} />
  </button>
));
const ResolvedToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button ref={ref}
    className={`${styles.ReferStatusBU} ${styles.Resolved}`}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}>
    Resolved <ChevronDown className={`${styles.DownArrowStatus}`} />
  </button>
));
const InprogressToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button ref={ref}
    className={`${styles.ReferStatusBU} ${styles.InProgress}`}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}>
    In-Progress <ChevronDown className={`${styles.DownArrowStatus}`} />

  </button>
));
const CancelledToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button ref={ref}
    className={`${styles.ReferStatusBU} ${styles.Cancelled}`}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}>
    Cancelled <ChevronDown className={`${styles.DownArrowStatus}`} />

  </button>
));




const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const schema = yup.object().shape({
  tenant_id: yup.object().required('This field is Required').nullable(),
  category: yup.object().required('This field is Required').nullable(),
  description: yup.string().required('This field is Required').max(200, 'Description cannot be more than 200 characters'),
  contact_way: yup.object().required('This field is Required').nullable(),
  contact_val_phone: yup.string().when('contact_way', (contact_way, schema) => {
    if (contact_way && contact_way.value === 'Phone') return yup.string().required('This field is Required').matches(phoneRegExp, 'Invalid phone number');
    else return yup.string();
  }),
  contact_val_email: yup.string().when('contact_way', (contact_way, schema) => {
    if (contact_way && contact_way.value === 'Email') return yup.string().required('This field is Required').email('Invalid E-mail').max(50, 'Organizer E-mail cannot be more than 50 characters');
    else return yup.string();
  }),
  contact_time_val: yup.object().required('This field is Required').nullable(),
  contact_time_start: yup.date().required('This field is Required'),
  contact_time_end: yup.date().required('This field is Required'),
  priority: yup.object().required('This field is Required').nullable(),
});

const categoryOptions = [
  { label: 'Garbage', value: 'Garbage' },
  { label: 'Noise', value: 'Noise' },
  { label: 'Other', value: 'Other' },
]

const priorityList = [
  { value: 1, label: 'Low' },
  { value: 2, label: 'Medium' },
  { value: 3, label: 'High' }
];
const contactWayList = [
  { value: 'Email', label: 'Email' },
  { value: 'Phone', label: 'Phone' }
];
const contactTimeList = [
  { value: 'Weekdays', label: 'Weekdays' },
  { value: 'Weekends', label: 'Weekends' }
];
function PhoneField(props) {
  return <NumberFormat
    prefix=""
    displayType="input"
    type="tel"
    format={"(###) ###-####"}
    {...props} />;
}

const ComplaintsForm = React.forwardRef((props, ref) => {
  const { register, handleSubmit, control, errors, setValue, watch, reset
  } = useForm({
    resolver: yupResolver(schema), mode: 'onChange'
  });
  const { getAccessTokenSilently } = useAuth0();
  const audience = process.env.REACT_APP_CASE_API_URL;
  const [TenantList, setTenantList] = useState({ data: [], loading: false });
  const [selectedContactway, setselectedContactway] = useState({ value: '', label: 'Select the best way to contact' });
  const defaultTimeStart = moment("12:00 AM", "LT");
  const defaultTimeEnd = moment("12:00 PM", "LT");
  const maxChars = 200;
  const [charsLeft, setCharsLeft] = useState(maxChars);
  const [maxSelectedContactWayChars, setMaxSelectedContactWayChars] = useState(0);
  const [contactWayCharsLeft, setcontactWayCharsLeft] = useState(maxSelectedContactWayChars);
  const [contactType, setContactType] = useState('');

  useEffect(() => {
    async function fetchAssignee() {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
      await axios.get(audience + 'v1/property-tenant-list', {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).then(response => {
        setTenantList(response.data.data);
      }).catch(error => {
        if (typeof error.response !== 'undefined')
          toast.error(error.response.data.message);
      });
    }

    fetchAssignee();

  }, [audience, getAccessTokenSilently]);


  function removeF() {
    reset({
      tenant_id: null,
      category: null,
      contact_way: null,
      contact_time_val: null,
      priority: null,
      contact_time_start: moment("12:00 AM", "LT"),
      contact_time_end: moment("12:00 PM", "LT")
    });
    setCharsLeft(maxChars);
    setcontactWayCharsLeft(maxSelectedContactWayChars);
    setContactType(null);
    setselectedContactway({ value: '', label: 'Select the best way to contact' });


    let tempFiles = [];
    let tempImages = [];
    let newData = props.ImgPathTemp;
    newData = { ...newData, imagePathArr: tempImages, Files: tempFiles };
    props.setState({ ...props.state, ImgPathTemp: newData });
    props.removeClassFun();
  }
  function submitForm(data) {
    reset({
      tenant_id: null,
      category: null,
      contact_way: null,
      contact_time_val: null,
      priority: null,
      contact_time_start: moment("12:00 AM", "LT"),
      contact_time_end: moment("12:00 PM", "LT")
    });
    setCharsLeft(maxChars);
    setcontactWayCharsLeft(maxSelectedContactWayChars);
    setContactType(null);
    setselectedContactway({ value: '', label: 'Select the best way to contact' });
    props.onSubmit(data);
  }

  const onErrorNew = (error, e) => {
    e.stopPropagation();
    e.preventDefault();
  }
  function getContactWay(event) {
    setValue('contact_way', event);
    let m = 0;
    setselectedContactway(event);
    if (event.value === 'Phone') {
      m = 10;
      setContactType('phone');
    }
    if (event.value === 'Email') {
      m = 50;
      setContactType('email');
    }
    setMaxSelectedContactWayChars(m);
    setcontactWayCharsLeft(m);
  }
  function fileUploadButtonClickTemp() {
    document.getElementById('fileUploadID').getElementsByClassName('inputBtn')[0].click();
    return false;
  }

  const fileHandler = (e) => {
    var validExtensions = ['jpg', 'jpeg', 'png', 'svg']; //array of valid extensions
    var fileName = e.target.files[0].name;
    var fileNameExt = fileName.substr(fileName.lastIndexOf('.') + 1);
    if (validExtensions.indexOf(fileNameExt.toLowerCase()) === -1) {
      toast.error('File type is not allowed');

    }
    else if (e.target.files[0].size > 5242880) {
      toast.error('Image size should not be greater than 5 mb.');

    }
    else {
      let tempFiles = props.ImgPathTemp.Files;
      let tempImages = props.ImgPathTemp.imagePathArr;
      let newData = props.ImgPathTemp;
      for (let n = 0; n < e.target.files.length; n++) {
        let file = e.target.files[n];
        tempImages.push(URL.createObjectURL(file));
        tempFiles.push(file);
      }
      newData = { ...newData, imagePathArr: tempImages, Files: tempFiles };
      props.setState({ ...props.state, ImgPathTemp: newData });
    }

  }

  function deleteTempImage(index) {
    let tempFiles = props.ImgPathTemp.Files;
    let tempImages = props.ImgPathTemp.imagePathArr;
    let newData = props.ImgPathTemp;

    tempFiles = tempFiles.filter(function (item, i) {
      return i !== index;
    });

    tempImages = tempImages.filter(function (item, i) {
      return i !== index;
    });
    newData = { ...newData, imagePathArr: tempImages, Files: tempFiles };
    props.setState({ ...props.state, ImgPathTemp: newData });
  }

  return (
    <form onSubmit={handleSubmit(submitForm, onErrorNew)}>
      <div className={`${styles.ReqAddRow}`}>
        <div className={`${styles.ReqAddFormSec}`}>
          <div className={`${styles.FormSec}`}>
            <Controller styles={customStyles}
              name="tenant_id"
              control={control}
              options={TenantList}
              as={Select}
              inputref={register}
              placeholder='Select tenant'
              className={`${styles.CustomSelect}`}
              defaultValue={null}
            />
            {errors.tenant_id && !watch().tenant_id && <p className={`${styles.ErrorM}`}>{errors.tenant_id.message}</p>}
          </div>
          <div className={`${styles.FormSec}`}>
            <Controller styles={customStyles}
              name="category"
              control={control}
              options={categoryOptions}
              as={Select}
              inputref={register}
              placeholder='Select category'
              className={`${styles.CustomSelect}`}
              defaultValue={null}
            />
            {errors.category && !watch().category && <p className={`${styles.ErrorM}`}>{errors.category.message}</p>}
          </div>
          <div className={`${styles.FormSec}`}>
            <textarea placeholder="Add description" className={`${styles.FormFieldArea}`} name="description" ref={register} maxLength={maxChars} onChange={e => setCharsLeft(maxChars - e.target.value.length)}></textarea>
            <p className={`${styles.RemainingText}`}>{charsLeft} {(charsLeft > 1) ? 'characters' : 'character'} remaining</p>
            {errors.description && <p className={`${styles.ErrorM}`}>{errors.description.message}</p>}

          </div>
          <div className={`${styles.FormSecRow}`}>
            <div className={`${styles.FormHalfSec}`}>
              <div className={`${styles.FormSec}`}>
                <Controller
                  name="contact_way"
                  control={control}
                  options={contactWayList}
                  inputref={register}
                  defaultValue={null}
                  styles={customStyles}
                  className={`${styles.CustomSelect}`}
                  render={({ onChange, options, value }) => (
                    <Select styles={customStyles} className={`${styles.CustomSelect}`} onChange={getContactWay} options={contactWayList} value={selectedContactway} placeholder='Select the best way to contact' />
                  )}
                />
                {errors.contact_way && !watch().contact_way && <p className={`${styles.ErrorM}`}>{errors.contact_way.message}</p>}
              </div>
            </div>
            <div className={`${styles.FormHalfSec}`}>

              {contactType === 'phone' && <div className={`${styles.FormSec}`}>
                <Controller
                  className={`${styles.FormField}`}
                  name="contact_val_phone"
                  control={control}
                  inputref={register}
                  placeholder="Enter phone no."
                  defaultValue={''}
                  render={({ onChange, value, ref }) => (
                    <PhoneField inputref={ref} onChange={onChange} value={value} className={`${styles.FormField}`} />
                  )}
                />

                {errors.contact_val_phone && <p className={`${styles.ErrorM}`}>{errors.contact_val_phone.message}</p>}
              </div>}
              {contactType === 'email' && <div className={`${styles.FormSec}`}>
                <input placeholder="Enter email address" className={`${styles.FormField}`} type="text" name="contact_val_email"
                  ref={register} maxLength={maxSelectedContactWayChars} onChange={e => setcontactWayCharsLeft(maxSelectedContactWayChars - e.target.value.length)} />
                {(maxSelectedContactWayChars === 50) && <p className={`${styles.RemainingText}`}>{contactWayCharsLeft} {(contactWayCharsLeft > 1) ? 'characters' : 'character'} remaining</p>}
                {errors.contact_val_email && <p className={`${styles.ErrorM}`}>{errors.contact_val_email.message}</p>}
              </div>}
            </div>
          </div>
          <div className={`${styles.FormSecRow}`}>
            <div className={`${styles.FormHalfSec}`}>
              <div className={`${styles.FormSec}`}>
                <Controller styles={customStyles}
                  name="contact_time_val"
                  control={control}
                  options={contactTimeList}
                  as={Select}
                  inputref={register}
                  placeholder='Select the best time to contact'
                  className={`${styles.CustomSelect}`}
                  defaultValue={null}
                />
                {errors.contact_time_val && !watch().contact_time_val && <p className={`${styles.ErrorM}`}>{errors.contact_time_val.message}</p>}
              </div>
            </div>
            <div className={`${styles.FormHalfSec}`}>
              <div className={`${styles.FormTimeZone}`}>
                <div className={`${styles.TimePick} TimePick`}>
                  <Clock className={`${styles.ClockIcon}`} />
                  <Controller
                    defaultValue={defaultTimeStart}
                    control={control}
                    name="contact_time_start"
                    render={(props) => (
                      <TimePicker
                        value={props.value}
                        onChange={(e) => props.onChange(e)}
                      />
                    )}
                  />
                  {errors.contact_time_start && <p className={`${styles.ErrorM}`}>{errors.contact_time_start.message}</p>}
                </div>
                <p className={`${styles.TimeP}`}>to</p>
                <div className={`${styles.TimePick} TimePick`}>
                  <Clock className={`${styles.ClockIcon}`} />
                  <Controller
                    control={control}
                    defaultValue={defaultTimeEnd}
                    name="contact_time_end"
                    render={(props) => (
                      <TimePicker
                        value={props.value}
                        onChange={(e) => props.onChange(e)}
                      />
                    )}
                  />
                  {errors.contact_time_end && <p className={`${styles.ErrorM}`}>{errors.contact_time_end.message}</p>}
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.FormSec}`}>
            <Controller styles={customStyles}
              name="priority"
              control={control}
              options={priorityList}
              as={Select}
              inputref={register}
              placeholder='Select priority'
              className={`${styles.CustomSelect}`}
              defaultValue={null}
            />
            {errors.priority && !watch().priority && <p className={`${styles.ErrorM}`}>{errors.priority.message}</p>}
          </div>
        </div>
        <div className={`${styles.FormIMGSec}`}>
          <div className={`${styles.FileIMGDiv}`}>
            <div className={`${styles.FileUpDuBUDiv}`}>
              <label htmlFor="file-upload" className={`${styles.UploadLabel}`} id="fileUploadID">
                <input className="inputBtn" type="file" multiple onChange={fileHandler} accept=".jpg,.png,.jpeg,.svg" inputprops={{ accept: 'image/*' }} />
                <span onClick={fileUploadButtonClickTemp.bind()}>
                  <div className={`${styles.UpIcon}`}><img src="/static/img/upload-floor.png" alt="" /></div>
                  <p>Upload Photos</p>
                  <p className={`${styles.UploadText}`}>File size upto 5 MB  JPEG, JPG, PNG</p>
                </span>
              </label>
            </div>
            <div className={`${styles.Width100}`}>
              <div className={`${styles.UpIMGSec}`}>
                {props.ImgPathTemp.imagePathArr.map((item, index) => {
                  return <div className={`${styles.ImgFile}`} key={index}>
                    <div className={`${styles.ImgFileUnder}`}>
                      <img src={item} alt="" />
                      <Button onClick={deleteTempImage.bind(this, index)}><X /></Button>
                    </div>
                  </div>
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.ButtonDiv}`}>
        <button type="submit" className={`${styles.ButtonVoiletSolid}`}>Add</button>
        <button onClick={() => { removeF(this) }} type="Reset" className={`${styles.ButtonVoiletLine}`}>Cancel</button>
      </div>
    </form>
  );
});





class Complaints extends React.Component {
  constructor({ match, ...props }) {
    super(...arguments);
    this.perPage = 8;
    this.state = {
      allData: [], data: [], pageCount: 0, page: 1, formOpen: false, filterStatusList: [{ value: '', label: 'Any' }, { value: 'Open', label: 'Open' }, { value: 'In-Progress', label: 'In-Progress' }, { value: 'Resolved', label: 'Resolved' }, { value: 'Cancelled', label: 'Cancelled' }], filterViewList: [{ value: '', label: 'All' }, { value: 'Active', label: 'Active' }, { value: 'Archived', label: 'Archived' }], filterPriorityList: [{ value: '', label: 'Any' }, { value: 'Low', label: 'Low' }, { value: 'Medium', label: 'Medium' }, { value: 'High', label: 'High' }], filterData: { searchKey: '', view: '', status: '', property: '', priority: '', assignee: '', created: '' }, filterAssigneeList: [], loading: true, contentLoading: true, ImgPathTemp: { Files: [], imagePathArr: [] }
    }
    this.audience = process.env.REACT_APP_CASE_API_URL;
    this.audienceApplicant = process.env.REACT_APP_APPLICANT_API_URL;

  }

  async componentDidMount() {
    const { getAccessTokenSilently } = this.props.auth0;
    this.token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

    try {
      this.setState({ ...this.state, loading: true, contentLoading: true });
      await axios(this.audience + 'v1/complaints', {
        headers: {
          Authorization: `Bearer ${this.token}`,
        }
      }).then(response => {
        let allData = response.data.data;
        let pageCount = (allData.length / this.perPage);
        if (pageCount > parseInt(pageCount))
          pageCount = (parseInt(pageCount) + 1);

        this.setState({ ...this.state, allData: allData, data: allData, pageCount: pageCount, loading: false, contentLoading: false }, this.filterHandler);
      });
      axios(
        this.audience + 'v1/assignees', {
        headers: {
          Authorization: `Bearer ${this.token}`,
        }
      }).then(response => {
        let assigneeData = response.data.data;

        assigneeData = [{ value: '', label: 'Any' }].concat(assigneeData);
        this.setState({ ...this.state, filterAssigneeList: assigneeData });
      });

      axios(
        this.audienceApplicant + 'v1/get-all-properties', {
        headers: {
          Authorization: `Bearer ${this.token}`,
        }
      }).then(response => {
        let propertyData = response.data.data;

        propertyData = [{ value: '', label: 'Any' }].concat(propertyData);
        this.setState({ ...this.state, filterPropertyList: propertyData });
      });
    } catch (error) {
      this.setState({ ...this.state, contentLoading: false });
    }
  }

  changeCStatus(data, cVal, e) {
    e.stopPropagation();
    if (data.current_status !== cVal) {
      this.setState({ ...this.state, loading: true });
      axios.put(this.audience + 'v1/complaints/' + data.id,
        { 'current_status': cVal }, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        }
      }).then(response => {
        let respData = response.data.data;
        let tempData = this.state.allData.map((item) => {
          if (item.id === data.id) {
            return { ...data, current_status: cVal, overdue: respData.overdue }
          }
          return item;
        });
        toast.success(response.data.message);
        this.setState({ ...this.state, allData: tempData, loading: false }, this.filterHandler);
        let statusText = 'Open';
        if (respData.current_status === 1) {
          statusText = 'In-Progress';
        }
        if (respData.current_status === 2) {
          statusText = 'Resolved';
        }
        if (respData.current_status === 3) {
          statusText = 'Cancelled';
        }

        let logData = {
          'title': 'Complaint status changed',
          'description': [
            'Name: ' + respData.title,
            'Complaints number: ' + respData.complaint_no,
            'Property: ' + respData.property_name,
            'Apartment: ' + respData.apt_name,
            'Tenant: ' + respData.tenant_name,
            'Status: ' + statusText,
          ]
        }
        AddLogCallAPI(logData, this.token);
      }).catch(error => {
        if (typeof error.response !== 'undefined')
          toast.error(error.response.data.message);
        this.setState({ ...this.state, loading: false });

      });
    }
  }

  onChangeHandler(e) {
    let filterData = this.state.filterData;
    filterData = { ...filterData, searchKey: e.target.value };
    this.setState({ ...this.state, filterData: filterData }, this.filterHandler);
  }

  handleChangeFilter = (stype, selectedOption) => {
    let filterData = this.state.filterData;
    if (stype === 'view') {
      filterData = { ...filterData, view: selectedOption.value };
    }
    if (stype === 'status') {
      filterData = { ...filterData, status: selectedOption.value };
    }
    if (stype === 'priority') {
      filterData = { ...filterData, priority: selectedOption.value };
    }
    if (stype === 'property') {
      filterData = { ...filterData, property: selectedOption.value };
    }
    if (stype === 'assignee') {
      filterData = { ...filterData, assignee: selectedOption.value };
    }
    this.setState({ ...this.state, filterData: filterData }, this.filterHandler);
  };

  handleApply(state, event, picker) {
    let dateL = moment(picker.startDate).format('L') + '-' + moment(picker.endDate).format('L');
    let filterData = state.filterData;
    filterData = { ...filterData, created: dateL };
    this.setState({ ...this.state, filterData: filterData }, this.filterHandler);
  }

  handleCancel(state, event, picker) {
    let filterData = state.filterData;
    filterData = { ...filterData, created: '' };
    this.setState({ ...this.state, filterData: filterData }, this.filterHandler);
  }

  handleHide(state, event, picker) {
    let filterData = state.filterData;
    if (filterData.created === '') {
      this.setState({ ...this.state, filterData: filterData }, this.filterHandler);
    }
  }

  filterHandler() {
    const { filterData } = this.state;
    let filteredData = this.state.allData;
    if (filterData.searchKey !== '') {
      let inputVal = filterData.searchKey;
      filteredData = filteredData.filter((item) => {
        return item.title.toLowerCase().includes(inputVal) || item.complaint_no.toLowerCase().includes(inputVal) || item.tenant_name.toLowerCase().includes(inputVal) || item.property_name.toLowerCase().includes(inputVal) || item.apt_name.toLowerCase().includes(inputVal) || item.assignee_name.toLowerCase().includes(inputVal);
      });
    }
    if (filterData.property !== '') {

      filteredData = filteredData.filter(i => i.property_id === filterData.property);
    }
    if (filterData.assignee !== '') {
      filteredData = filteredData.filter(i => i.assignee_id === filterData.assignee);
    }
    if (filterData.view !== '') {
      if (filterData.view === 'Active') {
        filteredData = filteredData.filter(i => i.is_archived === 0);
      }
      if (filterData.view === 'Archived') {
        filteredData = filteredData.filter(i => i.is_archived === 1);
      }
    }
    if (filterData.status !== '') {
      if (filterData.status === 'Open') {
        filteredData = filteredData.filter(i => i.current_status === 0 && i.is_archived === 0);
      }
      if (filterData.status === 'In-Progress') {
        filteredData = filteredData.filter(i => i.current_status === 1 && i.is_archived === 0);
      }
      if (filterData.status === 'Resolved') {
        filteredData = filteredData.filter(i => i.current_status === 2 && i.is_archived === 0);
      }
      if (filterData.status === 'Cancelled') {
        filteredData = filteredData.filter(i => i.current_status === 3 && i.is_archived === 0);
      }
    }
    if (filterData.priority !== '') {
      if (filterData.priority === 'Low') {
        filteredData = filteredData.filter(i => i.priority === 1);
      }
      if (filterData.priority === 'Medium') {
        filteredData = filteredData.filter(i => i.priority === 2);
      }
      if (filterData.priority === 'High') {
        filteredData = filteredData.filter(i => i.priority === 3);
      }
    }
    if (filterData.created !== '') {
      filteredData = filteredData.filter(i => {
        var dateR = filterData.created.split('-');
        if (dateR[0] <= moment(i.created_at).format('L') && moment(i.created_at).format('L') <= dateR[1]) {
          return true;
        }
        return false;
      });
    }
    let pageCount = (filteredData.length / this.perPage);
    if (pageCount > parseInt(pageCount))
      pageCount = (parseInt(pageCount) + 1);

    let cPage = this.state.page;
    if (cPage > pageCount)
      cPage = pageCount;
    if (cPage === 0)
      cPage = 1;

    this.setState({ ...this.state, data: filteredData, pageCount: pageCount, page: cPage });
  }

  paginationChange = (event, value) => {
    this.setState({ ...this.state, page: value });
  };

  addClassFun(event) {
    event.stopPropagation();
    event.stopPropagation();
    this.setState({ ...this.state, formOpen: true, Open: false, });
  }

  removeClassFun(event, data) {
    this.setState({ ...this.state, formOpen: false, ImgPathTemp: { Files: [], imagePathArr: [] } });
  }

  async onSubmit(data) {
    this.setState({ ...this.state, loading: true });
    if (data.category) {
      data.category = data.category.value;
    }
    if (data.tenant_id) {
      data.property_id = data.tenant_id.property_id;
      data.tenant_id = data.tenant_id.value;
    }
    if (data.contact_way) {
      data.contact_way = data.contact_way.value;
    }
    if (data.contact_time_val) {
      data.contact_time_val = data.contact_time_val.value;
    }
    if (data.contact_way === 'Phone') {
      data.contact_val = data.contact_val_phone;
    }
    if (data.contact_way === 'Email') {
      data.contact_val = data.contact_val_email;
    }
    if (data.priority) {
      data.priority = data.priority.value;
    }
    if (data.contact_time_start) {
      data.contact_time_start = moment(data.contact_time_start).format('LT');
    }
    if (data.contact_time_end) {
      data.contact_time_end = moment(data.contact_time_end).format('LT');
    }
    let formData = new FormData();
    Object.keys(data).map((key) => {
      formData.append(key, data[key]);
      return true;
    });
    if (this.state.ImgPathTemp.Files.length) {
      for (let n in this.state.ImgPathTemp.Files) {
        formData.append("uploadImages[]", this.state.ImgPathTemp.Files[n]);
      }
    }

    return axios.post(this.audience + 'v1/complaints', formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${this.token}`,
      },
    }).then(response => {
      let respData = response.data.data;
      toast.success(response.data.message);
      let allMdata = this.state.allData;
      allMdata = [respData].concat(allMdata);
      this.setState({ ...this.state, allData: allMdata, formOpen: false, Open: false, loading: false, ImgPathTemp: { Files: [], imagePathArr: [] } }, this.filterHandler);
      let logData = {
        'title': 'New complaint is created',
        'description': [
          'Name: ' + respData.title,
          'Complaints number: ' + respData.complaint_no,
          'Property: ' + respData.property_name,
          'Apartment: ' + respData.apt_name,
          'Tenant: ' + respData.tenant_name,
        ]
      }
      AddLogCallAPI(logData, this.token);

    }).catch(error => {
      if (typeof error.response !== 'undefined')
        toast.error(error.response.data.message);
      this.setState({ ...this.state, loading: false });
    });

  };

  render() {
    const { user, isAuthenticated } = this.props.auth0;
    return (<React.Fragment>
      {this.state.loading && <Loader />}
      <Helmet title="Complaints" />
      <div className={`${styles.pageTitleWrap}`} >
        <Typography display="inline" className={`${styles.pageTitle}`} >Complaints</Typography>
        <nav aria-label="breadcrumb">
          <ol className={`${styles.breadcrumb}`}>
            <li className={`${styles.breadcrumbItem}`}><span><Home /></span>Cases</li>
            <li className={`${styles.breadcrumbItem} ${styles.active}`}>Complaints</li>
          </ol>
        </nav>
      </div>

      <div className={`${styles.BodyCon}`}>
        <div className={`${styles.mainCardDiv}`}>
          <div className={`${styles.mainCard}`}>
            {this.state.contentLoading && <div className="Loader TableLoader"><TableLoader /></div>}
            {!this.state.contentLoading && <div className="control-pane">
              <div className={`${styles.Row}`}>
                <div className={`${styles.SearchFilterSec}`}>
                  <div className={`${styles.SearchCon}`}>
                    <div className={`${styles.SearchConSec}`}>
                      <Search />
                      <input type="text" placeholder="Search" onChange={this.onChangeHandler.bind(this)} />
                    </div>
                  </div>

                  <div className={`${styles.FilterSec}`}>
                    <div className={`${styles.FilterCard} ${styles.Select}`}>
                      <Select styles={customStyles} className={`${styles.CustomSelect}`} placeholder='Property' onChange={this.handleChangeFilter.bind(this, 'property')} options={this.state.filterPropertyList} />
                    </div>
                    {(this.state.filterData.view === 'Active' || this.state.filterData.view === '') && <div className={`${styles.FilterCard} ${styles.Select}`}>
                      <Select styles={customStyles} className={`${styles.CustomSelect}`} placeholder='Status' onChange={this.handleChangeFilter.bind(this, 'status')} options={this.state.filterStatusList} />
                    </div>}
                    <div className={`${styles.FilterCard} ${styles.Select}`}>
                      <Select styles={customStyles} className={`${styles.CustomSelect}`} placeholder='View' onChange={this.handleChangeFilter.bind(this, 'view')} options={this.state.filterViewList} />
                    </div>
                    {(this.state.filterData.view === 'Archived') && <div className={`${styles.FilterCard} ${styles.Select}`}>
                      <Select styles={customStyles} className={`${styles.CustomSelect}`} placeholder='Assignee' onChange={this.handleChangeFilter.bind(this, 'assignee')} options={this.state.filterAssigneeList} />
                    </div>}
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic" className={`${styles.TableAddBUSecondary}`}>
                        More ({(this.state.filterData.view === 'Active' || this.state.filterData.view === '') ? 3 : 1}) <ChevronDown />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="DropDownMenuBoxCus">
                        <div className={`${styles.ApplicantFilterSec}`}>
                          {(this.state.filterData.view === '' || this.state.filterData.view === 'Active') && <>
                            <div className={`${styles.FilterCardDP} ${styles.Select}`}>
                              <Select styles={customStyles} className={`${styles.CustomSelect}`} placeholder='Priority' onChange={this.handleChangeFilter.bind(this, 'priority')} options={this.state.filterPriorityList} />
                            </div>
                            <div className={`${styles.FilterCardDP} ${styles.Select}`}>
                              <Select styles={customStyles} className={`${styles.CustomSelect}`} placeholder='Assignee' onChange={this.handleChangeFilter.bind(this, 'assignee')} options={this.state.filterAssigneeList} />
                            </div>
                          </>}
                          <div className={`${styles.FilterCardDP}`}>
                            <div className={`${styles.DatePickerCustom}`}>
                              <DateRangePicker initialSettings={{ showDropdowns: true, autoUpdateInput: false }} drops="true" onApply={this.handleApply.bind(this, this.state)} onCancel={this.handleCancel.bind(this, this.state)} onHide={this.handleHide.bind(this, this.state)}>
                                <input type="text" className={`${styles.formControl}`} defaultValue={this.state.filterData.created} placeholder="Any" />
                              </DateRangePicker>

                              <Calendar />
                            </div>
                          </div>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                    {(isAuthenticated && user['https://propertydek.com/permissions'].indexOf('complaint:create') > -1) && <Link className={`${styles.AddNoteBu}`} onClick={this.addClassFun.bind(this)}><Plus /> Complaint</Link>}
                  </div>

                </div>

                <div className={`${styles.NotesRow}`}>
                  {this.state.formOpen && <div className={`${styles.ReqAddCard} `}>
                    <p className={`${styles.ReqAddCardTitle}`}>New Complaints</p>
                    <ComplaintsForm onSubmit={this.onSubmit.bind(this)} removeClassFun={this.removeClassFun.bind(this)} setState={this.setState.bind(this)} ImgPathTemp={this.state.ImgPathTemp} state={this.state} />
                  </div>}
                  {this.state.data.map((item, index) => {
                    return (index >= ((this.state.page - 1) * this.perPage) && index < (((this.state.page - 1) * this.perPage) + this.perPage)) ? <div className={`${styles.NotesCard}`} key={index}>
                      <RouterLink to={`/cases/complaints/details/${item.id}`} key={index}>
                        <div className={`${styles.TitleWithTag}`}>
                          <div className={`${styles.TitleRow}`}>
                            {item.overdue && item.current_status !== 3 && <span className={`${styles.TagDiv} ${styles.Red}`}>{item.overdue}</span>}
                            {(item.is_archived === 1) && <span className={`${styles.AcrhivedLabel}`}> Archived</span>}
                            <p className={`${styles.InsideConName}`}>
                              <Tooltip title={item.title}><span className={`${styles.ConNameTrancate}`}>{item.title}</span></Tooltip>
                              {/* <span>#{item.complaint_no}</span> */}
                            </p>
                          </div>


                        </div>
                        <div className={`${styles.PropeCardInFR}`}>
                          <div className={`${styles.MainReqConDivBig}`}>
                            <div className={`${styles.MainReqConUR}`}>
                              <Tooltip title={item.tenant_name}><p className={`${styles.MainReqConDe} ${styles.TextEclipsName}`}>{item.tenant_name}</p></Tooltip>
                              <p className={`${styles.MainReqConDe}`}><span>Submitted {item.submitted_date}</span></p>
                            </div>
                          </div>
                          <div className={`${styles.MainReqConDivSmall}`}>
                            <div className={`${styles.MainReqConUR}`}>
                              <p className={`${styles.MainReqConDe}`}><Tooltip title={item.property_name}><span className={`${styles.TextEclips}`}>{item.property_name}</span></Tooltip> - {item.apt_name}</p>
                              <p className={`${styles.MainReqConDe}`}>
                                <span>Assignee</span>
                                <Tooltip title={(item.assignee_name ? item.assignee_name : '--')}><span className={`${styles.TextEclipsName}`}>{(item.assignee_name ? item.assignee_name : '--')}</span></Tooltip>
                              </p>
                            </div>
                          </div>
                          <div className={`${styles.MainReqConAction}`}>
                            {item.is_archived === 0 && <div>
                              {item.priority === 1 && <p className={`${styles.ReffStatus} ${styles.Low}`}>
                                <ArrowUp />
                                <span> {item.priority_text}</span>
                              </p>}
                              {item.priority === 2 && <p className={`${styles.ReffStatus} ${styles.Medium}`}>
                                <ArrowUp />
                                <span> {item.priority_text}</span>
                              </p>}
                              {item.priority === 3 && <p className={`${styles.ReffStatus} ${styles.High}`}>
                                <ArrowUp />
                                <span> {item.priority_text}</span>
                              </p>}
                            </div>}
                            {/* {(item.is_archived === 1) && <div><p className={`${styles.ReffStatus} ${styles.Archived}`}> Archived</p></div>} */}
                            {item.is_archived === 0 && <div className={`${styles.MainReqConDropBU}`}>
                              {!(isAuthenticated && user['https://propertydek.com/permissions'].indexOf('complaint:update') > -1) && <Dropdown>
                                {item.current_status === 0 && <Dropdown.Toggle as={OpenedToggleReadOnly} />}
                                {item.current_status === 1 && <Dropdown.Toggle as={InprogressToggleReadOnly} />}
                                {item.current_status === 2 && <Dropdown.Toggle as={ResolvedToggleReadOnly} />}
                                {item.current_status === 3 && <Dropdown.Toggle as={CancelledToggleReadOnly} />}
                              </Dropdown>}
                              {(isAuthenticated && user['https://propertydek.com/permissions'].indexOf('complaint:update') > -1) && <Dropdown>
                                {item.current_status === 0 && <Dropdown.Toggle as={OpenedToggle} />}
                                {item.current_status === 1 && <Dropdown.Toggle as={InprogressToggle} />}
                                {item.current_status === 2 && <Dropdown.Toggle as={ResolvedToggle} />}
                                {item.current_status === 3 && <Dropdown.Toggle as={CancelledToggle} />}
                                <Dropdown.Menu className="DropDownMenuBoxCus">
                                  <Dropdown.Item className="DropDListWIcon" onClick={this.changeCStatus.bind(this, item, 0)}><AlertCircle /> Open</Dropdown.Item>
                                  <Dropdown.Item className="DropDListWIcon" onClick={this.changeCStatus.bind(this, item, 1)}><Clock /> In-Progress</Dropdown.Item>
                                  <Dropdown.Item className="DropDListWIcon" onClick={this.changeCStatus.bind(this, item, 2)}><CheckCircle /> Resolved</Dropdown.Item>
                                  <Dropdown.Item className="DropDListWIcon" onClick={this.changeCStatus.bind(this, item, 3)}><XCircle /> Cancelled</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>}
                            </div>}
                          </div>
                        </div>
                      </RouterLink>
                    </div> : null;

                  })}
                  {this.state.pageCount > 0 && <div className="Pagination">
                    <Pagination count={this.state.pageCount} color="primary" page={this.state.page} onChange={this.paginationChange} />
                    <div className="PagiCount">{this.state.page} of {this.state.pageCount} pages ({(this.state.data.length > 1) ? this.state.data.length + ' items' : this.state.data.length + ' item'})</div>
                  </div>}
                  {this.state.data.length === 0 && <div className={`${styles.NoDataMain}`}>
                    <div className={`${styles.NoDataIMG}`}>
                      <img src="/static/img/nodata.svg" alt="No Data" />
                    </div>
                    <p className={`${styles.NoDataText}`}>No Data Found</p>
                  </div>}
                </div>
              </div>
              <div id='waitingpopup' className={`${styles.waitingpopup}`}>
                <span id='gif' className={`${styles.image}`}></span>
              </div>
            </div>}
          </div>
        </div>
      </div>

      <Footer />

    </React.Fragment >);
  }

}


//export default Complaints;
export default withAuthenticationRequired(withAuth0(Complaints), {
  onRedirecting: () => <Loader />,
});