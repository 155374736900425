// import { io } from 'socket.io-client';
// console.log(process.env.REACT_APP_SOCKET_SERVER_URL)

// const socket = io(process.env.REACT_APP_SOCKET_SERVER_URL);
// export default socket;
// const socket = io('ws://localhost:3000');
// useSocket.js
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { io } from 'socket.io-client';

let socketInstance = null;

const useSocket = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [socket, setSocket] = useState(socketInstance);

  useEffect(() => {
    const initSocket = async () => {
      if (!socketInstance) {
        try {
          const token = await getAccessTokenSilently();
          const newSocket = io(process.env.REACT_APP_SOCKET_SERVER_URL, {
            auth: {
              token: token,
            },
          });
          socketInstance = newSocket;
          setSocket(newSocket);
        } catch (error) {
          console.error('Error initializing socket:', error);
        }
      }
    };

    initSocket();

    // Cleanup function to disconnect the socket when the application unmounts
    return () => {
      if (socketInstance) {
        socketInstance.disconnect();
        socketInstance = null;
      }
    };
  }, [getAccessTokenSilently]);

  return socket;
};

export default useSocket;
