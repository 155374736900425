import { useAuth0 } from "@auth0/auth0-react";
import { Button, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { ArrowLeft, Calendar, Search, X } from "react-feather";
import { toast } from "react-smart-toaster";
import Loader from "../../../components/Loader";
import styles from "../smartdevices.module.css";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AddLogCallAPI } from "../../../components/AddLogs";
import DateRangePicker from "rsuite/DateRangePicker";
import moment from "moment";

const schema = yup.object().shape({
    property_id: yup.string().required("This field is Required"),
    apt_id: yup.string().required("This field is Required"),
    floor_id: yup.string().when('apt_id', (apt_id) => {
        if (apt_id && apt_id === 0) return yup.string().required('This field is Required');
        else return yup.string();
    }),
    apt_text: yup.string().when('apt_id', (apt_id) => {
        if (apt_id && apt_id === 0) return yup.string().required('This field is Required');
        else return yup.string();
    }),
});

const AddLockFormNew = ({ setShowAddFormNew, setSnackbarMsg, setSnackbarOpen, onSubmitLocal }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [loading, setLoading] = useState(true);
    const [allTuyaDeviceList, setAllTuyaDeviceList] = useState([]);
    const [tuyaDeviceList, setTuyaDeviceList] = useState([]);
    const [deviceList, setDeviceList] = useState([]);
    const [deviceIds, setDeviceIds] = useState([]);
    const [propertyList, setPropertyList] = useState([]);
    const [apartmentList, setApartmentList] = useState([]);
    const [selectPropertyId, setSelectPropertyId] = useState(0);
    const [defaultDateFilter, setDefaultDateFilter] = useState([moment().toDate(), moment().toDate()]);
    const [searchKey, setSearchKey] = useState("");
    const [selectedDeviceIds, setSelectedDeviceIds] = useState([]);
    const [selectedDevices, setSelectedDevices] = useState([]);
    const [cuurentStep, setCuurentStep] = useState(1);
    const [isNonApartment, setIsNonApartment] = useState(false);
    const [floorList, setFloorList] = useState([]);

    const { handleSubmit, control, setValue, errors, clearErrors } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange"
    });

    useEffect(() => {
        const fetchPropertyList = async () => {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
            await axios.get(process.env.REACT_APP_DEVICE_API_URL + "v1/properties", {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                setPropertyList(response.data.data);
            }).catch((error) => {
                if (typeof error.response !== "undefined")
                    toast.error(error.response.data.message);
            });
        };
        fetchPropertyList();
    }, [getAccessTokenSilently]);

    useEffect(() => {
        const fetchDeviceList = async () => {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
            await axios.get(process.env.REACT_APP_DEVICE_API_URL + "v1/ttlock/all-lock-list", {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then((response) => {
                setLoading(false);
                setAllTuyaDeviceList(response.data.data);
            }).catch((error) => {
                setLoading(false);
                if (typeof error.response !== "undefined")
                    toast.error(error.response.data.message);
            });
        };
        fetchDeviceList();
    }, [getAccessTokenSilently]);

    useEffect(() => {
        const fetchDeviceIdList = async () => {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
            await axios.get(process.env.REACT_APP_DEVICE_API_URL + "v1/all-device-ids", {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then((response) => {
                setDeviceIds(response.data.data);
            }).catch((error) => {
                if (typeof error.response !== "undefined")
                    toast.error(error.response.data.message);
            });
        };
        fetchDeviceIdList();
    }, [getAccessTokenSilently]);

    useEffect(() => {
        if (allTuyaDeviceList.length) {
            let tempDeviceList = allTuyaDeviceList.filter(i => deviceIds.indexOf(i?.lock_id?.toString()) === -1);
            tempDeviceList.sort((a, b) => a?.binddate < b?.binddate ? 1 : -1);

            setTuyaDeviceList(tempDeviceList);
        }
    }, [deviceIds, allTuyaDeviceList, defaultDateFilter, searchKey]);

    useEffect(() => {
        if (tuyaDeviceList.length) {
            let tempDeviceList = tuyaDeviceList;

            if (searchKey !== '') {
                let inputVal = searchKey;
                inputVal = inputVal.toLowerCase();

                tempDeviceList = tempDeviceList.filter((item) => {
                    return (item?.id?.toLowerCase().includes(inputVal) || item?.name?.toLowerCase().includes(inputVal) || item?.product_name?.toLowerCase().includes(inputVal));
                });
            }

            if (defaultDateFilter) {
                tempDeviceList = tempDeviceList.filter(i => {
                    if (moment(moment.unix(i?.binddate / 1000).format("YYYY-MM-DD")).isBetween(moment(defaultDateFilter[0]).format("YYYY-MM-DD"), moment(defaultDateFilter[1]).format("YYYY-MM-DD"), undefined, [])) {
                        return true;
                    }
                    return false;
                })
            }

            setDeviceList(tempDeviceList);
        }
    }, [tuyaDeviceList, defaultDateFilter, searchKey]);

    const selectProperty = (e) => {
        let apartmentListTemp = [];
        propertyList?.map(i => {
            if (i.value === e.target.value) {
                apartmentListTemp = i.apartments;

                var floorArr = [];
                for (var j = 1; j <= i?.no_of_floor; j++) {
                    floorArr.push(j);
                }
                setFloorList(floorArr);
            }
            return null;
        });

        setValue('apt_id', '');
        setApartmentList(apartmentListTemp);
        setSelectPropertyId(e.target.value);

        clearErrors();
    }

    const onSubmit = async (data) => {
        if (selectedDeviceIds.length === 0) {
            toast.error('Please select device.');
            return false;
        }

        setLoading(true);
        data = { ...data, devices: selectedDevices };

        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
        axios.post(process.env.REACT_APP_DEVICE_API_URL + "v1/ttlock/add-multiple-devices", data, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then((response) => {
            setLoading(false);
            setShowAddFormNew(false);

            setSnackbarMsg(response.data.message);
            setSnackbarOpen(true);

            let rData = response.data.data;
            onSubmitLocal(rData);

            rData?.map(item => {
                let logData = {
                    'title': 'New smart device is created',
                    'description': [
                        'Device name: ' + item.name,
                        'Device id: ' + item.deviceID,
                        'Product type: ' + item.product_type,
                        'Location: ' + item.location
                    ]
                }
                AddLogCallAPI(logData, token);

                return false;
            });

        }).catch((error) => {
            setLoading(false);
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
        });
    }

    const getLocationVal = (id) => {
        if (selectedDeviceIds.indexOf(id) > -1) {
            let deviceTemp = selectedDevices?.filter(i => i?.lock_id === id);
            if (deviceTemp?.length > 0) {
                return deviceTemp[0]?.location;
            }
        }
        return '';
    }

    return (<>
        {loading && <Loader />}
        <DialogTitle id="alert-dialog-title">
            <button className={`${styles.BackAddBU}`} onClick={(e) => setShowAddFormNew(false)}><ArrowLeft /></button>
            Add TTLock Device
            <button onClick={(e) => setShowAddFormNew(false)}><X /></button>
        </DialogTitle>
        <DialogContent className={`${styles.RequestBodyPop}`}>
            {cuurentStep === 1 && <div className={`${styles.ModalFormGroup}`}>
                <div className={`${styles.AddDeviceSec}`}>
                    <div className={`${styles.SearchConSec}`}>
                        <Search />
                        <input type="text" placeholder="Search" onChange={(e) => setSearchKey(e.target.value)} />
                    </div>
                    <div className={`${styles.AddDeviceDatePick} DashFilterSec AddDevice`}>
                        <DateRangePicker
                            format={"M/d/yy"}
                            placeholder={"Filter by date"}
                            placement={"bottomEnd"}
                            caretAs={Calendar}
                            showOneCalendar
                            defaultValue={defaultDateFilter}
                            onChange={(e) => setDefaultDateFilter(e)}
                        />
                    </div>
                </div>
                <p className={`${styles.DeviceSelectedMSG}`}>{(selectedDeviceIds?.length > 0 ? (selectedDeviceIds?.length > 1 ? selectedDeviceIds?.length + ' devices selected' : selectedDeviceIds?.length + ' device selected') : 'No device selected')}</p>
                <div className={`${styles.AddDeviceRow}`}>
                    {deviceList?.map((item, index) => {
                        return (<div className={`${styles.AddDeviceList}`} key={index}>
                            <div className={selectedDeviceIds.indexOf(item?.lock_id) > -1 ? `${styles.AddDeviceListUnder} ${styles.Active}` : `${styles.AddDeviceListUnder}`}>
                                <div className={`${styles.AddDeviceListHeadSec}`}>
                                    <div className={`${styles.AddDeviceListIconSec}`}>
                                        {item?.type === 'Lock' && <img src="/static/img/Door.svg" width="24px" height="24px" alt="" />}
                                        {item?.type === 'Gateway' && <img src="/static/img/router.svg" width="24px" height="24px" alt="" />}
                                    </div>
                                    <div className={`${styles.AddDeviceListTitleSec}`}>
                                        <p className={`${styles.AddDeviceListTitle}`}>
                                            <Tooltip title={item?.lock_title} placement="bottom-start">{item?.lock_title}</Tooltip>
                                            {item?.status === 1 && <span className={`${styles.OpenBadge}`}>Online</span>}
                                            {!item?.status === 0 && <span className={`${styles.OfflineBadge}`}>Offline</span>}
                                        </p>
                                        <p className={`${styles.AddDeviceListTitle}`}>TT Lock</p>
                                    </div>
                                </div>
                                <div className={`${styles.ProspectCardFootSec}`}>
                                    <p className={`${styles.ProsCardDate}`}>{item?.binddate && moment.unix(item?.binddate / 1000).fromNow()}</p>
                                    <div className={selectedDeviceIds.indexOf(item?.lock_id) > -1 ? `${styles.MainReqConDropBU} FormGroup SelectRoom Active` : `${styles.MainReqConDropBU} FormGroup SelectRoom`}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Select Room</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Select Room"
                                                value={getLocationVal(item?.lock_id)}
                                                onChange={(e) => {
                                                    if (selectedDeviceIds.indexOf(item?.lock_id) === -1) {
                                                        setSelectedDeviceIds(prev => {
                                                            return prev.concat([item?.lock_id]);
                                                        });
                                                        setSelectedDevices(prev => {
                                                            return prev.concat([{ ...item, location: e.target.value }]);
                                                        });
                                                    } else {
                                                        setSelectedDevices(prev => {
                                                            return prev.map(i => {
                                                                if (i?.lock_id === item?.lock_id)
                                                                    return { ...i, location: e.target.value };
                                                                return i;
                                                            });
                                                        });
                                                    }
                                                }}
                                            >
                                                <MenuItem value={'Bedroom'}>Bedroom</MenuItem>
                                                <MenuItem value={'Bathroom'}>Bathroom</MenuItem>
                                                <MenuItem value={'Living Room'}>Living Room</MenuItem>
                                                <MenuItem value={'Bedroom Closet'}>Bedroom Closet</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <button className={`${styles.ResetRoomSelect}`} type="button" onClick={(e) => {
                                            setSelectedDeviceIds(prev => {
                                                return prev.filter(i => i !== item?.lock_id);
                                            });
                                            setSelectedDevices(prev => {
                                                return prev.filter(i => i?.lock_id !== item?.lock_id);
                                            });
                                        }}><X /></button>
                                    </div>
                                </div>
                            </div>
                        </div>);
                    })}
                </div>
                <div className={`${styles.ButtonAction}`}>
                    <Button className="CancelPopupBU" onClick={(e) => setShowAddFormNew(false)}>Cancel</Button>
                    <Button className="SubmitPopupBU" onClick={(e) => {
                        if (selectedDeviceIds.length === 0) {
                            toast.error('Please select device.');
                            return false;
                        }
                        setCuurentStep(2);
                    }}>Next</Button>
                </div>
            </div>}

            {cuurentStep === 2 && <div className={`${styles.ModalFormGroupTwo}`}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={`${styles.AddDeviceFormTwo}`}>
                        <div className={`${styles.AddDeviceFormRow}`}>
                            <p className={`${styles.AddDeviceFormTitle}`}>Location</p>
                            <div className={`${styles.OneThirdSec} FormGroup AddDevice`}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Property*</InputLabel>
                                    <Controller
                                        name="property_id"
                                        control={control}
                                        render={(field) => (
                                            <Select
                                                {...field}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Property*"
                                                onChange={(e) => { field.onChange(e); selectProperty(e); }}
                                            >
                                                {propertyList.map(i => {
                                                    return <MenuItem key={i?.value} value={i?.value}>{i?.label}</MenuItem>
                                                })}
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                                {errors?.property_id && (<p className={`${styles.ErrorM}`}>{errors?.property_id?.message}</p>)}
                            </div>
                            <div className={`${styles.OneThirdSec} FormGroup AddDevice`}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label" className={selectPropertyId ? `` : `DisabledLabel`}>Apartment*</InputLabel>
                                    <Controller
                                        name="apt_id"
                                        control={control}
                                        render={(field) => (
                                            <Select
                                                {...field}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Apartment*"
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    clearErrors();

                                                    if (e.target.value > 0) {
                                                        setIsNonApartment(false);
                                                    } else {
                                                        setIsNonApartment(true);
                                                    }
                                                }}
                                                disabled={!selectPropertyId}
                                            >
                                                {apartmentList.map(i => {
                                                    return <MenuItem key={i?.value} value={i?.value}>{i?.label}</MenuItem>
                                                })}
                                                <MenuItem value={0}>{"Other"}</MenuItem>
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                                {errors?.apt_id && (<p className={`${styles.ErrorM}`}>{errors?.apt_id?.message}</p>)}
                            </div>
                            {isNonApartment && <>
                                <div className={`${styles.OneThirdSec} FormGroup AddDevice`}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Floor*</InputLabel>
                                        <Controller
                                            name="floor_id"
                                            control={control}
                                            render={(field) => (
                                                <Select
                                                    {...field}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    label="Floor*"
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                    }}
                                                >
                                                    {floorList.map(i => {
                                                        return <MenuItem key={i} value={i}>{i}</MenuItem>
                                                    })}
                                                </Select>
                                            )}
                                        />
                                    </FormControl>
                                    {errors?.floor_id && (<p className={`${styles.ErrorM}`}>{errors?.floor_id?.message}</p>)}
                                </div>
                                <div className={`${styles.OneThirdSec} FormGroup AddDevice`}>
                                    <FormControl fullWidth>
                                        <Controller
                                            name="apt_text"
                                            control={control}
                                            render={(field) => (
                                                <TextField
                                                    {...field}
                                                    id="outlined-multiline-static"
                                                    label="Other Text*"
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </FormControl>
                                    {errors?.apt_text && (<p className={`${styles.ErrorM}`}>{errors?.apt_text?.message}</p>)}
                                </div>
                            </>}
                        </div>
                    </div>
                    <p className={`${styles.DeviceSelectedMSG}`}>{(selectedDeviceIds?.length > 0 ? (selectedDeviceIds?.length > 1 ? selectedDeviceIds?.length + ' devices selected' : selectedDeviceIds?.length + ' device selected') : 'No device selected')}</p>
                    <ul className={`${styles.SelectedDeviceList}`}>
                        {selectedDevices?.map((item, index) => {
                            return (<li key={index}>
                                {item?.type === 'Lock' && <img src="/static/img/Door.svg" width="24px" height="24px" alt="" />}
                                {item?.type === 'Gateway' && <img src="/static/img/router.svg" width="24px" height="24px" alt="" />}
                                <p>{item?.lock_title} /</p>
                                <div className={`${styles.SelectRoomStepTwo} FormGroup SelectRoom Two`}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Select Room</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Select Room"
                                            value={getLocationVal(item?.lock_id)}
                                            onChange={(e) => {
                                                if (selectedDeviceIds.indexOf(item?.lock_id) === -1) {
                                                    setSelectedDeviceIds(prev => {
                                                        return prev.concat([item?.lock_id]);
                                                    });
                                                    setSelectedDevices(prev => {
                                                        return prev.concat([item]);
                                                    });
                                                } else {
                                                    setSelectedDevices(prev => {
                                                        return prev.map(i => {
                                                            if (i?.lock_id === item?.lock_id)
                                                                return { ...i, location: e.target.value };
                                                            return i;
                                                        });
                                                    });
                                                }
                                            }}
                                        >
                                            <MenuItem value={'Bedroom'}>Bedroom</MenuItem>
                                            <MenuItem value={'Bathroom'}>Bathroom</MenuItem>
                                            <MenuItem value={'Living Room'}>Living Room</MenuItem>
                                            <MenuItem value={'Bedroom Closet'}>Bedroom Closet</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </li>)
                        })}
                    </ul>
                    <div className={`${styles.ButtonAction}`}>
                        <Button onClick={(e) => setCuurentStep(1)} className="CancelPopupBU">Back</Button>
                        <Button className="SubmitPopupBU" type="submit">Add</Button>
                    </div>
                </form>
            </div>}
        </DialogContent>
    </>);
}

export default AddLockFormNew;