import { usePubNub } from 'pubnub-react';
import { useEffect } from 'react';

const PubNubChannel = (props) => {
    const pubnub = usePubNub();
    const statusChannel = props.ownerId + props.aptId;
    const alertsChannel = props.ownerId + 'web-alerts';

    useEffect(() => {
        // Subscribe to the channel
        pubnub.subscribe({ channels: [statusChannel, alertsChannel] });

        const listener = {
            message: (msg) => {
                if (msg.message.bizCode) {
                    props.updateDeviceStatus(msg.message.deviceId, msg.message.bizCode, 'bizCode', msg.message.data);
                } else {
                    props.updateDeviceStatus(msg.message.deviceId, msg.message.status, "", msg.message.data);
                }
            }
        };

        // Add the listener
        pubnub.addListener(listener);

        // Cleanup function to unsubscribe and remove listeners when the component unmounts
        return () => {
            pubnub.unsubscribe({ channels: [statusChannel, alertsChannel] });
            pubnub.removeListener(listener);
        };
    }, [pubnub, statusChannel, alertsChannel, props]);

    return null;
};

export default PubNubChannel;
