import { Dropdown } from 'react-bootstrap';
import styles from '../propertydetails.module.css';
import { Archive, Edit, MoreVertical, Trash2 } from 'react-feather';

const PropertyCard = ({ data, setEditFormOpen, setArchiveModalOpen, setDeleteModalOpen }) => {

    return (<div className={`${styles.SMProCusSmallDiv}`}>
        <div className={`${styles.CardSmall}`}>
            <div className={`${styles.SMProCardHead}`}>
                <div className={`${styles.SMProPicMain}`}>
                    <img src={data?.new_image !== "" ? data?.new_image : "/static/img/whimage.jpg"} className={`${styles.ProfileIMG}`} alt="" />
                </div>
                <h5>{data?.name}</h5>
                <Dropdown className={`${styles.ProEditSec}`}>
                    <Dropdown.Toggle className={`${styles.EditActionsBU} ProsDrBU`} id="dropdown-basic">
                        <MoreVertical />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={`${styles.ActionsBUList} ActionsBUList`}>
                        <Dropdown.Item className={`${styles.EditActionsBUListItem}`} onClick={(e) => setEditFormOpen(true)}><Edit />Edit</Dropdown.Item>
                        <Dropdown.Item className={`${styles.EditActionsBUListItem}`} onClick={(e) => setArchiveModalOpen(true)}><Archive />{data?.is_archived === 1 ? "Unarchive" : "Archive"}</Dropdown.Item>
                        <Dropdown.Item className={`${styles.EditActionsBUListItem} ${styles.Delete}`} onClick={(e) => setDeleteModalOpen(true)}><Trash2 />Delete</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className={`${styles.SMProCardDe}`}>
                <ul>
                    <li>
                        <span className={`${styles.SMProDETag}`}>Landlord:</span>
                        <span className={`${styles.SMProDE}`}>{data?.landlord_agent}</span>
                    </li>
                    <li>
                        <span className={`${styles.SMProDETag}`}>Email:</span>
                        <span className={`${styles.SMProDE}`}>{data?.email}</span>
                    </li>
                    <li>
                        <span className={`${styles.SMProDETag}`}>Phone:</span>
                        <span className={`${styles.SMProDE}`}>{data?.phone}</span>
                    </li>
                    <li>
                        <span className={`${styles.SMProDETag}`}>Address:</span>
                        <span className={`${styles.SMProDE}`}>{data?.fulladdress}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>);
}

export default PropertyCard;