import styles from "../propertydetails.module.css";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import NumberFormat from "react-number-format";
import { IconButton, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { UploadCloud } from "react-feather";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import Loader from "../../../components/Loader";
import { toast } from "react-smart-toaster";
import { usePlacesWidget } from "react-google-autocomplete";
import { AddLogCallAPI } from "../../../components/AddLogs";
import { styled as MUIstyled } from '@mui/material/styles';

const phoneRegExp = /^\(?([0-9]{3})\)?[ ]?([0-9]{3})[-]?([0-9]{4})$/;
const schema = yup.object().shape({
  name: yup.string().required("This field is Required").max(50, "Name cannot be more than 50 characters"),
  landlord_agent: yup.string().required("This field is Required").max(50, "Landlord/Managing agent cannot be more than 50 characters"),
  email: yup.string().required("This field is Required").email("Invalid E-mail").max(50, "Email cannot be more than 50 characters"),
  phone: yup.string().required("This field is Required").matches(phoneRegExp, "Invalid phone number"),
  full_address: yup.string().required("This field is Required").max(100, "Address cannot be more than 100 characters"),
  no_of_floor: yup.string().required("This field is Required").max(3, "No. of floor can not be more than 2 digits").test("non-zero-validate", "This field should be 1 or greater.", (value) => parseInt(value) > 0)
});

function PhoneField(props) {
  return (
    <NumberFormat
      customInput={TextField}
      prefix=""
      displayType="input"
      type="tel"
      format={"(###) ###-####"}
      {...props}
    />
  );
}

function MaxTwoDigitField(props) {
  return (
    <NumberFormat
      customInput={TextField}
      prefix=""
      displayType="input"
      type="text"
      thousandSeparator={false}
      allowNegative={false}
      decimalScale={0}
      {...props}
    />
  );
}

const VisuallyHiddenInput = MUIstyled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const EditPropertyFormCom = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [tempFile, setTempFile] = useState(null);

  const { register, handleSubmit, control, errors, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const { ref: autocompleteRef } = usePlacesWidget({
    apiKey: "AIzaSyDrxtVszbIQ4U8rL90V1lDfy5HvN673fcg",
    options: {
      types: ["address"],
      componentRestrictions: { country: "us" },
      fields: ["address_components", "geometry"],
    },
    onPlaceSelected: (place) => {
      let address = "";
      let city = "";
      let state = "";
      let postcode = "";

      for (let component of place.address_components) {
        let componentType = component.types[0];
        switch (componentType) {
          case "street_number": {
            address = component.long_name;
            break;
          }
          case "route": {
            address += " " + component.short_name;
            break;
          }
          case "postal_code": {
            postcode = component.long_name;
            break;
          }
          case "locality":
            city = component.long_name;
            break;
          case "sublocality_level_1":
            city = component.long_name;
            break;
          case "administrative_area_level_3":
            city = component.long_name;
            break;
          case "administrative_area_level_1": {
            state = component.short_name;
            break;
          }
          default:
            break;
        }
      }

      setValue("full_address", address + ", " + city + ", " + state + " " + postcode);
      setValue("address", address);
      setValue("city", city);
      setValue("state", state);
      setValue("zip", postcode);
      setValue("latitude", place?.geometry?.location.lat());
      setValue("longitude", place?.geometry?.location.lng());
    },
  });

  useEffect(() => {
    setValue("name", props?.itemData?.name);
    setValue("landlord_agent", props?.itemData?.landlord_agent);
    setValue("email", props?.itemData?.email);
    setValue("phone", props?.itemData?.phone);
    setValue("address", props?.itemData?.address);
    setValue("city", props?.itemData?.city);
    setValue("state", props?.itemData?.state);
    setValue("zip", props?.itemData?.zip);
    setValue("latitude", props?.itemData?.latitude);
    setValue("longitude", props?.itemData?.longitude);
    setValue("no_of_floor", props?.itemData?.no_of_floor);
    setValue("full_address", props?.itemData?.address + ", " + props?.itemData?.city + ", " + props?.itemData?.state + " " + props?.itemData?.zip);
  }, [props?.itemData, setValue]);

  const onSubmit = async (data) => {
    setLoading(true);
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

    if (data?.full_address) {
      delete data?.full_address;
    }

    let formData = new FormData();

    Object.keys(data).map((key) => {
      formData.append(key, data[key]);

      return true;
    });

    if (tempFile) {
      formData.append('uploadImage', tempFile);
    }

    return axios.post(process.env.REACT_APP_PROPERTY_API_URL + "v1/properties/" + props?.itemData?.id, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      setLoading(false);
      let rData = response.data.data;
      props?.onSubmit(response.data);
      props.setEditFormOpen(false)
      let logData = {
        title: "Property is updated",
        description: ["Name: " + rData.name, "Address: " + rData.fulladdress],
      };
      AddLogCallAPI(logData, token);
    }).catch((error) => {
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
      setLoading(false);
    });
  };

  const fileHandler = (e) => {
    var validExtensions = ['jpg', 'jpeg', 'png'];
    var fileName = e.target.files[0].name;
    var fileNameExt = fileName.substr(fileName.lastIndexOf('.') + 1);

    if (validExtensions.indexOf(fileNameExt.toLowerCase()) === -1) {
      toast.error('File type is not allowed');

    } else if (e.target.files[0].size > 5242880) {
      toast.error('Image size should not be greater than 5 mb.');

    } else {
      setTempFile(e.target.files[0]);
    }
  }

  return (<>
    {loading && <Loader />}
    <form onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" name="address" ref={register} />
      <input type="hidden" name="city" ref={register} />
      <input type="hidden" name="state" ref={register} />
      <input type="hidden" name="zip" ref={register} />
      <input type="hidden" name="latitude" ref={register} />
      <input type="hidden" name="longitude" ref={register} />

      <div className={`${styles.ProfileIMGSec}`}>
        <div className={`${styles.ProfileIMGCard}`}>
          <div className={`${styles.ProfileIMGMobile}`}>
            <img src={tempFile ? URL.createObjectURL(tempFile) : (props?.itemData?.new_image !== "" ? props?.itemData?.new_image : "/static/img/whimage.jpg")} className={`${styles.roundedcircle}`} alt="" />
          </div>
          <IconButton component="label" className={`${styles.ProfileIMGUploadBUMobile}`}>
            <UploadCloud />
            <VisuallyHiddenInput type="file" onChange={fileHandler} accept=".jpg,.png,.jpeg" />
          </IconButton>
        </div>
      </div>

      <div className={`${styles.ProfileEditFormSecMobile}`}>
        <div className={`${styles.EditFormHalfSec} FormGroup`}>
          <Controller
            name={`name`}
            control={control}
            render={(field) => (
              <TextField
                onChange={(e) => field.onChange(e)}
                id="outlined-basic"
                label="Property Name*"
                value={field.value}
                error={errors?.name}
              />
            )}
          />
          {errors?.name && <p className={`${styles.ErrorM}`}>{errors?.name?.message}</p>}
        </div>
        <div className={`${styles.EditFormHalfSec} FormGroup`}>
          <Controller
            name={`landlord_agent`}
            control={control}
            render={(field) => (
              <TextField
                onChange={(e) => field.onChange(e)}
                id="outlined-basic"
                label="Landlord/Managing agent*"
                value={field.value}
                error={errors?.landlord_agent}
              />
            )}
          />
          {errors?.landlord_agent && <p className={`${styles.ErrorM}`}>{errors?.landlord_agent?.message}</p>}
        </div>
        <div className={`${styles.EditFormHalfSec} FormGroup`}>
          <Controller
            name={`email`}
            control={control}
            render={(field) => (
              <TextField
                onChange={(e) => field.onChange(e)}
                id="outlined-basic"
                label="Email*"
                value={field.value}
                error={errors?.email}
              />
            )}
          />
          {errors?.email && <p className={`${styles.ErrorM}`}>{errors?.email?.message}</p>}
        </div>
        <div className={`${styles.EditFormHalfSec} FormGroup`}>
          <Controller
            name={`phone`}
            control={control}
            render={(field) => (
              <PhoneField
                onChange={(e) => field.onChange(e)}
                id="outlined-basic"
                label="Phone*"
                value={field.value}
                error={errors?.phone}
              />
            )}
          />
          {errors?.phone && <p className={`${styles.ErrorM}`}>{errors?.phone?.message}</p>}
        </div>
        <div className={`${styles.EditFormOneTSec} FormGroup`}>
          <Controller
            name={`full_address`}
            control={control}
            render={(field) => (
              <TextField
                inputRef={autocompleteRef}
                placeholder=""
                onChange={(e) => field.onChange(e)}
                id="outlined-basic"
                label="Full Address*"
                value={field.value}
                error={errors?.full_address}
              />
            )}
          />
          {errors?.full_address && <p className={`${styles.ErrorM}`}>{errors?.full_address?.message}</p>}
        </div>
        <div className={`${styles.EditFormOneTSecSm} FormGroup`}>
          <Controller
            name={`no_of_floor`}
            control={control}
            render={(field) => (
              <MaxTwoDigitField
                format="##"
                onChange={(e) => field.onChange(e)}
                id="outlined-basic"
                label="No. of floors*"
                value={field.value}
                error={errors?.no_of_floor}
              />
            )}
          />
          {errors?.no_of_floor && <p className={`${styles.ErrorM}`}>{errors?.no_of_floor?.message}</p>}
        </div>
      </div>
      <div className={`${styles.ButtonSec}`}>
        <button className={`${styles.CancelBU}`} onClick={(e) => props.setEditFormOpen(false)}>Cancel</button>
        <button className={`${styles.AddBU}`} type="submit">Save</button>
      </div>
    </form>
  </>);
};

export default EditPropertyFormCom;
