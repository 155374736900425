import React, { useState, useEffect } from "react";
import styles from "../../propertydetails.module.css";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Lock, DollarSign } from "react-feather";
import { Tooltip, Switch } from "@mui/material";
import axios from "axios";
import { toast } from "react-smart-toaster";
import { useAuth0 } from "@auth0/auth0-react";
import NumberFormat from "react-number-format";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Loader from "../../../../components/Loader";
import { AddLogCallAPI } from "../../../../components/AddLogs";

function MoneyField(props) {
    return (
        <NumberFormat
            prefix=""
            displayType="input"
            type="text"
            thousandSeparator={true}
            allowNegative={false}
            decimalScale={2}
            {...props}
        />
    );
}

const schemaRentalApplicant = yup.object().shape({
    application_amount: yup.string().required("This field is Required").max(5, "Application fee can not be more than 4 digits").test(
        "non-zero-validate", "This field should be $1 or greater.", (value) => parseInt(value) > 0)
})

const RentalApplicationTab = (props) => {
    const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
    const [loading, setLoading] = useState(false);
    const [rentalApplicationPublicUrl, setRentalApplicationPublicUrl] = useState("");

    const { register, setValue, errors, control, handleSubmit } = useForm({
        resolver: yupResolver(schemaRentalApplicant),
        shouldUnregister: false,
    });

    useEffect(() => {
        setRentalApplicationPublicUrl(process.env.REACT_APP_BASE_URL + "/rental-application/" + props?.url_token);
    }, [props?.url_token]);

    useEffect(() => {
        if (props?.rental_application) {
            if (props?.rental_application?.application_amount) {
                setValue("application_amount", props?.rental_application?.application_amount);
            }
        }
    }, [props?.rental_application, setValue]);

    const onSubmit = async (data) => {
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
        setLoading(true);
        data = { ...data, applcation_fee: true };

        return axios.post(process.env.REACT_APP_PROPERTY_API_URL + "v1/properties/" + props?.property_id + "/update-rental-application", { rental_application: data }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            setLoading(false);

            props.setSnackbarMsg(response.data.message);
            props.setSnackbarOpen(true);

            props.setPropertyData(response.data.data);

            let logData = {
                title: "Rental application is updated",
                description: ["Property: " + props?.property_name],
            };
            AddLogCallAPI(logData, token);
        }).catch((error) => {
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
            setLoading(false);
        });
    }

    return (<>
        {loading && <Loader />}
        <div className={`${styles.RentalAppliSec}`}>
            <div className={`${styles.ShareAppliSec}`}>
                <p className={`${styles.ShareAppliTitle}`}>Share Application</p>
                <div className={`${styles.ShareAppliCopySec}`}>
                    <p className={`${styles.CopyText}`}>{rentalApplicationPublicUrl}</p>
                    <CopyToClipboard text={rentalApplicationPublicUrl}>
                        <Tooltip title="Copy">
                            <button className={`${styles.CopyBU}`}>
                                <img src="/static/img/copy.png" alt="" />
                            </button>
                        </Tooltip>
                    </CopyToClipboard>
                </div>
            </div>

            <div className={`${styles.ShareAppliQuestionSec}`}>
                <form id="myForm2" onSubmit={handleSubmit(onSubmit)}>
                    <p className={`${styles.QuestionSecTText}`}>Below are the default questions that are within your rental application for this property. You can toggle each question on/off to update your application preferences.</p>
                    <ul className={`${styles.QuestionListSec}`}>
                        <li>
                            <p className={`${styles.QuestionNo}`}>Question 1</p>
                            <div className={`${styles.QuestionSec}`}>
                                <p className={`${styles.Question}`}>Desired move-in date</p>
                                <Switch
                                    name="move_in_date"
                                    inputRef={register}
                                    defaultChecked={props.rental_application.move_in_date === true}
                                    disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("property:update") > -1)}
                                />
                            </div>
                        </li>
                        <li>
                            <p className={`${styles.QuestionNo}`}>Question 2A</p>
                            <div className={`${styles.QuestionSec}`}>
                                <p className={`${styles.Question}`}>First name</p>
                                <div className={`${styles.DisabledSec}`}>
                                    <Lock />
                                    <Switch
                                        name="first_name"
                                        inputRef={register}
                                        disabled
                                        defaultChecked
                                    />
                                </div>
                            </div>
                        </li>
                        <li>
                            <p className={`${styles.QuestionNo}`}>Question 2B</p>
                            <div className={`${styles.QuestionSec}`}>
                                <p className={`${styles.Question}`}>Middle name</p>
                                <Switch
                                    name="middle_name"
                                    inputRef={register}
                                    defaultChecked={props.rental_application.middle_name === true}
                                    disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("property:update") > -1)}
                                />
                            </div>
                        </li>
                        <li>
                            <p className={`${styles.QuestionNo}`}>Question 2C</p>
                            <div className={`${styles.QuestionSec}`}>
                                <p className={`${styles.Question}`}>Last name</p>
                                <div className={`${styles.DisabledSec}`}>
                                    <Lock />
                                    <Switch
                                        name="last_name"
                                        inputRef={register}
                                        disabled
                                        defaultChecked
                                    />
                                </div>
                            </div>
                        </li>
                        <li>
                            <p className={`${styles.QuestionNo}`}>Question 3</p>
                            <div className={`${styles.QuestionSec}`}>
                                <p className={`${styles.Question}`}>Birth date</p>
                                <div className={`${styles.DisabledSec}`}>
                                    <Lock />
                                    <Switch
                                        name="birth_date"
                                        inputRef={register}
                                        disabled
                                        defaultChecked
                                    />
                                </div>
                            </div>
                        </li>
                        <li>
                            <p className={`${styles.QuestionNo}`}>Question 4</p>
                            <div className={`${styles.QuestionSec}`}>
                                <p className={`${styles.Question}`}>Driver’s license or ID</p>
                                <div className={`${styles.DisabledSec}`}>
                                    <Lock />
                                    <Switch
                                        name="driver_license"
                                        inputRef={register}
                                        disabled
                                        defaultChecked
                                    />
                                </div>
                            </div>
                        </li>
                        <li>
                            <p className={`${styles.QuestionNo}`}>Question 5</p>
                            <div className={`${styles.QuestionSec}`}>
                                <p className={`${styles.Question}`}>Email address</p>
                                <div className={`${styles.DisabledSec}`}>
                                    <Lock />
                                    <Switch
                                        name="email"
                                        inputRef={register}
                                        disabled
                                        defaultChecked
                                    />
                                </div>
                            </div>
                        </li>
                        <li>
                            <p className={`${styles.QuestionNo}`}>Question 6</p>
                            <div className={`${styles.QuestionSec}`}>
                                <p className={`${styles.Question}`}>Phone number</p>
                                <div className={`${styles.DisabledSec}`}>
                                    <Lock />
                                    <Switch
                                        name="phone"
                                        inputRef={register}
                                        disabled
                                        defaultChecked
                                    />
                                </div>
                            </div>
                        </li>
                        <li>
                            <p className={`${styles.QuestionNo}`}>Question 7</p>
                            <div className={`${styles.QuestionSec}`}>
                                <p className={`${styles.Question}`}>Current address</p>
                                <div className={`${styles.DisabledSec}`}>
                                    <Lock />
                                    <Switch
                                        name="address"
                                        inputRef={register}
                                        disabled
                                        defaultChecked
                                    />
                                </div>
                            </div>
                        </li>
                        <li>
                            <p className={`${styles.QuestionNo}`}>Question 8</p>
                            <div className={`${styles.QuestionSec}`}>
                                <p className={`${styles.Question}`}>Name of present landlord</p>
                                <Switch
                                    name="landlord_name"
                                    inputRef={register}
                                    defaultChecked={props.rental_application.landlord_name === true}
                                    disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("property:update") > -1)}
                                />
                            </div>
                        </li>
                        <li>
                            <p className={`${styles.QuestionNo}`}>Question 9A</p>
                            <div className={`${styles.QuestionSec}`}>
                                <p className={`${styles.Question}`}>Phone number of present landlord</p>
                                <Switch
                                    name="landlord_phone_no"
                                    inputRef={register}
                                    defaultChecked={props.rental_application.landlord_phone_no === true}
                                    disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("property:update") > -1)}
                                />
                            </div>
                        </li>
                        <li>
                            <p className={`${styles.QuestionNo}`}>Question 9B</p>
                            <div className={`${styles.QuestionSec}`}>
                                <p className={`${styles.Question}`}>Reason for moving</p>
                                <Switch
                                    name="reason_for_moving"
                                    inputRef={register}
                                    defaultChecked={props.rental_application.reason_for_moving === true}
                                    disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("property:update") > -1)}
                                />
                            </div>
                        </li>
                        <li>
                            <p className={`${styles.QuestionNo}`}>Question 10</p>
                            <div className={`${styles.QuestionSec}`}>
                                <p className={`${styles.Question}`}>Who else will be living with you? Please provide their first name, last name, birth date and relationship to you</p>
                                <Switch
                                    name="who_live_with_you"
                                    inputRef={register}
                                    defaultChecked={props.rental_application.who_live_with_you === true}
                                    disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("property:update") > -1)}
                                />
                            </div>
                        </li>
                        <li>
                            <p className={`${styles.QuestionNo}`}>Question 11</p>
                            <div className={`${styles.QuestionSec}`}>
                                <p className={`${styles.Question}`}>How many months have you lived at present address?</p>
                                <Switch
                                    name="lived_current_addrs"
                                    inputRef={register}
                                    defaultChecked={props.rental_application.lived_current_addrs === true}
                                    disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("property:update") > -1)}
                                />
                            </div>
                        </li>
                        <li>
                            <p className={`${styles.QuestionNo}`}>Question 12</p>
                            <div className={`${styles.QuestionSec}`}>
                                <p className={`${styles.Question}`}>Current rent per month</p>
                                <Switch
                                    name="current_rent"
                                    inputRef={register}
                                    defaultChecked={props.rental_application.current_rent === true}
                                    disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("property:update") > -1)}
                                />
                            </div>
                        </li>
                        <li>
                            <p className={`${styles.QuestionNo}`}>Question 13</p>
                            <div className={`${styles.QuestionSec}`}>
                                <p className={`${styles.Question}`}>Employment information</p>
                                <Switch
                                    name="employer_information"
                                    inputRef={register}
                                    defaultChecked={props.rental_application.employer_information === true}
                                    disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("property:update") > -1)}
                                />
                            </div>
                        </li>
                        <li>
                            <p className={`${styles.QuestionNo}`}>Question 14</p>
                            <div className={`${styles.QuestionSec}`}>
                                <p className={`${styles.Question}`}>How many evictions have been filled upon you?</p>
                                <Switch
                                    name="ever_evicted"
                                    inputRef={register}
                                    defaultChecked={props.rental_application.ever_evicted === true}
                                    disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("property:update") > -1)}
                                />
                            </div>
                        </li>
                        <li>
                            <p className={`${styles.QuestionNo}`}>Question 15</p>
                            <div className={`${styles.QuestionSec}`}>
                                <p className={`${styles.Question}`}>How many court cases have been flled against you in housing court?</p>
                                <Switch
                                    name="ever_case_filed"
                                    inputRef={register}
                                    defaultChecked={props.rental_application.ever_case_filed === true}
                                    disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("property:update") > -1)}
                                />
                            </div>
                        </li>
                        <li>
                            <p className={`${styles.QuestionNo}`}>Question 16</p>
                            <div className={`${styles.QuestionSec}`}>
                                <p className={`${styles.Question}`}>Have you ever broken a lease?</p>
                                <Switch
                                    name="ever_broken_lease"
                                    inputRef={register}
                                    defaultChecked={props.rental_application.ever_broken_lease === true}
                                    disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("property:update") > -1)}
                                />
                            </div>
                        </li>
                        <li>
                            <p className={`${styles.QuestionNo}`}>Question 17</p>
                            <div className={`${styles.QuestionSec}`}>
                                <p className={`${styles.Question}`}>What is your credit score?</p>
                                <div className={`${styles.DisabledSec}`}>
                                    <Lock />
                                    <Switch
                                        name="credit_score"
                                        inputRef={register}
                                        disabled
                                        defaultChecked
                                    />
                                </div>
                            </div>
                        </li>
                        <li>
                            <p className={`${styles.QuestionNo}`}>Question 18</p>
                            <div className={`${styles.QuestionSec}`}>
                                <p className={`${styles.Question}`}>How many pets do you have?</p>
                                <Switch
                                    name="have_pets"
                                    inputRef={register}
                                    defaultChecked={props.rental_application.have_pets === true}
                                    disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("property:update") > -1)}
                                />
                            </div>
                        </li>
                        <li>
                            <p className={`${styles.QuestionNo}`}>Question 19</p>
                            <div className={`${styles.QuestionSec}`}>
                                <p className={`${styles.Question}`}>Have you ever had bed bugs?</p>
                                <Switch
                                    name="ever_had_bedbugs"
                                    inputRef={register}
                                    defaultChecked={props.rental_application.ever_had_bedbugs === true}
                                    disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("property:update") > -1)}
                                />
                            </div>
                        </li>
                        <li>
                            <p className={`${styles.QuestionNo}`}>Question 20</p>
                            <div className={`${styles.QuestionSec}`}>
                                <p className={`${styles.Question}`}>Have you ever filed for bankruptcy?</p>
                                <Switch
                                    name="ever_had_Bankruptcy"
                                    inputRef={register}
                                    defaultChecked={props.rental_application.ever_had_Bankruptcy === true}
                                    disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("property:update") > -1)}
                                />
                            </div>
                        </li>
                        <li>
                            <p className={`${styles.QuestionNo}`}>Question 21</p>
                            <div className={`${styles.QuestionSec}`}>
                                <p className={`${styles.Question}`}>Do you smoke?</p>
                                <Switch
                                    name="do_you_smoke"
                                    inputRef={register}
                                    defaultChecked={props.rental_application.do_you_smoke === true}
                                    disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("property:update") > -1)}
                                />
                            </div>
                        </li>
                        <li>
                            <p className={`${styles.QuestionNo}`}>Question 22</p>
                            <div className={`${styles.QuestionSec}`}>
                                <p className={`${styles.Question}`}>How many vehicles do you own?</p>
                                <Switch
                                    name="vechicles_you_own"
                                    inputRef={register}
                                    defaultChecked={props.rental_application.vechicles_you_own === true}
                                    disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("property:update") > -1)}
                                />
                            </div>
                        </li>

                        <li>
                            <p className={`${styles.QuestionNo}`}>Question 23</p>
                            <div className={`${styles.QuestionSec}`}>
                                <p className={`${styles.Question}`}>Social Security Number</p>
                                <Switch
                                    name="social_security_number"
                                    inputRef={register}
                                    defaultChecked={props.rental_application.social_security_number === true}
                                    disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("property:update") > -1)}
                                />
                            </div>
                        </li>
                        <li>
                            <div className={`${styles.QuestionSec}`}>
                                <p className={`${styles.Question}`}>Application Fee</p>
                                <div className={`${styles.FormGroupHalf} ${styles.Dollar}`}>
                                    <div className={`${styles.Width100}`}>
                                        <p className={`${styles.AccoDollarSign}`}><DollarSign /></p>
                                        <Controller
                                            name="application_amount"
                                            as={<MoneyField />}
                                            control={control}
                                            inputref={register}
                                            className={`${styles.formControl}`}
                                            placeholder="00.00"
                                        />
                                    </div>
                                </div>
                                {errors?.application_amount && (<p className={`${styles.ErrorM}`}>{errors?.application_amount.message}</p>)}
                            </div>
                        </li>
                    </ul>
                    <div className={`${styles.ButtonDiv}`}>
                        <button type="submit" className={`${styles.ButtonVoiletSolid}`}>Apply</button>
                    </div>
                </form>
            </div>
        </div>
    </>);
}


export default RentalApplicationTab;