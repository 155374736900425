import {
  Typography,
  Box,
  Grid,
  Divider as MuiDivider,
  Fab,
} from "@material-ui/core";
import { ChevronLeft, ChevronUp, Send, X } from "react-feather";
import styled from "styled-components/macro";
import styles from "../communication-chat.module.css";
import TextareaAutosize from "react-textarea-autosize";
const ChatInputsTwo = styled(Grid)`
  min-height: 40px;
  padding: 16px;
`;
const ChatInputBox = (props) => {
  return (
    <>
      <div>
        <ChatInputsTwo>
          <div className={`${styles.ChatInputSec}`}>
            <Grid item style={{ flexGrow: 1 }}>
              <TextareaAutosize
                minRows={1}
                maxRows={3}
                placeholder="Type your message"
                value={props?.messageTextVal}
                onChange={props?.handleChange}
              />
            </Grid>
            <button
              onClick={props.sendMessage}
              className={`${styles.SendBUMSG}`}
            >
              <Send />
            </button>
          </div>
        </ChatInputsTwo>
      </div>
    </>
  );
};

export default ChatInputBox;
