import { useEffect, useState } from "react";
import styles from "../dashboard.module.css";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";

const SmartDeviceAlerts = ({ totalData, data }) => {
  const [alertList, setAlertList] = useState([]);

  useEffect(() => {
    setAlertList(data);
  }, [data]);

  return (<div className={`${styles.CaseDiv} ${styles.CardSmartDe}`}>
    <div className={`${styles.IfnoCard}`}>
      <div className={`${styles.InsideCard}`}>
        <div className={`${styles.HeadFlex}`}>
          <h6 className={`${styles.InsideCardTitle2}`}>Device Alerts {totalData > 0 && <span>{totalData} Open</span>}</h6>
          <Link to="/smart-devices/alerts" className={`${styles.ViewAllBU}`}>View All</Link>
        </div>
        <div className={`${styles.ScrollCard}`}>
          {alertList?.map((item) => {
            return (<Link to={"/smart-devices/details/" + item?.device_id} key={item?.id}>
              <div className={`${styles.CaseCard}`}>
                {item?.title === "Water Leak" && (<div className={`${styles.DeviceAlertIcon} ${styles.Blue}`}>
                  <img src="/static/img/water-dash.svg" width="24px" height="24px" alt="" />
                </div>)}
                {item?.title === "Low Battery" && (<div className={`${styles.DeviceAlertIcon} ${styles.Orange}`}>
                  <img src="/static/img/battery-dash.svg" width="24px" height="24px" alt="" />
                </div>)}
                {item?.title === "Device Offline" && (<div className={`${styles.DeviceAlertIcon} ${styles.Red}`}>
                  <img src="/static/img/wifi-dash.svg" width="24px" height="24px" alt="" />
                </div>)}
                {(item?.title?.toLowerCase().includes('door unlocked') || item?.title?.toLowerCase().includes('door is unlocked') || item?.title?.toLowerCase().includes('door was unlocked')) && (<div className={`${styles.DeviceAlertIcon} ${styles.Green}`}>
                  <img src="/static/img/lock-dash.svg" width="24px" height="24px" alt="" />
                </div>)}
                <div className={`${styles.DeviceAlertTT}`}>
                  <div className={`${styles.CaseCardTitle}`}>
                    <Tooltip title={item?.title} placement="bottom-start">
                      <p className={`${styles.TitleText} ${styles.DeviceTextWrap}`}>{item?.title}</p>
                    </Tooltip>
                    <p className={`${styles.DateText}`}>{item?.time_str}</p>
                  </div>
                  <p className={`${styles.TitleName}`}>{item?.apt_no ? item?.apt_no : item?.apt_text}, {item?.product_type}</p>
                </div>
              </div>
            </Link>);
          })}
          {alertList?.length === 0 && (<div className={`${styles.NoDataSec}`}>
            <div className={`${styles.NoDataIMG}`}><img src="/static/img/no-device.png" alt="" /></div>
            <p className={`${styles.NoDataText}`}>Don't Worry!</p>
            <p className={`${styles.NoDataTextSub}`}>Device Alerts is empty.</p>
          </div>)}
        </div>
      </div>
    </div>
  </div>);
};

export default SmartDeviceAlerts;