import { ChevronDown } from "react-feather";
import styles from "../communication-chat.module.css";
import { useState } from "react";

const ProspectList = (props) => {
  const [accordionOn, setaccordionOn] = useState(false);


  // Function to format phone number
  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return cleaned;
  };

  return (
    <>
      <div
        onClick={() => props.toggleAccordionForProspect()}
        className={
          props.accordionOnForProspect
            ? `${styles.accordionOn} ${styles.Open}`
            : `${styles.accordionOn}`
        }
      >
        <p className={`${styles.ListAccoudionTitle}`}>
          <ChevronDown /> Prospects ({props.prospectList.length} members)
        </p>
        {props.prospectList.length > 0 &&
          props.prospectList.map((prospect, i) => {
            return (
              <div key={i}>
                <div
                  className={
                    prospect.id === props?.prospectTenantDetails?.id
                      ? `${styles.activeTenant} ${styles.CommuListSec}`
                      : `${styles.CommuListSec}`
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    props.setDetails(prospect);
                  }}
                >
                  <div className={`${styles.CommuListDeSec}`}>
                    <p className={`${styles.ProsTenantList}`}>
                     { prospect?.first_name && <span>
                        {prospect?.first_name}&nbsp;{prospect?.last_name}
                      </span>}
                      { !prospect?.first_name && <span>
                        { formatPhoneNumber(prospect?.phone)}
                      </span>}
                     { prospect?.current_status === 0 &&  <span
                        className={`${styles.StatusBadge} ${styles.NotApplied}`}
                      >
                        Not Applied
                      </span> }
                      { prospect?.current_status === 1 && <span
                        className={`${styles.StatusBadge} ${styles.Applied}`}
                      >
                        Applied
                      </span> }
                      { prospect?.current_status === 2 && <span
                        className={`${styles.StatusBadge} ${styles.Screened}`}
                      >
                        Screened
                      </span> }
                      { prospect?.current_status === 3 && <span
                        className={`${styles.StatusBadge} ${styles.Approved}`}
                      >
                        Approved
                      </span> }
                      { prospect?.current_status === 4 && <span
                        className={`${styles.StatusBadge} ${styles.Declined}`}
                      >
                        Declined
                      </span> }
                    </p>
                    <p className={`${styles.ProsTenantListDe}`}>
                      <span>{prospect?.property_name}</span>
                      {prospect?.current_status !== 0 &&<span>{prospect?.apt_no}</span>}
                    </p>
                  </div>
                </div>

                {/* <p
                  className={
                    prospect.id === props?.prospectTenantDetails?.id
                      ? `${styles.activeTenant} ${styles.ProsTenantList}`
                      : `${styles.ProsTenantList}`
                  }
                >
                  {prospect?.first_name && (
                    <span className={`${styles.Name}`}>
                      {prospect?.first_name}&nbsp;{prospect?.last_name}
                    </span>
                  )}{" "}
                  <span className={`${styles.Phone}`}>
                    {formatPhoneNumber(prospect?.phone)}
                  </span>
                </p> */}
              </div>
            );
          })}
        {props.prospectList.length === 0 && (
          <div className={`${styles.NoDataSecOnlyText}`}>
            <img src="/static/img/chatIcon.png" alt=""/>
            <p>No prospect here!</p>
          </div>
        )}
      </div>
    </>
  );
};

export default ProspectList;
