import { Typography, Box, AppBar, Tabs, Tab } from "@mui/material";
import { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import FeedbackTab from "./Tabs/FeedbackTab";
import HistoryTab from './Tabs/HistoryTab';
import CommentsTab from './Tabs/CommentsTab';
import SummaryTab from './Tabs/SummaryTab';
import AppointmentList from './Tabs/WorkOrderTab/Appointment/AppointmentList';
import LaborList from "./Tabs/WorkOrderTab/Labor/LaborList";
import MaterialList from "./Tabs/WorkOrderTab/Material/MaterialList";
import VehicleList from "./Tabs/WorkOrderTab/Vehicle/VehicleList";
import ContractorList from "./Tabs/WorkOrderTab/Contractor/ContractorList";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

const SimpleTabs = ({ requestData, setRequestData, appointments, labors, materials, contractors, vehicles, notes, histories, feedbacks, setSnackbarOpen, setSnackbarMsg, setLoading }) => {
    const classes = useStyles();
    const [tabValue, setTabValue] = useState(0);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (<div className={classes.root}>
        <AppBar position="static">
            <Tabs value={tabValue} onChange={handleChange} aria-label="simple tabs example" variant="scrollable" scrollButtons="auto">
                <Tab label="Summary" {...a11yProps(0)} />
                <Tab label="Work Order" {...a11yProps(1)} />
                <Tab label="Comments" {...a11yProps(2)} />
                <Tab label="History" {...a11yProps(3)} />
                <Tab label="Feedback" {...a11yProps(4)} />
            </Tabs>
        </AppBar>

        <TabPanel value={tabValue} index={0}>
            <SummaryTab requestData={requestData} setRequestData={setRequestData.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} setLoading={setLoading.bind(this)} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
            <AppointmentList requestData={requestData} appointments={appointments} setRequestData={setRequestData.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} setLoading={setLoading.bind(this)} />

            <ContractorList requestData={requestData} contractors={contractors} setRequestData={setRequestData.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} setLoading={setLoading.bind(this)} />

            <LaborList requestData={requestData} labors={labors} setRequestData={setRequestData.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} setLoading={setLoading.bind(this)} />

            <MaterialList requestData={requestData} materials={materials} setRequestData={setRequestData.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} setLoading={setLoading.bind(this)} />

            <VehicleList requestData={requestData} vehicles={vehicles} setRequestData={setRequestData.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} setLoading={setLoading.bind(this)} />

        </TabPanel>
        <TabPanel value={tabValue} index={2}>
            <CommentsTab notes={notes} requestData={requestData} setRequestData={setRequestData.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} setLoading={setLoading.bind(this)} />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
            <HistoryTab histories={histories} />
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
            <FeedbackTab feedbacks={feedbacks} />
        </TabPanel>
    </div>);
}

export default SimpleTabs;