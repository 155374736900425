import { useEffect, useState } from "react";
import styles from "../dashboard.module.css";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";

const RentPayments = ({ data }) => {
    const [paymentList, setpaymentList] = useState([]);

    useEffect(() => {
        setpaymentList(data);
    }, [data]);

    return (<div className={`${styles.CardDivTwo} ${styles.CardRent} ${styles.CardHalf}`}>
        <div className={`${styles.IfnoCard}`}>
            <div className={`${styles.InsideCard}`}>
                <div className={`${styles.HeadFlex}`}>
                    <h6 className={`${styles.InsideCardTitle2}`}>Rent Payments</h6>
                    <Link to="/payments" className={`${styles.ViewAllBU}`}>View All</Link>
                </div>
                <div className={`${styles.ScrollCard}`}>
                    {paymentList?.map((item) => {
                        return (<Link to={"/payment/rent-roll/" + item?.rent_roll_id + "/details"} className={`${styles.CaseCard}`} key={item?.id}>
                            <div className={`${styles.CaseCardTitle} ${styles.RentRoll}`}>
                                <div className={`${styles.RentCardNSec}`}>
                                    <p className={`${styles.TitleText}`}>
                                        <Tooltip title={item?.tenant_name} placement="bottom-start">
                                            <span className={`${styles.NoWrapTwo}`}>{item?.tenant_name}</span>
                                        </Tooltip>
                                    </p>
                                    <p className={`${styles.TitleName}`}>{item?.apt_name}</p>
                                </div>
                                <div className={`${styles.RentCardPSec}`}>
                                    <Tooltip title={item?.payment_text} placement="bottom-start">
                                        <p className={item?.payment_status === 0 ? `${styles.PaymentText} ${styles.Yellow}` : item?.payment_status === 2 ? `${styles.PaymentText} ${styles.Red}` : `${styles.PaymentText} ${styles.Green}`}>{item?.payment_text}</p>
                                    </Tooltip>
                                    <p className={`${styles.TitleName}`}>Balance: ${item?.formatted_balance}</p>
                                </div>
                                <div className={`${styles.RentCardTSec}`}>
                                    <p className={`${styles.DateText}`}>{item?.submitted_date}</p>
                                </div>
                            </div>
                        </Link>);
                    })}

                    {paymentList?.length === 0 && (<div className={`${styles.NoDataSec}`}>
                        <div className={`${styles.NoDataIMG}`}><img src="/static/img/no-payment.png" alt="" /></div>
                        <p className={`${styles.NoDataText}`}>Let's get started!</p>
                        <p className={`${styles.NoDataTextSub}`}>Payments History is Empty.</p>
                    </div>)}
                </div>
            </div>
        </div>
    </div>);

}

export default RentPayments;