import { Check, ChevronDown } from 'react-feather';
import styles from '../rentalappplication.module.css';
import { Accordion, AccordionDetails, AccordionSummary, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import axios from 'axios';

const TenDigitField = React.forwardRef((props, ref) => {
    return <NumberFormat
        customInput={TextField}
        prefix=""
        displayType="input"
        type="text"
        thousandSeparator={false}
        decimalScale={0}
        {...props}
    />;
});

const OtherInfoAccordion = ({ expanded, stepData, setStepData, accordinChange, lastStep, setLastStep, setCurrentStep, getNextStep, setPanelExpanded, panelRef, applicantId }) => {
    const [charsLeft, setCharsLeft] = useState({ additional_information: 200 });

    const { handleSubmit, control, setValue, formState: { errors } } = useForm({
        mode: "onChange",
        defaultValues: {
            ever_evicted: '',
            ever_case_filed: '',
            ever_broken_lease: '',
            credit_score: '',
            have_pets: '',
            ever_had_bedbugs: '',
            ever_had_bankruptcy: '',
            do_you_smoke: '',
            own_vehicles: '',
            additional_information: '',
        }
    });

    useEffect(() => {
        if (stepData) {
            if (stepData?.ever_evicted) {
                setValue('ever_evicted', stepData?.ever_evicted);
            }
            if (stepData?.ever_case_filed) {
                setValue('ever_case_filed', stepData?.ever_case_filed);
            }
            if (stepData?.ever_broken_lease) {
                setValue('ever_broken_lease', stepData?.ever_broken_lease);
            }
            if (stepData?.credit_score) {
                setValue('credit_score', stepData?.credit_score);
            }
            if (stepData?.have_pets) {
                setValue('have_pets', stepData?.have_pets);
            }
            if (stepData?.ever_had_bedbugs) {
                setValue('ever_had_bedbugs', stepData?.ever_had_bedbugs);
            }
            if (stepData?.ever_had_bankruptcy) {
                setValue('ever_had_bankruptcy', stepData?.ever_had_bankruptcy);
            }
            if (stepData?.do_you_smoke) {
                setValue('do_you_smoke', stepData?.do_you_smoke);
            }
            if (stepData?.own_vehicles) {
                setValue('own_vehicles', stepData?.own_vehicles);
            }
            if (stepData?.additional_information) {
                setValue('additional_information', stepData?.additional_information);
            }
        }
    }, [stepData, setValue]);

    const onSubmit = (data) => {
        let postData = { ...data, is_other_info_submitted: 1 };
        setStepData(prev => {
            return { ...prev, ...postData };
        });

        if (applicantId) {
            axios.post(process.env.REACT_APP_APPLICANT_API_URL + 'v1/save-applicants/' + applicantId, postData);
        }

        let currentStepTemp = getNextStep(6);
        setCurrentStep(currentStepTemp);
        setPanelExpanded('panel' + currentStepTemp);
        if (lastStep < currentStepTemp) {
            setLastStep(currentStepTemp);
        }
    }

    return (<Accordion expanded={expanded === 'panel6'} onChange={accordinChange('panel6', 6)} disabled={lastStep < 6}>
        <AccordionSummary expandIcon={<ChevronDown />} aria-controls="panel1bh-content" id="panel1bh-header" ref={panelRef}>
            <div className={`${styles.FormAccoHead}`}>
                <p className={`${styles.FormAccoHeadTitle}`}>Other information</p>
                <div className={((lastStep > 6) ? `${styles.AccoCheck} ${styles.Active}` : `${styles.AccoCheck}`)}><Check /></div>
            </div>
        </AccordionSummary>
        <AccordionDetails className={`${styles.CussAccoRow}`}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={`${styles.AccoFormSec}`}>

                    <div className={`${styles.FormGroupHalf} FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>Evictions<span>*</span></label>
                        <p className={`${styles.FormSubLabel}`}>How many evictions have been filed upon you?</p>
                        <FormControl fullWidth>
                            <Controller
                                name="ever_evicted"
                                control={control}
                                rules={{
                                    required: 'This field is required.',
                                    maxLength: {
                                        value: 2,
                                        message: "Evicted can not be more than 2 digits."
                                    },
                                }}
                                render={(field) => (
                                    <TenDigitField
                                        {...field}
                                        format="##"
                                        label="No. of evictions"
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }}
                                    />
                                )}
                            />
                            {errors.ever_evicted && <p className={`${styles.ErrorM}`}>{errors.ever_evicted.message}</p>}
                        </FormControl>
                    </div>
                    <div className={`${styles.FormGroupHalf} FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>Housing Court Cases<span>*</span></label>
                        <p className={`${styles.FormSubLabel}`}>How many cases have been filled with you in housing court?</p>
                        <FormControl fullWidth>
                            <Controller
                                name="ever_case_filed"
                                control={control}
                                rules={{
                                    required: 'This field is required.',
                                    maxLength: {
                                        value: 2,
                                        message: "Case filed can not be more than 2 digits."
                                    },
                                }}
                                render={(field) => (
                                    <TenDigitField
                                        {...field}
                                        format="##"
                                        label="No. of Cases"
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }}
                                    />
                                )}
                            />
                            {errors.ever_case_filed && <p className={`${styles.ErrorM}`}>{errors.ever_case_filed.message}</p>}
                        </FormControl>
                    </div>

                    <div className={`${styles.ToggleBUHalf} FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>Have you ever broken a lease?<span>*</span></label>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select</InputLabel>
                            <Controller
                                name="ever_broken_lease"
                                control={control}
                                rules={{
                                    required: 'This field is required.',
                                }}
                                render={(field) => (
                                    <Select
                                        {...field}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }}
                                    >
                                        < MenuItem value={'Yes'}>{'Yes'}</MenuItem>
                                        < MenuItem value={'No'}>{'No'}</MenuItem>
                                    </Select>
                                )}
                            />
                            {errors.ever_broken_lease && <p className={`${styles.ErrorM}`}>{errors.ever_broken_lease.message}</p>}
                        </FormControl>
                    </div>
                    <div className={`${styles.FormGroupHalf} FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>What is your credit score?<span>*</span></label>
                        <FormControl fullWidth>
                            <Controller
                                name="credit_score"
                                control={control}
                                rules={{
                                    required: 'This field is required.',
                                    pattern: {
                                        value: /\b\d{3}\b/,
                                        message: "Please enter valid credit score."
                                    },
                                }}
                                render={(field) => (
                                    <TenDigitField
                                        {...field}
                                        format="###"
                                        label="Credit Score"
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }}
                                    />
                                )}
                            />
                            {errors.credit_score && <p className={`${styles.ErrorM}`}>{errors.credit_score.message}</p>}
                        </FormControl>
                    </div>

                    <div className={`${styles.FormGroupHalf} FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>How many pets do you have?<span>*</span></label>
                        <FormControl fullWidth>
                            <Controller
                                name="have_pets"
                                control={control}
                                rules={{
                                    required: 'This field is required.',
                                    maxLentgh: {
                                        value: 1,
                                        message: "Pets field  can not be more than 1 digit."
                                    },
                                }}
                                render={(field) => (
                                    <TenDigitField
                                        {...field}
                                        format="##"
                                        label="No. of pets"
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }}
                                    />
                                )}
                            />
                            {errors.have_pets && <p className={`${styles.ErrorM}`}>{errors.have_pets.message}</p>}
                        </FormControl>
                    </div>
                    <div className={`${styles.ToggleBUHalf} FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>Have you ever had bed bugs?<span>*</span></label>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select</InputLabel>
                            <Controller
                                name="ever_had_bedbugs"
                                control={control}
                                rules={{
                                    required: 'This field is required.',
                                }}
                                render={(field) => (
                                    <Select
                                        {...field}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }}
                                    >
                                        < MenuItem value={'Yes'}>{'Yes'}</MenuItem>
                                        < MenuItem value={'No'}>{'No'}</MenuItem>
                                    </Select>
                                )}
                            />
                            {errors.ever_had_bedbugs && <p className={`${styles.ErrorM}`}>{errors.ever_had_bedbugs.message}</p>}
                        </FormControl>
                    </div>

                    <div className={`${styles.ToggleBUHalf} FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>Have you ever filed for bankruptcy?<span>*</span></label>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select</InputLabel>
                            <Controller
                                name="ever_had_bankruptcy"
                                control={control}
                                rules={{
                                    required: 'This field is required.',
                                }}
                                render={(field) => (
                                    <Select
                                        {...field}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }}
                                    >
                                        < MenuItem value={'Yes'}>{'Yes'}</MenuItem>
                                        < MenuItem value={'No'}>{'No'}</MenuItem>
                                    </Select>
                                )}
                            />
                            {errors.ever_had_bankruptcy && <p className={`${styles.ErrorM}`}>{errors.ever_had_bankruptcy.message}</p>}
                        </FormControl>
                    </div>
                    <div className={`${styles.ToggleBUHalf} FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>Do you smoke?<span>*</span></label>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select</InputLabel>
                            <Controller
                                name="do_you_smoke"
                                control={control}
                                rules={{
                                    required: 'This field is required.',
                                }}
                                render={(field) => (
                                    <Select
                                        {...field}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }}
                                    >
                                        < MenuItem value={'Yes'}>{'Yes'}</MenuItem>
                                        < MenuItem value={'No'}>{'No'}</MenuItem>
                                    </Select>
                                )}
                            />
                            {errors.do_you_smoke && <p className={`${styles.ErrorM}`}>{errors.do_you_smoke.message}</p>}
                        </FormControl>
                    </div>

                    <div className={`${styles.FormGroupHalf} FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>How many vehicles do you own?<span>*</span></label>
                        <FormControl fullWidth>
                            <Controller
                                name="own_vehicles"
                                control={control}
                                rules={{
                                    required: 'This field is required.',
                                    maxLength: {
                                        value: 1,
                                        message: "Vehicles filed can not be more than 1 digit."
                                    },
                                }}
                                render={(field) => (
                                    <TenDigitField
                                        {...field}
                                        format="#"
                                        label="No. of vehicles"
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }}
                                    />
                                )}
                            />
                            {errors.own_vehicles && <p className={`${styles.ErrorM}`}>{errors.own_vehicles.message}</p>}
                        </FormControl>
                    </div>

                    <div className={`${styles.FormGroupFull} FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>Additional information<span>*</span></label>
                        <FormControl fullWidth>
                            <Controller
                                name="additional_information"
                                control={control}
                                render={(field) => (
                                    <TextField
                                        {...field}
                                        label="Describe comments or explanations."
                                        multiline
                                        rows={4}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            setCharsLeft((prev) => {
                                                return { ...prev, additional_information: 200 - e.target.value.length };
                                            });
                                        }}
                                        inputProps={{ maxLength: 200 }}
                                    />
                                )}
                            />
                            <p className={`${styles.FormFieldMessage}`}>{charsLeft?.additional_information > 1 ? charsLeft?.additional_information + " characters remaining" : charsLeft?.additional_information + " character remaining"}</p>
                        </FormControl>
                    </div>

                    <div className={`${styles.AccoFormBUsec}`}>
                        <button type="submit" className={`${styles.applicantNextBU}`}>NEXT</button>
                    </div>

                </div>
            </form>
        </AccordionDetails>
    </Accordion >);
}

export default OtherInfoAccordion;