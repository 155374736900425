import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";

const Auth0ProviderWithHistory = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  const onRedirectCallback = (appState) => {
    let rLink = window.location.pathname + 'admin';
    if (typeof (appState) !== 'undefined' && typeof (appState.returnTo) !== 'undefined' && appState.returnTo !== '/') {
      rLink = appState.returnTo;
    }

    window.location.href = rLink;
    //history.push(appState?.returnTo || window.location.pathname);
    //history.push(window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider >
  );
};

export default Auth0ProviderWithHistory;