import { Check, ChevronDown } from 'react-feather';
import styles from '../rentalappplication.module.css';
import { Accordion, AccordionDetails, AccordionSummary, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';

const BasicInfoAccordion2 = ({ expanded, stepData, setStepData, apartmentList, accordinChange, lastStep, setLastStep, setCurrentStep, getNextStep, setPanelExpanded }) => {
    const [apartmentImages, setApartmentImages] = useState([]);

    const { register, handleSubmit, control, setValue, formState: { errors } } = useForm({
        mode: "onChange",
        defaultValues: {
            apt_id: '',
            apt_type: '',
            no_of_applicants: ''
        }
    });


    useEffect(() => {
        if (apartmentList?.length > 0) {
            if (stepData) {
                if (stepData?.apt_id) {
                    setValue('apt_id', stepData?.apt_id);
                    setValue('apt_type', stepData?.apt_type);

                    let selectedApt = apartmentList.filter(i => i?.id === stepData?.apt_id);
                    if (selectedApt.length > 0) {
                        setApartmentImages([...selectedApt[0]?.layout_images, ...selectedApt[0]?.images]);
                    }

                } else {
                    setApartmentImages([...apartmentList[0]?.layout_images, ...apartmentList[0]?.images]);
                    setValue('apt_id', apartmentList[0]?.id);
                    setValue('apt_type', apartmentList[0]?.apt_type);
                }
                if (stepData?.move_in_date) {
                    setValue('move_in_date', dayjs(stepData?.move_in_date));
                }
                if (stepData?.move_in_date) {
                    setValue('no_of_applicants', stepData?.no_of_applicants);
                }
            } else {
                setApartmentImages([...apartmentList[0]?.layout_images, ...apartmentList[0]?.images]);
                setValue('apt_id', apartmentList[0]?.id);
                setValue('apt_type', apartmentList[0]?.apt_type);
            }

        }
    }, [stepData, apartmentList, setValue]);

    const onSubmit = (data) => {
        data = { ...data, move_in_date: dayjs(data?.move_in_date).format('YYYY-MM-DD') };

        setStepData(prev => {
            if (data?.no_of_applicants === 1) {
                delete prev?.name2;
                delete prev?.email2;
            }
            return { ...prev, ...data };
        });

        let currentStepTemp = getNextStep(1);
        setCurrentStep(currentStepTemp);
        setPanelExpanded('panel' + currentStepTemp);
        if (lastStep < currentStepTemp) {
            setLastStep(currentStepTemp);
        }
    }

    return (<Accordion expanded={expanded === 'panel1'} onChange={accordinChange('panel1', 1)}>
        <AccordionSummary expandIcon={<ChevronDown />} aria-controls="panel1bh-content" id="panel1bh-header">
            <div className={`${styles.FormAccoHead}`}>
                <p className={`${styles.FormAccoHeadTitle}`}>Basic information</p>
                <div className={((lastStep > 1) ? `${styles.AccoCheck} ${styles.Active}` : `${styles.AccoCheck}`)}><Check /></div>
            </div>
        </AccordionSummary>
        <AccordionDetails className={`${styles.CussAccoRow}`}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <input type='hidden' ref={register} name='apt_type' />
                <div className="RentalAppliCaro">
                    <Carousel autoPlay="true" infiniteLoop="true">
                        {apartmentImages.map((item, index) => {
                            return (<div key={index}>
                                <img src={item.file_public_url} alt="" />
                            </div>)
                        })}
                    </Carousel>
                </div>
                <div className={`${styles.AccoFormSec}`}>
                    <div className={`${styles.FormGroupFull} FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>Apt #<span>*</span></label>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select</InputLabel>
                            <Controller
                                name="apt_id"
                                control={control}
                                render={(field) => (
                                    <Select
                                        {...field}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }}
                                        disabled
                                    >
                                        {apartmentList?.map(item => {
                                            return (< MenuItem key={item?.id} value={item?.id}>{item?.apt_no} - {item?.apt_type} / {item?.bathroom} Bath</MenuItem>);
                                        })}
                                    </Select>
                                )}
                            />
                        </FormControl>
                    </div>

                    <div className={`${styles.FormGroupHalf} FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>Specify the number of applicant<span>*</span></label>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select</InputLabel>
                            <Controller
                                name="no_of_applicants"
                                control={control}
                                render={(field) => (
                                    <Select
                                        {...field}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }}
                                        disabled
                                    >
                                        <MenuItem value={1}>1</MenuItem>
                                        <MenuItem value={2}>2</MenuItem>
                                    </Select>
                                )}
                            />
                        </FormControl>
                    </div>

                    <div className={`${styles.FormGroupHalf} FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>Desired move-in date<span>*</span></label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Controller
                                name="move_in_date"
                                control={control}
                                rules={{ required: 'This field is required.' }}
                                render={(field) => (
                                    <DatePicker
                                        {...field}
                                        renderInput={(params) => (
                                            <TextField {...params} />
                                        )}
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }}
                                        disabled
                                    />
                                )}
                            />
                        </LocalizationProvider>
                        {errors.move_in_date && <p className={`${styles.ErrorM}`}>This field is required</p>}
                    </div>

                    <div className={`${styles.AccoFormBUsec}`}>
                        <button type="submit" className={`${styles.applicantNextBU}`} disabled>NEXT</button>
                    </div>

                </div>
            </form>
        </AccordionDetails>
    </Accordion >);
}

export default BasicInfoAccordion2;