import styles from '../communication-prospect.module.css';
import styled from "styled-components/macro";
import { Send } from "react-feather";
import { Grid, Box, Fab } from "@material-ui/core";
import TextareaAutosize from 'react-textarea-autosize';
const ChatInputs = styled(Grid)`
  min-height: 40px;
  padding: 34px; 24px 24px 24px;
`;
function ProspectChatInput(props) {

  return (
    <>
      {props.userDet?.id && <ChatInputs container>
        <div className={`${styles.ChatInputSec}`}>
          <Grid item style={{ flexGrow: 1 }}>

            <TextareaAutosize minRows={1} maxRows={6} placeholder="Type your message" value={props.messageTextVal} onChange={props.handleChange} />
          </Grid>

          <Grid item>
            <Box ml={2}>
              <Fab className={`${styles.SendBUMSG}`} aria-label="add" onClick={props.sendMessage}>
                <Send />
              </Fab>
            </Box>
          </Grid>
        </div>
      </ChatInputs>}
    </>
  )
}

export default ProspectChatInput;