import React, { useState, useEffect } from "react";
import styles from "../../propertydetails.module.css";
import { useForm } from "react-hook-form";
import { Switch } from "@mui/material";
import axios from "axios";
import { toast } from "react-smart-toaster";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "../../../../components/Loader";
import { AddLogCallAPI } from "../../../../components/AddLogs";

const AppPermissionTab = (props) => {
    const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
    const [loading, setLoading] = useState(false);
    const [changedAppPerms, setChangedAppPerms] = useState({ chat: false, energy: false, payments_acf: false, payments_card: false, request: false, smart_rooms: false });
    const [defaultAppPerms, setDefaultAppPerms] = useState({ chat: false, energy: false, payments_acf: false, payments_card: false, request: false, smart_rooms: false });

    const { register, handleSubmit } = useForm({
        shouldUnregister: false,
    });

    useEffect(() => {
        setDefaultAppPerms(props?.app_permissions);
        setChangedAppPerms(props?.app_permissions);
    }, [props?.app_permissions]);

    const ifValChange = () => {
        if (JSON.stringify(defaultAppPerms) === JSON.stringify(changedAppPerms)) {
            return false;
        } else {
            return true;
        }
    };

    const onSubmit = async (data) => {
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
        setLoading(true);
        return axios.put(process.env.REACT_APP_PROPERTY_API_URL + "v1/properties/" + props?.property_id + "/update-permission", data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setLoading(false);

            props.setSnackbarMsg(response.data.message);
            props.setSnackbarOpen(true);

            props.setPropertyData(response.data.data);

            let logData = {
                title: "Mobile app permission is updated",
                description: ["Property: " + props?.property_name],
            };
            AddLogCallAPI(logData, token);
        }).catch((error) => {
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
            setLoading(false);
        });
    }


    return (<>
        {loading && <Loader />}
        <div className={`${styles.Permission}`}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h5>Mobile App Permissions</h5>
                <div className={`${styles.PermissionSwitch}`}>
                    <Switch
                        onChange={(e) => { setChangedAppPerms({ ...changedAppPerms, smart_rooms: e.target.checked }); }}
                        name="smart_rooms"
                        inputRef={register}
                        checked={changedAppPerms.smart_rooms === true}
                        disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("property:update") > -1)}
                    />
                    Smart Rooms
                </div>
                <div className={`${styles.PermissionSwitch}`}>
                    <Switch
                        onChange={(e) => { setChangedAppPerms({ ...changedAppPerms, request: e.target.checked }); }}
                        name="request"
                        inputRef={register}
                        checked={changedAppPerms.request === true}
                        disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("property:update") > -1)}
                    />
                    Requests
                </div>
                <div className={`${styles.PermissionSwitch}`}>
                    <Switch
                        onChange={(e) => { setChangedAppPerms({ ...changedAppPerms, energy: e.target.checked }); }}
                        name="energy"
                        inputRef={register}
                        checked={changedAppPerms.energy === true}
                        disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("property:update") > -1)}
                    />
                    Lease
                </div>
                <div className={`${styles.PermissionSwitch}`}>
                    <Switch
                        onChange={(e) => { setChangedAppPerms({ ...changedAppPerms, payments_card: e.target.checked }); }}
                        name="payments_card"
                        inputRef={register}
                        checked={changedAppPerms.payments_card === true}
                        disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("property:update") > -1)}
                    />
                    Payments
                </div>
                <div className={`${styles.PermissionSwitch}`}>
                    <Switch
                        onChange={(e) => { setChangedAppPerms({ ...changedAppPerms, payments_acf: e.target.checked }); }}
                        name="payments_acf"
                        inputRef={register}
                        checked={changedAppPerms.payments_acf === true}
                        disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("property:update") > -1)}
                    />
                    ACH Payment
                </div>
                <div className={`${styles.PermissionSwitch}`}>
                    <Switch
                        onChange={(e) => { setChangedAppPerms({ ...changedAppPerms, chat: e.target.checked }); }}
                        name="chat"
                        inputRef={register}
                        checked={changedAppPerms.chat === true}
                        disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("property:update") > -1)}
                    />
                    Chat
                </div>
                <div className={`${styles.ButtonDiv}`}>
                    {ifValChange() && (<button type="submit" className={`${styles.ButtonVoiletSolid}`} disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("property:update") > -1)}>Apply</button>)}
                </div>
            </form>
        </div>
    </>);
}


export default AppPermissionTab;