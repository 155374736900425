import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { Img } from 'react-image';
import styles from '../communication.module.css';
import { Download, X } from 'react-feather';

const FileViewer = ({ fileUrl, fileName }) => {
  const [fullImage, setFullImage] = useState(false)
  const getFileExtension = (filename) => {
    return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
  };
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    link.download = fileName; // Set the desired filename for the downloaded image
    link.click();
  };


  const renderFileContent = () => {
    const fileExtension = getFileExtension(fileUrl);

    if (fileExtension === 'png' || fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'gif' || fileExtension === 'svg') {
      return <div onClick={() => setFullImage(true)} className={`${styles.ImgFile}`}><Img src={fileUrl} alt="Image" /></div>;
    } else if (fileExtension === 'mp4') {
      return <ReactPlayer url={fileUrl} controls />;
    } else if (fileExtension === 'mp3') {
      return <audio src={fileUrl} controls />;
    } else {
      return (
        <div>
          <p className={`${styles.NoFileTT}`}>This file type is not supported for preview.</p>
          <a className={`${styles.NoFileDrBU}`} href={fileUrl} download>Download File</a>
        </div>
      );
    }
  };

  return (
    <div className={fullImage ? `${styles.ActiveIMG}` : ''}>
      <p>{fileName}</p>  {(getFileExtension(fileUrl) === 'png' || getFileExtension(fileUrl) === 'jpg' || getFileExtension(fileUrl) === 'jpeg' || getFileExtension(fileUrl) === 'gif' || getFileExtension(fileUrl) === 'svg') && <button className={`${styles.DRBU}`} onClick={handleDownload}><Download /></button>}{renderFileContent()}
      <div className={`${styles.ImgFilePops}`}><Img src={fileUrl} alt="Image" /><button onClick={() => setFullImage(false)} className={`${styles.CancelImgBU}`}><X /></button></div>
    </div>
  );
};

export default FileViewer;



// import React from 'react';

// const FileViewer = ({ fileUrl }) => {
//   const getFileExtension = (filename) => {
//     return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
//   };

//   const renderFileContent = () => {
//     const fileExtension = getFileExtension(fileUrl);

//     if (fileExtension === 'pdf') {
//       return (
//         <iframe src={fileUrl} title="PDF File" width="100%" height="500px" />
//       );
//     } else if (fileExtension === 'png' || fileExtension === 'jpg' || fileExtension === 'jpeg') {
//       return <img src={fileUrl} alt="Image" />;
//     } else if (fileExtension === 'mp4') {
//       return <video src={fileUrl} controls />;
//     } else {
//       return (
//         <div>
//           <p>This file type is not supported for preview.</p>
//           <a href={fileUrl} download>Download File</a>
//         </div>
//       );
//     }
//   };

//   return (
//     <div>
//       <h2>File Viewer</h2>
//       {renderFileContent()}
//     </div>
//   );
// };

// export default FileViewer;
