import React, { useEffect, useState } from "react";
import styles from "./userlist.module.css";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import Footer from "../../components/Footer";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, Slide, Snackbar, TablePagination, Typography } from "@mui/material";
import { Archive, ArrowLeft, ArrowRight, Edit, Filter, Home, MoreVertical, Plus, Search, Trash2, X } from "react-feather";
import { Dropdown } from "react-bootstrap";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "../../components/Loader";
import { TableLoaderSmall } from "../../components/LoaderC";
import UserAdd from "./components/AddForm";
import { toast } from 'react-smart-toaster';
import { AddLogCallAPI } from "../../components/AddLogs";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import PeoplePageTitle from "../tenants/components/PeoplePageTitle";
import CutomTooltip from "./components/CutomTooltip";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    maxWidth: 400,
    fontSize: 13,
    textAlign: 'left',
  },
}));

const UserList = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  const [propertyList, setPropertyList] = useState([]);
  const [allData, setAllData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [filterData, setFilterData] = useState({ searchKey: "", role: "", property: "" });
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [editUserData, setEditUserData] = useState(null);
  const [selectecId, setSelectecId] = useState(0);
  const [isArchive, setIsArchive] = useState(false);
  const [perPage, setPerPage] = useState(8);


  useEffect(() => {
    const getUserList = async () => {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

      axios(process.env.REACT_APP_APPLICANT_API_URL + "v1/get-all-properties", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        setPropertyList([{ value: 'all', label: 'Any' }].concat(response.data.data));
      }).catch(() => {
        setPropertyList([]);
      });
    }

    getUserList();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const getUserList = async () => {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

      axios(process.env.REACT_APP_USER_API_URL + "v1/users", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        setContentLoading(false);
        setAllData(response.data.data);
      }).catch(() => {
        setContentLoading(false);
      });
    }

    getUserList();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (allData.length > 0) {
      let filteredDataTemp = allData;

      if (filterData.searchKey !== "") {
        let inputVal = filterData.searchKey;
        inputVal = inputVal.toLowerCase();
        filteredDataTemp = filteredDataTemp.filter((item) => {
          return (item.name.toLowerCase().includes(inputVal) || item.role.toLowerCase().includes(inputVal) || item.phone.toLowerCase().includes(inputVal) || item.email.toLowerCase().includes(inputVal));
        });
      }

      if (filterData.property !== "" && filterData.property !== "all") {
        filteredDataTemp = filteredDataTemp.filter((i) => i.property_ids.indexOf(filterData.property) > -1);
      }

      if (filterData.role !== "" && filterData.role !== "all") {
        filteredDataTemp = filteredDataTemp.filter((i) => i.role === filterData.role);
      }

      setFilteredData(filteredDataTemp);
    }
  }, [allData, filterData]);

  const userAdd = (data) => {
    setAllData(prev => {
      return [data].concat(prev);
    })
  }

  const userUpdate = (data) => {
    setAllData(prev => {
      let updateList = prev?.map(item => {
        if (item?.id === data?.id)
          return data;
        else
          return item;
      })
      return updateList;
    })
  }

  const confirmDelete = async () => {
    setDeleteModalOpen(false);
    setLoading(true);
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

    axios.delete(process.env.REACT_APP_USER_API_URL + 'v1/users/' + selectecId, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then(response => {
      setSnackbarOpen(true);
      setSnackbarMsg(response.data.message);
      setLoading(false);

      let respData = response.data.data;

      setAllData(prev => {
        let updateList = prev?.filter(i => i?.id !== selectecId);
        return updateList;
      })
      setSelectecId(0);

      let logData = {
        'title': 'User is deleted',
        'description': [
          'Name: ' + respData.name,
          'Email: ' + respData.email,
          'Role: ' + respData.role
        ]
      }
      AddLogCallAPI(logData, token);
    }).catch(error => {
      if (typeof error.response !== 'undefined')
        toast.error(error.response.data.message);
      setLoading(false);
    });
  }

  const confirmArchive = async () => {
    setArchiveModalOpen(false);
    setLoading(true);
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

    let is_archived = isArchive ? 0 : 1;
    axios.put(process.env.REACT_APP_USER_API_URL + 'v1/users/' + selectecId, { is_archived: is_archived }, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
    ).then(response => {
      setSnackbarOpen(true);
      setSnackbarMsg(response.data.message);
      setLoading(false);

      let respData = response.data.data;

      userUpdate(respData);

      let logData = {
        'title': (respData.is_archived ? 'User is archived' : 'User is unarchived'),
        'description': [
          'Name: ' + respData.name,
          'Email: ' + respData.email,
          'Role: ' + respData.role
        ]
      }
      AddLogCallAPI(logData, token);
    }).catch(error => {
      if (typeof error.response !== 'undefined')
        toast.error(error.response.data.message);
      setLoading(false);
    });
  }



  return (<>
    {loading && <Loader />}
    <Helmet title="Users" />

    <div className={`${styles.pageTitleWrap}`}>
      <PeoplePageTitle defaultPage={'Users'} />
      <Typography display="inline" className={`${styles.pageTitle} ${styles.Big}`}>Users</Typography>
      <nav aria-label="breadcrumb">
        <ol className={`${styles.breadcrumb}`}>
          <li className={`${styles.breadcrumbItem}`}><span><Home /></span>People</li>
          <li className={`${styles.breadcrumbItem} ${styles.active}`}>Users</li>
        </ol>
      </nav>
    </div>

    <div className={`${styles.BodyCon}`}>
      <div className={`${styles.mainCardDiv}`}>
        <div className={`${styles.mainCard}`}>
          {contentLoading && <div className="Loader TableLoader"><TableLoaderSmall /></div>}

          {!contentLoading && <div className="control-pane">
            <div className={`${styles.Row}`}>

              <div className={`${styles.SearchFilterSec}`}>
                <div className={`${styles.SearchCon}`}>
                  <div className={`${styles.SearchConSec}`}>
                    <Search />
                    <input type="text" placeholder="Search" onChange={(e) => setFilterData(prev => { return { ...prev, searchKey: e.target.value } })} />
                  </div>
                </div>
                <div className={`${styles.FilterSec}`}>
                  <div className={!showFilter ? `${styles.FilterFieldSec}` : `${styles.FilterFieldSec} ${styles.Open}`}>
                    <p className={`${styles.FilterTitle}`}>
                      Filters
                      <span onClick={(e) => { setShowFilter(!showFilter) }}>
                        <ArrowRight />
                      </span>
                    </p>
                    <div className={`${styles.FilterCard} ${styles.Select} CusSelectFilter Filter`}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Property</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Property"
                          onChange={(e) => setFilterData(prev => {
                            return { ...prev, property: e.target.value }
                          })}
                        >
                          {propertyList?.map(item => {
                            return (<MenuItem key={item?.value} value={item?.value}>{item?.label}</MenuItem>);
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <div className={`${styles.FilterCard} ${styles.Select} CusSelectFilter Filter`}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Role</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Role"
                          onChange={(e) => setFilterData(prev => {
                            return { ...prev, role: e.target.value }
                          })}
                        >
                          <MenuItem value="all">Any</MenuItem>
                          <MenuItem value="Admin">Admin</MenuItem>
                          <MenuItem value="Property Manager">Property Manager</MenuItem>
                          <MenuItem value="Technician">Technician</MenuItem>
                          <MenuItem value="Assistant">Assistant</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <Link className={`${styles.FilterBu}`} onClick={(e) => { setShowFilter(!showFilter) }}>
                    <Filter />
                  </Link>
                  <Link className={`${styles.AddNoteBu}`} onClick={(e) => { setAddModalOpen(true) }}>
                    <Plus /> <span>User</span>
                  </Link>
                </div>
              </div>

              <div className={`${styles.NotesRow}`}>

                {filteredData.map((item, index) => {
                  return (perPage === -1 || (index >= page * perPage && index < page * perPage + perPage)) ? (<div className={`${styles.ProspectCard}`}>
                    <div className={`${styles.ProspectCardUnder}`}>
                      <div className={`${styles.ProspectCardHeadSection}`}>
                        <Dropdown className={`${styles.ActionLeaseBUSec}`}>
                          <Dropdown.Toggle className={`${styles.ActionsLeaseBU} ActionsBU`} id="dropdown-basic">
                            <MoreVertical />
                          </Dropdown.Toggle>

                          <Dropdown.Menu className={`${styles.ActionsBUList} ActionsBUList`}>
                            <Dropdown.Item className={`${styles.EditActionsBUListItem}`} onClick={(e) => { setArchiveModalOpen(true); setSelectecId(item.id); setIsArchive(item?.is_archived === 1); }}>
                              <Archive /> {item?.is_archived === 1 ? 'Unarchive' : 'Archive'}
                            </Dropdown.Item>
                            <Dropdown.Item className={`${styles.EditActionsBUListItem}`} onClick={(e) => { setEditModalOpen(true); setEditUserData(item); }}>
                              <Edit /> Edit
                            </Dropdown.Item>
                            <Dropdown.Item className={`${styles.EditActionsBUListItem} ${styles.Delete}`} onClick={(e) => { setDeleteModalOpen(true); setSelectecId(item.id); }}>
                              <Trash2 /> Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>

                        <div className={`${styles.ProsIMGSec}`}>
                          <p>{item?.initial}</p>
                        </div>
                        <div className={`${styles.HeadConSec}`}>
                          <p className={`${styles.ProsUserName}`}>
                            <span className={`${styles.UserName}`}>{item?.name}</span>
                            {item?.is_archived === 1 && <span className={`${styles.ArchiveBadge}`}>Archived</span>}
                          </p>
                          <p className={`${styles.ProsUserName}`}>{item?.role}</p>
                        </div>
                      </div>
                      <div className={`${styles.ProspectCardBodySec}`}>
                        <ul>
                          <li>
                            <p className={`${styles.Title}`}>Phone:</p>
                            <p className={`${styles.Content}`}>{item?.phone}</p>
                          </li>
                          <li>
                            <p className={`${styles.Title}`}>Email:</p>
                            <p className={`${styles.Content}`}>{item?.email}</p>
                          </li>
                          <li>
                            <p className={`${styles.Title}`}>Property:</p>
                            <p className={`${styles.Content}`}>
                              {item?.property_arr[0]}

                              {item?.property_arr?.length > 1 && <BootstrapTooltip title={item?.property_arr?.join(', ')}>
                                <span className={`${styles.MorePropertyBU}`}>+{item?.property_arr?.length - 1}</span>
                              </BootstrapTooltip>}

                              {item?.property_arr?.length > 1 && <CutomTooltip pArr={item?.property_arr} />}
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>) : null;
                })}


                {filteredData.length > 0 && (<div className="Pagination TablePaginationNew List">
                  <TablePagination
                    count={filteredData.length}
                    color="primary"
                    page={page}
                    rowsPerPage={perPage}
                    onPageChange={(e, v) => setPage(v)}
                    rowsPerPageOptions={[
                      { label: '4', value: 4 },
                      { label: '8', value: 8 },
                      { label: '16', value: 16 },
                      { label: 'All', value: -1 },
                    ]}
                    onRowsPerPageChange={(e) => {
                      setPage(0);
                      setPerPage(e.target.value);
                    }}
                    labelRowsPerPage={'Users per Page:'}
                  />
                </div>)}

                {!contentLoading && filteredData.length === 0 && (<div className={`${styles.NoDataMain}`}>
                  <div className={`${styles.NoDataIMG}`}>
                    <img src="/static/img/nodata.svg" alt="No Data" />
                  </div>
                  <p className={`${styles.NoDataText}`}>No Users Found</p>
                </div>)}

              </div>

            </div>
          </div>}
        </div>
      </div>
    </div>

    <Dialog
      open={deleteModalOpen}
      onClose={(e) => setDeleteModalOpen(false)}
      fullWidth
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp"
    >
      <DialogTitle id="alert-dialog-title">Would you like to delete this user?<button onClick={(e) => setDeleteModalOpen(false)}><X /></button></DialogTitle>
      <DialogContent>
        <div className="ModalFormGroup">
          <label className="PopupBodyText">The user information will be removed immediately.You can't undo this action.</label>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={(e) => confirmDelete()} className="CancelPopupBU">Delete</Button>
        <Button onClick={(e) => setDeleteModalOpen(false)} className="SubmitPopupBU">Cancel</Button>
      </DialogActions>
    </Dialog>

    <Dialog
      open={archiveModalOpen}
      onClose={(e) => setArchiveModalOpen(false)}
      fullWidth
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp"
    >
      <DialogTitle id="alert-dialog-title">Would you like to {isArchive ? 'unarchive' : 'archive'} this user?<button onClick={(e) => setArchiveModalOpen(false)}><X /></button></DialogTitle>
      <DialogContent>
        <div className="ModalFormGroup">
          <label className="PopupBodyText">The user information will be {isArchive ? 'unarchived' : 'archived'} immediately.You can undo this action at anytime.</label>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={(e) => confirmArchive()} className="CancelPopupBU">{isArchive ? 'Unarchive' : 'Archive'}</Button>
        <Button onClick={(e) => setArchiveModalOpen(false)} className="SubmitPopupBU">Cancel</Button>
      </DialogActions>
    </Dialog>

    <Dialog
      open={addModalOpen}
      onClose={(e) => { setAddModalOpen(false) }}
      fullWidth
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp MobileForm"
    >
      <DialogTitle id="alert-dialog-title">
        <button className={`${styles.BackAddBU}`} onClick={(e) => { setAddModalOpen(false) }}><ArrowLeft /></button>
        Add User
        <button onClick={(e) => { setAddModalOpen(false) }}><X /></button>
      </DialogTitle>
      <DialogContent>
        <UserAdd setAddModalOpen={setAddModalOpen.bind(this)} setLoading={setLoading.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} userAddLocal={userAdd.bind(this)} editData={null} />
      </DialogContent>
    </Dialog>

    <Dialog
      open={editModalOpen}
      onClose={(e) => { setEditModalOpen(false) }}
      fullWidth
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp MobileForm"
    >
      <DialogTitle id="alert-dialog-title">
        <button className={`${styles.BackAddBU}`} onClick={(e) => { setEditModalOpen(false) }}><ArrowLeft /></button>
        Update User
        <button onClick={(e) => { setEditModalOpen(false) }}><X /></button>
      </DialogTitle>
      <DialogContent>
        <UserAdd setAddModalOpen={setEditModalOpen.bind(this)} setLoading={setLoading.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} userAddLocal={userUpdate.bind(this)} editData={editUserData} />
      </DialogContent>
    </Dialog>

    <Snackbar
      open={snackbarOpen}
      onClose={(e) => setSnackbarOpen(false)}
      TransitionComponent={Slide}
      message={snackbarMsg}
      autoHideDuration={10000}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={(e) => setSnackbarOpen(false)}
        >
          <X />
        </IconButton>
      }
    />

    <Footer />
  </>
  )
}

export default UserList;