import styles from "../../../../maintenancerequestdetails.module.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-smart-toaster";
import { useAuth0 } from "@auth0/auth0-react";
import { AddLogCallAPI } from "../../../../../../components/AddLogs";
import { Select, FormControl, InputLabel, MenuItem, TextField, Button } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const schema = yup.object().shape({
  assignee_id: yup.string().required("This field is Required"),
  date: yup.date().required("This field is Required"),
  spent_time: yup.string().required("This field is Required"),
});

const LaborAdd = ({ appEditId, appEditData, requestData, setRequestData, setAddModalOpen, setSnackbarOpen, setSnackbarMsg, setLoading }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [technicianList, setTechnicianList] = useState([]);

  const { control, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      assignee_id: "",
      date: dayjs(),
    },
  });

  useEffect(() => {
    if (appEditData) {
      setValue("assignee_id", appEditData?.assignee_id);
      setValue("date", dayjs(appEditData?.date));
      setValue("spent_time", appEditData?.spent_time);
    }
  }, [appEditData, setValue]);

  useEffect(() => {
    async function getAssigneeList() {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      axios.get(process.env.REACT_APP_CASE_API_URL + "v1/technicians", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((response) => {
        setTechnicianList(response.data.data);
      }).catch((error) => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
      });
    }
    getAssigneeList();
  }, [getAccessTokenSilently]);

  const onSubmit = async (data) => {
    if (data?.date) {
      data = { ...data, date: dayjs(data?.date).format("YYYY-MM-DD") };
    }

    setAddModalOpen(false);
    setLoading(true);

    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

    if (appEditId === 0) {
      return axios.post(process.env.REACT_APP_CASE_API_URL + "v1/maintenance-requests/" + requestData?.id + "/appointment-labors", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        setLoading(false);

        setSnackbarOpen(true);
        setSnackbarMsg(response.data.message);

        setRequestData((prev) => {
          let labors = [response.data.data].concat(prev?.labors);
          return { ...prev, labors: labors };
        });

        let logData = {
          title: "Maintenance request new labor is created",
          description: [
            "Name: " + requestData?.title,
            "Request number: " + requestData?.request_no,
            "Property: " + requestData?.property_name,
            "Apartment: " + requestData?.apt_name,
            "Tenant: " + requestData?.tenant_name,
          ],
        };
        AddLogCallAPI(logData, token);
      }).catch((error) => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
        setLoading(false);
      });
    } else {
      axios.put(process.env.REACT_APP_CASE_API_URL + "v1/maintenance-requests/" + requestData?.id + "/appointment-labors/" + appEditId, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        setLoading(false);

        setSnackbarOpen(true);
        setSnackbarMsg(response.data.message);

        setRequestData((prev) => {
          let labors = prev?.labors?.map((i) => {
            if (i?.id === appEditId) {
              return response.data.data;
            }
            return i;
          });
          return { ...prev, labors: labors };
        });

        let logData = {
          title: "Maintenance request labor is updated",
          description: [
            "Name: " + requestData?.title,
            "Request number: " + requestData?.request_no,
            "Property: " + requestData?.property_name,
            "Apartment: " + requestData?.apt_name,
            "Tenant: " + requestData?.tenant_name,
          ],
        };
        AddLogCallAPI(logData, token);
      }).catch((error) => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
        setLoading(false);
      });
    }
  };

  return (<div className={`${styles.ModalFormGroup}`}>
    <form onSubmit={handleSubmit(onSubmit)}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className={`${styles.PopFormGroup}`}>
          <div className={`${styles.FormGroupFull} FormGroup`}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select Technician*</InputLabel>
              <Controller
                name="assignee_id"
                control={control}
                render={(field) => (
                  <Select
                    label="Select Technician*"
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                  >
                    {technicianList.map((item) => {
                      return (<MenuItem value={item?.value}>{item?.label}</MenuItem>);
                    })}
                  </Select>
                )}
              />
            </FormControl>
            {errors.assignee_id && (<p className={`${styles.ErrorM}`}>{errors.assignee_id.message}</p>)}
          </div>
        </div>
        <div className={`${styles.PopFormGroupTwo}`}>
          <div className={`${styles.FormFullSec} FormGroup`}>
            <Controller
              className={`${styles.formControl}`}
              name="spent_time"
              control={control}
              render={(field) => (
                <TextField
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  id="outlined-basic"
                  label="Type the time spent in hours*"
                  variant="outlined"
                />
              )}
            />
            {errors.spent_time && (<p className={`${styles.ErrorM}`}>{errors.spent_time.message}</p>)}
          </div>
          <div className={`${styles.Width36}`}>
            <div className={`${styles.DatePick} TimePick`}>
              <Controller
                className={`${styles.formControl}`}
                name="date"
                control={control}
                render={(fields) => (
                  <MobileDatePicker {...fields} label="Select Date" />
                )}
              />
              {errors.date && (<p className={`${styles.ErrorM}`}>{errors.date.message}</p>)}
            </div>
          </div>
        </div>
      </LocalizationProvider>
      <div className={`${styles.ButtonAction}`}>
        <Button className="CancelPopupBU" onClick={(e) => setAddModalOpen(false)}>Cancel</Button>
        <Button className="SubmitPopupBU" type="submit">{appEditId ? "Update" : "Add"}</Button>
      </div>
    </form>
  </div>);
};

export default LaborAdd;