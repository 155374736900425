import { ClickAwayListener } from "@mui/material";
import { useState } from "react";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import styles from "../userlist.module.css";

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        maxWidth: 400,
        fontSize: 13,
        textAlign: 'left',
    },
}));

const CutomTooltip = ({ pArr }) => {
    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    return (<ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
            <BootstrapTooltip
                PopperProps={{
                    disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                title={pArr?.join(', ')}
                disableFocusListener
                disableHoverListener
                disableTouchListener
            >
                <button onClick={handleTooltipOpen}>
                    <span className={`${styles.MorePropertyBU}`}>+{pArr?.length - 1}</span>
                </button>
            </BootstrapTooltip>
        </div>
    </ClickAwayListener>);
}

export default CutomTooltip;