
import { Edit2, MoreVertical, Plus, Search, Trash2, X } from "react-feather";
import styles from "../../tenantdetails.module.css";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TablePagination } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { toast } from "react-smart-toaster";
import { Dropdown } from "react-bootstrap";

const schema = yup.object().shape({
    note: yup.string().required("This field is Required").max(200, "Note cannot be more than 200 characters"),
});

const NotesTab = ({ tenantData, setTenantData, setLoading, setSnackbarOpen, setSnackbarMsg }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [noteList, setNoteList] = useState([]);
    const [filterKey, setFilterKey] = useState("");
    const [noteFormCard, setNoteFormCard] = useState(false);
    const [noteEditFormCard, setNoteEditFormCard] = useState(false);
    const [noteEditId, setNoteEditId] = useState(false);
    const [page, setPage] = useState(0);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState(0);
    const [perPage, setPerPage] = useState(5);


    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (tenantData?.notes) {
            let noteTempList = tenantData?.notes;

            if (filterKey !== "") {
                let inputVal = filterKey.toLowerCase();
                noteTempList = noteTempList.filter((item) => {
                    return (item.note.toLowerCase().includes(inputVal) || item.property.toLowerCase().includes(inputVal) || item.time_str.toLowerCase().includes(inputVal) || item.role.toLowerCase().includes(inputVal));
                });
            }

            setNoteList(noteTempList);
        }
    }, [tenantData, filterKey]);

    const onSubmit = async (data) => {
        setLoading(true);
        setNoteFormCard(false);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        data = { ...data, user_id: "" };

        axios.post(process.env.REACT_APP_TENANT_API_URL + "v1/tenants/" + tenantData?.id + "/notes", data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            setLoading(false);
            setSnackbarOpen(true);
            setSnackbarMsg(response.data.message);

            setTenantData(prev => {
                return { ...prev, notes: [response.data.data].concat(prev.notes) };
            });
        }).catch((error) => {
            setLoading(false);
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
        });
    }

    const onUpdate = async (data) => {
        setLoading(true);
        setNoteEditFormCard(false);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        data = { ...data, user_id: "" };

        axios.put(process.env.REACT_APP_TENANT_API_URL + "v1/tenants/" + tenantData?.id + "/notes/" + noteEditId, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then((response) => {
            setLoading(false);
            setSnackbarOpen(true);
            setSnackbarMsg(response.data.message);

            setTenantData(prev => {
                let allNotes = prev?.notes.map((item) => {
                    if (item.id === noteEditId)
                        return { ...item, note: response.data.data?.note };
                    return item
                });

                return { ...prev, notes: allNotes };
            });

            setNoteEditId(0);
        }).catch((error) => {
            setLoading(false);
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
        });
    }

    const delConfirm = async () => {
        setLoading(true);
        setDeleteModal(false);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        axios.delete(process.env.REACT_APP_TENANT_API_URL + "v1/tenants/" + tenantData?.id + "/notes/" + deleteId, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            setLoading(false);
            setSnackbarOpen(true);
            setSnackbarMsg(response.data.message);

            setTenantData(prev => {
                let allNotes = prev?.notes.filter((item) => item.id !== deleteId);

                return { ...prev, notes: allNotes };
            });

            setDeleteId(0);
        }).catch((error) => {
            setLoading(false);
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
        });
    }

    return <>
        {tenantData?.notes?.length > 0 && <div className={`${styles.NotesSearchArea}`}>
            <div className={`${styles.NotesSearchMain}`}>
                <input type="text" placeholder="Search" className={`${styles.searchInput}`} onChange={(e) => setFilterKey(e.target.value)} />
                <Search />
            </div>
            <button className={`${styles.AddNoteBu}`} onClick={(e) => setNoteFormCard(true)}><Plus /></button>
        </div>}
        {noteFormCard && (<div className={`${styles.AddCommDiv}`}>
            <form id="myForm" onSubmit={handleSubmit(onSubmit)}>
                <div className={`${styles.formGroup}`}>
                    <textarea
                        type="text"
                        className={`${styles.ReferCommentArea}`}
                        name="note"
                        ref={register}
                        placeholder="Add text here..."
                    ></textarea>
                    {errors.note && (<p className={`${styles.ErrorM}`}>{errors.note.message}</p>)}
                </div>
                <Button onClick={(e) => setNoteFormCard(false)} className={`${styles.ButtonVoiletLine}`}>Cancel</Button>
                <Button type="submit" className={`${styles.ButtonVoiletSolid}`}>Add</Button>
            </form>
        </div>
        )}
        {noteList?.length > 0 && <>
            <div className={`${styles.NotesRow}`}>
                {noteList?.map((item, index) => {
                    return (perPage === -1 || (index >= page * perPage && index < page * perPage + perPage)) ? (<div className={`${styles.NotesCard}`} key={item?.id}>
                        <div className={`${styles.NotesCardInside}`}>
                            <img src="/static/img/avatars/profile-avatar-small.png" alt="" />
                            <div className={`${styles.CardInsideCon}`}>
                                <p className={`${styles.InsideConName}`}>{item.user_name} <span>{item.role} @{item.property}</span></p>
                                <Dropdown className={`${styles.DropDownMain}`}>
                                    <Dropdown.Toggle id="dropdown-basic" className={`${styles.FilesUpCardDD} ${styles.NoPadd}`}><MoreVertical /></Dropdown.Toggle>

                                    <Dropdown.Menu className={`${styles.ActionsBUList} ActionsBUList`}>
                                        <Dropdown.Item className={`${styles.EditActionsBUListItem}`} onClick={(e) => { setNoteEditFormCard(true); setNoteEditId(item?.id); }}><Edit2 /> Edit</Dropdown.Item>
                                        <Dropdown.Item className={`${styles.EditActionsBUListItem} ${styles.Delete}`} onClick={(e) => { setDeleteModal(true); setDeleteId(item?.id); }}><Trash2 /> Delete</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <p className={`${styles.InsideConDate}`}>{item.time_str}</p>
                                <p className={`${styles.InsideConText}`}>
                                    {noteEditFormCard && noteEditId && noteEditId === item?.id && (<div className={`${styles.AddCommDiv}`}>
                                        <form id="myForm" onSubmit={handleSubmit(onUpdate)}>
                                            <div className={`${styles.formGroup}`}>
                                                <textarea
                                                    type="text"
                                                    className={`${styles.ReferCommentArea}`}
                                                    name="note"
                                                    ref={register}
                                                    placeholder="Add text here..."
                                                    defaultValue={item.note}
                                                ></textarea>
                                                {errors.note && (<p className={`${styles.ErrorM}`}>{errors.note.message}</p>)}
                                            </div>
                                            <Button onClick={(e) => { setNoteEditFormCard(false); setNoteEditId(0); }} className={`${styles.ButtonVoiletLine}`}
                                            >Cancel</Button>
                                            <Button type="submit" className={`${styles.ButtonVoiletSolid}`}>Update</Button>
                                        </form>
                                    </div>)}
                                    {(noteEditId !== item?.id) && item.note}
                                </p>
                            </div>
                        </div>
                    </div>) : null;
                })}
            </div>

            {noteList.length > 0 && (<div className="Pagination TablePaginationNew">
                <TablePagination
                    count={noteList.length}
                    color="primary"
                    page={page}
                    rowsPerPage={perPage}
                    onPageChange={(e, v) => setPage(v)}
                    rowsPerPageOptions={[
                        { label: '5', value: 5 },
                        { label: '10', value: 10 },
                        { label: '15', value: 15 },
                        { label: 'All', value: -1 },
                    ]}
                    onRowsPerPageChange={(e) => {
                        setPage(0);
                        setPerPage(e.target.value);
                    }}
                    labelRowsPerPage={'Notes per Page:'}
                />
            </div>)}
        </>}

        {!noteFormCard && noteList?.length === 0 && <div className={`${styles.NotesRow}`}>
            <div className={`${styles.NoDataMain}`}>
                <div>
                    <img src="/static/img/no-content.png" alt="" className={`${styles.NoDataIMG}`} />
                    <p className={`${styles.NoDataText}`}>No Notes Found</p>
                    <button className={`${styles.AddLeaseBU}`} onClick={(e) => setNoteFormCard(true)}><Plus /> Add New</button>
                </div>
            </div>
        </div>}

        <Dialog
            open={deleteModal}
            fullWidth
            maxWidth="sm"
            onClose={(e) => setDeleteModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp"
        >
            <DialogTitle id="alert-dialog-title">Delete Note<button onClick={(e) => setDeleteModal(false)}><X /></button></DialogTitle>
            <DialogContent>
                <DialogContentText className="ModalFormGroup" style={{ marginBottom: "0px", padding: "25px 0" }}>
                    <label className="PopupBodyText">Are you sure you want to delete this item?</label>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button className="CancelPopupBU" onClick={(e) => delConfirm()}>Delete</Button>
                <Button onClick={(e) => setDeleteModal(false)} className="SubmitPopupBU">Cancel</Button>
            </DialogActions>
        </Dialog>
    </>;
}
export default NotesTab;