import { Box, Grid } from "@material-ui/core";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
function ProspectsSearch(props) {
  return (
    <>
      <Grid item xs={12}>
        <Box p={2}>
          <Typeahead
            id="search-tenants"
            labelKey={(option) =>
              option.full_name ? option.full_name : option.phone
            }
            filterBy={props.filterByCallback}
            options={props.prospectTypeHeadList}
            placeholder="Search prospect"
            clearButton={true}
            flip={true}
            onChange={props.setSelected}
            renderMenuItemChildren={(option) => {
              return option?.full_name ? (
                <div>{option?.full_name}</div>
              ) : (
                <div>{option?.phone}</div>
              );
            }}
          />
        </Box>
      </Grid>
    </>
  );
}

export default ProspectsSearch;
