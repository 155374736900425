import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, Slide, Slider, Snackbar } from "@mui/material";
import styles from "../../smartdevicedetails.module.css";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import SmartDeviceDetailsService from "../../smartdevicedetails.service";
import { toast } from "react-smart-toaster";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from 'axios';
import { ChevronDown, Edit, Eye, EyeOff, Plus, Trash2, X } from "react-feather";
import OTPInput from "react-otp-input";
import dayjs from "dayjs";

const schema = yup.object().shape({
    key_type: yup.string().required("This field is Required"),
    password: yup.string().required("This field is Required").length(6, "This field is Required"),
    tenant_info_id: yup.string().when("key_type", (key_type) => {
        if (key_type && key_type === 3)
            return yup.string().required("This field is Required");
        else return yup.string();
    }),
});

const schemaUpdate = yup.object().shape({
    password: yup.string().required("This field is Required").length(6, "This field is Required"),
});

const TTDoorLockcontroller = ({ ttLockDeviceData, deviceDetails, setLoading }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [showUnlockIcon, setShowUnlockIcon] = useState(false);
    const [allPasswordList, setAllPasswordList] = useState([]);
    const [passwordList, setPasswordList] = useState([]);
    const [addPinState, setAddPinState] = useState(false);
    const [allTenantList, setAllTenantList] = useState([]);
    const [tenantList, setTenantList] = useState([]);
    const [isTemp, setIsTemp] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(0);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [resetPinState, setResetPinState] = useState(false);
    const [resetPinData, setResetPinData] = useState(null);
    const [isMasterKey, setIsMasterKey] = useState(false);
    const [isTenantKey, setIsTenantKey] = useState(false);
    const [unlockValue, setUnloackValue] = useState(0);

    const { handleSubmit, control, errors } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: {
            'key_type': '',
            'password': '',
            'tenant_info_id': '',
        }
    });

    const { handleSubmit: handleSubmitUpdate, control: controlUpdate, errors: errorsUpdate } = useForm({
        resolver: yupResolver(schemaUpdate),
        mode: "onChange",
        defaultValues: {
            'password': '',
        }
    });

    useEffect(() => {
        if (deviceDetails?.lock_passwords) {
            setAllPasswordList(deviceDetails?.lock_passwords);
        }
    }, [deviceDetails]);

    useEffect(() => {
        const getTenantList = async () => {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            axios.get(`${process.env.REACT_APP_DEVICE_API_URL}v1/device-tenant-list/${deviceDetails?.apt_id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                setAllTenantList(response?.data?.data);
            }).catch((error) => {
                if (typeof error.response !== "undefined")
                    toast.error(error.response.data.message);
            });
        }

        if (deviceDetails?.apt_id)
            getTenantList();
    }, [deviceDetails?.apt_id, getAccessTokenSilently]);

    useEffect(() => {
        if (allPasswordList.length) {
            let tenantListIds = [];
            let passwordListTemp = allPasswordList?.map(i => {
                if (i?.password_id === '')
                    return null;

                if (i?.tenant_info_id > 0) {
                    tenantListIds.push(i?.tenant_info_id);
                }

                if (i?.type === 1) {
                    setIsMasterKey(true);
                }
                if (i?.type === 2) {
                    setIsTenantKey(true);
                }

                return { ...i, p_type: "password", p_val: '000000' };
            });

            setPasswordList(passwordListTemp);

            let tList = allTenantList.filter(i => tenantListIds.indexOf(i?.id) === -1);

            setTenantList(tList);
        } else {
            setTenantList(allTenantList);
        }
    }, [allPasswordList, allTenantList]);

    const onDoorLockChange = async (e, v) => {
        if (v !== 10) {
            return;
        }

        setShowUnlockIcon(true);

        try {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            const headers = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            const response = await SmartDeviceDetailsService.OnUnlockTTLock(deviceDetails?.deviceID, headers);

            if (response.status === 200) {
                setSnackbarMsg(response?.data?.message);
                setSnackbarOpen(true);

                setTimeout(() => {
                    setShowUnlockIcon(false);
                    setUnloackValue(0);
                }, 4000);
            } else {
                setShowUnlockIcon(false);
                toast.error("Door Lock Device Error");
                setUnloackValue(0);
            }
        } catch (error) {
            setShowUnlockIcon(false);
            toast.error(error?.response?.data?.message);
        }
    }

    const onSubmit = async (data) => {
        setLoading(true);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
        let postData = data;
        postData = { ...postData, device_id: deviceDetails?.deviceID };

        axios.post(`${process.env.REACT_APP_DEVICE_API_URL}v1/ttlock/add-passcode`, postData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setLoading(false);
            setSnackbarMsg(response?.data?.message);
            setSnackbarOpen(true);

            let newData = response?.data?.data;
            newData = { ...newData, p_type: "password", p_val: '000000' };

            setPasswordList(prev => {
                let newArr = prev;
                newArr.push(newData);
                return newArr;
            });

            if (parseInt(newData?.type) === 1) {
                setIsMasterKey(true);
            }
            if (parseInt(newData?.type) === 2) {
                setIsTenantKey(true);
            }

            if (parseInt(newData?.type) === 3) {
                let tenantInfoId = parseInt(newData?.tenant_info_id);
                setTenantList(prev => {
                    return prev.filter(i => i?.id !== tenantInfoId);
                });
            }

            setAddPinState(false);
            setIsTemp(false);
        }).catch((error) => {
            setLoading(false);
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
        });
    }

    const viewPassword = async (passItem) => {
        if (passItem?.p_type !== "password") {
            setPasswordList(prev => {
                let newArr = prev?.map(item => {
                    if (item?.id === passItem?.id) {
                        let p_type = "password";
                        let p_val = "000000";
                        return { ...item, p_type: p_type, p_val: p_val };
                    }
                    return item;
                });
                return newArr;
            });
        } else {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            axios.get(`${process.env.REACT_APP_DEVICE_API_URL}v1/doorlock/decrypt-password/${passItem?.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                setPasswordList(prev => {
                    let newArr = prev?.map(item => {
                        if (item?.id === passItem?.id) {
                            let p_type = "password";
                            let p_val = "000000";
                            if (item?.p_type === "password") {
                                p_type = "text";
                                p_val = response?.data?.data;
                            }
                            return { ...item, p_type: p_type, p_val: p_val };
                        }
                        return item;
                    });
                    return newArr;
                });
            });
        }
    }

    const deletePassword = async () => {
        setLoading(true);
        setDeleteModalOpen(false);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        axios.delete(`${process.env.REACT_APP_DEVICE_API_URL}v1/ttlock/delete-passcode/${deleteId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setLoading(false);
            setSnackbarMsg(response?.data?.message);
            setSnackbarOpen(true);

            let deletedPassword = passwordList?.filter(i => i?.id === deleteId);

            setPasswordList(prev => {
                let newArr = prev?.filter(i => i?.id !== deleteId);
                return newArr;
            });

            if (deletedPassword?.length > 0) {
                if (parseInt(deletedPassword[0]?.type) === 1) {
                    setIsMasterKey(false);
                }
                if (parseInt(deletedPassword[0]?.type) === 2) {
                    setIsTenantKey(false);
                }

                if (parseInt(deletedPassword[0]?.type) === 3) {
                    let tenantInfoId = parseInt(deletedPassword[0]?.tenant_info_id);
                    let tList = allTenantList.filter(i => i?.id === tenantInfoId);
                    setTenantList(prev => {
                        return prev.concat(tList);
                    });
                }
            }


            setDeleteId(0);
        }).catch((error) => {
            setLoading(false);
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
        });
    }

    const onUpdate = async (data) => {
        setLoading(true);
        setResetPinState(false);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
        let postData = data;

        axios.post(`${process.env.REACT_APP_DEVICE_API_URL}v1/ttlock/reset-passcode/${resetPinData?.id}`, postData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setLoading(false);

            setSnackbarMsg(response?.data?.message);
            setSnackbarOpen(true);

            setResetPinData(false);
        }).catch((error) => {
            setLoading(false);
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
        });
    }

    return (<>
        <div className={`${styles.cardUnderS}`}>
            {deviceDetails?.online === 0 && (<div className={`${styles.OfflineSection}`}>
                <img src="/static/img/offline.svg" width="60px" height="60px" alt="" />
                <p>Device Is Offline</p>
            </div>)}
            {/* <p className={`${styles.ControllTitle}`} onClick={OpenController}>Control Panel<ChevronDown/></p> */}
            <div className={`${styles.WidgetIndi} ${styles.Doorlock}`}>
                <div className={`${styles.HeadIcon}`}>
                    <img src="/static/img/battery.png" alt="" />
                </div>
                <div>
                    <p className={`${styles.BatteryT}`}>{ttLockDeviceData?.electric_quantity}%</p>
                    <p className={`${styles.BatteryTT}`}>Battery</p>
                </div>
            </div>
            <div className={`${styles.WidgetIndi}`}>
                <p className={`${styles.WidgetT}`}>Unlock Door</p>
                {!showUnlockIcon && (<div className="DoorLockWidget">
                    <Slider
                        className="DoorLock"
                        value={unlockValue}
                        aria-labelledby="discrete-slider"
                        valueLabelDisplay="off"
                        step={1}
                        marks
                        min={0}
                        max={10}
                        onChange={(e, v) => setUnloackValue(v)}
                        onChangeCommitted={onDoorLockChange}
                    />
                    <button className="DoorlockSBU">
                        <img src="/static/img/lock-line.svg" alt="" />
                    </button>
                </div>)}
                {showUnlockIcon && (<div className={`${styles.DoorLockedIcon} ${styles.Green}`}>
                    <img src="/static/img/lock-dash.svg" width="28px" height="28px" alt="" />
                </div>)}
            </div>

            <div className={`${styles.WidgetIndi}`}>
                {!addPinState && <p className={`${styles.WidgetT}`}>
                    Pin Codes
                    {passwordList?.length > 0 && !resetPinState && <button onClick={(e) => setAddPinState(true)}><Plus /></button>}
                </p>}
                {!passwordList?.length > 0 && !addPinState && !resetPinState && (!isMasterKey || !isTenantKey || tenantList?.length > 0) &&
                    <div className={`${styles.NoPinSec}`}>
                        <div>
                            <img src="/static/img/noDoorPin.svg" alt="" />
                            <p>No Pin Codes are Available</p>
                            <button onClick={(e) => setAddPinState(true)}>Create Pin</button>
                        </div>
                    </div>
                }
                {addPinState && <div className={`${styles.Full}`}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={`${styles.PinTypeSec} FormGroup PinTypeSec`}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Type*</InputLabel>
                                <Controller
                                    name="key_type"
                                    control={control}
                                    render={(field) => (
                                        <Select
                                            {...field}
                                            label="Type*"
                                            onChange={(e) => {
                                                field.onChange(e);

                                                if (e.target.value === 3)
                                                    setIsTemp(true);
                                                else
                                                    setIsTemp(false);
                                            }}
                                        >
                                            {!isMasterKey && <MenuItem value={1}>Master Pin</MenuItem>}
                                            {!isTenantKey && <MenuItem value={2}>Tenant Pin</MenuItem>}
                                            {tenantList?.length > 0 && <MenuItem value={3}>Temp Pin Code</MenuItem>}
                                        </Select>
                                    )}
                                />
                            </FormControl>
                            {errors?.key_type && (<p className={`${styles.ErrorM}`}>{errors?.key_type?.message}</p>)}
                        </div>
                        {isTemp && <div className={`${styles.PinTypeSec} FormGroup PinTypeSec`}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Tenant*</InputLabel>
                                <Controller
                                    name="tenant_info_id"
                                    control={control}
                                    render={(field) => (
                                        <Select
                                            {...field}
                                            label="Tenant*"
                                            onChange={(e) => {
                                                field.onChange(e);
                                            }}
                                        >
                                            {tenantList?.map((item) => {
                                                return <MenuItem key={item?.id} value={item?.id}>{item?.full_name}</MenuItem>
                                            })}
                                        </Select>
                                    )}
                                />
                            </FormControl>
                            {errors?.tenant_info_id && (<p className={`${styles.ErrorM}`}>{errors?.tenant_info_id?.message}</p>)}
                        </div>}
                        <div className={`${styles.PinArea}`}>
                            <FormControl fullWidth>
                                <label id="demo-simple-select-label">Pin Code*</label>
                                <Controller
                                    name="password"
                                    control={control}
                                    render={(field) => (
                                        <OTPInput
                                            {...field}
                                            numInputs={6}
                                            renderSeparator={<span>-</span>}
                                            renderInput={(props) => <input {...props} />}
                                        />
                                    )}
                                />
                            </FormControl>
                        </div>
                        {errors?.password && (<p className={`${styles.ErrorM}`}>{errors?.password?.message}</p>)}
                        <div className={`${styles.ButtonAreaPinCode}`}>
                            <button type="button" onClick={(e) => setAddPinState(false)} className={`${styles.ButtonVoiletLine}`}>Cancel</button>
                            <button type="submit" className={`${styles.ButtonVoiletSolid}`}>Add</button>
                        </div>
                    </form>
                </div>}
                {!addPinState && !resetPinState && passwordList?.length > 0 && <div className={`${styles.PinCodeListArea}`}>
                    {passwordList?.map(i => {
                        return (<div className={`${styles.PinCodeList}`}>
                            <div className={`${styles.PinCodeListTitleSec}`}>
                                <p className={`${styles.PinCodeListTitle}`}>{i?.title}</p>
                                {i?.type !== 1 && <div>
                                    <button onClick={(e) => { setResetPinState(true); setResetPinData(i); }}><Edit /></button>
                                    <button onClick={(e) => { setDeleteModalOpen(true); setDeleteId(i?.id); }}><Trash2 /></button>
                                </div>}
                            </div>
                            {i?.expiration_time && <p className={`${styles.PinCodeListDate}`}>Valid till: {dayjs(i?.expiration_time).format('MM/DD/YYYY')}</p>}
                            <div className={`${styles.PincodeListPinArea}`}>
                                <div className={`${styles.PinArea}`}>
                                    <OTPInput
                                        value={i?.p_val}
                                        numInputs={6}
                                        renderSeparator={<span>-</span>}
                                        renderInput={(props) => <input {...props} type={i?.p_type} disabled />}
                                    />
                                </div>
                                <button onClick={(e) => viewPassword(i)} className={`${styles.PincodeViewBU}`}>{i?.p_type === "password" ? <Eye /> : <EyeOff />}</button>
                            </div>
                        </div>);
                    })}
                </div>}
                {resetPinState && <div className={`${styles.Full}`}>
                    <form onSubmit={handleSubmitUpdate(onUpdate)}>
                        <p className={`${styles.PinCodeListTitle}`}>{resetPinData?.title}</p>
                        <div className={`${styles.PinArea}`}>
                            <FormControl fullWidth>
                                <Controller
                                    name="password"
                                    control={controlUpdate}
                                    render={(field) => (
                                        <OTPInput
                                            {...field}
                                            numInputs={6}
                                            renderSeparator={<span>-</span>}
                                            renderInput={(props) => <input {...props} />}
                                        />
                                    )}
                                />
                            </FormControl>
                        </div>
                        {errorsUpdate?.password && (<p className={`${styles.ErrorM}`}>{errorsUpdate?.password?.message}</p>)}
                        <div className={`${styles.ButtonAreaPinCode}`}>
                            <button type="button" onClick={(e) => { setResetPinState(false); setResetPinData(null); }} className={`${styles.ButtonVoiletLine}`}>Cancel</button>
                            <button type="submit" className={`${styles.ButtonVoiletSolid}`}>Update</button>
                        </div>
                    </form>
                </div>}
            </div>

        </div>

        <Dialog
            open={deleteModalOpen}
            fullWidth
            maxWidth="sm"
            onClose={(e) => setDeleteModalOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp"
        >
            <DialogTitle id="alert-dialog-title">Are you sure you want to delete the pin code? <button onClick={(e) => setDeleteModalOpen(false)}><X /></button></DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div className="ModalFormGroup">
                        <label className="PopupBodyText">The pin code will be removed immediately. You can't undo this action.</label>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={(e) => deletePassword()} className="CancelPopupBU">Delete</Button>
                <Button onClick={(e) => setDeleteModalOpen(false)} className="SubmitPopupBU">Cancel</Button>
            </DialogActions>
        </Dialog>

        <Snackbar
            open={snackbarOpen}
            onClose={(e) => setSnackbarOpen(false)}
            TransitionComponent={Slide}
            message={snackbarMsg}
            autoHideDuration={10000}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            action={
                <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={(e) => setSnackbarOpen(false)}
                >
                    <X />
                </IconButton>
            }
        />

    </>);
}

export default TTDoorLockcontroller;