import React from "react";
import styles from './home.module.css';
import Helmet from "react-helmet";
import { ArrowRight, Calendar } from 'react-feather';
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import Header from '../../components/HeaderPublic'
import Footer from '../../components/FooterPublic';

class Home extends React.Component {
  constructor({ match, ...props }) {
    super(...arguments);
  }


  render() {
    return (<React.Fragment>
      <Helmet title="Home" />

      <Header />

      {/* <ScrollContainer>
        <ScrollPage>
          <Animator animation={batch(Fade(), Sticky())}>
            <span style={{ fontSize: "30px" }}>Let me show you scroll animation 😀</span>
          </Animator>
        </ScrollPage>
        <ScrollPage>
          <Animator animation={ZoomInScrollOut}>
            <span style={{ fontSize: "40px" }}>I'm FadeUpScrollOut ✨</span>
          </Animator>
        </ScrollPage>
        <ScrollPage>
          <Animator animation={FadeUp}>
            <span style={{ fontSize: "40px" }}>I'm FadeUp ⛅️</span>
          </Animator>
        </ScrollPage>
        <ScrollPage>
          <div>
            <span style={{ fontSize: "40px" }}>
              <Animator animation={batch(Fade(), Sticky(), FadeIn())}>Hello Guys 👋🏻</Animator>
              <Animator animation={MoveIn(1000, 0)}>Nice to meet you 🙋🏻‍♀️</Animator>
              - I'm Dante Chun -
              <Animator animation={MoveOut(1000, 0)}>Good bye ✋🏻</Animator>
              <Animator animation={MoveOut(-1000, 0)}>See you 💛</Animator>
            </span>
          </div>
        </ScrollPage>
        <ScrollPage>
          <Animator animation={batch(Fade(), Sticky())}>
            <span style={{ fontSize: "40px" }}>Done</span>
            <br />
            <span style={{ fontSize: "30px" }}>
              There's FadeAnimation, MoveAnimation, StickyAnimation, ZoomAnimation
            </span>
          </Animator>
        </ScrollPage>
      </ScrollContainer> */}

      <div className="WhiteBG">
        <section id="#HomeSec">
          <div className={`${styles.BannerArea}`}>
            <div className={`${styles.Container}`}>
              <div className={`${styles.Row}`}>

                <div className={`${styles.BannerTextArea}`}>
                  <Fade bottom>
                    <h2 className={`${styles.BannerTitleText}`}>Complete property management for smart residential buildings.</h2>
                    <p className={`${styles.BannerConText}`}>Propertydek is a comprehensive platform that manages your smart locks, thermostats & dimmers to ensure an enhanced tenant experience while maximizing your Net Operating Income.</p>
                    {/* <Linkto to="" className={`${styles.LearnMBUBanner}`}>Learn More <ArrowRight /></Linkto> */}
                    <div className={`${styles.ScrollDiv}`}>
                      <div className={`${styles.scrollReminderTick}`}></div>
                    </div>
                  </Fade>

                </div>

                <div className={`${styles.BannerIMGArea}`}>
                  <Fade><img src="/static/img/HomePageBannerIMG1.png" alt="" className={`${styles.BannerMainIMG}`} /></Fade>
                  <Fade right delay={700}><img src="/static/img/HomePageBannerIMG2.png" alt="" className={`${styles.BannerMainIMG2}`} /></Fade>
                  <Bounce top delay={1000}><img src="/static/img/HomePageBannerIMG3.png" alt="" className={`${styles.BannerMainIMG3}`} /></Bounce>
                </div>

              </div>
            </div>
          </div>
        </section>

        <div className={`${styles.WelcomeArea}`}>
          <div className={`${styles.Container} ${styles.PositionRelative}`}>
            <div className={`${styles.WelcomRow}`}>

              <Fade left>
                <div className={`${styles.WelcomeIMGSec}`}>
                  <img src="/static/img/HOME-PAGE-welcomImg.png" alt="" />
                </div>
              </Fade>

              <div className={`${styles.WelcomeConSec}`}>
                <p className={`${styles.WelcomSmallHead}`}>Welcome To</p>
                <p className={`${styles.WelcomBigHead}`}>Propertydek</p>
                <p className={`${styles.BannerConText}`}>An automated property management platform for residential buildings of any size. It's easy-to-use platform simplifies the process of running your business and keeps you organized, so you can get back to focusing on what really matters.</p>
                {/* <Linkto href="" className={`${styles.LearnMLink}`}>Learn More <ArrowRight /></Linkto> */}
              </div>

            </div>
          </div>
        </div>

        <section id="#features" className={`${styles.Padd40}`}>
          <div className={`${styles.features}`}>
            <div className={`${styles.Container}`}>

              <div className={`${styles.featuresTitleSec}`}>
                <p className={`${styles.featuresTitleB}`}>It’s time to connect  your building.</p>
                <p className={`${styles.featuresTitleS}`}>Imagine managing and monitoring your buildings remotely from the convenience of your own home, at any time of day. Propertydek helps to remove tedious manual labour so that you can have more time to focus on your core activities and have an easier time of keeping your buildings up and running.</p>
                {/* <Linkto href="" className={`${styles.LearnMLink}`}>Learn More <ArrowRight /></Linkto> */}
              </div>

              <div className={`${styles.featuresGraSec}`}>
                <div className={`${styles.featuresGraImgSec}`}>
                  <img src="/static/img/featuresGra.jpg" alt="" className={`${styles.featuresGraImg}`} />
                </div>
                <Bounce left duration={500}>
                  <div className={`${styles.featuresSmallSec} ${styles.IconSec1}`}>
                    <div className={`${styles.IconSec}`}><img src="/static/img/featuresGraIcon1.png" alt="" /></div>
                    <p className={`${styles.featuresSmallTitle}`}>Tenant app</p>
                  </div>
                </Bounce>
                <Bounce left duration={1100}>
                  <div className={`${styles.featuresSmallSec} ${styles.IconSec2}`}>
                    <div className={`${styles.IconSec}`}><img src="/static/img/featuresGraIcon2.png" alt="" /></div>
                    <p className={`${styles.featuresSmallTitle}`}>Core property management tools</p>
                  </div>
                </Bounce>
                <Bounce right duration={800}>
                  <div className={`${styles.featuresSmallSec} ${styles.IconSec3}`}>
                    <div className={`${styles.IconSec}`}><img src="/static/img/featuresGraIcon3.png" alt="" /></div>
                    <p className={`${styles.featuresSmallTitle}`}>Building automation & remote monitoring</p>
                  </div>
                </Bounce>
                <Bounce right duration={1400}>
                  <div className={`${styles.featuresSmallSec} ${styles.IconSec4}`}>
                    <div className={`${styles.IconSec}`}><img src="/static/img/featuresGraIcon4.png" alt="" /></div>
                    <p className={`${styles.featuresSmallTitle}`}>Smart devices management</p>
                  </div>
                </Bounce>
              </div>

              <div className={`${styles.featuresTitleSec}`}>
                <p className={`${styles.featuresTitleB}`}>Instant visibility into the inner workings of your building.</p>
              </div>

              <Fade bottom>
                <div className={`${styles.featuresCardSec}`}>
                  <div className={`${styles.featuresCardRow}`}>

                    <div className={`${styles.featuresCard}`}>
                      <div className={`${styles.featuresCardUnder}`}>
                        <div className={`${styles.featuresCardImgSec}`}>
                          <img src="/static/img/featuresCardImg1.jpg" alt="" />
                          <p className={`${styles.featuresCardImgText}`}>Streamline day-to-day operations</p>
                        </div>
                        <div className={`${styles.featuresCardBodySec}`}>
                          <div className={`${styles.featuresCardBodyList}`}>
                            <img src="/static/img/listIcon.jpg" alt="" />
                            <p className={`${styles.ListText}`}>Manage maintenance service requests.</p>
                          </div>
                          <div className={`${styles.featuresCardBodyList}`}>
                            <img src="/static/img/listIcon.jpg" alt="" />
                            <p className={`${styles.ListText}`}>Automatically remind tenants when rent is due.</p>
                          </div>
                          <div className={`${styles.featuresCardBodyList}`}>
                            <img src="/static/img/listIcon.jpg" alt="" />
                            <p className={`${styles.ListText}`}>On-board new tenants seamlessly & digitally sign leases.</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={`${styles.featuresCard}`}>
                      <div className={`${styles.featuresCardUnder}`}>
                        <div className={`${styles.featuresCardImgSec}`}>
                          <img src="/static/img/featuresCardImg2.jpg" alt="" />
                          <p className={`${styles.featuresCardImgText}`}>Centralize Building Security</p>
                        </div>
                        <div className={`${styles.featuresCardBodySec}`}>
                          <div className={`${styles.featuresCardBodyList}`}>
                            <img src="/static/img/listIcon.jpg" alt="" />
                            <p className={`${styles.ListText}`}>Manage keyless building access.</p>
                          </div>
                          <div className={`${styles.featuresCardBodyList}`}>
                            <img src="/static/img/listIcon.jpg" alt="" />
                            <p className={`${styles.ListText}`}>Reset apartment smart door locks and key fobs.</p>
                          </div>
                          <div className={`${styles.featuresCardBodyList}`}>
                            <img src="/static/img/listIcon.jpg" alt="" />
                            <p className={`${styles.ListText}`}>Track visitors, prevent unauthorized entries, & much more!</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={`${styles.featuresCard}`}>
                      <div className={`${styles.featuresCardUnder}`}>
                        <div className={`${styles.featuresCardImgSec}`}>
                          <img src="/static/img/featuresCardImg3.jpg" alt="" />
                          <p className={`${styles.featuresCardImgText}`}>Take Control</p>
                        </div>
                        <div className={`${styles.featuresCardBodySec}`}>
                          <div className={`${styles.featuresCardBodyList}`}>
                            <img src="/static/img/listIcon.jpg" alt="" />
                            <p className={`${styles.ListText}`}>Manage the energy consumption for each unit.</p>
                          </div>
                          <div className={`${styles.featuresCardBodyList}`}>
                            <img src="/static/img/listIcon.jpg" alt="" />
                            <p className={`${styles.ListText}`}>Track daily, weekly, & monthly water usage for each unit.</p>
                          </div>
                          <div className={`${styles.featuresCardBodyList}`}>
                            <img src="/static/img/listIcon.jpg" alt="" />
                            <p className={`${styles.ListText}`}>Supervise & control all smart devices from a single cockpit.</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={`${styles.featuresCard}`}>
                      <div className={`${styles.featuresCardUnder}`}>
                        <div className={`${styles.featuresCardImgSec}`}>
                          <img src="/static/img/featuresCardImg4.jpg" alt="" />
                          <p className={`${styles.featuresCardImgText}`}>Enhanced Tenant experience</p>
                        </div>
                        <div className={`${styles.featuresCardBodySec}`}>
                          <div className={`${styles.featuresCardBodyList}`}>
                            <img src="/static/img/listIcon.jpg" alt="" />
                            <p className={`${styles.ListText}`}>Smarter & personalized living.</p>
                          </div>
                          <div className={`${styles.featuresCardBodyList}`}>
                            <img src="/static/img/listIcon.jpg" alt="" />
                            <p className={`${styles.ListText}`}>Instant chat & communication channel.</p>
                          </div>
                          <div className={`${styles.featuresCardBodyList}`}>
                            <img src="/static/img/listIcon.jpg" alt="" />
                            <p className={`${styles.ListText}`}>Control home systems remotely.</p>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </Fade>

            </div>
          </div>
        </section>

        <div className={`${styles.featuresFull}`}>
          <img src="/static/img/featuresFullImg.jpg" alt="" />
          <div className={`${styles.Container} ${styles.Height100}`}>
            <div className={`${styles.featuresFullRow} ${styles.Height100}`}>
              <Fade left>
                <div className={`${styles.featuresFullCon}`}>
                  <p className={`${styles.featuresFullTitle}`}>Convenience that matters.</p>
                  <p className={`${styles.featuresFullText}`}>Tenants can set the temperature, control lights, locks, and amenities from anywhere, anytime and with any device.</p>
                  {/* <Linkto href="" className={`${styles.featuresFullBU}`}>Learn More <ArrowRight /></Linkto> */}
                </div>
              </Fade>
            </div>
          </div>
        </div>

        <section id="#blogs" className={`${styles.Padd40}`}>
          <div className={`${styles.OurBlog}`}>
            <div className={`${styles.Container}`}>

              <div className={`${styles.BlogTitleSec}`}>
                <p className={`${styles.BlogTitleSmall}`}>Our blog</p>
                <p className={`${styles.BlogTitleBig}`}>Smart Apartment Building Articles.</p>
              </div>

              <Fade bottom>
                <div className={`${styles.OurBlogRow}`}>

                  <div className={`${styles.OurBlogCard}`}>
                    <div className={`${styles.OurBlogCardUnder}`}>
                      <img src="/static/img/blogImgnew1.png" alt="" className={`${styles.BlogImg}`} />
                      <div className={`${styles.OurBlogCon}`}>
                        <p className={`${styles.OurBlogDate}`}><Calendar /> DECEMBER 16TH, 2021</p>
                        <p className={`${styles.OurBlogText}`}>15 Smart Apartment Building Amenities Needed to Sustain Demand</p>
                        <a href="http://connectedremag.com/smart-buildings/proptech/15-smart-apartment-building-amenities-needed-to-sustain-demand/" target="_blank" rel="noreferrer" className={`${styles.LearnMLink}`}>Learn More <ArrowRight /></a>
                      </div>
                    </div>
                  </div>

                  <div className={`${styles.OurBlogCard}`}>
                    <div className={`${styles.OurBlogCardUnder}`}>
                      <img src="/static/img/blogImgnew2.jpg" alt="" className={`${styles.BlogImg}`} />
                      <div className={`${styles.OurBlogCon}`}>
                        <p className={`${styles.OurBlogDate}`}><Calendar /> JUNE 16TH, 2021</p>
                        <p className={`${styles.OurBlogText}`}>Top 9 Smart Apartment Trends of 2021</p>
                        <a href="https://draperandkramer.com/news-and-insights/top-9-smart-apartment-trends-of-2021/" target="_blank" rel="noreferrer" className={`${styles.LearnMLink}`}>Learn More <ArrowRight /></a>
                      </div>
                    </div>
                  </div>

                  <div className={`${styles.OurBlogCard}`}>
                    <div className={`${styles.OurBlogCardUnder}`}>
                      <img src="/static/img/blogImgnew3.jpg" alt="" className={`${styles.BlogImg}`} />
                      <div className={`${styles.OurBlogCon}`}>
                        <p className={`${styles.OurBlogDate}`}><Calendar /> SEPTEMBER 29TH, 2021</p>
                        <p className={`${styles.OurBlogText}`}>Why Smart Apartment Buildings Are A Smart Investment</p>
                        <a href="https://www.american-apartment-owners-association.org/property-management/why-smart-apartment-buildings-are-a-smart-investment/" rel="noreferrer" target="_blank" className={`${styles.LearnMLink}`}>Learn More <ArrowRight /></a>
                      </div>
                    </div>
                  </div>

                </div>
              </Fade>

            </div>
          </div>
        </section>
        <Footer />
      </div>

      {/* <div className={`${styles.footer}`}>
        <div className={`${styles.Container}`}>
          <div className={`${styles.FooterRow}`}>

            <div className={`${styles.FooterLogoSec}`}>
              <img src="/static/img/logo.svg" alt="logo" />
            </div>

            <div className={`${styles.FooterConSec}`}>
              <div className={`${styles.FooterConSecSmall}`}>
                <p className={`${styles.FootListTitle}`}>Company</p>
                <Linkto className={`${styles.FootListLink}`}>Blog</Linkto>
                <Linkto className={`${styles.FootListLink}`}>Contact</Linkto>
              </div>
              <div className={`${styles.FooterConSecSmall}`}>
                <p className={`${styles.FootListTitle}`}>Services</p>
                <Linkto className={`${styles.FootListLink}`}>e-learn App</Linkto>
                <Linkto className={`${styles.FootListLink}`}>My Portal App</Linkto>
                <Linkto className={`${styles.FootListLink}`}>Pedramp Tracker App</Linkto>
              </div>
              <div className={`${styles.FooterConSecSmall}`}>
                <p className={`${styles.FootListTitle}`}>Legal</p>
                <Linkto className={`${styles.FootListLink}`}>Privacy Policy</Linkto>
                <Linkto className={`${styles.FootListLink}`}>Terms & conditions</Linkto>
                <Linkto className={`${styles.FootListLink}`}>Cookies</Linkto>
              </div>
              <div className={`${styles.FooterConSecBig}`}>
                <div>
                  <a className={`${styles.FootListLinkBig}`} href="tel:17188254746">1-718-825-4746</a>
                  <a className={`${styles.FootListLinkBig}`} href="mailto:hello@propertydek.com">hello@propertydek.com</a>
                </div>
              </div>
            </div>

          </div>

          <div className={`${styles.LastFootRow}`}>
            <p className={`${styles.CopyRightText}`}>© 2022. Propertydek.com. All rights reserved.</p>
            <div className={`${styles.SocialIcon}`}>
              <a href="https://www.linkedin.com/company/fbrothers"><img src="/static/img/linkedin.png" alt="logo" /></a>
              <a href="https://twitter.com/tech4SMBs"><img src="/static/img/twitter.png" alt="logo" /></a>
              <a href="https://www.facebook.com/tech4smbs/"><img src="/static/img/facebook.png" alt="logo" /></a>
            </div>
          </div>
        </div>
      </div> */}

      {/* <ScrollContainer>
        <ScrollPage>
          <Animator animation={batch(Fade(), MoveOut(0, 200))}>
            <span style={{ fontSize: "30px" }}>Let me show you scroll animation 😀</span>
          </Animator>
        </ScrollPage>
      </ScrollContainer> */}

    </React.Fragment >);
  }
}


export default Home;