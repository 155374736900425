import { memo } from "react";
import { Star, Users } from "react-feather";

const TimeSlot = ({ TimeId, TimeSlot, GrapTimeId, SetGrapTimeId, isOpenHouse, isScheduled, no_of_schedule }) => {
  return (<li onClick={() => { SetGrapTimeId(TimeId); }} className={TimeId === GrapTimeId ? `TimeSelected TimeLI` : "TimeLI"}>
    <p className="AddTourTime">{TimeSlot}</p>
    {isOpenHouse === 1 && (<p className="AddTourOpenHouse"><Star /> Open House</p>)}
    {isOpenHouse === 0 && isScheduled === 1 && (<p className="AddTourScheduled"><Users /> <span className="ScheduledTag">Scheduled {(no_of_schedule > 1) && '+' + (no_of_schedule - 1)}</span></p>)}
  </li>
  );
};

export default memo(TimeSlot);
