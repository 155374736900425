
import { X } from "react-feather";
import styles from "../prospectdetails.module.css";
import { Button, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, TextField, MenuItem, Select, OutlinedInput, Box, Chip, Checkbox, ListItemText } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { ListManager } from "react-beautiful-dnd-grid";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { toast } from "react-smart-toaster";
import { useHistory } from "react-router-dom";

function sortList(list) {
    let listTemp = list.slice().sort((first, second) => first.order - second.order);
    listTemp = listTemp.map((i, index) => {
        return { ...i, index: index };
    });
    return listTemp;
}

function ListElement({ item: { id, title, index } }) {
    return (
        <Chip className={"DropListNew"} key={id} label={index + 1 + ". " + title} />
    );
}

function MoneyField(props) {
    return (
        <NumberFormat
            customInput={TextField}
            prefix=""
            displayType="input"
            type="text"
            thousandSeparator={true}
            allowNegative={false}
            decimalScale={2}
            fixedDecimalScale
            {...props}
        />
    );
}

const schema = yup.object().shape({
    lease_type: yup.string().required("This field is Required"),
    lease_term: yup.string().required("This field is Required"),
    security_amount: yup.string().required("This field is Required").test("non-zero-validate", "This field should be $1 or greater.", (value) => parseInt(value) > 0).test("max-4-digit", "Security Amount can not be more than 6 digits.", (value) => {
        if (typeof (value) !== 'undefined')
            return parseInt(value.replace(/,/g, "")) < 10000;
    }),
    rent: yup.string().required("This field is Required").test("non-zero-validate", "This field should be $1 or greater.", (value) => parseInt(value) > 0
    ).test("max-4-digit", "Rent can not be more than 6 digits.", (value) => {
        if (typeof (value) !== 'undefined')
            return parseInt(value.replace(/,/g, "")) < 10000;
    }),
    start_date: yup.date().required("This field is Required"),
    end_date: yup.date().required("This field is Required"),
    docume_ids: yup.array().required("This field is Required").nullable()
});

const LeaseForm = (props) => {
    const redirect = useHistory();
    const { getAccessTokenSilently } = useAuth0();
    const [LeaseDocumentOptions, setLeaseDocumentOptions] = useState([]);
    const [selectDocument, setSelectDocument] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectDocumentIds, setSelectDocumentIds] = useState([]);
    const [sortedLeaseDocuments, setSortedLeaseDocuments] = useState(sortList([]));

    const { handleSubmit, control, errors, setValue, getValues, clearErrors } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: {
            lease_type: "New",
            lease_term: "1 Year"
        }
    });

    useEffect(() => {
        if (props.leaseFormData) {
            if (props?.leaseFormData?.applicant_det?.rent) {
                setValue('security_amount', props?.leaseFormData?.applicant_det?.rent);
                setValue('rent', props?.leaseFormData?.applicant_det?.rent);
            }

            if (props?.leaseFormData?.lease_documents) {
                setLeaseDocumentOptions(props?.leaseFormData?.lease_documents);
            }
        }
    }, [props.leaseFormData, setValue]);

    useEffect(() => {
        let ids = selectDocument.map((i) => {
            return i.id;
        });

        let docs = selectDocument.map((i, index) => {
            return { ...i, order: index, index: index };
        });

        setSelectDocumentIds(ids);

        setSortedLeaseDocuments(docs);
    }, [selectDocument]);

    const onSubmit = async (data) => {
        let dIds = sortedLeaseDocuments?.map((i) => {
            return i.id;
        });

        let docume_ids = dIds.join();

        let postData = data;
        postData = { ...postData, rent: data.rent.replace(/,/g, "") };
        postData = { ...postData, security_amount: data.security_amount.replace(/,/g, "") };
        postData = { ...postData, start_date: dayjs(data.start_date).format('YYYY-MM-DD') };
        postData = { ...postData, end_date: dayjs(data.end_date).format('YYYY-MM-DD') };
        postData = { ...postData, docume_ids: docume_ids };

        props?.setLoading(true);
        props?.setAddLeaseModalOpen(false);

        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        axios.post(process.env.REACT_APP_APPLICANT_API_URL + "v1/prospects/" + props.applicant_id + "/move-in-tenant", postData, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            props?.setLoading(false);

            props.setSnackbarMsg(response.data.message);
            props.setSnackbarOpen(true);

            setTimeout(() => {
                redirect.push("/people/tenants/details/" + response?.data?.data?.id);
            }, 2000);
        }).catch((error) => {
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
            props?.setLoading(false);
        });
    }

    const selectDocuments = (event) => {
        const { target: { value } } = event;
        setSelectDocument(value);
        setValue("docume_ids", value);
    };

    const reorderList = (sourceIndex, destinationIndex) => {
        if (destinationIndex === sourceIndex) {
            return;
        }
        const list = sortedLeaseDocuments;

        if (destinationIndex === 0) {
            list[sourceIndex].order = list[0].order - 1;

            setSortedLeaseDocuments(sortList(list));
            return;
        }

        if (destinationIndex === list.length - 1) {
            list[sourceIndex].order = list[list.length - 1].order + 1;
            setSortedLeaseDocuments(sortList(list));
            return;
        }

        if (destinationIndex < sourceIndex) {
            list[sourceIndex].order = (list[destinationIndex].order + list[destinationIndex - 1].order) / 2;
            setSortedLeaseDocuments(sortList(list));
            return;
        }

        list[sourceIndex].order = (list[destinationIndex].order + list[destinationIndex + 1].order) / 2;
        setSortedLeaseDocuments(sortList(list));
    };

    return (<>
        <DialogTitle id="alert-dialog-title">Add Lease<button onClick={(e) => props.setAddLeaseModalOpen(false)}><X /></button></DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className={`${styles.AddTenantpopupBody}`}>
                        <div className={`${styles.AddTenantpopupBodyRow}`}>
                            <div className={`${styles.HalfSecHalf} FormGroup`}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Lease type*</InputLabel>
                                    <Controller
                                        name="lease_type"
                                        control={control}
                                        render={(field) => (
                                            <Select
                                                {...field}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Lease type*"
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                }}
                                            >
                                                <MenuItem value="New">New Lease</MenuItem>
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                                {errors.lease_type && (<p className={`${styles.ErrorM}`}>{errors.lease_type.message}</p>)}
                            </div>
                            <div className={`${styles.HalfSecHalf} FormGroup`}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Lease term*</InputLabel>
                                    <Controller
                                        name="lease_term"
                                        control={control}
                                        render={(field) => (
                                            <Select
                                                {...field}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Lease term*"
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                }}
                                            >
                                                <MenuItem value="6 Months">6 Months</MenuItem>
                                                <MenuItem value="1 Year">1 Year</MenuItem>
                                                <MenuItem value="2 Years">2 Years</MenuItem>
                                                <MenuItem value="3 Years">3 Years</MenuItem>
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                                {errors.lease_term && (<p className={`${styles.ErrorM}`}>{errors.lease_term.message}</p>)}
                            </div>
                            <div className={`${styles.HalfSecHalf} FormGroup`}>
                                <Controller
                                    name="security_amount"
                                    control={control}
                                    render={(field) => (
                                        <MoneyField
                                            {...field}
                                            id="outlined-basic"
                                            variant="outlined"
                                            label="Security Amount*"
                                            value={field.value ? field.value : ""}
                                        />
                                    )}
                                />
                                {errors.security_amount && (<p className={`${styles.ErrorM}`}>{errors.security_amount.message}</p>)}
                            </div>
                            <div className={`${styles.HalfSecHalf} FormGroup`}>
                                <Controller
                                    name="rent"
                                    control={control}
                                    render={(field) => (
                                        <MoneyField
                                            {...field}
                                            id="outlined-basic"
                                            variant="outlined"
                                            label="Monthly Rent*"
                                            value={field.value ? field.value : ""}
                                        />
                                    )}
                                />
                                {errors.rent && (<p className={`${styles.ErrorM}`}>{errors.rent.message}</p>)}
                            </div>
                            <div className={`${styles.HalfSecHalf} FormGroup`}>
                                <Controller
                                    name="start_date"
                                    control={control}
                                    render={(field) => (
                                        <DatePicker
                                            {...field}
                                            label="Lease start date*"
                                            renderInput={(params) => (<TextField {...params} />)}
                                            onChange={(e) => {
                                                field.onChange(e);

                                                clearErrors();
                                                if (getValues("lease_term") === "6 Months") {
                                                    setValue("end_date", dayjs(e).add(6, "months").subtract(1, "day"));
                                                } else if (getValues("lease_term") === "1 Year") {
                                                    setValue("end_date", dayjs(e).add(1, "year").subtract(1, "day"));
                                                } else if (getValues("lease_term") === "2 Years") {
                                                    setValue("end_date", dayjs(e).add(2, "years").subtract(1, "day"));
                                                } else if (getValues("lease_term") === "3 Years") {
                                                    setValue("end_date", dayjs(e).add(3, "years").subtract(1, "day"));
                                                } else {
                                                    setValue("end_date", dayjs(e).add(1, "year").subtract(1, "day"));
                                                }
                                            }}
                                        />
                                    )}
                                />
                                {errors.start_date && (<p className={`${styles.ErrorM}`}>{errors.start_date.message}</p>)}
                            </div>
                            <div className={`${styles.HalfSecHalf} FormGroup`}>
                                <Controller
                                    name="end_date"
                                    control={control}
                                    render={(field) => (
                                        <DatePicker
                                            {...field}
                                            label="Lease end date*"
                                            renderInput={(params) => (<TextField {...params} />)}
                                            onChange={(e) => {
                                                field.onChange(e);

                                                clearErrors();
                                                if (getValues("lease_term") === "6 Months") {
                                                    setValue("start_date", dayjs(e).subtract(6, "months").add(1, "day"));
                                                } else if (getValues("lease_term") === "1 Year") {
                                                    setValue("start_date", dayjs(e).subtract(1, "year").add(1, "day"));
                                                } else if (getValues("lease_term") === "2 Years") {
                                                    setValue("start_date", dayjs(e).subtract(2, "years").add(1, "day"));
                                                } else if (getValues("lease_term") === "3 Years") {
                                                    setValue("start_date", dayjs(e).subtract(3, "years").add(1, "day"));
                                                } else {
                                                    setValue("start_date", dayjs(e).subtract(1, "year").add(1, "day"));
                                                }
                                            }}
                                        />
                                    )}
                                />
                                {errors.end_date && (<p className={`${styles.ErrorM}`}>{errors.end_date.message}</p>)}
                            </div>
                            <div className={`${styles.HalfSecFull} FormGroup`}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Select property lease documents*</InputLabel>
                                    <Controller
                                        name="docume_ids"
                                        control={control}
                                        render={(field) => (
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectDocument}
                                                onChange={selectDocuments}
                                                onClick={(e) => {
                                                    if ((typeof e.target?.parentElement?.classList?.contains("MenuListNew") !== "undefined" && e.target?.parentElement?.classList.contains("MenuListNew")) || (typeof e.target?.parentElement?.parentElement?.classList?.contains("MenuListNew") !== "undefined" && e.target?.parentElement?.parentElement?.classList.contains("MenuListNew"))) {
                                                        setDropdownOpen(true);
                                                    } else if (e.target.localName === "span" && e.target.parentElement.classList.contains("DropListNew")) {
                                                        setDropdownOpen(false);
                                                    } else if (e.target.localName === "div" && ((typeof e.target?.parentElement?.classList?.contains("DropListNew") !== "undefined" && e.target?.parentElement?.classList.contains("DropListNew")) || (typeof e.target?.parentElement?.parentElement?.classList?.contains("DropListNew") !== "undefined" && e.target?.parentElement?.parentElement?.classList.contains("DropListNew")))) {
                                                        setDropdownOpen(false);
                                                    } else {
                                                        setDropdownOpen((prev) => !prev);
                                                    }
                                                }}
                                                multiple
                                                input={<OutlinedInput label="Select property lease documents*" />}
                                                open={dropdownOpen}
                                                renderValue={(e) => (
                                                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }} className={"DropListNew"}>
                                                        <ListManager
                                                            className={"DropListNew"}
                                                            items={sortedLeaseDocuments}
                                                            direction="horizontal"
                                                            maxItems={40}
                                                            render={(item) => (<ListElement item={item} />)}
                                                            onDragEnd={reorderList}
                                                        />
                                                    </Box>
                                                )}
                                            >
                                                {LeaseDocumentOptions.map((item) => (
                                                    <MenuItem key={item.id} value={item} className={"MenuListNew"}>
                                                        <Checkbox checked={selectDocumentIds.indexOf(item.id) > -1} />
                                                        <ListItemText primary={item.title} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                                {errors.docume_ids && (
                                    <p className={`${styles.ErrorM}`}>
                                        {errors.docume_ids.message}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                </LocalizationProvider>
            </DialogContent>
            <DialogActions>
                <Button className="CancelPopupBU" onClick={(e) => props.setAddLeaseModalOpen(false)}>Cancel</Button>
                <Button className="SubmitPopupBU" type="submit">Add</Button>
            </DialogActions>
        </form >
    </>);
};

export default LeaseForm;
