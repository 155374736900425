import { useEffect, useState } from "react";
import styles from "../dashboard.module.css";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";

const Prospects = ({ newData, data }) => {
  const [prospectList, setProspectList] = useState([]);

  useEffect(() => {
    setProspectList(data);
  }, [data]);

  return (<div className={`${styles.CardDivTwo} ${styles.CardHalf}`}>
    <div className={`${styles.IfnoCard}`}>
      <div className={`${styles.InsideCard}`}>
        <div className={`${styles.HeadFlex}`}>
          <h6 className={`${styles.InsideCardTitle2}`}>Prospects {newData > 0 && <span>{newData} New Prospects</span>}</h6>
          <Link to="/people/prospects" className={`${styles.ViewAllBU}`}>View All</Link>
        </div>
        <div className={`${styles.ScrollCard}`}>
          {prospectList?.map((item) => {
            return (<Link to={"people/prospects/details/" + item?.id} key={item?.id} className={`${styles.CaseCard}`}>
              <div className={`${styles.CaseCardTitle}`}>
                <div className={`${styles.CaseCardLeftSec}`}>
                  <Tooltip title={item?.full_name} placement="bottom-start">
                    <p className={`${styles.TitleText} ${styles.TextWrap}`}>{item?.full_name}</p>
                  </Tooltip>
                  <p className={`${styles.TitleName}`}>{item?.phone}</p>
                  <div className={`${styles.IconBadgeSec}`}>
                    <Tooltip title={item?.credit_score !== "" ? "Credit Score - " + item?.credit_score : "Credit Score - N/A"}>
                      <span className={item?.check_list?.credit_score === 2 ? `${styles.MandateCheckSec} ${styles.Fail}` : item?.check_list?.credit_score === 1 ? `${styles.MandateCheckSec} ${styles.Active}` : `${styles.MandateCheckSec}`}>
                        <img src="/static/img/credit-score-icon.svg" alt="" />
                      </span>
                    </Tooltip>
                    <Tooltip title={item?.income_per_year !== "" ? "Salary - $" + item?.formatted_income_per_year : "Salary - N/A"}>
                      <span className={item?.check_list?.income === 2 ? `${styles.MandateCheckSec} ${styles.Fail}` : item?.check_list?.income === 1 ? `${styles.MandateCheckSec} ${styles.Active}` : `${styles.MandateCheckSec}`}>
                        <img src="/static/img/salary-icon.svg" alt="" />
                      </span>
                    </Tooltip>
                    <Tooltip title={item?.ever_had_bankruptcy !== "" ? "Bankruptcy - " + item?.ever_had_bankruptcy : "Bankruptcy - N/A"}>
                      <span className={item?.check_list?.bankruptcy === 2 ? `${styles.MandateCheckSec} ${styles.Fail}` : item?.check_list?.bankruptcy === 1 ? `${styles.MandateCheckSec} ${styles.Active}` : `${styles.MandateCheckSec}`}>
                        <img src="/static/img/bankruptcy-icon.svg" alt="" />
                      </span>
                    </Tooltip>
                    <Tooltip title={item?.ever_case_filed !== "" ? "Housing Court Cases - " + item?.ever_case_filed : "Housing Court Cases - N/A"}>
                      <span className={item?.check_list?.housing_court_case === 2 ? `${styles.MandateCheckSec} ${styles.Fail}` : item?.check_list?.housing_court_case === 1 ? `${styles.MandateCheckSec} ${styles.Active}` : `${styles.MandateCheckSec}`}>
                        <img src="/static/img/court-cases-icon.svg" alt="" />
                      </span>
                    </Tooltip>
                  </div>
                </div>
                <div className={`${styles.CardPillSec}`}>
                  {item?.current_status === 0 && (<span className={`${styles.StatusPill} ${styles.NotApplied}`}>Not Applied</span>)}
                  {item?.current_status === 1 && (<span className={`${styles.StatusPill} ${styles.Open}`}>Applied</span>)}
                  {item?.current_status === 2 && (<span className={`${styles.StatusPill} ${styles.Yellow}`}>Screened</span>)}
                  {item?.current_status === 3 && (<span className={`${styles.StatusPill} ${styles.Green}`}>Approved</span>)}
                  {item?.current_status === 4 && (<span className={`${styles.StatusPill} ${styles.Red}`}>Declined</span>)}
                  <p className={`${styles.DateText}`}>{item?.submitted_date_str}</p>
                </div>
              </div>
            </Link>);
          })}
          {prospectList?.length === 0 && (<div className={`${styles.NoDataSec}`}>
            <div className={`${styles.NoDataIMG}`}><img src="/static/img/no-prospect.png" alt="" /></div>
            <p className={`${styles.NoDataText}`}>Let's get started!</p>
            <p className={`${styles.NoDataTextSub}`}>Prospect List is Currently Empty.</p>
          </div>)}
        </div>
      </div>
    </div>
  </div>);
};

export default Prospects;