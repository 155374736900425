import React from "react";
import styles from "../renrolldetails.module.css";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import dayjs from "dayjs";
import { toast } from "react-smart-toaster";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const PaymentCard = ({ data, panel, taskExpanded, handleChange, setLoading, setSnackbarMsg, setSnackbarOpen, setDeleteModalShow, setDeleteId, setDeleteType, setAddBillModalShow, setAddCreditModalShow, setAddPaymentModalShow, setIsUpdate, setEditId }) => {
    const { getAccessTokenSilently } = useAuth0();

    const resendBill = async (rowId) => {
        setLoading(true);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        return axios.get(process.env.REACT_APP_PAYMENT_API_URL + "v1/resend-bill/" + rowId, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setLoading(false);
            setSnackbarOpen(true);
            setSnackbarMsg(response.data.message);
        }).catch((error) => {
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
            setLoading(false);
        });
    }

    const resendReceipt = async (rowId) => {
        setLoading(true);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        return axios.get(process.env.REACT_APP_PAYMENT_API_URL + "v1/resend-receipt/" + rowId, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setLoading(false);
            setSnackbarOpen(true);
            setSnackbarMsg(response.data.message);
        }).catch((error) => {
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
            setLoading(false);
        });
    }

    return (<Accordion expanded={taskExpanded === panel} onChange={handleChange(panel)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
            <div className={`${styles.NotesCard}`}>
                <div className={`${styles.AlertCardFirstSec}`}>
                    <div className={`${styles.AccoTitleHead}`}>

                        <div className={`${styles.CardStatusSec}`}>
                            {data?.type === 1 && <p className={`${styles.DateInfo2}`}>
                                <img src="/static/img/bill-icon.svg" alt="" className={`${styles.StatusIcon}`} />
                                <div>
                                    <p className={`${styles.DueOnBadge}`}>Bill Due On</p>
                                    <p className={`${styles.BadgeDate}`}>{dayjs(data?.date).format("LL")}</p>
                                </div>
                            </p>}
                            {data?.type === 2 && data?.payment_type === "record-payment" && <p className={`${styles.DateInfo2}`}>
                                <img src="/static/img/credit-icon.svg" alt="" className={`${styles.StatusIcon}`} />
                                <div>
                                    <p className={`${styles.CreditedBadge}`}>Payment Applied On</p>
                                    <p className={`${styles.BadgeDate}`}>{dayjs(data?.date).format("LL")}</p>
                                </div>
                            </p>}
                            {data?.type === 2 && data?.payment_type === "add-credit" && <p className={`${styles.DateInfo2}`}>
                                <img src="/static/img/credit-icon.svg" alt="" className={`${styles.StatusIcon}`} />
                                <div>
                                    <p className={`${styles.CreditedBadge}`}>Credit Applied On</p>
                                    <p className={`${styles.BadgeDate}`}>{dayjs(data?.date).format("LL")}</p>
                                </div>
                            </p>}
                            {data?.type === 2 && data?.payment_type === "online-payment" && <p className={`${styles.DateInfo2}`}>
                                <img src="/static/img/bank-icon.svg" alt="" className={`${styles.StatusIcon}`} />
                                <div>
                                    {data?.payment_status === 0 && <p className={`${styles.PendingBadge}`}>Payment Sent: Pending</p>}
                                    {data?.payment_status === 1 && <p className={`${styles.CreditedBadge}`}>Payment Sent: Completed</p>}
                                    {data?.payment_status === 2 && <p className={`${styles.FailedBadge}`}>Payment Sent: Failed</p>}
                                    <p className={`${styles.BadgeDate}`}>{dayjs(data?.date).format("LL")}</p>
                                </div>
                            </p>}

                        </div>

                        <div className={`${styles.CardDesSec}`}>
                            <p className={`${styles.DateInfo}`}>{data?.description}</p>
                        </div>

                        <div className={`${styles.CardAmountSec}`}>
                            <div>
                                <p className={`${styles.DateInfo}`}><span>Due:</span>{data?.due_amount_new}</p>
                            </div>
                            <div>
                                <p className={`${styles.DateInfo}`}><span>Payment:</span>{data?.type === 2 ? data?.payment_amount_new : '-'}</p>
                            </div>
                            <div>
                                <p className={`${styles.DateInfo}`}><span>Balance:</span>{data?.balance_amount_new}</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </AccordionSummary>
        <AccordionDetails className={`${styles.CussAccoRow}`}>
            {data?.type === 1 && (data.payment_type === 'rent' || data.payment_type === 'security-deposit' || data.payment_type === 'late-fee') && <div className={`${styles.AccoMainSec}`}>
                <ul>
                    <li className={`${styles.CardDesList}`}>
                        <p className={`${styles.AlertText}`}>
                            <span>Description:</span> {data?.description}
                        </p>
                    </li>
                    <li>
                        <p className={`${styles.AlertText}`}>
                            <span>Invoice No:</span> {100000 + parseInt(data?.id)}
                        </p>
                    </li>
                    <li>
                        <p className={`${styles.AlertText}`}>
                            <span>Type:</span> One Time
                        </p>
                    </li>
                    <li className={`${styles.DetailsBUSec}`}>
                        <button className={`${styles.ButtonVoiletLine}`} onClick={(e) => resendBill(data?.id)}>Resend Bill</button>
                    </li>
                </ul>
            </div>}
            {data?.type === 1 && (data.payment_type === 'add-bill') && <div className={`${styles.AccoMainSec}`}>
                <ul>
                    <li className={`${styles.CardDesList}`}>
                        <p className={`${styles.AlertText}`}>
                            <span>Description:</span> {data?.description}
                        </p>
                    </li>
                    <li>
                        <p className={`${styles.AlertText}`}>
                            <span>Invoice No:</span> {100000 + parseInt(data?.id)}
                        </p>
                    </li>
                    <li>
                        <p className={`${styles.AlertText}`}>
                            <span>Type:</span> One Time
                        </p>
                    </li>
                    <li className={`${styles.DetailsBUSec}`}>
                        <button className={`${styles.ButtonVoiletSolid}`} onClick={(e) => { setAddBillModalShow(true); setIsUpdate(true); setEditId(data?.id); }}>Edit</button>
                        <button className={`${styles.ButtonDelete}`} onClick={(e) => { setDeleteModalShow(true); setDeleteId(data?.id); setDeleteType('bill'); }}>Delete</button>
                        <button className={`${styles.ButtonVoiletLine}`} onClick={(e) => resendBill(data?.id)}>Resend Bill</button>
                    </li>
                </ul>
            </div>}
            {data?.type === 2 && data.payment_type === 'add-credit' && <div className={`${styles.AccoMainSec}`}>
                <ul>
                    <li className={`${styles.CardDesList}`}>
                        <p className={`${styles.AlertText}`}>
                            <span>Description:</span> {data?.description}
                        </p>
                    </li>
                    <li>
                        <p className={`${styles.AlertText}`}>
                            <span>Type:</span> One Time
                        </p>
                    </li>
                    <li className={`${styles.DetailsBUSec}`}>
                        <button className={`${styles.ButtonVoiletSolid}`} onClick={(e) => { setAddCreditModalShow(true); setIsUpdate(true); setEditId(data?.id); }}>Edit</button>
                        <button className={`${styles.ButtonDelete}`} onClick={(e) => { setDeleteModalShow(true); setDeleteId(data?.id); setDeleteType('credit'); }}>Delete</button>
                        <button className={`${styles.ButtonVoiletLine}`} onClick={(e) => resendReceipt(data?.id)}>Resend Receipt</button>
                    </li>
                </ul>
            </div>}
            {data?.type === 2 && data.payment_type === 'record-payment' && <div className={`${styles.AccoMainSec}`}>
                <ul>
                    <li className={`${styles.CardDesList}`}>
                        <p className={`${styles.AlertText}`}>
                            <span>Description:</span> {data?.description}
                        </p>
                    </li>
                    <li>
                        <p className={`${styles.AlertText}`}>
                            <span>Type:</span> One Time
                        </p>
                    </li>
                    <li className={`${styles.DetailsBUSec}`}>
                        <button className={`${styles.ButtonVoiletSolid}`} onClick={(e) => { setAddPaymentModalShow(true); setIsUpdate(true); setEditId(data?.id); }}>Edit</button>
                        <button className={`${styles.ButtonDelete}`} onClick={(e) => { setDeleteModalShow(true); setDeleteId(data?.id); setDeleteType('payment'); }}>Delete</button>
                        <button className={`${styles.ButtonVoiletLine}`} onClick={(e) => resendReceipt(data?.id)}>Resend Receipt</button>
                    </li>
                </ul>
            </div>}
            {data?.type === 2 && data.payment_type === 'online-payment' && <div className={`${styles.AccoMainSec}`}>
                <ul>
                    <li className={`${styles.CardDesList}`}>
                        <p className={`${styles.AlertText}`}>
                            <span>Description:</span> {data?.description}
                        </p>
                    </li>
                    <li>
                        <p className={`${styles.AlertText}`}>
                            <span>Payer:</span> {data?.payment_details?.payer}
                        </p>
                    </li>
                    <li>
                        <p className={`${styles.AlertText}`}>
                            <span>Memo:</span> {data?.payment_details?.memo}
                        </p>
                    </li>
                    <li>
                        <p className={`${styles.AlertText}`}>
                            <span>Amount:</span> ${data?.payment_details?.formatted_amount}
                        </p>
                    </li>
                    <li>
                        <p className={`${styles.AlertText}`}>
                            <span>Type:</span> One Time
                        </p>
                    </li>
                    <li>
                        <p className={`${styles.AlertText}`}>
                            <span>Status:</span> {data?.payment_details?.status}
                        </p>
                    </li>
                    <li>
                        <p className={`${styles.AlertText}`}>
                            <span>Sent On:</span>  {dayjs(data?.created_at).format("MMM D, YYYY")}
                        </p>
                    </li>
                    <li>
                        <p className={`${styles.AlertText}`}>
                            <span>Estimated Deposit Date:</span> {dayjs(data?.date).format("MMM D, YYYY")}
                        </p>
                    </li>
                    <li>
                        <p className={`${styles.AlertText}`}>
                            <span>Payment Method:</span>
                            {data?.payment_details?.payment_method === "card" && " Credit Card"}
                            {data?.payment_details?.payment_method === "us_bank_account" && " ACH Bank Transfer"}
                        </p>
                    </li>
                    <li>
                        <p className={`${styles.AlertText}`}>
                            <span>From Account:</span> {data?.payment_details?.account}
                        </p>
                    </li>
                    {data?.payment_details?.status === "Failed" && (<li className={`${styles.WidthFull}`}>
                        <p className={`${styles.AlertText}`}>
                            <span>Failure Message:</span> {data?.payment_details?.failure_message}
                        </p>
                    </li>)}
                    {data?.payment_details?.status === "Completed" && (<li className={`${styles.DetailsBUSec}`}>
                        <button className={`${styles.ButtonVoiletLine}`} onClick={(e) => resendReceipt(data?.id)}>Resend Receipt</button>
                    </li>)}
                </ul>
            </div>}
        </AccordionDetails>
    </Accordion>);
}

export default PaymentCard; 