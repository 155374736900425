import React from "react";
import { withAuthenticationRequired, withAuth0 } from "@auth0/auth0-react";
import Loader from "../../components/Loader";

class Default extends React.Component {

  constructor({ match, ...props }) {
    super(...arguments);
  }
  async componentDidMount() {
    const { user, isAuthenticated } = this.props.auth0;
    if (isAuthenticated) {
      if (user['https://propertydek.com/roles'].indexOf('Technician') > -1) {
        this.props.history.push('/cases/maintenance-request');
      } else {
        this.props.history.push('/dashboard');
      }
    }
  }

  render() {

    return (
      <React.Fragment>
        <Loader />
      </React.Fragment>
    );
  }

}
export default withAuthenticationRequired(withAuth0(Default), {
  onRedirecting: () => <Loader />,
});