import { useEffect, useState } from "react";
import styles from "../smartdevicedetails.module.css";
import { FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-smart-toaster";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { AddLogCallAPI } from "../../../components/AddLogs";
import { UploadCloud } from "react-feather";
import { styled as MUIstyled } from '@mui/material/styles';

const VisuallyHiddenInput = MUIstyled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const schema = yup.object().shape({
    property_id: yup.string().required("This field is Required"),
    apt_id: yup.string().required("This field is Required"),
    location: yup.string().required("This field is Required"),
    product_type: yup.string().required("This field is Required"),
    floor_id: yup.string().when('apt_id', (apt_id) => {
        if (apt_id && (apt_id === 0 || apt_id === "0")) return yup.string().required('This field is Required');
        else return yup.string();
    }),
    apt_text: yup.string().when('apt_id', (apt_id) => {
        if (apt_id && (apt_id === 0 || apt_id === "0")) return yup.string().required('This field is Required');
        else return yup.string();
    }),
    //name: yup.string().required("This field is Required").max(50, "Device Name cannot be more than 50 characters"),
});

const EditDeviceForm = ({ setLoading, deviceDetails, setEditModalOpen, setDeviceDetails, setSnackbarOpen, setSnackbarMsg }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [propertyList, setPropertyList] = useState([]);
    const [apartmentList, setApartmentList] = useState([]);
    const [productTitle, setProductTitle] = useState('');
    const [tempFile, setTempFile] = useState(null);
    const [defaultImage, setDefaultImage] = useState('');
    const [isNonApartment, setIsNonApartment] = useState(false);
    const [floorList, setFloorList] = useState([]);

    const { handleSubmit, control, setValue, errors, clearErrors } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: {
            name: '',
            property_id: '',
            apt_id: '',
            location: '',
            product_type: '',
            floor_id: '',
            apt_text: ''
        }
    });

    useEffect(() => {
        const fetchPropertyList = async () => {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
            await axios.get(process.env.REACT_APP_DEVICE_API_URL + "v1/properties", {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                setPropertyList(response.data.data);
            }).catch((error) => {
                if (typeof error.response !== "undefined")
                    toast.error(error.response.data.message);
            });
        };
        fetchPropertyList();
    }, [getAccessTokenSilently]);

    useEffect(() => {
        setValue('property_id', deviceDetails?.property_id);
        setValue('apt_id', deviceDetails?.apt_id);
        setValue('location', deviceDetails?.location);
        setValue('product_type', deviceDetails?.product_type);
        setValue('name', deviceDetails?.name);
        setProductTitle(deviceDetails?.name);

        if (deviceDetails?.apt_id === 0 || deviceDetails?.apt_id === "0") {
            setValue('floor_id', deviceDetails?.floor_id);
            setValue('apt_text', deviceDetails?.apt_text);

            setIsNonApartment(true);
        }


        if (propertyList?.length > 0) {
            let apartmentListTemp = [];
            propertyList?.map(i => {
                if (i.value === deviceDetails?.property_id) {
                    apartmentListTemp = i.apartments;

                    var floorArr = [];
                    for (var j = 1; j <= i?.no_of_floor; j++) {
                        floorArr.push(j);
                    }
                    setFloorList(floorArr);
                }
                return null;
            });
            setApartmentList(apartmentListTemp);
        }

        setDefaultImage(deviceDetails?.image);

    }, [deviceDetails, setValue, propertyList]);

    const onSubmit = async (data) => {
        let formData = new FormData();

        Object.keys(data).map((key) => {
            formData.append(key, data[key]);

            return true;
        });

        if (tempFile) {
            formData.append('uploadImage', tempFile);
        }

        setLoading(true);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
        axios.post(process.env.REACT_APP_DEVICE_API_URL + "v1/devices/" + deviceDetails?.id, formData, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            setLoading(false);
            setEditModalOpen(false);

            setSnackbarMsg(response.data.message);
            setSnackbarOpen(true);

            let rData = response.data.data;

            setDeviceDetails(prev => {
                if (rData.image === "") {
                    return { ...prev, property_id: rData.property_id, apt_id: rData.apt_id, property: rData.property, apartment: rData.apartment, location: rData.location, product_type: rData.product_type, name: rData.name, apt_text: rData.apt_text, floor_id: rData.floor_id };
                } else {
                    return { ...prev, property_id: rData.property_id, apt_id: rData.apt_id, property: rData.property, apartment: rData.apartment, location: rData.location, product_type: rData.product_type, name: rData.name, image: rData.image, apt_text: rData.apt_text, floor_id: rData.floor_id };
                }
            });

            let logData = {
                title: "Smart device is updated",
                description: [
                    "Device name: " + rData.name,
                    "Product type: " + rData.product_type,
                    "Location: " + rData.location,
                ],
            };
            AddLogCallAPI(logData, token);
        }).catch((error) => {
            setLoading(false);
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
        });
    }

    const fileHandler = (e) => {
        var validExtensions = ['jpg', 'jpeg', 'png'];
        var fileName = e.target.files[0].name;
        var fileNameExt = fileName.substr(fileName.lastIndexOf('.') + 1);

        if (validExtensions.indexOf(fileNameExt.toLowerCase()) === -1) {
            toast.error('File type is not allowed');

        } else if (e.target.files[0].size > 5242880) {
            toast.error('Image size should not be greater than 5 mb.');

        } else {
            setTempFile(e.target.files[0]);
            setDefaultImage(URL.createObjectURL(e.target.files[0]));
        }
    }

    return (<div className={`${styles.ProfileCardEditSec}`}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`${styles.ProfileIMGSec}`}>
                <div className={`${styles.ProfileIMGCard}`}>
                    <div className={`${styles.ProfileIMG}`}>
                        <img src={defaultImage} className={`${styles.roundedcircle}`} alt="" />
                    </div>
                    <IconButton component="label" className={`${styles.ProfileIMGUploadBUMobile}`}>
                        <UploadCloud />
                        <VisuallyHiddenInput type="file" onChange={fileHandler} accept=".jpg,.png,.jpeg" />
                    </IconButton>
                </div>
            </div>
            <div className={`${styles.ProfileEditFormSec}`}>
                <div className={`${styles.EditFormHalfSec} FormGroup`}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Property*</InputLabel>
                        <Controller
                            name="property_id"
                            control={control}
                            render={(field) => (
                                <Select
                                    {...field}
                                    label="Property*"
                                    onChange={(e) => {
                                        field.onChange(e);

                                        let apartmentListTemp = [];
                                        propertyList?.map(i => {
                                            if (i.value === e.target.value) {
                                                apartmentListTemp = i.apartments;

                                                var floorArr = [];
                                                for (var j = 1; j <= i?.no_of_floor; j++) {
                                                    floorArr.push(j);
                                                }
                                                setFloorList(floorArr);
                                            }
                                            return null;
                                        });

                                        setValue('apt_id', '');
                                        setApartmentList(apartmentListTemp);
                                    }}>
                                    {propertyList.map(i => {
                                        return <MenuItem key={i?.value} value={i?.value}>{i?.label}</MenuItem>
                                    })}
                                </Select>
                            )}
                        />
                    </FormControl>
                    {errors.property_id && (<p className={`${styles.ErrorM}`}>{errors?.property_id?.message}</p>)}
                </div>
                <div className={`${styles.EditFormHalfSec} FormGroup`}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Apartment*</InputLabel>
                        <Controller
                            name="apt_id"
                            control={control}
                            render={(field) => (
                                <Select
                                    {...field}
                                    label="Apartment*"
                                    onChange={(e) => {
                                        field.onChange(e);
                                        clearErrors();

                                        if (e.target.value > 0) {
                                            setIsNonApartment(false);

                                            let sApt = apartmentList?.filter(i => i?.value === e.target.value);
                                            if (sApt.length > 0) {
                                                let label = sApt[0]?.label;
                                                let ptArr = productTitle.split('-');
                                                if (ptArr?.length > 2) {
                                                    ptArr[ptArr.length - 1] = label;
                                                }
                                                setProductTitle(ptArr.join('-'));
                                                setValue('name', ptArr.join('-'));
                                            }
                                        } else {
                                            setIsNonApartment(true);

                                            let ptArr = productTitle.split('-');
                                            if (ptArr?.length > 2) {
                                                ptArr[ptArr.length - 1] = 'Other';
                                            }
                                            setProductTitle(ptArr.join('-'));
                                            setValue('name', ptArr.join('-'));
                                        }
                                    }}
                                >
                                    {apartmentList.map(i => {
                                        return <MenuItem key={i?.value} value={i?.value}>{i?.label}</MenuItem>
                                    })}
                                    <MenuItem value={0}>{"Other"}</MenuItem>
                                </Select>
                            )}
                        />
                    </FormControl>
                    {errors.apt_id && (<p className={`${styles.ErrorM}`}>{errors?.apt_id?.message}</p>)}
                </div>
                {isNonApartment && <>
                    <div className={`${styles.EditFormHalfSec} FormGroup`}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Floor*</InputLabel>
                            <Controller
                                name="floor_id"
                                control={control}
                                render={(field) => (
                                    <Select
                                        {...field}
                                        label="Floor*"
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }}>
                                        {floorList.map(i => {
                                            return <MenuItem key={i} value={i}>{i}</MenuItem>
                                        })}
                                    </Select>
                                )}
                            />
                        </FormControl>
                        {errors.floor_id && (<p className={`${styles.ErrorM}`}>{errors?.floor_id?.message}</p>)}
                    </div>
                    <div className={`${styles.EditFormHalfSec} FormGroup`}>
                        <FormControl fullWidth>
                            <Controller
                                name="apt_text"
                                control={control}
                                render={(field) => (
                                    <TextField
                                        {...field}
                                        id="outlined-multiline-static"
                                        label="Other Text*"
                                        variant="outlined"
                                    />
                                )}
                            />
                        </FormControl>
                        {errors.apt_text && (<p className={`${styles.ErrorM}`}>{errors?.apt_text?.message}</p>)}
                    </div>
                </>}
                <div className={`${styles.EditFormHalfSec} FormGroup`}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Room*</InputLabel>
                        <Controller
                            name="location"
                            control={control}
                            render={(field) => (
                                <Select
                                    {...field}
                                    label="Room*"
                                    onChange={(e) => {
                                        field.onChange(e);

                                        let ptArr = productTitle.split('-');
                                        if (ptArr?.length > 2) {
                                            ptArr[ptArr.length - 2] = e.target.value;
                                        }
                                        setProductTitle(ptArr.join('-'));
                                        setValue('name', ptArr.join('-'));
                                    }}
                                >
                                    <MenuItem value={'Bedroom'}>Bedroom</MenuItem>
                                    <MenuItem value={'Bathroom'}>Bathroom</MenuItem>
                                    <MenuItem value={'Living Room'}>Living Room</MenuItem>
                                    <MenuItem value={'Bedroom Closet'}>Bedroom Closet</MenuItem>
                                </Select>
                            )}
                        />
                    </FormControl>
                    {errors.location && (<p className={`${styles.ErrorM}`}>{errors?.location?.message}</p>)}
                </div>
                <div className={`${styles.EditFormHalfSec} FormGroup`}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Product Type*</InputLabel>
                        <Controller
                            name="product_type"
                            control={control}
                            render={(field) => (
                                <Select
                                    {...field}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Product Type*"
                                    onChange={(e) => { field.onChange(e); }}
                                >
                                    <MenuItem value={'Propertydek-Door Lock'}>Propertydek-Door Lock</MenuItem>
                                    <MenuItem value={'Propertydek-Light'}>Propertydek-Light</MenuItem>
                                    <MenuItem value={'Propertydek-Thermostat'}>Propertydek-Thermostat</MenuItem>
                                </Select>
                            )}
                        />
                    </FormControl>
                    {errors.product_type && (<p className={`${styles.ErrorM}`}>{errors?.product_type?.message}</p>)}
                </div>
                <div className={`${styles.EditFormFullSec} FormGroup`}>
                    <Controller
                        name={`name`}
                        control={control}
                        render={(field) => (
                            <TextField
                                value={field.value}
                                onChange={(e) => {
                                    field.onChange(e);
                                }}
                                label="Device Name*"
                                variant="outlined"
                                disabled
                            />
                        )}
                    />
                    {errors.name && (<p className={`${styles.ErrorM}`}>{errors?.name?.message}</p>)}
                </div>
            </div>
            <div className={`${styles.ButtonArea}`}>
                <button className={`${styles.ButtonVoiletLine}`} type="button" onClick={(e) => setEditModalOpen(false)}>Cancel</button>
                <button className={`${styles.ButtonVoiletSolid}`} type="submit">Update</button>
            </div>
        </form>
    </div >);
}

export default EditDeviceForm;