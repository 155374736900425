import styles from "../propertydetails.module.css";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import NumberFormat from "react-number-format";
import { Editor } from "@tinymce/tinymce-react";
import { Button, FormControl, InputLabel, MenuItem, Select, TextField, } from "@mui/material";
import { useEffect, useState } from "react";
import { X } from "react-feather";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import Loader from "../../../components/Loader";
import { toast } from "react-smart-toaster";
import { AddLogCallAPI } from "../../../components/AddLogs";

const schema = yup.object().shape({
  layout_id: yup.string().required("This field is Required"),
  layout_title: yup.string().when("layout_id", {
    is: (val) => parseInt(val) === 99999,
    then: (schema) => schema.required("This field is Required"),
    otherwise: (schema) => schema,
  }),
  apt_no: yup.string().required("This field is Required").max(4, "Unit no can not be more than 4 characters"),
  description: yup.string().required("This field is Required"),
  apt_type: yup.string().required("This field is Required"),
  bathroom: yup.string().required("This field is Required"),
  floor: yup.string().required("This field is Required"),
  size: yup.string().required("This field is Required").max(4, "Unit size can not be more than 4 digits"),
  rent: yup.string().required("This field is Required").test("max-4-digit", "Rent can not be more than 6 digits.", (value) => {
    return parseInt(value?.replace(/,/g, "")) < 10000;
  }),
  ada_complaint: yup.string().required("This field is Required"),
  parking: yup.string().required("This field is Required"),
});

function AreaField(props) {
  return (
    <NumberFormat
      customInput={TextField}
      prefix=""
      displayType="input"
      type="text"
      thousandSeparator={false}
      allowNegative={false}
      decimalScale={0}
      {...props}
    />
  );
}

function MoneyFieldTwo(props) {
  return (
    <NumberFormat
      customInput={TextField}
      prefix=""
      displayType="input"
      type="text"
      thousandSeparator={true}
      allowNegative={false}
      decimalScale={2}
      fixedDecimalScale
      {...props}
    />
  );
}

const AddApartmentForm = ({ layouts, no_of_floor, setShowAddApartmentForm, property_id, addLayoutRow, addApartmentRow, property_name, setSnackbarOpen, setSnackbarMsg, editApartmentData }) => {
  const { getAccessTokenSilently } = useAuth0();

  const [uploadFiles, setUploadFiles] = useState([]);
  const [uploadFilesPath, setUploadFilesPath] = useState([]);
  const [loading, setLoading] = useState(false);
  const [layoutList, setLayoutList] = useState([]);
  const [layoutTitle, setLayoutTitle] = useState("");
  const [isNewLayout, setIsNewLayout] = useState(false);
  const [isLayout, setIsLayout] = useState(false);
  const [layoutImages, setLayoutImages] = useState([]);
  const [floorList, setFloorList] = useState([]);
  const [uploadedFilesPath, setUploadedFilesPath] = useState([]);

  const { handleSubmit, control, errors, setValue, clearErrors } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      apt_type: "",
      bathroom: "",
      ada_complaint: "",
      parking: "",
      floor: "",
      layout_id: ""
    }
  });


  useEffect(() => {
    if (layouts) {
      let layoutTemp = layouts.map((i) => {
        return { ...i, value: i.id, label: i.title };
      });
      layoutTemp = [{ value: 0, label: "None" }].concat(layoutTemp);
      layoutTemp.push({ value: 99999, label: "New Layout" });
      setLayoutList(layoutTemp);
    }
  }, [layouts]);

  useEffect(() => {
    if (no_of_floor) {
      var floorArr = [];
      for (var i = 1; i <= no_of_floor; i++) {
        floorArr.push(i);
      }
      setFloorList(floorArr);
    }
  }, [no_of_floor]);

  const onSubmit = async (data) => {
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

    const formData = new FormData();

    Object.keys(data).map((key) => {
      if (key === "layout_id") {
        formData.append("layout_id", data?.layout_id);
        if (parseInt(data.layout_id) === 99999) {
          formData.append("layout", data?.layout_title);
        } else {
          formData.append("layout", layoutTitle);
        }
      } else if (key === "apt_no") {
        formData.append("apt_no", data?.apt_no);
      } else if (key === "apt_type") {
        formData.append("apt_type", data?.apt_type);
      } else if (key === "description") {
        formData.append("description", data?.description);
      } else if (key === "bathroom") {
        formData.append("bathroom", data?.bathroom);
      } else if (key === "floor") {
        formData.append("floor", data?.floor);
      } else if (key === "size") {
        formData.append("size", data?.size);
      } else if (key === "ada_complaint") {
        formData.append("ada_complaint", data?.ada_complaint);
      } else if (key === "parking") {
        formData.append("parking", data?.parking);
      } else if (key === "rent") {
        formData.append("rent", data?.rent?.replace(/,/g, ""));
      }
      return false;
    });

    if (uploadFiles.length) {
      for (let n in uploadFiles) {
        formData.append("uploadImages[]", uploadFiles[n]);
      }
    }

    setLoading(true);

    axios.post(process.env.REACT_APP_PROPERTY_API_URL + "v1/properties/" + property_id + "/units", formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      setLoading(false);

      addApartmentRow(response.data.data);
      setShowAddApartmentForm(false);

      if (response.data.layout) {
        addLayoutRow(response.data.layout);
      }

      setSnackbarOpen(true);
      setSnackbarMsg(response.data.message);

      let logData = {
        title: "New apartment is added",
        description: [
          "Unit No: " + response.data.data?.apt_no,
          "Unit Type: " + response.data.data?.apt_type,
          "Property: " + property_name
        ],
      };

      AddLogCallAPI(logData, token);
    }).catch((error) => {
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
      setLoading(false);
    });
  };

  const fileHandler = (e) => {
    let uploadFilesTemp = uploadFiles;
    if (e.target.files?.length > 0) {
      let item = e.target.files[0];
      let fileName = item.name;
      let fileSize = item.size;
      let fileNameExt = fileName.substr(fileName.lastIndexOf(".") + 1);
      fileNameExt = '.' + fileNameExt;

      if (['.png', '.jpg', '.jpeg'].indexOf(fileNameExt) === -1) {
        toast.error("File type is not allowed.");
        return false;
      }

      if (fileSize >= 5242880) {
        toast.error("File size should not be greater than 5 MB.");
        return false;
      }

      uploadFilesTemp.push(e.target.files[0]);
      setUploadFiles(uploadFilesTemp);

      setUploadFilesPath((prevFilesPath) => {
        return [...prevFilesPath, ...[URL.createObjectURL(e.target.files[0])]];
      });
    }
  };

  const removeFIles = (index) => {
    setUploadFiles(prev => {
      return prev.filter((item, i) => i !== index);
    });
    setUploadFilesPath(prev => {
      return prev.filter((item, i) => i !== index);
    });
  }

  useEffect(() => {
    if (editApartmentData) {
      setValue('layout_id', editApartmentData?.layout_id);
      setValue('apt_no', editApartmentData?.formatted_unit_no);
      setValue('description', editApartmentData?.description);
      setValue('apt_type', editApartmentData?.apt_type);
      setValue('bathroom', editApartmentData?.bathroom);
      setValue('floor', editApartmentData?.floor);
      setValue('size', editApartmentData?.size);
      setValue('rent', editApartmentData?.rent);
      setValue('ada_complaint', editApartmentData?.ada_complaint);
      setValue('parking', editApartmentData?.parking);

      if (editApartmentData?.layout_id === 0) {
        setIsLayout(false);
      } else {
        setIsLayout(true);
      }

      setLayoutTitle(editApartmentData?.layout);

      setLayoutImages(editApartmentData?.layout_images);
      setUploadedFilesPath(editApartmentData?.images);
    }
  }, [editApartmentData, setValue]);

  const onUpdate = async (data) => {
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

    const formData = new FormData();

    Object.keys(data).map((key) => {
      if (key === "layout_id") {
        formData.append("layout_id", data?.layout_id);
        if (parseInt(data.layout_id) === 99999) {
          formData.append("layout", data?.layout_title);
        } else {
          formData.append("layout", layoutTitle);
        }
      } else if (key === "apt_no") {
        formData.append("apt_no", data?.apt_no);
      } else if (key === "apt_type") {
        formData.append("apt_type", data?.apt_type);
      } else if (key === "description") {
        formData.append("description", data?.description);
      } else if (key === "bathroom") {
        formData.append("bathroom", data?.bathroom);
      } else if (key === "floor") {
        formData.append("floor", data?.floor);
      } else if (key === "size") {
        formData.append("size", data?.size);
      } else if (key === "ada_complaint") {
        formData.append("ada_complaint", data?.ada_complaint);
      } else if (key === "parking") {
        formData.append("parking", data?.parking);
      } else if (key === "rent") {
        formData.append("rent", data?.rent?.replace(/,/g, ""));
      }
      return false;
    });

    if (uploadFiles.length) {
      for (let n in uploadFiles) {
        formData.append("uploadImages[]", uploadFiles[n]);
      }
    }

    if (uploadedFilesPath?.length) {
      for (let n in uploadedFilesPath) {
        formData.append("uploadedImageId[]", uploadedFilesPath[n]?.id);
      }
    }

    setLoading(true);

    axios.post(process.env.REACT_APP_PROPERTY_API_URL + "v1/properties/" + property_id + "/units/" + editApartmentData?.id, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      setLoading(false);

      addApartmentRow(response.data.data);
      setShowAddApartmentForm(false);

      if (response.data.layout) {
        addLayoutRow(response.data.layout);
      }

      setSnackbarOpen(true);
      setSnackbarMsg(response.data.message);

      let logData = {
        title: "Apartment is updated",
        description: [
          "Unit No: " + response.data.data?.apt_no,
          "Unit Type: " + response.data.data?.apt_type,
          "Property: " + property_name
        ],
      };

      AddLogCallAPI(logData, token);
    }).catch((error) => {
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
      setLoading(false);
    });
  }

  return (<>
    {loading && <Loader />}
    <div className={`${styles.AddUnitSec}`}>
      <form className={`${styles.AddLayoutForm}`} onSubmit={handleSubmit(editApartmentData ? onUpdate : onSubmit)}>
        <div className={`${styles.SMSmallSideBody} TabMainClientProfile ClientDetails`}>
          <div className={`${styles.AddUserMain}`}>
            <div className={`${styles.FormRow}`}>
              <div className={isNewLayout ? `${styles.FormGroup} FormGroup` : `${styles.FormGroupFull} FormGroup`}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Layout Type*</InputLabel>
                  <Controller
                    name="layout_id"
                    control={control}
                    render={(field) => (
                      <Select
                        {...field}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Layout Type"
                        onChange={(e) => {
                          field.onChange(e);

                          setIsNewLayout(false);
                          if (e.target.value === 99999) {
                            setIsNewLayout(true);
                          }

                          if (e.target.value === 0) {
                            setLayoutTitle('None');
                          }

                          if (e.target.value === 0 || e.target.value === 99999) {
                            setIsLayout(false);
                            setLayoutImages([]);

                            setValue("description", "");
                            setValue("rent", "");
                            setValue("size", "");
                            setValue("apt_type", "");
                            setValue("ada_complaint", "");
                            setValue("bathroom", "");
                            setValue("parking", "");
                            setValue("unit_type", "");

                            clearErrors();
                          } else {
                            setIsLayout(true);

                            let selLayout = layoutList?.filter(i => i?.id === e.target.value);
                            if (selLayout?.length) {
                              selLayout = selLayout[0];

                              setLayoutTitle(selLayout?.title);

                              setValue("description", selLayout?.description);
                              setValue("rent", selLayout?.rent);
                              setValue("size", selLayout?.size);
                              setValue("apt_type", selLayout?.unit_type);
                              setValue("ada_complaint", selLayout?.ada_complaint);
                              setValue("bathroom", selLayout?.bathroom);
                              setValue("parking", selLayout?.parking);

                              clearErrors();

                              setLayoutImages(selLayout?.images);
                            }
                          }
                        }}
                      >
                        {layoutList?.map(item => {
                          return <MenuItem key={item?.value} value={item?.value}>{item?.label}</MenuItem>
                        })}
                      </Select>
                    )}
                  />
                </FormControl>
                {errors.layout_id && (<p className={`${styles.ErrorM}`}>{errors.layout_id.message}</p>)}
              </div>
              {isNewLayout && (<div className={`${styles.FormGroup} FormGroup`}>
                <Controller
                  name={`layout_title`}
                  control={control}
                  render={(field) => (
                    <TextField
                      onChange={(e) => field.onChange(e)}
                      id="outlined-basic"
                      variant="outlined"
                      label="Layout Name*"
                      value={field.value}
                    />
                  )}
                />
                {errors.layout_title && (<p className={`${styles.ErrorM}`}>{errors.layout_title.message}</p>)}
              </div>)}
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroup} FormGroup`}>
                <Controller
                  name={`apt_no`}
                  control={control}
                  render={(field) => (
                    <TextField
                      onChange={(e) => field.onChange(e)}
                      id="outlined-basic"
                      variant="outlined"
                      label="Unit No*"
                      value={field.value}
                    />
                  )}
                />
                {errors.apt_no && (<p className={`${styles.ErrorM}`}>{errors.apt_no.message}</p>)}
              </div>
              <div className={`${styles.FormGroup} FormGroup`}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Unit Type*</InputLabel>
                  <Controller
                    name="apt_type"
                    control={control}
                    render={(field) => (
                      <Select
                        {...field}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Unit Type*"
                        onChange={(e) => { field.onChange(e); }}
                        disabled={isLayout}
                      >
                        <MenuItem value={'Studio'}>Studio</MenuItem>
                        <MenuItem value={'1 Bedroom'}>1 Bedroom</MenuItem>
                        <MenuItem value={'2 Bedroom'}>2 Bedroom</MenuItem>
                        <MenuItem value={'3 Bedroom'}>3 Bedroom</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
                {errors.apt_type && (<p className={`${styles.ErrorM}`}>{errors.apt_type.message}</p>)}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroup} FormGroup`}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Bathrooms*</InputLabel>
                  <Controller
                    name="bathroom"
                    control={control}
                    render={(field) => (
                      <Select
                        {...field}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Bathrooms*"
                        onChange={(e) => { field.onChange(e); }}
                        disabled={isLayout}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
                {errors.bathroom && (<p className={`${styles.ErrorM}`}>{errors.bathroom.message}</p>)}
              </div>
              <div className={`${styles.FormGroup} FormGroup`}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Floor*</InputLabel>
                  <Controller
                    name="floor"
                    control={control}
                    render={(field) => (
                      <Select
                        {...field}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Floor*"
                        onChange={(e) => { field.onChange(e); }}
                      >
                        {floorList?.map(item => {
                          return <MenuItem key={item} value={item}>{item}</MenuItem>
                        })}
                      </Select>
                    )}
                  />
                </FormControl>
                {errors.floor && (<p className={`${styles.ErrorM}`}>{errors.floor.message}</p>)}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroup} FormGroup`}>
                <Controller
                  name={`size`}
                  control={control}
                  render={(field) => (
                    <AreaField
                      onChange={(e) => field.onChange(e)}
                      id="outlined-basic"
                      variant="outlined"
                      label="Unit Size*"
                      value={field.value}
                      disabled={isLayout}
                    />
                  )}
                />
                {errors.size && (<p className={`${styles.ErrorM}`}>{errors.size.message}</p>)}
              </div>
              <div className={`${styles.FormGroup} FormGroup`}>
                <Controller
                  name={`rent`}
                  control={control}
                  render={(field) => (
                    <MoneyFieldTwo
                      onChange={(e) => field.onChange(e)}
                      id="outlined-basic"
                      variant="outlined"
                      label="Rent*"
                      value={field.value}
                      disabled={isLayout}
                    />
                  )}
                />
                {errors.rent && (<p className={`${styles.ErrorM}`}>{errors.rent.message}</p>)}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroup} FormGroup`}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">ADA Compliant*</InputLabel>
                  <Controller
                    name="ada_complaint"
                    control={control}
                    render={(field) => (
                      <Select
                        {...field}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="ADA Compliant*"
                        onChange={(e) => { field.onChange(e); }}
                        disabled={isLayout}
                      >
                        <MenuItem value={'Yes'}>Yes</MenuItem>
                        <MenuItem value={'No'}>No</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
                {errors.ada_complaint && (<p className={`${styles.ErrorM}`}>{errors.ada_complaint.message}</p>)}
              </div>
              <div className={`${styles.FormGroup} FormGroup`}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Parking*</InputLabel>
                  <Controller
                    name="parking"
                    control={control}
                    render={(field) => (
                      <Select
                        {...field}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Parking*"
                        onChange={(e) => { field.onChange(e); }}
                        disabled={isLayout}
                      >
                        <MenuItem value={'Yes'}>Yes</MenuItem>
                        <MenuItem value={'No'}>No</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
                {errors.parking && (<p className={`${styles.ErrorM}`}>{errors.parking.message}</p>)}
              </div>
            </div>
            <div className={`${styles.FormRow} ${styles.FileUploadSmall}`}>
              <div className={`${styles.FormGroupFull}`}>
                <div className={`${styles.FileUpDuBUDiv}`}>
                  <label
                    for="file-upload"
                    className={`${styles.UploadLabel}`}
                  >
                    <span>
                      <div className={`${styles.UpIcon}`}>
                        <img src="/static/img/upload-floor.svg" alt="" />
                      </div>
                      <div>
                        <p>Upload Photo</p>
                        <p className={`${styles.UploadText}`}>
                          File size upto 5 MB <br /> JPEG, JPG, PNG only
                        </p>
                      </div>
                    </span>
                  </label>
                  <input id="file-upload" type="file" onChange={fileHandler} accept={'.png,.jpg,.jpeg'} />
                </div>
                <div className={`${styles.UpIMGSec}`}>

                  {layoutImages?.map((item, index) => {
                    return <div className={`${styles.ImgFile}`} key={index}>
                      <div className={`${styles.ImgFileUnder}`}>
                        <img src={item?.file_public_url} alt="" />
                      </div>
                    </div>
                  })}

                  {uploadedFilesPath?.map((item) => {
                    return (<div className={`${styles.ImgFile}`}>
                      <div className={`${styles.ImgFileUnder}`}>
                        <img src={item?.file_public_url} alt="" />
                        <Button onClick={(e) => setUploadedFilesPath(prev => {
                          return prev?.filter(i => i?.id !== item?.id);
                        })}>
                          <X />
                        </Button>
                      </div>
                    </div>
                    );
                  })}

                  {uploadFilesPath?.map((item, index) => {
                    return <div className={`${styles.ImgFile}`} key={index}>
                      <div className={`${styles.ImgFileUnder}`}>
                        <img src={item} alt="" />
                        <Button onClick={(e) => removeFIles(index)}><X /></Button>
                      </div>
                    </div>
                  })}

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.SMBigSideBodyUnder} TabMainClientProfile ClientDetails`}>
          <div className={`${styles.FormRow}`}>
            <div className={`${styles.FormGroupFull}`}>
              <Controller
                name="description"
                control={control}
                render={(field) => (
                  <Editor
                    className={`${styles.formControlTextArea}`}
                    apiKey={"z3r7z5rrb92qgt55caemf3mrz1kdxuckuq25g9u3syc75r7u"}
                    value={field?.value}
                    disabled={isLayout}
                    init={{
                      height: 400,
                      menubar: false,
                      toolbar:
                        "undo redo | bold italic underline strikethrough | fontselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat |  fullscreen  | ltr rtl | code",
                    }}
                    onEditorChange={(e) => field.onChange(e)}
                  />
                )}
              />
              {errors.description && (<p className={`${styles.ErrorM}`}>{errors.description.message}</p>)}
            </div>
          </div>
        </div>
        <div className={`${styles.ButtonAction}`}>
          <button className="CancelPopupBU" type="button" onClick={() => setShowAddApartmentForm(false)}> Cancel </button>
          <button className="SubmitPopupBU" type="submit"> {editApartmentData ? 'Update' : 'Add'} </button>
        </div>
      </form>
    </div>
  </>);
};

export default AddApartmentForm;
