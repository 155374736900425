import React, { useState, useEffect } from "react";
import { SketchPicker } from "react-color";
import reactCSS from "reactcss";
import styles from "../../propertydetails.module.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-smart-toaster";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "../../../../components/Loader";
import { AddLogCallAPI } from "../../../../components/AddLogs";
import UploaderComponent from "../MobileTheme/UploaderComponent";

const MobileThemeTab = (props) => {
    const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
    const [defaultColor, setDefaultColor] = useState("#F8D524");
    const [defaultSecondColor, setDefaultSecondColor] = useState("#008080");
    const [colorInput, setColorInput] = useState("#F8D524");
    const [colorSecondInput, setColorSecondInput] = useState("#008080");
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [displaySecondColorPicker, setDisplaySecondColorPicker] = useState(false);


    const { register, handleSubmit, setValue } = useForm({
        shouldUnregister: false,
    });

    const bgstyles = reactCSS({
        default: {
            color: {
                width: "36px",
                height: "14px",
                borderRadius: "2px",
                background: `${defaultColor}`,
            },
            colorsecond: {
                width: "36px",
                height: "14px",
                borderRadius: "2px",
                background: `${defaultSecondColor}`,
            },
            swatch: {
                padding: "5px",
                background: "#fff",
                borderRadius: "1px",
                boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
                display: "inline-block",
                cursor: "pointer",
            },
            popover: { position: "absolute", zIndex: "2" },
            cover: {
                position: "fixed",
                top: "0px",
                right: "0px",
                bottom: "0px",
                left: "0px",
            },
        },
    });

    useEffect(() => {
        if (props?.app_theme_options) {
            if (props?.app_theme_options["image"]) {
                setValue("image", props?.app_theme_options["image"]);
            }
            if (props?.app_theme_options["image_url"]) {
                setImageUrl(props?.app_theme_options["image_url"]);
            }

            if (props?.app_theme_options["primary_color"]) {
                setValue("primary_color", props?.app_theme_options["primary_color"]);
                setDefaultColor(props?.app_theme_options["primary_color"]);
                setColorInput(props?.app_theme_options["primary_color"]);
            }
            if (props?.app_theme_options["secondary_color"]) {
                setValue("secondary_color", props?.app_theme_options["secondary_color"]);
                setDefaultSecondColor(props?.app_theme_options["secondary_color"]);
                setColorSecondInput(props?.app_theme_options["secondary_color"]);
            }
        }
    }, [props?.app_theme_options, setValue])

    const onSubmit = async (data) => {
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
        setLoading(true);
        return axios.put(process.env.REACT_APP_PROPERTY_API_URL + "v1/properties/" + props?.property_id + "/update-theme", data, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then((response) => {
            setLoading(false);

            props.setSnackbarMsg(response.data.message);
            props.setSnackbarOpen(true);

            props.setPropertyData(response.data.data);

            let logData = {
                title: "Mobile theme is updated",
                description: ["Property: " + props?.property_name],
            };
            AddLogCallAPI(logData, token);
        }).catch((error) => {
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
            setLoading(false);
        });
    }

    const onUploadSuccessUnit = (file) => {
        setImageUrl(file?.url);
        setValue("image", file?.file_path);
    }

    const handleChangeComplete = (color) => {
        setDefaultColor(color.hex);
        setColorInput(color.hex);
        setValue("primary_color", color.hex);
    };

    const secondaryHandleChangeComplete = (color) => {
        setDefaultSecondColor(color.hex);
        setColorSecondInput(color.hex);
        setValue("secondary_color", color.hex);
    };

    const resetTheme = () => {
        if (props?.app_theme_options) {
            setValue("primary_color", props?.app_theme_options["primary_color"]);
            setDefaultColor(props?.app_theme_options["primary_color"]);
            setColorInput(props?.app_theme_options["primary_color"]);
            setValue("secondary_color", props?.app_theme_options["secondary_color"]);
            setDefaultSecondColor(props?.app_theme_options["secondary_color"]);
            setColorSecondInput(props?.app_theme_options["secondary_color"]);
        } else {
            setValue("primary_color", "#F8D524");
            setDefaultColor("#F8D524");
            setColorInput("#F8D524");
            setValue("secondary_color", "#008080");
            setDefaultSecondColor("#008080");
            setColorSecondInput("#008080");
        }
    };

    return (<>
        {loading && <Loader />}

        <div className={`${styles.ThemeRow}`}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={`${styles.FileUpDuBUDivBox}`}>

                    <UploaderComponent
                        allowedExtensions={".jpg,.png,.jpeg"}
                        isMuliple={false}
                        maxFileSize={5242880}
                        setLoading={setLoading.bind(this)}
                        uploadUrl={process.env.REACT_APP_PROPERTY_API_URL + "v1/properties/app-theme/image-upload"}
                        onUploadLocal={onUploadSuccessUnit.bind(this)}
                        buttonText={"Upload Logo"}
                        helpText={"File size upto 5 MB\nJPEG, JPG, PNG"}
                    />

                    <div className={`${styles.ColorPicker}`}>
                        <div className={`${styles.ColorPickerList}`}>
                            <p>Select primary theme color:</p>
                            <input className={`${styles.formControl}`} value={colorInput} type="text" name="primary_color" ref={register} />
                            <div style={bgstyles.swatch} onClick={(e) => setDisplayColorPicker(true)}>
                                <div style={bgstyles.color} />
                            </div>
                            {displayColorPicker ? (<div style={bgstyles.popover}>
                                <div style={bgstyles.cover} onClick={(e) => setDisplayColorPicker(false)} />
                                <SketchPicker color={colorInput} onChangeComplete={handleChangeComplete} />
                            </div>) : null}
                        </div>
                        <div className={`${styles.ColorPickerList}`}>
                            <p>Select secondary theme color:</p>
                            <input className={`${styles.formControl}`} value={colorSecondInput} type="text" name="secondary_color" ref={register} />
                            <div style={bgstyles.swatch} onClick={(e) => setDisplaySecondColorPicker(true)}>
                                <div style={bgstyles.colorsecond} />
                            </div>
                            {displaySecondColorPicker ? (<div style={bgstyles.popover}>
                                <div style={bgstyles.cover} onClick={(e) => setDisplaySecondColorPicker(false)} />
                                <SketchPicker color={colorSecondInput} onChangeComplete={secondaryHandleChangeComplete} />
                            </div>) : null}
                        </div>
                    </div>

                    <div className={`${styles.ButtonDiv}`}>
                        <button type="button" onClick={resetTheme} className={`${styles.ButtonVoiletLine}`} disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("property:update") > -1)}>Reset</button>
                        <button type="submit" className={`${styles.ButtonVoiletSolid}`} disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("property:update") > -1)}>Apply</button>
                    </div>
                </div>
            </form >

            <div className={`${styles.DemoIMG}`}>
                <img src="/static/img/mobile.svg" className={`${styles.IMG}`} alt="" />
                <img src={imageUrl !== "" ? imageUrl : "/static/img/DefaultLogo2.png"} className={`${styles.ThemeLogoIMG}`} alt="" />
                <p className={`${styles.ThemeIMGLabel}`} style={{ backgroundColor: colorInput }}>9</p>
                <div className={`${styles.RequestAddBU}`} style={{ backgroundColor: colorInput }}>
                    <img src="/static/img/RequestM-Black.svg" class={`${styles.IMG}`} style={{ backgroundColor: colorInput }} alt="" />
                    New Maintenance Request
                </div>
                <div className={`${styles.RequestAddBUC}`} style={{ backgroundColor: colorInput }}>
                    <img src="/static/img/RequestC-Black.svg" class={`${styles.IMG}`} alt="" />
                    New Complaint
                </div>
                <div className={`${styles.ReqMBUR}`} style={{ backgroundColor: colorSecondInput }}>
                    <img src="/static/img/RequestM-White.svg" class={`${styles.IMG}`} alt="" />
                </div>
                <div className={`${styles.ReqCBUR}`} style={{ backgroundColor: colorSecondInput }}>
                    <img src="/static/img/RequestC-White.svg" class={`${styles.IMG}`} alt="" />
                </div>
                <div className={`${styles.ThemeIMGTabImg}`} style={{ fill: colorInput }}>
                    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="M23,18c-2.15-2.15-2-2-2.07-2.06l-5.68-4.2,2.2-2.2a4.93,4.93,0,0,0,6.1-6.76.7.7,0,0,0-1.13-.19L20.57,4.42a.7.7,0,0,1-1-1l1.82-1.82A.71.71,0,0,0,21.2.48a4.92,4.92,0,0,0-6.76,6.09L11.9,9.12c-.52-.4.16.28-4.72-4.89l.52-.51a.71.71,0,0,0-.14-1.1L3.8.36A2.51,2.51,0,0,0,.36,3.8L2.62,7.56a.71.71,0,0,0,1.1.14l.48-.49c4.85,5.15,4.22,4.45,4.6,4.94l0,0L6.57,14.44A4.92,4.92,0,0,0,.48,21.2a.7.7,0,0,0,1.13.19l1.82-1.82a.7.7,0,0,1,1,1L2.6,22.39a.7.7,0,0,0,.2,1.13,4.93,4.93,0,0,0,6.76-6.1l1.92-1.92L16,21l1.92,1.93A3.79,3.79,0,0,0,20.55,24a3.34,3.34,0,0,0,2-.62A3.51,3.51,0,0,0,23,18ZM4.73,5.72a.7.7,0,0,0-1,0l-.36.36-1.79-3a1.09,1.09,0,0,1,1.5-1.5l3,1.79-.36.36a.7.7,0,0,0,0,1c5.61,5.94,4.73,5.05,5.21,5.42L9.83,11.19C9.46,10.71,10.32,11.65,4.73,5.72Zm3.5,11a.7.7,0,0,0-.14.8,3.51,3.51,0,0,1-.69,4,3.47,3.47,0,0,1-2.48,1,4.19,4.19,0,0,1-.5,0l1-1a2.11,2.11,0,0,0-3-3l-1,1a3.51,3.51,0,0,1,5-3.66.72.72,0,0,0,.8-.14l8.53-8.53a.7.7,0,0,0,.13-.8,3.52,3.52,0,0,1,3.67-5l-1,1A2.06,2.06,0,0,0,18,3.93a2.11,2.11,0,0,0,3.6,1.49l1-1a3.52,3.52,0,0,1-5,3.67.72.72,0,0,0-.8.14Zm4.25-2.26,1.73-1.73L16,14.07l-2.11,2.12Zm9.22,7.73a2.21,2.21,0,0,1-2.81-.34L17,20l-2.26-2.75,2.35-2.36L20,17.06,22,19A2.12,2.12,0,0,1,21.7,22.23Z" transform="translate(0 0)" />
                    </svg>
                </div>
                <div className={`${styles.ThemeIMGTabText}`} style={{ color: colorInput }}>Request</div>
            </div>
        </div >
    </>);
}


export default MobileThemeTab;