import styles from '../communication.module.css'
import { ChevronDown } from "react-feather";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";

function PropertyTenantsList(props) {

    const handleClick = (item, i) => {
        props.openChatWindow(item);
    }

    const openProperty = (item) => {
        props.openProperty(item);
    }

    return (
        <>
            <div className={(props.isOpen) ? `${styles.PropertyGroup} ${styles.Open}` : `${styles.PropertyGroup}`}>
                <div className={`${styles.PropertyGroupHead}`} onClick={() => openProperty(props?.item?.id)}>
                    <span className={`${styles.Icon}`}><ChevronDown /></span>
                    <div className={`${styles.PropertyImageSec}`}>
                        <img src={(props.item.image !== '') ? props.item.image : '/static/img/DefaultLogo.png'} alt="Property" />
                    </div>
                    <div>
                        <p className={`${styles.PropertyGroupTitle}`}>{props.item.name}</p>
                        <p className={`${styles.PropertyGroupMember}`}>{props.item.tenants?.length} {(props.item.tenants?.length > 1) ? 'Units' : 'Unit'}</p>
                    </div>
                    {(props.item.unread_no > 0) && <span className={`${styles.PropertyGroupNotiCount}`}>{props.item.unread_no}</span>}
                </div>
                <div className={`${styles.PropertyGroupBody} PropertyGroupBody`}>
                    <List style={{ maxHeight: '55vh', overflow: 'auto' }} className="ApartmentListSec">
                        {props.item.tenants.map((item, index) => {
                            return (<ListItem button key={index} onClick={() => handleClick(item, index)} className={(props.ActiveTenant === item.id) ? `${styles.activeList}` : ``}>
                                <ListItemIcon>
                                    <TenantAvatar tenants={item.tenant_list}></TenantAvatar>
                                </ListItemIcon>
                                <div className="GroupName">
                                    <div className="TenantName">
                                        {item.tenant_list.map((tnt, index) => {
                                            return (
                                                <>
                                                    <span key={index}>{tnt.full_name}</span>
                                                </>
                                            )
                                        })}
                                    </div>
                                    <ListItemText className="AptName">{item.apt_no}</ListItemText>
                                </div>
                                {(item.unread_no > 0) && <span className={`${styles.PropertyTenantNotiCount}`}>{item.unread_no}</span>}
                            </ListItem>)
                        })}
                    </List>
                </div>
            </div>
        </>
    );
}

function TenantAvatar({ tenants }) {
    return (
        <>
            {/* ******** For four tenant ********* */}
            {tenants.length === 4 && <div className={`${styles.AvatarSec}`}>
                <div className={`${styles.AvatarHalfSec}`}>
                    <div className={`${styles.AvatarHalfIcon}`}>{tenants[0]?.user_image ? <img src={tenants[0]?.user_image} alt='' /> : <span>{tenants[0]?.full_name?.charAt(0).toUpperCase()}</span>}</div>
                    <div className={`${styles.AvatarHalfIcon}`}>{tenants[1]?.user_image ? <img src={tenants[1]?.user_image} alt='' /> : <span>{tenants[1]?.full_name?.charAt(0).toUpperCase()}</span>}</div>
                </div>
                <div className={`${styles.AvatarHalfSec}`}>
                    <div className={`${styles.AvatarHalfIcon}`}>{tenants[2]?.user_image ? <img src={tenants[2]?.user_image} alt='' /> : <span>{tenants[2]?.full_name?.charAt(0).toUpperCase()}</span>}</div>
                    <div className={`${styles.AvatarHalfIcon}`}>{tenants[3]?.user_image ? <img src={tenants[3]?.user_image} alt='' /> : <span>{tenants[3]?.full_name?.charAt(0).toUpperCase()}</span>}</div>
                </div>
            </div>}
            {/* ******** For four tenant ********* */}

            {/* ******** For triple tenant ********* */}
            {tenants.length === 3 && <div className={`${styles.AvatarSec}`}>
                <div className={`${styles.AvatarHalfSec} ${styles.Full}`}>
                    <div className={`${styles.AvatarHalfIcon}`}>{tenants[0]?.user_image ? <img src={tenants[0]?.user_image} alt='' /> : <span>{tenants[0]?.full_name?.charAt(0).toUpperCase()}</span>}</div>
                </div>
                <div className={`${styles.AvatarHalfSec}`}>
                    <div className={`${styles.AvatarHalfIcon}`}>{tenants[1]?.user_image ? <img src={tenants[1]?.user_image} alt='' /> : <span>{tenants[1]?.full_name?.charAt(0).toUpperCase()}</span>}</div>
                    <div className={`${styles.AvatarHalfIcon}`}>{tenants[2]?.user_image ? <img src={tenants[2]?.user_image} alt='' /> : <span>{tenants[2]?.full_name?.charAt(0).toUpperCase()}</span>}</div>
                </div>
            </div>}
            {/* ******** For triple tenant ********* */}

            {/* ******** For double tenant ********* */}
            {tenants.length === 2 && <div className={`${styles.AvatarSec}`}>
                <div className={`${styles.AvatarHalfSec} ${styles.Full}`}>
                    <div className={`${styles.AvatarHalfIcon}`}>{tenants[0]?.user_image ? <img src={tenants[0]?.user_image} alt='' /> : <span>{tenants[0]?.full_name?.charAt(0).toUpperCase()}</span>}</div>
                </div>
                <div className={`${styles.AvatarHalfSec} ${styles.Full}`}>
                    <div className={`${styles.AvatarHalfIcon}`}> {tenants[1]?.user_image ? <img src={tenants[1]?.user_image} alt='' /> : <span>{tenants[1]?.full_name?.charAt(0).toUpperCase()}</span>}</div>
                </div>
            </div>}
            {/* ******** For double tenant ********* */}

            {/* ******** For single tenant ********* */}
            {tenants.length === 1 && <div className={`${styles.AvatarSec} ${styles.Full}`}>
                <div className={`${styles.AvatarHalfSec}`}>
                    <div className={`${styles.AvatarHalfIcon}`}> {tenants[0]?.user_image ? <img src={tenants[0]?.user_image} alt='' /> : <span>{tenants[0]?.full_name?.charAt(0).toUpperCase()}</span>}</div>
                </div>
            </div>}
            {/* ******** For single tenant ********* */}
        </>
    )
}

export default PropertyTenantsList