import React, { useState, useEffect } from "react";
import styles from "../../propertydetails.module.css";
import { Link } from "react-router-dom";
import { Search, Trash2, X, Copy, ChevronDown, ArrowLeft, Filter } from "react-feather";
import { Tooltip, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TablePagination, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import axios from "axios";
import { toast } from "react-smart-toaster";
import { Dropdown } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "../../../../components/Loader";
import { AddLogCallAPI } from "../../../../components/AddLogs";
import AddApartmentForm from "../AddApartmentForm";

const RentedToggleReadOnly = React.forwardRef(({ children, onClick }, ref) => (
    <button
        ref={ref}
        className={`${styles.ReferStatusBU} ${styles.ReferStatusBUNew} ${styles.StatusBuGreen}`}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        Rented
    </button>
));

const VacantToggleReadOnly = React.forwardRef(({ children, onClick }, ref) => (
    <button
        ref={ref}
        className={`${styles.ReferStatusBU} ${styles.ReferStatusBUNew} ${styles.StatusBuOpen}`}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        Available
    </button>
));

const UnderConstructionToggleReadOnly = React.forwardRef(({ children, onClick }, ref) => (
    <button
        ref={ref}
        className={`${styles.ReferStatusBU} ${styles.ReferStatusBUNew} ${styles.StatusBuYellow}`}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        Coming Soon
    </button>
));

const NotListedToggleReadOnly = React.forwardRef(({ children, onClick }, ref) => (
    <button
        ref={ref}
        className={`${styles.ReferStatusBU} ${styles.ReferStatusBUNew} ${styles.StatusBuRed}`}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        Not Listed
    </button>
));

const VacantToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
        ref={ref}
        className={`${styles.ReferStatusBU} ${styles.StatusBuOpen}`}
        onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClick(e);
        }}
    >
        Available <ChevronDown className={`${styles.DownArrowStatus}`} />
    </button>
));

const UnderConstructionToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
        ref={ref}
        className={`${styles.ReferStatusBU} ${styles.StatusBuYellow}`}
        onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClick(e);
        }}
    >
        Coming Soon <ChevronDown className={`${styles.DownArrowStatus}`} />
    </button>
));

const NotListedToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
        ref={ref}
        className={`${styles.ReferStatusBU} ${styles.StatusBuRed}`}
        onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClick(e);
        }}
    >
        Not Listed <ChevronDown className={`${styles.DownArrowStatus}`} />
    </button>
));

const ApartmentTab = (props) => {
    const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
    const [loading, setLoading] = useState(false);
    const [showAddApartmentForm, setShowAddApartmentForm] = useState(false);
    const [showEditApartmentForm, setShowEditApartmentForm] = useState(false);
    const [editApartmentData, setEditApartmentData] = useState(null);
    const [allApartmentList, setAllApartmentList] = useState([]);
    const [apartmentList, setApartmentList] = useState([]);
    const [page, setPage] = useState(0);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [unitModalOpen, setUnitModalOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(0);
    const [isArchived, setIsArchived] = useState(0);
    const [filterData, setFilterData] = useState({ searchKey: "", status: [0, 1, 2, 3] });
    const [perPage, setPerPage] = useState(5);
    const [filterText, setFilterText] = useState("All");


    useEffect(() => {
        if (props?.property_units)
            setAllApartmentList(props.property_units);
    }, [props?.property_units]);

    useEffect(() => {
        let filteredList = allApartmentList;

        if (filterData.searchKey !== "") {
            let inputVal = filterData.searchKey;
            inputVal = inputVal.toLowerCase();
            filteredList = filteredList.filter((item) => item.apt_no.toLowerCase().includes(inputVal) || item.apt_type.toLowerCase().includes(inputVal) || item.floor.toString().toLowerCase().includes(inputVal) || item.bathroom.toString().toLowerCase().includes(inputVal) || item.size.toString().toLowerCase().includes(inputVal) || item.rent.toString().toLowerCase().includes(inputVal) || item.layout.toLowerCase().includes(inputVal));
        }

        filteredList = filteredList.filter((item) => filterData.status?.indexOf(item.current_status) > -1);

        setApartmentList(filteredList);
    }, [allApartmentList, filterData]);


    const changeCStatus = async (data, cVal) => {
        if (data.current_status !== cVal && cVal !== 2) {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
            setLoading(true);

            axios.put(process.env.REACT_APP_PROPERTY_API_URL + "v1/properties/" + props?.property_id + "/units/" + data.id, { current_status: cVal }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                setLoading(false);

                props.setSnackbarMsg(response.data.message);
                props.setSnackbarOpen(true);

                let tempDataList = allApartmentList.map((item) => {
                    if (item.id === data.id) {
                        return { ...item, current_status: cVal };
                    }
                    return item;
                });

                props.setPropertyData(prev => {
                    return { ...prev, property_units: tempDataList };
                });

                let statusText = "Available";
                if (response.data.data.current_status === 0) {
                    statusText = "Not Listed";
                }
                if (response.data.data.current_status === 1) {
                    statusText = "Available";
                }
                if (response.data.data.current_status === 2) {
                    statusText = "Rented ";
                }
                let logData = {
                    title: "Apartment status changed",
                    description: [
                        "Unit No: " + response.data.data.apt_no,
                        "Unit Type: " + response.data.data.apt_type,
                        "Property: " + props?.property_name,
                        "Status: " + statusText,
                    ],
                };
                AddLogCallAPI(logData, token);
            }).catch((error) => {
                if (typeof error.response !== "undefined")
                    toast.error(error.response.data.message);
                setLoading(false);
            });
        }
    }

    const addApartmentRow = (data) => {
        props.setPropertyData(prev => {
            let propertyUnitList = [data].concat(prev?.property_units);
            return { ...prev, property_units: propertyUnitList };
        });
    }

    const editApartmentRow = (data) => {
        props.setPropertyData(prev => {
            let unitList = prev?.property_units?.map(i => {
                if (i?.id === data?.id)
                    return data;
                return i;
            });
            return { ...prev, property_units: unitList };
        });
    }

    const addLayoutRow = (data) => {
        props.setPropertyData(prev => {
            let layoutList = [data].concat(prev?.layouts);
            return { ...prev, layouts: layoutList };
        });
    }


    const deleteConfirm = async () => {
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
        setDeleteModalOpen(false);
        setLoading(true);

        return axios.delete(process.env.REACT_APP_PROPERTY_API_URL + "v1/properties/" + props?.property_id + "/units/" + selectedId, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            setLoading(false);

            props.setSnackbarMsg(response.data.message);
            props.setSnackbarOpen(true);

            let tempDataList = allApartmentList.filter((item) => item.id !== selectedId);

            props.setPropertyData(prev => {
                return { ...prev, property_units: tempDataList };
            });

            setSelectedId(0);

            let logData = {
                title: "Apartment is deleted",
                description: [
                    "Unit No: " + response.data.data.apt_no,
                    "Unit Type: " + response.data.data.apt_type,
                    "Property: " + props?.property_name
                ],
            };
            AddLogCallAPI(logData, token);
        }).catch((error) => {
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
            setLoading(false);
        });
    }

    const archiveConfirm = async () => {
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
        setDeleteModalOpen(false);
        setLoading(true);

        let is_archived = 1 - isArchived;

        axios.put(process.env.REACT_APP_PROPERTY_API_URL + "v1/properties/" + props?.property_id + "/units/" + selectedId, { is_archived: is_archived }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            setLoading(false);

            props.setSnackbarMsg(response.data.message);
            props.setSnackbarOpen(true);

            let tempDataList = allApartmentList.map((item) => {
                if (item.id === selectedId) {
                    return { ...item, is_archived: is_archived };
                }
                return item;
            });

            props.setPropertyData(prev => {
                return { ...prev, property_units: tempDataList };
            });

            setSelectedId(0);

            let logData = {
                title: is_archived ? "Apartment is archived" : "Apartment is unarchived",
                description: [
                    "Unit No: " + response.data.data.apt_no,
                    "Unit Type: " + response.data.data.apt_type,
                    "Property: " + props?.property_name
                ],
            };
            AddLogCallAPI(logData, token);
        }).catch((error) => {
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
            setLoading(false);
        });
    }

    const copyUnitConfirm = async () => {
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
        setUnitModalOpen(false);
        setLoading(true);

        return axios.post(process.env.REACT_APP_PROPERTY_API_URL + "v1/properties/" + props?.property_id + "/copy-unit/" + selectedId, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            setLoading(false);

            props.setSnackbarMsg(response.data.message);
            props.setSnackbarOpen(true);

            addApartmentRow(response.data.data);

            let logData = {
                title: "Apartment is copied",
                description: [
                    "Unit No: " + response.data.data.apt_no,
                    "Unit Type: " + response.data.data.apt_type,
                    "Property: " + props?.property_name
                ],
            };
            AddLogCallAPI(logData, token);
        }).catch((error) => {
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
            setLoading(false);
        });
    }

    return (<>
        {loading && <Loader />}

        <div className={`${styles.NotesRow}`}>

            <div className={`${styles.NotesSearchArea}`}>
                <div className={`${styles.NotesSearchMain}`}>
                    <Search />
                    <input type="text" placeholder="Search" className={`${styles.searchInput}`} onChange={(e) => setFilterData(prev => { return { ...prev, searchKey: e.target.value } })} />
                </div>
                <div className={`CusSelectFilter Filter`}>
                    <Dropdown>
                        <Dropdown.Toggle className={`${styles.ActionsBULine} ActionsBULine`} id="dropdown-basic">
                            <span className={`${styles.ReqBigFilter}`}>Show: {filterText} <ChevronDown /></span>
                            <span className={`${styles.ReqSmallFilter}`}><Filter /></span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className={`${styles.ActionsBUList} ActionsBUList`}>
                            <FormGroup row className={`${styles.CheckFilter}`}>
                                <FormControlLabel
                                    control={<Checkbox
                                        name="All"
                                        color="primary"
                                        checked={filterData?.status?.indexOf(0) > -1 && filterData?.status?.indexOf(1) > -1 && filterData?.status?.indexOf(2) > -1 && filterData?.status?.indexOf(3) > -1}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setFilterData(prev => {
                                                    return { ...prev, status: [0, 1, 2, 3] }
                                                });
                                                setFilterText("All");
                                            } else {
                                                setFilterData(prev => {
                                                    return { ...prev, status: [] }
                                                });
                                                setFilterText("None");
                                            }
                                        }}
                                    />}
                                    label="All"
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        name="rented"
                                        color="primary"
                                        checked={filterData?.status?.indexOf(2) > -1}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setFilterData(prev => {
                                                    let statusTemp = prev?.status;
                                                    if (statusTemp?.indexOf(2) === -1) {
                                                        statusTemp.push(2);
                                                    }
                                                    return { ...prev, status: statusTemp }
                                                });
                                            } else {
                                                setFilterData(prev => {
                                                    let statusTemp = prev?.status;
                                                    if (statusTemp?.indexOf(2) > -1) {
                                                        statusTemp.splice(statusTemp?.indexOf(2), 1);
                                                    }
                                                    return { ...prev, status: statusTemp }
                                                });
                                            }
                                            if (filterData?.status?.length === 4) {
                                                setFilterText("All");
                                            } else if (filterData?.status?.length === 0) {
                                                setFilterText("None");
                                            } else {
                                                setFilterText(filterData?.status?.length + " Selected");
                                            }
                                        }}
                                    />
                                    }
                                    label="Rented"
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        name="available"
                                        color="primary"
                                        checked={filterData?.status?.indexOf(1) > -1}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setFilterData(prev => {
                                                    let statusTemp = prev?.status;
                                                    if (statusTemp?.indexOf(1) === -1) {
                                                        statusTemp.push(1);
                                                    }
                                                    return { ...prev, status: statusTemp }
                                                });
                                            } else {
                                                setFilterData(prev => {
                                                    let statusTemp = prev?.status;
                                                    if (statusTemp?.indexOf(1) > -1) {
                                                        statusTemp.splice(statusTemp?.indexOf(1), 1);
                                                    }
                                                    return { ...prev, status: statusTemp }
                                                });
                                            }
                                            if (filterData?.status?.length === 4) {
                                                setFilterText("All");
                                            } else if (filterData?.status?.length === 0) {
                                                setFilterText("None");
                                            } else {
                                                setFilterText(filterData?.status?.length + " Selected");
                                            }
                                        }}
                                    />}
                                    label="Available"
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        name="comingsoon"
                                        color="primary"
                                        checked={filterData?.status?.indexOf(3) > -1}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setFilterData(prev => {
                                                    let statusTemp = prev?.status;
                                                    if (statusTemp?.indexOf(3) === -1) {
                                                        statusTemp.push(3);
                                                    }
                                                    return { ...prev, status: statusTemp }
                                                });
                                            } else {
                                                setFilterData(prev => {
                                                    let statusTemp = prev?.status;
                                                    if (statusTemp?.indexOf(3) > -1) {
                                                        statusTemp.splice(statusTemp?.indexOf(3), 1);
                                                    }
                                                    return { ...prev, status: statusTemp }
                                                });
                                            }
                                            if (filterData?.status?.length === 4) {
                                                setFilterText("All");
                                            } else if (filterData?.status?.length === 0) {
                                                setFilterText("None");
                                            } else {
                                                setFilterText(filterData?.status?.length + " Selected");
                                            }
                                        }}
                                    />}
                                    label="Coming Soon"
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        name="notlisted"
                                        color="primary"
                                        checked={filterData?.status?.indexOf(0) > -1}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setFilterData(prev => {
                                                    let statusTemp = prev?.status;
                                                    if (statusTemp?.indexOf(0) === -1) {
                                                        statusTemp.push(0);
                                                    }
                                                    return { ...prev, status: statusTemp }
                                                });
                                            } else {
                                                setFilterData(prev => {
                                                    let statusTemp = prev?.status;
                                                    if (statusTemp?.indexOf(0) > -1) {
                                                        statusTemp.splice(statusTemp?.indexOf(0), 1);
                                                    }
                                                    return { ...prev, status: statusTemp }
                                                });
                                            }
                                            if (filterData?.status?.length === 4) {
                                                setFilterText("All");
                                            } else if (filterData?.status?.length === 0) {
                                                setFilterText("None");
                                            } else {
                                                setFilterText(filterData?.status?.length + " Selected");
                                            }
                                        }}
                                    />}
                                    label="Not Listed"
                                />
                            </FormGroup>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className={`${styles.clientDoubleButton}`}>
                    {isAuthenticated && user["https://propertydek.com/permissions"].indexOf("property:update") > -1 && (<div>
                        <button className={`${styles.AddBUUnit}`} onClick={(e) => { setShowAddApartmentForm(true); setEditApartmentData(null); }}>
                            <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg> Unit
                        </button>
                    </div>)}
                </div>
            </div>

            {apartmentList.map((item, index) => {
                return (perPage === -1 || (index >= page * perPage && index < page * perPage + perPage)) ? (<div className={`${styles.NotesCard}`} key={index} style={{ cursor: 'pointer' }} onClick={(e) => { setShowEditApartmentForm(true); setEditApartmentData(item); }}>
                    <div className={`${styles.PropeCardInFR}`}>
                        <div className={`${styles.MainReqConDivBig}`}>
                            <div className={`${styles.CardTiRow}`}>
                                <p className={`${styles.InsideConName}`}><span className={`${styles.ConNameTrancate}`}>{item.apt_no}</span></p>
                            </div>
                            <div className={`${styles.MainReqConUR}`}>
                                <p className={`${styles.MainReqConDe}`}>${item.formatted_rent}</p>
                                <p className={`${styles.MainReqConDe}`}>
                                    <span>{item.layout}</span>
                                </p>
                                <p className={`${styles.MainReqConDe}`}>
                                    <span>Floor {item.floor}</span>
                                </p>
                                <p className={`${styles.MainReqConDe}`}>
                                    <span>{parseInt(item.size)} sq ft</span>
                                </p>
                            </div>
                        </div>
                        <div className={`${styles.MainReqConDivSmall}`}>
                            <p className={`${styles.MainReqConDe}`}>
                                <span>{item.apt_type} - {item.bathroom} bathroom</span>
                            </p>
                            <div className={`${styles.MainReqConUR}`}>
                                {/* <p className={`${styles.MainReqConDe}`}>{item.tenant_name ? item.tenant_name : "---"}</p> */}
                                {item.tenant_name && <p className={`${styles.MainReqConDe}`}><Link to={"/people/tenants/details/" + item.current_tenant}>{item.tenant_name}</Link></p>}
                                {!item.tenant_name && <p className={`${styles.MainReqConDe}`}>---</p>}
                            </div>
                        </div>
                        <div className={`${styles.MainReqConDivSmall}`}>
                            <div className={`${styles.MainReqConDeTwo}`}># of Tours: <span>{item?.tour_list?.completed}/{item?.tour_list?.total}</span></div>
                            <div className={`${styles.MainReqConDeTwo}`}>Days Available: <span>{item?.available_days}</span></div>
                        </div>

                        <div className={`${styles.MainReqConAction}`}>
                            {item.is_archived === 1 && (<div>
                                <p className={`${styles.ReffStatus} ${styles.Archived}`}>Archived</p>
                            </div>)}

                            {item.is_archived === 0 && !(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("request:update") > -1) && (<Dropdown>
                                {item.current_status === 2 && (<Dropdown.Toggle as={RentedToggleReadOnly} />)}
                                {item.current_status === 1 && (<Dropdown.Toggle as={VacantToggleReadOnly} />)}
                                {item.current_status === 3 && (<Dropdown.Toggle as={UnderConstructionToggleReadOnly} />)}
                                {item.current_status === 0 && (<Dropdown.Toggle as={NotListedToggleReadOnly} />)}
                            </Dropdown>)}

                            {item.is_archived === 0 && isAuthenticated && user["https://propertydek.com/permissions"].indexOf("request:update") > -1 && (<Dropdown>
                                {item.current_status === 2 && (<Dropdown.Toggle as={RentedToggleReadOnly} />)}
                                {item.current_status === 1 && (<Dropdown.Toggle as={VacantToggle} />)}
                                {item.current_status === 3 && (<Dropdown.Toggle as={UnderConstructionToggle} />)}
                                {item.current_status === 0 && (<Dropdown.Toggle as={NotListedToggle} />)}
                                {item.current_status !== 2 && (<Dropdown.Menu className={`${styles.ActionsBUList} ActionsBUList`}>
                                    <Dropdown.Item className="DropDListWIcon" onClick={(e) => { e.preventDefault(); e.stopPropagation(); changeCStatus(item, 1); }}>Available</Dropdown.Item>
                                    <Dropdown.Item className="DropDListWIcon" onClick={(e) => { e.preventDefault(); e.stopPropagation(); changeCStatus(item, 3); }}>Coming Soon</Dropdown.Item>
                                    <Dropdown.Item className="DropDListWIcon" onClick={(e) => { e.preventDefault(); e.stopPropagation(); changeCStatus(item, 0); }}>Not Listed</Dropdown.Item>
                                </Dropdown.Menu>)}
                            </Dropdown>)}

                            {isAuthenticated && user["https://propertydek.com/permissions"].indexOf("property:update") > -1 && (<>
                                <Tooltip title="Copy">
                                    <Button className={`${styles.ProTrash}`} onClick={(e) => { e.preventDefault(); e.stopPropagation(); setUnitModalOpen(true); setSelectedId(item.id); }}><Copy /></Button>
                                </Tooltip>
                                <Tooltip title="Delete">
                                    <Button className={`${styles.ProTrash}`} onClick={(e) => { e.preventDefault(); e.stopPropagation(); setDeleteModalOpen(true); setSelectedId(item.id); setIsArchived(item.is_archived); }}><Trash2 /></Button>
                                </Tooltip>
                            </>)}
                        </div>
                    </div>
                </div>) : null;
            })}

            {apartmentList.length > 0 && (<div className="Pagination TablePaginationNew">
                <TablePagination
                    count={apartmentList.length}
                    color="primary"
                    page={page}
                    rowsPerPage={perPage}
                    onPageChange={(e, v) => setPage(v)}
                    rowsPerPageOptions={[
                        { label: '5', value: 5 },
                        { label: '10', value: 10 },
                        { label: '25', value: 25 },
                        { label: 'All', value: -1 },
                    ]}
                    onRowsPerPageChange={(e) => {
                        setPage(0);
                        setPerPage(e.target.value);
                    }}
                    labelRowsPerPage={'Apartments per Page:'}
                />
            </div>)}

            {apartmentList.length === 0 && (<div className={`${styles.NoDataMain}`}>
                <div className={`${styles.NoDataIMG}`}>
                    <img src="/static/img/no-property.png" alt="No Data" />
                </div>
                <p className={`${styles.NoDataText}`}>Unfortunately!</p>
                <p className={`${styles.NoDataTextSub}`}>We haven't find any apartment at this stage.</p>
            </div>)}
        </div>

        <Dialog
            open={deleteModalOpen}
            fullWidth
            maxWidth="sm"
            onClose={(e) => setDeleteModalOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp"
        >
            <DialogTitle id="alert-dialog-title">Would you like to {isArchived ? "unarchive" : "archive"} or delete?<button onClick={(e) => setDeleteModalOpen(false)}><X /></button>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div className="ModalFormGroup">
                        <label className="PopupBodyText">{isArchived ? "Delete will remove information permanently. Unarchive will make it Active but it can be archive at anytime." : "Delete will remove information permanently. Archive will remove it from your account but it can be unarchive at anytime."}</label>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <button onClick={(e) => deleteConfirm()} className="CancelPopupBU">Delete</button>
                <button onClick={(e) => archiveConfirm()} className="ArchivePopupBU">{isArchived ? "Unarchive" : "Archive"}</button>
                <button onClick={(e) => setDeleteModalOpen(false)} className="SubmitPopupBU">Cancel</button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={unitModalOpen}
            fullWidth
            maxWidth="xs"
            onClose={(e) => setUnitModalOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp"
        >
            <DialogTitle id="alert-dialog-title">Copy Apartment<button onClick={(e) => setUnitModalOpen(false)}><X /></button>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div className="ModalFormGroup">
                        <label className="PopupBodyText">Are you sure you want to copy this apartment?</label>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <button onClick={(e) => setUnitModalOpen(false)} className="CancelPopupBU">Cancel</button>
                <button onClick={(e) => copyUnitConfirm()} className="SubmitPopupBU">Copy</button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={showAddApartmentForm}
            onClose={(e) => setShowAddApartmentForm(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp MobileForm"
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle id="alert-dialog-title">
                <button className={`${styles.BackAddBU}`} onClick={(e) => setShowAddApartmentForm(false)}><ArrowLeft /></button>
                <span>Add Unit</span>
                <button onClick={(e) => setShowAddApartmentForm(false)}><X /></button>
            </DialogTitle>
            <DialogContent>
                <AddApartmentForm layouts={props.layouts} no_of_floor={props.no_of_floor} setShowAddApartmentForm={setShowAddApartmentForm.bind(this)} property_id={props.property_id} addLayoutRow={addLayoutRow.bind(this)} addApartmentRow={addApartmentRow.bind(this)} property_name={props?.property_name} setSnackbarOpen={props?.setSnackbarOpen.bind(this)} setSnackbarMsg={props?.setSnackbarMsg.bind(this)} editApartmentData={null} />
            </DialogContent>
        </Dialog>

        <Dialog
            open={showEditApartmentForm}
            onClose={(e) => setShowEditApartmentForm(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp MobileForm"
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle id="alert-dialog-title">
                <button className={`${styles.BackAddBU}`} onClick={(e) => setShowEditApartmentForm(false)}><ArrowLeft /></button>
                <span>Edit Unit</span>
                <button onClick={(e) => setShowEditApartmentForm(false)}><X /></button>
            </DialogTitle>
            <DialogContent>
                <AddApartmentForm layouts={props.layouts} no_of_floor={props.no_of_floor} setShowAddApartmentForm={setShowEditApartmentForm.bind(this)} property_id={props.property_id} addLayoutRow={addLayoutRow.bind(this)} addApartmentRow={editApartmentRow.bind(this)} property_name={props?.property_name} setSnackbarOpen={props?.setSnackbarOpen.bind(this)} setSnackbarMsg={props?.setSnackbarMsg.bind(this)} editApartmentData={editApartmentData} />
            </DialogContent>
        </Dialog>
    </>)
}

export default ApartmentTab;