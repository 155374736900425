import React, { useEffect, useState } from "react";
import styles from "../renrolldetails.module.css";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import dayjs from "dayjs";
import NumberFormat from "react-number-format";
import { AddLogCallAPI } from "../../../components/AddLogs";
import { toast } from "react-smart-toaster";

const MoneyField = (props) => {
    return (<NumberFormat
        prefix=""
        displayType="input"
        type="text"
        thousandSeparator={true}
        allowNegative={false}
        decimalScale={2}
        fixedDecimalScale
        {...props}
    />);
}

const schema = yup.object().shape({
    amount: yup.string().required("This field is Required").test("non-zero-validate", "This field should be $1 or greater.", (value) => parseInt(value) > 0).test("max-4-digit", "Amount can not be more than 6 digits.", (value) => {
        return parseInt(value?.replace(/,/g, "")) < 10000;
    }),
    description: yup.string().required("This field is Required").max(100, "Description cannot be more than 100 characters"),
    date: yup.date().required("This field is Required"),
    payment_type: yup.string().required("This field is Required"),
});

const RecordPayment = ({ rent_roll_id, setModalShow, setLoading, setSnackbarMsg, setSnackbarOpen, addData, updateData, isUpdate, editId }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [rentRollData, setRentRollData] = useState(null);
    const [charsLeft, setCharsLeft] = useState({ description: 100 });
    const [paymentType, setPaymentType] = useState('');

    const { handleSubmit, control, errors, setValue, clearErrors } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            date: dayjs(),
            payment_type: '',
            amount: '',
            description: '',
        },
    });

    useEffect(() => {
        async function fetchDetails() {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
            await axios.get(process.env.REACT_APP_PAYMENT_API_URL + "v1/rent-rolls/" + rent_roll_id, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                setRentRollData(response.data.data);
            });
        }
        fetchDetails();
    }, [rent_roll_id, getAccessTokenSilently]);

    useEffect(() => {
        async function fetchRentRollDet() {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            await axios.get(process.env.REACT_APP_PAYMENT_API_URL + "v1/rent-rolls/" + rent_roll_id + "/list/" + editId, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then((response) => {
                const resData = response.data.data;
                setValue("amount", resData.amount);
                setValue("description", resData.description);
                setValue("date", dayjs(resData.date));
                setValue("payment_type", 'other');

                setCharsLeft((prev) => {
                    return { ...prev, description: 100 - resData.description.length };
                });
            }).catch((error) => {
                if (typeof error.response !== "undefined")
                    toast.error(error.response.data.message);
            });
        }
        if (isUpdate) {
            fetchRentRollDet();
        }
    }, [isUpdate, getAccessTokenSilently, editId, setValue, rent_roll_id]);

    const onSubmit = async (data) => {
        setLoading(true);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        let postData = {
            description: data.description,
            amount: data.amount?.replace(/,/g, ""),
            date: dayjs(data.date).format("YYYY-MM-DD"),
            type: 2,
            payment_type: "record-payment",
        };

        return axios.post(process.env.REACT_APP_PAYMENT_API_URL + "v1/rent-rolls/" + rent_roll_id + "/list", postData, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then((response) => {
            setLoading(false);
            setModalShow(false);
            setSnackbarMsg(response.data.message);
            setSnackbarOpen(true);
            let resData = response.data.data;

            addData(resData);

            let logData = {
                title: "A new payment is added",
                description: [
                    "Tenant: " + rentRollData?.tenant_name,
                    "Property: " + rentRollData?.property_name + " - " + rentRollData?.apt_name,
                    "Amount: $" + resData.formatted_amount,
                    "Apply On: " + dayjs(resData.date).format("L"),
                    "Memo: " + resData.description,
                ],
            };
            AddLogCallAPI(logData, token);
        }).catch((error) => {
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
            setLoading(false);
        });
    }

    const onUpdate = async (data) => {
        setLoading(true);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        let postData = {
            description: data.description,
            amount: data.amount?.replace(/,/g, ""),
            date: dayjs(data.date).format("YYYY-MM-DD"),
            type: 2,
            payment_type: "record-payment",
        }

        return axios.put(process.env.REACT_APP_PAYMENT_API_URL + "v1/rent-rolls/" + rent_roll_id + "/list/" + editId, postData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setLoading(false);
            setModalShow(false);
            setSnackbarMsg(response.data.message);
            setSnackbarOpen(true);
            let resData = response.data.data;

            updateData(resData);

            let logData = {
                title: "Payment is updated",
                description: [
                    "Tenant: " + rentRollData?.tenant_name,
                    "Property: " + rentRollData?.property_name + " - " + rentRollData?.apt_name,
                    "Amount: $" + resData.formatted_amount,
                    "Apply On: " + dayjs(resData.date).format("L"),
                    "Memo: " + resData.description,
                ],
            };
            AddLogCallAPI(logData, token);
        }).catch((error) => {
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
            setLoading(false);
        });
    }

    return (<div className={`${styles.AddSection}`}>
        <div className={`${styles.DetailsDiv}`}>
            <div className={`${styles.DetailsDivHalf}`}>
                <p className={`${styles.DetailsTitle}`}>Current Balance</p>
                <p className={`${styles.DetailsDes}`}>
                    <span>${rentRollData?.formatted_balance}</span> {rentRollData?.balance > 0 ? "Outstanding" : rentRollData?.balance < 0 ? "Over Paid" : "Balance"}
                </p>
            </div>
            <div className={`${styles.DetailsDivHalf}`}>
                <p className={`${styles.DetailsTitle}`}>Renters</p>
                <p className={`${styles.DetailsDes}`}>{rentRollData?.tenant_name}</p>
            </div>
        </div>
        <form className={`${styles.AddLayoutForm}`} onSubmit={handleSubmit(isUpdate ? onUpdate : onSubmit)}>
            <div className={`${styles.SMSmallSideBody}`}>
                <div className={`${styles.AddUserMain}`}>
                    <div className={`${styles.FormRow}`}>
                        <div className={`${styles.FormGroupFull} FormGroup`}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Select Amount Type*</InputLabel>
                                <Controller
                                    name="payment_type"
                                    control={control}
                                    render={(props) => (
                                        <Select
                                            {...props}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Select Amount Type*"
                                            onChange={(e) => {
                                                props.onChange(e);
                                                setPaymentType(e?.target?.value);
                                                if (e?.target?.value === 'full') {
                                                    setValue('amount', rentRollData?.balance);
                                                } else {
                                                    setValue('amount', '');
                                                }

                                                clearErrors();
                                            }}
                                        >
                                            <MenuItem value="full">Current Balance</MenuItem>
                                            <MenuItem value="other">Other Amount</MenuItem>
                                        </Select>
                                    )}
                                />
                                {errors.payment_type && (<p className={`${styles.ErrorM}`}>{errors.payment_type.message}</p>)}
                            </FormControl>
                        </div>
                        <div className={`${styles.FormGroupFull} FormGroup`}>
                            <Controller
                                name="amount"
                                control={control}
                                render={(props) => (
                                    <TextField
                                        {...props}
                                        id="outlined-basic"
                                        label="Amount*"
                                        variant="outlined"
                                        InputProps={{ inputComponent: MoneyField }}
                                        disabled={paymentType === 'full'}
                                        onChange={(e) => {
                                            props?.onChange(e);
                                            setValue('payment_type', 'other');
                                        }}
                                    />
                                )}
                            />
                            {errors.amount && (<p className={`${styles.ErrorM}`}>{errors.amount.message}</p>)}
                        </div>
                    </div>
                    <div className={`${styles.FormRow}`}>
                        <div className={`${styles.FormGroupFull} FormGroup`}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Controller
                                    control={control}
                                    name="date"
                                    render={(fields) => (
                                        <DatePicker
                                            {...fields}
                                            label="Due on*"
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    )}
                                />
                                {errors.date && (<p className={`${styles.ErrorM}`}>{errors.date.message}</p>)}
                            </LocalizationProvider>
                        </div>
                    </div>
                    <div className={`${styles.FormRow}`}>
                        <div className={`${styles.FormGroupFull} FormGroup`}>
                            <Controller
                                name="description"
                                control={control}
                                render={(props) => (
                                    <TextField
                                        {...props}
                                        id="outlined-basic"
                                        label="Memo for your renters*"
                                        variant="outlined"
                                        multiline
                                        rows={4}
                                        onChange={(e) => {
                                            props.onChange(e);
                                            setCharsLeft((prev) => {
                                                return {
                                                    ...prev,
                                                    description: 100 - e.target.value.length,
                                                };
                                            })
                                        }}
                                        inputProps={{ maxLength: 100 }}
                                    />
                                )}
                            />
                            <p className={`${styles.Example}`}>{charsLeft?.description > 1 ? charsLeft?.description + " characters remaining" : charsLeft?.description + " character remaining"}</p>
                            {errors.description && (<p className={`${styles.ErrorM}`}>{errors.description.message}</p>)}
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${styles.ButtonDiv}`}>
                <button type="button" className="CancelPopupBU" onClick={(e) => setModalShow(false)}>Cancel</button>
                <button type="submit" className="SubmitPopupBU" >{isUpdate ? 'Update Payment' : 'Record Payment'}</button>
            </div>
        </form>
    </div>)
}

export default RecordPayment; 