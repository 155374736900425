import styles from "../../../../maintenancerequestdetails.module.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-smart-toaster";
import { useAuth0 } from "@auth0/auth0-react";
import { AddLogCallAPI } from "../../../../../../components/AddLogs";
import { TextField, IconButton, Button } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { FileText, X } from "react-feather";
import { makeStyles } from "@material-ui/core/styles";

const schema = yup.object().shape({
  contractor_name: yup.string().required("This field is Required"),
  date: yup.date().required("This field is Required"),
  description: yup.string().required("This field is Required"),
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  input: {
    display: "none",
  },
}));

const ContractorAdd = ({ appEditId, appEditData, requestData, setRequestData, setAddModalOpen, setSnackbarOpen, setSnackbarMsg, setLoading }) => {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const [uploadFile, setUploadFile] = useState(null);
  const [isFile, setIsFile] = useState(false);

  const { control, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      assignee_id: "",
      date: dayjs(),
    },
  });

  useEffect(() => {
    if (appEditData) {
      setValue("contractor_name", appEditData?.contractor_name);
      setValue("date", dayjs(appEditData?.date));
      setValue("description", appEditData?.description);

      if (appEditData?.file_path && appEditData?.file_path !== "") {
        setIsFile(true);
      }
    }
  }, [appEditData, setValue]);

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("contractor_name", data?.contractor_name);
    formData.append("date", dayjs(data?.date).format("YYYY-MM-DD"));
    formData.append("description", data?.description);

    if (uploadFile) {
      formData.append("receipt", uploadFile);
    }

    setAddModalOpen(false);
    setLoading(true);

    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

    if (appEditId === 0) {
      return axios.post(process.env.REACT_APP_CASE_API_URL + "v1/maintenance-requests/" + requestData?.id + "/contractors", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        setLoading(false);

        setSnackbarOpen(true);
        setSnackbarMsg(response.data.message);

        setRequestData((prev) => {
          let contractors = [response.data.data].concat(prev?.contractors);
          return { ...prev, contractors: contractors };
        });

        let logData = {
          title: "Maintenance request new contractor is created",
          description: [
            "Name: " + requestData?.title,
            "Request number: " + requestData?.request_no,
            "Property: " + requestData?.property_name,
            "Apartment: " + requestData?.apt_name,
            "Tenant: " + requestData?.tenant_name,
          ],
        };
        AddLogCallAPI(logData, token);
      }).catch((error) => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
        setLoading(false);
      });
    } else {
      formData.append("old_file", appEditData?.file_path);
      axios.post(process.env.REACT_APP_CASE_API_URL + "v1/maintenance-requests/" + requestData?.id + "/contractors/" + appEditId, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        setLoading(false);

        setSnackbarOpen(true);
        setSnackbarMsg(response.data.message);

        setRequestData((prev) => {
          let contractors = prev?.contractors?.map((i) => {
            if (i?.id === appEditId) {
              return response.data.data;
            }
            return i;
          });
          return { ...prev, contractors: contractors };
        });

        let logData = {
          title: "Maintenance request contractor is updated",
          description: [
            "Name: " + requestData?.title,
            "Request number: " + requestData?.request_no,
            "Property: " + requestData?.property_name,
            "Apartment: " + requestData?.apt_name,
            "Tenant: " + requestData?.tenant_name,
          ],
        };
        AddLogCallAPI(logData, token);
      }).catch((error) => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
        setLoading(false);
      });
    }
  };

  const fileHandler = (e) => {
    setUploadFile(e.target.files[0]);
  };

  return (<div className={`${styles.ModalFormGroup}`}>
    <form onSubmit={handleSubmit(onSubmit)}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className={`${styles.PopFormGroup}`}>
          <div className={`${styles.FormGroupFull} FormGroup`}>
            <Controller
              name={`contractor_name`}
              control={control}
              render={(field) => (
                <TextField
                  {...field}
                  id="outlined-basic"
                  label="Contractor Name*"
                  variant="outlined"
                />
              )}
            />
            {errors.contractor_name && (<p className={`${styles.ErrorM}`}>{errors.contractor_name.message}</p>)}
          </div>
        </div>
        <div className={`${styles.PopFormGroup}`}>
          <div className={`${styles.FormGroupFull} FormGroup`}>
            <Controller
              name={`description`}
              control={control}
              render={(field) => (
                <TextField
                  {...field}
                  id="outlined-basic"
                  label="Work Description*"
                  variant="outlined"
                />
              )}
            />
            {errors.description && (<p className={`${styles.ErrorM}`}>{errors.description.message}</p>)}
          </div>
        </div>
        <div className={`${styles.PopFormGroup}`}>
          <div className={`${styles.Width48} FormGroup`}>
            <Controller
              className={`${styles.formControl}`}
              name="date"
              control={control}
              render={(fields) => (
                <MobileDatePicker {...fields} label="Select Date" />
              )}
            />
          </div>
        </div>
        <div>
          <input accept="image/*,.pdf" className={classes.input} id="icon-button-file" type="file" onChange={fileHandler} />
          <label htmlFor="icon-button-file">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              className={`${styles.UploadReceiptBU}`}
            >
              <FileText /> Upload Receipt
            </IconButton>
          </label>

          {uploadFile && <div className={`${styles.UploadFileList}`}>
            <FileText />
            <p>{uploadFile?.name}</p>
            <button onClick={(e) => setUploadFile(false)}>
              <X />
            </button>
          </div>}

          {(!uploadFile && isFile) && <div className={`${styles.UploadFileList}`}>
            <FileText />
            <p>Receipt</p>
            <button onClick={(e) => setIsFile(false)}>
              <X />
            </button>
          </div>}
        </div>
      </LocalizationProvider>
      <div className={`${styles.ButtonAction}`}>
        <Button className="CancelPopupBU" onClick={(e) => setAddModalOpen(false)}>Cancel</Button>
        <Button className="SubmitPopupBU" type="submit">{appEditId ? "Update" : "Add"}</Button>
      </div>
    </form>
  </div>);
};

export default ContractorAdd;