import { useEffect, useState } from "react";
import styles from "../../smartdevices.module.css";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import { toast } from "react-smart-toaster";
import { Link } from "react-router-dom";
import { TablePagination, Tooltip } from "@mui/material";

const LockList = ({ deviceDetails }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [deviceList, setDeviceList] = useState([]);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(8);

    useEffect(() => {
        const getTenantList = async () => {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            axios.get(`${process.env.REACT_APP_DEVICE_API_URL}v1/ttlock/get-gateway-locks/${deviceDetails?.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                setDeviceList(response?.data?.data);
            }).catch((error) => {
                if (typeof error.response !== "undefined")
                    toast.error(error.response.data.message);
            });
        }

        if (deviceDetails?.id)
            getTenantList();
    }, [deviceDetails?.id, getAccessTokenSilently]);

    return (<div className={`${styles.NotesRow}`}>
        {deviceList.length > 0 && deviceList.map((item, index) => {
            return (perPage === -1 || (index >= page * perPage && index < page * perPage + perPage)) ? (<Link className={`${styles.ProspectCard}`} to={`/smart-devices/details/${item.id}`}>
                <div className={`${styles.ProspectCardUnder}`}>
                    <div className={`${styles.ProspectCardHeadSection}`}>
                        <div className={`${styles.ProsIMGSec}`}>
                            {item.product_type === "Propertydek-Door Lock" && (<img src="/static/img/Door.svg" className={`${styles.ProspectCardImg}`} alt="" />)}
                            {item.product_type === "Propertydek-Light" && (<img src="/static/img/bulb.svg" className={`${styles.ProspectCardImg}`} alt="" />)}
                            {item.product_type === "Propertydek-LED Light" && (<img src="/static/img/LED-light.svg" className={`${styles.ProspectCardImg}`} alt="" />)}
                            {item.product_type === "Propertydek-Thermostat" && (<img src="/static/img/temperature.svg" className={`${styles.ProspectCardImg}`} alt="" />)}
                            {item.product_type === "Propertydek-Device Gateway" && (<img src="/static/img/router.svg" className={`${styles.ProspectCardImg}`} alt="" />)}
                            {item.product_type === "Propertydek-Lock Gateway" && (<img src="/static/img/router.svg" className={`${styles.ProspectCardImg}`} alt="" />)}
                            {item.product_type === "Propertydek-Water Meter" && (<img src="/static/img/watermeter.svg" className={`${styles.ProspectCardImg}`} alt="" />)}
                        </div>

                        <div className={`${styles.HeadConSec}`}>
                            <div className={`${styles.prospectNameActionSec}`}>
                                <p className={`${styles.ProsUserName}`}>{item.product_type}</p>
                            </div>
                            <p className={`${styles.ProsUserName}`}>{item.location}</p>
                        </div>
                    </div>
                    <div className={`${styles.ProspectCardBodySec}`}>
                        <ul>
                            <li>
                                <p className={`${styles.Title}`}>Device Name:</p>
                                <Tooltip title={item.name} placement="bottom-start"><p className={`${styles.Content}`}>{item.name}</p></Tooltip>
                            </li>
                            <li>
                                <p className={`${styles.Title}`}>Device ID:</p>
                                <p className={`${styles.Content}`}>{item.deviceID}</p>
                            </li>
                            <li>
                                <p className={`${styles.Title}`}>Property:</p>
                                <p className={`${styles.Content}`}>{item.property.name}</p>
                            </li>
                            <li>
                                <p className={`${styles.Title}`}>Unit:</p>
                                <p className={`${styles.Content}`}>{item.apartment ? item.apartment.apt_no : item.apt_text}</p>
                            </li>
                        </ul>
                    </div>
                    <div className={`${styles.ProspectCardFootSec}`}>
                        <p className={`${styles.ProsCardDate}`}>{item?.online === 0 && item.last_seen_str}</p>
                        {item?.online === 0 && <span className={`${styles.OfflineBadge}`}>Offline</span>}
                        {item?.online === 1 && <span className={`${styles.OpenBadge}`}>Online</span>}
                    </div>
                </div>
            </Link>) : null;
        })}

        {deviceList.length > 0 && (<div className="Pagination TablePaginationNew List">
            <TablePagination
                count={deviceList.length}
                color="primary"
                page={page}
                rowsPerPage={perPage}
                onPageChange={(e, v) => setPage(v)}
                rowsPerPageOptions={[
                    { label: '4', value: 4 },
                    { label: '8', value: 8 },
                    { label: '16', value: 16 },
                    { label: 'All', value: -1 },
                ]}
                onRowsPerPageChange={(e) => {
                    setPage(0);
                    setPerPage(e.target.value);
                }}
                labelRowsPerPage={'Devices per Page:'}
            />
        </div>)}

        {deviceList.length === 0 && (<div className={`${styles.NoDataMain}`}>
            <div className={`${styles.NoDataIMG}`}>
                <img src="/static/img/noDevice.svg" alt="No Data" />
            </div>
            <p className={`${styles.NoDataText}`}>Let's fill it up!</p>
            <p className={`${styles.NoDataTextSub}`}>Device List is Currently Empty.</p>
        </div>)}
    </div>);
}

export default LockList;