import styles from "../propertydetails.module.css";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import NumberFormat from "react-number-format";
import { Editor } from "@tinymce/tinymce-react";
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { X } from "react-feather";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import Loader from "../../../components/Loader";
import { toast } from "react-smart-toaster";
import { AddLogCallAPI } from "../../../components/AddLogs";

const schema = yup.object().shape({
  title: yup.string().required("This field is Required").max(50, "Layout title can not be more than 4 characters"),
  description: yup.string().required("This field is Required"),
  unit_type: yup.string().required("This field is Required"),
  bathroom: yup.string().required("This field is Required"),
  size: yup.string().required("This field is Required").max(4, "Unit size can not be more than 4 digits"),
  rent: yup.string().required("This field is Required").test("max-4-digit", "Rent can not be more than 6 digits.", (value) => {
    return parseInt(value?.replace(/,/g, "")) < 10000;
  }),
  ada_complaint: yup.string().required("This field is Required"),
  parking: yup.string().required("This field is Required"),
});

function AreaField(props) {
  return (
    <NumberFormat
      customInput={TextField}
      prefix=""
      displayType="input"
      type="text"
      thousandSeparator={false}
      allowNegative={false}
      decimalScale={0}
      {...props}
    />
  );
}

function MoneyFieldTwo(props) {
  return (
    <NumberFormat
      customInput={TextField}
      prefix=""
      displayType="input"
      type="text"
      thousandSeparator={true}
      allowNegative={false}
      decimalScale={2}
      fixedDecimalScale
      {...props}
    />
  );
}

const LayoutAdd = ({ property_id, addLayoutRow, setShowLayout, property_name, editLayoutData }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [uploadFiles, setUploadFiles] = useState([]);
  const [uploadFilesPath, setUploadFilesPath] = useState([]);
  const [uploadedFilesPath, setUploadedFilesPath] = useState([]);
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, control, errors, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      unit_type: "",
      bathroom: "",
      ada_complaint: "",
      parking: ""
    }
  });

  const onSubmit = async (data) => {
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

    const formData = new FormData();
    Object.keys(data).map((key) => {
      if (key === "title") {
        formData.append("title", data?.title);
      } else if (key === "description") {
        formData.append("description", data?.description);
      } else if (key === "unit_type") {
        formData.append("unit_type", data?.unit_type);
      } else if (key === "bathroom") {
        formData.append("bathroom", data?.bathroom);
      } else if (key === "size") {
        formData.append("size", data?.size);
      } else if (key === "ada_complaint") {
        formData.append("ada_complaint", data?.ada_complaint);
      } else if (key === "parking") {
        formData.append("parking", data?.parking);
      } else if (key === "rent") {
        formData.append("rent", data?.rent?.replace(/,/g, ""));
      }
      return false;
    });

    if (uploadFiles.length) {
      for (let n in uploadFiles) {
        formData.append("uploadImages[]", uploadFiles[n]);
      }
    }

    setLoading(true);

    axios.post(process.env.REACT_APP_PROPERTY_API_URL + "v1/properties/" + property_id + "/layouts", formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      setLoading(false);
      addLayoutRow(response.data.data);
      setShowLayout(false);

      let logData = {
        'title': 'New layout  is added',
        'description': [
          'Title: ' + response.data.data?.title,
          'Unit Type: ' + response.data.data?.unit_type,
          'Property:' + property_name
        ]
      }
      AddLogCallAPI(logData, token);
    }).catch((error) => {
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
      setLoading(false);
    });
  };

  const fileHandler = (e) => {
    let uploadFilesTemp = uploadFiles;

    if (e.target.files?.length > 0) {
      let item = e.target.files[0];
      let fileName = item.name;
      let fileSize = item.size;
      let fileNameExt = fileName.substr(fileName.lastIndexOf(".") + 1);
      fileNameExt = '.' + fileNameExt;

      if (['.png', '.jpg', '.jpeg'].indexOf(fileNameExt) === -1) {
        toast.error("File type is not allowed.");
        return false;
      }

      if (fileSize >= 5242880) {
        toast.error("File size should not be greater than 5 MB.");
        return false;
      }

      uploadFilesTemp.push(e.target.files[0]);
      setUploadFiles(uploadFilesTemp);

      setUploadFilesPath((prevFilesPath) => {
        return [...prevFilesPath, ...[URL.createObjectURL(e.target.files[0])]];
      });
    }

  };

  const removeFIles = (index) => {
    setUploadFiles(prev => {
      return prev.filter((item, i) => i !== index);
    });
    setUploadFilesPath(prev => {
      return prev.filter((item, i) => i !== index);
    });
  }

  useEffect(() => {
    if (editLayoutData) {
      setValue('title', editLayoutData?.title);
      setValue('description', editLayoutData?.description);
      setValue('unit_type', editLayoutData?.unit_type);
      setValue('bathroom', editLayoutData?.bathroom);
      setValue('size', editLayoutData?.size);
      setValue('rent', editLayoutData?.rent);
      setValue('ada_complaint', editLayoutData?.ada_complaint);
      setValue('parking', editLayoutData?.parking);

      setUploadedFilesPath(editLayoutData?.images);
    }
  }, [editLayoutData, setValue])

  const onUpdate = async (data) => {
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

    const formData = new FormData();
    Object.keys(data).map((key) => {
      if (key === "title") {
        formData.append("title", data?.title);
      } else if (key === "description") {
        formData.append("description", data?.description);
      } else if (key === "unit_type") {
        formData.append("unit_type", data?.unit_type);
      } else if (key === "bathroom") {
        formData.append("bathroom", data?.bathroom);
      } else if (key === "size") {
        formData.append("size", data?.size);
      } else if (key === "ada_complaint") {
        formData.append("ada_complaint", data?.ada_complaint);
      } else if (key === "parking") {
        formData.append("parking", data?.parking);
      } else if (key === "rent") {
        formData.append("rent", data?.rent?.replace(/,/g, ""));
      }
      return false;
    });

    if (uploadFiles.length) {
      for (let n in uploadFiles) {
        formData.append("uploadImages[]", uploadFiles[n]);
      }
    }

    if (uploadedFilesPath?.length) {
      for (let n in uploadedFilesPath) {
        formData.append("uploadedImageId[]", uploadedFilesPath[n]?.id);
      }
    }

    setLoading(true);

    axios.post(process.env.REACT_APP_PROPERTY_API_URL + "v1/properties/" + property_id + "/layouts/" + editLayoutData?.id, formData, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((response) => {
      setLoading(false);
      addLayoutRow(response.data.data);
      setShowLayout(false);

      let logData = {
        'title': 'Layout  is updated',
        'description': [
          'Title: ' + response.data.data?.title,
          'Unit Type: ' + response.data.data?.unit_type,
          'Property:' + property_name
        ]
      }
      AddLogCallAPI(logData, token);
    }).catch((error) => {
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
      setLoading(false);
    });
  }

  return (<>
    {loading && <Loader />}

    <div className={`${styles.AddUnitSec}`}>
      <form className={`${styles.AddLayoutForm}`} onSubmit={handleSubmit(editLayoutData ? onUpdate : onSubmit)}>
        <div className={`${styles.SMSmallSideBody} TabMainClientProfile ClientDetails`}>
          <div className={`${styles.AddUserMain}`}>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroupFull} FormGroup`}>
                <Controller
                  name={`title`}
                  control={control}
                  render={(field) => (
                    <TextField
                      onChange={(e) => field.onChange(e)}
                      id="outlined-basic"
                      label="Layout Name*"
                      value={field.value}
                    />
                  )}
                />
                {errors.title && (<p className={`${styles.ErrorM}`}>{errors.title.message}</p>)}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroup} FormGroup`}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Unit Type*</InputLabel>
                  <Controller
                    name="unit_type"
                    control={control}
                    render={(field) => (
                      <Select
                        {...field}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Unit Type"
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                      >
                        <MenuItem value={"Studio"}>Studio</MenuItem>
                        <MenuItem value={"1 Bedroom"}>1 Bedroom</MenuItem>
                        <MenuItem value={"2 Bedroom"}>2 Bedroom</MenuItem>
                        <MenuItem value={"3 Bedroom"}>3 Bedroom</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
                {errors.unit_type && (<p className={`${styles.ErrorM}`}>{errors.unit_type.message}</p>)}
              </div>
              <div className={`${styles.FormGroup} FormGroup`}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Bathrooms*</InputLabel>
                  <Controller
                    name="bathroom"
                    control={control}
                    render={(field) => (
                      <Select
                        {...field}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Bathrooms"
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
                {errors?.bathroom && (<p className={`${styles.ErrorM}`}>{errors?.bathroom?.message}</p>)}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroup} FormGroup`}>
                <Controller
                  name="size"
                  control={control}
                  ref={register}
                  placeholder="Size (sq. ft)"
                  render={(field) => (
                    <AreaField
                      {...field}
                      id="outlined-basic"
                      variant="outlined"
                      label="Unit Size*"
                      value={field.value ? field.value : ""}
                    />
                  )}
                />
                {errors.size && (<p className={`${styles.ErrorM}`}>{errors.size.message}</p>)}
              </div>
              <div className={`${styles.FormGroup} FormGroup`}>
                <Controller
                  name="rent"
                  control={control}
                  ref={register}
                  placeholder="Rent"
                  render={(field) => (
                    <MoneyFieldTwo
                      {...field}
                      id="outlined-basic"
                      variant="outlined"
                      label="Rent*"
                      value={field.value ? field.value : ""}
                    />
                  )}
                />
                {errors.rent && (<p className={`${styles.ErrorM}`}>{errors.rent.message}</p>)}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroup} FormGroup`}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">ADA Compliant*</InputLabel>
                  <Controller
                    name="ada_complaint"
                    control={control}
                    render={(field) => (
                      <Select
                        {...field}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="ADA Compliant"
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                      >
                        <MenuItem value={"Yes"}>Yes</MenuItem>
                        <MenuItem value={"No"}>No</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
                {errors.ada_complaint && (<p className={`${styles.ErrorM}`}>{errors.ada_complaint.message}</p>)}
              </div>
              <div className={`${styles.FormGroup} FormGroup`}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Parking*</InputLabel>
                  <Controller
                    name="parking"
                    control={control}
                    render={(field) => (
                      <Select
                        {...field}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Parking"
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                      >
                        <MenuItem value={"Yes"}>Yes</MenuItem>
                        <MenuItem value={"No"}>No</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
                {errors.parking && (<p className={`${styles.ErrorM}`}>{errors.parking.message}</p>)}
              </div>
            </div>
            <div className={`${styles.FormRow} ${styles.FileUploadSmall}`}>
              <div className={`${styles.FormGroupFull}`}>
                <div className={`${styles.FileUpDuBUDiv}`}>
                  <label for="file-upload" className={`${styles.UploadLabel}`}>
                    <span>
                      <div className={`${styles.UpIcon}`}>
                        <img src="/static/img/upload-floor.svg" alt="" />
                      </div>
                      <div>
                        <p>Upload Photo</p>
                        <p className={`${styles.UploadText}`}>File size upto 5 MB <br /> JPEG, JPG, PNG only</p>
                      </div>
                    </span>
                  </label>
                  <input id="file-upload" type="file" onChange={fileHandler} accept={'.png,.jpg,.jpeg'} />
                </div>

                <div className={`${styles.UpIMGSec}`}>
                  {uploadedFilesPath?.map((item) => {
                    return (<div className={`${styles.ImgFile}`}>
                      <div className={`${styles.ImgFileUnder}`}>
                        <img src={item?.file_public_url} alt="" />
                        <Button onClick={(e) => setUploadedFilesPath(prev => {
                          return prev?.filter(i => i?.id !== item?.id);
                        })}>
                          <X />
                        </Button>
                      </div>
                    </div>
                    );
                  })}


                  {uploadFilesPath?.map((item, index) => {
                    return (<div className={`${styles.ImgFile}`}>
                      <div className={`${styles.ImgFileUnder}`}>
                        <img src={item} alt="" />
                        <Button onClick={(e) => removeFIles(index)}>
                          <X />
                        </Button>
                      </div>
                    </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`${styles.SMBigSideBodyUnder} TabMainClientProfile ClientDetails`}>
          <div className={`${styles.FormRow}`}>
            <div className={`${styles.FormGroupFull}`}>
              <Controller
                name="description"
                control={control}
                render={(field) => (
                  <Editor
                    className={`${styles.formControlTextArea}`}
                    apiKey={"z3r7z5rrb92qgt55caemf3mrz1kdxuckuq25g9u3syc75r7u"}
                    value={field?.value}
                    init={{
                      height: 400,
                      menubar: false,
                      plugins: ["lists"],
                      toolbar:
                        "undo redo | bold italic underline strikethrough | fontselect fontsizeselect | alignleft aligncenter alignright alignjustify |  numlist bullist | forecolor backcolor removeformat |  fullscreen  | ltr rtl | code",
                    }}
                    onEditorChange={(e) => field.onChange(e)}
                  />
                )}
              />
              {errors.description && (<p className={`${styles.ErrorM}`}>{errors.description.message}</p>)}
            </div>
          </div>
        </div>
        <div className={`${styles.ButtonAction}`}>
          <button className="CancelPopupBU" type="button" onClick={(e) => setShowLayout(false)}> Cancel </button>
          <button className="SubmitPopupBU" type="submit"> {editLayoutData ? 'Update' : 'Add'} </button>
        </div>
      </form>
    </div>
  </>);
};

export default LayoutAdd;
