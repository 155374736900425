export default function communicationChatBoxReducer (isOpen = false, actions) {
    
        switch (actions.type) {
    
            case 'open':
                return isOpen =  actions.payload;
                
    
            default:
                return isOpen
        }
    
    }