import React, { useEffect, useState } from "react";
import { Card, CardContent } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import styles from "../dashboard.module.css";

const OccupancyData = (props) => {
    const [noData, setNoData] = useState(true);
    const data = {
        labels: (props?.occupancyData) ? props?.occupancyData?.labels : [],
        datasets: [
            {
                data: (props?.occupancyData) ? props?.occupancyData?.data : [],
                backgroundColor: [
                    'rgb(32, 2, 84)',
                    'rgb(170, 102, 204)',
                ],
                borderWidth: 4,
            },
        ],
    };

    const options = {
        maintainAspectRatio: false,
        legend: {
            display: true,
            position: 'bottom',
            maxHeight: 20,
            maxWidth: 20,
            labels: {
                padding: 30
            }
        },
        cutoutPercentage: 80,
    };

    useEffect(() => {
        if (props?.occupancyData?.data[0] === 0 && props?.occupancyData?.data[1] === 0) {
            setNoData(true);
        } else {
            setNoData(false);
        }
    }, [props?.occupancyData]);

    return (<div className={`${styles.CardDivTwo} DoughnutChart`}>
        <Card mb={3}>
            <div className={`${styles.InsideCard}`}>
                <div className={`${styles.BatteryHead}`}>
                    <h6 className={`${styles.BatteryCardTitle}`}>Occupancy</h6>
                </div>

                <CardContent style={{ display: noData ? 'none' : 'block' }}>
                    <div style={{ height: '270px', position: 'relative' }}>
                        <Doughnut data={data} options={options} />
                    </div>
                </CardContent>

                {noData && (<div className={`${styles.NoDataSec}`}>
                    <img src="/static/img/nodata.svg" alt="" className={`${styles.NoDataIMG}`} />
                    <p className={`${styles.NoDataText}`}>No Data Found</p>
                </div>)}
            </div>
        </Card>
    </div>);
};

export default OccupancyData;
