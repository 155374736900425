import React from "react";
import styles from './privacy.module.css';
import Helmet from "react-helmet";
import Header from '../../components/HeaderPublicOther';
import Footer from '../../components/FooterPublicOther';



class HomePage extends React.Component {
  constructor({ match, ...props }) {
    super(...arguments);
  }


  render() {
    return (<React.Fragment>
      <Helmet title="Privacy" />
      <Header />
      <div className="WhiteBG">
        <div className={`${styles.PrivacyArea}`}>
          <div className={`${styles.Container}`}>
            <div className={`${styles.PolicyTitleSec}`}>
              <p className={`${styles.PolicyTitle}`}>Privacy Policy</p>
            </div>

            <div className={`${styles.PolicyConSec}`}>
              <h3 className={`${styles.PolicyConTitle}`}>Personal Data</h3>
              <div className={`${styles.PolicyConDe}`}>
                <p className={`${styles.PolicyText}`}>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally identifiable information may include, but is not limited to:</p>
                <ul>
                  <li>Email address</li>
                  <li>First name and last name</li>
                  <li>Phone number</li>
                  <li>Address, State, Province, ZIP/Postal code, City</li>
                  <li>Cookies and Usage Data</li>
                </ul>
                <p className={`${styles.PolicyText}`}>We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send or by contacting us.</p>
              </div>
              <h3 className={`${styles.PolicyConTitle}`}>Usage Data</h3>
              <div className={`${styles.PolicyConDe}`}>
                <p className={`${styles.PolicyText}`}>We may also collect information how the Service is accessed and used (“Usage Data”). This Usage Data may include information such as your computer’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
              </div>
              <h3 className={`${styles.PolicyConTitle}`}>Tracking &amp; Cookies Data</h3>
              <div className={`${styles.PolicyConDe}`}>
                <p className={`${styles.PolicyText}`}>We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.</p>
                <p className={`${styles.PolicyText}`}>Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service.</p>
                <p className={`${styles.PolicyText}`}>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
                <p className={`${styles.PolicyText}`}>Examples of Cookies we use:</p>
                <ul>
                  <li>Session Cookies. We use Session Cookies to operate our Service.</li>
                  <li>Preference Cookies. We use Preference Cookies to remember your preferences and various settings.</li>
                  <li>Security Cookies. We use Security Cookies for security purposes.</li>
                </ul>
              </div>
              <h3 className={`${styles.PolicyConTitle}`}>Retention of Data</h3>
              <div className={`${styles.PolicyConDe}`}>
                <p className={`${styles.PolicyText}`}>Propertydek will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
                <p className={`${styles.PolicyText}`}>Propertydek will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.</p>
              </div>
              <h3 className={`${styles.PolicyConTitle}`}>Security of Data</h3>
              <div className={`${styles.PolicyConDe}`}>
                <p className={`${styles.PolicyText}`}>The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </React.Fragment >);
  }
}


export default HomePage;