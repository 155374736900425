import { useState } from "react";
import styles from "../communication-prospect.module.css";
import { Send, Search, X } from "react-feather";

function ProspectChatHeader(props) {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    props.onSearchMessage(event.target.value);
  };

  const onClearSearch = () => {
    props.onSearchMessage("");
    setSearchQuery("");
  };

  return (
    <>
      <div className={`${styles.ChatSecHeader}`}>
        <div className={`${styles.ChatSecHeaderTenantSec}`}>
          {props.userDet?.id && (
            <>
              <div className={`${styles.PropertyImageSec} ${styles.Avatar}`}>
                <div className={`${styles.ProspectChatHeadIcon}`}>AH</div>
              </div>

              <div>
                {/* <p className={`${styles.ChatSecHeaderTenantSecTitle}`}>{false && props.userDet?.tenant_list.map((tnt, index) => {
                return (<>
                  <span key={index}>{tnt.full_name}{index !== props.userDet?.tenant_list.length - 1 && ','}</span>
                </>)
              })}</p> */}
                <p className={`${styles.ChatSecHeaderTenantSecTitle}`}>
                  {props.userDet?.full_name
                    ? props.userDet?.full_name
                    : props.userDet?.phone}{" "}
                  <span></span>
                </p>
                {props.userDet?.propertyName && (
                  <div className={`${styles.ChatSecHeaderTenantSec}`}>
                    <p className={`${styles.ChatSecHeaderTenantSecMember}`}>
                      {props.userDet?.propertyName} - {props.userDet?.apt_no}
                    </p>
                  </div>
                )}
              </div>
            </>
          )}
        </div>

        <div className={`${styles.ChatSecHeaderActions}`}>
          {props.userDet?.id && (
            <div
              className={`${styles.ChatSecSearch}`}
              onClick={props.toggleMenu}
            >
              <input type="text" placeholder="Search..." />
              <Search />
            </div>
          )}
        </div>
      </div>
      <div
        className={
          props.isMenuOpen
            ? `${styles.HeadSearchSec} ${styles.Open}`
            : `${styles.HeadSearchSec} `
        }
      >
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearch}
        />
        <X onClick={onClearSearch} />
      </div>
    </>
  );
}

export default ProspectChatHeader;
