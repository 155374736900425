import React from "react";
import styles from './leasesignsuccess.module.css';
import Helmet from "react-helmet";
import Header from '../../components/HeaderPublicOther'
import Footer from '../../components/FooterPublicOther'

function LeaseSignSuccess({ history, match }) {

  return (
    <div>
      <Helmet title="Lease Sign Success" />
      <Header />

      <div className="WhiteBG">
        <div className={`${styles.ErrorMain}`}>
          <div className={`${styles.ErrorMainSec}`}>
            <img src="/static/img/contract.png" alt="" className={`${styles.LeaseSignIMG}`} />
            <p className={`${styles.ErrorTitle}`}>Lease Agreement</p>
            <p className={`${styles.PaymentSuccessTitleSub}`}>Thank you! Your lease was signed successfully.</p>
          </div>
        </div>
        <Footer />
      </div>

    </div>
  );
}

export default LeaseSignSuccess;
