import React from "react";
import { withAuth0 } from '@auth0/auth0-react';
import styles from './headerpublicother.module.css';
import { Events, animateScroll as scroll, scroller } from 'react-scroll'
import { Menu } from 'react-feather';
import { Link as Linkto } from "react-router-dom";
import 'react-sticky-header/styles.css';

class HeaderPublicOther extends React.Component {
  constructor({ match, ...props }) {
    super(...arguments);
    this.scrollToTop = this.scrollToTop.bind(this);
    this.state = {
      isMenuOpen: ''
    };
  }

  componentDidMount() {
    Events.scrollEvent.register('begin', function () {
      console.log("begin", arguments);
    });

    Events.scrollEvent.register('end', function () {
      console.log("end", arguments);
    });
  }

  scrollToTop() {
    scroll.scrollToTop();
  }

  scrollTo() {
    scroller.scrollTo('scroll-to-element', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    })
  }

  scrollToWithContainer() {
    let goToContainer = new Promise((resolve, reject) => {
      Events.scrollEvent.register('end', () => {
        resolve();
        Events.scrollEvent.remove('end');
      });

      scroller.scrollTo('scroll-container', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      });

    });

    goToContainer.then(() =>
      scroller.scrollTo('scroll-container-second-element', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
        containerId: 'scroll-container'
      }));
  }
  componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }
  toggleMenu() {
    let isOpen = this.state.isMenuOpen;
    if (isOpen === '') {
      isOpen = 'Open';
    } else {
      isOpen = '';
    }
    this.setState({ isMenuOpen: isOpen })
  }

  render() {
    return (<div className={`${styles.Header}`}>
      <div className={`${styles.Container}`}>
        <div className={`${styles.HeadRow}`}>
          <div className={`${styles.Logo}`}>
            <Linkto to="/"><img src="/static/img/logo.svg" alt="logo" /></Linkto>
          </div>
          <div className={`${styles.RightSec}`}>
            <div className={(this.state.isMenuOpen === '') ? `${styles.MenuArea}` : `${styles.MenuArea} ${styles.Open}`} >
              <ul>
                <li><Linkto activeclass={`${styles.active}`} to="/#HomeSec" >Home</Linkto></li>
                <li><Linkto activeclass={`${styles.active}`} to="/#features">Features</Linkto></li>
                <li><Linkto activeclass={`${styles.active}`} to="/#blogs">Blog</Linkto></li>
                {/* <li><Linkto to={{ pathname: "https://fbc.nyc/" }} target="_blank">Company</Linkto></li> */}
                <li><Linkto activeClass={`${styles.active}`} to={'/contact-us'}>Contact Us</Linkto></li>
              </ul>
              <Linkto target="_blank" to={'/login'} className={`${styles.ContactLinkHead}`}>Log in</Linkto>
              {/* <Linkto to={'/dashboard'} className={`${styles.ContactBUHead}`} target="_blank">Sign up</Linkto> */}
            </div>
            <Linkto className={`${styles.MenuHamBU}`} onClick={this.toggleMenu.bind(this)} ><Menu /></Linkto>
          </div>
        </div>
      </div>
    </div>
    );
  }
}

export default withAuth0(HeaderPublicOther);