import { Box, Grid } from "@material-ui/core";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
function PropertyTenantsSearch(props) {


  return (
    <>
      <Grid item xs={12}>
        <Box p={2}>
          <Typeahead
            id="search-tenants"
            labelKey="name"
            filterBy={props.filterByCallback}
            options={props.propertyTypeAheadObj}
            placeholder="Search tenants"
            clearButton={true}
            flip={true}
            onChange={props.setSelected}
            renderMenuItemChildren={(option) => {
              return option?.is_parent ? (
                <div>
                  {option?.name}
                </div>
              ) : (
                <div style={{ marginLeft: '20px' }}>
                  {option?.name}
                  <div>
                    <small>{option?.apt_no}</small>
                  </div>
                </div>
              )
            }}
          />
        </Box>
      </Grid>
    </>
  )
}


export default PropertyTenantsSearch;