import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { ArrowLeft, Home, Plus, X } from "react-feather";
import styles from "./dashboard.module.css";
import Helmet from "react-helmet";
import Footer from "../../../components/Footer";
import { Typography as MuiTypography } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import moment from "moment";
import { TableLoader } from "../../../components/LoaderC";
import { Dropdown } from "react-bootstrap";
import { Dialog, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, Slide, Snackbar } from "@mui/material";
import StepOneForm from "../../prospect/Components/AddProspect/StepOneForm";
import StepTwoForm from "../../prospect/Components/AddProspect/StepTwoForm";
import StepThreeForm from "../../prospect/Components/AddProspect/StepThreeForm";
import { toast } from "react-smart-toaster";
import dayjs from "dayjs";
import AddForm from "../../tenants/components/AddForm";
import Loader from "../../../components/Loader";
import SmartDeviceStatus from "./Components/SmartDeviceStatus";
import SmartDeviceAlerts from "./Components/SmartDeviceAlerts";
import PubNubChannel from "../../smartdevices/components/pubnub/pubnub-channel";
import Prospects from "./Components/Prospects";
import AddDeviceForm from "../../smartdevices/AddComponent/AddDeviceFormNew";
import AddPropertyForm from "../../properties/components/AddPropertyForm";
import { AddLogCallAPI } from "../../../components/AddLogs";
import AddMaintenanceForm from "../../maintenancerequest/components/AddMaintenanceForm";
import Complaints from "./Components/Complaints";
import Ruquests from "./Components/Requests";
import RentPayments from "./Components/RentPayments";
import OccupancyData from "./Components/OccupancyData";
import MonthlyRentRollData from "./Components/MonthlyRentRollData";
import MonthlyCasesData from "./Components/MonthlyCasesData";
import LeaseData from "./Components/LeaseData";
import MonthCollection from "./Components/MonthCollection";
import MonthCollection2 from "./Components/MonthlyCollection2";
import BattryStatuses from "./Components/BattryStatuses";
import SmartDeviceStatus3D from "./Components/SmartDeviceStatus3D";
import ApartmentDataHeatMap from "./Components/ApartmentDataHeatMap";

const Typography = styled(MuiTypography)(spacing);

function Default() {
  const { getAccessTokenSilently, user } = useAuth0();
  const [contentLoading, setContentLoading] = useState(false);
  const [propertyList, setPropertyList] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [propertyData, setPropertyData] = useState(null);
  const [addProperty, setAddProperty] = useState(false);
  const [addProspect, setAddProspect] = useState();
  const [addFormStep, setAddFormStep] = useState(1);
  const [stepOneData, setStepOneData] = useState(null);
  const [stepTwoData, setStepTwoData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [addTenantPop, setAddTenantPop] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [ownerId, setOwnerId] = useState("");
  const [addDevicePop, setAddDevicePop] = useState(false);
  const [addRequestPop, setAddRequestPop] = useState(false);
  const [currentTimePeriod, setCurrentTimePeriod] = useState('Current Month');
  const [monthlyCollected, setMonthlyCollected] = useState(null);

  useEffect(() => {
    async function fetchData() {
      setContentLoading(true);
      let userMetadata = user["https://propertydek.com/user_metadata"];
      setOwnerId(userMetadata?.ownerID);
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

      await axios.get(process.env.REACT_APP_USER_API_URL + "v1/get-all-properties", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        setContentLoading(false);
        let propertyListTemp = response.data.data;
        propertyListTemp = [{ value: 0, label: "All" }].concat(propertyListTemp);
        setPropertyList(propertyListTemp);
        setSelectedProperty(0);
      }).catch((error) => {
        setContentLoading(false);
        setPropertyList([]);
      });
    }
    fetchData();
  }, [getAccessTokenSilently, user]);

  useEffect(() => {
    const fetchData = async (pId) => {
      setContentLoading(true);
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

      if (pId > 0) {
        await axios.get(process.env.REACT_APP_USER_API_URL + "v1/dashboard/get-all-data/" + pId, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((response) => {
          setContentLoading(false);
          setPropertyData(response.data.data);
        }).catch((error) => {
          setContentLoading(false);
        });
      } else {
        await axios.get(process.env.REACT_APP_USER_API_URL + "v1/dashboard/get-all-data", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((response) => {
          setContentLoading(false);
          setPropertyData(response.data.data);
        }).catch((error) => {
          setContentLoading(false);
        });
      }
    };
    if (selectedProperty > 0) {
      fetchData(selectedProperty);
    } else {
      fetchData(0);
    }
  }, [selectedProperty, getAccessTokenSilently]);

  const onSubmitOne = (data) => {
    setAddFormStep(2);
    setStepOneData(data);
  };

  const onSubmitTwo = (data) => {
    let cData = {};
    if (typeof data.credit_score !== "undefined") {
      cData = { ...cData, credit_score: data.credit_score };
    }
    if (typeof data.have_pets !== "undefined") {
      cData = { ...cData, have_pets: data.have_pets };
    }
    if (typeof data.income_per_year !== "undefined") {
      cData = { ...cData, income_per_year: data.income_per_year };
    }
    if (typeof data.bedrooms !== "undefined") {
      cData = { ...cData, bedrooms: data.bedrooms };
    }
    if (typeof data.move_in_date !== "undefined") {
      cData = { ...cData, move_in_date: dayjs(data?.move_in_date).format("YYYY-MM-DD HH:mm:ss"), };
    }
    if (typeof data.bathrooms !== "undefined") {
      cData = { ...cData, bathrooms: data.bathrooms };
    }
    if (typeof data.do_you_smoke !== "undefined") {
      cData = { ...cData, do_you_smoke: data.do_you_smoke };
    }
    if (typeof data.is_tour !== "undefined") {
      cData = { ...cData, is_tour: data.is_tour };
    }

    setAddFormStep(3);
    setStepTwoData(cData);
  };

  const onAddProspectSubmit = async (data) => {
    let postData = {};
    postData = { ...postData, ...stepOneData, ...stepTwoData };
    if (addFormStep === 2) {
      if (typeof data.credit_score !== "undefined") {
        postData = { ...postData, credit_score: data.credit_score };
      }
      if (typeof data.have_pets !== "undefined") {
        postData = { ...postData, have_pets: data.have_pets };
      }
      if (typeof data.income_per_year !== "undefined") {
        postData = { ...postData, income_per_year: data.income_per_year };
      }
      if (typeof data.bedrooms !== "undefined") {
        postData = { ...postData, bedrooms: data.bedrooms };
      }
      if (typeof data.move_in_date !== "undefined") {
        postData = { ...postData, move_in_date: dayjs(data?.move_in_date).format("YYYY-MM-DD HH:mm:ss"), };
      }
      if (typeof data.bathrooms !== "undefined") {
        postData = { ...postData, bathrooms: data.bathrooms };
      }
      if (typeof data.do_you_smoke !== "undefined") {
        postData = { ...postData, do_you_smoke: data.do_you_smoke };
      }
    }

    if (typeof data.tour_date_time !== "undefined") {
      postData = { ...postData, tour_date_time: data.tour_date_time };
    }

    setAddProspect(false);
    setLoading(true);
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

    let is_tour = false;
    if (typeof postData.is_tour !== "undefined") {
      is_tour = postData.is_tour;
    }

    await axios.post(process.env.REACT_APP_APPLICANT_API_URL + "v1/add-prospect", postData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      setLoading(false);

      setSnackbarMsg(response.data.message);
      setSnackbarOpen(true);

      let logData = {
        title: "A new prospect is added",
        description: [
          "Name: " + response?.data?.data?.full_name,
          "Phone: " + response?.data?.data?.phone,
          "Property: " + response?.data?.data?.property_name,
          "Apartment: " + response?.data?.data?.apt_no,
        ],
      };
      AddLogCallAPI(logData, token);

      if (is_tour) {
        let logData = {
          title: "A new prospect tour is added",
          description: [
            "Name: " + response?.data?.data?.full_name,
            "Phone: " + response?.data?.data?.phone,
            "Property: " + response?.data?.data?.property_name,
            "Date/Time: " +
            moment(data.tour_date_time).format("MM/DD/YYYY hh:mm a"),
          ],
        };
        AddLogCallAPI(logData, token);
      }
    }).catch((error) => {
      setLoading(false);
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
    });
  };

  const addTenant = (data) => {
    setAddTenantPop(false);
    setSnackbarMsg("Tenant(s) have been added successfully.");
    setSnackbarOpen(true);
  };

  const updateDeviceStatus = (deviceId, deviceStatus, bizCode, data) => {
    try {
      if (!data) {
        return;
      }
      const timeDistance = moment(data.created_at).fromNow();
      data.time_str = `${timeDistance}`;

      if (deviceId) {
        propertyData.device_alert_list = [data, ...propertyData.device_alert_list];
        propertyData.device_alert_list.pop();

        if (bizCode && deviceStatus === "offline") {
          const online = propertyData.smart_devices_data.data[0];
          const offline = propertyData.smart_devices_data.data[1];
          propertyData.smart_devices_data.data[0] = online ? online - 1 : online;
          propertyData.smart_devices_data.data[1] = offline + 1;
          propertyData.smart_devices_data.data = [...propertyData.smart_devices_data.data];
        }
        setPropertyData({ ...propertyData });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onDeviceAdd = (data) => {
    console.log(data);
  };

  const onRequestAdd = (data) => {
    console.log(data);
  }

  const onAddProperty = (data) => {
    setSnackbarMsg(data.message);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

      await axios.get(process.env.REACT_APP_USER_API_URL + "v1/dashboard/get-payment-collection", {
        params: { property_id: selectedProperty, time: currentTimePeriod },
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).then((response) => {
        setMonthlyCollected(response.data.data);
      });
    };

    fetchData();
  }, [currentTimePeriod, selectedProperty, getAccessTokenSilently]);

  return (<React.Fragment>
    {loading && <Loader />}

    <Helmet title="Dashboard" />

    <div className={`${styles.pageTitleWrap}`}>
      <Typography display="inline" className={`${styles.pageTitle}`}>Dashboard</Typography>
      <nav aria-label="breadcrumb">
        <ol className={`${styles.breadcrumb}`}>
          <li className={`${styles.breadcrumbItem}`}><span><Home /></span>Dashboard</li>
        </ol>
      </nav>
    </div>

    <div className={`${styles.mainCard}`}>
      {contentLoading && (<div className="Loader TableLoader">
        <TableLoader />
      </div>)}
      {!contentLoading && (<div className={`${styles.Row}`}>
        <div className={`${styles.DahhboardHead}`}>
          <div className={`${styles.SwitchDiv} ${styles.Select}`}>
            <div className={`${styles.FormGroupSelect} FormGroup`}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select Property</InputLabel>
                <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Select Property" value={selectedProperty} onChange={(e) => setSelectedProperty(e?.target?.value)}>
                  {propertyList?.map(item => {
                    return (<MenuItem key={item?.value} value={item?.value}> {item?.label} </MenuItem>);
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
          <Dropdown>
            <Dropdown.Toggle className={`${styles.ActionsBU} ActionsBUT`} id="dropdown-basic">
              <Plus className={`${styles.Add}`} /> Add
            </Dropdown.Toggle>

            <Dropdown.Menu className={`${styles.ActionsBUList} ActionsBUList`}>
              <Dropdown.Item className={`${styles.ActionsBUListItem}`} onClick={(e) => setAddProperty(true)}>
                <img src="/static/img/home-dash.svg" width="20px" height="20px" alt="" />
                Property
              </Dropdown.Item>
              <Dropdown.Item className={`${styles.ActionsBUListItem}`} onClick={(e) => { setAddProspect(true); setAddFormStep(1); }}>
                <img src="/static/img/prospect-dash.svg" width="20px" height="20px" alt="" />
                Prospect
              </Dropdown.Item>
              <Dropdown.Item className={`${styles.ActionsBUListItem}`} onClick={(e) => setAddTenantPop(true)}>
                <img src="/static/img/tenant-dash.svg" width="20px" height="20px" alt="" />
                Tenant
              </Dropdown.Item>
              {/*<Dropdown.Item className={`${styles.ActionsBUListItem}`}>
                <img src="/static/img/icome-dash.svg" width="20px" height="20px" alt="" />
                Income
              </Dropdown.Item>
              <Dropdown.Item className={`${styles.ActionsBUListItem}`}>
                <img src="/static/img/expence-dash.svg" width="20px" height="20px" alt="" />
                Expense
              </Dropdown.Item>*/}
              <Dropdown.Item className={`${styles.ActionsBUListItem}`} onClick={(e) => setAddRequestPop(true)}>
                <img src="/static/img/request-dash.svg" width="20px" height="20px" alt="" />
                Request
              </Dropdown.Item>
              <Dropdown.Item className={`${styles.ActionsBUListItem}`} onClick={(e) => setAddDevicePop(true)}>
                <img src="/static/img/device-dash.svg" width="20px" height="20px" alt="" />
                Smart Device
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className={`${styles.ScoreCards}`}>
          <div className={`${styles.ScoreCardsFull}`}>
            <div className={`${styles.ScoreCardsUnder}`}>
              <div className={`${styles.ScoreCardIcon}`}>
                <img src="/static/img/customer-dash-icon.svg" alt="" />
              </div>
              <div className={`${styles.ScoreCardCon}`}>
                <p className={`${styles.ScoeCardTitle}`}># of New Applications</p>
                <p className={`${styles.ScoeCardCon}`}>{propertyData?.no_of_prospects}</p>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.ScoreCards}`}>
          <div className={`${styles.ScoreCardsFull}`}>
            <div className={`${styles.ScoreCardsUnder}`}>
              <div className={`${styles.ScoreCardIcon}`}>
                <img src="/static/img/tours-dash-icon.svg" alt="" />
              </div>
              <div className={`${styles.ScoreCardCon}`}>
                <p className={`${styles.ScoeCardTitle}`}># of Tours Completed</p>
                <p className={`${styles.ScoeCardCon}`}>{propertyData?.no_of_tours}</p>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.ScoreCards}`}>
          <div className={`${styles.ScoreCardsFull}`}>
            <div className={`${styles.ScoreCardsUnder}`}>
              <div className={`${styles.ScoreCardIcon}`}>
                <img src="/static/img/messages-dash-icon.svg" alt="" />
              </div>
              <div className={`${styles.ScoreCardCon}`}>
                <p className={`${styles.ScoeCardTitle}`}># of Messages Received</p>
                <p className={`${styles.ScoeCardCon}`}>{propertyData?.no_of_messages}</p>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.ScoreCards}`}>
          <div className={`${styles.ScoreCardsFull}`}>
            <div className={`${styles.ScoreCardsUnder}`}>
              <div className={`${styles.ScoreCardIcon}`}>
                <img src="/static/img/tenant-dash-icon.svg" alt="" />
              </div>
              <div className={`${styles.ScoreCardCon}`}>
                <p className={`${styles.ScoeCardTitle}`}># of New Tenants</p>
                <p className={`${styles.ScoeCardCon}`}>{propertyData?.no_of_tenants}</p>
              </div>
            </div>
          </div>
        </div>

        <div className={`${styles.IconCardDiv}`}>
          <div className={`${styles.IconCardFullDiv}`}>
            <div className={`${styles.IfnoCardTwo}`}>
              <div className={`${styles.RentCollectBigSec}`}>
                <MonthCollection monthly_collected={monthlyCollected} currentTimePeriod={currentTimePeriod} setCurrentTimePeriod={setCurrentTimePeriod.bind(this)} />
                <LeaseData data={propertyData?.expiring_leases} />
              </div>
              <div className={`${styles.RentCollectSmallSec}`}>
                <MonthCollection2 monthly_collected={monthlyCollected} />
              </div>
            </div>
          </div>
        </div>

        <BattryStatuses no_of_floor={propertyData?.no_of_floor} lock_battery_status={propertyData?.lock_battery_status} selectedProperty={selectedProperty} />

        <OccupancyData occupancyData={propertyData?.occupancy_data} />

        <SmartDeviceAlerts totalData={propertyData?.device_alert_counts} data={propertyData?.device_alert_list} />

        <SmartDeviceStatus data={propertyData?.smart_devices_data} />

        <Ruquests newData={propertyData?.maintenance_requests_no} data={propertyData?.maintenance_requests} />

        <Complaints newData={propertyData?.complaint_no} data={propertyData?.complaints} />

        <Prospects newData={propertyData?.new_prospect_counts} data={propertyData?.prospect_list} />

        <RentPayments data={propertyData?.rent_payments} />

        <MonthlyCasesData monthlyCaseData={propertyData?.monthly_cases_data} monthlyCaseLabel={propertyData?.monthly_cases_labels} />

        <MonthlyRentRollData monthlyRentRoll={propertyData?.monthly_rent_roll} />

        <SmartDeviceStatus3D />

        <ApartmentDataHeatMap />
      </div>)}
    </div>

    <Dialog
      open={addProperty}
      fullWidth
      maxWidth="sm"
      onClose={(e) => setAddProperty(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp MobileForm"
    >

      <DialogTitle id="alert-dialog-title">
        <button className={`${styles.BackAddBU}`} onClick={(e) => setAddProperty(false)}><ArrowLeft /></button>
        Add Property
        <button onClick={(e) => setAddProperty(false)}>
          <X />
        </button>
      </DialogTitle>
      <DialogContent>
        <AddPropertyForm closeForm={setAddProperty.bind(this)} onSubmitLocal={onAddProperty.bind(this)} />
      </DialogContent>
    </Dialog>

    <Dialog
      open={addProspect}
      fullWidth
      maxWidth="sm"
      onClose={(e) => setAddProspect(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp CustomWidthPopUs"
    >
      {addFormStep === 1 && (<StepOneForm setOpenAddProspectModal={setAddProspect.bind(this)} onSave={onSubmitOne.bind(this)} selectedProperty={0} />)}

      {addFormStep === 2 && (<StepTwoForm setOpenAddProspectModal={setAddProspect.bind(this)} onSave={onSubmitTwo.bind(this)} onSubmit={onAddProspectSubmit.bind(this)} isTourOption={true} />)}

      {addFormStep === 3 && (<StepThreeForm setOpenAddProspectModal={setAddProspect.bind(this)} onSubmit={onAddProspectSubmit.bind(this)} prospect_name={stepOneData?.prospect_name} property_id={stepOneData?.property_id} />)}
    </Dialog>

    <Dialog
      open={addTenantPop}
      fullWidth
      maxWidth="lg"
      onClose={(e) => setAddTenantPop(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp AddTenantDash"
    >
      <AddForm addTenant={addTenant.bind(this)} closeAddForm={(e) => setAddTenantPop(false)} />
      <button onClick={(e) => setAddTenantPop(false)} className={`${styles.CloseAddTenantDash}`}>
        <X />
      </button>
    </Dialog>

    <Dialog
      open={addDevicePop}
      fullWidth
      maxWidth="lg"
      onClose={(e) => setAddDevicePop(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp AddTenantDash"
    >
      <AddDeviceForm setShowAddFormNew={setAddDevicePop.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} onSubmitLocal={onDeviceAdd.bind(this)} />
      <button onClick={(e) => setAddDevicePop(false)} className={`${styles.CloseAddTenantDash}`}>
        <X />
      </button>
    </Dialog>

    <Dialog
      open={addRequestPop}
      fullWidth
      maxWidth="lg"
      onClose={(e) => setAddRequestPop(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp AddTenantDash"
    >
      <AddMaintenanceForm closeForm={setAddRequestPop.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} onSubmitLocal={onRequestAdd.bind(this)} />
      <button onClick={(e) => setAddRequestPop(false)} className={`${styles.CloseAddTenantDash}`}>
        <X />
      </button>
    </Dialog>

    <Snackbar
      open={snackbarOpen}
      onClose={(e) => setSnackbarOpen(false)}
      TransitionComponent={Slide}
      message={snackbarMsg}
      autoHideDuration={10000}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={(e) => setSnackbarOpen(false)}
        >
          <X />
        </IconButton>
      }
    />
    {ownerId && (<PubNubChannel ownerId={ownerId} updateDeviceStatus={updateDeviceStatus}></PubNubChannel>)}

    <Footer />
  </React.Fragment >);
}

export default Default;