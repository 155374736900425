import { Switch } from "@mui/material";
import styles from "../../tenantdetails.module.css";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { toast } from "react-smart-toaster";

const PermissionsTab = ({ tenantData, setTenantData, setLoading, setSnackbarOpen, setSnackbarMsg }) => {
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [defaultAppPerms, setDefaultAppPerms] = useState({ chat: false, energy: false, payments_acf: false, payments_card: false, request: false, smart_rooms: false });
    const [changedAppPerms, setChangedAppPerms] = useState({ chat: false, energy: false, payments_acf: false, payments_card: false, request: false, smart_rooms: false });

    const { register, handleSubmit } = useForm({
        shouldUnregister: false,
    });

    useEffect(() => {
        if (tenantData?.app_permissions) {
            setDefaultAppPerms(tenantData?.app_permissions);
            setChangedAppPerms(tenantData?.app_permissions);
        }
    }, [tenantData]);

    const onSubmit = async (data) => {
        setLoading(true);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        return axios.put(process.env.REACT_APP_TENANT_API_URL + "v1/tenants/" + tenantData?.id + "/update-permission", data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            setLoading(false);

            setSnackbarOpen(true);
            setSnackbarMsg(response.data.message);

            setTenantData(prev => {
                return { ...prev, app_permissions: response.data.data };
            });
        }).catch((error) => {
            setLoading(false);
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
        });
    }

    return <div className={`${styles.Permission}`}>
        <h5>Mobile App Permissions</h5>

        <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`${styles.TabSecMain}`}>
                <div className={`${styles.PermissionSwitch}`}>
                    <Switch
                        onChange={(e) => {
                            setChangedAppPerms(prev => {
                                return { ...prev, smart_rooms: e.target.checked }
                            });
                        }}
                        name="smart_rooms"
                        inputRef={register}
                        checked={changedAppPerms?.smart_rooms === true}
                        disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("tenant:update") > -1)}
                    />
                    Smart Rooms
                </div>
                <div className={`${styles.PermissionSwitch}`}>
                    <Switch
                        onChange={(e) => {
                            setChangedAppPerms(prev => {
                                return { ...prev, request: e.target.checked }
                            });
                        }}
                        name="request"
                        inputRef={register}
                        checked={changedAppPerms?.request === true}
                        disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("tenant:update") > -1)}
                    />
                    Requests
                </div>
                <div className={`${styles.PermissionSwitch}`}>
                    <Switch
                        onChange={(e) => {
                            setChangedAppPerms(prev => {
                                return { ...prev, energy: e.target.checked }
                            });
                        }}
                        name="energy"
                        inputRef={register}
                        checked={changedAppPerms?.energy === true}
                        disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("tenant:update") > -1)}
                    />
                    Lease
                </div>
                <div className={`${styles.PermissionSwitch}`}>
                    <Switch
                        onChange={(e) => {
                            setChangedAppPerms(prev => {
                                return { ...prev, payments_card: e.target.checked }
                            });
                        }}
                        name="payments_card"
                        inputRef={register}
                        checked={changedAppPerms?.payments_card === true}
                        disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("tenant:update") > -1)}
                    />
                    Payments
                </div>
                <div className={`${styles.PermissionSwitch}`}>
                    <Switch
                        onChange={(e) => {
                            setChangedAppPerms(prev => {
                                return { ...prev, payments_acf: e.target.checked }
                            });
                        }}
                        name="payments_acf"
                        inputRef={register}
                        checked={changedAppPerms?.payments_acf === true}
                        disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("tenant:update") > -1)}
                    />
                    ACH Payment
                </div>
                <div className={`${styles.PermissionSwitch}`}>
                    <Switch
                        onChange={(e) => {
                            setChangedAppPerms(prev => {
                                return { ...prev, chat: e.target.checked }
                            });
                        }}
                        name="chat"
                        inputRef={register}
                        checked={changedAppPerms?.chat === true}
                        disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("tenant:update") > -1)}
                    />
                    Chat
                </div>
                {(JSON.stringify(defaultAppPerms) !== JSON.stringify(changedAppPerms)) && <button type="submit" className={`${styles.ButtonVoiletSolidSM}`}>Apply</button>}
            </div>
        </form>
    </div>
}

export default PermissionsTab;