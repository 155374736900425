import React, { useEffect, useState } from "react";
import styles from "../../propertydetails.module.css";
import { Trash2, X, Edit, Plus, Eye, Copy, MoreVertical, EyeOff, Search, ArrowLeft } from "react-feather";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip } from "@mui/material";
import axios from "axios";
import { toast } from "react-smart-toaster";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "../../../../components/Loader";
import { Dropdown } from "react-bootstrap";
import moment from "moment";
import LeaseTemplateAddForm from "../LeaseTemplate/AddForm";

const LeaseDocumentTab = (props) => {
    const { getAccessTokenSilently } = useAuth0();
    const [loading, setLoading] = useState(false);
    const [leaseArchId, setLeaseArchId] = useState(0);
    const [leaseDelId, setLeaseDelId] = useState(0);
    const [allLeaseDocuments, setAllLeaseDocuments] = useState([]);
    const [leaseDocuments, setLeaseDocuments] = useState([]);
    const [searchKey, setSearchKey] = useState("");
    const [archiveModalOpen, setArchiveModalOpen] = useState(false);
    const [publishModalOpen, setPublishModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [leaseEditId, setLeaseEditId] = useState(0);

    useEffect(() => {
        if (props?.lease_documents)
            setAllLeaseDocuments(props.lease_documents);
    }, [props?.lease_documents]);

    useEffect(() => {
        let leaseDocumentsTemp = allLeaseDocuments;

        if (searchKey !== '') {
            let inputVal = searchKey;
            inputVal = inputVal.toLowerCase();

            leaseDocumentsTemp = leaseDocumentsTemp.filter((item) => {
                return (item?.title?.toLowerCase().includes(inputVal) || item?.author?.toLowerCase().includes(inputVal));
            });
        }

        setLeaseDocuments(leaseDocumentsTemp);
    }, [allLeaseDocuments, searchKey]);

    const delConfirm = async () => {
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
        setDeleteModalOpen(false);
        setLoading(true);

        axios.delete(process.env.REACT_APP_PROPERTY_API_URL + "v1/properties/" + props.property_id + "/leases/" + leaseDelId, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            setLoading(false);

            props.setSnackbarMsg("Lease document deleted successfully.");
            props.setSnackbarOpen(true);

            let tempDataList = props?.lease_documents?.filter((item) => item.id !== leaseDelId);

            props.setPropertyData(prev => {
                return { ...prev, lease_documents: tempDataList };
            });

            setLeaseDelId(0);

        }).catch((error) => {
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
            setLoading(false);
        });
    };

    const changeStatusConfirm = async (data) => {
        setLoading(true);
        setArchiveModalOpen(false);
        setPublishModalOpen(false);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        axios.put(process.env.REACT_APP_PROPERTY_API_URL + "v1/properties/" + props.property_id + "/leases/" + leaseArchId, { is_archived: data }, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then((response) => {
            setLoading(false);

            props.setSnackbarMsg("Lease document updated successfully.");
            props.setSnackbarOpen(true);

            let tempDataList = props?.lease_documents?.map((item) => {
                if (item.id === leaseArchId) {
                    return { ...item, is_archived: data };
                }
                return item;
            });

            props.setPropertyData(prev => {
                return { ...prev, lease_documents: tempDataList };
            });

            setLeaseArchId(0);
        }).catch((error) => {
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
            setLoading(false);
        });
    };

    const copyDocument = async (id) => {
        setLoading(true);

        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        axios.post(process.env.REACT_APP_PROPERTY_API_URL + "v1/properties/" + props.property_id + "/copy-document/" + id, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then((response) => {
            setLoading(false);

            props.setSnackbarMsg(response?.data?.message);
            props.setSnackbarOpen(true);

            props.setPropertyData(prev => {
                return { ...prev, lease_documents: prev?.lease_documents.concat([response?.data?.data]) };
            });
        }).catch((error) => {
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
            setLoading(false);
        });
    }

    const addLeaseDocument = (data) => {
        props.setPropertyData(prev => {
            return { ...prev, lease_documents: prev?.lease_documents.concat([data]) };
        });
    }

    const updateLeaseDocument = (data) => {
        let leaseDocs = allLeaseDocuments?.map(i => {
            if (i.id === data.id) {
                return data;
            }
            return i;
        })
        props.setPropertyData(prev => {
            return { ...prev, lease_documents: leaseDocs };
        });
    }


    return (<>
        {loading && <Loader />}

        {leaseDocuments?.length === 0 && <div className={`${styles.NoLeaseFound}`}>
            <div>
                <img src="/static/img/no-content.png" alt="" />
                <p className={`${styles.NoLeaseText}`}>No Templates Found</p>
                <button className={`${styles.AddLeaseBU}`} onClick={(e) => { setAddModalOpen(true); setLeaseEditId(0); }}>
                    <Plus /> Templates
                </button>
            </div>
        </div>}

        {leaseDocuments?.length > 0 && (<>
            <div className={`${styles.NotesSearchArea}`}>
                <div className={`${styles.NotesSearchMain}`}>
                    <input type="text" placeholder="Search" className={`${styles.searchInput}`} onChange={(e) => setSearchKey(e.target.value)} />
                    <Search />
                </div>
                <div className={`${styles.clientDoubleButton}`}>
                    <button className={`${styles.AddBUUnit}`} onClick={(e) => { setAddModalOpen(true); setLeaseEditId(0); }}>
                        <Plus /> Document
                    </button>
                </div>
            </div>
            <div className={`${styles.LeaseTemplateRow}`}>

                {leaseDocuments?.map(item => {
                    return (<div className={`${styles.LeaseTemplateCard}`}>
                        <div className={`${styles.LeaseTemplateCardUnder}`}>
                            <div className={`${styles.TemplateCardHead}`}>
                                <Dropdown className={`${styles.ActionLeaseBUSec}`}>
                                    <Dropdown.Toggle className={`${styles.ActionsLeaseBU} ActionsBU`} id="dropdown-basic">
                                        <MoreVertical />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className={`${styles.ActionsBUList} ActionsBUList`}>
                                        <Dropdown.Item className={`${styles.EditActionsBUListItem}`} onClick={(e) => { setAddModalOpen(true); setLeaseEditId(item?.id); }}>
                                            <Edit /> Edit
                                        </Dropdown.Item>
                                        <Dropdown.Item className={`${styles.EditActionsBUListItem}`} onClick={(e) => copyDocument(item?.id)}>
                                            <Copy /> Copy
                                        </Dropdown.Item>
                                        {item?.is_archived === 0 && <Dropdown.Item className={`${styles.EditActionsBUListItem}`} onClick={(e) => { setArchiveModalOpen(true); setLeaseArchId(item?.id); }}>
                                            <EyeOff /> Unpublished
                                        </Dropdown.Item>}
                                        {item?.is_archived === 1 && <Dropdown.Item className={`${styles.EditActionsBUListItem}`} onClick={(e) => { setPublishModalOpen(true); setLeaseArchId(item?.id); }}>
                                            <Eye /> Published
                                        </Dropdown.Item>}
                                        <Dropdown.Item className={`${styles.EditActionsBUListItem} ${styles.Delete}`} onClick={(e) => { setDeleteModalOpen(true); setLeaseDelId(item?.id); }}>
                                            <Trash2 /> Delete
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <div className={`${styles.LeaseIMGSec}`}>
                                    <img src="/static/img/lease-icon.svg" alt="" />
                                </div>
                                <div className={`${styles.LeaseConSec}`}>
                                    <Tooltip title="Lease Agreement" placement="bottom-start">
                                        <p className={`${styles.LeaseConTitle}`}>{item?.title}</p>
                                    </Tooltip>
                                    <p className={`${styles.LeaseConTitle}`}>{item?.author}</p>
                                </div>
                            </div>
                            <div className={`${styles.TemplateCardFoot}`}>
                                <p className={`${styles.LeaseConDate}`}>{moment(item?.updated_at).fromNow()}</p>
                                {item?.is_archived === 0 && <span className={`${styles.PublishBadge}`}>Published</span>}
                                {item?.is_archived === 1 && <span className={`${styles.UnublishBadge}`}>Unpublished</span>}
                            </div>
                        </div>
                    </div>)
                })}

            </div>
        </>)}

        <Dialog
            open={archiveModalOpen}
            onClose={(e) => setArchiveModalOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp"
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle id="alert-dialog-title">Are you sure you want to archive lease document?<button onClick={(e) => setArchiveModalOpen(false)}><X /></button></DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div className="ModalFormGroup">
                        <label className="PopupBodyText">The lease document will be archived immediately.You can publish it at any time.</label>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <button onClick={(e) => setArchiveModalOpen(false)} className="CancelPopupBU">Cancel</button>
                <button className="SubmitPopupBU" onClick={(e) => changeStatusConfirm(1)}>Archive</button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={publishModalOpen}
            onClose={(e) => setPublishModalOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp"
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle id="alert-dialog-title">Are you sure you want to publish lease document?<button onClick={(e) => setPublishModalOpen(false)}><X /></button></DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div className="ModalFormGroup">
                        <label className="PopupBodyText">The lease document will be published immediately. You can unpublish it at any time.</label>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <button onClick={(e) => setPublishModalOpen(false)} className="CancelPopupBU">Cancel</button>
                <button className="SubmitPopupBU" onClick={(e) => changeStatusConfirm(0)}>Publish</button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={deleteModalOpen}
            onClose={(e) => setDeleteModalOpen()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp"
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle id="alert-dialog-title">Are you sure you want to delete the document records?<button onClick={(e) => setDeleteModalOpen()}><X /></button></DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div className="ModalFormGroup">
                        <label className="PopupBodyText">The document record will be removed immediately. You can't undo this action.</label>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <button className="CancelPopupBU" onClick={(e) => delConfirm()}>Delete</button>
                <button onClick={(e) => setDeleteModalOpen()} className="SubmitPopupBU">Cancel</button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={addModalOpen}
            onClose={(e) => setAddModalOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp MobileForm CustomWidthLeasePops"
            fullWidth
            maxWidth="md"
        >
            <DialogTitle id="alert-dialog-title">
                <button className={`${styles.BackAddBU}`} onClick={(e) => setAddModalOpen(false)}><ArrowLeft /></button>
                {leaseEditId > 0 ? 'Update Lease Template' : 'New Lease Template'}
                <button onClick={(e) => setAddModalOpen(false)}><X /></button>
            </DialogTitle>
            <DialogContent>
                <LeaseTemplateAddForm property_id={props?.property_id} leaseEditId={leaseEditId} setAddModalOpen={setAddModalOpen.bind(this)} setLoading={setLoading.bind(this)} setSnackbarMsg={props?.setSnackbarMsg.bind(this)} setSnackbarOpen={props?.setSnackbarOpen.bind(this)} addLeaseDocument={addLeaseDocument.bind(this)} updateLeaseDocument={updateLeaseDocument.bind(this)} />
            </DialogContent>
        </Dialog>
    </>)
}

export default LeaseDocumentTab;