import React, { useEffect, useState } from "react";
import styles from "../properties.module.css";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import NumberFormat from "react-number-format";
import { usePlacesWidget } from "react-google-autocomplete";
import Loader from "../../../components/Loader";
import { useAuth0 } from "@auth0/auth0-react";
import { AddLogCallAPI } from "../../../components/AddLogs";
import { toast } from "react-smart-toaster";

const PhoneField = React.forwardRef((props, ref) => {
  return (
    <NumberFormat
      customInput={TextField}
      prefix=""
      displayType="input"
      type="tel"
      format={"(###) ###-####"}
      {...props}
    />
  );
});

const MaxTwoDigitField = React.forwardRef((props, ref) => {
  return (
    <NumberFormat
      customInput={TextField}
      prefix=""
      displayType="input"
      type="text"
      thousandSeparator={false}
      allowNegative={false}
      decimalScale={0}
      {...props}
    />
  );
});

const FiveDigitFieldformat = React.forwardRef((props, ref) => {
  return (
    <NumberFormat
      customInput={TextField}
      prefix=""
      displayType="input"
      type="text"
      thousandSeparator={false}
      decimalScale={0}
      {...props}
    />
  );
});

const zipRegExp = /\b\d{5}\b/;
const phoneRegExp = /^\(?([0-9]{3})\)?[ ]?([0-9]{3})[-]?([0-9]{4})$/;
const schema = yup.object().shape({
  name: yup
    .string()
    .required("This field is Required")
    .max(50, "Name cannot be more than 50 characters"),
  landlord_agent: yup
    .string()
    .required("This field is Required")
    .max(50, "Landlord/Managing agent cannot be more than 50 characters"),
  email: yup
    .string()
    .required("This field is Required")
    .email("Invalid E-mail")
    .max(50, "Email cannot be more than 50 characters"),
  phone: yup
    .string()
    .required("This field is Required")
    .matches(phoneRegExp, "Invalid phone number"),
  address: yup
    .string()
    .required("This field is Required")
    .max(50, "Address cannot be more than 50 characters"),
  city: yup
    .string()
    .required("This field is Required")
    .max(50, "City cannot be more than 50 characters"),
  state: yup.string().required("This field is Required"),
  zip: yup
    .string()
    .required("This field is Required")
    .matches(zipRegExp, {
      message: "Please enter a valid zip code",
      excludeEmptyString: true,
    }),
  no_of_floor: yup
    .string()
    .required("This field is Required")
    .max(3, "No. of floor can not be more than 2 digits")
    .test(
      "non-zero-validate",
      "This field should be 1 or greater.",
      (value) => parseInt(value) > 0
    ),
});

const AddPropertyForm = ({ closeForm, onSubmitLocal }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [stateList, setStateList] = useState([]);
  const [charsLeft, setCharsLeft] = useState({ name: 50, landlord_agent: 50 });
  const [loading, setLoading] = useState(false);
  const [addrCoordinates, setAddrCoordinates] = useState({ lat: "", lng: "" });

  const { handleSubmit, control, errors, setValue, clearErrors } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      state: "",
      city: "",
    },
  });

  const { ref: autocompleteRef } = usePlacesWidget({
    apiKey: "AIzaSyDrxtVszbIQ4U8rL90V1lDfy5HvN673fcg",
    options: {
      types: ["address"],
      componentRestrictions: { country: "us" },
      fields: ["address_components", "geometry"],
    },
    onPlaceSelected: (place) => {
      let address = "";
      let city = "";
      let state = "";
      let postcode = "";

      for (let component of place.address_components) {
        let componentType = component.types[0];
        switch (componentType) {
          case "street_number": {
            address = component.long_name;
            break;
          }
          case "route": {
            address += " " + component.short_name;
            break;
          }
          case "postal_code": {
            postcode = component.long_name;
            break;
          }
          case "locality":
            city = component.long_name;
            break;
          case "sublocality_level_1":
            city = component.long_name;
            break;
          case "administrative_area_level_3":
            city = component.long_name;
            break;
          case "administrative_area_level_1": {
            state = component.short_name;
            break;
          }
          default:
            break;
        }
      }

      setValue("address", address);
      setValue("city", city);
      setValue("state", state);
      setValue("zip", postcode);

      setAddrCoordinates({
        lat: place?.geometry?.location.lat(),
        lng: place?.geometry?.location.lng(),
      });

      clearErrors("address");
      clearErrors("city");
      clearErrors("state");
      clearErrors("zip");
    },
  });

  useEffect(() => {
    async function fetchState() {
      await axios
        .get(process.env.REACT_APP_PROPERTY_API_URL + "v1/states")
        .then((response) => {
          setStateList(response.data.data);
        })
        .catch((error) => {
          setStateList([]);
        });
    }
    fetchState();
  }, []);

  const onSubmit = async (data) => {
    data = {
      ...data,
      latitude: addrCoordinates?.lat,
      longitude: addrCoordinates?.lng,
    };
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });

    setLoading(true);

    return axios
      .post(process.env.REACT_APP_PROPERTY_API_URL + "v1/properties", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        let respData = response.data.data;
        setLoading(false);
        closeForm(false);
        onSubmitLocal(response.data);

        let logData = {
          title: "A new property is added",
          description: [
            "Name: " + respData.name,
            "Address: " + respData.fulladdress,
          ],
        };
        AddLogCallAPI(logData, token);
      })
      .catch((error) => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
        setLoading(false);
      });
  };

  return (
    <>
      {loading && <Loader />}
      <div className={`${styles.AddPropertyRow}`}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={`${styles.AddPropertyFullSec}`}>
            <div className={`${styles.AddPropertyHalfSecRow}`}>
              <div className={`${styles.HalfSecHalf} FormGroup`}>
                <Controller
                  name={`name`}
                  control={control}
                  render={(field) => (
                    <TextField
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e);
                        setCharsLeft((prev) => {
                          return {
                            ...prev,
                            name: 50 - e.target.value.length,
                          };
                        });
                      }}
                      id="outlined-basic"
                      label="Property Name*"
                      variant="outlined"
                      inputProps={{ maxLength: 50 }}
                    />
                  )}
                />
                <p className={`${styles.RemainingText}`}>
                  {charsLeft?.name > 1
                    ? charsLeft?.name + " characters remaining"
                    : charsLeft?.name + " character remaining"}
                </p>
                {errors.name && (
                  <p className={`${styles.ErrorM}`}>
                    {errors?.name?.message}
                  </p>
                )}
              </div>
              <div className={`${styles.HalfSecHalf} FormGroup`}>
                <Controller
                  name={`landlord_agent`}
                  control={control}
                  render={(field) => (
                    <TextField
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e);
                        setCharsLeft((prev) => {
                          return {
                            ...prev,
                            landlord_agent: 50 - e.target.value.length,
                          };
                        });
                      }}
                      id="outlined-basic"
                      label="Landlord/Managing agent*"
                      variant="outlined"
                      inputProps={{ maxLength: 50 }}
                    />
                  )}
                />
                <p className={`${styles.RemainingText}`}>
                  {charsLeft?.landlord_agent > 1
                    ? charsLeft?.landlord_agent + " characters remaining"
                    : charsLeft?.landlord_agent + " character remaining"}
                </p>
                {errors.landlord_agent && (
                  <p className={`${styles.ErrorM}`}>
                    {errors?.landlord_agent?.message}
                  </p>
                )}
              </div>
              <div className={`${styles.HalfSecHalf} FormGroup`}>
                <Controller
                  name={`email`}
                  control={control}
                  render={(field) => (
                    <TextField
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      id="outlined-basic"
                      label="Email*"
                      variant="outlined"
                    />
                  )}
                />
                {errors.email && (
                  <p className={`${styles.ErrorM}`}>
                    {errors?.email?.message}
                  </p>
                )}
              </div>
              <div className={`${styles.HalfSecHalf} FormGroup`}>
                <Controller
                  name={`phone`}
                  control={control}
                  render={(field) => (
                    <PhoneField
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      id="outlined-basic"
                      label="Phone*"
                      variant="outlined"
                    />
                  )}
                />
                {errors.phone && (
                  <p className={`${styles.ErrorM}`}>
                    {errors?.phone?.message}
                  </p>
                )}
              </div>
              <div className={`${styles.HalfSecBig} FormGroup`}>
                <Controller
                  name={`address`}
                  control={control}
                  render={(field) => (
                    <TextField
                      inputRef={autocompleteRef}
                      placeholder=""
                      onChange={(e) => field.onChange(e)}
                      id="outlined-basic"
                      variant="outlined"
                      label="Address*"
                      value={field.value}
                      error={errors?.full_address}
                    />
                  )}
                />
                {errors.address && (
                  <p className={`${styles.ErrorM}`}>
                    {errors?.address?.message}
                  </p>
                )}
              </div>
              <div className={`${styles.HalfSecSmall} FormGroup`}>
                <Controller
                  name={`no_of_floor`}
                  control={control}
                  render={(field) => (
                    <MaxTwoDigitField
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      id="outlined-basic"
                      label="No. of floors*"
                      variant="outlined"
                      inputProps={{ maxLength: 2 }}
                    />
                  )}
                />
                {errors.no_of_floor && (
                  <p className={`${styles.ErrorM}`}>
                    {errors?.no_of_floor?.message}
                  </p>
                )}
              </div>
              <div className={`${styles.HalfSecMedium} FormGroup`}>
                <Controller
                  name={`city`}
                  control={control}
                  render={(field) => (
                    <TextField
                      {...field}
                      id="outlined-basic"
                      label="City*"
                      variant="outlined"
                    />
                  )}
                />
                {errors.city && (
                  <p className={`${styles.ErrorM}`}>
                    {errors?.city?.message}
                  </p>
                )}
              </div>
              <div className={`${styles.HalfSecMedium} FormGroup`}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    State*
                  </InputLabel>
                  <Controller
                    name="state"
                    control={control}
                    render={(field) => (
                      <Select
                        label="State*"
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                      >
                        {stateList.map((i) => {
                          return (
                            <MenuItem value={i?.value}>{i?.label}</MenuItem>
                          );
                        })}
                      </Select>
                    )}
                  />
                </FormControl>
                {errors.state && (
                  <p className={`${styles.ErrorM}`}>
                    {errors?.state?.message}
                  </p>
                )}
              </div>
              <div className={`${styles.HalfSecMedium} FormGroup`}>
                <Controller
                  name={`zip`}
                  control={control}
                  render={(field) => (
                    <FiveDigitFieldformat
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      format="#####"
                      id="outlined-basic"
                      label="Zip Code*"
                      variant="outlined"
                    />
                  )}
                />
                {errors.zip && (
                  <p className={`${styles.ErrorM}`}>{errors?.zip?.message}</p>
                )}
              </div>
            </div>
          </div>
          <div className={`${styles.ButtonSec}`}>
            <button
              className="CancelPopupBU"
              onClick={(e) => closeForm(false)}
            >
              Cancel
            </button>
            <button type="submit" className="SubmitPopupBU">
              Add
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddPropertyForm;
