import { useEffect, useState } from "react";
import styles from "../dashboard.module.css";
import { Card, CardContent } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import { useHistory } from "react-router-dom";

const SmartDeviceStatus = ({ data }) => {
    const redirect = useHistory();
    const [noData, setNoData] = useState(true);
    const chartData = {
        labels: data?.labels,
        datasets: [
            {
                data: data?.data,
                backgroundColor: [
                    'rgb(101, 198, 119)',
                    'rgb(226, 55, 37)',
                ],
                borderWidth: 4,
            },
        ],
    };

    const options = {
        maintainAspectRatio: false,
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                padding: 30
            }
        },
        cutoutPercentage: 80
    };

    useEffect(() => {
        if (data?.data[0] === 0 && data?.data[1] === 0) {
            setNoData(true);
        } else {
            setNoData(false);
        }
    }, [data]);

    return <div className={`${styles.CardDivTwo} DoughnutChart`}>
        <Card mb={3}>
            <div className={`${styles.InsideCard}`}>
                <div className={`${styles.BatteryHead}`}>
                    <h6 className={`${styles.BatteryCardTitle}`}>Smart Device Status</h6>
                </div>
                <CardContent style={{ display: noData ? 'none' : 'block' }}>
                    <div style={{ height: '270px', position: 'relative' }}>
                        <Doughnut
                            data={chartData}
                            options={options}
                            getElementsAtEvent={(e) => {
                                if (typeof (e[0]?._view?.label) !== 'undefined') {
                                    if (e[0]?._view?.label === 'Offline') {
                                        localStorage.setItem("statusFilterData", 0);
                                        redirect.push('/smart-devices/list');
                                    }
                                    if (e[0]?._view?.label === 'Online') {
                                        localStorage.setItem("statusFilterData", 1);
                                        redirect.push('/smart-devices/list');

                                    }
                                }
                            }}
                        />
                    </div>
                </CardContent>

                {noData && (<div className={`${styles.NoDataSec}`}>
                    <div className={`${styles.NoDataIMG}`}><img src="/static/img/no-device.png" alt="" /></div>
                    <p className={`${styles.NoDataText}`}>Let's fill it up!</p>
                    <p className={`${styles.NoDataTextSub}`}>Device List is Currently Empty.</p>
                </div>)}
            </div>
        </Card>
    </div>;
}

export default SmartDeviceStatus;