import { Archive, Check, Clock, Edit, Eye, MoreVertical, Send, Trash2, UserPlus, X } from "react-feather";
import styles from "../tenantdetails.module.css";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip } from "@mui/material";
import { useHistory } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { useState } from "react";
import dayjs from "dayjs";
import axios from "axios";
import { toast } from "react-smart-toaster";
import { useAuth0 } from "@auth0/auth0-react";


const TenantCard = ({ tenantData, setTenantData, setLoading, setSnackbarOpen, setSnackbarMsg, setShowAddForm, setActiveTenant, activeTenant, setIsAddForm }) => {
    const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
    const redirect = useHistory();
    const [docUrl, setDocUrl] = useState("");
    const [openDocModal, setOpenDocModal] = useState(false);
    const [openArchiveModal, setOpenArchiveModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const viewDocumnets = async (item) => {
        setLoading(true);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
        axios.get(process.env.REACT_APP_TENANT_API_URL + "v1/get-tenant-document/" + item?.foreign_id, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            setLoading(false);
            setOpenDocModal(true);
            setDocUrl(response.data.data?.file_url);
        }).catch((error) => {
            setLoading(false);
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
        });
    }

    const resendWelcomeEmail = async (tId) => {
        setLoading(true);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
        axios.get(process.env.REACT_APP_TENANT_API_URL + "v1/resend-welcome-email/" + tId, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            setLoading(false);
            setSnackbarOpen(true);
            setSnackbarMsg(response.data.message);
        }).catch((error) => {
            setLoading(false);
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
            toast.error("Error occured. Try Again!");
        });
    }

    const archiveModalOpen = async (tId) => {
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
        axios.get(process.env.REACT_APP_TENANT_API_URL + "v1/tenants/check-before-archive/" + tId, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            if (response.data.status === true) {
                setOpenArchiveModal(true);
            } else {
                toast.error(response.data.message);
            }
        }).catch((error) => {
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
        });
    }

    const unArchiveModalOpen = async (tId) => {
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
        axios.get(process.env.REACT_APP_TENANT_API_URL + "v1/tenants/check-before-unarchive/" + tId, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            if (response.data.status === true) {
                setOpenArchiveModal(true);
            } else {
                toast.error(response.data.message);
            }
        }).catch((error) => {
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
        });
    };

    const archiveConfirm = async () => {
        setLoading(true);
        setOpenArchiveModal(false);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        let is_archived = 1 - tenantData?.is_archived;
        let curUrl = process.env.REACT_APP_TENANT_API_URL + "v1/tenants/" + tenantData?.id + "/archive";
        if (is_archived === 0) {
            curUrl = process.env.REACT_APP_TENANT_API_URL + "v1/tenants/" + tenantData?.id + "/unarchive";
        }
        if (is_archived === 1) {
            curUrl = process.env.REACT_APP_TENANT_API_URL + "v1/tenants/" + tenantData?.id + "/archive";
        }
        axios.put(curUrl, { is_archived: is_archived }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            setLoading(false);
            setSnackbarOpen(true);
            if (is_archived === 0) {
                setSnackbarMsg("Tenant unarchived successfully. The Lease and associated Rent Roll records will be unarchived immediately");
            } else {
                setSnackbarMsg("Tenant archived successfully. The Lease and associated Rent Roll records will be archived immediately");
            }

            setTenantData(prev => {
                return { ...prev, is_archived: is_archived }
            });
        }).catch((error) => {
            setLoading(false);
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
        });
    }

    const deleteConfirm = async () => {
        setLoading(true);
        setOpenDeleteModal(false);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        axios.delete(process.env.REACT_APP_TENANT_API_URL + "v1/tenants/" + tenantData?.id, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            setLoading(false);
            setSnackbarOpen(true);
            setSnackbarMsg(response.data.message);

            setTimeout(() => {
                redirect.push("/people/tenants");
            }, 2000);
        }).catch((error) => {
            setLoading(false);
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
        });
    }

    return <>
        <div className={`${styles.SMProCusSmallDiv}`}>
            <div className={`${styles.CardSmall}`}>
                <div className={`${styles.SMProCardHead}`}>
                    <div className={tenantData?.all_tenants.length === 3 ? `${styles.SMProPicMainDubble} ${styles.Three}` : tenantData?.all_tenants.length === 2 ? `${styles.SMProPicMainDubble}` : `${styles.SMProPicMain}`}>
                        {tenantData?.all_tenants.map((item, index) => {
                            return (<Tooltip title={item?.full_name} placement="bottom" onClick={(e) => setActiveTenant(index)}>
                                <div className={activeTenant === index ? `${styles.avatar} ${styles.active}` : `${styles.avatar}`}>
                                    {(item?.user_image !== "") ? <img src={item?.user_image} className={`${styles.roundedcircle}`} alt="" /> : <p>{item?.initial}</p>}
                                </div>
                            </Tooltip>);
                        })}
                    </div>
                    <h5>
                        {tenantData?.all_tenants.length > 0 && tenantData?.all_tenants[activeTenant] ? tenantData?.all_tenants[activeTenant]?.full_name : null}
                        {tenantData?.all_tenants.length > 0 && tenantData?.all_tenants[activeTenant] && tenantData?.all_tenants[activeTenant]?.verification_status === 1 && (<img src="/static/img/verified.svg" className={`${styles.Varified}`} alt="" />)}
                    </h5>
                    <Dropdown className={`${styles.ProEditSec}`}>
                        <Dropdown.Toggle className={`${styles.EditActionsBU} ProsDrBU`} id="dropdown-basic"><MoreVertical /></Dropdown.Toggle>
                        <Dropdown.Menu className={`${styles.ActionsBUList} ActionsBUList`}>
                            <Dropdown.Item className={`${styles.EditActionsBUListItem}`} onClick={(e) => { setShowAddForm(true); setIsAddForm(false); }}><Edit /> Edit</Dropdown.Item>
                            {isAuthenticated && user["https://propertydek.com/permissions"].indexOf("tenant:update") > -1 && tenantData?.is_archived === 0 && tenantData?.all_tenants?.length < 3 && (<Dropdown.Item className={`${styles.EditActionsBUListItem}`} onClick={(e) => { setShowAddForm(true); setIsAddForm(true); }}><UserPlus /> New Tenant</Dropdown.Item>)}
                            {isAuthenticated && user["https://propertydek.com/permissions"].indexOf("tenant:update") > -1 && tenantData?.is_archived === 1 && (<Dropdown.Item className={`${styles.EditActionsBUListItem}`} onClick={(e) => unArchiveModalOpen(tenantData?.id)}><Archive /> Unarchive All</Dropdown.Item>)}
                            {isAuthenticated && user["https://propertydek.com/permissions"].indexOf("tenant:update") > -1 && tenantData?.is_archived === 0 && (<Dropdown.Item className={`${styles.EditActionsBUListItem}`} onClick={(e) => archiveModalOpen(tenantData?.id)}><Archive /> Archive All</Dropdown.Item>)}
                            <Dropdown.Item className={`${styles.EditActionsBUListItem}`} onClick={(e) => resendWelcomeEmail(tenantData?.id)}><Send /> Resend Welcome Email</Dropdown.Item>
                            {isAuthenticated && user["https://propertydek.com/permissions"].indexOf("tenant:delete") > -1 && (<Dropdown.Item className={`${styles.EditActionsBUListItem} ${styles.Delete}`} onClick={(e) => setOpenDeleteModal(true)}><Trash2 /> Delete All</Dropdown.Item>)}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className={`${styles.SMProCardDe}`}>
                    <ul>
                        <li>
                            <span className={`${styles.SMProDETag}`}>Email:</span>
                            <span className={`${styles.SMProDE}`}>{tenantData?.all_tenants.length > 0 && tenantData?.all_tenants[activeTenant] && tenantData?.all_tenants[activeTenant]?.email}</span>
                        </li>
                        <li>
                            <span className={`${styles.SMProDETag}`}>Phone:</span>
                            <span className={`${styles.SMProDE}`}>{tenantData?.all_tenants.length > 0 && tenantData?.all_tenants[activeTenant] && tenantData?.all_tenants[activeTenant]?.phone}</span>
                        </li>
                        <li>
                            <span className={`${styles.SMProDETag}`}>Property:</span>
                            <span className={`${styles.SMProDE}`}>{tenantData?.property?.name}</span>
                        </li>
                        <li>
                            <span className={`${styles.SMProDETag}`}>Apartment:</span>
                            <span className={`${styles.SMProDE}`}>{tenantData?.apartment?.apt_no}</span>
                        </li>
                    </ul>
                </div>
                <div className={`${styles.cardUnderS}`}>
                    <div className={`${styles.cardUnderSHead}`}>
                        <div className={`${styles.HeadIcon}`}>
                            <img src="/static/img/tenant-journey.png" alt="" />
                        </div>
                        <div className={`${styles.HeadCon}`}>
                            <p className={`${styles.HeadConTitle}`}>Tenant Journey</p>
                            <p className={`${styles.HeadConText}`}>Tenant Since {dayjs(tenantData?.journey[0]?.submitted_at).format("MMM D, YYYY")}</p>
                        </div>
                    </div>
                    <div className={`${styles.cardUnderSBody}`}>
                        <ul>
                            {tenantData?.journey?.map((item) => {
                                let className = "";
                                if (item?.status === 1) {
                                    className = `${styles.Completed}`;
                                } else if (item?.status === 2) {
                                    className = `${styles.Failed}`;
                                } else {
                                    className = `${styles.Ongoing}`;
                                }
                                return <li key={item.id} className={className}>
                                    {item?.status === 1 && (<Tooltip title="Completed"><span><Check /></span></Tooltip>)}
                                    {item?.status === 0 && (<Tooltip title="Ongoing"><span><Clock /></span></Tooltip>)}
                                    {item?.status === 2 && (<Tooltip title="Failed"><span>!</span></Tooltip>)}
                                    <div>
                                        {(item?.status === 1 && item?.type === "id-verify") ? (<button onClick={(e) => viewDocumnets(item)}>{item?.description} <Eye /></button>) : <p>{item?.description}</p>}
                                        <p className={`${styles.Date}`}>{dayjs(item?.submitted_at).format("MMMM D, YYYY")}</p>
                                    </div>
                                </li>
                            })}
                        </ul>
                    </div>
                </div>
                <div className={`${styles.cardUnderS}`}>
                    <div className={`${styles.cardUnderSHead}`}>
                        <div className={`${styles.HeadIcon}`}>
                            <img src="/static/img/transaction-summary.png" alt="" />
                        </div>
                        <div className={`${styles.HeadCon}`}>
                            <p className={`${styles.HeadConTitle}`}>Transaction Summary</p>
                        </div>
                    </div>
                    <div className={`${styles.cardUnderSBody}`}>
                        <div className={`${styles.SummeryList} ${styles.Green}`}>
                            <span>Deposits:</span>{tenantData?.depositstr}
                        </div>
                        <div className={`${styles.SummeryList} ${styles.Red}`}>
                            <span>Outstanding:</span>{tenantData?.balancestr}
                        </div>
                        <div className={`${styles.SummeryList} ${styles.Green}`}>
                            <span>Payment Confidence:</span>{tenantData?.payment_confidence}%
                        </div>
                    </div>
                </div>
            </div>
        </div >

        <Dialog
            onClose={(e) => { setOpenDocModal(false); setDocUrl(""); }}
            aria-labelledby="customized-dialog-title"
            open={openDocModal}
            className="NewPopUp NewPopUpForLease"
        >
            <button onClick={(e) => { setOpenDocModal(false); setDocUrl(""); }} className={`${styles.IDImageCloseBU}`}><X /></button>
            <DialogContent>
                <div className={`${styles.IDImageSec}`}>
                    <img src={docUrl} className={`${styles.IDImage}`} alt="" />
                </div>
            </DialogContent>
        </Dialog>

        <Dialog
            open={openArchiveModal}
            fullWidth
            maxWidth="sm"
            onClose={(e) => setOpenArchiveModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp"
        >
            <DialogTitle id="alert-dialog-title">Are you sure you want to {tenantData?.is_archived ? "unarchive" : "archive"} the Tenant Record?<button onClick={(e) => setOpenArchiveModal(false)}><X /></button></DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div className="ModalFormGroup">
                        <label className="PopupBodyText">{tenantData?.is_archived ? "The Tenant Record will be unarchived immediately.You can archive it at any time." : "The Tenant will be moved out and Tenant Record will be archived immediately.You can unarchive it at any time."}</label>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button className="CancelPopupBU" onClick={(e) => archiveConfirm()}>{tenantData?.is_archived ? "Unarchive" : "Archive"}</Button>
                <Button onClick={(e) => setOpenArchiveModal(false)} className="SubmitPopupBU">Cancel</Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={openDeleteModal}
            fullWidth
            maxWidth="sm"
            onClose={(e) => setOpenDeleteModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp"
        >
            <DialogTitle id="alert-dialog-title">Are you sure you want to delete the Tenant Record?<button onClick={(e) => setOpenDeleteModal(false)}><X /></button></DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div className="ModalFormGroup">
                        <label className="PopupBodyText">The Tenant Record will be removed immediately.You can't undo this action.</label>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={(e) => deleteConfirm()} className="CancelPopupBU">Delete</Button>
                <Button onClick={(e) => setOpenDeleteModal(false)} className="SubmitPopupBU">Cancel</Button>
            </DialogActions>
        </Dialog>
    </>
}

export default TenantCard;