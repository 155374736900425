import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Loader from "../../components/Loader";
import Helmet from "react-helmet";
import { Home, Calendar, X } from "react-feather";
import styles from './addlease.module.css';
import { Typography, Button, Switch, FormControlLabel, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import Footer from "../../components/Footer";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Select from "react-select";
import axios from 'axios';
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import { toast } from 'react-smart-toaster';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import NumberFormat from "react-number-format";
import { AddLogCallAPI } from "../../components/AddLogs";

const brandColor = '#cccccc';
const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: state.isFocused ? 0 : 0,
        borderColor: state.isFocused
            ? brandColor
            : base.borderColor,
        '&:hover': {
            borderColor: state.isFocused
                ? brandColor
                : base.borderColor,
        }
    })
};

function MoneyField(props) {
    return <NumberFormat
        prefix=""
        displayType="input"
        type="text"
        thousandSeparator={true}
        allowNegative={false}
        decimalScale={2}
        fixedDecimalScale
        {...props} />;
}

const schema = yup.object().shape({
    property: yup.mixed().required('This field is Required'),
    tenant: yup.mixed().required('This field is Required'),
    security_amount: yup.string().required('This field is Required')
        .test("non-zero-validate", "This field should be $1 or greater.", (value) => parseInt(value) > 0)
        .test("max-4-digit", "Security Amount can not be more than 6 digits.", (value) => {
            return parseInt(value.replace(/,/g, '')) < 10000;
        }),
    rent: yup.string().required('This field is Required')
        .test("non-zero-validate", "This field should be $1 or greater.", (value) => parseInt(value) > 0)
        .test("max-4-digit", "Rent can not be more than 6 digits.", (value) => {
            return parseInt(value.replace(/,/g, '')) < 10000;
        }),
    lease_term: yup.mixed().required('This field is Required'),
    start_date: yup.date().required('This field is Required'),
    end_date: yup.date().required('This field is Required')
});

function RenewLease({ history, match }) {
    const { getAccessTokenSilently } = useAuth0();
    const { id } = match.params;
    const redirect = useHistory();
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [state, setState] = React.useState({ checkedA: true });
    const [IsEsign, setIsEsign] = useState(0);
    const [propertyDDList, setPropertyDDList] = useState([]);
    const [tenantDDList, setTenantDDList] = useState([]);
    const [oldSecurityAmount, setOldSecurityAmount] = useState(0);
    const [isTemplate, setIsTemplate] = useState(0);

    const { register, handleSubmit, control, errors, setValue, getValues } = useForm({
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        async function fetchTenantProperty() {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            await axios.get(process.env.REACT_APP_PAYMENT_API_URL + 'v1/tenant-lease-det/' + id, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then(response => {
                let tempData = response.data.data;
                let oldRent = parseInt(tempData.rent);
                let oldSecurity = parseInt(tempData.security_amount);
                let newRent = oldRent + (oldRent / 10);
                newRent = parseInt(newRent);
                let newSecurity = newRent - oldSecurity;
                if (newSecurity < 0) {
                    newSecurity = 0;
                }
                let start_date = moment(tempData.end_date).add(1, 'day');
                let end_date = moment(tempData.end_date).add(1, 'year');
                setPropertyDDList([{ value: tempData.property_id, label: tempData.property }]);
                setTenantDDList([{ value: tempData.id, label: tempData.tenant_name }]);
                setValue('property', { value: tempData.property_id, label: tempData.property });
                setValue('tenant', { value: tempData.id, label: tempData.tenant_name });
                setValue('lease_term', { 'value': '1 Year', 'label': '1 Year' });
                setValue('old_security_amount', oldSecurity);
                setValue('security_amount', newSecurity);
                setValue('rent', newRent);
                setValue('start_date', start_date);
                setValue('end_date', end_date);
                setOldSecurityAmount(oldSecurity);
                setIsTemplate(tempData.is_temaplte);
                setIsEsign(tempData.is_temaplte);

            }).catch(error => {
                console.log('Error');
            });
        }
        fetchTenantProperty();
    }, [id, getAccessTokenSilently, setValue])

    async function onSubmit(data) {
        setLoading(true);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        let postData = {
            rent: data.rent.replace(/,/g, ''),
            old_security_amount: oldSecurityAmount,
            security_amount: data.security_amount.replace(/,/g, ''),
            start_date: moment(data.start_date).format('YYYY-MM-DD'),
            end_date: moment(data.end_date).format('YYYY-MM-DD'),
            tenant_id: data.tenant.value,
            first_rent: data.rent.replace(/,/g, ''),
            lease_term: data.lease_term.value,
            is_esign: IsEsign
        }

        return axios.post(process.env.REACT_APP_PAYMENT_API_URL + 'v1/renew-lease', postData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(response => {
            setLoading(false);
            let message = response.data.message;
            let resdocumentData = response.data.documentData;
            if (resdocumentData && resdocumentData?.sendUrl) {

                const myWindow = window.open(resdocumentData?.sendUrl, "Create Document", "width=1000,height=600");

                let timer = setInterval(function () {
                    if (myWindow?.closed) {
                        clearInterval(timer);

                        let logData = {
                            'title': 'A new lease is added',
                            'description': [
                                'Tenant: ' + response.data?.data?.tenant_name,
                                'Property: ' + response.data?.data?.property_name + ' - ' + response.data?.data?.apt_no,
                                'Lease Term: ' + moment(response.data?.data?.start_date).format('MM/DD/YYYY') + ' - ' + moment(response.data?.data?.end_date).format('MM/DD/YYYY')
                            ]
                        }
                        AddLogCallAPI(logData, token);

                        toast.success(message);
                        setTimeout(() => {
                            redirect.push('/leases');
                        }, 3000);
                    }
                }, 1000);
            } else {

                let logData = {
                    'title': 'Lease is renewed',
                    'description': [
                        'Tenant: ' + response.data?.data?.tenant_name,
                        'Property: ' + response.data?.data?.property_name + ' - ' + response.data?.data?.apt_no,
                        'Lease Term: ' + moment(response.data?.data?.start_date).format('MM/DD/YYYY') + ' - ' + moment(response.data?.data?.end_date).format('MM/DD/YYYY')
                    ]
                }
                AddLogCallAPI(logData, token);

                toast.success(message);
                setTimeout(() => {
                    redirect.push('/leases');
                }, 3000);
            }
        }).catch(error => {
            if (typeof error.response !== 'undefined')
                toast.error(error.response.data.message);
            setLoading(false);
        });
    }


    const createNewTemplate = () => {
        setModalOpen(false);
    }

    const handleChange = (event) => {
        setIsEsign(event.target.checked ? 1 : 0);
        setState({ ...state, [event.target.name]: event.target.checked });
    }

    /*
    const redirect = useHistory();
    
    const [defaulProperty, setDefaulProperty] = useState(false);
    const [TenantOptions, setTenantOptions] = useState([]);
    const [TenantList, setTenantList] = useState({ data: [], loading: false });
    const [PropertyList, setPropertyList] = useState({ data: [], loading: false });
    

    

    useEffect(() => {
        async function fetchProperty() {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            await axios.get(process.env.REACT_APP_PAYMENT_API_URL + 'v1/property-list', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then(response => {
                setPropertyList({ data: response.data.data, loading: true });
            }).catch(error => {
                setPropertyList({ data: [], loading: true });
            });
        }
        fetchProperty();
    }, [setPropertyList, getAccessTokenSilently]);

    useEffect(() => {
        async function fetchTenant() {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            await axios.get(process.env.REACT_APP_PAYMENT_API_URL + 'v1/tenant-list/add-lease', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then(response => {
                setTenantList({ data: response.data.data, loading: true });
            }).catch(error => {
                setTenantList({ data: [], loading: true });
            });
        }
        fetchTenant();
    }, [setTenantList, getAccessTokenSilently]);


    useEffect(() => {
        setValue('lease_term', { 'value': '1 Year', 'label': '1 Year' });
    }, [setValue]);

    

    async function propertyChange(event) {
        if (IsEsign === 1) {
            setLoading(true);
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
            axios.get(process.env.REACT_APP_PAYMENT_API_URL + 'v1/check-template/' + event.value, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then(response => {
                setLoading(false);
                if (response.data?.type === 1) {
                    setDefaulProperty(event);
                    setValue('property', event);
                    setValue('tenant', null);
                    let tenants = TenantList.data.filter(i => i.property_id === event.value);
                    setTenantOptions(tenants);
                } else {
                    setModalOpen(true);
                    setSelectedProperty(event.value);

                    setDefaulProperty(null);
                    setValue('property', null);
                    setValue('tenant', null);
                    setTenantOptions([]);
                }
            });
        } else {
            setDefaulProperty(event);
            setValue('property', event);
            setValue('tenant', null);
            let tenants = TenantList.data.filter(i => i.property_id === event.value);
            setTenantOptions(tenants);
        }
    }

    

    
    */

    return (<React.Fragment>
        {loading && <Loader />}
        <Helmet title={'Renew Lease'} />
        <div className={`${styles.pageTitleWrap}`}>
            <Typography display="inline" className={`${styles.pageTitle}`}>Renew Lease</Typography>
            <nav aria-label="breadcrumb">
                <ol className={`${styles.breadcrumb}`}>
                    <li className={`${styles.breadcrumbItem}`}><span><Home /></span>Payment</li>
                    <li className={`${styles.breadcrumbItem}`}><Link to={'/leases'} >Leases</Link></li>
                    <li className={`${styles.breadcrumbItem} ${styles.active}`}>Renew</li>
                </ol>
            </nav>
        </div>
        <div className={`${styles.mainCard}`}>
            <div className={`${styles.AddUserMain}`}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={`${styles.FormRow}`}>
                        {(isTemplate === 1) && <div className={`${styles.FormGroup} OnOffSwitch Right`}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={state.checkedA}
                                        onChange={handleChange}
                                        name="checkedA"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                }
                                label="E-Sign"
                                labelPlacement="start"
                            />
                        </div>}
                        <div className={`${styles.FormGroup}`}>
                            <label className={`${styles.FilterLabel}`} >Property<span className={`${styles.errorSpan}`}>*</span></label>
                            <Controller
                                name="property"
                                control={control}
                                ref={register}
                                render={(field) => (
                                    <Select {...field} styles={customStyles} isSearchable={true} options={propertyDDList} />
                                )}
                            />
                            {errors.property && <p className={`${styles.ErrorM}`}>{errors.property.message}</p>}
                        </div>
                        <div className={`${styles.FormGroup}`}>
                            <label className={`${styles.FilterLabel}`} >Tenant<span className={`${styles.errorSpan}`}>*</span></label>
                            <Controller
                                name="tenant"
                                control={control}
                                ref={register}
                                isSearchable={true}
                                render={(props) => (
                                    <Select {...props} styles={customStyles} isSearchable={true} options={tenantDDList} />
                                )}
                            />
                            {errors.tenant && <p className={`${styles.ErrorM}`}>{errors.tenant.message}</p>}
                        </div>
                    </div>
                    <div className={`${styles.FormRow}`}>
                        <div className={`${styles.FormGroup}`}>
                            <label className={`${styles.FilterLabel}`} >Lease Term<span className={`${styles.errorSpan}`}>*</span></label>
                            <Controller
                                name="lease_term"
                                control={control}
                                options={[{ 'value': '6 Months', 'label': '6 Months' }, { 'value': '1 Year', 'label': '1 Year' }, { 'value': '2 Years', 'label': '2 Years' }, { 'value': '3 Years', 'label': '3 Years' }]}
                                ref={register}
                                render={(props) => (
                                    <Select {...props} styles={customStyles} isSearchable={true} options={[{ 'value': '6 Months', 'label': '6 Months' }, { 'value': '1 Year', 'label': '1 Year' }, { 'value': '2 Years', 'label': '2 Years' }, { 'value': '3 Years', 'label': '3 Years' }]} onChange={(e) => {
                                        props.onChange(e);
                                        if (e?.value === '6 Months') {
                                            setValue('end_date', moment(getValues('start_date')).add(6, 'months').subtract(1, 'day'));
                                        }
                                        if (e?.value === '1 Year') {
                                            setValue('end_date', moment(getValues('start_date')).add(1, 'year').subtract(1, 'day'));
                                        }
                                        if (e?.value === '2 Years') {
                                            setValue('end_date', moment(getValues('start_date')).add(2, 'years').subtract(1, 'day'));
                                        }
                                        if (e?.value === '3 Years') {
                                            setValue('end_date', moment(getValues('start_date')).add(3, 'years').subtract(1, 'day'));
                                        }
                                    }} />
                                )}
                            />
                            {errors.lease_term && <p className={`${styles.ErrorM}`}>{errors.lease_term.message}</p>}
                        </div>
                    </div>
                    <div className={`${styles.FormRow}`}>
                        <div className={`${styles.FormGroupS} ${styles.SYS}`}>
                            <label className={`${styles.FilterLabel}`} >Security Amount<span className={`${styles.errorSpan}`}>*</span></label>
                            <p className={`${styles.SymbalP}`}>$</p>
                            <Controller
                                name="security_amount"
                                as={<MoneyField />}
                                control={control}
                                ref={register}
                                className={`${styles.formControl}`}
                            />
                            {errors.security_amount && <p className={`${styles.ErrorM}`}>{errors.security_amount.message}</p>}
                        </div>
                        <div className={`${styles.FormGroupS} ${styles.SYS}`}>
                            <label className={`${styles.FilterLabel}`} >Monthly Rent<span className={`${styles.errorSpan}`}>*</span></label>
                            <p className={`${styles.SymbalP}`}>$</p>
                            <Controller
                                name="rent"
                                as={<MoneyField />}
                                control={control}
                                ref={register}
                                className={`${styles.formControl}`}
                            />
                            {errors.rent && <p className={`${styles.ErrorM}`}>{errors.rent.message}</p>}
                        </div>
                    </div>
                    <div className={`${styles.FormRow}`}>
                        <div className={`${styles.FormGroupS}`}>
                            <label className={`${styles.FilterLabel}`} >Start Date<span className={`${styles.errorSpan}`}>*</span></label>
                            <div className={`${styles.TimePick} TimePick`}>
                                <Calendar className={`${styles.ClockIcon}`} />
                                <Controller
                                    control={control}
                                    name="start_date"
                                    ref={register}
                                    render={(props) => (
                                        <DatePicker
                                            format="MM/dd/yyyy"
                                            value={props.value}
                                            onChange={(e) => {
                                                props.onChange(e);
                                                if (getValues('lease_term')?.value === '6 Months') {
                                                    setValue('end_date', moment(e).add(6, 'months').subtract(1, 'day'));
                                                } else if (getValues('lease_term')?.value === '1 Year') {
                                                    setValue('end_date', moment(e).add(1, 'year').subtract(1, 'day'));
                                                } else if (getValues('lease_term')?.value === '2 Years') {
                                                    setValue('end_date', moment(e).add(2, 'years').subtract(1, 'day'));
                                                } else if (getValues('lease_term')?.value === '3 Years') {
                                                    setValue('end_date', moment(e).add(3, 'years').subtract(1, 'day'));
                                                } else {
                                                    setValue('end_date', moment(e).add(1, 'year').subtract(1, 'day'));
                                                }
                                            }}
                                        />
                                    )}
                                />
                                {errors.start_date && <p className={`${styles.ErrorM}`}>{errors.start_date.message}</p>}
                            </div>
                        </div>
                        <div className={`${styles.FormGroupS}`}>
                            <label className={`${styles.FilterLabel}`} >End Date<span className={`${styles.errorSpan}`}>*</span></label>
                            <div className={`${styles.TimePick} TimePick`}>
                                <Calendar className={`${styles.ClockIcon}`} />
                                <Controller
                                    control={control}
                                    name="end_date"
                                    ref={register}
                                    render={(props) => (
                                        <DatePicker
                                            format="MM/dd/yyyy"
                                            value={props.value}
                                            onChange={(e) => {
                                                props.onChange(e);
                                                if (getValues('lease_term')?.value === '6 Months') {
                                                    setValue('start_date', moment(e).subtract(6, 'months'));
                                                } else if (getValues('lease_term')?.value === '1 Year') {
                                                    setValue('start_date', moment(e).subtract(1, 'year'));
                                                } else if (getValues('lease_term')?.value === '2 Years') {
                                                    setValue('start_date', moment(e).subtract(2, 'years'));
                                                } else if (getValues('lease_term')?.value === '3 Years') {
                                                    setValue('start_date', moment(e).subtract(3, 'years'));
                                                } else {
                                                    setValue('start_date', moment(e).subtract(1, 'year'));
                                                }
                                            }}
                                        />
                                    )}
                                />
                                {errors.end_date && <p className={`${styles.ErrorM}`}>{errors.end_date.message}</p>}
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.FormRow}`}>
                        <div className={`${styles.ButtonDiv}`}>
                            <Link to={'/leases'} as={Button} className={`${styles.ButtonVoiletLine}`}>Cancel</Link>
                            <button type="submit" className={`${styles.ButtonVoiletSolid}`}>Add</button>
                        </div>
                    </div>
                </form>
            </div>
        </div >

        <Dialog
            open={modalOpen}
            fullWidth
            maxWidth="xs"
            onClose={() => setModalOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp"
        >
            <DialogTitle id="alert-dialog-title">Lease Required<button onClick={() => setModalOpen(false)}><X /></button></DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div className="ModalFormGroup">
                        <label className="PopupBodyText">The property has not be setup with a lease. Would you like to upload a lease now?</label>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions className={`${styles.ButtonSec}`}>
                <Button onClick={createNewTemplate} className="SubmitPopupBU">Yes</Button>
                <Button onClick={() => setModalOpen(false)} className="CancelPopupBU">No</Button>
            </DialogActions>
        </Dialog>

        <Footer />
    </React.Fragment >);


}

export default withAuthenticationRequired(RenewLease, {
    onRedirecting: () => <Loader />,
});
