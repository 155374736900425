import React from "react";
import { useSelector } from "react-redux";

import { Helmet } from "react-helmet";

import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider } from "styled-components/macro";
import PubNub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';

import createTheme from "./theme";
import 'rsuite/dist/rsuite.min.css';
import Routes from "./routes/Routes";
import './global.css';
import GetUserInfo from "./services/userInfoService";
import InitialAPICall from "./components/initialAPICall";


const pubnub = new PubNub({
    publishKey: process.env.REACT_APP_PUBNUB_PUBLISHKEY,
    subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBEKEY,
    uuid: "123myuiididui2345624",
    ssl: true,
});

function App() {
    const theme = useSelector((state) => state.themeReducer);

    return (
        <React.Fragment>
            <PubNubProvider client={pubnub}>
                <GetUserInfo></GetUserInfo>
                <Helmet titleTemplate="%s | PropertyDek" defaultTitle="PropertyDek" />

                <StylesProvider injectFirst>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <MuiThemeProvider theme={createTheme(theme.currentTheme)}>
                            <ThemeProvider theme={createTheme(theme.currentTheme)}>

                                <Routes />
                                <InitialAPICall />


                            </ThemeProvider>
                        </MuiThemeProvider>
                    </MuiPickersUtilsProvider>
                </StylesProvider>
            </PubNubProvider>
        </React.Fragment>
    );
}

export default App;