import React, { useState, useEffect } from "react";
import styles from "../../propertydetails.module.css";
import { Search, Trash2, X, Plus, ArrowLeft, Copy } from "react-feather";
import { Tooltip, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TablePagination } from "@mui/material";
import axios from "axios";
import { toast } from "react-smart-toaster";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "../../../../components/Loader";
import { AddLogCallAPI } from "../../../../components/AddLogs";
import LayoutAdd from "../AddLayoutForm";

const LayoutTab = (props) => {
    const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
    const [loading, setLoading] = useState(false);
    const [showAddLayoutForm, setShowAddLayoutForm] = useState(false);
    const [showEditLayoutForm, setShowEditLayoutForm] = useState(false);
    const [editLayoutData, setEditLayoutData] = useState(null);
    const [allLayoutList, setAllLayoutList] = useState([]);
    const [layoutList, setLayoutList] = useState([]);
    const [page, setPage] = useState(0);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(0);
    const [filterData, setFilterData] = useState({ searchKey: "" });
    const [perPage, setPerPage] = useState(5);
    const [unitModalOpen, setUnitModalOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(0);



    useEffect(() => {
        if (props?.layouts)
            setAllLayoutList(props.layouts);
    }, [props?.layouts]);

    useEffect(() => {
        let filteredList = allLayoutList;

        if (filterData.searchKey !== "") {
            let inputVal = filterData.searchKey;
            inputVal = inputVal.toLowerCase();
            filteredList = filteredList.filter((item) => item.title.toLowerCase().includes(inputVal) || item.unit_type.toLowerCase().includes(inputVal));
        }

        setLayoutList(filteredList);
    }, [allLayoutList, filterData]);

    const deleteConfirm = async () => {
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
        setDeleteModalOpen(false);
        setLoading(true);

        return axios.delete(process.env.REACT_APP_PROPERTY_API_URL + "v1/properties/" + props?.property_id + "/layouts/" + deleteId, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            setLoading(false);

            props.setSnackbarMsg(response.data.message);
            props.setSnackbarOpen(true);

            let layouts = allLayoutList.filter((item) => item.id === deleteId);

            let tempDataList = allLayoutList.filter((item) => item.id !== deleteId);

            props.setPropertyData(prev => {
                return { ...prev, layouts: tempDataList };
            });

            setDeleteId(0);

            let logData = {
                title: "Layout is deleted",
                description: [
                    "Unit No: " + layouts[0].title,
                    "Unit Type: " + layouts[0].unit_type,
                    "Property: " + props?.property_name
                ],
            };
            AddLogCallAPI(logData, token);
        }).catch((error) => {
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
            setLoading(false);
        });
    }

    const addLayoutRow = (data) => {
        props.setSnackbarMsg('Layout added successfully');
        props.setSnackbarOpen(true);

        props.setPropertyData(prev => {
            let layoutList = [data].concat(prev?.layouts);
            return { ...prev, layouts: layoutList };
        });
    }

    const editLayoutRow = (data) => {
        props.setSnackbarMsg('Layout updated successfully');
        props.setSnackbarOpen(true);

        props.setPropertyData(prev => {
            let unitList = prev?.property_units?.map(i => {
                if (i?.layout_id === data?.id)
                    return { ...i, ada_complaint: data?.ada_complaint, bathroom: data?.bathroom, description: data?.description, formatted_rent: data?.formatted_rent, layout_images: data?.images, parking: data?.parking, rent: data?.rent, size: data?.size, layout: data?.title, apt_type: data?.unit_type };
                return i;
            });

            let layoutList = prev?.layouts?.map(i => {
                if (i?.id === data?.id)
                    return data;
                return i;
            });
            return { ...prev, layouts: layoutList, property_units: unitList };
        });
    }

    const copyUnitConfirm = async () => {
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
        setUnitModalOpen(false);
        setLoading(true);

        return axios.post(process.env.REACT_APP_PROPERTY_API_URL + "v1/properties/" + props?.property_id + "/copy-layout/" + selectedId, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            setLoading(false);

            props.setSnackbarMsg(response.data.message);
            props.setSnackbarOpen(true);

            addLayoutRow(response.data.data);

            let logData = {
                title: "Layout is copied",
                description: [
                    "Title: " + response.data.data.title,
                    "Property: " + props?.property_name
                ],
            };
            AddLogCallAPI(logData, token);
        }).catch((error) => {
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
            setLoading(false);
        });
    }

    return (<>
        {loading && <Loader />}

        <div className={`${styles.NotesSearchArea}`}>
            <div className={`${styles.NotesSearchMain}`}>
                <input type="text" placeholder="Search" className={`${styles.searchInput}`} onChange={(e) => setFilterData(prev => { return { ...prev, searchKey: e.target.value } })} />
                <Search />
            </div>
            <div className={`${styles.clientDoubleButton}`}>
                {isAuthenticated && user["https://propertydek.com/permissions"].indexOf("property:update") > -1 && (
                    <button className={`${styles.AddBUUnit}`} onClick={(e) => { setShowAddLayoutForm(true); setEditLayoutData(null); }}><Plus /> Layout</button>
                )}
            </div>
        </div>

        <div className={`${styles.NotesRow}`}>
            {layoutList?.map((item, index) => {
                return (perPage === -1 || (index >= page * perPage && index < page * perPage + perPage)) ? (<div className={`${styles.NotesCard}`} style={{ cursor: 'pointer' }} onClick={(e) => { setShowEditLayoutForm(true); setEditLayoutData(item); }}>
                    <div className={`${styles.PropeCardInFR}`}>
                        <div className={`${styles.MainReqConDivBigTwo}`}>
                            <p className={`${styles.InsideConName}`}><span className={`${styles.ConNameTrancate}`}>{item?.title}</span></p>
                            <div className={`${styles.MainReqConUR}`}>
                                <p className={`${styles.MainReqConDe}`}>${item?.formatted_rent}</p>
                                <p className={`${styles.MainReqConDe}`}><span>{item?.size} sq ft</span></p>
                            </div>
                        </div>
                        <div className={`${styles.MainReqConDivSmallTwo}`}>
                            <p className={`${styles.MainReqConDe}`}><span>{item?.unit_type} - {item?.bathroom} bathroom</span></p>
                        </div>
                        <div className={`${styles.MainReqConActionTwo}`}>
                            {isAuthenticated && user["https://propertydek.com/permissions"].indexOf("property:update") > -1 && (<>
                                <Tooltip title="Copy">
                                    <Button className={`${styles.ProTrash}`} onClick={(e) => { e.preventDefault(); e.stopPropagation(); setUnitModalOpen(true); setSelectedId(item.id); }}><Copy /></Button>
                                </Tooltip>
                                <Tooltip title="Delete">
                                    <Button onClick={(e) => { e.preventDefault(); e.stopPropagation(); setDeleteModalOpen(true); setDeleteId(item.id); }} className={`${styles.ProTrash}`}>
                                        <Trash2 />
                                    </Button>
                                </Tooltip>
                            </>)}
                        </div>
                    </div>
                </div>) : null;
            })}

            {layoutList.length > 0 && (<div className="Pagination TablePaginationNew">
                <TablePagination
                    count={layoutList.length}
                    color="primary"
                    page={page}
                    rowsPerPage={perPage}
                    onPageChange={(e, v) => setPage(v)}
                    rowsPerPageOptions={[
                        { label: '5', value: 5 },
                        { label: '10', value: 10 },
                        { label: '25', value: 25 },
                        { label: 'All', value: -1 },
                    ]}
                    onRowsPerPageChange={(e) => {
                        setPage(0);
                        setPerPage(e.target.value);
                    }}
                    labelRowsPerPage={'Layouts per Page:'}
                />
            </div>)}

            {layoutList.length === 0 && (<div className={`${styles.NoDataMain}`}>
                <div className={`${styles.NoDataIMG}`}>
                    <img src="/static/img/no-property.png" alt="No Data" />
                </div>
                <p className={`${styles.NoDataText}`}>Oops!</p>
                <p className={`${styles.NoDataTextSub}`}>It looks empty here. Let's get started.</p>
            </div>)}
        </div>

        <Dialog
            open={deleteModalOpen}
            fullWidth
            maxWidth="sm"
            onClose={(e) => setDeleteModalOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp"
        >
            <DialogTitle id="alert-dialog-title">Would you like to delete?<button onClick={(e) => setDeleteModalOpen(false)}><X /></button></DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div className="ModalFormGroup">
                        <label className="PopupBodyText">The layout record will be removed immediately.You can't undo this action.</label>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <button onClick={(e) => deleteConfirm()} className="CancelPopupBU">Delete</button>
                <button onClick={(e) => setDeleteModalOpen(false)} className="SubmitPopupBU">Cancel</button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={showAddLayoutForm}
            onClose={(e) => setShowAddLayoutForm(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp MobileForm"
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle id="alert-dialog-title">
                <button className={`${styles.BackAddBU}`} onClick={(e) => setShowAddLayoutForm(false)}><ArrowLeft /></button>
                <span>Add Layout</span>
                <button onClick={(e) => setShowAddLayoutForm(false)}>
                    <X />
                </button>
            </DialogTitle>
            <DialogContent>
                <LayoutAdd setShowLayout={setShowAddLayoutForm.bind(this)} property_id={props.property_id} addLayoutRow={addLayoutRow.bind(this)} property_name={props?.property_name} editLayoutData={null} />
            </DialogContent>
        </Dialog>

        <Dialog
            open={showEditLayoutForm}
            onClose={(e) => setShowEditLayoutForm(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp MobileForm"
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle id="alert-dialog-title">
                <button className={`${styles.BackAddBU}`} onClick={(e) => setShowEditLayoutForm(false)}><ArrowLeft /></button>
                <span>Edit Layout</span>
                <button onClick={(e) => setShowEditLayoutForm(false)}>
                    <X />
                </button>
            </DialogTitle>
            <DialogContent>
                <LayoutAdd setShowLayout={setShowEditLayoutForm.bind(this)} property_id={props.property_id} addLayoutRow={editLayoutRow.bind(this)} property_name={props?.property_name} editLayoutData={editLayoutData} />
            </DialogContent>
        </Dialog>

        <Dialog
            open={unitModalOpen}
            fullWidth
            maxWidth="sm"
            onClose={(e) => setUnitModalOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp"
        >
            <DialogTitle id="alert-dialog-title">Copy Layout<button onClick={(e) => setUnitModalOpen(false)}><X /></button>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div className="ModalFormGroup">
                        <label className="PopupBodyText">Are you sure you want to copy this item?</label>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <button onClick={(e) => setUnitModalOpen(false)} className="CancelPopupBU">Cancel</button>
                <button onClick={(e) => copyUnitConfirm()} className="SubmitPopupBU">Copy</button>
            </DialogActions>
        </Dialog>

    </>)
}

export default LayoutTab;