import React, { useEffect, useState } from "react";
import styles from "../prospectdetails.module.css";
import { Edit2, MoreVertical, Plus, Search, Trash2, X } from "react-feather";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Loader from "../../../components/Loader";
import axios from "axios";
import { toast } from "react-smart-toaster";
import { Dropdown } from "react-bootstrap";
import { AddLogCallAPI } from "../../../components/AddLogs";

const schemaNote = yup.object().shape({
  note: yup.string().required("This field is Required").max(200, "Note cannot be more than 200 characters"),
});

const NotesTab = (props) => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [editId, setEditId] = useState(0);
  const [noteList, setNoteList] = useState([]);
  const [noteSearchKey, setNoteSearchKey] = useState("");

  const { register, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(schemaNote),
  });

  useEffect(() => {
    if (props?.prospectData?.notes) {
      let noteListTemp = props?.prospectData?.notes;

      if (noteSearchKey !== "") {
        noteListTemp = noteListTemp.filter((item) => item?.note.toLowerCase().includes(noteSearchKey.toLowerCase()));
      }

      setNoteList(noteListTemp);
    }
  }, [props?.prospectData?.notes, noteSearchKey]);

  const onSubmit = async (data) => {
    setIsAddFormOpen(false);
    setLoading(true);
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
    return axios.post(process.env.REACT_APP_APPLICANT_API_URL + "v1/applicants/" + props?.applicant_id + "/notes", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      setLoading(false);

      props?.setSnackbarMsg(response.data.message);
      props?.setSnackbarOpen(true);

      props.addNoteData(response.data.data);

      let logData = {
        'title': 'A new prospect note is added',
        'description': [
          'Name: ' + props?.prospectData?.all_applicants[0]?.full_name,
          'Email: ' + props?.prospectData?.all_applicants[0]?.email,
          'Phone: ' + props?.prospectData?.all_applicants[0]?.phone,
          'Note: ' + response.data.data?.note,
        ]
      }
      AddLogCallAPI(logData, token);
    }).catch((error) => {
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
      setLoading(false);
    });
  };

  const onUpdate = async (data) => {
    if (editId > 0) {
      setLoading(true);
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
      axios.put(process.env.REACT_APP_APPLICANT_API_URL + "v1/applicants/" + props?.applicant_id + "/notes/" + editId,
        data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        setLoading(false);

        props?.setSnackbarMsg(response.data.message);
        props?.setSnackbarOpen(true);

        let notes = response.data.data;
        props?.editNoteData(notes, editId);
        setEditId(0);

        let logData = {
          'title': 'A prospect note is updated',
          'description': [
            'Name: ' + props?.prospectData?.all_applicants[0]?.full_name,
            'Email: ' + props?.prospectData?.all_applicants[0]?.email,
            'Phone: ' + props?.prospectData?.all_applicants[0]?.phone,
            'Note: ' + response.data.data?.note,
          ]
        }
        AddLogCallAPI(logData, token);
      }).catch((error) => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
        setLoading(false);
      });
    }
  };

  const deleteConfirm = async () => {
    setOpenDeleteModal(false);
    setLoading(true);
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
    return axios.delete(process.env.REACT_APP_APPLICANT_API_URL + "v1/applicants/" + props?.applicant_id + "/notes/" + deleteId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      setLoading(false);

      props?.setSnackbarMsg(response.data.message);
      props?.setSnackbarOpen(true);

      props?.deleteNoteData(deleteId);
      setDeleteId(0);

      let logData = {
        'title': 'A prospect note is deleted',
        'description': [
          'Name: ' + props?.prospectData?.all_applicants[0]?.full_name,
          'Email: ' + props?.prospectData?.all_applicants[0]?.email,
          'Phone: ' + props?.prospectData?.all_applicants[0]?.phone
        ]
      }
      AddLogCallAPI(logData, token);
    }).catch((error) => {
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
      setLoading(false);
    });
  };

  const editNote = (item) => {
    setEditId(item?.id);
    setTimeout(() => {
      setValue("note", item?.note);
    }, 100);
  };

  return (<>
    {loading && <Loader />}

    {props?.prospectData?.notes?.length > 0 && (<div className={`${styles.NotesSearchArea}`}>
      <div className={`${styles.NotesSearchMain}`}>
        <input type="text" placeholder="Search" className={`${styles.searchInput}`} defaultValue={noteSearchKey} onChange={(e) => setNoteSearchKey(e.target.value)} />
        <Search />
      </div>
      {(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("prospect-note:create") > -1) && (<button className={`${styles.AddNoteBu}`} onClick={(e) => { setIsAddFormOpen(true); }} >
        <Plus />
      </button>)}
    </div>)}

    {isAddFormOpen && (<div className={`${styles.AddCommDiv}`}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={`${styles.formGroup}`}>
          <textarea type="text" className={`${styles.ReferCommentArea}`} name="note" ref={register} placeholder="Add text here..."></textarea>
          {errors.note && (<p className={`${styles.ErrorM}`}>{errors.note.message}</p>)}
        </div>
        <button className={`${styles.ButtonVoiletLine}`} onClick={(e) => { setIsAddFormOpen(false); }}>Cancel</button>
        <button type="submit" className={`${styles.ButtonVoiletSolid}`}>Add</button>
      </form>
    </div>)}

    <div className={`${styles.NotesRow}`}>
      {noteList?.map((itemNote, indexN) => {
        return (<div className={`${styles.NotesCard}`} key={indexN}>
          <div className={`${styles.NotesCardInside}`}>
            <img src={itemNote?.user_image === "" ? "/static/img/default_avataar.svg" : itemNote?.user_image} alt="" />
            <div className={`${styles.CardInsideCon}`}>
              <p className={`${styles.InsideConName}`}>
                {itemNote?.user_name}{" "}
                <span>
                  {itemNote?.role} @{itemNote?.property}
                </span>
              </p>
              {isAuthenticated && (user["https://propertydek.com/permissions"].indexOf("prospect-note:update") > -1 || user["https://propertydek.com/permissions"].indexOf("prospect-note:delete") > -1) && (<Dropdown className={`${styles.DropDownMain}`}>
                <Dropdown.Toggle id="dropdown-basic" className={`${styles.FilesUpCardDD} ${styles.NoPadd}`}>
                  <MoreVertical />
                </Dropdown.Toggle>
                <Dropdown.Menu className={`${styles.ActionsBUList} ActionsBUList`}>
                  {isAuthenticated && user["https://propertydek.com/permissions"].indexOf("prospect-note:update") > -1 && (<Dropdown.Item className={`${styles.EditActionsBUListItem}`} onClick={editNote.bind(this, itemNote)}>
                    <Edit2 /> Edit
                  </Dropdown.Item>)}
                  {isAuthenticated && user["https://propertydek.com/permissions"].indexOf("prospect-note:delete") > -1 && (<Dropdown.Item className={`${styles.EditActionsBUListItem} ${styles.Delete}`} onClick={(e) => {
                    setOpenDeleteModal(true);
                    setDeleteId(itemNote?.id);
                  }}>
                    <Trash2 /> Delete
                  </Dropdown.Item>)}
                </Dropdown.Menu>
              </Dropdown>)}
              <p className={`${styles.InsideConDate}`}>{itemNote?.time_str}</p>
              <div className={`${styles.InsideConText}`}>
                {editId > 0 && editId === itemNote.id && (<div className={`${styles.AddCommDiv}`}>
                  <form id="myForm" onSubmit={handleSubmit(onUpdate)}>
                    <div className={`${styles.formGroup}`}>
                      <textarea type="text" className={`${styles.ReferCommentArea}`} name="note" ref={register} placeholder="Add text here..."></textarea>
                      {errors.note && (<p className={`${styles.ErrorM}`}>{errors.note.message}</p>)}
                    </div>
                    <Button className={`${styles.ButtonVoiletLine}`} onClick={(e) => { setEditId(0); }}>Cancel</Button>
                    <Button type="submit" className={`${styles.ButtonVoiletSolid}`}>Update</Button>
                  </form>
                </div>)}
                {(editId === 0 || editId !== itemNote.id) && itemNote?.note}
              </div>
            </div>
          </div>
        </div>);
      })}
    </div>

    {!isAddFormOpen && noteList?.length === 0 && (<div className={`${styles.NotesRow}`}>
      <div className={`${styles.NoDataMain}`}>
        <div>
          <img src="/static/img/no-content.png" alt="" className={`${styles.NoDataIMG}`} />
          <p className={`${styles.NoDataText}`}>No Notes Found</p>
          {(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("prospect-note:create") > -1) && <button className={`${styles.AddLeaseBU}`} onClick={(e) => { setIsAddFormOpen(true); }}>
            <Plus /> Add New
          </button>}
        </div>
      </div>
    </div>)}

    <Dialog
      open={openDeleteModal}
      fullWidth
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp"
      onClose={(e) => {
        setOpenDeleteModal(false);
        setDeleteId(0);
      }}
    >
      <DialogTitle id="alert-dialog-title">
        Delete Note{" "}
        <button
          onClick={(e) => {
            setOpenDeleteModal(false);
            setDeleteId(0);
          }}
        >
          <X />
        </button>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" className="ModalFormGroup" style={{ marginBottom: "0px", padding: "25px 0" }}>
          <label className="PopupBodyText">Are you sure you want to delete this item?</label>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className="CancelPopupBU" onClick={(e) => { deleteConfirm(); }}>Delete</Button>
        <Button className="SubmitPopupBU" onClick={(e) => { setOpenDeleteModal(false); setDeleteId(0); }}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  </>);
};

export default NotesTab;
