import React from "react";
import { withRouter } from 'react-router-dom';
import styles from './footerpublic.module.css';
import { Link, scroller } from 'react-scroll'
import { Link as Linkto } from "react-router-dom";
import 'react-sticky-header/styles.css';
import moment from 'moment';

class FooterPublic extends React.Component {

  componentDidMount() {
    if (this.props.location.hash !== '') {
      this.scrollTo(this.props.location.hash);
    }
  }

  scrollTo(id) {
    scroller.scrollTo(id, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    });
  }

  render() {
    return (<div className={`${styles.footer}`}>
      <div className={`${styles.Container}`}>
        <div className={`${styles.FooterRow}`}>
          <div className={`${styles.FooterLogoSec}`}>
            <img src="/static/img/logo.svg" alt="logo" />
          </div>
          <div className={`${styles.FooterConSec}`}>
            <div className={`${styles.FooterConSecSmall}`}>
              <p className={`${styles.FootListTitle}`}>Features</p>
              <Link className={`${styles.FootListLink}`} to="#blogs">Blog</Link>
              <Linkto className={`${styles.FootListLink}`} to={'contact-us'}>Contact</Linkto>
            </div>
            <div className={`${styles.FooterConSecSmall}`}>
              <p className={`${styles.FootListTitle}`}>Legal</p>
              <Linkto to={'/privacy-policy'} className={`${styles.FootListLink}`}>Privacy Policy</Linkto>
              <Linkto to={'/eula'} className={`${styles.FootListLink}`}>End User License Agreement</Linkto>
            </div>
            <div className={`${styles.FooterConSecBig}`}>
              <div>
                <a className={`${styles.FootListLinkBig}`} href="tel:17188254746">1-718-825-4746</a>
                <a className={`${styles.FootListLinkBig}`} href="mailto:hello@propertydek.com">hello@propertydek.com</a>
                <div className={`${styles.AppstoreSec}`}>
                  <a href="https://play.google.com/store/apps/details?id=com.propertydek.ionicapp" target="_blank" rel="noreferrer"><img src="/static/img/play-store.png" alt="PropertyDek-play-store" /></a>
                  <a href="https://apps.apple.com/app/PropertyDek/id1661119081" target="_blank" rel="noreferrer"><img src="/static/img/app-store.png" alt="PropertyDek-app-store" /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.LastFootRow}`}>
          <p className={`${styles.CopyRightText}`}>© {moment().format("YYYY")}. Propertydek.com. All rights reserved.</p>
          {/* <div className={`${styles.SocialIcon}`}>
            <a href="https://www.linkedin.com/company/fbrothers"><img src="/static/img/linkedin.png" alt="logo" /></a>
            <a href="https://twitter.com/tech4SMBs"><img src="/static/img/twitter.png" alt="logo" /></a>
            <a href="https://www.facebook.com/tech4smbs/"><img src="/static/img/facebook.png" alt="logo" /></a>
          </div> */}
        </div>
      </div>
    </div>
    );
  }
}

export default withRouter(FooterPublic);