import styles from "../prospectdetails.module.css";
import axios from "axios";
import { toast } from "react-smart-toaster";
import { useAuth0 } from "@auth0/auth0-react";

const UploaderComponent = ({ allowedExtensions, isMuliple, uploadUrl, setLoading, onUploadLocal, buttonText, helpText }) => {
    const { getAccessTokenSilently } = useAuth0();


    const fileUploadButtonClick = (e) => {
        document.getElementById("fileUploadIDNew").getElementsByClassName("inputBtn")[0].click();
        return false;
    }

    const fileHandler = async (e) => {
        let extErr = false;
        let sizeErr = false;
        let files = e.target.files;
        let tempFiles = Array.from(files);
        let allowedExtensionArr = allowedExtensions.split(',');

        if (files.length > 0) {
            if (allowedExtensionArr.length > 0) {
                tempFiles.map((item) => {
                    let fileName = item.name;
                    let fileSize = item.size;
                    let fileNameExt = fileName.substr(fileName.lastIndexOf(".") + 1);
                    fileNameExt = '.' + fileNameExt;

                    if (allowedExtensionArr.indexOf(fileNameExt) === -1) {
                        extErr = true;
                    }

                    if (fileSize >= 5242880) {
                        sizeErr = true;
                    }

                    return false;
                })
            }
        }

        if (extErr) {
            toast.error("File type is not allowed.");
            return false;
        }

        if (sizeErr) {
            toast.error("File size should not be greater than 5 MB.");
            return false;
        }

        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        tempFiles.map((item, index) => {
            setLoading(true);

            let formData = new FormData();
            formData.append("fileUpload", files[index]);

            axios.post(uploadUrl, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then(response => {
                setLoading(false);
                onUploadLocal(response.data.data);
            }).catch(error => {
                setLoading(false);
            });

            return false;
        });
    }

    return <div className={`${styles.FileUpDuBUDiv} fileUploadID`} id="fileUploadIDNew">
        <label for="file-upload" className={`${styles.UploadLabel}  ${styles.PersonalInfo}`}>
            <input type="file" className="inputBtn" onChange={fileHandler} multiple={isMuliple} accept={allowedExtensions} />
            <span onClick={(e) => fileUploadButtonClick(e)}>
                <div className={`${styles.UpIcon}`}>
                    <img src="/static/img/upload-floor-RA.png" alt="" />
                </div>
                <p>Upload Files</p>
                <p className={`${styles.UploadText}`}>Maximum file size 5MB. Only PDF, png, jpeg files are supported.</p>
            </span>

        </label>
    </div>;
}

export default UploaderComponent;