import React from "react";
import { Link } from "react-router-dom";
import styles from './notifications.module.css';
import styled from "styled-components/macro";
import axios from 'axios';
import Loader from "../../components/Loader";
import { Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, FormControlLabel, Checkbox } from "@material-ui/core";
import { Home, ArrowRight, Trash2, X } from "react-feather";
import Helmet from "react-helmet";
import Footer from "../../components/Footer";
import { Search, Filter } from 'react-feather';
import { TableLoader } from "../../components/LoaderC";
import { toast } from "react-smart-toaster";
import Select from "react-select";
import { withAuthenticationRequired, withAuth0 } from "@auth0/auth0-react";
import { Pagination as MuiPagination } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { AddLogCallAPI } from "../../components/AddLogs";


const Pagination = styled(MuiPagination)(spacing);
const iconList =
{
  'complaint': '/static/img/NotiIcon3.png',
  'request': '/static/img/NotiIcon2.png',
  'application': '/static/img/NotiIcon4.png',
  'device-on': '/static/img/NotiIcon6.png',
  'device-off': '/static/img/NotiIcon5.png',
}
const urlList =
{
  'complaint': '/cases/complaints/details/',
  'request': '/cases/maintenance-request/details/',
  'application': '/people/applicants/details/',
  'device-on': '/static/img/NotiIcon6.png',
  'device-off': '/static/img/NotiIcon5.png',
}
class Notifications extends React.Component {
  constructor({ match }) {
    super(...arguments);
    this.perPage = 8;
    this.state = { allData: [], data: [], pageCount: 0, page: 1, check: false, modalOpen: false, selectedItem: '', loading: false, contentLoading: true, filterPropertyList: [], filterApartmentList: [], filterTypeList: [{ value: '', label: 'Any' }, { value: 'New Application Received', label: 'New Application Received' }, { value: 'Maintenance Request Recieved', label: 'Maintenance Request Recieved' }, { value: 'Complaint Received', label: 'Complaint Received' }, { value: 'Smart Device Online', label: 'Smart Device Online' }, { value: 'Smart Device Offline', label: 'Smart Device Offline' }], filterViewList: [{ value: '', label: 'All' }, { value: 'Active', label: 'Active' }, { value: 'Archived', label: 'Archived' }], filterData: { searchKey: '', type: '', property: '', apartment: '' }, filterShowValue: { Type: '', Property: '', Apartment: '' }, defaultValueView: { value: 'Active', label: 'Active' }, defaultValueProperty: { value: '', label: 'Any' }, defaultValueapartment: null, defaultValueType: { value: '', label: 'Any' }, isFilter: false, notification: '', notificationCount: 0, notificationDeleteModal: false, notificationDelID: '', isReadcheck: false, notificationReaddArr: [], isCheckedAllNew: false };
    this.audience = process.env.REACT_APP_NOTIFICATION_API_URL;
    const timezone_offset_minutesdata = new Date().getTimezoneOffset();
    this.timezone_offset_minutes = timezone_offset_minutesdata === 0 ? 0 : -timezone_offset_minutesdata;
  }
  async componentDidMount() {
    const { getAccessTokenSilently } = this.props.auth0;
    this.token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
    this.stateOptions = [];
    this.cityOptions = [];
    try {
      this.setState({ ...this.state, contentLoading: true });
      await axios(this.audience + 'v1/notifications?timezone_offset=' + this.timezone_offset_minutes, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        }
      }).then(response => {
        let allData = response.data.data;
        let pageCount = (allData.length / this.perPage);
        if (pageCount > parseInt(pageCount))
          pageCount = (parseInt(pageCount) + 1);

        this.setState({ ...this.state, allData: allData, data: allData, pageCount: pageCount, contentLoading: false }, this.filterHandler);
      });
      axios(
        this.audience + 'v1/properties', {
        headers: {
          Authorization: `Bearer ${this.token}`,
        }
      }).then(response => {
        let propertyData = response.data.data;

        propertyData = [{ value: '', label: 'Any', apartments: [] }].concat(propertyData);
        this.setState({ ...this.state, filterPropertyList: propertyData });
      });
    } catch (error) {
      this.setState({ ...this.state, contentLoading: false });
    }
  }

  onChangeHandler(e) {
    let filterData = this.state.filterData;
    filterData = { ...filterData, searchKey: e.target.value };
    this.setState({ ...this.state, filterData: filterData }, this.filterHandler);
  }

  async changeStatus(data, e) {
    e.stopPropagation();
    let is_archived = (1 - data.is_archived);
    this.setState({ ...this.state, loading: true });
    axios.put(this.audience + 'v1/properties/' + data.id, { 'is_archived': is_archived }, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      }
    }).then(response => {
      let tempData = this.state.allData.map((item) => {
        if (item.id === data.id) {
          return { ...data, is_archived: is_archived }
        }
        return item;
      });
      toast.success(response.data.message);
      this.setState({ ...this.state, allData: tempData, loading: false }, this.filterHandler);

      let respData = response.data.data;
      let logData = {
        'title': (respData.is_archived ? 'Property is archived' : 'Property is not archived'),
        'description': [
          'Name: ' + respData.name,
          'Address: ' + respData.fulladdress
        ]
      }
      AddLogCallAPI(logData, this.token);
    }).catch(error => {
      if (typeof error.response !== 'undefined')
        toast.error(error.response.data.message);
      this.setState({ ...this.state, loading: false });
    });
  }

  handleChangeFilter = (stype, selectedOption) => {
    let filterData = this.state.filterData;
    let filterShowValue = this.state.filterShowValue;
    let defaultValueProperty = this.state.defaultValueProperty;
    let filterApartmentList = this.state.filterApartmentList;
    let defaultValueApartment = this.state.defaultValueApartment;
    let filterTypeList = this.state.filterTypeList;
    let defaultValueType = this.state.defaultValueType;
    let isFilter = false;
    if (selectedOption.value !== '') {
      isFilter = true;
    }
    if (stype === 'type') {
      filterData = { ...filterData, type: selectedOption.value };
      filterShowValue = { ...filterShowValue, Type: selectedOption.label };
      defaultValueType = { label: selectedOption.label, value: selectedOption.value };
    }
    if (stype === 'property') {
      filterData = { ...filterData, property: selectedOption.value };
      filterShowValue = { ...filterShowValue, Property: selectedOption.label };
      defaultValueProperty = { label: selectedOption.label, value: selectedOption.value };
      if (selectedOption.value !== '') {
        let property_id = selectedOption.value;
        let applist = this.state.filterPropertyList.filter(i => i.id === property_id);
        filterApartmentList = applist[0].apartments.filter(i => i.property_id === property_id);
        filterApartmentList = [{ value: '', label: 'All' }].concat(filterApartmentList);

        this.setState({ ...this.state, filterApartmentList: filterApartmentList, filterData: filterData, filterShowValue: filterShowValue });
      }
      if (selectedOption.value !== '') {
        filterApartmentList = [{ value: '', label: 'All' }].concat(selectedOption.apartments);
        defaultValueApartment = null;
        filterData = { ...filterData, apartment: '' };
        filterShowValue = { ...filterShowValue, Apartment: '' };
      } else {
        filterApartmentList = [];
        defaultValueApartment = null;
        filterData = { ...filterData, apartment: '' };
        filterShowValue = { ...filterShowValue, Apartment: '' };
      }
    }
    if (stype === 'apartment') {
      filterData = { ...filterData, apartment: selectedOption.value };
      filterShowValue = { ...filterShowValue, Apartment: selectedOption.label };
      defaultValueApartment = selectedOption;
    }
    if (stype === 'property' && selectedOption.value === '') {
      filterApartmentList = [];
      document.getElementsByClassName(" css-1uccc91-singleValue").innerHTML = 'Any';
    }
    this.setState({ ...this.state, filterData: filterData, defaultValueProperty: defaultValueProperty, isFilter: isFilter, filterShowValue: filterShowValue, defaultValueApartment: defaultValueApartment, filterApartmentList: filterApartmentList, defaultValueType: defaultValueType, filterTypeList: filterTypeList }, this.filterHandler);
  };
  deleteFilter(stype) {
    let filterData = this.state.filterData;
    let filterShowValue = this.state.filterShowValue;
    let defaultValueType = this.state.defaultValueType;
    let defaultValueProperty = this.state.defaultValueProperty;
    let defaultValueApartment = this.state.defaultValueApartment;
    let filterApartmentList = this.state.filterApartmentList;
    let isFilter = this.state.isFilter;
    if (stype === 'Type') {
      filterData = { ...filterData, type: '' };
      filterShowValue = { ...filterShowValue, Type: '' };
      defaultValueType = { value: '', label: 'Any' };
    }
    if (stype === 'Property') {
      filterData = { ...filterData, property: '', apartment: '' };
      filterShowValue = { ...filterShowValue, Property: '', Apartment: '' };
      defaultValueProperty = { value: '', label: 'Any' };
      defaultValueApartment = null;
      filterApartmentList = [];
    }
    if (stype === 'Apartment') {
      filterData = { ...filterData, apartment: '' };
      defaultValueApartment = { value: '', label: 'Any' };
      filterShowValue = { ...filterShowValue, Apartment: '' };
    }
    if (filterShowValue.Type === '' && filterShowValue.Property === '' && filterShowValue.Apartment === '') {
      isFilter = false;
    }
    this.setState({ ...this.state, filterData: filterData, filterShowValue: filterShowValue, defaultValueType: defaultValueType, defaultValueProperty: defaultValueProperty, isFilter: isFilter, defaultValueApartment: defaultValueApartment, filterApartmentList: filterApartmentList }, this.filterHandler);
  }
  deleteNotification(notificationDelID, event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ ...this.state, notificationDeleteModal: true, notificationDelID: notificationDelID });
  }
  delNotificationClose() {
    this.setState({ ...this.state, notificationDeleteModal: false, notificationDelID: '' });
  }
  delNotification() {
    this.setState({ ...this.state, notificationDeleteModal: false, loading: true });
    let allNotification = this.state.data.filter(item => item.id !== this.state.notificationDelID);
    this.setState({ ...this.state, data: allNotification, notificationDelID: '', loading: false });
    return axios.delete(this.audience + 'v1/notifications/' + this.state.notificationDelID, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      }
    }).then(response => {
      toast.success(response.data.message);
      let allNotification = this.state.data.filter(item => item.id !== this.state.notificationDelID);
      this.setState({ ...this.state, data: allNotification, notificationDelID: '', loading: false, notificationDeleteModal: false });
    }).catch(error => {
      if (typeof error.response !== 'undefined')
        toast.error(error.response.data.message);

    });
  }
  paginationChange = (event, value) => {
    this.setState({ ...this.state, page: value });
  };
  filterHandler = () => {
    const { filterData } = this.state;
    let filteredData = this.state.allData;
    let isFilter = this.state.isFilter;
    if (filterData.searchKey !== '') {
      let inputVal = filterData.searchKey;
      filteredData = filteredData.filter((item) => {
        return item.title.toLowerCase().includes(inputVal) || item.property_name.toLowerCase().includes(inputVal) || item.apt_name.toLowerCase().includes(inputVal) || item.foreign_title.toLowerCase().includes(inputVal) || item.time_str.toLowerCase().includes(inputVal) || item.time_str2.toLowerCase().includes(inputVal);
      });
    }
    if (filterData.type !== '') {
      filteredData = filteredData.filter(i => i.title === filterData.type);
    }
    if (filterData.property !== '') {
      filteredData = filteredData.filter(i => i.property_id === filterData.property);
    }
    if (filterData.apartment !== '' && filterData.property !== '') {
      filteredData = filteredData.filter(i => i.apt_id === filterData.apartment);
    }
    let pageCount = (filteredData.length / this.perPage);
    if (pageCount > parseInt(pageCount))
      pageCount = (parseInt(pageCount) + 1);
    let cPage = this.state.page;
    if (cPage > pageCount)
      cPage = pageCount;
    if (cPage === 0)
      cPage = 1;
    this.setState({ ...this.state, data: filteredData, isFilter: isFilter, pageCount: pageCount, page: cPage });
  }
  goDetails(item, event) {
    if (event.target.tagName !== 'INPUT') {
      let postData = {};
      postData = { ids: [item.id] };
      return axios.put(this.audience + 'v1/notifications/read-as-by-ids', postData, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        }
      }).then(response => {
        this.props.history.push(urlList[item.type] + item.foreign_id);
      }).catch(error => {
      });
    }
  }
  markAllCheckRead(event) {
    let checkArr = [];
    if (event.target.checked) {
      let newData = this.state.data.map((item) => {
        checkArr.push(item.id);
        return { ...item, is_checked: 1 };
      });
      this.setState({ ...this.state, isCheckedAllNew: true, data: newData, notificationReaddArr: checkArr });
    } else {
      let newData = this.state.data.map((item) => {
        return { ...item, is_checked: 0 };
      });
      this.setState({ ...this.state, isCheckedAllNew: false, data: newData, notificationReaddArr: checkArr });
    }
  }
  markCheckRead(item, event) {

    let checkArr = [];
    let newData = [];
    if (event.target.checked) {
      newData = this.state.data.map((i) => {
        if (i === item) {
          checkArr.push(i.id);
          return { ...i, is_checked: 1 };
        } else if (i.is_checked === 1) {
          checkArr.push(i.id);
        }
        return i;
      });
    } else {
      newData = this.state.data.map((i) => {
        if (i === item) {
          return { ...i, is_checked: 0 };
        } else if (i.is_checked === 1) {
          checkArr.push(i.id);
        }
        return i;
      });
    }
    let isCheckedAll = newData.every(i => i.is_checked === 1);
    this.setState({ ...this.state, data: newData, isCheckedAllNew: isCheckedAll, notificationReaddArr: checkArr });
  }
  markReadAll() {
    this.setState({ ...this.state, loading: true });
    let postData = {};
    if (this.state.data.length !== 0) {
      return axios.put(this.audience + 'v1/notifications/read-as-all', postData, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        }
      }).then(response => {
        toast.success(response.data.message);
        let tempData = this.state.data.map(el => (
          { ...el, is_read: 1 }
        ))
        this.setState({ ...this.state, data: tempData, loading: false });
      }).catch(error => {
      });
    }
  }
  readSubmit() {
    this.setState({ ...this.state, loading: true });
    let checkArr = this.state.notificationReaddArr;
    let postData = {};
    if (checkArr.length !== 0) {
      postData = { ids: this.state.notificationReaddArr };

      return axios.put(this.audience + 'v1/notifications/read-as-by-ids', postData, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        }
      }).then(response => {
        toast.success(response.data.message);
        let tempData = this.state.data.map(el => (
          checkArr.indexOf(el.id) > -1 ? { ...el, is_read: 1, is_checked: 0 } : el
        ))
        this.setState({ ...this.state, data: tempData, notificationCount: response.data.data, notificationReaddArr: [], loading: false });
      });
    }
  }
  render() {
    let activebtnbgcolor = '#EDEDED';
    let activebtncolor = '#ADADAD';
    if (this.state.notificationReaddArr.length !== 0) {
      activebtnbgcolor = '#230058';
      activebtncolor = '#fff';

    }
    return (<React.Fragment>
      {this.state.loading && <Loader />}
      <Helmet title="Notifications" />
      <div className={`${styles.pageTitleWrap}`}  >
        <Typography display="inline" className={`${styles.pageTitle}`} >Notifications</Typography>
        <nav aria-label="breadcrumb">
          <ol className={`${styles.breadcrumb}`}>
            <li className={`${styles.breadcrumbItem}`}><span><Home /></span>Notifications </li>
          </ol>
        </nav>
      </div>
      <div className={`${styles.BodyCon}`}  >
        <div className={`${styles.mainCardDiv}`}>
          <div className={`${styles.mainCard}`}>
            {this.state.contentLoading && <div className="Loader TableLoader"><TableLoader /></div>}
            <div className={`${styles.Row}`}>
              <div className={`${styles.NotesSearchArea}`}>
                <div className={`${styles.NotesSearchMain}`}>
                  <input type="text" placeholder="Search" className={`${styles.searchInput}`} onChange={this.onChangeHandler.bind(this)} />
                  <Search />
                </div>
                <button className={`${styles.FilterBu}`}><Filter /></button>
              </div>
              {this.state.isFilter && <div className={`${styles.FilterShowingDiv}`}>
                <p className={`${styles.TitleFilterPill}`}>Filter By:</p>
                {Object.entries(this.state.filterShowValue).map(([key, val], i) => (
                  (val !== '') && (val !== 'Any') && <span className={`${styles.FilterPill}`} key={i}>
                    <span>{key}: {val}</span>
                    {<Link value={key} onClick={this.deleteFilter.bind(this, key)}><X /></Link>}
                  </span>
                ))}
              </div>}
              <div className={`${styles.CheckSubArea}`}>
                <FormControlLabel control={<Checkbox />} label="" checked={this.state.isCheckedAllNew} onChange={this.markAllCheckRead.bind(this)} />
                <Button className={`${styles.RaedBUSub} `} style={{
                  backgroundColor: activebtnbgcolor, color: activebtncolor
                }} disabled={(this.state.notificationReaddArr.length === 0)} onClick={this.readSubmit.bind(this)}>Mark as read</Button>


              </div>
              <div className={`${styles.NotesRow}`}>
                {this.state.data.map((item, index) => {
                  let bgColors = '#ffffff';
                  if (item.is_read === 0) {
                    bgColors = '#efefef';
                  }


                  return (index >= ((this.state.page - 1) * this.perPage) && index < (((this.state.page - 1) * this.perPage) + this.perPage)) ? <div key={index} className={`${styles.NotesCard}`} style={{ backgroundColor: bgColors }}>
                    <div className={`${styles.PropeCardInFR}`} onClick={this.goDetails.bind(this, item)}>
                      <div className={`${styles.MainReqConDivBig}`}>
                        <FormControlLabel control={<Checkbox />} className={`${styles.NotiCheck}`} onChange={this.markCheckRead.bind(this, item)} checked={item.is_checked} />
                        <img src={iconList[item.type]} alt="Icon" />
                        <p className={`${styles.InsideConName}`}>
                          <span className={`${styles.ConNameTrancate}`}>{item.title}</span>
                        </p>
                      </div>
                      <div className={`${styles.MainReqConDivSmall}`}>
                        <div className={`${styles.MainReqConUR}`}>
                          <p className={`${styles.MainReqConDe}`}>
                            <span className={`${styles.TextEclips}`} title={item.foreign_title}>{item.foreign_title}</span></p>
                          <p className={`${styles.MainReqConDe}`}>
                            <span className={`${styles.TextEclips}`} title={item.property_name}>{item.property_name}</span> - {item.apt_name}</p>
                        </div>
                      </div>
                      <div className={`${styles.MainReqConAction}`}>
                        <div className={`${styles.MainReqConUR}`}>
                          <div className={`${styles.DateDiv}`}>
                            <p className={`${styles.DateTB}`}>{item.date_str}</p>
                            <p className={`${styles.DateTS}`}>{item.time_str}, {item.time_str2}</p>
                          </div>
                          <Link value={index} onClick={this.deleteNotification.bind(this, item.id)} ><Trash2 /></Link>
                        </div>
                      </div>
                    </div>
                  </div> : null;
                })}
                {this.state.pageCount > 0 && <div className="Pagination">
                  <Pagination count={this.state.pageCount} color="primary" page={this.state.page} onChange={this.paginationChange} />
                  <div className="PagiCount">{this.state.page} of {this.state.pageCount} pages ({(this.state.data.length > 1) ? this.state.data.length + ' items' : this.state.data.length + ' item'})</div>
                </div>}
                {(!this.state.contentLoading) && this.state.data.length === 0 && <div className={`${styles.NoDataMain}`}>
                  <div className={`${styles.NoDataIMG}`}>
                    <img src="/static/img/nodata.svg" alt="No Data" />
                  </div>
                  <p className={`${styles.NoDataText}`}>No Data Found</p></div>}
                <Dialog
                  open={this.state.notificationDeleteModal}
                  fullWidth
                  maxWidth="sm"
                  onClose={this.delNotificationClose.bind(this)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">Delete Notification<button onClick={this.delNotificationClose.bind(this)}><X /></button></DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      <div className={`${styles.ModalFormGroup}`}>
                        <label className={`${styles.FilterLabel}`} >Are you sure you want to delete this item?</label>
                      </div>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.delNotificationClose.bind(this)} className={`${styles.ModalBUCan}`}>
                      Cancel
                    </Button>
                    <Button onClick={this.delNotification.bind(this)} className={`${styles.ModalBUSub}`}>
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>


              </div>
            </div>
          </div>
          <div className={((this.state.Open) ? `FilterFoot Open` : `FilterFoot`)} >
            <Footer />
          </div>
        </div>
        <div className={`${styles.BodyFilterArea} `} id="filterAreaID" >
          <p className={`${styles.FilterTitle}`}>Filters <span><ArrowRight /></span></p>
          <div className={`${styles.FilterList}`}>
            <p className={`${styles.FilterLabel}`}>Type</p>
            <Select id="selApartment" onChange={this.handleChangeFilter.bind(this, 'type')} value={this.state.defaultValueType} options={this.state.filterTypeList} />
          </div>
          <div className={`${styles.FilterList}`}>
            <p className={`${styles.FilterLabel}`}>Property</p>
            <Select onChange={this.handleChangeFilter.bind(this, 'property')} value={this.state.defaultValueProperty} options={this.state.filterPropertyList} />
          </div>
          <div className={`${styles.FilterList}`}>
            <p className={`${styles.FilterLabel}`}>Apartment</p>
            <Select placeholder="Any" id="selApartment" onChange={this.handleChangeFilter.bind(this, 'apartment')} value={this.state.defaultValueApartment} options={this.state.filterApartmentList} />
          </div>
          <Button className={`${styles.RaedBU}`} onClick={this.markReadAll.bind(this)}>Mark as read all</Button>
        </div>
      </div>
    </React.Fragment >);
  }

}

export default withAuthenticationRequired(withAuth0(Notifications), {
  onRedirecting: () => <Loader />,
});