import { Download } from "react-feather";
import styles from "../leasedetails.module.css";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import axios from "axios";
import { toast } from "react-smart-toaster";
import { useAuth0 } from "@auth0/auth0-react";
import fileDownload from 'js-file-download';

const LeaseDetailsCard = ({ data, setLoading, setSnackbarOpen, setSnackbarMsg }) => {
    const { getAccessTokenSilently } = useAuth0();

    const downloadFile = async (id) => {
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        setLoading(true);
        await axios.get(process.env.REACT_APP_PAYMENT_API_URL + 'v1/leases-download-new/' + id, {
            responseType: 'blob',
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            setLoading(false);
            setSnackbarMsg('Lease agreement downloaded successfull');
            setSnackbarOpen(true);

            fileDownload(response.data, 'lease_agreement.pdf');
        }).catch(error => {
            setLoading(false);
            if (typeof error.response !== 'undefined')
                toast.error(error.response.data.message);
        });
    }

    return (<div className={`${styles.BodyPadd}`}>
        <ul className={`${styles.CaseSummSideUL}`}>
            <li>
                <p className={`${styles.ULtitle}`}>Tenant</p>
                <p className={`${styles.ULData}`}>
                    <Link to={"/people/tenants/details/" + data?.tenant_id}>{data?.tenant_name}</Link>
                </p>
            </li>
            <li>
                <p className={`${styles.ULtitle}`}>Property</p>
                <p className={`${styles.ULData}`}>{data?.property_name}</p>
            </li>
            <li>
                <p className={`${styles.ULtitle}`}>Apartment</p>
                <p className={`${styles.ULData}`}>{data?.apt_no}</p>
            </li>
            <li>
                <p className={`${styles.ULtitle}`}>Rent Amount</p>
                <p className={`${styles.ULData}`}>${data?.formatted_rent}</p>
            </li>
            <li>
                <p className={`${styles.ULtitle}`}>Security Diposit Amount</p>
                <p className={`${styles.ULData}`}>${data?.formatted_amount}</p>
            </li>
            <li>
                <p className={`${styles.ULtitle}`}>Start Date</p>
                <p className={`${styles.ULData}`}>{dayjs(data?.start_date).format('MM/DD/YYYY')}</p>
            </li>
            <li>
                <p className={`${styles.ULtitle}`}>End Date</p>
                <p className={`${styles.ULData}`}>{dayjs(data?.end_date).format('MM/DD/YYYY')}</p>
            </li>
            {data?.archived_leases.map((item) => {
                return <li>
                    <div className={`${styles.LeaseCardSec}`}>
                        <div className={`${styles.LeaseCard}`}>
                            <img src="/static/img/lease-iconW.svg" className={`${styles.LeaseIcon}`} alt="" />
                            <div>
                                <p className={`${styles.LeaseTitle}`}>Lease</p>
                                <p className={`${styles.LeaseTitleSmall}`}>{dayjs(item?.start_date).format('MM/DD/YYYY')} - {dayjs(item?.end_date).format('MM/DD/YYYY')}</p>
                            </div>
                            <button className={`${styles.LeaseDownloadBU}`} onClick={(e) => downloadFile(item?.id)}><Download /></button>
                        </div>
                    </div>
                </li>
            })}
        </ul>
    </div>)
}

export default LeaseDetailsCard;