import { useEffect, useState } from "react";
import { X } from "react-feather";
import styles from "../tour.module.css";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AddTourTileSlot from "../../prospect/Components/AddTourTileSlot";
import dayjs from "dayjs";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { toast } from "react-smart-toaster";
import Loader from "../../../components/Loader";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const OpenHouseFrom = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [selectedTourTime, setSelectedTourTime] = useState("");
  const [selectedTourDate, setSelectedTourDate] = useState(dayjs());
  const [tourTimeSlotError, setTourTimeSlotError] = useState("");
  const [selectProperty, setSelectProperty] = useState("");
  const [propertyError, setPropertyError] = useState("");
  const [editId, setEditId] = useState(0);

  useEffect(() => {
    if (props.is_edit) {
      let date_time = props.selectedEvent?.extendedProps?.date_time;
      let dateArr = date_time.split(" ");
      setSelectedTourDate(dayjs(dateArr[0]));
      setSelectedTourTime(dateArr[1]);
      setSelectProperty(props.selectedEvent?.extendedProps?.property_id);
      setEditId(props.selectedEvent?.extendedProps?.open_house_id);
    }
  }, [props.is_edit, props.selectedEvent]);

  useEffect(() => {
    if (props.selectedProperty > 0) {
      setSelectProperty(props.selectedProperty);
    }
  }, [props.selectedProperty]);

  useEffect(() => {
    if (selectedTourTime !== "") {
      setTourTimeSlotError("");
    }
  }, [selectedTourTime]);

  useEffect(() => {
    if (selectProperty !== "") {
      setPropertyError("");
    }
  }, [selectProperty]);

  const onSubmit = async () => {
    if (selectProperty === "") {
      setPropertyError("Please select property.");
      return false;
    }

    if (selectedTourTime === "") {
      setTourTimeSlotError("Please select time slot.");
      return false;
    }

    let postData = {
      property_id: selectProperty,
      date_time:
        dayjs(selectedTourDate).format("YYYY-MM-DD") + " " + selectedTourTime,
    };

    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });

    setLoading(true);
    axios
      .post(
        process.env.REACT_APP_APPLICANT_API_URL + "v1/open-houses",
        postData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        props.setOpenHouseFormOpen(false);
        setLoading(false);
        props.setSnackbarMsg(response.data.message);
        props.setSnackbarOpen(true);

        props.onSubmit(response.data.data);
      })
      .catch((error) => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
        setLoading(false);
      });
  };

  const onUpdate = async () => {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    let postData = {
      property_id: selectProperty,
      date_time:
        dayjs(selectedTourDate).format("YYYY-MM-DD") + " " + selectedTourTime,
    };

    setLoading(true);
    axios
      .put(
        process.env.REACT_APP_APPLICANT_API_URL + "v1/open-houses/" + editId,
        postData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        props.setOpenHouseFormOpen(false);
        setLoading(false);
        props.setSnackbarMsg(response.data.message);
        props.setSnackbarOpen(true);

        props.onSubmit(response.data.data);
      })
      .catch((error) => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
        setLoading(false);
      });
  };

  return (
    <>
      {loading && <Loader />}
      <DialogTitle id="alert-dialog-title">
        {props?.is_edit ? "Edit" : "Add"} Open House
        <button onClick={(e) => props.setOpenHouseFormOpen(false)}>
          <X />
        </button>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          <div className={`${styles.Width100}`}>
            <div className={`${styles.PopupFilterRow}`}>
              <div
                className={`${styles.FilterCard} ${styles.Select} ${styles.Full} CusSelectFilter AddTour`}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Property Name*
                  </InputLabel>
                  <Select
                    input={<OutlinedInput label="Property Name" />}
                    MenuProps={MenuProps}
                    onChange={(e) => setSelectProperty(e.target.value)}
                    value={selectProperty}
                  >
                    {props?.allProperties?.map((item) => {
                      return (
                        <MenuItem key={item?.value} value={item?.value}>
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {propertyError !== "" && (
                  <p className={`${styles.AddTourErrorMessage}`}>
                    {propertyError}
                  </p>
                )}
              </div>
            </div>

            <p className={`${styles.AddTourPopTT}`}>
              Select Open House Date and Time
            </p>
            <div className={`${styles.ModalFormGroup} ModalFormGroup`}>
              <div className={`${styles.AddTourModal} AddTourModal`}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <StaticDatePicker
                    orientation="landscape"
                    value={selectedTourDate}
                    onChange={(e) => setSelectedTourDate(e)}
                  />
                </LocalizationProvider>
              </div>
              <div className={`${styles.AddTourTimeArea}`}>
                <p className={`${styles.TimeSlotTitle}`}>
                  {dayjs(selectedTourDate).format("dddd, MMMM D")}
                </p>
                <p className={`${styles.AddTourErrorMessage}`}>
                  {tourTimeSlotError !== "" && tourTimeSlotError}
                </p>
                <AddTourTileSlot
                  setSelectedTourTime={setSelectedTourTime.bind(this)}
                  selectedTourTime={selectedTourTime}
                  selectedTourDate={selectedTourDate}
                  selectProperty={selectProperty}
                />
              </div>
            </div>
          </div>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={(e) => props.setOpenHouseFormOpen(false)}
          className="CancelPopupBU"
        >
          Cancel
        </Button>
        {!props?.is_edit && (
          <Button className="SubmitPopupBU" onClick={onSubmit}>
            Add
          </Button>
        )}
        {props?.is_edit && (
          <Button className="SubmitPopupBU" onClick={onUpdate}>
            Update
          </Button>
        )}
      </DialogActions>
    </>
  );
};

export default OpenHouseFrom;
