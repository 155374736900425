import { X } from "react-feather";
import styles from "../tour.module.css";
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import dayjs from "dayjs";

const EventView = (props) => {

  return (<>
    <DialogTitle id="alert-dialog-title">
      {props?.isTour ? "Tour" : "Open House"}
      <button onClick={(e) => props.setOpenHouseFormOpen(false)}><X /></button>
    </DialogTitle>

    <DialogContent>
      <DialogContentText>
        <div className={`${styles.CalendarEditPopup}`}>
          <ul>
            {props?.isTour && (<li>
              <p className={`${styles.CalendarEditPopupTitle}`}>Prospect:</p>
              <p className={`${styles.CalendarEditPopupCon}`}>{props?.selectedEvent?.extendedProps?.prospect_name}</p>
            </li>)}
            {props?.isTour && (<li>
              <p className={`${styles.CalendarEditPopupTitle}`}>Phone:</p>
              <p className={`${styles.CalendarEditPopupCon}`}>{(props?.selectedEvent?.extendedProps?.prospect_phone !== '' ? props?.selectedEvent?.extendedProps?.prospect_phone : '-')}</p>
            </li>)}
            {props?.isTour && (<li>
              <p className={`${styles.CalendarEditPopupTitle}`}>Email:</p>
              <p className={`${styles.CalendarEditPopupCon}`}>{(props?.selectedEvent?.extendedProps?.prospect_email !== '' ? props?.selectedEvent?.extendedProps?.prospect_email : '-')}</p>
            </li>)}
            <li>
              <p className={`${styles.CalendarEditPopupTitle}`}>Property:</p>
              <p className={`${styles.CalendarEditPopupCon}`}>{props?.selectedEvent?.extendedProps?.property_name}</p>
            </li>
            {props?.selectedEvent?.extendedProps?.apt_no !== "" && <li>
              <p className={`${styles.CalendarEditPopupTitle}`}>Apartment:</p>
              <p className={`${styles.CalendarEditPopupCon}`}>{props?.selectedEvent?.extendedProps?.apt_no}</p>
            </li>}
            <li>
              <p className={`${styles.CalendarEditPopupTitle}`}>Date:</p>
              <p className={`${styles.CalendarEditPopupCon}`}>{dayjs(props?.selectedEvent?.extendedProps?.date_time).format("MM/DD/YYYY hh:mmA")}</p>
            </li>
          </ul>
        </div>
      </DialogContentText>
    </DialogContent>

    <DialogActions>
      <Button className="CancelPopupBU" onClick={(e) => { props.setOpenHouseFormOpen(false); props.setOpenDeleteModal(true); }}>Delete</Button>
      <Button className="SubmitPopupBU" onClick={(e) => { props.setOpenHouseFormOpen(false); props.setEditEventOpen(true); }}>Edit</Button>
    </DialogActions>
  </>);
};

export default EventView;
