import { Check, ChevronDown } from 'react-feather';
import styles from '../rentalappplication.module.css';
import { Accordion, AccordionDetails, AccordionSummary, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import axios from 'axios';
import { usePlacesWidget } from 'react-google-autocomplete';

const FiveDigitFieldformat = React.forwardRef((props, ref) => {
    return <NumberFormat
        customInput={TextField}
        prefix=""
        displayType="input"
        type="text"
        thousandSeparator={false}
        decimalScale={0} {...props} />;
});

const AddressAccordion = ({ expanded, stepData, setStepData, accordinChange, lastStep, setLastStep, setCurrentStep, getNextStep, setPanelExpanded, panelRef, applicantId }) => {
    const [stateList, setStateList] = useState([]);

    const { handleSubmit, control, setValue, formState: { errors }, clearErrors } = useForm({
        mode: "onChange",
        defaultValues: {
            address: '',
            address2: '',
            city: '',
            state: '',
            zip: '',

        }
    });

    useEffect(() => {
        if (stepData) {
            if (stepData?.address) {
                setValue('address', stepData?.address);
            }
            if (stepData?.address2) {
                setValue('address2', stepData?.address2);
            }
            if (stepData?.city) {
                setValue('city', stepData?.city);
            }
            if (stepData?.state) {
                setValue('state', stepData?.state);
            }
            if (stepData?.zip) {
                setValue('zip', stepData?.zip);
            }
        }
    }, [stepData, setValue]);

    const { ref: autocompleteRef } = usePlacesWidget({
        apiKey: "AIzaSyDrxtVszbIQ4U8rL90V1lDfy5HvN673fcg",
        options: {
            types: ["address"],
            componentRestrictions: { country: "us" },
            fields: ["address_components", "geometry"],
        },
        onPlaceSelected: (place) => {
            let address = "";
            let city = "";
            let state = "";
            let postcode = "";

            for (let component of place.address_components) {
                let componentType = component.types[0];
                switch (componentType) {
                    case "street_number": {
                        address = component.long_name;
                        break;
                    }
                    case "route": {
                        address += ' ' + component.short_name;
                        break;
                    }
                    case "postal_code": {
                        postcode = component.long_name;
                        break;
                    }
                    case "locality": {
                        if (city === '')
                            city = component.long_name;
                        break;
                    }
                    case "sublocality_level_1": {
                        city = component.long_name;
                        break;
                    }
                    case "administrative_area_level_1": {
                        state = component.short_name;
                        break;
                    }
                    default:
                        break;
                }
            }

            setValue('address', address);
            setValue('city', city);
            setValue('state', state);
            setValue('zip', postcode);
            clearErrors();
        }
    });

    useEffect(() => {
        const fetchState = async () => {
            await axios.get(process.env.REACT_APP_PROPERTY_API_URL + 'v1/states').then(response => {
                setStateList(response.data.data);
            }).catch(error => {
                setStateList([]);
            });
        }
        fetchState();
    }, []);

    const onSubmit = (data) => {
        let postData = { ...data, is_address_submitted: 1 };
        setStepData(prev => {
            return { ...prev, ...postData };
        });

        if (applicantId) {
            axios.post(process.env.REACT_APP_APPLICANT_API_URL + 'v1/save-applicants/' + applicantId, postData);
        }

        let currentStepTemp = getNextStep(3);
        setCurrentStep(currentStepTemp);
        setPanelExpanded('panel' + currentStepTemp);
        if (lastStep < currentStepTemp) {
            setLastStep(currentStepTemp);
        }
    }

    return (<Accordion expanded={expanded === 'panel3'} onChange={accordinChange('panel3', 3)} disabled={lastStep < 3}>
        <AccordionSummary expandIcon={<ChevronDown />} aria-controls="panel1bh-content" id="panel1bh-header" ref={panelRef}>
            <div className={`${styles.FormAccoHead}`}>
                <p className={`${styles.FormAccoHeadTitle}`}>Address</p>
                <div className={((lastStep > 3) ? `${styles.AccoCheck} ${styles.Active}` : `${styles.AccoCheck}`)}><Check /></div>
            </div>
        </AccordionSummary>
        <AccordionDetails className={`${styles.CussAccoRow}`}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={`${styles.AccoFormSec}`}>

                    <div className={`${styles.FormGroupHalf} FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>Current street address<span>*</span></label>
                        <FormControl fullWidth>
                            <Controller
                                name="address"
                                control={control}
                                rules={{
                                    required: 'This field is required.',
                                    maxLength: {
                                        value: 50,
                                        message: "Address cannot be more than 50 characters."
                                    }
                                }}
                                render={(field) => (
                                    <TextField
                                        {...field}
                                        inputRef={autocompleteRef}
                                        autoComplete='off'
                                        placeholder=""
                                        label="Current street address"
                                    />
                                )}
                            />
                            {errors.address && <p className={`${styles.ErrorM}`}>{errors.address.message}</p>}
                        </FormControl>
                    </div>
                    <div className={`${styles.FormGroupHalf} FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>Street address</label>
                        <FormControl fullWidth>
                            <Controller
                                name="address2"
                                control={control}
                                rules={{
                                    maxLength: {
                                        value: 50,
                                        message: "Street address cannot be more than 50 characters."
                                    }
                                }}
                                render={(field) => (
                                    <TextField
                                        {...field}
                                        label="Street address"
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }} />
                                )}
                            />
                            {errors.address2 && <p className={`${styles.ErrorM}`}>{errors.address2.message}</p>}
                        </FormControl>
                    </div>

                    <div className={`${styles.FormGroupHalf} FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>City<span>*</span></label>
                        <FormControl fullWidth>
                            <Controller
                                name="city"
                                control={control}
                                rules={{
                                    required: 'This field is required.',
                                    maxLength: {
                                        value: 50,
                                        message: "City cannot be more than 50 characters."
                                    }
                                }}
                                render={(field) => (
                                    <TextField
                                        {...field}
                                        label="City"
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }} />
                                )}
                            />
                            {errors.city && <p className={`${styles.ErrorM}`}>{errors.city.message}</p>}
                        </FormControl>
                    </div>
                    <div className={`${styles.FormGroupHalf}  FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>State<span>*</span></label>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select</InputLabel>
                            <Controller
                                name="state"
                                control={control}
                                rules={{
                                    required: 'This field is required.',
                                }}
                                render={(field) => (
                                    <Select
                                        {...field}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }}
                                    >
                                        {stateList?.map(item => {
                                            return (< MenuItem key={item?.value} value={item?.value}>{item?.label}</MenuItem>);
                                        })}
                                    </Select>
                                )}
                            />
                            {errors.state && <p className={`${styles.ErrorM}`}>{errors.state.message}</p>}
                        </FormControl>
                    </div>

                    <div className={`${styles.FormGroupHalf} FormGroup CusRental`}>
                        <label className={`${styles.FormLabel}`}>Zip code<span>*</span></label>
                        <FormControl fullWidth>
                            <Controller
                                name="zip"
                                control={control}
                                rules={{
                                    required: 'This field is required.',
                                    pattern: {
                                        value: /\b\d{5}\b/,
                                        message: "Please enter a valid zip code."
                                    },
                                }}
                                render={(field) => (
                                    <FiveDigitFieldformat
                                        {...field}
                                        label="Zip code"
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }} />
                                )}
                            />
                            {errors.zip && <p className={`${styles.ErrorM}`}>{errors.zip.message}</p>}
                        </FormControl>
                    </div>

                    <div className={`${styles.AccoFormBUsec}`}>
                        <button type="submit" className={`${styles.applicantNextBU}`}>NEXT</button>
                    </div>

                </div>
            </form>
        </AccordionDetails>
    </Accordion >);
}

export default AddressAccordion;