import React, { useEffect } from "react";
import { DialogTitle, DialogContent, DialogContentText, FormControl, InputLabel, Select, MenuItem, Button, Chip, OutlinedInput, Box } from "@mui/material";
import styles from "../../prospectlist.module.css";
import { X } from "react-feather";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import AddTourTileSlot from "../AddTourTileSlot";
import dayjs from "dayjs";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const StepThreeForm = (props) => {
    const [selectedTourTime, setSelectedTourTime] = React.useState("");
    const [selectedTourDate, setSelectedTourDate] = React.useState(dayjs());
    const [tourTimeSlotError, setTourTimeSlotError] = React.useState("");

    useEffect(() => {
        if (selectedTourTime !== "") {
            setTourTimeSlotError("");
        }
    }, [selectedTourTime]);

    const onSubmit = () => {
        if (selectedTourTime === "") {
            setTourTimeSlotError("Please select time slot.");
            return false;
        }
        let data = {};
        data = { ...data, tour_date_time: dayjs(selectedTourDate).format("YYYY-MM-DD") + " " + selectedTourTime };
        props.onSubmit(data);
    }

    return (<>
        <DialogTitle id="alert-dialog-title"><span>Add Prospect</span><button onClick={(e) => props?.setOpenAddProspectModal(false)}><X /></button></DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <div className={`${styles.Width100}`}>
                    <div className={`${styles.FilterCard} ${styles.SelectPros} CusSelectFilter AddTour`}>
                        <FormControl fullWidth disabled>
                            <InputLabel id="demo-simple-select-label">Prospect's Name</InputLabel>
                            <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                defaultValue={props?.prospect_name}
                                input={
                                    <OutlinedInput id="select-multiple-chip" label="Prospect's Name" />
                                }
                                renderValue={(selected) => (
                                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                        <Chip key={0} label={props?.prospect_name} />
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                            >
                                <MenuItem key={0} value={props?.prospect_name}>{props?.prospect_name}</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <p className={`${styles.AddTourPopTT}`}>Select Tour Date and Time</p>
                    <div className={`${styles.ModalFormGroup} ModalFormGroup`}>
                        <div className={`${styles.AddTourModal} AddTourModal`}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <StaticDatePicker
                                    orientation="landscape"
                                    defaultValue={selectedTourDate}
                                    minDate={dayjs()}
                                    onChange={(e) => setSelectedTourDate(e)}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className={`${styles.AddTourTimeArea}`}>
                            <p className={`${styles.TimeSlotTitle}`}>{dayjs(selectedTourDate).format("dddd, MMMM D")}</p>
                            <p className={`${styles.AddTourErrorMessage}`}>{tourTimeSlotError !== "" && tourTimeSlotError}</p>
                            <AddTourTileSlot setSelectedTourTime={setSelectedTourTime.bind(this)} selectedTourTime={selectedTourTime} selectProperty={props?.property_id} />
                        </div>
                    </div>
                    <div className={`${styles.ButtonAction}`}>
                        <Button className="CancelPopupBU" onClick={(e) => props?.setOpenAddProspectModal(false)}>Cancel</Button>
                        <Button className="SubmitPopupBU" onClick={onSubmit}>Add</Button>
                    </div>
                </div>
            </DialogContentText>
        </DialogContent >
    </>);
}

export default StepThreeForm;