import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import Loader from "../../components/Loader";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from 'axios';
import Select from "react-select";
import Helmet from "react-helmet";
import { toast } from 'react-smart-toaster';
import styles from './violationsadd.module.css';
import { Typography, Button } from "@material-ui/core";
import Footer from "../../components/Footer";
import { Home, Upload, X } from 'react-feather';
import 'date-fns';
import { FromLoader } from "../../components/LoaderC";
import { AddLogCallAPI } from "../../components/AddLogs";

const brandColor = '#cccccc';
const customStyles = {
  control: (base, state) => ({
    ...base,
    boxShadow: state.isFocused ? 0 : 0,
    borderColor: state.isFocused
      ? brandColor
      : base.borderColor,
    '&:hover': {
      borderColor: state.isFocused
        ? brandColor
        : base.borderColor,
    }
  })
};

const priorityList = [
  { value: 1, label: 'Low' },
  { value: 2, label: 'Medium' },
  { value: 3, label: 'High' }
];

const schema = yup.object().shape({
  tenant_id: yup.object().required('This field is Required'),
  title: yup.string().required('This field is Required').max(50, 'Title cannot be more than 50 characters'),
  description: yup.string().required('This field is Required').max(200, 'Description cannot be more than 200 characters'),
  //assignee: yup.string().required('This field is Required'),
  priority: yup.object().required('This field is Required'),
  // status: yup.string().required('This field is Required'),
});


function ViolationAdd({ history, match }) {
  const { getAccessTokenSilently } = useAuth0();;
  const redirect = useHistory();
  const audience = process.env.REACT_APP_CASE_API_URL;

  const { id } = match.params;
  const isAddMode = !id;
  const [loading, setLoading] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const [TenantList, setTenantList] = useState({ data: [], loading: false });
  const [contentLoading, setContentLoading] = useState(false);


  const [ImgClass, setImgClass] = useState({ progressBar: 'hide', progress: 0, imageBlock: 'hide', imagePathArr: [], imageNameArr: [] });
  const [ImgPathTemp, setImgPathTemp] = useState({ Files: [], imagePathArr: [] });
  const { register, handleSubmit, control, errors, setValue, watch } = useForm({
    resolver: yupResolver(schema), mode: 'onChange'
  });
  const maxChars = 200;
  const [charsLeft, setCharsLeft] = useState(maxChars);

  const maxTitleChars = 50;
  const [titleCharsLeft, setTitleCharsLeft] = useState(maxTitleChars);


  useEffect(() => {
    async function fetchTenant() {
      setContentLoading(true);
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
      await axios.get(audience + 'v1/property-tenant-list', {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).then(response => {
        setTenantList({ data: response.data.data, loading: true });
        setContentLoading(false);
      }).catch(error => {
        setTenantList({ data: [], loading: true });
      });
    }
    if (!isAddMode) {
      fetchTenant();
    }
  }, [audience, setValue, isAddMode, getAccessTokenSilently]);

  useEffect(() => {

    if (!isAddMode) {
      async function fetchByID() {
        if (isLoad) {
          setContentLoading(false);
          return false;
        }
        setIsLoad(true);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        await axios.get(audience + 'v1/violations/' + id, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }).then(response => {

          const resData = response.data.data;
          let img_path_arr = resData.images.map((e) => {
            return e.file_public_url
          });
          let image_name_arr = resData.images.map((e) => {
            return { "id": e.id, "file_path": e.file_path };
          });
          setImgClass({ ...ImgClass, imageBlock: '', imagePathArr: img_path_arr, imageNameArr: image_name_arr });
          setCharsLeft(maxChars - resData.description.length);
          setTitleCharsLeft(maxTitleChars - resData.title.length);

          priorityList.map((e) => {
            if (e.value === resData.priority) {
              setValue('priority', e);
              // setselectedContactway(e);
            }
            return true;
          });
          const fields = ['title', 'description'];
          fields.forEach(field => setValue(field, resData[field]));
          TenantList.data.map((e) => {
            if (e.id === resData.property_id) {

              e.options.map((e1) => {
                if (e1.value === resData.tenant_id) {

                  setValue('tenant_id', e1);
                }
                return true;
              });
            }
            return true;
          });
          setContentLoading(false);
        }).catch(error => {
          if (typeof error.response !== 'undefined')
            toast.error(error.response.data.message);
          setContentLoading(false);
        });
      }
      if (TenantList.loading) {
        fetchByID();
      }
    }

  }, [id, isAddMode, TenantList, audience, setValue, isLoad, ImgClass, getAccessTokenSilently]);




  function deleteImage(index) {
    let imgClass = ImgClass;
    let img_path_arr = imgClass.imagePathArr;
    img_path_arr = img_path_arr.filter(function (item, i) {
      return i !== index;
    })
    let image_name_arr = imgClass.imageNameArr;
    image_name_arr = image_name_arr.filter(function (item, i) {
      return i !== index;
    })
    setImgClass({ ...ImgClass, imagePathArr: img_path_arr, imageNameArr: image_name_arr });
  }

  function fileUploadButtonClickTemp() {
    document.getElementById('fileUploadID').getElementsByClassName('inputBtn')[0].click();
    return false;
  }

  async function onSubmit(data) {

    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
    if (data.tenant_id) {
      data.property_id = data.tenant_id.property_id;
      data.tenant_id = data.tenant_id.value;
    }
    if (data.priority) {
      data.priority = data.priority.value;
    }
    data.images = JSON.stringify(ImgClass.imageNameArr);

    setLoading(true);

    let formData = new FormData();

    Object.keys(data).map((key) => {
      formData.append(key, data[key]);

      return true;
    });

    if (ImgPathTemp.Files.length) {
      for (let n in ImgPathTemp.Files) {
        formData.append("uploadImages[]", ImgPathTemp.Files[n]);
      }
    }

    if (isAddMode) {
      return axios.post(audience + 'v1/violations', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }).then(response => {
        let respData = response.data.data;
        toast.success(response.data.message);
        if (isAddMode) {
          setTimeout(() => {
            redirect.push('/cases/violations');
          }, 3000);
        }
        setLoading(false);
        let logData = {
          'title': 'New Violation is created',
          'description': [
            'Name: ' + respData.title,
            'Violations number: ' + respData.violation_no,
            'Property: ' + respData.property_name,
            'Apartment: ' + respData.apt_name,
            'Tenant: ' + respData.tenant_name,
          ]
        }
        AddLogCallAPI(logData, token);
      }).catch(error => {
        if (typeof error.response !== 'undefined')
          toast.error(error.response.data.message);
        setLoading(false);
      });
    } else {
      return axios.post(audience + 'v1/violations/edit/' + id, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }).then(response => {
        let respData = response.data.data;
        toast.success(response.data.message);
        setLoading(false);
        setTimeout(() => {
          redirect.push('/cases/violations/details/' + id);
        }, 3000);
        let logData = {
          'title': 'Violation is updated',
          'description': [
            'Name: ' + respData.title,
            'Violations number: ' + respData.violation_no,
            'Property: ' + respData.property_name,
            'Apartment: ' + respData.apt_name,
            'Tenant: ' + respData.tenant_name,
          ]
        }
        AddLogCallAPI(logData, token);

      }).catch(error => {
        if (typeof error.response !== 'undefined')
          toast.error(error.response.data.message);
        setLoading(false);
      });
    }
  };
  function cancelBtnClick(e) {
    if (!isAddMode) {
      redirect.push('/cases/violations/details/' + id);
    } else {
      redirect.push('/cases/violations/');
    }

  }

  const fileHandler = (e) => {
    var validExtensions = ['jpg', 'jpeg', 'png', 'svg']; //array of valid extensions
    var fileName = e.target.files[0].name;
    var fileNameExt = fileName.substr(fileName.lastIndexOf('.') + 1);
    if (validExtensions.indexOf(fileNameExt.toLowerCase()) === -1) {
      toast.error('File type is not allowed');

    }
    else if (e.target.files[0].size > 5242880) {
      toast.error('Image size should not be greater than 5 mb.');

    }
    else {
      let tempFiles = ImgPathTemp.Files;
      let tempImages = ImgPathTemp.imagePathArr;
      for (let n = 0; n < e.target.files.length; n++) {
        let file = e.target.files[n];
        tempImages.push(URL.createObjectURL(file));
        tempFiles.push(file);
      }
      setImgPathTemp({ ...ImgPathTemp, imagePathArr: tempImages, Files: tempFiles });
    }

  }

  function deleteTempImage(index) {
    let tempFiles = ImgPathTemp.Files;
    let tempImages = ImgPathTemp.imagePathArr;


    tempFiles = tempFiles.filter(function (item, i) {
      return i !== index;
    });

    tempImages = tempImages.filter(function (item, i) {
      return i !== index;
    })
    setImgPathTemp({ ...ImgPathTemp, imagePathArr: tempImages, Files: tempFiles });
  }

  return (<React.Fragment>
    {loading && <Loader />}
    <Helmet title={((isAddMode) ? 'Add' : 'Edit') + ' Violations'} />
    <div className={`${styles.pageTitleWrap}`}>
      <Typography display="inline" className={`${styles.pageTitle}`}>{(isAddMode) ? 'Add' : 'Edit'} Violations</Typography>
      <nav aria-label="breadcrumb">
        <ol className={`${styles.breadcrumb}`}>
          <li className={`${styles.breadcrumbItem}`}><span><Home /></span>Cases</li>
          <li className={`${styles.breadcrumbItem}`}><Link to={'/cases/violations'} >Violations</Link></li>
          <li className={`${styles.breadcrumbItem} ${styles.active}`}>{(isAddMode) ? 'Add' : 'Edit'}</li>
        </ol>
      </nav>
    </div>

    <div className={`${styles.mainCard}`}>
      {contentLoading && <div className="Loader FromLoader"><FromLoader /></div>}
      {!contentLoading && <div className={`${styles.AddUserMain}`}>
        <form id="myForm" onSubmit={handleSubmit(onSubmit)}>

          <div className={`${styles.Row}`}>
            <div className={`${styles.SmallDiv}`}>
              <div className={`${styles.FormRow}`}>
                <div className={`${styles.FormGroup}`}>
                  <label className={`${styles.FilterLabel}`} >Tenant<span className={`${styles.errorSpan}`}>*</span></label>
                  <Controller styles={customStyles}
                    name="tenant_id"
                    control={control}
                    options={TenantList.data}
                    as={Select}
                    ref={register}

                  />
                  {errors.tenant_id && !watch().tenant_id && <p className={`${styles.ErrorM}`}>{errors.tenant_id.message}</p>}
                  {errors.tenant_id && watch().tenant_id && <p className={`${styles.ErrorM}`}>{errors.tenant_id.message}</p>}

                </div>
                <div className={`${styles.FormGroup}`}>
                  <label className={`${styles.FilterLabel}`} >Title<span className={`${styles.errorSpan}`}>*</span></label>
                  <input className={`${styles.formControl}`} type="text" name="title" ref={register} maxLength={maxTitleChars} onChange={e => setTitleCharsLeft(maxTitleChars - e.target.value.length)} />
                  <p>{titleCharsLeft} {(titleCharsLeft > 1) ? 'characters' : 'character'} remaining</p>
                  {errors.title && <p className={`${styles.ErrorM}`}>{errors.title.message}</p>}
                </div>
              </div>

              <div className={`${styles.FormGroup}`}>
                <Controller styles={customStyles}
                  name="priority"
                  control={control}
                  options={priorityList}
                  as={Select}
                  ref={register}
                  placeholder='Select priority'
                />
                {errors.priority && !watch().priority && <p className={`${styles.ErrorM}`}>{errors.priority.message}</p>}
              </div>
            </div>


            <div className={`${styles.FormGroup}`}>
              <label className={`${styles.FilterLabel}`} >Description<span className={`${styles.errorSpan}`}>*</span></label>
              <textarea placeholder="Add text here..." className={`${styles.formControlTextArea}`} name="description" ref={register} maxLength={maxChars} onChange={e => setCharsLeft(maxChars - e.target.value.length)}></textarea>
              <p>{charsLeft} {(charsLeft > 1) ? 'characters' : 'character'} remaining</p>
              {errors.description && <p className={`${styles.ErrorM}`}>{errors.description.message}</p>}
            </div>

            <div className={`${styles.FileIMGDiv}`}>
              <div className={`${styles.FileUpDuBUDiv}`}>
                <label for="file-upload" className={`${styles.UploadLabel}`} id="fileUploadID">
                  <input type="file" className="inputBtn" onChange={fileHandler} multiple accept=".jpg,.png,.jpeg,.svg" />
                  <span onClick={fileUploadButtonClickTemp.bind()}>
                    <div className={`${styles.UpIcon}`}><Upload /></div> Upload Photo
                  </span>
                  <p className={`${styles.UploadText}`}>File size upto 5 MB  JPEG, JPG, PNG</p>
                </label>

                <span className={'e-upload-progress-wrap ' + ImgClass.progressBar}>
                  <span className="e-progress-inner-wrap">
                    <progressbar className="e-upload-progress-bar e-upload-progress" value="0" max="100" style={{ width: ImgClass.progress + '%' }}></progressbar>
                  </span>
                  <span className="e-progress-bar-text">{ImgClass.progress}%</span>
                </span>
              </div>
              {ImgClass.imagePathArr.map((item, index) => {
                return <div className={`${styles.ImgFile}`}>
                  <img src={item} alt="" />
                  <Button onClick={deleteImage.bind(this, index)}><X /></Button>
                </div>
              })}
              {ImgPathTemp.imagePathArr.map((item, index) => {
                return <div className={`${styles.ImgFile}`}>
                  <img src={item} alt="" />
                  <Button onClick={deleteTempImage.bind(this, index)}><X /></Button>
                </div>
              })}

            </div>
          </div>

          <div className={`${styles.ButtonDiv}`}>
            <button onClick={cancelBtnClick.bind(this)} type="button" className={`${styles.ButtonVoiletLine}`}>Cancel</button>
            <button type="submit" className={`${styles.ButtonVoiletSolid}`}>{isAddMode ? 'Add' : 'Update'}</button>


          </div>
        </form>
      </div>}
    </div>


    <Footer />
  </React.Fragment>);


}


export default withAuthenticationRequired(ViolationAdd, {
  onRedirecting: () => <Loader />,
});