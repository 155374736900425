import { useEffect, useState } from 'react';
import { ArrowRight, Filter, Search } from 'react-feather';
import styles from './devicealert.module.css';
import { Select, FormControl, MenuItem, InputLabel } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { toast } from "react-smart-toaster";
import { Link } from "react-router-dom";


const FilterComponent = ({ setFilterData }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [allProperties, setAllProperties] = useState([]);
    const [allApartments, setAllApartments] = useState([]);
    const [filterOpen, setFilterOpen] = useState(false);
    const OpenFilterDrawer = () => {
        setFilterOpen(true)
    }
    const CloseFilterDrawer = () => {
        setFilterOpen(false)
    }

    useEffect(() => {
        const fetchAllProperties = async () => {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
            await axios.get(process.env.REACT_APP_DEVICE_API_URL + "v1/properties", {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let propertyData = response.data.data;
                propertyData = [{ value: "all", label: "Any", apartments: [] }].concat(propertyData);

                setAllProperties(propertyData);
            }).catch((error) => {
                if (typeof error.response !== "undefined")
                    toast.error(error.response.data.message);
            });
        };
        fetchAllProperties();
    }, [getAccessTokenSilently]);

    return (
        <div className={`${styles.SearchFilterSec}`}>
            <div className={`${styles.SearchCon}`}>
                <div className={`${styles.SearchConSec}`}>
                    <Search />
                    <input type="text" placeholder="Search" onChange={(e) => setFilterData(prev => { return { ...prev, searchKey: e.target.value }; })} />
                </div>
            </div>
            <div className={`${styles.FilterSec}`}>
                <div className={filterOpen === true ? `${styles.FilterFieldSec} ${styles.Open}` : `${styles.FilterFieldSec}`}>
                    <p className={`${styles.FilterTitle}`}>
                        Filters
                        <span onClick={CloseFilterDrawer}><ArrowRight /></span>
                    </p>
                    <div className={`${styles.FilterCard} ${styles.Select} ${styles.Width200} CusSelectFilter Filter`}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select Property</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Select Property"
                                onChange={(e) => { let pObj = e.target.value; setFilterData(prev => { return { ...prev, property: pObj?.value }; }); setAllApartments([{ value: "all", label: "Any" }].concat(pObj?.apartments)); }}
                            >
                                {allProperties?.map(item => {
                                    return <MenuItem key={item?.value} value={item}>{item?.label}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    <div className={`${styles.FilterCard} ${styles.Select} ${styles.Width200} CusSelectFilter Filter`}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select Apartment</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Select Apartment"
                                onChange={(e) => setFilterData(prev => { return { ...prev, apartment: e.target.value }; })}
                            >
                                {allApartments?.map(item => {
                                    return <MenuItem key={item?.value} value={item?.value}>{item?.label}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    <div className={`${styles.FilterCard} ${styles.Select} ${styles.Width200} CusSelectFilter Filter`}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Select Status"
                                defaultValue={''}
                                onChange={(e) => setFilterData(prev => { return { ...prev, is_archived: e.target.value }; })}
                            >
                                <MenuItem value="all">All</MenuItem>
                                <MenuItem value={0}>Active</MenuItem>
                                <MenuItem value={1}>Archived</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>   
                <Link className={`${styles.FilterBu}`} onClick={OpenFilterDrawer}><Filter /></Link>
            </div>
        </div>
    )
    
}


export default FilterComponent;