import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styles from "./tenantdetails.module.css";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Home, ArrowLeft, X } from "react-feather";
import Helmet from "react-helmet";
import Footer from "../../components/Footer";
import axios from "axios";
import { toast } from "react-smart-toaster";
import Loader from "../../components/Loader";
import { TableLoader } from "../../components/LoaderC";
import { useAuth0 } from "@auth0/auth0-react";
import { Typography, IconButton, Snackbar, Slide, Dialog } from "@mui/material";
import TenantCard from "./components/TenantCard";
import TenantEditForm from "./components/TenantEditForm";
import SimpleTabs from "./components/SimpleTabs";

const TenantDetails = ({ history, match }) => {
  const { id } = match.params;
  const redirect = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  const [showAddForm, setShowAddForm] = useState(false);
  const [tenantData, setTenantData] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [activeTenant, setActiveTenant] = useState(0);
  const [isAddForm, setIsAddForm] = useState(false);

  useEffect(() => {
    const getTenantDetails = async () => {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

      axios.get(process.env.REACT_APP_TENANT_API_URL + "v1/tenants/" + id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        setContentLoading(false);
        setTenantData(response.data.data);
      }).catch((error) => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);

        setContentLoading(false);
      });
    }

    getTenantDetails();
  }, [getAccessTokenSilently, id]);

  return (<>
    <Helmet title={tenantData?.all_tenants?.length > 0 && tenantData?.all_tenants[0].full_name} />
    {loading && <Loader />}



    <div className={`${styles.mainCard}`}>
      {contentLoading && (<div className="Loader TableLoader">
        <TableLoader />
      </div>)}
      {!contentLoading && <div>
        <div className={`${styles.AppliSwitchRow}`}>
          <div className={`${styles.AppliOne}`}>
            <div className={`${styles.SMProCusRow}`}>

              <div className={`${styles.BreadcrumbHeadSec}`}>
                <nav aria-label="breadcrumb">
                  <ol className={`${styles.breadcrumb}`}>
                    <li className={`${styles.breadcrumbItem}`}><span><Home /></span>People</li>
                    <li className={`${styles.breadcrumbItem}`}>Tenant</li>
                    <li className={`${styles.breadcrumbItem} ${styles.active}`}>{tenantData?.all_tenants?.length > 0 && tenantData?.all_tenants[0].full_name}</li>
                  </ol>
                </nav>

                <div className={`${styles.PageTitleSec}`}>
                  <div>
                    <button onClick={() => redirect.goBack()} className={`${styles.BackArrow}`}><ArrowLeft /></button>
                    <Typography display="inline" className={`${styles.pageTitle}`}>{tenantData?.all_tenants?.length > 0 && tenantData?.all_tenants[0].full_name}</Typography>
                  </div>
                </div>
              </div>

              <TenantCard tenantData={tenantData} activeTenant={activeTenant} setActiveTenant={setActiveTenant.bind(this)} setTenantData={setTenantData.bind(this)} setShowAddForm={setShowAddForm.bind(this)} setLoading={setLoading.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} setIsAddForm={setIsAddForm.bind(this)} />

              <div className={`${styles.SMProCusBigDiv}`}>
                <div className={`${styles.card}`}>
                  <div className={`${styles.cardbody}`}>
                    <div className={`${styles.SMBigSideBody} TabMainClientProfile ClientDetails`}>

                      <SimpleTabs tenantData={tenantData} setTenantData={setTenantData.bind(this)} setLoading={setLoading.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} />

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>}
    </div>

    <Dialog
      open={showAddForm}
      fullWidth
      maxWidth="sm"
      onClose={(e) => setShowAddForm(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp MobileForm"
    >
      <TenantEditForm tenantId={tenantData?.id} tenantData={tenantData?.all_tenants[activeTenant]} isAddForm={isAddForm} property={tenantData?.property} apartment={tenantData?.apartment} setShowAddForm={setShowAddForm.bind(this)} setTenantData={setTenantData.bind(this)} setLoading={setLoading.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} />
    </Dialog>

    <div className={`${styles.DetailsFooter} FooterBack`}>
      <Footer />
    </div>

    <Snackbar
      open={snackbarOpen}
      onClose={(e) => setSnackbarOpen(false)}
      TransitionComponent={Slide}
      message={snackbarMsg}
      autoHideDuration={10000}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={(e) => setSnackbarOpen(false)}
        >
          <X />
        </IconButton>
      }
    />
  </>);
}

export default withAuthenticationRequired(TenantDetails, {
  onRedirecting: () => <Loader />,
});