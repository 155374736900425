import React from "react";
import styles from './blogs.module.css';
import Helmet from "react-helmet";
import { Link as Linkto } from "react-router-dom";
import { Calendar, ArrowRight, ChevronLeft } from 'react-feather';
//import Zoom from 'react-reveal/Zoom';
//import Fade from 'react-reveal/Fade';
//import Bounce from 'react-reveal/Bounce';
// import { Animator, ScrollContainer, ScrollPage, batch, FadeIn, Move, MoveIn, MoveOut, Sticky, StickyIn, ZoomIn } from "react-scroll-motion";

// const ZoomInScrollOut = batch(StickyIn(), FadeIn(), ZoomIn());
// const FadeUp = batch(Fade(), Move(), Sticky());

import Header from '../../components/HeaderPublicOther'
import Footer from '../../components/FooterPublicOther';
class HomePage extends React.Component {
  constructor({ match, ...props }) {
    super(...arguments);
  }


  render() {
    return (<React.Fragment>
      <Helmet title="Home" />

      <Header />

      <div className="WhiteBG">
        <div className={`${styles.BlogDeBodySec}`}>
          <div className={`${styles.Container}`}>
            <div className={`${styles.BlogDeRow}`}>

              <div className={`${styles.BlogDeBigSec}`}>
                <Linkto to={'home'} className={`${styles.BackLink}`}><ChevronLeft /> Back</Linkto>
                <div className={`${styles.BlogDeBigHead}`}>
                  <p className={`${styles.BlogDeBigHeadDate}`}><Calendar /> 18TH JUNE, 2020</p>
                  <div className={`${styles.IconSecCon}`}>
                    <a href="https://www.facebook.com/tech4smbs/" className={`${styles.FB}`}><img src="/static/img/facebook.png" alt="logo" /></a>
                    <a href="https://twitter.com/tech4SMBs" className={`${styles.TW}`}><img src="/static/img/twitter.png" alt="logo" /></a>
                    <a href="https://www.linkedin.com/company/fbrothers" className={`${styles.LD}`}><img src="/static/img/linkedin.png" alt="logo" /></a>
                  </div>
                </div>
                <div className={`${styles.BlogDeBody}`}>
                  <p class={`${styles.DetailsHeadCon}`}>The videos we can quickly create with Wibbitz help us engage our audiences on social media</p>
                  <img src="/static/img/blogImg1.jpg" alt="" />
                  <p class={`${styles.DetailsBodyCon}`}>
                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.
                  </p>
                  <p class={`${styles.DetailsBodyCon}`}>
                    At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.
                  </p>
                </div>
              </div>

              <div className={`${styles.BlogDeSmallSec}`}>
                <div className={`${styles.BlogDeSideSec}`}>
                  <p className={`${styles.BlogDeSideSecTitle}`}>Latest Blogs</p>
                  <div className={`${styles.LatestBlogCard}`}>
                    <img src="/static/img/blogImg1.jpg" alt="" />
                    <div>
                      <p className={`${styles.LatestBlogCardTitle}`}>The videos we can quickly create with Wibbitz help us engage our audiences on social media</p>
                      <Linkto to={'blogs'} className={`${styles.ReBSideLink}`}>Learn More <ArrowRight /></Linkto>
                    </div>
                  </div>
                  <div className={`${styles.LatestBlogCard}`}>
                    <img src="/static/img/blogImg2.jpg" alt="" />
                    <div>
                      <p className={`${styles.LatestBlogCardTitle}`}>The videos we can quickly create with Wibbitz help us engage our audiences on social media</p>
                      <Linkto to={'blogs'} className={`${styles.ReBSideLink}`}>Learn More <ArrowRight /></Linkto>
                    </div>
                  </div>
                  <div className={`${styles.LatestBlogCard}`}>
                    <img src="/static/img/blogImg3.jpg" alt="" />
                    <div>
                      <p className={`${styles.LatestBlogCardTitle}`}>The videos we can quickly create with Wibbitz help us engage our audiences on social media</p>
                      <Linkto to={'blogs'} className={`${styles.ReBSideLink}`}>Learn More <ArrowRight /></Linkto>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <Footer />
      </div>

      {/* <div className={`${styles.footer}`}>
        <div className={`${styles.Container}`}>
          <div className={`${styles.FooterRow}`}>

            <div className={`${styles.FooterLogoSec}`}>
              <img src="/static/img/logo.svg" alt="logo" />
            </div>

            <div className={`${styles.FooterConSec}`}>
              <div className={`${styles.FooterConSecSmall}`}>
                <p className={`${styles.FootListTitle}`}>Company</p>
                <Linkto className={`${styles.FootListLink}`}>Blog</Linkto>
                <Linkto className={`${styles.FootListLink}`}>Contact</Linkto>
              </div>
              <div className={`${styles.FooterConSecSmall}`}>
                <p className={`${styles.FootListTitle}`}>Services</p>
                <Linkto className={`${styles.FootListLink}`}>e-learn App</Linkto>
                <Linkto className={`${styles.FootListLink}`}>My Portal App</Linkto>
                <Linkto className={`${styles.FootListLink}`}>Pedramp Tracker App</Linkto>
              </div>
              <div className={`${styles.FooterConSecSmall}`}>
                <p className={`${styles.FootListTitle}`}>Legal</p>
                <Linkto className={`${styles.FootListLink}`}>Privacy Policy</Linkto>
                <Linkto className={`${styles.FootListLink}`}>Terms & conditions</Linkto>
                <Linkto className={`${styles.FootListLink}`}>Cookies</Linkto>
              </div>
              <div className={`${styles.FooterConSecBig}`}>
                <div>
                  <a className={`${styles.FootListLinkBig}`} href="tel:17188254746">1-718-825-4746</a>
                  <a className={`${styles.FootListLinkBig}`} href="mailto:hello@propertydek.com">hello@propertydek.com</a>
                </div>
              </div>
            </div>

          </div>

          <div className={`${styles.LastFootRow}`}>
            <p className={`${styles.CopyRightText}`}>© 2022. Propertydek.com. All rights reserved.</p>
            <div className={`${styles.SocialIcon}`}>
              <a href="https://www.linkedin.com/company/fbrothers"><img src="/static/img/linkedin.png" alt="logo" /></a>
              <a href="https://twitter.com/tech4SMBs"><img src="/static/img/twitter.png" alt="logo" /></a>
              <a href="https://www.facebook.com/tech4smbs/"><img src="/static/img/facebook.png" alt="logo" /></a>
            </div>
          </div>
        </div>
      </div> */}

      {/* <ScrollContainer>
        <ScrollPage>
          <Animator animation={batch(Fade(), MoveOut(0, 200))}>
            <span style={{ fontSize: "30px" }}>Let me show you scroll animation 😀</span>
          </Animator>
        </ScrollPage>
      </ScrollContainer> */}

    </React.Fragment >);
  }
}


export default HomePage;