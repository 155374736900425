import { AppBar, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import DeviceAlerts from "./DeviceAlerts";
import LockList from "./lockgateway/LockList";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const SimpleTabs = ({ deviceDetails, setLoading, setSnackbarMsg, setSnackbarOpen, setDeviceDetails }) => {
    const classes = useStyles();
    const [tabValue, setTabValue] = useState(0);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (<div className={classes.root}>
        <AppBar position="static">
            <Tabs value={tabValue} onChange={handleChange} aria-label="simple tabs example">
                <Tab label="Device Alerts" {...a11yProps(0)} />
                {(deviceDetails?.product_type === "Propertydek-Lock Gateway") && <Tab label="Associated Locks" {...a11yProps(1)} />}
            </Tabs>
        </AppBar>
        <TabPanel value={tabValue} index={0}>
            <DeviceAlerts deviceDetails={deviceDetails} setLoading={setLoading.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setDeviceDetails={setDeviceDetails.bind(this)} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
            <LockList deviceDetails={deviceDetails} />
        </TabPanel>
    </div>);
}

export default SimpleTabs;