import { ChevronRight } from "react-feather";
import styles from "../dashboard.module.css";
import { useState } from "react";
import MonthlyCollectionList from "./MonthlyCollectionList";

const MonthCollection = ({ monthly_collected }) => {
    const [openDuePaymentList, setOpenDuePaymentList] = useState(false);
    const [openLatePaymentList, setOpenLatePaymentList] = useState(false);
    const [openPrePaymentList, setOpenPrePaymentList] = useState(false);

    return (<>
        <div className={`${styles.InsideCard} ${styles.FullSize}`} onClick={(e) => setOpenDuePaymentList(true)}>
            <div className={`${styles.InsideCardFlex} ${styles.Flex}`}>
                <h6 className={`${styles.InsideCardTitle}`}>Payments Due</h6>
                <div className={`${styles.InsideCardFlexConTwo}`}>
                    <h3 className={`${styles.InsideCardTitleAmount}`} style={{ fontSize: '28px' }}>${monthly_collected?.due_amount_f}</h3>
                </div>
                <ChevronRight />
            </div>
        </div>
        <div className={`${styles.InsideCard} ${styles.FullSize}`} onClick={(e) => setOpenLatePaymentList(true)}>
            <div className={`${styles.InsideCardFlex} ${styles.Flex}`}>
                <h6 className={`${styles.InsideCardTitle}`}>Late Payments</h6>
                <div className={`${styles.InsideCardFlexConTwo}`}>
                    <h3 className={`${styles.InsideCardTitleAmount}`} style={{ fontSize: '28px' }}>${monthly_collected?.late_payment_f}</h3>
                </div>
                <ChevronRight />
            </div>
        </div>
        <div className={`${styles.InsideCard} ${styles.FullSize}`} onClick={(e) => setOpenPrePaymentList(true)}>
            <div className={`${styles.InsideCardFlex} ${styles.Flex}`}>
                <h6 className={`${styles.InsideCardTitle}`}>Pre-payments for next month</h6>
                <div className={`${styles.InsideCardFlexConTwo}`}>
                    <h3 className={`${styles.InsideCardTitleAmount}`} style={{ fontSize: '28px' }}>${monthly_collected?.pre_payment_f}</h3>
                </div>
                <ChevronRight />
            </div>
        </div>

        <MonthlyCollectionList openedRentCard={openDuePaymentList} setOpenedRentCard={setOpenDuePaymentList.bind(this)} title={'Payments Due'} total={monthly_collected?.due_amount_f} data={monthly_collected?.due_amounts?.map(i => { return { 'apt_name': i?.apt_name, 'tenant_name': i?.tenant_name, 'amount': i?.formatted_due_amount }; })} />
        <MonthlyCollectionList openedRentCard={openLatePaymentList} setOpenedRentCard={setOpenLatePaymentList.bind(this)} title={'Late Payments'} total={monthly_collected?.late_payment_f} data={monthly_collected?.late_payments?.map(i => { return { 'apt_name': i?.apt_name, 'tenant_name': i?.tenant_name, 'amount': i?.formatted_late_payment_amount }; })} />
        <MonthlyCollectionList openedRentCard={openPrePaymentList} setOpenedRentCard={setOpenPrePaymentList.bind(this)} title={'Pre Payments'} total={monthly_collected?.pre_payment_f} data={monthly_collected?.pre_payments?.map(i => { return { 'apt_name': i?.apt_name, 'tenant_name': i?.tenant_name, 'amount': i?.formatted_advanced_amount }; })} />
    </>);
}

export default MonthCollection;