import React from "react";
import styles from './eula.module.css';
import Helmet from "react-helmet";
import Header from '../../components/HeaderPublicOther';
import Footer from '../../components/FooterPublicOther';


class HomePage extends React.Component {
  constructor({ match, ...props }) {
    super(...arguments);
  }


  render() {
    return (<React.Fragment>
      <Helmet title="EULA" />
      <Header />
      <div className="WhiteBG">
        <div className={`${styles.PrivacyArea}`}>
          <div className={`${styles.Container}`}>
            <div className={`${styles.PolicyTitleSec}`}>
              <p className={`${styles.PolicyTitle}`}>End User License Agreement</p>
            </div>

            <div className={`${styles.PolicyConSec}`}>
              <div className={`${styles.PolicyConDe}`}>
                <p className={`${styles.PolicyText}`}>This End-User License Agreement (this “EULA”) is a legal agreement between you (“Licensee”) and
                  FBrothers & Company, Inc. (FBC) (“Licensor”), the author of Propertydek, including all HTML files, XML
                  files, Java files, graphics files, animation files, data files, technology, development tools, scripts and
                  programs, both in object code and source code (the “Software”), the deliverables provided pursuant to
                  this   EULA,   which   may   include   associated   media,   printed   materials,   and   “online”   or   electronic
                  documentation.</p>
                <p className={`${styles.PolicyText}`}>By installing, copying, or otherwise using the Software, Licensee agrees to be bound by the terms and
                  conditions set forth in this EULA. If Licensee does not agree to the terms and conditions set forth in this
                  EULA, then Licensee may not download, install, or use the Software.</p>
              </div>
              <div className={`${styles.PolicyConDe}`}>
                <p className={`${styles.PolicyText}`}><span>1. Grant of License</span></p>
                <ul>
                  <li><span>Scope of License.</span> Subject to the terms of this EULA, Licensor hereby grants to Licensee a
                    royalty-free, non-exclusive license to possess and to use a copy of the Software.</li>
                  <li><span>Installation and Use.</span> Licensee may install and use a maximum of one (1) copies of the Software
                    solely for Licensee's business use.</li>
                </ul>
              </div>
              <div className={`${styles.PolicyConDe}`}>
                <p className={`${styles.PolicyText}`}><span>2. Description of Rights and Limitations</span></p>
                <ul>
                  <li><span>Limitations.</span> Licensee and third parties may not reverse engineer, decompile, or disassemble the
                    Software, except and only to the extent that such activity is expressly permitted by applicable law
                    notwithstanding the limitation.</li>
                  <li><span>Update and Maintenance.</span> Licensor shall provide updates and maintenance on the Software on an
                    as needed basis.</li>
                  <li><span>Separation of Components.</span> The Software is licensed as a single product. Its components may
                    not be separated for use on more than one computer.</li>
                </ul>
              </div>
              <div className={`${styles.PolicyConDe}`}>
                <p className={`${styles.PolicyText}`}><span>3. Title to Software.</span> Licensor represents and warrants that it has the legal right to enter into and perform its obligations under this EULA, and that use by the Licensee of the Software, in accordance with the terms of this EULA, will not infringe upon the intellectual property rights of any third parties.</p>
              </div>
              <div className={`${styles.PolicyConDe}`}>
                <p className={`${styles.PolicyText}`}><span>4. Intellectual Property.</span> All now known or hereafter known tangible and intangible rights, title, interest,
                  copyrights and moral rights in and to the Software, including but not limited to all images, photographs,
                  animations, video, audio, music, text, data, computer code, algorithms, and information, are owned by
                  Licensor. The Software is protected by all applicable copyright laws and international treaties.</p>
              </div>
              <div className={`${styles.PolicyConDe}`}>
                <p className={`${styles.PolicyText}`}><span>5. Support.</span> Licensor will provide phone support, available during normal business hours for a time
                  period of 1 year.</p>
              </div>
              <div className={`${styles.PolicyConDe}`}>
                <p className={`${styles.PolicyText}`}><span>6. Duration.</span> This EULA is perpetual or until:</p>
                <ul>
                  <li>utomatically terminated or suspended if Licensee fails to comply with any of the terms and
                    conditions set forth in this EULA; or</li>
                  <li>Terminated or suspended by Licensor, with or without cause.</li>
                </ul>
                <p className={`${styles.PolicyText}`}>In the event this EULA is terminated, you must cease use of the Software and destroy all copies of the Software.</p>
              </div>
              <div className={`${styles.PolicyConDe}`}>
                <p className={`${styles.PolicyText}`}><span>7. Jurisdiction.</span> This EULA shall be deemed to have been made in, and shall be construed pursuant to
                  the laws of the State of New York, without regard to conflicts of laws provisions thereof. Any legal action
                  or proceeding relating to this EULA shall be brought exclusively in courts located in New York City, NY,
                  and each party consents to the jurisdiction thereof. The prevailing party in any action to enforce this EULA
                  shall be entitled to recover costs and expenses including, without limitation, attorneys’ fees. This EULA is
                  made within the exclusive jurisdiction of the United States, and its jurisdiction shall supersede any other
                  jurisdiction of either party’s election.</p>
              </div>
              <div className={`${styles.PolicyConDe}`}>
                <p className={`${styles.PolicyText}`}><span>8. Non-Transferable.</span> This EULA is not assignable or transferable by Licensee, and any attempt to do so
                  would be void.</p>
              </div>
              <div className={`${styles.PolicyConDe}`}>
                <p className={`${styles.PolicyText}`}><span>9. Severability.</span> No failure to exercise, and no delay in exercising, on the part of either party, any
                  privilege, any power or any rights hereunder will operate as a waiver thereof, nor will any single or partial
                  exercise of any right or power hereunder preclude further exercise of any other right hereunder. If any
                  provision of this EULA shall be adjudged by any court of competent jurisdiction to be unenforceable or
                  invalid, that provision shall be limited or eliminated to the minimum extent necessary so that this EULA
                  shall otherwise remain in full force and effect and enforceable.</p>
              </div>
              <div className={`${styles.PolicyConDe}`}>
                <p className={`${styles.PolicyText}`}><span>10. WARRANTY   DISCLAIMER.</span> LICENSOR,   AND   AUTHOR   OF   THE   SOFTWARE,   HEREBY
                  EXPRESSLY   DISCLAIM   ANY   WARRANTY   FOR   THE   SOFTWARE.   THE   SOFTWARE   AND   ANY
                  RELATED DOCUMENTATION IS PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND, EITHER
                  EXPRESS   OR   IMPLIED,   INCLUDING,   WITHOUT   LIMITATION,   THE   IMPLIED   WARRANTIES   OF
                  MERCHANTABILITY,   FITNESS   FOR   A   PARTICULAR   PURPOSE,   OR   NON-INFRINGEMENT.
                  LICENSEE ACCEPTS ANY AND ALL RISK ARISING OUT OF USE OR PERFORMANCE OF THE
                  SOFTWARE.</p>
              </div>
              <div className={`${styles.PolicyConDe}`}>
                <p className={`${styles.PolicyText}`}><span>11. LIMITATION OF LIABILITY.</span> LICENSOR SHALL NOT BE LIABLE TO LICENSEE, OR ANY OTHER
                  PERSON   OR   ENTITY   CLAIMING   THROUGH   LICENSEE   ANY   LOSS   OF   PROFITS,   INCOME,
                  SAVINGS,   OR   ANY   OTHER   CONSEQUENTIAL,   INCIDENTAL,   SPECIAL,   PUNITIVE,   DIRECT   OR
                  INDIRECT DAMAGE, WHETHER ARISING IN CONTRACT, TORT, WARRANTY, OR OTHERWISE.
                  THESE   LIMITATIONS   SHALL   APPLY   REGARDLESS   OF   THE   ESSENTIAL   PURPOSE   OF   ANY
                  LIMITED REMEDY. UNDER NO CIRCUMSTANCES SHALL LICENSOR’S AGGREGATE LIABILITY TO LICENSEE, OR ANY OTHER PERSON OR ENTITY CLAIMING THROUGH LICENSEE, EXCEED THE
                  FINANCIAL AMOUNT ACTUALLY PAID BY LICENSEE TO LICENSOR FOR THE SOFTWARE.</p>
              </div>
              <div className={`${styles.PolicyConDe}`}>
                <p className={`${styles.PolicyText}`}><span>12. Entire Agreement.</span> This EULA constitutes the entire agreement between Licensor and Licensee and
                  supersedes   all   prior   understandings   of   Licensor   and   Licensee,   including   any   prior   representation,
                  statement, condition, or warranty with respect to the subject matter of this EULA.</p>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </React.Fragment >);
  }
}


export default HomePage;