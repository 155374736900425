import { useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import axios from 'axios';
import { incrementProspectCounter } from "../redux/actions/prospectCounterAction";
import { useDispatch } from "react-redux";
const InitialAPICall = () => {
    const { getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch();
    useEffect(()=>{
        console.log("InitialAPICall")
        
        async function getProspectMessageCounter() {
          
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
            await axios.get(process.env.REACT_APP_COMMUNICATION_NODE_API_URL + 'prospect/get-prospect-tenant-message-counter', {
              headers: {
                Authorization: `Bearer ${token}`,
              }
            }).then(response => {
              console.log(response.data.data)
                 
              const counter = response.data.data.length ? response.data.data[0].total_unread_no_prospect: 0;
              const integerCounter = parseInt(counter, 10)
              console.log(integerCounter)
              dispatch(incrementProspectCounter(integerCounter))
            }).catch(error => {
              console.log("error",error)
            });
          }
          // getProspectMessageCounter();
    },[getAccessTokenSilently])



    return(<></>)

}

export default InitialAPICall