import styles from '../../maintenancerequestdetails.module.css';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { Dropdown } from 'react-bootstrap';
import { MoreVertical, Trash, Edit, Search, Plus, X } from "react-feather";
import { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import { useAuth0 } from "@auth0/auth0-react";
import { AddLogCallAPI } from '../../../../components/AddLogs';
import AddNoteForm from './CommentsTab/AddNoteForm';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

const CommentsTab = ({ notes, requestData, setRequestData, setSnackbarOpen, setSnackbarMsg, setLoading }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [noteList, setNoteList] = useState([]);
    const [filteredNoteList, setFilteredNoteList] = useState([]);
    const [filterData, setFilterData] = useState({ searchKey: "" });
    const [addFormOpen, setAddFormOpen] = useState(false);
    const [noteData, setNoteData] = useState(null);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    useEffect(() => {
        if (notes) {
            setNoteList(notes);
        }
    }, [notes]);

    useEffect(() => {
        let filteredList = noteList;

        if (filterData.searchKey !== "") {
            let inputVal = filterData.searchKey;
            inputVal = inputVal.toLowerCase();
            filteredList = filteredList.filter((item) => item.note.toLowerCase().includes(inputVal));
        }

        setFilteredNoteList(filteredList);
    }, [noteList, filterData]);

    const delConfirm = async () => {
        setOpenDeleteModal(false);
        setLoading(true);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
        return axios.delete(process.env.REACT_APP_CASE_API_URL + 'v1/maintenance-requests/' + requestData?.id + '/notes/' + noteData?.id, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            setLoading(false);

            setSnackbarOpen(true);
            setSnackbarMsg(response.data.message);

            setRequestData(prev => {
                let notes = prev?.notes?.filter(i => i?.id !== noteData?.id);
                return { ...prev, notes: notes };
            });

            let logData = {
                'title': 'Maintenance request  comment is deleted',
                'description': [
                    'Name: ' + requestData?.title,
                    'Request number: ' + requestData?.request_no,
                    'Property: ' + requestData?.property_name,
                    'Apartment: ' + requestData?.apt_name,
                    'Tenant: ' + requestData?.tenant_name,
                    'Comment: ' + noteData?.note,
                ]
            }
            AddLogCallAPI(logData, token);

            setNoteData(null);
        }).catch(error => {
            if (typeof error.response !== 'undefined')
                toast.error(error.response.data.message);
            setLoading(false);
        });
    }

    return (<div>
        <div className={`${styles.NotesSearchArea}`}>
            <div className={`${styles.NotesSearchMain}`}>
                <input type="text" placeholder="Search" className={`${styles.searchInput}`} onChange={(e) => setFilterData(prev => { return { ...prev, searchKey: e.target.value } })} />
                <Search />
            </div>
            <button className={`${styles.AddNoteBu}`} onClick={(e) => { setAddFormOpen(true); setNoteData(null); }}><Plus /></button>
        </div>

        {(addFormOpen && !noteData) && <AddNoteForm requestId={requestData?.id} noteData={noteData} setNoteData={setNoteData.bind(this)} setAddFormOpen={setAddFormOpen.bind(this)} requestData={requestData} setRequestData={setRequestData.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} setLoading={setLoading.bind(this)} />}

        {filteredNoteList?.map(item => {
            return (<div className={`${styles.ReferBodySummCommNotes}`} key={item?.id}>
                <img src={(item.user_image === '') ? "/static/img/default_avataar.svg" : item.user_image} alt="" />
                <div className={`${styles.ReferBodySummCommBody}`}>
                    <div className={`${styles.NoteListTitle}`}>
                        <p className={`${styles.SummCommBodyName}`}>{item.user_name} <span>{item.role} @{item.property}</span></p>
                        <div className={`${styles.NoteListIcons}`}>
                            <Dropdown className={`${styles.DropDownMain}`}>
                                <Dropdown.Toggle id="dropdown-basic" className={`${styles.FilesUpCardDD}`}>
                                    <MoreVertical />
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="DropDownMenuBoxCus">
                                    <Dropdown.Item className="DropDListWIcon" onClick={(e) => { setAddFormOpen(true); setNoteData(item); }}><Edit /> Edit</Dropdown.Item>
                                    <Dropdown.Item className="DropDListWIcon" onClick={(e) => { setOpenDeleteModal(true); setNoteData(item); }}><Trash /> Delete</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <p className={`${styles.SummCommBodyDate}`}>{dayjs(item?.created_at).fromNow()}</p>
                    <p className={`${styles.SummCommBodyText}`}>
                        {(addFormOpen && noteData && noteData?.id === item?.id) && <AddNoteForm requestId={requestData?.id} noteData={noteData} setNoteData={setNoteData.bind(this)} setAddFormOpen={setAddFormOpen.bind(this)} requestData={requestData} setRequestData={setRequestData.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} setLoading={setLoading.bind(this)} />}
                        {!(addFormOpen && noteData) && item.note}
                    </p>
                </div>
            </div>);
        })}

        {filteredNoteList?.length === 0 && <div className={`${styles.NoDataMain}`}>
            <div className={`${styles.NoDataIMG}`}>
                <img src="/static/img/nodata.svg" alt="No Data" />
            </div>
            <p className={`${styles.NoDataText}`}>No Data Found</p>
        </div>}

        <Dialog
            open={openDeleteModal}
            fullWidth
            maxWidth="sm"
            onClose={(e) => setOpenDeleteModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp"
        >
            <DialogTitle id="alert-dialog-title">Are you sure you want to delete the note?<button onClick={(e) => setOpenDeleteModal(false)}><X /></button></DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div className="ModalFormGroup">
                        <label className="PopupBodyText">The note will be removed immediately.You can't undo this action.</label>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button className="CancelPopupBU" onClick={(e) => delConfirm()}>Delete</Button>
                <Button className="SubmitPopupBU" onClick={(e) => setOpenDeleteModal(false)}>Cancel</Button>
            </DialogActions>
        </Dialog>
    </div>)

}

export default CommentsTab;