import React, { useEffect, useRef, useState } from 'react';
import styles from './rentalappplication.module.css';
import { Link as Linkto, useHistory } from "react-router-dom";
import { Background, Parallax } from 'react-parallax';
import Loader from '../../components/Loader';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import BasicInfoAccordion from './components/BasicInfoAccordion';
import PersonalInfoAccordion from './components/PersonalInfoAccordion';
import AddressAccordion from './components/AddressAccordion';
import RentalInfoAccordion from './components/RentalInfoAccordion';
import EmploymentAccordion from './components/EmploymentAccordion';
import OtherInfoAccordion from './components/OtherInfoAccordion';
import FilesAccordion from './components/FilesAccordion';
import TermsCondAccordion from './components/TermsCondAccordion';
import moment from 'moment';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide, Snackbar } from '@mui/material';
import { ArrowLeft, X } from 'react-feather';
import OTPInput from 'react-otp-input';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import NumberFormat from 'react-number-format';
import BasicInfoAccordion2 from './components/BasicInfoAccordion2';
import { SmartToaster, toast } from 'react-smart-toaster';

const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const PhoneField = React.forwardRef((props, ref) => {
  return <NumberFormat
    prefix=""
    displayType="input"
    type="tel"
    format={"(###) ###-####"}
    {...props} />;
});

const loginSchema1 = yup.object().shape({
  phone: yup.string().required('This field is Required').matches(phoneRegExp, 'Invalid phone number')
});

const loginSchema2 = yup.object().shape({
  otp: yup.string().required('Please enter 6 digit code.').min(6, 'Please enter 6 digit code.').max(6, 'Please enter 6 digit code.')
});

const RentalApplication = ({ match }) => {
  const { appStr, str } = match.params;
  const redirect = useHistory();
  const [loading, setLoading] = useState(false);
  const [propertDetails, setPropertDetails] = useState(null);
  const [apartmentList, setApartmentList] = useState([]);
  const [panelExpanded, setPanelExpanded] = useState('panel1');
  const [currentStep, setCurrentStep] = useState(1);
  const [lastStep, setLastStep] = useState(1);
  const [stepData, setStepData] = useState(null);
  const [skipSteps, setSkipSteps] = useState([]);
  const [applicantId, setApplicantId] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const panel2Ref = useRef();
  const panel3Ref = useRef();
  const panel4Ref = useRef();
  const panel5Ref = useRef();
  const panel6Ref = useRef();
  const panel7Ref = useRef();
  const panel8Ref = useRef();
  const [applicantDet, setApplicantDet] = useState(null);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [loginModalOpen2, setLoginModalOpen2] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [loginError2, setLoginError2] = useState('');
  const [loginPhone, setLoginPhone] = useState('');
  const [resendSuccess, setResendSuccess] = useState('');

  const { register: loginRegister1, handleSubmit: loginHandleSubmit1, control: loginControl1, errors: loginErrors1 } = useForm({
    resolver: yupResolver(loginSchema1),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const { register: loginRegister2, handleSubmit: loginHandleSubmit2, control: loginControl2, errors: loginErrors2, clearErrors, setValue } = useForm({
    resolver: yupResolver(loginSchema2)
  });

  useEffect(() => {
    let prospectLoginData = localStorage.getItem("prospectLoginData");
    if (prospectLoginData) {
      console.log(111111);
      prospectLoginData = JSON.parse(prospectLoginData);

      if (Date.now() > prospectLoginData?.time) {
        console.log(22222);
        setLoginModalOpen(true);
      }

      console.log('prospectLoginData?.applicantId');
      console.log(prospectLoginData?.applicantId);
      console.log('applicantId');
      console.log(applicantId);

      if (applicantId > 0 && prospectLoginData?.applicantId !== applicantId) {
        console.log(33333333333);
        console.log('prospectLoginData?.applicantId');
        console.log(prospectLoginData?.applicantId);
        console.log('applicantId');
        console.log(applicantId);
        setLoginModalOpen(true);
      }
    } else {
      console.log(444444444);
      setLoginModalOpen(true);
    }
  }, [applicantId]);

  useEffect(() => {
    const fetchByID = async () => {
      await axios.get(process.env.REACT_APP_APPLICANT_API_URL + 'v1/application/property/' + str).then(response => {
        const resData = response.data;
        if (resData.apartmentList.length === 0) {
          redirect.push('/rental-application-error/' + resData?.propertyDet?.id);
        } else {
          setPropertDetails(resData?.propertyDet);
          setApartmentList(resData?.apartmentList);

          setSkipSteps([]);

          /*let propertyFormTemp = resData?.propertyDet?.rental_application;
          if (propertyFormTemp?.landlord_name === false && propertyFormTemp?.landlord_phone_no === false && propertyFormTemp?.reason_for_moving === false && propertyFormTemp?.who_live_with_you === false && propertyFormTemp?.lived_current_addrs === false && propertyFormTemp?.current_rent === false) {
            setSkipSteps(oldArray => [...oldArray, 4]);
          }
          if (propertyFormTemp?.employer_information === false) {
            setSkipSteps(oldArray => [...oldArray, 5]);
          }*/
        }
      }).catch(error => {
        redirect.push('/rental-application-invalid');
      });
    }
    fetchByID();
  }, [str, redirect]);

  useEffect(() => {
    async function fetchByStr() {
      axios.get(process.env.REACT_APP_APPLICANT_API_URL + 'v1/application/details/' + appStr).then(response => {
        const resData = response.data.data;

        if (propertDetails && resData?.is_payment === 1) {
          redirect.push('/rental-application-success/' + propertDetails?.id);
          return false;
        }

        if (propertDetails && resData?.is_prospect_applied === 1) {
          redirect.push('/rental-application-payment/' + resData?.payment_url_token_str);
          return false;
        }

        setApplicantId(resData?.id);
        setApplicantDet(resData);

        setStepData(prev => {
          return { ...prev, property_id: resData?.property_id, apt_id: resData?.apt_id, no_of_applicants: resData?.no_of_applicants, move_in_date: resData?.move_in_date, apt_type: resData?.apt_type, first_name: resData?.first_name, middle_name: resData?.middle_name, last_name: resData?.last_name, birth_date: resData?.birth_date, driver_license: resData?.driver_license, social_security_number: resData?.social_security_number, email: resData?.email, phone: resData?.phone, is_personal_info_submitted: resData?.is_personal_info_submitted };
        });

        if (resData?.no_of_applicants === 2) {
          setStepData(prev => {
            return { ...prev, name2: resData?.name2, email2: resData?.email2 }
          });
        }

        if (resData?.is_address_submitted === 1) {
          setStepData(prev => {
            return { ...prev, address: resData?.address, address2: resData?.address2, city: resData?.city, state: resData?.state, zip: resData?.zip, is_address_submitted: resData?.is_address_submitted };
          });
        }

        if (resData?.is_rental_info_submitted === 1) {
          setStepData(prev => {
            return { ...prev, landlord_name: resData?.landlord_name, landlord_phone_no: resData?.landlord_phone_no, reason_for_moving: resData?.reason_for_moving, who_live_with_you: resData?.who_live_with_you, long_lived_current_addrs: resData?.long_lived_current_addrs, current_rent: resData?.current_rent, is_rental_info_submitted: resData?.is_rental_info_submitted };
          });
        }

        if (resData?.is_employement_submitted === 1) {
          setStepData(prev => {
            return { ...prev, employement: resData?.employement, employer_name: resData?.employer_name, employer_address: resData?.employer_address, years_employed: resData?.years_employed, job_title: resData?.job_title, income_per_year: resData?.income_per_year, hire_date: resData?.hire_date, is_employement_submitted: resData?.is_employement_submitted };
          });
        }

        if (resData?.is_other_info_submitted === 1) {
          setStepData(prev => {
            return { ...prev, ever_evicted: resData?.ever_evicted, ever_case_filed: resData?.ever_case_filed, credit_score: resData?.credit_score, have_pets: resData?.have_pets, own_vehicles: resData?.own_vehicles, additional_information: resData?.additional_information, do_you_smoke: resData?.do_you_smoke, ever_had_bankruptcy: resData?.ever_had_bankruptcy, ever_broken_lease: resData?.ever_broken_lease, ever_had_bedbugs: resData?.ever_had_bedbugs, is_other_info_submitted: resData?.is_other_info_submitted };
          });
        }

        if (resData?.is_upload_files === 1) {
          setStepData(prev => {
            return { ...prev, fileList: JSON.stringify(resData?.files), is_upload_files: resData?.is_upload_files };
          });
        }

        if (resData?.is_term_submitted === 1) {
          setStepData(prev => {
            return { ...prev, terms: true, signature: resData?.signature, is_term_submitted: resData?.is_term_submitted };
          });
        }

        if (resData?.is_term_submitted === 1) {
          setCurrentStep(8);
          setLastStep(8);
          setPanelExpanded('panel8');
        } else if (resData?.is_upload_files === 1) {
          setCurrentStep(8);
          setLastStep(8);
          setPanelExpanded('panel8');
          panel8Ref.current.scrollIntoView({ behavior: "smooth" });
        } else if (resData?.is_other_info_submitted === 1) {
          setCurrentStep(7);
          setLastStep(7);
          setPanelExpanded('panel7');
          panel7Ref.current.scrollIntoView({ behavior: "smooth" });
        } else if (resData?.is_employement_submitted === 1) {
          setCurrentStep(6);
          setLastStep(6);
          setPanelExpanded('panel6');
          panel6Ref.current.scrollIntoView({ behavior: "smooth" });
        } else if (resData?.is_rental_info_submitted === 1) {
          setCurrentStep(5);
          setLastStep(5);
          setPanelExpanded('panel5');
          panel5Ref.current.scrollIntoView({ behavior: "smooth" });
        } else if (resData?.is_address_submitted === 1) {
          setCurrentStep(4);
          setLastStep(4);
          setPanelExpanded('panel4');
          panel4Ref.current.scrollIntoView({ behavior: "smooth" });
        } else if (resData?.is_personal_info_submitted === 1) {
          setCurrentStep(3);
          setLastStep(3);
          setPanelExpanded('panel3');
          panel3Ref.current.scrollIntoView({ behavior: "smooth" });
        }

      }).catch(error => {
        redirect.push('/rental-application-invalid');
      });
    }

    fetchByStr();

  }, [appStr, propertDetails, redirect]);

  const accordinChange = (panel, step) => (event, isExpanded) => {
    setPanelExpanded(isExpanded ? panel : false);
    if (isExpanded) {
      setCurrentStep(step);
      if (lastStep < step) {
        setLastStep(step);
      }
    }
  };

  const getNextStep = (cStep) => {
    cStep++;
    if (skipSteps.includes(cStep)) {
      let cTemp = getNextStep(cStep);
      return cTemp;
    } else {
      return cStep;
    }
  }

  useEffect(() => {

    setTimeout(() => {
      if (currentStep === 2) {
        panel2Ref.current.scrollIntoView({ behavior: "smooth" });
      }
      if (currentStep === 3) {
        panel3Ref.current.scrollIntoView({ behavior: "smooth" });
      }
      if (currentStep === 4) {
        panel4Ref.current.scrollIntoView({ behavior: "smooth" });
      }
      if (currentStep === 5) {
        panel5Ref.current.scrollIntoView({ behavior: "smooth" });
      }
      if (currentStep === 6) {
        panel6Ref.current.scrollIntoView({ behavior: "smooth" });
      }
      if (currentStep === 7) {
        panel7Ref.current.scrollIntoView({ behavior: "smooth" });
      }
      if (currentStep === 8) {
        panel8Ref.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 500);

  }, [currentStep]);

  const checkIsSubmit = () => {
    let isPersonalInfoSubmitted = false;
    let isAddressSubmitted = false;
    let isRentalInfoSubmitted = false;
    let isEmployementSubmitted = false;
    let isOtherInfoSubmitted = false;
    let isUploadFiles = false;
    let isTermSubmitted = false;
    if (stepData?.is_personal_info_submitted && stepData?.is_personal_info_submitted === 1) {
      isPersonalInfoSubmitted = true;
    }
    if (stepData?.is_address_submitted && stepData?.is_address_submitted === 1) {
      isAddressSubmitted = true;
    }
    if (stepData?.is_rental_info_submitted && stepData?.is_rental_info_submitted === 1) {
      isRentalInfoSubmitted = true;
    }
    if (stepData?.is_employement_submitted && stepData?.is_employement_submitted === 1) {
      isEmployementSubmitted = true;
    }
    if (stepData?.is_other_info_submitted && stepData?.is_other_info_submitted === 1) {
      isOtherInfoSubmitted = true;
    }
    if (stepData?.is_upload_files && stepData?.is_upload_files === 1) {
      isUploadFiles = true;
    }
    if (stepData?.is_term_submitted && stepData?.is_term_submitted === 1) {
      isTermSubmitted = true;
    }

    if (isPersonalInfoSubmitted && isAddressSubmitted && isRentalInfoSubmitted && isEmployementSubmitted && isOtherInfoSubmitted && isUploadFiles && isTermSubmitted)
      return true;
    else
      return false;
  }

  const submitApplicant = () => {
    setLoading(true);
    let postData = stepData;
    postData = { ...postData, applcation_fee: propertDetails?.rental_application?.applcation_fee };

    if (postData?.CurrentStep) {
      delete postData?.CurrentStep;
    }

    if (applicantDet?.is_primary === 0) {
      axios.post(process.env.REACT_APP_APPLICANT_API_URL + 'v1/add-applicants-2/' + applicantId, postData).then(response => {
        setSnackbarOpen(true);
        setSnackbarMsg('Rental Application submitted successfully!');
        setLoading(false);
        setTimeout(() => {
          redirect.push('/rental-application-payment/' + response?.data?.urlToken);
        }, 2000);
      }).catch(error => {
        setLoading(false);
      });
    } else {
      axios.post(process.env.REACT_APP_APPLICANT_API_URL + 'v1/add-applicants', postData).then(response => {
        setSnackbarOpen(true);
        setSnackbarMsg('Rental Application submitted successfully!');
        setLoading(false);
        setTimeout(() => {
          redirect.push('/rental-application-payment/' + response?.data?.urlToken);
        }, 2000);
      }).catch(error => {
        setLoading(false);
      });
    }
  }

  const getToken = (data) => {
    setLoginError('');
    setLoginError2('');
    setLoginPhone('');
    setLoading(true);
    setResendSuccess('');

    data = { ...data, applicantId: applicantId };

    axios.post(process.env.REACT_APP_APPLICANT_API_URL + 'v1/send-login-code', data).then(response => {
      setLoading(false);
      if (response.data.status === 'validation-error') {
        setLoginError(response.data.message);
        return false;
      }
      if (response.data?.resp?.error && response.data?.resp?.error === 'bad.connection') {
        setLoginError('The phone number is invalid.');
        return false;
      }
      if (response.data?.resp?.error) {
        setLoginError(response.data?.resp?.error_description);
        return false;
      }
      if (!response.data?.resp?.error) {
        setLoginModalOpen(false);
        setLoginModalOpen2(true);
        setLoginPhone(response.data?.resp?.phone_number);
      }
    }).catch((error) => {
      setLoading(false);
    });
  }

  const confirmToken = (data) => {
    setResendSuccess('');
    setLoading(true);
    setLoginError2('');

    data = { ...data, phone: loginPhone };

    axios.post(process.env.REACT_APP_APPLICANT_API_URL + 'v1/authenticate-code', data).then(response => {
      setLoading(false);
      if (response.data?.resp?.error) {
        setLoginError2(response.data?.resp?.error_description);
        return false;
      } else {
        let rsData = response.data?.resp;
        localStorage.setItem("prospectLoginData", JSON.stringify({ applicantId: applicantId, access_token: rsData?.id_token, time: Date.now() + rsData?.expires_in }));
        setLoginModalOpen2(false);
      }
    }).catch((error) => {
      setLoading(false);
    });
  }

  const resendCode = () => {
    setLoading(true);

    let data = { phone: loginPhone.replace('+1', ''), applicantId: applicantId };

    axios.post(process.env.REACT_APP_APPLICANT_API_URL + 'v1/send-login-code', data).then(response => {
      setLoading(false);
      setResendSuccess('Verification token has been resent.');
    }).catch((error) => {
      setLoading(false);
    });
  }

  return (<>
    {loading && <Loader />}
    <Helmet title="Rental Applicant" />
    <SmartToaster store={toast} lightBackground={true} position={"top_center"} />

    <Parallax strength={300} className="RentalParallax">
      <Background className="custom-bg">
        <img src="/static/img/rental-app-bg.jpg" alt="fill murray" />
      </Background>
    </Parallax>

    <div className="WhiteBG">

      <div className={`${styles.RentalAppliArea}`}>

        <div className={`${styles.RentalAppliHead}`}>
          <img src={(propertDetails?.app_theme_options && propertDetails?.app_theme_options?.image_url !== '') ? propertDetails?.app_theme_options?.image_url : '/static/img/logo.svg'} alt="logo" style={{ width: '200px' }} />
          <p className={`${styles.RentalAppliHeadTitle}`}>{propertDetails?.name}</p>
          <p className={`${styles.RentalAppliHeadTitle}`}>Rental Application</p>
        </div>

        <div className={`${styles.ApplicantSec}`}>
          <p className={`${styles.ApplicantSecTitle}`}>Applicant #{applicantDet?.is_primary === 0 ? 2 : 1}</p>
          <div className={`${styles.FormNoteSec}`}>
            <img src="/static/img/NoteIcon.svg" alt="logo" />
            <p className={`${styles.FormNoteText}`}>Please note that <span>all fields are required</span>. Only fields marked optional are optional</p>
          </div>
          <div className="FormAccording">

            {applicantDet?.is_primary === 0 ? <BasicInfoAccordion2 stepData={stepData} setStepData={setStepData.bind(this)} apartmentList={apartmentList} expanded={panelExpanded} accordinChange={accordinChange.bind(this)} lastStep={lastStep} setLastStep={setLastStep.bind(this)} setCurrentStep={setCurrentStep.bind(this)} getNextStep={getNextStep.bind(this)} setPanelExpanded={setPanelExpanded?.bind(this)} /> : <BasicInfoAccordion stepData={stepData} setStepData={setStepData.bind(this)} apartmentList={apartmentList} expanded={panelExpanded} accordinChange={accordinChange.bind(this)} lastStep={lastStep} setLastStep={setLastStep.bind(this)} setCurrentStep={setCurrentStep.bind(this)} getNextStep={getNextStep.bind(this)} setPanelExpanded={setPanelExpanded?.bind(this)} />}

            <PersonalInfoAccordion panelRef={panel2Ref} setStepData={setStepData.bind(this)} stepData={stepData} expanded={panelExpanded} accordinChange={accordinChange.bind(this)} lastStep={lastStep} setLastStep={setLastStep.bind(this)} setCurrentStep={setCurrentStep.bind(this)} getNextStep={getNextStep.bind(this)} setPanelExpanded={setPanelExpanded?.bind(this)} applicantId={applicantId} setApplicantId={setApplicantId?.bind(this)} propertyId={propertDetails?.id} applicantN={1} />

            <AddressAccordion panelRef={panel3Ref} stepData={stepData} setStepData={setStepData.bind(this)} expanded={panelExpanded} accordinChange={accordinChange.bind(this)} lastStep={lastStep} setLastStep={setLastStep.bind(this)} setCurrentStep={setCurrentStep.bind(this)} getNextStep={getNextStep.bind(this)} setPanelExpanded={setPanelExpanded?.bind(this)} applicantId={applicantId} />

            <RentalInfoAccordion panelRef={panel4Ref} stepData={stepData} setStepData={setStepData.bind(this)} expanded={panelExpanded} accordinChange={accordinChange.bind(this)} lastStep={lastStep} setLastStep={setLastStep.bind(this)} setCurrentStep={setCurrentStep.bind(this)} getNextStep={getNextStep.bind(this)} setPanelExpanded={setPanelExpanded?.bind(this)} applicantId={applicantId} />

            <EmploymentAccordion panelRef={panel5Ref} stepData={stepData} setStepData={setStepData.bind(this)} expanded={panelExpanded} accordinChange={accordinChange.bind(this)} lastStep={lastStep} setLastStep={setLastStep.bind(this)} setCurrentStep={setCurrentStep.bind(this)} getNextStep={getNextStep.bind(this)} setPanelExpanded={setPanelExpanded?.bind(this)} applicantId={applicantId} />

            <OtherInfoAccordion panelRef={panel6Ref} stepData={stepData} setStepData={setStepData.bind(this)} expanded={panelExpanded} accordinChange={accordinChange.bind(this)} lastStep={lastStep} setLastStep={setLastStep.bind(this)} setCurrentStep={setCurrentStep.bind(this)} getNextStep={getNextStep.bind(this)} setPanelExpanded={setPanelExpanded?.bind(this)} applicantId={applicantId} />

            <FilesAccordion panelRef={panel7Ref} stepData={stepData} setStepData={setStepData.bind(this)} expanded={panelExpanded} accordinChange={accordinChange.bind(this)} lastStep={lastStep} setLastStep={setLastStep.bind(this)} setCurrentStep={setCurrentStep.bind(this)} getNextStep={getNextStep.bind(this)} setPanelExpanded={setPanelExpanded?.bind(this)} applicantId={applicantId} setLoading={setLoading.bind(this)} />

            <TermsCondAccordion panelRef={panel8Ref} stepData={stepData} setStepData={setStepData.bind(this)} expanded={panelExpanded} accordinChange={accordinChange.bind(this)} lastStep={lastStep} setPanelExpanded={setPanelExpanded?.bind(this)} applicantId={applicantId} />

            <button type="button" onClick={(e) => submitApplicant()} className={checkIsSubmit() ? `${styles.RentallAppliSubmitBU} ${styles.Active}` : `${styles.RentallAppliSubmitBU}`} disabled={!checkIsSubmit()}>Submit <img src="/static/img/Icon-send.png" alt="" /></button>

          </div>
        </div>

      </div>

      <div className={`${styles.FooterWrap}`}>
        <div className={`${styles.footerMain}`}>

          <div className={`${styles.footerMainLeft}`}>
            <Linkto to={'/'}><img src={(propertDetails?.app_theme_options && propertDetails?.app_theme_options?.image_url !== '') ? propertDetails?.app_theme_options?.image_url : '/static/img/logo.svg'} alt="logo" /></Linkto>
          </div>

          <div className={`${styles.footerMainRight}`}>
            <div>
              <h3>CONTACT US</h3>
              <ul>
                <li><a href={`mailto: ${propertDetails?.email}`}>{propertDetails?.email}</a></li>
                <li><a href={`tel: ${propertDetails?.phone}`} >{propertDetails?.phone}</a></li>
              </ul>
            </div>
            <div>
              <h3>FIND US</h3>
              <ul>
                <li>{propertDetails?.address} <br />{propertDetails?.city}, {propertDetails?.state} {propertDetails?.zip}</li>
              </ul>
            </div>
            <div>
              <h3>LEGAL</h3>
              <ul>
                <li><Linkto to={'/privacy-policy'}>Privacy Policy</Linkto></li>
                <li><Linkto to={'/eula'}>End User License Agreement</Linkto></li>
              </ul>
            </div>
            <div>
              <ul>
                <li>Weston Hall © {moment().format("YYYY")}.<br />All rights reserved.</li>
              </ul>
            </div>
          </div>

        </div>
      </div>

    </div>

    <Dialog
      open={loginModalOpen}
      fullWidth
      maxWidth="xs"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp"
    >
      <form onSubmit={loginHandleSubmit1(getToken)}>
        <DialogTitle id="alert-dialog-title" className={`${styles.OtpPopupTTSec}`}>Welcome to PropertyDek</DialogTitle>
        <DialogContent>
          <p className="PopTokenText">Enter your phone number and we'll send a text to verify your phone.</p>

          <div className="PopTokenFormSec">
            <img src={'/static/img/USA-icon.svg'} alt="logo" />
            <p className="FormCodeSec">+1</p>
            <Controller
              name="phone"
              render={({ onChange, value }) => (<PhoneField className="PopTokenInput" value={value} onChange={(e) => { onChange(e); setLoginError(''); }} />)}
              control={loginControl1}
              ref={loginRegister1}
              className="PopTokenInput"
            />
          </div>
          {(loginError !== '') && <p className={`${styles.ErrorM2}`}>{loginError}</p>}
          {loginErrors1?.phone && <p className={`${styles.ErrorM2}`}>{loginErrors1?.phone.message}</p>}
        </DialogContent>
        <DialogActions className={`${styles.ButtonSec}`}>
          <Button type="submit" className="SubmitPopupBUPopPin">Next</Button>
        </DialogActions>
      </form>
    </Dialog>

    <Dialog
      open={loginModalOpen2}
      fullWidth
      maxWidth="xs"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp"
    >
      <form onSubmit={loginHandleSubmit2(confirmToken)}>
        <DialogTitle id="alert-dialog-title" className={`${styles.OtpPopupTTSec}`}>Confirm token</DialogTitle>
        <DialogContent>
          <p className="PopTokenText">Confirm token by typing in the 6 digit code we sent via text to {loginPhone}.</p>
          <div className="PinArea">
            <Controller
              name="otp"
              render={({ onChange, value }) => (<OTPInput
                value={value}
                onChange={(e) => { onChange(e); setResendSuccess(''); clearErrors(); setLoginError2('') }}
                numInputs={6}
                inputType='number'
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
              />)}
              control={loginControl2}
              ref={loginRegister2}
            />
            {loginErrors2?.otp && <p className={`${styles.ErrorM2}`}>{loginErrors2?.otp.message}</p>}
            {(loginError2 !== '') && <p className={`${styles.ErrorM2}`}>{loginError2}</p>}
            {/* <p className="PopTokenTText">We sent a code to the following phone number:</p> */}
            {resendSuccess !== '' && <p className="PopTokenTText">{resendSuccess}</p>}
            <div className="Poplink">
              {/* <button className="PopTokenLink">{loginPhone}</button> */}
              <button className="PopTokenLink" type='button' onClick={(e) => { setResendSuccess(''); resendCode(); setLoginError2(''); setValue('otp', ''); clearErrors(); }}>Resend code</button>
            </div>
          </div>
        </DialogContent>
        <DialogActions className={`${styles.ButtonSec}`}>
          <Button type="submit" className="CancelPopupBUPin" onClick={(e) => { setLoginError(''); setLoginError2(''); setLoginModalOpen2(false); setLoginModalOpen(true); }}><ArrowLeft /></Button>
          <Button type="submit" className="SubmitPopupBUPopPin">Confirm</Button>
        </DialogActions>
      </form>
    </Dialog>

    <Snackbar
      open={snackbarOpen}
      onClose={(e) => setSnackbarOpen(false)}
      TransitionComponent={Slide}
      message={snackbarMsg}
      autoHideDuration={10000}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={(e) => setSnackbarOpen(false)}
        >
          <X />
        </IconButton>
      }
    />
  </>);

}

export default RentalApplication;
