import React, { useState, useEffect } from "react";
import styles from "./rentalapplicationsuccess.module.css";
import Helmet from "react-helmet";
import { Parallax, Background } from "react-parallax";
import axios from "axios";
import moment from "moment";
import { Link as Linkto } from "react-router-dom";

export default function RentalApplicationSuccess({ history, match }) {
  const { id } = match.params;
  const [propertDetails, setpropertDetails] = useState([]);

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_APPLICANT_API_URL +
          "v1/application/property-det/" +
          id
      )
      .then((response) => {
        const resData = response.data.propertyDet;
        setpropertDetails(resData);
      })
      .catch((error) => {});
  }, [id]);

  return (
    <React.Fragment>
      <Helmet title="Rental Applicant Success" />

      <Parallax strength={300} className="RentalParallax">
        <Background className="custom-bg">
          <img src="/static/img/rental-app-bg.jpg" alt="fill murray" />
        </Background>
      </Parallax>

      <div className="WhiteBG">
        <div className={`${styles.RentalAppliArea}`}>
          <div className={`${styles.RentalAppliSuccessSec}`}>
            <img
              src="/static/img/success.png"
              alt=""
              className={`${styles.SuccessImg}`}
            />
            <p className={`${styles.PaymentSuccessTitle}`}>Thank You!</p>
            <p className={`${styles.PaymentSuccessTitleSub}`}>
              Your application and payment has been received. It will take up to
              4 days to process your application.
            </p>
          </div>
        </div>

        <div className={`${styles.FooterWrap}`}>
          <div className={`${styles.footerMain}`}>
            <div className={`${styles.footerMainLeft}`}>
              <Linkto to={"/"}>
                <img
                  src={
                    propertDetails?.image !== ""
                      ? propertDetails?.image
                      : "/static/img/logo.svg"
                  }
                  alt="logo"
                />
              </Linkto>
            </div>

            <div className={`${styles.footerMainRight}`}>
              <div>
                <h3>CONTACT US</h3>
                <ul>
                  <li>
                    <a href={`mailto: ${propertDetails.email}`}>
                      {propertDetails.email}
                    </a>
                  </li>
                  <li>
                    <a href={`tel: ${propertDetails.phone}`}>
                      {propertDetails.phone}
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <h3>FIND US</h3>
                <ul>
                  <li>
                    {propertDetails.address} <br />
                    {propertDetails.city}, {propertDetails.state}{" "}
                    {propertDetails.zip}
                  </li>
                </ul>
              </div>
              <div>
                <h3>LEGAL</h3>
                <ul>
                  <li>
                    <Linkto to={"/privacy-policy"}>Privacy Policy</Linkto>
                  </li>
                  <li>
                    <Linkto to={"/eula"}>End User License Agreement</Linkto>
                  </li>
                </ul>
              </div>
              <div>
                <ul>
                  <li>
                    Propertydek © {moment().format("YYYY")}.<br />
                    All rights reserved.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
