import { FormControl, MenuItem, Select } from "@mui/material";
import styles from "../dashboard.module.css";

const TimePeriods = [
    'Current Month',
    'Last Month',
    'Last 3 Months',
    'Last 6 Months',
    'This Year'
];

const MonthCollection = ({ monthly_collected, currentTimePeriod, setCurrentTimePeriod }) => {

    return (
        <div className={`${styles.InsideCard} ${styles.Size55}`}>
            <div className={`${styles.InsideCardFlex} ${styles.Flex}`}>
                <h6 className={`${styles.InsideCardTitle}`}>Rent Collected</h6>
                <div className={`${styles.RentDropdown} FormGroup`}>
                    <FormControl fullWidth>
                        <Select value={currentTimePeriod} onChange={(e) => setCurrentTimePeriod(e.target.value)}>
                            {TimePeriods?.map((item, index) => {
                                return <MenuItem key={index} value={item}>{item}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </div>

                <div className={`${styles.InsideCardFlexCon}`}>
                    <h3 className={`${styles.InsideCardTitleAmount}`}>
                        ${monthly_collected?.received_f}
                        {monthly_collected?.total > 0 && (<span>{(monthly_collected?.received / monthly_collected?.total) * 100 > 100 ? 100 : ((monthly_collected?.received / monthly_collected?.total) * 100).toFixed(1)}%</span>)}
                    </h3>
                    {monthly_collected?.total > 0 && (<div className={`${styles.PrograssDiv}`}>
                        {monthly_collected?.received_p > 0 && (<div style={{ width: `${monthly_collected?.received_p}%` }} className={`${styles.PrograssDivCollected}`}>
                            <div style={{ background: "#5AC55E", height: "7px", borderTopLeftRadius: "4px", borderBottomLeftRadius: "4px" }}></div>
                            <div className={`${styles.ProgressDivHoverBox} ${styles.Collected}`}>
                                <div></div>
                                <p>Rent Collected: ${monthly_collected?.received_f}</p>
                            </div>
                        </div>)}
                        {monthly_collected?.pending_p > 0 && (<div style={{ width: `${monthly_collected?.pending_p}%` }} className={`${styles.PrograssDivProcessing}`}>
                            <div style={{ background: "#FFBC00", height: "7px" }}></div>
                            <div className={`${styles.ProgressDivHoverBox} ${styles.Processing}`}>
                                <div></div>
                                <p>Rent Processing: ${monthly_collected?.pending_f}</p>
                            </div>
                        </div>)}

                        {monthly_collected?.due_p > 0 && (<div style={{ width: `${monthly_collected?.due_p}%` }} className={`${styles.PrograssDivDue}`}>
                            <div style={{ background: "#EAECEF", height: "7px", borderTopRightRadius: "4px", borderBottomRightRadius: "4px" }}></div>
                            <div className={`${styles.ProgressDivHoverBox} ${styles.Due}`}>
                                <div></div>
                                <p>Rent Due: ${monthly_collected?.due_f}</p>
                            </div>
                        </div>)}
                    </div>)}
                </div>
            </div>
        </div>
    );
}

export default MonthCollection;